<div class="container login-container" id="mainContainerAdmin">
    <!-- Login shop page start -->
    <div id="loginContentAdmin" class="row">
        <div class="well login-panel" [hidden]="showLoginPanel == false">
             <h1 class="text-center main-heading"><img class="img-responsive center-block" src="assets/img/big_logo.png" title="ACCOUNT LOGIN" alt="Complete Auto Reports Login"></h1>
            <mat-card class="card-box">

                <form [formGroup]="shop" novalidate (ngSubmit)="login()" autocomplete="off">
                    <!-- email field-->
                    <input name="email" type="hidden" />
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never" hideRequiredMarker="false" appearance="fill">
                        <input name="email" matInput autocomplete="off" placeholder="Enter Email *" maxlength="200" formControlName="email" class="form-control input-lg custom-form-input mat-form-field-placeholder"  required />
                        <mat-error *ngIf="shop.controls.email.hasError('pattern')">
                            Please enter a valid email address
                        </mat-error>
                        <mat-error *ngIf="shop.controls.email.hasError('required')">
                            Email is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                    <!--password field-->
                    <input name="emailPassword" type="hidden" />
                    <mat-form-field class="car-input btn-block input-suffix" floatPlaceholder="never" hideRequiredMarker="false" appearance="fill">

                        <input name="emailPassword" type="password" matInput autocomplete="new-password" placeholder="Enter Password *" formControlName="password"  class="form-control input-lg custom-form-input mat-form-field-placeholder" [type]="hide1 ? 'text' : 'password'" required />
                        <mat-icon matSuffix (click)="hide1 = !hide1">{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                        <mat-error *ngIf="shop.controls.password.hasError('required')">
                            Password is <strong>required</strong>
                        </mat-error>
                        <mat-error *ngIf="shop.controls.password.hasError('minLength')">
                            The password must be minimum 6 characters
                        </mat-error>
                    </mat-form-field>
                    <div class="text-left margin-bottom-15">
                      <mat-checkbox matInput formControlName="remember_me">Remember me</mat-checkbox>
                    </div>
                    <!-- login btn-->
                    <div class="margin-bottom-5 row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <button type="submit" mat-raised-button class="car-btn btn-block text-uppercase  btn-lg font-bold fontcolor-white btn-black-expanded" color="primary"  title="Login"  >LOGIN</button>
                        </div>
                    </div>
                    <!--forgot password link-->
                    <div class="form-group row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <a class="pull-right black-link" tabindex="3" title="Forgot Password?" href="javascript:void(0)" (click)="showForgotPasswordPanel()">Forgot Your Password?</a>
                        </div>
                    </div>
                    <div class="margin-bottom-5 row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <button type="button" (click)="registration()" mat-raised-button class="car-btn btn-block text-uppercase  btn-lg font-bold fontcolor-white" color="accent" title="Create New Account"  >CREATE NEW ACCOUNT</button>
                        </div>
                    </div>
                </form>
            </mat-card>
        </div>

    </div>
    <!-- Login shop page end -->
</div>
