import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { GlobalService } from 'src/environments/global.service';
import { globalConstants } from 'src/environments/globalConstants';
import { Router } from '@angular/router';


@Component({
  selector: 'app-self-checkin',
  templateUrl: './self-checkin.component.html',
  styleUrls: ['./self-checkin.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelfCheckinComponent implements OnInit {
  public currentStep: number = 0; // Set the current step to 1 for the welcome step
  public showCommonErrorMessage: any = 0;
  public customerSearchForm: FormGroup; // customerSearchForm
  public customerAddForm: FormGroup;
  public newSrRequestForm: FormGroup;
  public addVehicleForm: FormGroup;
  public customerInfo: any;
  public phoneMask = globalConstants.phoneMask;
  public shopDetail: any;
  public loginUserDetails: any;
  public countDownTimer: any;
  public countDown: any;
  public imgPath = globalConstants.S3_BUCKET_URL + globalConstants.VEHICLE_PICTURES + globalConstants.IMG_THUMB;
  public selectedVehicle: any;
  public customerVehicles: any[];
  public serviceRequestJobCategories: any;
  public showVehicleList: any = false;
  public showVehiclesSection: boolean = false;
  public showAddVehicleSection: boolean = false;
  public jobSelectedStatus = false;
  public selectedJobPayload = {};
  public states: any;
  public defaultState: any;
  public shopState: boolean;
  public vehicleMakes: any;
  public vehicleYears: any = [];
  public vehicleModels: any = [];
  public selectedJobs: any = [];
  public srInformation: any;
  public showMakeTxtFld : boolean =  false;
  public showYearTxtFld : boolean = false;
  public showModelTxtFld : boolean = false;
  public showYearAndModel :boolean = true;
  public showModel : boolean = true;
  public disableYear :any = true;
  public disableModel : any = true;
  constructor(
    private formBuilder: FormBuilder,
    public globalService: GlobalService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loginUserDetails = JSON.parse(localStorage.getItem("loggedInUser"));
    var payload = "user_id=" + this.loginUserDetails.shop_id + "&user_type=" + this.loginUserDetails.user_type + "&shop_id=" + this.loginUserDetails.shop_id;
    this.globalService.callAPI(globalConstants.API_SHOP_DETAILS, payload).subscribe((data: any) => {
      this.shopDetail = data.data;
      this.currentStep = 1;
      if ((this.shopDetail == null && typeof (this.shopDetail) == 'undefined') || (this.loginUserDetails == null && typeof (this.loginUserDetails) == 'undefined')) {
        this.router.navigate(['shops']);
      }
      this.buildCustomerSearchForm();
      this.buildAddCustomerForm();
      this.buildSRRequestForm();
      this.buildAddVehicleForm();
    });
  }

  buildCustomerSearchForm() {
    this.customerSearchForm = this.formBuilder.group({
      mobileNumber: ['', Validators.pattern(globalConstants.MOBILE_REGEX)],
      emailAddress: ['', Validators.pattern(globalConstants.EMAIL_REGEX)],
    });
  }

  buildAddCustomerForm() {
    this.customerAddForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      mobileNumber: ['', [Validators.required, Validators.pattern(globalConstants.MOBILE_REGEX)]],
      confirmMobileNumber: ['', [Validators.required, Validators.pattern(globalConstants.MOBILE_REGEX)]],
      emailAddress: ['', Validators.pattern(globalConstants.EMAIL_REGEX)]
    });
  }

  /**
 * This function checks if the mobile number and confirm mobile number fields have matching values.
 * If they do not match, it sets a 'mismatch' error on the confirm mobile number form control.
 */
  validateMobileNumbersMatch() {
    let mobileNumber = this.customerAddForm.value.mobileNumber;
    let confirmMobileNumber = this.customerAddForm.value.confirmMobileNumber;
    if (!(confirmMobileNumber === mobileNumber)) {
        this.customerAddForm.controls.confirmMobileNumber.setErrors({ mismatch: true });
    }
  }

  buildSRRequestForm() {
    this.newSrRequestForm = this.formBuilder.group({
      user_id: [this.shopDetail.id],
      customer_id: [''],
      user_type: [this.loginUserDetails.user_type],
      request_car_id: [''],
      shop_id: [this.shopDetail.id],
    });
  }

  buildAddVehicleForm() {
    this.addVehicleForm = this.formBuilder.group({
      license_plate: [''],
      state: [''],
      make: [''],
      year: ['',Validators.pattern('^[12][0-9]{3}$')],
      model: [''],
      customer_id: [''],
      production_date: [''],
      sub_model: [''],
      mileage: [''],
      color: [''],
      user_id: [this.shopDetail.id],
      user_type: ['User'],
      vin: ['']
    });
  }

  searchCustomer(nextStep: number) {
    if (this.customerSearchForm.controls.mobileNumber.value || this.customerSearchForm.controls.emailAddress.value) {
      let payload = {
        opr: 1
      }
      if (this.customerSearchForm.controls.mobileNumber.value) {
        payload["mobile_phone"] = this.customerSearchForm.controls.mobileNumber.value;
      }
      if (this.customerSearchForm.controls.emailAddress.value) {
        payload["email"] = this.customerSearchForm.controls.emailAddress.value;
      }
      this.findCustomer('self_checking_user_search', payload);
    } else {
      this.showCommonErrorMessage = 1;
    }
  }

  checkMobilePhone() {
    let mobile = this.customerAddForm.controls.mobileNumber;
    let errorMobile = false;
    if (mobile.hasError('required') || mobile.hasError('pattern')) {
      errorMobile = true;
    }
    var payload = "opr=1&mobile_phone=" + this.customerAddForm.value.mobileNumber;
    (!errorMobile) && this.findCustomer("self-checking_user_registration", payload)
  }


  checkEmailAddress(){
    let email = this.customerAddForm.controls.emailAddress;
    let errorEmail = false;
    if (email.hasError('pattern')) {
      errorEmail = true;
    }
    var payload = "opr=1&email=" + this.customerAddForm.value.emailAddress.replaceAll('+','%2B');
    (!errorEmail) && this.findCustomer("self-checking_user_registration", payload)
  }


  findCustomer(screenType, payload) {
    this.globalService.callAPI(globalConstants.API_CHECK_CUSTOMER_INFORMATION_URL, payload).subscribe((res: any) => {
      if (res.result == 1) {
        this.customerInfo = res.data;
        this.customerInfo.userExitStatus = 1;
        this.newSrRequestForm.controls.customer_id.setValue(this.customerInfo.id);
        this.getAllVehicles();
        this.getServiceRequestJobCatagories();
        this.getCountries();
        this.getVehicleMakes();
        this.goToNextStep(2);
      } else {
        if (screenType == 'self_checking_user_search') {
          this.globalService.snackbar("error", "No Customer Found, Please Try Again With valid Information");
          this.goToNextStep(4);
        }
      }
    })

  }



  addCustomer(nextStep: any) {
    if (this.customerAddForm.valid) {
      if (this.customerAddForm.controls.mobileNumber.value == this.customerAddForm.controls.confirmMobileNumber.value) {
        let payload = {
          user_type: "User",
          user_id: this.loginUserDetails.id,
          first_name: this.customerAddForm.controls.firstName.value,
          last_name: this.customerAddForm.controls.lastName.value,
          mobile: this.customerAddForm.controls.mobileNumber.value,
        }
        this.globalService.callAPI(globalConstants.API_SELF_CHECKIN_ADD_CUSTOMER, payload).subscribe((res: any) => {
          if (res.result == 1) {
            this.customerInfo = res.data.customer;
            this.customerVehicles = res.data.vehicles;
            this.customerInfo.userExitStatus = res.data.existing_customer;
            this.newSrRequestForm.controls.customer_id.setValue(this.customerInfo.id);
            this.getServiceRequestJobCatagories();
            this.getCountries();
            this.getVehicleMakes();
            this.goToNextStep(2);
          } else {
            this.globalService.snackbar("error", res.message);
          }
        })
      } else {
        this.validateMobileNumbersMatch();
      }
    } 
  }

  getServiceRequestJobCatagories() {
    let payload = {
      user_id: this.loginUserDetails.id,
      user_type: "User"
    }
    this.globalService.callAPI(globalConstants.API_SELF_CHECKIN_SERVICE_REQUEST_LIST, payload).subscribe((res: any) => {
      if (res.result == 1) {
        this.serviceRequestJobCategories = res.data;
      }
    })
  }

  getAllVehicles() {
    var payload = "customer_id=" + this.newSrRequestForm.controls.customer_id.value + "&from_v2=1&sorting=alpha";
    this.customerVehicles = [];
    this.globalService.callAPI(globalConstants.API_GET_CUSTOMER_VEHICLES_URL, payload).subscribe((res: any) => {
      if (res.result == 1) {
        this.customerVehicles = res.data;
      }
    })
  }

  handleJobSelection(event, parentEleId) {
    let parentEle = document.getElementById('expansion-' + parentEleId);
    let ele = null;
    if (event.checked == true) {
      parentEle.classList.contains("checked") ? console.log("checked set") : parentEle.classList.add("checked");
      if (event.source._elementRef.nativeElement.nextElementSibling != undefined) {
        ele = event.source._elementRef.nativeElement.nextElementSibling.id;
        document.getElementById(ele).classList.add("active");
      }
    } else if (event.checked == false) {
      if (event.source._elementRef.nativeElement.nextElementSibling != undefined) {
        ele = event.source._elementRef.nativeElement.nextElementSibling.id;
        document.getElementById(ele).classList.remove("active");
      }
    }
  }

  makeSRJson(object, levelOneJob, level2ndJob, level3rdJob, pid) {
    if (object.checked || object.value == 1  || object.type == "blur") {
      this.jobSelectedStatus = true;
      if (level2ndJob.children) {
        if (typeof (level2ndJob.children) != 'undefined' && level2ndJob.children[0].input == "checkbox") {
          for(var i in level2ndJob.children){
            this.selectedJobs.push(this.makeJobObject(levelOneJob.id, level2ndJob.id, level2ndJob.children[i].id, 3, 'checkBox', true));
          }
        } else if (level3rdJob != undefined && level3rdJob.input == "radio") {
          // this.addTheJobValue(levelOneJob.id,level2ndJob.id,level3rdJob.id,level3rdJob.id);

        } else if (typeof (level2ndJob.children) != 'undefined' && level2ndJob.children[0].input == "textarea") {
          var textAreaValue = object.target == undefined ? '' : object.target.value;
          this.removeJob(levelOneJob.id, level2ndJob.id, null, textAreaValue);
          this.selectedJobs.push(this.makeJobObject(levelOneJob.id, level2ndJob.id, level2ndJob.children[0].id, 3, 'textArea', textAreaValue));
        } else if (level3rdJob != undefined && level3rdJob.input == "imageupload") {
          //image upload code goes here 
          //integration to be done when enabled on frontend
        }
      } else {
        if (level2ndJob.input == "checkbox") { // always true condition , second child will always be checkbox
          this.selectedJobs.push(this.makeJobObject(levelOneJob.id, level2ndJob.id, null, 2, 'checkBox', true))
        }
      }
    } else {
      if (level2ndJob.children) {
        if (typeof (level2ndJob.children[0]) != 'undefined' && level2ndJob.children[0].input == "checkbox") {
          this.removeJob(levelOneJob.id, level2ndJob.id, null, null);
        } else if (typeof (level2ndJob.children[0]) != 'undefined' && level2ndJob.children[0].input == "radio") {
          this.removeJob(levelOneJob.id, level2ndJob.id, null, null);
        } else if (typeof (level2ndJob.children[0]) != 'undefined' && level2ndJob.children[0].input == "textarea") {
          this.removeJob(levelOneJob.id, level2ndJob.id, null, null);
          let f = <HTMLButtonElement>document.getElementById(levelOneJob.id + "-" + level2ndJob.id);
          f.value = ""
        }
        if(this.selectedJobs.length == 0){
          this.jobSelectedStatus = false;
        }
      } else {
        this.removeJob(levelOneJob.id, level2ndJob.id, null, null);
        if(this.selectedJobs.length == 0){
          this.jobSelectedStatus = false;
       }
      }
    }
  }

  removeJob(levelOneJob, level2ndJob, level3rdJob, jobValue) {
    for (let i = 0; i < this.selectedJobs.length; i++) {
      if (this.selectedJobs[i].level1Id == levelOneJob && this.selectedJobs[i].level2Id == level2ndJob) {
        this.selectedJobs.splice(i, 1);
      }
    }
  }

  makeJobObject(level1Id, level2Id, level3Id, levelType, inputType, value) {
    let obj = {
      level1Id: level1Id,
      level2Id: level2Id,
      level3Id: level3Id,
      levelType: levelType,
      inputType: inputType,
      value: value
    }
    return obj;
  }

  createSrRequest() {
    this.newSrRequestForm.controls.request_car_id.setValue(this.selectedVehicle.id);
    this.selectedJobPayload = {};
    let data = new FormData();
    for (var i in this.selectedJobs) {
      if (this.selectedJobs[i].levelType == 2) {
        this.selectedJobPayload["chk[" + this.selectedJobs[i].level1Id + "][" + this.selectedJobs[i].level2Id + "]"] = this.selectedJobs[i].value;
      } else {
        this.selectedJobPayload["chk[" + this.selectedJobs[i].level1Id + "][" + this.selectedJobs[i].level2Id + "][" + this.selectedJobs[i].level3Id + "]"] = this.selectedJobs[i].value;
      }
      //  data.append(i, this.selectedJobPayload[i]);
    }
    for (var i in this.selectedJobPayload) {
      data.append(i, this.selectedJobPayload[i]);
    }

    for (var i in this.newSrRequestForm.value) {
      if ((this.newSrRequestForm.value[i] == null) || (typeof this.newSrRequestForm.value[i] == 'undefined')) {
        data.append(i, '');
      } else {
        data.append(i, this.newSrRequestForm.value[i]);
      }
    }
    this.globalService.formData(globalConstants.API_SELF_CHECKIN_CREATE_SERVICE_REQUEST, data).subscribe((response: any) => {
      if (response.result == 1) {
        this.selectedJobs = [];
        this.srInformation = response.data;
        this.goToNextStep(3);
      } else {
        this.globalService.snackbar("error", response.message);
      }
    })
  }

  getVehicleName(vehicleInfo: any) {
    return this.globalService.getGlobalVehicleName(vehicleInfo);
  }

  selectVehicles(car) {
    this.selectedVehicle = null;
    setTimeout(() => {
      this.selectedVehicle = car;
      this.showVehicleList = true;
      window.scrollTo(0, 0);
      this.setCountDownTimer(120);
    }, 500);
  }

  unSelectVehicles(car) {
    this.selectedVehicle = null;
  }

  goToNextStep(nextStep: number) {
    if (nextStep !== 1) {
      if (nextStep == 3) {
        this.setCountDownTimer(10);
      } else {
        this.setCountDownTimer(120);
      }
    }
    this.currentStep = nextStep;
    this.selectedVehicle = null;
    this.showVehiclesSection = false;
    window.scrollTo(0, 0);
  }

  setCountDownTimer(maxTime) {
    if (this.countDown) {
      clearInterval(this.countDown);
    }
    this.countDownTimer = maxTime;
    this.countDown = setInterval(() => {
      // Your interval logic here
      --this.countDownTimer;
      if (this.countDownTimer == 0) {
        this.goToWelcomeScreen();
      }
    }, 1000);
  }

  goToWelcomeScreen() {
    this.customerSearchForm.reset();
    this.customerAddForm.reset();
    this.showCommonErrorMessage = 0;
    this.currentStep = 1;
    this.showVehiclesSection = false;
    this.showVehicleList = false;
    this.showAddVehicleSection = false;
    this.jobSelectedStatus = false;
    this.selectedVehicle = null;
    this.selectedJobs = [];
    this.selectedJobPayload = {};
    if (this.countDown) {
      clearInterval(this.countDown);
    }
    if(this.currentStep !== 1){
       this.reSetAddVehicleForm();
    }
  }

  // navigate got registration-step on click register now button
  registerNow() {
    if (this.currentStep === 1) {
      this.currentStep = 4;
    }
  }

  confirmCustomer() {
    this.showVehiclesSection = !this.showVehiclesSection;
    this.showVehicleList = !this.showVehicleList;
    this.selectedVehicle = null;
  }

  // Toggle the visibility of the Add Vehicle Details section
  showAddVehiclesSection() {
    this.showAddVehicleSection = !this.showAddVehicleSection;
    this.showVehicleList = !this.showVehicleList;
    this.selectedVehicle = null;
    this.reSetAddVehicleForm();
    window.scrollTo(0, 0);
  }

  getCountries() {
    let countryId = (this.customerInfo.country == "" || typeof (this.customerInfo.country) == 'undefined') ? this.shopDetail.country : this.customerInfo.country;
      this.getStates(countryId);
  }

  getStates(countryId: any) {
    var payload = "country_id=" + countryId + "&opr=1";
    this.globalService.callAPI(globalConstants.API_GET_STATES_URL, payload).subscribe((data: any) => {
      this.states = data.data;
      var statesList = [];
      for (var i in this.states) {
        this.states[i].id = this.states[i].state_id;
        this.states[i].text = this.states[i].state_name;
        statesList.push(this.states[i]);
      }
      this.states = statesList;
      this.shopState = this.loginUserDetails.state;
      this.setDefaultState();
    })
  }

  checkLicense(VINFindState = false) {
    let license_plate = this.addVehicleForm.controls.license_plate.value;
    if (license_plate) {
      let stateName = this.addVehicleForm.controls.state.value;
      let payload = "user_type=" + this.loginUserDetails.user_type + "&user_id=" + this.loginUserDetails.shop_id  + "&customer_id=" + this.customerInfo.id;
      let carState = this.states.filter(state => state.state_name == stateName);
      if (carState.length > 0) {
        payload = payload + "&state_name=" + carState[0].state_name + "&state=" + carState[0].state_code + "&partstech_check=1";
      } else {
        payload = payload + "&state=&partstech_check=1";
      }
      if(VINFindState){
        payload = payload+ "&vin=" + this.addVehicleForm.controls.vin.value;
      }else{
        payload = payload+ "&license_plate=" + license_plate
      }
      this.globalService.callAPI(globalConstants.API_CHECK_VEHICLE_INFO, payload).subscribe((data: any) => {
        if (data.result == "1") {
          let vehicleInfo = data.data;
          if ('code' in data && data.code == '500' && 'vehicle_info' in data.data) {
            if (vehicleInfo.vehicle_info.customer_id == this.customerInfo.id) {
              let info= this.checkVehicleExit(vehicleInfo.vehicle_info);
              if(info.status == true){
                this.selectVehicles(info.vehicleInfo);
                this.showAddVehicleSection = false;
                this.reSetAddVehicleForm()
              }

            } else {
              let info= this.checkVehicleExit(vehicleInfo.vehicle_info);
              if(info.status == true){
                this.selectVehicles(info.vehicleInfo);
                this.showAddVehicleSection = false;
                this.reSetAddVehicleForm()
              }else{
                let obj = {
                  vehicle_pic: "",
                  id: vehicleInfo.vehicle_info.vehicle_id,
                  make: typeof (vehicleInfo.vehicle_info.make) !== 'undefined' ? vehicleInfo.vehicle_info.make : '',
                  year: typeof (vehicleInfo.vehicle_info.year) !== 'undefined' ? vehicleInfo.vehicle_info.year : '',
                  model: typeof (vehicleInfo.vehicle_info.model) !== 'undefined' ? vehicleInfo.vehicle_info.model : '',
                  ownerName: vehicleInfo.vehicle_info.customer_name,
                  ownerStatus: true,
                  license_plate: vehicleInfo.vehicle_info.license_plate
                }
                this.customerVehicles.push(obj);
                this.selectVehicles(obj);
                this.showAddVehicleSection = false;
                this.reSetAddVehicleForm()
              }
            }
          } else {
            this.fillLicensePlateData(vehicleInfo);
          }
        } else {
          this.globalService.snackbar("error", data.message);
        }
      });
    }
  }


  checkVehicleExit(vehicleInfo) {
    var status = false;
    for (var i in this.customerVehicles) {
      if (vehicleInfo.license_plate == this.customerVehicles[i].license_plate) {
        return {
          status: true,
          vehicleInfo: this.customerVehicles[i]
        }
      } else {
        status = true;
      }
    }
    return {
      status : false
    }
  }

  fillLicensePlateData(vehicleInfo) {
    this.showMakeTxtFld  = true;
    this.showYearTxtFld = true;
    this.showModelTxtFld = true;
    this.showYearAndModel = false;
    this.showModel = false;
    Object.entries(vehicleInfo).forEach(([key, val]) => {
      if (key == 'license_plate') {
        this.addVehicleForm.controls.license_plate.setValue(val);
      }
      else if (key == 'make') {
        this.addVehicleForm.controls.make.setValue(val);
      }
      else if (key == 'model') {
        this.addVehicleForm.controls.model.setValue(val);
      } else if (key == 'vin') {
        this.addVehicleForm.controls.vin.setValue(val);
      } else if (key == 'year') {
        this.addVehicleForm.controls.year.setValue(val);
      } else if (key == 'engine_size') {
        this.addVehicleForm.controls.sub_model.setValue(val);
      } 
    });
    this.disableYear = true;
    this.disableModel = true;
    if (vehicleInfo.year != "" && typeof vehicleInfo.year != 'undefined') {
      let carProductionDate = "01/" + vehicleInfo.year;
      this.addVehicleForm.controls.production_date.setValue(carProductionDate);
    } else {
      this.addVehicleForm.controls.production_date.setValue('');
    }
  }

  useExitingMake(status) {
    this.showMakeTxtFld  = status;
    this.showYearTxtFld = status;
    this.showModelTxtFld = status;
    this.showYearAndModel = !this.showYearAndModel;
    this.showModel   = !this.showModel;
    this.disableYear = true;
  }

  useExitingYear(status){
     this.showYearTxtFld = status;
     this.showModelTxtFld = status;
     this.showModel   = !this.showModel;
  }

  useExitingModel(status){
      this.showModelTxtFld = status;
  }

  reSetAddVehicleForm(){
    this.addVehicleForm.reset();
    this.showMakeTxtFld  = false;
    this.showYearTxtFld = false;
    this.showModelTxtFld = false;
    this.showYearAndModel = true;
    this.showModel = true;
    this.disableModel = true;
    this.disableYear = true;
    this.addVehicleForm.controls.user_type.setValue('User');
    this.addVehicleForm.controls.user_id.setValue(this.shopDetail.id);
    this.addVehicleForm.controls.make.setValue('');
    this.addVehicleForm.controls.year.setValue('');
    this.addVehicleForm.controls.model.setValue('');
    this.showCommonErrorMessage = 0;
    this.defaultState = '';
    this.setDefaultState();
  }
  AddNewVehicles() {
  
    this.addVehicleForm.controls.customer_id.setValue(this.customerInfo.id);
    if ((this.addVehicleForm.value.license_plate && this.addVehicleForm.value.state) || (this.addVehicleForm.value.make && this.addVehicleForm.value.year && this.addVehicleForm.value.model && this.addVehicleForm.valid)) {
      var formData = new FormData();
      for (var i in this.addVehicleForm.value) {
        if (this.addVehicleForm.value[i] == null) {
          formData.append(i, '');
        } else {
          formData.append(i, this.addVehicleForm.value[i]);
        }
      }
      this.globalService.formData(globalConstants.API_SELF_CHECKIN_ADD_VEHICLE, formData).subscribe(data => {
        var message = data.message;
        if (data.result == "1") {
          this.globalService.snackbar('success', message);
          this.customerVehicles.push(data.data);
          this.selectVehicles(data.data);
          this.showAddVehicleSection = false;
          this.reSetAddVehicleForm();
        } else {
          if (data.code == "311" || data.code == "310" ) {
            this.checkLicense(true);
          }else if (data.code == "312" || data.code == "313" ) {
            this.checkLicense();
        }
        }
      })
    } else {
      this.showCommonErrorMessage = 3
    }

  }

  setDefaultState() {
    var stateName: any = "";
    stateName = (this.customerInfo.state == "" || typeof (this.customerInfo.state) == 'undefined') ? this.shopDetail.state : this.customerInfo.state;
    if (this.shopState && stateName == '') {
      stateName = this.shopState;
    }
    if (stateName) {
      const carState = this.states.filter(state => state.state_name == stateName);
      if (carState.length > 0) {
        this.defaultState = carState[0].state_id;
        this.addVehicleForm.controls.state.setValue(carState[0].state_name);
      } else {
        this.defaultState = '';
        this.addVehicleForm.controls.state.setValue('');
      }
    }
  }

  handleSelectedState(opt) {
    const carState = this.states.filter(state => state.state_id == opt);
    this.addVehicleForm.controls.state.setValue(carState[0].state_name);
    this.checkLicense();
  }

  getVehicleMakes() {
    this.globalService.callAPI(globalConstants.API_GET_CAR_MAKES, "opr=1").subscribe((data: any) => {
      this.vehicleMakes = data.data;
      /* FOR SEARCH BOX DROPDOWN */
      var makelist = [
        {
           id:'',
           text: "Select Make"
        }
      ];
      for (var i in this.vehicleMakes) {
        this.vehicleMakes[i].id = this.vehicleMakes[i].make;
        this.vehicleMakes[i].text = this.vehicleMakes[i].make;
        makelist.push(this.vehicleMakes[i]);
      }
      this.vehicleMakes = makelist;
    })
  }

  handleSelectedMakes(opt: any) {
    if (opt !== '') {
      this.getVehicleYears(opt);
      this.disableYear = false;
      this.disableModel = true;
      this.addVehicleForm.controls.make.setValue(opt);
      this.addVehicleForm.controls.year.setValue('');
      this.addVehicleForm.controls.model.setValue('');
    } else {
      this.disableYear = true;
      this.disableModel = true;
      this.addVehicleForm.controls.year.setValue('');
      this.addVehicleForm.controls.model.setValue('');
    }
  }

  getVehicleYears(opt) {
    this.globalService.callAPI(globalConstants.API_GET_YEAR_LIST, "opr=1&make=" + opt).subscribe((data: any) => {
      if (data.result == "1") {
        this.addVehicleForm.controls.year.enable();
        var yearsList = [
          {
            id:'',
            text: "Select Year"
         }
        ];
        if (data.data.length != undefined) {
          this.vehicleYears = [];
          this.vehicleYears = data.data;
          /* FOR SEARCH BOX DROPDOWN SELECT */
          for (var i in this.vehicleYears) {
            this.vehicleYears[i].id = this.vehicleYears[i].year;
            this.vehicleYears[i].text = this.vehicleYears[i].year;
            yearsList.push(this.vehicleYears[i]);
          }
          this.vehicleYears = yearsList;
          /* END FOR V2 SELECT */
        } else {
          this.vehicleYears = [];
          this.vehicleYears[0] = data.data;
          /* FOR SEARCH BOX DROPDOWN SELECT */
          this.vehicleYears.id = this.vehicleYears.year;
          this.vehicleYears.text = this.vehicleYears.year;
          yearsList.push(this.vehicleYears);
          this.vehicleYears = yearsList;
          /* END FOR V2 SELECT */
        }
      } else {
        this.addVehicleForm.controls.year.disable();
      }
    })
  }

  handleSelectedYear(opt: any) {
    if(opt !== ''){
       this.addVehicleForm.controls.model.setValue('');
       this.addVehicleForm.controls.year.setValue(opt);
       this.getVehicleModels(opt);
    }else{
      this.disableModel = true;
      this.addVehicleForm.controls.model.setValue('');
    }
  }

  getVehicleModels(event) {
    if (event == undefined || event == "") {
      this.vehicleModels = [];
      this.addVehicleForm.controls.model.reset();
      this.disableModel = false;
    } else {
      this.addVehicleForm.controls.model.reset();
      this.disableModel = false;
      this.globalService.callAPI(globalConstants.API_GET_MODELS_LIST_URL, "opr=1&make=" + this.addVehicleForm.value.make + "&year=" + event).subscribe((data: any) => {
        if (data.result == "1") {
          var modelsList = [
            {
              id:'',
              text: "Select Model"
           }
          ];
          if (data.data.length == undefined) {
            this.vehicleModels = [];
            this.vehicleModels[0] = data.data;
            /* FOR SEARCH BOX DROPDOWN SELECT */
            this.vehicleModels.id = this.vehicleModels.model + (this.vehicleModels.trim ? ' ' + this.vehicleModels.trim : '') + (this.vehicleModels.body_style ? ' ' + this.vehicleModels.body_style : '')
            this.vehicleModels.text = this.vehicleModels.model + (this.vehicleModels.trim ? ' ' + this.vehicleModels.trim : '') + (this.vehicleModels.body_style ? ' ' + this.vehicleModels.body_style : '')
            modelsList.push(this.vehicleModels);
            this.vehicleModels = modelsList;
            /* END FOR V2 SELECT */
          } else {
            this.vehicleModels = [];
            this.vehicleModels = data.data;
            /* FOR SEARCH BOX DROPDOWN SELECT */
            for (var i in this.vehicleModels) {
              this.vehicleModels[i].id = this.vehicleModels[i].model + (this.vehicleModels[i].trim ? ' ' + this.vehicleModels[i].trim : '') + (this.vehicleModels[i].body_style ? ' ' + this.vehicleModels[i].body_style : '')
              this.vehicleModels[i].text = this.vehicleModels[i].model + (this.vehicleModels[i].trim ? ' ' + this.vehicleModels[i].trim : '') + (this.vehicleModels[i].body_style ? ' ' + this.vehicleModels[i].body_style : '')
              modelsList.push(this.vehicleModels[i]);
            }
            this.vehicleModels = modelsList;
            /* END FOR V2 SELECT */
          }
        } else {
          this.addVehicleForm.controls.model.disable();
        }
      });
    }
  }

  getSelectedOptionModels(opt: any) {
    this.addVehicleForm.controls.model.setValue(opt);
  }

}
