<div class="panel panel-primary">
  <div class="panel-heading padding-left-30">
    <h3 class="panel-title">Service Request Detail</h3>
    <button mat-mini-fab class="custom-btn-back" (click)="globalService.goBack();">
      <mat-icon>arrow_back</mat-icon>
    </button>
  </div>
  <div class="panel-body bgcolor-lightgrey">
    <div class="jumbotron padding-25 car-view-str">
      <div class="row">
        <div class="col-sm-6 col-md-6">
          <h3 class="margin-0">Service Request Information</h3>
        </div>
        <div class="col-sm-6 col-md-6" *ngIf="isAllowed('views','invoices')">
          <a class="btn btn-success pull-right " id="downloadInvoice" *ngIf="(serviceInfo.value.request_status=='6' || serviceInfo.value.request_status=='8') && showDownloadOrPrice" name="downloadInvoice" (click)="getInvoicePdf()" href="javascript:void(0);" title="">
            <i class="fa fa-download"></i>  Download Invoice
          </a>
        </div>
      </div>
      <hr>

      <form id="viewServiceRequestForm" class="form-horizontal" role="form">
        <!-- Replaced div grid structure with table -->
        <div class="row">
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Request ID:</label></div>
              <div class="col-sm-6">{{this.serviceInfo.value.request_id}}</div>
            </div>
          </div>
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Shop Name :</label></div>
              <div class="col-sm-6 break-word">
                {{this.serviceInfo.value.service_facility_name || this.serviceInfo.value.company_name}}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Vehicle :&nbsp;&nbsp;<span class="tooltip-status" (click)="detailTab(1)"><i class="fa fa-info-circle"></i></span></label></div>
              <div class="col-sm-6">
                <!-- {{this.serviceInfo.value.year}}/{{this.serviceInfo.value.make}}/{{this.serviceInfo.value.model}} -->
                <span *ngIf="vehicle_pic ==null || vehicle_pic ==undefined || vehicle_pic.length == 0">
                  <span *ngIf="this.serviceInfo.value.year !=undefined && this.serviceInfo.value.make !=undefined && this.serviceInfo.value.model !=undefined">
                    {{vehicleName}}
                  </span>
                </span>
                <div *ngIf="vehicle_pic !=null && vehicle_pic !=undefined && vehicle_pic.length != 0">
                <span class="tooltip-status" [tooltip]="myTooltip" (click)="openImageDialog(1);">{{this.serviceInfo.value.year}}/{{this.serviceInfo.value.make}}/{{this.serviceInfo.value.model}}</span>
                <tooltip-content #myTooltip class="image-tooltip" placement="bottom">
                    <img src="{{globalConstants.S3_BUCKET_URL}}{{globalConstants.VEHICLE_PICTURES}}{{globalConstants.IMG_ORIGINAL}}{{vehicle_pic}}" class="img-responsive">
                </tooltip-content>
                </div>
                <p id="expMsgs" class="text-danger" style="font-size:11px;margin-top: 5px;margin-bottom: 0;">
                  <span *ngIf="chkDate(serviceInfo.value.insurance_expiration_date)">Vehicle Insurance Expired -{{serviceInfo.value.insurance_expiration_date}}<br></span>
                  <span *ngIf="chkDate(serviceInfo.value.registration_expiration_date)">Vehicle Registration Expired - {{serviceInfo.value.registration_expiration_date}}<br></span>
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>License Plate :</label></div>
              <div class="col-sm-6 break-word text-uppercase">
                {{this.serviceInfo.value.license_plate}}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Title :</label></div>
              <div class="col-sm-6">
                {{this.serviceInfo.value.title}}
              </div>
            </div>
          </div>
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>VIN Number :&nbsp;&nbsp;<span class="tooltip-status" (click)="copyText()"><i class="fa fa-copy"></i></span></label></div>
               <!-- *ngIf="this.serviceInfo.value.vin!=undefined" -->
              <div class="col-sm-6 break-word text-uppercase">
                <!-- {{this.serviceInfo.value.vin.substr(0,8)}}<strong style="color:#eb1e4c">{{this.serviceInfo.value.vin.substr(9)}}</strong> -->
                <span *ngIf="vin_photo ==null || vin_photo ==undefined || vin_photo.length == 0">
                  <span *ngIf="serviceInfo.value.vin">
                    {{serviceInfo.value.vin | slice:0:-8}}<span style="color:#eb1e4c">{{serviceInfo.value.vin.substr(serviceInfo.value.vin.length - 8)}}</span>
                  </span>
                </span>
                <div *ngIf="vin_photo !=null && vin_photo !=undefined && vin_photo.length != 0">
                    <span class="text-uppercase tooltip-status" [tooltip]="myTooltip1" (click)="openImageDialog(5);"  *ngIf="this.serviceInfo.value.vin!=null" style="display: inline-block;">
                      <span *ngIf="serviceInfo.value.vin">
                        {{serviceInfo.value.vin | slice:0:-8}}<span style="color:#eb1e4c">{{serviceInfo.value.vin.substr(serviceInfo.value.vin.length - 8)}}</span>
                      </span>
                  </span>
                    <tooltip-content #myTooltip1  class="image-tooltip" placement="bottom">
                      <img src="{{globalConstants.S3_BUCKET_URL}}{{globalConstants.VEHICLE_VIN_PIC}}{{globalConstants.IMG_ORIGINAL}}{{vin_photo}}" class="img-responsive">
                    </tooltip-content>
                </div>
              </div>
              <!-- <div class="col-sm-6 break-word text-uppercase" *ngIf="this.serviceInfo.value.vin==undefined">
                "--"
              </div> -->
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Customer Name :&nbsp;&nbsp;<span class="tooltip-status" (click)="detailTab(2)"><i class="fa fa-info-circle"></i></span></label></div>
              <div class="col-sm-6">
                {{this.serviceInfo.value.first_name}} {{this.serviceInfo.value.last_name}}
              </div>
            </div>
          </div>
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Requested Date :</label></div>
              <div class="col-sm-6 break-word">
                {{this.serviceInfo.value.requested_delivery_date}}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Mobile Number :</label></div>
              <div class="col-sm-6">
                <a class="text-black" *ngIf="isAllowed('views','view_customer_info')" href="tel:{{this.serviceInfo.value.mobile_phone}}">{{this.serviceInfo.value.mobile_phone}}</a>
                <span *ngIf="isAllowed('views','view_customer_info')==false">-</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Scheduled Date :</label></div>
              <div class="col-sm-6">
                {{this.serviceInfo.value.schedule_service_date}}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Present Mileage (Miles) :</label></div>
              <div class="col-sm-6">
                {{this.serviceInfo.value.present_mileage}}
              </div>
            </div>
          </div>
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Estimated Date :</label></div>
              <div class="col-sm-6">
                <span *ngIf="serviceInfo.value.estimated_delivery_date!='0000-00-00 00:00:00'">  {{this.serviceInfo.value.estimated_delivery_date}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 margin-bottom-10">
            <div class="row">
              <div class="col-sm-3"><label>Notes :</label></div>
              <div class="col-sm-9 break-all" [innerHtml]="getNltoBr(serviceInfo.value.notes)">
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 margin-bottom-10">
            <div class="row">
              <div class="col-sm-3"><label>Download Report :</label></div>
              <div class="col-sm-9">
                  <span id="downloadReportUPSP" *ngIf="reportDownloadType==1" >
                      <a href="javascript:void(0)" (click)="invokeDownload(reportDownloadLink,'CARInspectionReport')">(USPS-PMI-PS-Form)<i class="fa fa-download fa-fw "></i></a>
                    </span>
                    <span id="downloadReportACDELCO" *ngIf="reportDownloadType==2">
                      <a href="javascript:void(0)" (click)="invokeDownload(reportDownloadLink,'CARInspectionReport')">(CAR Inspection Report)<i class="fa fa-download fa-fw "></i></a>
                    </span>
                    <span id="noReportPerformed" *ngIf="reportDownloadType==0">
                      No Report Performed
                    </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 notifyToAdminContainer">

          </div>
        </div>
        <div class="row">
          <div class="col-sm-12" *ngIf="serviceInfo.value.request_documents!=null || serviceInfo.value.request_images!=null">
            <h4><strong>Documents &amp; Images</strong></h4>
            <div class="row">
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-12 margin-bottom-5"><label>Document(s)</label></div>
                  <div class="col-sm-12 text-danger">
                    <div id="serviceRequestDocumentsView">
                      <div id="serviceRequestDocumentsView" style="display:inline-block;margin:0px 5px 5px 0px;position:relative;" *ngFor="let doc of serviceInfo.value.request_documents">
                          <a href="javascript:void(0)" (click)="invokeDownload(globalConstants.S3_BUCKET_URL+'Service-Request-Documents/'+doc.document_name,'ServiceRequestDocument',doc.document_name)" class="thumbnail">
                            <!-- <img style="width:80px;height:75px;" src="assets/img/adobe_pdf-logo.jpg"> -->
                            <span class="fa far fa-file-pdf fa-4x" *ngIf="getFileIcons(doc.document_name)==3"></span>
                            <span class="fa far fa-file-word fa-4x" *ngIf="getFileIcons(doc.document_name)==4"></span>
                          </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6" *ngIf="serviceInfo.value.request_documents.length == 0 || serviceInfo.value.request_documents==null">
                <div class="text-danger"> No documents uploaded. </div>
               </div>
              <div class="col-sm-6" *ngIf="serviceInfo.value.request_images!=null">
                <div class="row">
                  <div class="col-sm-12 margin-bottom-5"><label>Image(s)</label></div>
                  <div class="col-sm-12" *ngIf="serviceInfo.value.request_images!=null">
                    <ul class="list-inline margin-bottom-0" >
                      <li  class="relative view-images" *ngFor="let image of serviceInfo.value.request_images;let i=index;">
                          <a (click)="imageToolDialog($event,i,8,serviceInfo.value.request_images)" class="thumbnail" href="javascript:void(0);">
                              <img style="width:100px;height:75px;" src="{{reqImagePath}}{{image.image_name}}">
                          </a>
                      </li>
                  </ul>
                  </div>

                </div>
              </div>

               <div class="col-sm-6" *ngIf="serviceInfo.value.request_images.length == 0 || serviceInfo.value.request_images==null">
               <div class="text-danger">
                No images uploaded.
              </div>
               </div>
            </div>
          </div>
        </div>

        <!-- DISPLAY REQUIRE APPROVAL -->
        <div class="display-full alert alert-info" *ngIf="(authorizeBy!='' && authorizeBy!=null && authorizeBy!='null')">
            <strong class="display-full margin-bottom-10">How was authorization obtained?</strong>
            <div *ngIf="authorizeBy!='Other'">
                <div class="row margin-bottom-10">
                  <div class="col-md-4 col-sm-6 col-lg-3"><span>Authorization obtained :</span></div>
                  <div class="col-md-8 col-sm-6 col-lg-9">{{authorizeBy}}</div>
                </div>

                <div class="row margin-bottom-10">
                  <div class="col-md-4 col-sm-6 col-lg-3"><span>Who was the person?</span></div>
                  <div class="col-md-8 col-sm-6 col-lg-9">{{contactPerson}}</div>
                </div>

                <div class="row margin-bottom-10">
                  <div class="col-md-4 col-sm-6 col-lg-3"><span>Phone number :</span></div>
                  <div class="col-md-8 col-sm-6 col-lg-9">{{contactPhoneNumber}}</div>
                </div>

                <div class="row margin-bottom-10">
                  <div class="col-md-4 col-sm-6 col-lg-3"><span>Date Time of call :</span></div>
                  <div class="col-md-8 col-sm-6 col-lg-9">{{dateTimeOfCall}}</div>
                </div>
            </div>

             <div *ngIf="authorizeBy=='Other'">
                <div class="row margin-bottom-10">
                  <div class="col-md-4 col-sm-6 col-lg-3"><span>Authorization obtained :</span></div>
                  <div class="col-md-8 col-sm-6 col-lg-9">{{authorizeBy}}</div>
                </div>

                <div class="row margin-bottom-10">
                  <!--<div class="col-sm-4"><label>Who was the person?</label></div>-->
                  <div class="col-md-12">{{otherMeans}}</div>
                </div>
            </div>
        </div>
        <!-- END -->
      </form>
    </div>


    <mat-accordion class="new-request-expension view-request show-price margin-bottom-10 display-full" displayMode="flat" hideToggle="false">
      <mat-expansion-panel hideToggle="true" [expanded]="a==0" *ngFor="let service_request of serviceInfo.value.service_request_list;let a=index" id="expansion-{{a+1}}">
        <mat-expansion-panel-header  collapsedHeight="42px" expandedHeight="42px" id="expansionHead-{{a+1}}" (click)="expansionOpen(a+1);"  [attr.data-state]="a==0">
          {{a+1}}. {{ service_request.name }}
        </mat-expansion-panel-header>
        <ul class="service-list-items">
          <li class="topPad" *ngFor="let servicerequestChild of service_request.children;let i = index">
            <p>{{i+1}}) {{servicerequestChild.name}}</p>
            <ul class="service-list-sub-items no-style" >
              <li class="topPad" *ngIf="serviceInfo.value.recommendation_saved == '1' ">
                <div class="row">
                  <div *ngFor="let recommendation of servicerequestChild.recommendations;let j = index">
                    <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.parts.length > 0">
                      <div class="form-group">
                        <label class="col-sm-3 col-lg-2 text-right control-label">Part(s):</label>
                        <div class="col-sm-9 text-justify">
                          <div class="addedPartsItems">
                            <div class="partRow row" [class.text-orange]="part.partstech_cart_item==1 || part.partstech_order_item==1" style="padding-top:3px;" *ngFor="let part of recommendation.parts;let k = index">
                              <div class="col-sm-8 pname">
                                <span *ngIf="part.part_number!=''">{{part.part_number}} - </span>
                                <span *ngIf="showDownloadOrPrice && (showCostPrice || (part.partstech_cart_item==1 || part.partstech_order_item==1))"> (${{part.cost}}) -</span> {{part.name}}
                                <span *ngIf="part.description!=''"> - {{part.description}}</span></div>
                              <div class="col-sm-2 pprice" ><span *ngIf="showCostPrice || (part.partstech_cart_item==1 || part.partstech_order_item==1)" ><span *ngIf="showDownloadOrPrice">$ {{parseFloats(part.part_price)}}</span></span></div>
                              <div class="col-sm-2 pquantity">{{part.quantity}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.generic_jobs.length > 0">
                      <div class="form-group">
                        <label class="col-sm-3 col-lg-2 text-right control-label">Generic Job(s):</label>
                        <div class="col-sm-9 text-justify">
                          <div class="addedGenericJobs">
                            <div class="partRow row" style="padding-top:3px;" *ngFor="let gjob of recommendation.generic_jobs;let l = index">
                              <div class="col-sm-8 pname">{{gjob.name}}</div>
                              <div class="col-sm-2 pprice " ><span *ngIf="showDownloadOrPrice && showCostPrice">$ {{parseFloats(gjob.price)}}</span></div>
                              <div class="col-sm-2 ppqty">{{gjob.quantity}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.computer_codes.length > 0">
                      <div class="form-group">
                        <label class="col-sm-3 col-lg-2 text-right control-label">Computer Codes(s):</label>
                        <div class="col-sm-9 col-lg-10 text-justify">
                          <div class="addedGenericJobs">
                            <div class="partRow row" style="padding-top:3px;" *ngFor="let cjob of recommendation.computer_codes;let l = index">
                              <div class="col-sm-12 pname">{{cjob.trouble_code}} {{cjob.fault_location}}</div>
                             </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 margin-bottom-15" *ngIf="currentShopId == serviceInfo.value.shop_id">
                      <div class="form-group" *ngIf="showCostPrice">
                        <label class="col-sm-3 col-lg-2 text-right control-label">Estimated Labor:</label>
                        <div class="col-sm-9 text-justify">
                          <div class="addedComputerCodes">$ {{ parseFloats(recommendation.estimated_labor)}}</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 margin-bottom-15" >
                      <div class="form-group">
                        <label class="col-sm-3 col-lg-2 text-right control-label">Estimated Time:</label>
                        <div class="col-sm-9 text-justify">
                          <div class="addedComputerCodes">{{recommendation.estimated_time}} hour(s) </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.service_request_list_id!=700">
                      <div class="form-group">
                        <label class="col-sm-3 col-lg-2 text-right control-label">Description of Work:</label>
                        <div class="col-sm-9 text-justify break-all">
                          <p [innerHtml]="getNltoBr(recommendation.recommendation)"></p>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.service_request_list_id!=700">
                      <div class="form-group">
                        <label class="col-sm-3 col-lg-2 text-right control-label">Notes:</label>
                        <div class="col-sm-9 text-justify break-all">
                          <p [innerHtml]="getNltoBr(recommendation.notes)"></p>
                        </div>
                      </div>
                    </div>
                       <div class="col-sm-12 margin-bottom-15 already-uploaded-images" *ngIf="recommendation.service_request_list_id!=700">
                        <div class="form-group">
                          <label class="col-sm-3 col-lg-2 text-right control-label"><b>Uploaded Image(s):</b></label>
                          <div class="col-sm-9 text-justify">
                          <ul class="list-inline margin-bottom-0" *ngIf="recommendation.uploaded_images.length > 0">
                              <li  class="relative view-images" *ngFor="let image of recommendation.uploaded_images;let i=index;">
                                  <a (click)="imageToolDialog($event,i,4,recommendation.uploaded_images)" class="thumbnail" href="javascript:void(0);">
                                    <img class="show-image" src="{{recoImagePath}}{{image.filename}}">
                                  </a>
                              </li>
                          </ul>
                          </div>
                          <div class="col-sm-9 text-justify" *ngIf="recommendation.uploaded_images.length == 0">
                            <span class="text-danger">No images uploaded.

                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 margin-bottom-15 already-uploaded-videos" *ngIf="recommendation.service_request_list_id!=700">
                        <div class="form-group">
                          <label class="col-sm-3 col-lg-2 text-right control-label"><b>Uploaded Video(s):</b></label>
                          <div class="col-sm-9 text-justify" *ngIf="recommendation.uploaded_videos.length > 0">
                            <ul id="vehicle_video-ul" class="list-inline margin-bottom-0">
                              <li class="video-upload relative" *ngFor="let video of recommendation.uploaded_videos;let i=index">
                                <a class="thumbnail margin-bottom-0" href="javascript:void(0)" (click)="videoShow(video.filename)">
                                  <span class="relative video-bg">
                                    <mat-icon class="position-center play-video">play_circle_filled</mat-icon>
                                    <div class="image-loader" *ngIf="video.loader">
                                      <mat-progress-spinner color="accent" mode="indeterminate" strokeWidth="2" diameter="30"></mat-progress-spinner>
                                    </div>
                                  </span>
                                </a>
                                <!-- <i class="fa fa-trash remove-img" (click)="imgDelete(video.id,'vehicle_video',i)"></i> -->
                              </li>
                            </ul>
                          </div>

                          <div class="col-sm-9 text-justify" *ngIf="recommendation.uploaded_videos.length == 0">
                            <span class="text-danger">No videos uploaded.
                              <span></span>
                            </span>
                          </div>
                        </div>
                      </div>
                    <div class="col-xs-12" *ngIf="recommendation.service_request_list_id!=700">


                      <div class="display-full text-right" >
                        <mat-button-toggle value="Accepted" class="toogle-btn btn-success" *ngIf="recommendation.approval_status==1" >Accepted</mat-button-toggle>
                        <mat-button-toggle value="Rejected" class="toogle-btn btn-danger" *ngIf="recommendation.approval_status==2" >Rejected</mat-button-toggle>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="topPad" *ngFor="let secondChild of servicerequestChild.children;let j = index">

                {{secondChild.name}}

                <p class="service-list-sub-items" *ngIf="secondChild.input == 'textarea'">
                  <span style="padding-left: 58px;" class="display-full" [innerHtml]="getNltoBr(secondChild.value)">
                  </span>
                </p>
                <p class="service-list-sub-items" *ngIf="secondChild.input == 'imageupload'">
                  <span style="padding-left: 58px;">
                    <img src="{{path}}{{secondChild.value}} "/>
                  </span>
                </p>

                <div class="row" *ngIf="serviceInfo.value.recommendation_saved == '1'">
                  <div *ngFor="let recommendation of secondChild.recommendations;let j = index">
                    <div *ngIf="recommendation.service_request_list_id!='158'">
                      <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.parts.length > 0 ">
                        <div class="form-group">
                          <label class="col-sm-3 col-lg-2 text-right control-label">Part(s):</label>
                          <div class="col-sm-9 text-justify">
                            <div class="addedPartsItems">
                              <div class="partRow row" [class.text-orange]="part.partstech_cart_item==1 || part.partstech_order_item==1" style="padding-top:3px;" *ngFor="let part of recommendation.parts;let k = index">
                                <div class="col-sm-8 pname">
                                  <span *ngIf="part.part_number!=''">{{part.part_number}} - </span>
                                  <span *ngIf="currentShopId == serviceInfo.value.shop_id && (showCostPrice || (part.partstech_cart_item==1 || part.partstech_order_item==1))"> (${{part.cost}}) -</span> {{part.name}}
                                  <span *ngIf="part.description!=''"> - {{part.description}}</span>
                                </div>
                                <div class="col-sm-2 pprice " ><span *ngIf="showCostPrice || (part.partstech_cart_item==1 || part.partstech_order_item==1)" ><span *ngIf="showDownloadOrPrice">$ {{ parseFloats(part.part_price)}}</span></span></div>
                                <div class="col-sm-2 pquantity">{{part.quantity}}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.generic_jobs.length > 0">
                        <div class="form-group">
                          <label class="col-sm-3 col-lg-2 text-right control-label">Generic Job(s):</label>
                          <div class="col-sm-9 text-justify">
                            <div class="addedGenericJobs">
                              <div class="partRow row" style="padding-top:3px;" *ngFor="let gjob of recommendation.generic_jobs;let l = index">
                                <div class="col-sm-8 pname">{{gjob.name}}</div>
                                <div class="col-sm-2 pprice "><span *ngIf="currentShopId == serviceInfo.value.shop_id && showCostPrice">$ {{ parseFloats(gjob.price)}}</span></div>
                                <div class="col-sm-2 ppqty">{{gjob.quantity}}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.computer_codes.length > 0">
                        <div class="form-group">
                          <label class="col-sm-3 col-lg-2 text-right control-label">Computer Codes(s):</label>
                          <div class="col-sm-9 col-lg-10 text-justify">
                            <div class="addedGenericJobs">
                              <div class="partRow row" style="padding-top:3px;" *ngFor="let cjob of recommendation.computer_codes;let l = index">
                                <div class="col-sm-12 pname">{{cjob.trouble_code}} {{cjob.fault_location}}</div>
                               </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 margin-bottom-15" *ngIf="currentShopId == serviceInfo.value.shop_id" >
                        <div class="form-group" *ngIf="showCostPrice">
                          <label class="col-sm-3 col-lg-2 text-right control-label" >Estimated Labor:</label>
                          <div class="col-sm-9 text-justify">
                            <div class="addedComputerCodes">$ {{ parseFloats(recommendation.estimated_labor)}}</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 margin-bottom-15">
                        <div class="form-group">
                          <label class="col-sm-3 col-lg-2 text-right control-label">Estimated Time:</label>
                          <div class="col-sm-9 text-justify">
                            <div class="addedComputerCodes">{{recommendation.estimated_time}} hour(s) </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 margin-bottom-15">
                        <div class="form-group">
                          <label class="col-sm-3 col-lg-2 text-right control-label">Description of Work:</label>
                          <div class="col-sm-9 text-justify break-all">
                            <p [innerHtml]="getNltoBr(recommendation.recommendation)"></p>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 margin-bottom-15">
                        <div class="form-group">
                          <label class="col-sm-3 col-lg-2 text-right control-label">Notes:</label>
                          <div class="col-sm-9 text-justify break-all">
                            <p [innerHtml]="getNltoBr(recommendation.notes)"></p>
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-12 margin-bottom-15 already-uploaded-images">
                        <div class="form-group">
                          <label class="col-sm-3 col-lg-2 text-right control-label"><b>Uploaded Image(s):</b></label>
                          <div class="col-sm-9 text-justify">
                          <ul class="list-inline margin-bottom-0" *ngIf="recommendation.uploaded_images.length > 0">
                              <li  class="relative view-images" *ngFor="let image of recommendation.uploaded_images;let i=index;">
                                  <a (click)="imageToolDialog($event,i,4,recommendation.uploaded_images)" class="thumbnail" href="javascript:void(0);">
                                    <img class="show-image" src="{{recoImagePath}}{{image.filename}}">
                                  </a>
                              </li>
                          </ul>
                          </div>
                          <div class="col-sm-9 text-justify" *ngIf="recommendation.uploaded_images.length == 0">
                            <span class="text-danger">No images uploaded.

                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 margin-bottom-15 already-uploaded-videos">
                        <div class="form-group">
                          <label class="col-sm-3 col-lg-2 text-right control-label"><b>Uploaded Video(s):</b></label>
                          <div class="col-sm-9 text-justify" *ngIf="recommendation.uploaded_videos.length > 0">
                            <ul id="vehicle_video-ul" class="list-inline margin-bottom-0">
                              <li class="video-upload relative" *ngFor="let video of recommendation.uploaded_videos;let i=index">
                                <a class="thumbnail margin-bottom-0" href="javascript:void(0)" (click)="videoShow(video.filename)">
                                  <span class="relative video-bg">
                                    <mat-icon class="position-center play-video">play_circle_filled</mat-icon>
                                    <div class="image-loader" *ngIf="video.loader">
                                      <mat-progress-spinner color="accent" mode="indeterminate" strokeWidth="2" diameter="30"></mat-progress-spinner>
                                    </div>
                                  </span>
                                </a>
                                <!-- <i class="fa fa-trash remove-img" (click)="imgDelete(video.id,'vehicle_video',i)"></i> -->
                              </li>
                            </ul>
                          </div>

                          <div class="col-sm-9 text-justify" *ngIf="recommendation.uploaded_videos.length == 0">
                            <span class="text-danger">No videos uploaded.
                              <span></span>
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="col-xs-12">


                         <div class="display-full text-right" >
                        <mat-button-toggle value="Accepted" class="toogle-btn btn-success" *ngIf="recommendation.approval_status==1">Accepted</mat-button-toggle>
                        <mat-button-toggle value="Rejected" class="toogle-btn btn-danger" *ngIf="recommendation.approval_status==2" >Rejected</mat-button-toggle>
                         </div>

                      </div>
                    </div>
                </div>
              </div>

            </li>
          </ul>
        </li>
      </ul>

      <div class="panel-price" *ngIf="ConvertToNumber(serviceInfo.value.request_status) >=3 && ConvertToNumber(serviceInfo.value.request_status) <9 && showCostPrice">
        <div class="panel-footer rejectedJobs col-sm-6" role="tab" *ngIf="showDownloadOrPrice">
          <h4 class="text-right" style="margin:0;"><span>Rejected Job(s) Cost&nbsp;:&nbsp;$</span>
            <span class="total-job-price-63">
              {{ this.ObjectJson[service_request.id]["RejectedValue"] }}
            </span>
          </h4>
        </div>
        <div class="panel-footer acceptedJobs col-sm-6" role="tab" *ngIf="showDownloadOrPrice">
          <h4 class="text-right" style="margin:0;"><span>Total Price&nbsp;:&nbsp;$</span>
            <span class="total-job-price-63">
              {{this.ObjectJson[service_request.id]["AcceptedValue"] }}
            </span>
          </h4>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="final-calculation" *ngIf="currentShopId == serviceInfo.value.shop_id">
      <h4 class="text-right" *ngIf="showCostPrice"><strong>Sub Total : </strong>$<span class="grand-total"> {{subTotal}}</span></h4>
      <label *ngIf="isAllowed('views','add_tax_invoice') && showCostPrice" class="checkbox text-right" style="font-size:18px;" id="taxRow" onclick="return false;">
        Tax (<span class="tax-rate-value">{{serviceInfo.value.tax_rate}}% : $ {{totalTax}}</span>)
          </label>
        <!-- <label class="checkbox text-right" style="font-size:18px;font-weight:bold;" id="supplyChargesRow" onclick="return false;">
          <input type="checkbox" name="is_supply_charges_included" id="chkSupplyCharges" class="chk-supply-charges hidden" value="1" disabled="" checked="checked">
          Supply Charges : <span class="supply-charges-value">$ {{serviceInfo.value.supplies_charges}}</span>
        </label> -->
        <h4 class="text-right" *ngIf="isAllowed('views','add_supplies_invoice') && showCostPrice" ><strong>Supply Charges : </strong>$ {{serviceInfo.value.supplies_charges}}</h4>
        <h4 class="text-right" *ngIf="showCostPrice"><strong>Grand Total : </strong>$ <span class="grand-total-with-tax">{{ finalShowAbleAmount }}</span></h4>
        <h4 class="text-right" *ngIf="showCostPrice"><strong>Total Paid : </strong>$ <span id="totalPaid">{{ parseFloats(serviceInfo.value.total_paid)}} </span></h4>
        <h4 class="text-right" *ngIf="showCostPrice"><strong>Balance : </strong>$<span id="balance"> {{ (finalShowAbleAmount - serviceInfo.value.total_paid).toFixed(2) }}</span></h4>
    <!--
    <p *ngIf="serviceInfo.value.recommendation_saved == '1'"><b>Grand Total (Incl. of all taxes)</b> : ${{ finalShowAbleAmount }}</p>
    <p><b>Total Paid</b> : ${{serviceInfo.value.total_paid == null ? "0.00" : serviceInfo.value.total_paid}}</p> -->
  </div>
  <div style="position:relative;" *ngIf="toNumber(serviceInfo.value.request_status) == 3">
      <p class="alert alert-info" id="disclaimer" *ngIf="isAllowed('views','disclaimer')">{{serviceInfo.value.disclaimer}}</p>
    </div>
</div>
</div>
