<div mat-dialog-title class="relative" >
    VIN SCAN
    <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
        <mat-icon class="notranslate close-icon-lg">close</mat-icon>
    </button>
</div>
<!-- BODY -->

    <form class="form-horizontal form-control-normal vin-scan-form" autocomplete="off" [formGroup]="scanVinForm" (ngSubmit)="qq?getVinScan():checkVIN()">
        <mat-dialog-content>        
            <div class="display-full margin-bottom-5">
                <div class="col-sm-12">
                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                        <input matInput type="text" placeholder="VIN Number*" title="Vin Number" formControlName="vin_number" class="form-control input-lg custom-form-input btn-block" (keyup)="checkVinValidity()" maxlength="17" />
                        <mat-error *ngIf="scanVinForm.controls.vin_number.hasError('required')">
                            VIN number cannot be empty
                        </mat-error>
                        <mat-error *ngIf="scanVinForm.controls.vin_number.hasError('VinError')">
                            VIN number should have 17 characters.
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </mat-dialog-content>
      
        <mat-dialog-actions>
            <div class="text-right flexBtns">
                  <button type="button" mat-raised-button class="mat-raised-button margin-right-10" color="accent" title="OK"  (click)="qq?getVinScan():checkVIN()">
                      OK
                  </button>
                  <button mat-raised-button mat-dialog-close class="font-bold" title="Close" id="close">Close</button>
                </div>  
        </mat-dialog-actions>
    </form>


<!-- END BODY -->
