import {Injectable} from '@angular/core';
import { MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';
import {MatDialogRef, MatDialog} from '@angular/material/dialog';
import {Observable, BehaviorSubject} from 'rxjs/Rx';
import {ConfirmDialogComponent} from '../app/assetsComponent/confirm-dialog/confirm-dialog.component';
import {SnackbarToasterComponent} from '../app/assetsComponent/snackbar-toaster/snackbar-toaster.component';
import {Http, Headers, Response,RequestOptions, ResponseContentType} from '@angular/http';
import {globalConstants, GlobalVariable} from "../environments/globalConstants"
import {globalFunctions} from "../environments/globalFunctions"
import {Location} from '@angular/common';
import {ScrollToService} from 'ng2-scroll-to-el';
import {Subject} from 'rxjs/Subject';
import {FormControl, FormGroup} from '@angular/forms';
// import {NgProgress} from 'ngx-progressbar';
import {Meta } from '@angular/platform-browser';
import { lang }  from "../environments/lang-en";
import {Router} from '@angular/router';
import * as moment from 'moment';
import  saveAs  from 'file-saver';
import { TitleService } from '../app/Titleservice.service';
import { BsModalRef, ModalDirective, BsModalService } from 'ngx-bootstrap/modal';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import {map,tap} from 'rxjs/operators';
import {Socket} from 'ngx-socket-io';
// import { ReplaySubject } from 'rxjs';
declare var jQuery:any;
declare var $ :any; 

export interface ModalInfo{
  component: any,
  data:any,
  name?:string,
  module?:string,
  id?:string
}

@Injectable()
export class GlobalService {
  public response;
  public production = false;
  public type: any;
  public snackConfig: any;
  public sessionCheck=true;
  public app;
  public globalVariable;
  public GLANGSTYLE='#goog-gt-tt{display: none !important;}.goog-text-highlight {background-color:transparent !important;box-shadow: none !important;}';
  private myAnnouncedSource = new Subject<string>();
  private acl:any=[];
  private diaLog;
  public  storage=localStorage;
  public validCountries = globalConstants.VALID_COUNTRIES;
  public SRNoteListId:BehaviorSubject<any> = new BehaviorSubject(false);
  public SRNoteListOpen:BehaviorSubject<any> = new BehaviorSubject(false);
  public UpdateQQ:BehaviorSubject<any> = new BehaviorSubject(false);
  public isDateRange:BehaviorSubject<any> = new BehaviorSubject(false);
  public receiveDirectPasteResponse: BehaviorSubject<any> = new BehaviorSubject(Object);
  myAnnounced$ = this.myAnnouncedSource.asObservable();
  private socket:any;
  private ioConnected=false
  public updateIO:BehaviorSubject<any> = new BehaviorSubject(false);
  private ioPort=':5000'
  private customerData:any={}
  private shopData:any=null;
  private modalsInfoData:Array<ModalInfo> = [];
  private _modalsInfo:Subject<Array<ModalInfo>> = new Subject<Array<ModalInfo>>();
  public modalsInfo = this._modalsInfo.asObservable();
  private modalReferences:Array<BsModalRef> = [];
  private modalDirectives:Array<ModalDirective> = [];
  // public checkValueSetStatus:any = new Subject<void>();
  httpOptions :any;
  public shopDetails: any = {}; //Draft variable
  public loginUserDetails: any ={}; ////Draft variable
  constructor(public titleService: TitleService,private meta: Meta,public dialog: MatDialog, public matSnackbar: MatSnackBar, public http: Http,
    public _location: Location, private scrollService: ScrollToService, public router: Router,
    //  public progressbar: NgProgress, 
    public httpClient: HttpClient,
     private modalService: BsModalService,
    private chatSocket: Socket,
    ) {
      this.globalVariable = GlobalVariable;
      // this.initIO()
    }
    public confirmDialog(id: any, message: string,dialogSize:number=300, data?:any,subPanelClass:string=""): Observable<boolean> {
      let dialogRef: MatDialogRef<ConfirmDialogComponent>;
      dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: dialogSize+'px',
        panelClass: ['car-dialogbox',subPanelClass]
      });
      dialogRef.componentInstance.id = id;
      dialogRef.componentInstance.message = message;
      dialogRef.componentInstance.data = data;
      dialogRef.disableClose = true;
      if(id == 'ask_vehicle_ins'){
        dialogRef.componentInstance.labelNo = 'Skip';
        dialogRef.disableClose = true;
      }else if(id == 'select_quick_appt'){
        dialogRef.componentInstance.labelYes = 'Appointments';
        dialogRef.componentInstance.labelNo = 'Assign Tech.';
        dialogRef.componentInstance.labelRm = 'Internal Notes';
      }else if(id=='taskTrackConfirm' || id=='taskTrackConfirmStop' ){
        dialogRef.componentInstance.labelYes = 'Pause';
        dialogRef.componentInstance.labelNo  = 'Cancel';
        dialogRef.componentInstance.labelRm  = 'Complete';
      } else if(id == 'select_walkin_customer'){
        dialogRef.componentInstance.labelNo = 'Skip';
      }
      return dialogRef.afterClosed();
    }

    public snackbar(type: any, message: string,action?:any,refreshButton?:any,duration = 5000): Observable<any> {
      this.type = type;
      let snackBarduration:number = type ==='success'? globalConstants.SUCCESS_SNACKBAR_DURATION: globalConstants.ERROR_SNACKBAR_DURATION;
      if(typeof action=='undefined'){
        action=null;
      }
 
      if(typeof refreshButton=='undefined'){
        refreshButton=null;
      }


      let snackbarRef: MatSnackBarRef<SnackbarToasterComponent>;
      if (window.innerWidth > 767) {
        this.snackConfig = {
         duration: snackBarduration,
          horizontalPosition: "right",
          verticalPosition: "top",
          politeness: 'polite',
          extraClasses: ["car-snackbar", this.type],
          data: action
        }
      } else {
        this.snackConfig = {
         duration: snackBarduration,
          horizontalPosition: "right",
          verticalPosition: "top",
          extraClasses: ["car-snackbar", this.type],
          data: action
        }
      }
      snackbarRef = this.matSnackbar.openFromComponent(SnackbarToasterComponent, this.snackConfig);
      snackbarRef.instance.type = type;
      snackbarRef.instance.message = message;
      snackbarRef.instance.saveButton = (action && !refreshButton ? true :false); 
      snackbarRef.instance.refreshButton = (refreshButton ? true :false); 
      return snackbarRef.afterDismissed();
    }

    public getCurrentCustomer() {
      return JSON.parse(localStorage.getItem("loggedInCustomer"))
    }

    public getCurrentUser() {
      return JSON.parse(localStorage.getItem("loggedInUser"))
    }

  

    //by ankur

    generateHeader(){

      let token = localStorage.getItem('token')
           this.httpOptions = {
        headers: new HttpHeaders({
          'content-type': 'application/x-www-form-urlencoded',
          
        })
      };   
    }

    public callAPI(URL, PAYLOAD):Observable<any> {
        
        const headers = new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        });

        const requestOptions = { headers: headers };

        if (typeof PAYLOAD == "object") {
          var content = globalFunctions.stringifyFormData(PAYLOAD, null);
        } else {
          content = PAYLOAD;
        }
        return this.httpClient.post(URL, content,requestOptions).pipe(map((res: any) => {
          // return res;
          var r=res;
              if(r.result=="0" && r.code=="391"){
                this.snackbar('error', r.message);
              }
              this.authText(r);
              return r;
        }));
    }


    public callAPIWithJSONHeader(URL, PAYLOAD):Observable<any> {
        
      const headers = new HttpHeaders({
        'Accept': 'application/json',
      });

      const requestOptions = { headers: headers };
      let  content = PAYLOAD;
      return this.httpClient.post(URL, content,requestOptions).pipe(map((res: any) => {
        var r=res;
            if(r.result=="0" && r.code=="391"){
              this.snackbar('error', r.message);
            }
            this.authText(r);
            return r;
      }));
  }

    //end it
    public goBack() {
      this._location.back();
    }
    public announceItem(item: string) {
      this.myAnnouncedSource.next(item);
    }
    public setScrolling(eleName, eleTiming, eleOffset) {
      this.scrollService.scrollTo(eleName, eleTiming, eleOffset);
    }
    public  mobileNumberValidation(control: FormControl) {
      return control.value.replace(/\D+/g, "").length >= 10 ? false : {minLength: true};
    }
    public imageLoader() {
      // this.progressbar.state.subscribe((loader) => {
        // console.log('image loader run');
        // if (loader.active) {
        //     document.querySelector('body').classList.add("image-upload-main");
        // } else {
        //     document.querySelector('body').classList.remove("image-upload-main");
        // }
      // });

    }

    public checkTimeStamp(key){
      let data=this.isJSON(localStorage.getItem(key),'timeStamp');
      if(data!=null &&  data){
        let timeout=parseInt(globalConstants.USER_TIMEOUT);
        let cTime=globalFunctions.getCurrentTimeStamp();
        let cOriginal= (cTime-data);
        // console.log(cOriginal)
        if(cOriginal>timeout){
        return false;
      } else{
        this.updateTimeout(key,cTime);
        return true;
      }
    }
    return false;
  }
  public isJSON(str,key){
    try {
      let r=JSON.parse(str);
      if(key){
        return r[key];
      }
      return r;
    } catch (e) {
      return false;
    }
  }
  public updateTimeout(key,value){
    let data=this.isJSON(localStorage.getItem(key),false);
    if(data && (data.timeStamp!=value)){
      data.timeStamp=value;
      // console.log("hee")
      localStorage.setItem(key,JSON.stringify(data));
    }
  }
  preferedServicesPhoneValidity(ctrl,key) {
    let l='{"'+key+'": true}';
    let home_phone =ctrl.value[key];
    if(home_phone !="" && (typeof home_phone=='string')) {
      let phoneResult =home_phone.replace(/\D+/g, "").length >= 10 ? false : true;
      if(phoneResult == false) {
        ctrl.get(key).setErrors(null);
      } else {
        ctrl.get(key).setErrors(JSON.parse(l))
      }
    }
  }

  preferedServicesPhoneValidityForCalendar(ctrl,key) {
    let l='{"'+key+'": true}';
    let home_phone =ctrl.value[key];
    if(home_phone !="" && (typeof home_phone=='string')) {
      let phoneResult =home_phone.replace(/\D+/g, "").length >= 4 ? false : true;
      if(phoneResult == false) {
        ctrl.get(key).setErrors(null);
      } else {
        ctrl.get(key).setErrors(JSON.parse(l))
      }
    }
  }

  public localGetterSetter(init, value,prefix) {
    if (init == null) {
      if((value!=null)){
        for (let i in value) {
          // console.log(i)
        if(i=='start'){
          localStorage.setItem(prefix+i,'0');
        }else if( i=='limit'){
          localStorage.setItem(prefix+i,'10');
        }else{
          localStorage.setItem(prefix+i,"");
        }
      }
    }
  }else if((typeof init=='object') && (value!=null)){
    for (let i in init) {
      let val=localStorage.getItem(prefix+i);
      if(i=='start'){
        value[i]=(parseInt(val) || 0);
      }else if( i=='limit'){
        value[i]=(parseInt(val) || 10 );
      }else{
        value[i]=( (typeof val==null || val=='' || val==null ) ? '' : val);
      }
      localStorage.setItem(prefix+i, value[i]);
    }
    return value;
  }else if(typeof init=='object' && value==null){
    for (let i in init) {
      localStorage.setItem(prefix+i, init[i]);
    }
  }else{
    localStorage.setItem(prefix+init, value);
  }
}
public localGetter(init, prefix){
  return localStorage.getItem(prefix+init);
}
firstList(PREFIX,options){
  if (localStorage.getItem(PREFIX+"first_list") == "true" || options.limit >= parseInt(localStorage.getItem(PREFIX+"totalRecords")) || options.start == 0)
  return true;
  else
  return false;
}
lastList(PREFIX){
  if(localStorage.getItem(PREFIX+"more_rows") == "true") {
    return !true
  } else {
    return !false
  }
}
 
openSearch(PREFIX){
  if(localStorage.getItem(PREFIX+"search_by") !="" || localStorage.getItem(PREFIX+"sort_by")!="" || localStorage.getItem(PREFIX+"sort_type")!="" || localStorage.getItem(PREFIX+"search_keyword")!="") {
    return true;
  } else {
    return false;
  }
}
checkUserSession(){
  if (this.sessionCheck) {
    this.sessionCheck=false;
    let redirect = true;
    let redirectTo = 'customer';
  }
}
validImage(file,inputID){
  // console.log(inputID,file);
  if (file.type == "image/png" || file.type == "image/jpeg" || file.type == 'image/jpg' ) {
  return true;
}
this.snackbar('error', "Please Choose valid image file.");
let ele=<HTMLInputElement>document.getElementById(inputID);
ele.value='';
return false;
}

checkImageSize(file,inputID){
  // console.log(inputID,file);
  if (file.size < 1e+7 ) {
  return true;
} else {
  this.snackbar('error', "Image size should be less than 10 MB.");
  let ele=<HTMLInputElement>document.getElementById(inputID);
  ele.value='';
  return false;
}
}
onDeleteCheck(prefix,val)   {
  let v=parseInt(localStorage.getItem(prefix+'current-visible'));
  if(v==1){
    this.localGetterSetter("start",(val.start-val.limit),prefix);
  }
}
setMetaData(module,page) {
  let pageTitle = lang[module][page].METADATA.TITLE;
  if(localStorage.getItem("loggedInUser")) {
    let currentUserInfo = JSON.parse(localStorage.getItem("loggedInUser"));
    if(currentUserInfo.profile_type == '2'){
      pageTitle = pageTitle.replace(/customer/ig,'Contact')
    }
  }
  this.titleService.setTitle(pageTitle)
  this.meta.updateTag({name:"description", content: lang[module][page].METADATA.DESCRIPTION})
  this.meta.updateTag({name: 'author', content: lang[module][page].METADATA.AUTHOR}),
  this.meta.updateTag({name: 'keywords', content: lang[module][page].METADATA.KEYWORDS })
}
sendGlobalErrorMail(d){
  console.log(d)
}
    /* Display Dates by Adding T */
    displayDates(dateValue) {
      return (dateValue!=null && dateValue!='0000-00-00 00:00:00' && dateValue!='') ? dateValue.replace(" ","T") : "";
    }
    /* Display Calendar Dates by Adding T */
    displayDatesCalendar(dateValue,datePipe) {
        return (dateValue!=null && dateValue!='0000-00-00 00:00:00' && dateValue!='') ? datePipe.transform(dateValue.replace(" ","T"),'MM/dd/yyyy hh:mm a') : "";
    }
    /* auto focus off on device */
    removeAutoFocus(){
        if ( /Android|webOS|iPhone|iPad|iphone|ipad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ){
            $('input').attr('tabindex','-1');
            return true;
        }
        return false;
    }
    /* toottipimage */
    hideTooltipImage(){
        if ( /Android|webOS|iPhone|iPad|iphone|ipad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ){
            $('tooltip-content.image-tooltip').hide();
            // alert('tooltip');
        }
    }
    /* Date Format*/
    DateFormat(){
        return 'MM/DD/YYYY hh:mm A';
    }
    /*
    * dateValue => date value which you want to convert
    * format => which format required (like 'MM/DD/YYYY hh:mm a') or G or GD
    * returnType => if'B' then '' , if'H' then '-', if'C' then current date, otherwise return returnType
    */
    getFullFormateDate(dateValue:any, format:any, returnType:any) {
        let result:any = '';
        if (format == 'G') {
            format = this.DateFormat();
        } else if (format == 'GD') {
            format = 'MM/DD/YYYY';
        }
        if (this.isDateBlankOrZero(dateValue) && (format != '') && format) {
            return moment(dateValue).format(format);
        }
        if (returnType == 'B') {
            result = '';
        } else if (returnType == 'H') {
            result = '-';
        } else if (returnType == 'C') {
            result = moment(new Date()).format(format);
        } else {
            result = returnType;
        }
        return result;
    }
    /** checking value blank or not */
    isDateBlankOrZero(value) {
        let allSet=false;
        if (value && (value != null) && (value != '') && (value.length > 0) ) {
            allSet=true;
        }
        if(allSet && ( value=='0000-00-00 00:00:00')){
            allSet=false;
        }
        return allSet;
    }
    isOldDate(oldDate) {
      var currentTimeStamp = moment().format('x');
      var oldTimeStamp = moment(oldDate).format('x');
      // new Date(oldDate.replace(" ","T")).getTime();
      // alert("currentTimeStamp="+currentTimeStamp+"oldTimeStamp="+oldTimeStamp)
      return currentTimeStamp > oldTimeStamp ? true : false;
    }
    
                                                            
    validateFiles(key,files){
    let isValid=false;
    let imgMaxLabel=globalConstants.MAX_ALLOWED_IMAGE_DISPLAYED_TEXT;
    let imgMaxAllowed=globalConstants.MAX_ALLOWED_IMAGE_SIZE;
    let imgFormat=globalConstants.FORMAT_SUPPORTED_FOR_IMAGE;

    let videoMaxLabel=globalConstants.MAX_ALLOWED_VIDEO_DISPLAYED_TEXT;
    let videoMaxAllowed=globalConstants.MAX_ALLOWED_VIDEO_SIZE;
    let videoFormat=globalConstants.FORMAT_SUPPORTED_FOR_VIDEO;
    
    let pdfMaxAllowed=10485760;
    let imgTypes=['image/png','image/jpeg','image/jpg'];
    let videoTypes=['video/mp4','video/quicktime'];
    let pdf=['application/pdf'];
    let doc=['application/msword','application/vnd.openxmlformats-officedocument.wordprocessingml.document','application/pdf','image/png','image/jpeg','image/jpg'];
    let docpdf=['application/msword','application/vnd.openxmlformats-officedocument.wordprocessingml.document','application/pdf'];
    let csv=['application/vnd.ms-excel','text/plain','text/csv','text/tsv'];
    if(typeof files[0].type !='undefined'){
        if((key == "video") && (videoTypes.indexOf(files[0].type)>=0)){
            isValid=true;
        }else if((key == "image") && (imgTypes.indexOf(files[0].type)>=0)){
            isValid=true;
        }else if((key == "pdf") && (pdf.indexOf(files[0].type)>=0)){
          isValid=true;
        }else if((key == "docpdf") && (docpdf.indexOf(files[0].type)>=0)){
          isValid=true;
        }else if((key == "doc") && (doc.indexOf(files[0].type)>=0)){
            isValid=true;
        }else if((key == "csv") && (csv.indexOf(files[0].type)>=0)){
          isValid=true;
        }
    }
    if (isValid == false) {
        if (key == "video") {
            this.snackbar("error", "Please upload "+videoFormat+" file only.");
        } else if (key == "image") {
            this.snackbar("error", "Please upload "+imgFormat+" file only.");
        }else if (key == "pdf") {
          this.snackbar("error", "Please upload pdf file only.");
        }else if (key == "docpdf") {
          this.snackbar("error", "Please upload pdf, doc, docx file only.");
        }else if (key == "doc") {
            this.snackbar("error", "Please upload doc,pdf,image file only.");
        }else if (key == "csv") {
          this.snackbar("error", "Please upload csv file only.");
        }
    } else if (isValid == true) {
        if ((key == "video") &&  (files[0].size > videoMaxAllowed) ) {
            isValid=false;
            this.snackbar("error", "Video should be less than " + videoMaxLabel + ".");
        } else if ((key == "image") && (files[0].size > imgMaxAllowed)) {
            isValid=false;
            this.snackbar("error", "Image should be less than " + imgMaxLabel + ".");
        }else if ((key == "pdf") && (files[0].size > pdfMaxAllowed)) {
          isValid=false;
          this.snackbar("error", "Pdf should be less than " + imgMaxLabel + ".");
        }else if ((key == "docpdf") && (files[0].size > pdfMaxAllowed)) {
          isValid=false;
          this.snackbar("error", "Document should be less than " + imgMaxLabel + ".");
        }else if ((key == "doc") && (files[0].size > pdfMaxAllowed)) {
            isValid=false;
            this.snackbar("error", "Document should be less than " + imgMaxLabel + ".");
        }else if ((key == "csv") && (files[0].size > pdfMaxAllowed)) {
          isValid=false;
          this.snackbar("error", "CSV should be less than " + imgMaxLabel + ".");
        }
    }
    return isValid;
  }                                                        
    /**
     * scroll top animation
     * @param ele 
     */
    expansionOpen(ele) {
      var target= '#expansionHead-'+ ele;
      var eleState = $(target).data('state');
      $(".new-request-expension").find(".mat-expansion-panel-header").data("state",false);
      if(!eleState){
        setTimeout(() => {
           let element = document.getElementById('expansionHead-' + ele);
           var target= '#expansionHead-'+ ele;
           setTimeout(() => {
             var body = $("html, body");
             body.animate({
                    scrollTop: jQuery(target).offset().top - 90
              }, 500);
           }, 500);
           $(target).data('state',true);
        }, 200);
      }
    }
    /** reload after order,save recommendation */
    reload(){
      /** reInit component variable */
    }
    scrollToEle(ele){
      let offset=$(ele).offset().top;
      this.scrollService.scrollTo(ele, 700,offset);
    }
    
    public formData(URL, content) {
      // console.log("this is content",)
      const headers = new HttpHeaders({
        'Accept': 'application/json',
      });

      const requestOptions = { headers: headers };
      return this.httpClient.post(URL, content, requestOptions).pipe(map((res: any) =>{
        var r=res;
        this.authText(r);
        return r;
      }));
    }
    public getApiCall(URL) {
      return this.httpClient.get(URL).pipe(map((res: any) =>{
        var r=res.json();
        this.authText(r);
        return r;
      }));
    }
    authText(res){
      if(res.code==globalConstants.DIALOG_ACCESS_CODE){
        this.diaLogObj(null,res);
        this.roleRestriction(res);
      }
      if(res.result==0 && res.code==globalConstants.DIALOG_CLOSE_CODE){
        this.snackbar('error', res.message);
        let redirectTo=this.getLoginType();
        this.diaLogObj(null,res);
        this.userLogout(redirectTo, false);
      }
    }
    userLogout(user_type, msg){
      this.diaLogObj(null,null);
      if (user_type == "customer") {
        this.callAPI(globalConstants.API_LOGOUT_API, "customer_id=" + JSON.parse(localStorage.getItem('loggedInCustomer'))["id"]).subscribe((data:any) => {
          if (data.result == "1") {
            if(msg !="HIDE_SESSION_MSG"){
              msg && this.snackbar("success", "Logged Out Successfully");
              (!msg) && this.snackbar("error", "Login session has been expired.");
            }
          localStorage.removeItem('loggedInCustomer');
          localStorage.removeItem('customerSubsResponse');
          localStorage.removeItem('customerState');
          localStorage.removeItem('customer-country');
          localStorage.removeItem('customerlicensePlateData');
          localStorage.removeItem(globalConstants.CUSTOMER_JWT_KEY);
          this.matSnackbar.dismiss();
          this.chatSocket.disconnect();
          this.router.navigate(["/customer"])
        }
      },(error)=>{});
    }
    if (user_type == "admin") {
      this.callAPI(globalConstants.API_LOGOUT_API, "userId=" + JSON.parse(localStorage.getItem('loggedInAdmin'))["id"] + "&userType=Super Admin").subscribe((data:any) => {
        if (data.result == "1") {
          (!msg) && this.snackbar("error", "Login session has been expired.");
          localStorage.removeItem('loggedInAdmin');
          localStorage.removeItem(globalConstants.ADMIN_JWT_KEY);
          this.matSnackbar.dismiss();
          this.router.navigate(["/admin"])
        }
      },(error)=>{})
    }
    if (user_type == "shops" || user_type == "user") {
      this.callAPI(globalConstants.API_LOGOUT_API, "userId=" + JSON.parse(localStorage.getItem('loggedInUser'))["id"] + "&userType=User").subscribe((data:any) => {
        if (data.result == "1") {
          let toastMsg = msg ? msg : "Login session has been expired.";
          this.snackbar("error", toastMsg);
          localStorage.removeItem('loggedInUser');
          localStorage.removeItem('openFilter');
          localStorage.removeItem('shopCustomerID');
          localStorage.removeItem('listCustomerID');
          localStorage.removeItem("menuloaded");
          localStorage.removeItem('shop_timezone');
          localStorage.removeItem('shop_state');
          localStorage.removeItem('shop_country');
          localStorage.removeItem('shop_country_Id');
          localStorage.removeItem('licensePlateData');
          localStorage.removeItem('business-selected-customer');
          localStorage.removeItem('business-selected-employee');
          localStorage.removeItem('advance-report-selected-customer');
          localStorage.removeItem('advance-report-selected-employee');
          localStorage.removeItem('isCompletedIncluded');
          localStorage.removeItem('shop-details');
          localStorage.removeItem('wpi');
          sessionStorage.removeItem('prefill_manufacturer');
          sessionStorage.removeItem('prefill_vendor');
          sessionStorage.removeItem('prefill_part_name');
          sessionStorage.removeItem('prefill_part_no');
          sessionStorage.removeItem('prefill_oem_part_no');
          sessionStorage.removeItem('pt_labor_req');
          localStorage.removeItem(globalConstants.SHOP_JWT_KEY);
          this.setDefaultLang('');
          sessionStorage.setItem('translate','0');
          jQuery('html').addClass('notranslate')
          // window.location.href="/shops"
          jQuery("#hfc-frame").hide();
          this.copyJob('reset');
          try{
            for (let i = 0, len = localStorage.length; i < len; ++i) {
              if (localStorage.key(i).indexOf('shop-service') != -1) {
                localStorage.setItem(localStorage.key(i), '');
              }
              if (localStorage.key(i) && localStorage.key(i).indexOf('bypassPopup') != -1) {
                localStorage.removeItem(localStorage.key(i));
              }
            }
          }catch(err) {}          
          this.matSnackbar.dismiss();
          this.router.navigate(["/shops"]);
        }
      },(error)=>{
        this.copyJob('reset');
      })
    }
    this.clearAllBootstrapModal();
    }
    
    getAdmin(){
      let data=localStorage.getItem("loggedInAdmin");
      if (data){
        return JSON.parse(data);
      }
      return {};
    }
    getLoginType(){
      let type=this.router.url.split('/');
      if(typeof type[1]!='undefined'){
        return type[1];
      }
      return '';
    }

    getSetItems(key,value?:any,prefix?:string){
      let r='';
      if(typeof value=='undefined'){
        value=false;
      }
      if(typeof prefix=='undefined'){
        prefix='';
      }
      if(key && (value || (value===''))){
        sessionStorage.setItem(prefix+key,value);
      }else{
        r=sessionStorage.getItem(prefix+key);
      }
      return r;
    }
        
    getFileIcon(file:any){
      let imgTypes=['image/png','image/jpeg','image/jpg','png','jpeg','jpg'];
      let videoTypes=['video/mp4','video/quicktime'];
      let pdf=['application/pdf','pdf'];
      let doc=['application/msword','application/vnd.openxmlformats-officedocument.wordprocessingml.document','doc','docx'];
      if((imgTypes.indexOf(file.type)>=0)){
        return 1;
      }else if((videoTypes.indexOf(file.type)>=0)){
        return 2;
      }else if((pdf.indexOf(file.type)>=0)){
        return 3;
      }else if((doc.indexOf(file.type)>=0)){
        return 4;
      }
    }
                                                            
    /* VALIDATE COUNTRY/STATE/CITY */
    validateState(ctrl,key) {
        for(var i=0;i<key.length;i++) {
            let l='{"required": true}';
            let getState =ctrl.value[key[i]];
            if(getState !="" && getState != undefined) {
                let txt=getState.toString();
                let getStateResult = (txt.length >= 1 ? false : {required: true});
                if(getStateResult == false) {
                    ctrl.get(key[i]).setErrors(null);
                } else {
                    ctrl.get(key[i]).setErrors(JSON.parse(l))
                }
            } else {
                ctrl.get(key[i]).setErrors(JSON.parse(l))
            }
        }
    }
 
    triggerHtmlEvent(element, eventName) {
      let event;
      let dom: any = document;
      try{
      if (typeof dom.createEvent != 'undefined') {
          event = dom.createEvent('HTMLEvents');
          event.initEvent(eventName, true, true);
          element.dispatchEvent(event);
      } else if (typeof dom.createEventObject != 'undefined') {
          event = dom.createEventObject();
          event.eventType = eventName;
          element.fireEvent('on' + event.eventType, event);
      }
    }catch(t){ }
  }
  setDefaultLang(lang) {
    var container = document.getElementById('google_translate_element');
    var select = container.getElementsByTagName('select')[0];
    if (typeof select != 'undefined') {
      setTimeout(() => {
        let sLang = $('#google_translate_element select:first > option:contains(' + lang + ')').val()
        $('#google_translate_element select:first').val(sLang);
        this.triggerHtmlEvent(select, 'change');
        this.triggerHtmlEvent(select, 'change');
      }, 1000);
    } else {
      setTimeout(() => {
        this.setDefaultLang(lang);
      },1000)
    }
  }
  setGLangCss(css?: string) {
    var t = document.getElementById('google-gatt');
    if (typeof css != 'undefined' && css.length > 0) {
      t.innerText = css;
    } else {
      t.innerText='';
    }
  }
  isAllowed(app,module,action?:any){
    if(typeof app.roles[module]!='undefined'){
      let allAction=app.roles[module];
      if(typeof action!='undefined'){

        if(Array.isArray(action)){
          let isTrue=false;
          for(var i in action){
            if( allAction[action[i]]=='1'){
              isTrue=true;
            }
          }
          return isTrue ;
        }else{
          return ( allAction[action]=='1' ? true : false );
        }
      }
      return allAction;
    }else{
      return false;
    }
  }
  roleRestriction(res,comId?:any){
    if(res.code=="378"){
    this.snackbar('error', res.message);
    let html='<div class="panel panel-primary margin-0"><div class="panel-heading padding-left-15">'+
        '<h3 class="panel-title">Unauthorized</h3></div><div class="panel-body bgcolor-lightgrey">'+
        '<div class="msg-response msg--fail"  style="height: auto;"><div><div class="msg-response_content" style="max-width: 100%;">'+
                    '<span><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></span>'+
                    '<h2 class="title">Unauthorized!</h2><h4 class="msg">You are not authorize to access this resource.</h4>'+
                    '<h4 class="msg">Please contact your administrator to resolve this.</h4>'+
                    '</div>'+
            '</div></div></div></div>';
      $('#mainCompotents').parent().html(html);
    }
  }
  /** this set user wise access control limit*/
  setAcl(rolesData){
    this.acl=rolesData;
  }
  /** return module wise access controls*/
  getAcl(module,action?:any){
    if(typeof this.acl[module]!='undefined'){
      let allAction=this.acl[module];
      if(typeof action!='undefined'){
          return ( allAction[action]=='1' ? true : false );
      }
      return allAction;
    }
  }

  downloadAsset(assetURL,fileName) {
    //console.log('asset url',assetURL);
    const headers = new HttpHeaders({
      'content-type': 'application/x-www-form-urlencoded',
    });
    const requestOptions = { headers: headers };
    this.httpClient.get(assetURL,{headers, responseType: 'blob', observe: 'response'}).
    map(function(res:any){
      let resContentType = res.headers.get('content-type');
      if(resContentType == 'application/json'){
        return {
          'contentType' : resContentType,
          'response' : res
        };
      }else{
        return {
          'contentType' : resContentType,
          'response' : new Blob([res.body],{ type: resContentType })
        };
      }
    })
    .subscribe((resp) =>{ 
      if(resp.contentType == 'application/json'){
        let fileReader = new FileReader();
        let pageCtx = this;
        fileReader.onload = function(){
          let parsedResponse= JSON.parse(<string>this.result);
          //console.log(parsedResponse);
          if(parsedResponse.result == "1"){
            pageCtx.snackbar("success", parsedResponse.message);
          }else{
            pageCtx.snackbar("error", parsedResponse.message);
          }
        };
        fileReader.readAsText(resp.response);
      }else{
        if(this.isDeviceiOS()){
          const authParams = this.getAuthParams();
          if(Object.keys(authParams).length > 0){
            let urlParts = assetURL.split('?');
            let skipFirst = (urlParts.length > 1) ? false : true;
            for(let key in authParams){
              if(skipFirst){
                assetURL += "?"+key+"="+authParams[key];
                skipFirst = false;
              }else{
                assetURL += "&"+key+"="+authParams[key];
              }
            }
          }
          var element = document.createElement('a');
          element.setAttribute('href', assetURL);
          element.setAttribute('download', fileName);
          element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        }else{
          saveAs(resp.response,fileName);
        }
      }
    });
  }

  /**
   * this is post  function for download the file
   */
  downloadFileWithPost(assetURL,option,fileTitle){
     let isPdf;
    const headers = new HttpHeaders({
      'content-type': 'application/x-www-form-urlencoded',
    });
    const requestOptions = { headers: headers };
    this.httpClient.post(assetURL,option,{headers, responseType: 'blob', observe: 'response'})
    .map(function(res:any){
      let resContentType = res.headers.get('content-type');
      if(resContentType == 'application/csv'){
        isPdf = false;
        return {
          'contentType' : resContentType,
          'response' : new Blob([res.body],{ type: resContentType })
        };
      }
       else if(resContentType == 'application/pdf')
       {
        isPdf = true;
        return{
        'contentType' : resContentType,
        'response' : new Blob([res.body],{ type: resContentType })
        }
      }
      else{
        return {
          'contentType' : resContentType,
          'response':res
        };
      }  
    }).subscribe((resp) =>{ 
      if(resp.contentType == 'application/json'){
        let fileReader = new FileReader();
        let pageCtx = this;
        fileReader.onload = function(){
          let parsedResponse= JSON.parse(<string>this.result);
          //console.log(parsedResponse);
          if(parsedResponse.result == "1"){
            pageCtx.snackbar("success", parsedResponse.message);
          }else{
            pageCtx.snackbar("error", parsedResponse.message);
          }
        };
        fileReader.readAsText(resp.response);
      }else if(resp.response){
       let  fileName;
        if(isPdf){
          fileName = fileTitle+'.pdf';
        }else{
          let dd=new Date();
          let dF=dd.getDate()+'_'+(dd.getMonth()+1)+'_'+dd.getFullYear()+'_'+dd.getHours()+dd.getMinutes()+dd.getSeconds();
          fileName = fileTitle+dF+'.csv';
        }
        
        if(this.isDeviceiOS()){
          const authParams = this.getAuthParams();
          if(Object.keys(authParams).length > 0){
            for(let key in authParams){
              assetURL += "&"+key+"="+authParams[key];
            }
          }
          var element = document.createElement('a');
          element.setAttribute('href', assetURL);
          element.setAttribute('download', fileName);
          element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        }else{
          saveAs(resp.response,fileName);
        }
      }
    })
  }

   /**
   * this is get method  for download the file
   */
  downloadFileWithGet(assetURL,serviceId:any){
    const headers = new HttpHeaders({
      'content-type': 'application/x-www-form-urlencoded',
    });
    const requestOptions = { headers: headers };
    this.httpClient.get(assetURL,{headers, responseType: 'blob', observe: 'response'})
    .map(function(res:any){
      let resContentType = res.headers.get('content-type');
      if(resContentType == 'application/pdf'){
        return {
          'contentType' : resContentType,
          'response' : new Blob([res.body],{ type: resContentType })
        };
      }else{
        return {
          'contentType' : resContentType,
          'response' : res
        };
      }
    })
    .subscribe((resp) =>{
      // if(resp.contentType == 'application/json'){
      //   let fileReader = new FileReader();
      //   fileReader.onload = () => {
      //     let parsedResponse= JSON.parse(fileReader.result as string);
      //     //console.log(parsedResponse);
      //     if(parsedResponse.result == "1"){
      //       if(assetURL == globalConstants.API_GENERATE_INVOICE_URL){
      //         //  this.checkValueSetStatus.next();
      //         //  this.checkValueSetStatus.complete();
      //       }
      //       this.snackbar("success", parsedResponse.message);
      //     }else{
      //       this.snackbar("error", parsedResponse.message);
      //     }
      //   };
      //   fileReader.readAsText(resp.response);
      // }else{
        var fileName = serviceId+'.pdf';
        if(this.isDeviceiOS()){
          const authParams = this.getAuthParams();
          if(Object.keys(authParams).length > 0){
            for(let key in authParams){
              assetURL += "&"+key+"="+authParams[key];
            }
          }
          var element = document.createElement('a');
          element.setAttribute('href', assetURL);
          element.setAttribute('download', fileName);
          element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        }else{
          saveAs(resp.response,fileName);
        }
      // }
    })
  }

  isSubscribe(option){
    let currentUser=this.getCurrentUser();
    let isSubscribed = (currentUser ? currentUser.is_subscribed: false);
    if(isSubscribed  && currentUser.subscription_data){
      if(option == 'employee'){
        if(currentUser.subscription_data.employee_role_db == "0"){
          return false;
        }else{
          return true;
        }
      }else if(option == 'no_employee'){
        return currentUser.subscription_data.no_of_employees;
      }else if(option == 'internal_notes'){
          return (currentUser.subscription_data.internal_notes == "0" ? false : true);
      }else if(option == 'service_notes'){
        return (currentUser.subscription_data.service_request_notes == "0" ? false : true);
      }else if(option == 'quick_quote'){
        return (currentUser.subscription_data.quick_quote == "0" ? false : true);
      }else if(option == globalConstants.SUBSCRIPTION_DISCOUNTS_FEATURE){ // added for coupon code.
        return (currentUser.subscription_data.discounts == "0" ? false : true);
      }
    }
    return false;
  }
  checkToBypassPopup(currentUrl,nextUrl,selectedRoutes){
    //console.log('currUrl',currentUrl);
    let currComponentPath = this.reConstructPath(currentUrl);
    let nextComponentPath = this.reConstructPath(nextUrl);
    let result = ((currComponentPath == selectedRoutes.viewRoutePath) && (nextComponentPath == selectedRoutes.fillRoutePath)) ? true : false;
    let revResult = ((currComponentPath == selectedRoutes.fillRoutePath) && (nextComponentPath == selectedRoutes.viewRoutePath)) ? true : false;
    return (result || revResult) ? true : false;
  }
  reConstructPath(url){
    let currUrl = url.split("/").filter(function(urlPart){
      return (urlPart != '');
    });
    return currUrl[0]+"/"+currUrl[1];
  }
  isPartstechAllowed(){
    let currentUser=this.getCurrentUser();
    let isSubscribed = (currentUser ? currentUser.is_subscribed: false);
    if(isSubscribed  && currentUser.subscription_data){
      if(currentUser.subscription_data.partstech_db == '1'){
        return true;
      }
    }
    return false;
  }
  isNexpartAllowed(){
    let currentUser=this.getCurrentUser();
    let isSubscribed = (currentUser ? currentUser.is_subscribed: false);
    if(isSubscribed  && currentUser.subscription_data){
      if(currentUser.subscription_data.nexpart == '1'){
        return true;
      }
    }
    return false;
  }
  isMultifileAllowed(){
    let currentUser=this.getCurrentUser();
    let isSubscribed = (currentUser ? currentUser.is_subscribed: false);
    if(isSubscribed  && currentUser.subscription_data){
      if(currentUser.subscription_data.multi_file_upload == '1'){
        return true;
      }
    }
    return false;
  }
  isFreeSubscription(){
    let currentUser=this.getCurrentUser();
    let isSubscribed = (currentUser ? currentUser.is_subscribed: false);
    if(isSubscribed  && currentUser.subscription_data){
      if(currentUser.subscription_data.subscription_type == '10'){
        return true;
      }
    }
    return false;
  }
  txtCopy(txt){
    var text = txt;
    var el:any = document.createElement("textarea");
    try {
      el.value = text;
      let l=0
      if(text && text.length){
        l=text.length
      }
      document.body.appendChild(el);
      el.select();
      /* for ios start*/
      if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
        var editable = el.contentEditable;
        var readOnly = el.readOnly;
        el.contentEditable = true;
        el.readOnly = false;
        var range = document.createRange();
        range.selectNodeContents(el);
        var sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
        el.setSelectionRange(0, l);
        el.contentEditable = editable;
        el.readOnly = readOnly;
      }
      /* for ios start*/
      document.execCommand("Copy");
      el.remove();
      text && this.snackbar("success", "Vin Copy to Clipboard");
    } catch (e) { el.remove(); }
  }

  txtCopyNexpart(txt){
    var text = txt;
    var el:any = document.createElement("textarea");
    try {
      el.value = text;
      let l=0
      if(text && text.length){
        l=text.length
      }
      document.body.appendChild(el);
      el.select();
      /* for ios start*/
      if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
        var editable = el.contentEditable;
        var readOnly = el.readOnly;
        el.contentEditable = true;
        el.readOnly = false;
        var range = document.createRange();
        range.selectNodeContents(el);
        var sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
        el.setSelectionRange(0, l);
        el.contentEditable = editable;
        el.readOnly = readOnly;
      }
      /* for ios start*/
      document.execCommand("Copy");
      el.remove();
      text && this.snackbar("success", "Labor description is copied to clipboard.");
    } catch (e) { el.remove(); }
  }
  diaLogObj(obj, data?: any) {
    try {
      if (data && data.result == 0 && (data.code == globalConstants.DIALOG_CLOSE_CODE || data.code == globalConstants.DIALOG_ACCESS_CODE)) {
        this.diaLog && this.diaLog.close();
        this.diaLog = null;
      } else if (obj) {
        this.diaLog = obj;
      } else {
        this.diaLog && this.diaLog.close();
        this.diaLog = null;
      }
    } catch (e) { }
  }
  formsData(obj) {
    var data = new FormData();
    for (let i in obj) {
      let val=(typeof obj[i] =="undefined" || obj[i]===null ? "" : obj[i] );
      data.append(i, val);
    }
    return data;
  }
  isInventories(){
    let currentUser:any=this.getCurrentUser();
    let isSubscribed = (currentUser ? currentUser.is_subscribed: false);
    if(isSubscribed  && currentUser.subscription_data){
      if(currentUser.subscription_data.parts_inventory == '1'){
        return true;
      }
    }
    return false;
  }

  public checkValidCountry(country:string){
    let valid = false;
    for(let ctr of this.validCountries){
      if(ctr == country){
        valid = true;
      }
    }
    return valid;
  }

  public verifyBuildVersion(type , buildVersion?:string){
    let user;
    if(type == 'shop'){
      user = JSON.parse(localStorage.getItem('loggedInUser'));
    }else if (type == 'customer'){
      user = JSON.parse(localStorage.getItem('loggedInCustomer'));
    }else if(type == 'admin'){
      user = JSON.parse(localStorage.getItem('loggedInAdmin'));
    }
    let latestVersion = Number(user.web_app_version_shops);
    if(buildVersion){
      latestVersion = Number(buildVersion);
    }
    let localVersion = Number(globalConstants.BUILD_VERSION);
      if(latestVersion > localVersion){
       // this.matSnackbar.openFromComponent(RefreshWebsiteSnackBarComponent);
       this.globalVariable.showRefresh = true;
      }
  }

  public isDeviceiOS(){
    return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
  }

  public getAuthParams(){
    const inURL = window.location.href.split('/');
    let jwtConfig:any='';
    if(typeof inURL[3]!='undefined' && inURL[3]=='shops'){
      jwtConfig =localStorage.getItem(globalConstants.SHOP_JWT_KEY);
    }else if(typeof inURL[3]!='undefined' && inURL[3]=='customer'){
      jwtConfig =localStorage.getItem(globalConstants.CUSTOMER_JWT_KEY);
    }else if(typeof inURL[3]!='undefined' && inURL[3]=='admin'){
      jwtConfig =localStorage.getItem(globalConstants.ADMIN_JWT_KEY);
    }
    return (jwtConfig) ? JSON.parse(jwtConfig) : {};
  }

  copyJob(type,data?:any){
    let pre:any=[];
    let tmp:any=this.storage.getItem('copyJobs');
    if(tmp){
      tmp=JSON.parse(tmp);
    }else{
      tmp=[];
    }
    if(type=='set'){
      if(!tmp){
        pre.push(data);
      }else{
        pre=tmp.filter((e,i)=>{
          return e.id!=data.id;
        })
        pre.push(data);
      }
     this.storage.setItem('copyJobs',JSON.stringify(pre));
     this.snackbar("success", "Job Copied to Clipboard");
    }else if(type=='reset'){
      this.storage.removeItem('copyJobs');
    }else if(tmp.length && type=='resetjob'){
      tmp.splice(data,1);
      this.storage.setItem('copyJobs',JSON.stringify(tmp));
    }
    return tmp;
  }
  
  isUserReportOn(){
    let user=this.getCurrentUser();
      if(user && user.is_report_generation==1){
        return true;
      }
      return false;
  }
  isFleet(){
    let user=this.getCurrentUser();
      if(user && user.profile_type=="2"){
        return true;
      }
      return false;
  }
  /** set service request id for note list */
  srnoteListId(lid){
    this.SRNoteListId.next(lid);
  }

  /** open/close service note list */
  setServiceNoteOpen(){
    let open=this.SRNoteListOpen.value
    if(open){
      this.SRNoteListOpen.next(!open)
    }else{
      this.SRNoteListOpen.next(!open)
    }
  }
  qqGrid(type){
    this.UpdateQQ.next(type);
  }

  ShowDateRange(status){
     this.isDateRange.next(status);
  }
  /** init Socket */
  initIO() {
    let proto=window.location.protocol
    let ioProto='ws'
    if(proto.indexOf('https')!=-1){
      ioProto='wss'
    }
    // this.socket = new WebSocket(ioProto+"://devsocket.completeautoreports.com"+this.ioPort);
    this.socket = new WebSocket(ioProto+"://54.236.77.112"+this.ioPort);
    var self = this
    this.socket.onopen = function (e) {
      this.ioConnected = true
    }
    this.socket.onmessage = function (e) {
      self.updateIO.next(e.data)
    };
    this.socket.onclose = function (e) {
      console.log("I'm sorry. Bye!", this.ioConnected);
    };
    this.socket.onerror = function (e) {
      console.log("ERR: " + e.data, this.ioConnected);
    };
  }
  //IO Send Message
  sendMessage(msg: any) {
    if(this.socket){
      this.socket.ioConnected && this.socket.send(JSON.stringify(msg));
    }
  }
  /** set customer detail */
  setCustomerData(data){
    this.customerData=data
  }
  /** customer is associated with fleet or not */
  isFleetCustomer(){
    let fleet=false
    if(this.customerData && typeof this.customerData.is_fleet_customer!='undefined'){
      fleet= (this.customerData.is_fleet_customer == 1 ? true : false)
    }
    return fleet;
  }
  /** return customer fleet id */
  customerFleet(){
    let fleet:any=0
    if(this.customerData && typeof this.customerData.fleet_shop_id!='undefined'){
      fleet= this.customerData.fleet_shop_id
    }
    return fleet;
  }
  /** Department OR Business Name */
  getBORDName() {
    let name = 'Business'
    if (this.isFleet()) {
      name = 'Department'
    }
    return name + ' Name'
  }
  /** set shop detail */
  setShopData(data){
    this.shopData=data
  }
  /** shop data assigned  */
  isDefaultDataAssigned(){
    let assigned=false
    if(this.shopData && typeof this.shopData.master_data_assignment_status!='undefined'){
      assigned= (this.shopData.master_data_assignment_status == 1 ? true : false)
    }
    return assigned;
  }

  setBootstrapModalInfo(id:string, info:ModalInfo){
    this.modalsInfoData[id] = info;
    // let modalsIds = JSON.parse(localStorage.getItem('minimizedWindows')) || [];
    // let tmpObj = {
    //   data: info.data,
    //   id: info.id,
    //   name: info.name,
    //   module: info.module
    // };
    // modalsIds.push(tmpObj);
    // localStorage.setItem('minimizedWindows', JSON.stringify(modalsIds));
    this.sendLatestModalsInfo();
  }
  
  getBootstrapModalInfo(id:string){
    if(this.modalsInfoData && (id in this.modalsInfoData)){
      return this.modalsInfoData[id];
    }
    else 
      return undefined;
  }
  
  getAllBootstrapModalsInfo(){
    return this.modalsInfoData;
  }
  
  clearBootstrapModal(id:string){
    delete this.modalsInfoData[id];
    // let modalsIds = JSON.parse(localStorage.getItem('minimizedWindows')) || [];
    // let idx = modalsIds.findIndex((m)=>{
    //   return m.id == id;
    // });
    // modalsIds.splice(idx,1);
    // localStorage.setItem('minimizedWindows', JSON.stringify(modalsIds));
    this.sendLatestModalsInfo();
  }
  
  clearAllBootstrapModal(){
    this.modalsInfoData.length = 0;
    this.modalsInfoData = [];
    // localStorage.removeItem('minimizedWindows');
    this.sendLatestModalsInfo();
  }
  
  private sendLatestModalsInfo(){
    this._modalsInfo.next(this.modalsInfoData);
  }
  
  setBootstrapModalRef(id:string, ref:BsModalRef){
    this.modalReferences[id] = ref;
  }
  
  getBootstrapModalRef(id:string):BsModalRef{
    if(this.modalReferences && (id in this.modalReferences)){
      return this.modalReferences[id];
    }
    else 
      return undefined;
  }
  
  setBootstrapModalDirective(id:string, dir:ModalDirective){
    this.modalDirectives[id] = dir;
  }
  
  getBootstrapModalDirective(id:string):ModalDirective{
    if(this.modalDirectives && (id in this.modalDirectives)){
      return this.modalDirectives[id];
    }
    else 
      return undefined;
  }
  
  updateBootstrapModalInfo(id:string, data:any){
    // console.log('update modal data = ', data);
    if(this.modalsInfoData && (id in this.modalsInfoData)){
      for(let x in data){
        this.modalsInfoData[id].data[x] = data[x];
        // if(x in this.modalsInfoData[id].data){
        // }
        // else{
        // }
      }
      this.sendLatestModalsInfo();
    }
    else 
      console.log('invalid id of popup');
  }
  
  openMinimizablePopup(component:any, info:{id:string, name:string, module?:string}, data?:any){
    let isModalPresent:ModalInfo = this.getBootstrapModalInfo(info.id);
    let initialState:any;
    if(!data){
      data = {};
    }
    data.isPopup = true;
    let bsModalRef;
    if(!isModalPresent){
      initialState = data;
      if(info.id== "customer-live-chat"){
        bsModalRef = this.modalService.show(component, {initialState , class: 'full-screen-modal customer-live-chat show-modal' });
      }else if(info.id=="Job-match-dailog"){
        bsModalRef = this.modalService.show(component, {initialState , class: 'job-suggestion-modal' });
      }
      else{
        bsModalRef = this.modalService.show(component, {initialState , class: 'service-history-block' });
      }
      this.setBootstrapModalRef(initialState.id, bsModalRef);
      this.setBootstrapModalInfo(initialState.id, {component: component, data: initialState, name: info.name, id: initialState.id, module:info.module});
    }
    else{
      initialState = isModalPresent.data;
      if(info.id== "customer-live-chat"){
          bsModalRef = this.modalService.show(isModalPresent.component, {initialState , class: 'full-screen-modal customer-live-chat show-modal' });
       }else if(info.id=="Job-match-dailog"){
        bsModalRef = this.modalService.show(component, {initialState , class: 'job-suggestion-modal' });
      }
       else{
          bsModalRef = this.modalService.show(isModalPresent.component, {initialState , class: 'service-history-block' });
       }
    }
    for(let x in initialState){
      bsModalRef.content[x] = initialState[x];
    }
  }
  
  setShopDetails(data:any){
    localStorage.setItem('shop-details',JSON.stringify(data));
  }
  
  getShopDetails(key?:any){
    let shopDetails:any = JSON.parse(localStorage.getItem('shop-details'));
    if(!key){
      return shopDetails;
    }
    return shopDetails[key];
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
        control.markAsDirty({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  isValidDate(d) {
    if (Object.prototype.toString.call(d) === "[object Date]") {
      if (isNaN(d)) { 
        return false;
      } else {
        return true;
      }
    } 
    return false;
  }

  getAPI(url: string): Observable<any> {
		return this.httpClient.get(url)
	}
  
  detectMobileScreen() {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];
    
    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
  }

  sortLabelsListInAlphabeticallyOrder(list:any){
   return list.sort((a,b) => a.label_name > b.label_name ? 1 : -1) 
  }

  passPasteObj(SRData){
    this.receiveDirectPasteResponse.next(SRData);
 }
 
  accordionOpenAnimation(ele){
    var target= '#expansion-'+ ele;
    setTimeout(() => {
       var body = $("html, body");
       body.animate({
              scrollTop: jQuery(target).offset().top - 90
        }, 500);
     }, 500);
  }

  globalNltoBr(val){
    if(val && val.length>0){
      return val.toString().replace(/\r\n/g, "<br />").replace(/\n/g, "<br />");
    }
    return '-';
  }

  getGlobalVehicleName(vehicleInfo:any){
    let vehicleName ="";
    if(vehicleInfo.year !=='' && vehicleInfo.year !=="0" ){
       vehicleName = vehicleInfo.year
    }
    if(vehicleInfo.make !=='' && vehicleInfo.make !== "0" ){
       if(vehicleInfo.year !=='' && vehicleInfo.year !=="0"){
        vehicleName += "/"+vehicleInfo.make;
       }else{
         vehicleName += vehicleInfo.make;
       }
    }
    if(vehicleInfo.model !=='' && vehicleInfo.model !=="0"){
      if(vehicleInfo.make !=='' && vehicleInfo.make !== "0" ){
         vehicleName += "/"+vehicleInfo.model;
      }else{
        vehicleName += vehicleInfo.model;
      }
    }
    if(vehicleInfo.sub_model !== '' && typeof(vehicleInfo.sub_model) !== 'undefined'){
       vehicleName += " ("+ vehicleInfo.sub_model+ ")";
    }
    return vehicleName;
  }

  handleWalkinProfileEditDelete(flagValue) {
    let msg = "This is walk-in customer/vehicle profile, edit/delete can not be performed.";
    this.snackbar("error", msg);
  }


  getCardTypeImage(cardNumber){
        let cardTypeImage='';
        //visa card
        var regex = new RegExp("^4");
        if (cardNumber.match(regex) != null)
           cardTypeImage= '<img src="../assets/img/payment.png"/ style="margin-left: -2px;">';

       //master
        regex = new RegExp("^5");
        if (cardNumber.match(regex) != null)
        cardTypeImage= '<img src="../assets/img/payment.png"/ style="margin-left: -63px;">';
            
        // AMEX
        regex = new RegExp("^3[47]");
        if (cardNumber.match(regex) != null)
        cardTypeImage= '<img src="../assets/img/payment.png"/ style="margin-left: -368px;">';
            
        // Discover
        regex = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
        if (cardNumber.match(regex) != null)
        cardTypeImage= '<img src="../../../assets/img/payment.png"/ style="margin-left: -245px;">';

        // Diners
        regex = new RegExp("^36");
        if (cardNumber.match(regex) != null)
        cardTypeImage= '<img src="../assets/img/payment.png"/ style="margin-left: -185px;">';

        // Diners - Carte Blanche
        regex = new RegExp("^30[0-5]");
        if (cardNumber.match(regex) != null)
          cardTypeImage= '<img src="../assets/img/payment.png"/ style="margin-left: -185px;">';

        // JCB
        regex = new RegExp("^35(2[89]|[3-8][0-9])");
        if (cardNumber.match(regex) != null)
          cardTypeImage= '<img src="../assets/img/payment.png"/ style="margin-left: -306px">';

        // Visa Electron
        regex = new RegExp("^(4026|417500|4508|4844|491(3|7))");
        if (cardNumber.match(regex) != null)
          cardTypeImage= '<img src="../assets/img/payment.png"/ style="margin-left: -124px;">';
            
       return cardTypeImage;
  }
  decodedWpiInfo(){
    let wpiInfo = localStorage.getItem('wpi');
    if ( wpiInfo!== null && typeof(wpiInfo) !== 'undefined') {
      let decodedWpiInfo: any = window.atob(wpiInfo);
      decodedWpiInfo = decodedWpiInfo.replaceAll("'", "\"");
      let planSummary :any = JSON.parse(decodedWpiInfo);
      return planSummary
    }else{
      return null;
    }
  }

  encodedWpiInfo(planData){
    let planInfoString = "{'planId':"+ planData.bt_plan_id+",'addOns':[]}";
    let encodeWpiInfo = window.btoa(planInfoString);
    localStorage.setItem("wpi",encodeWpiInfo);
  }
 /**
  * remove the WPi information form local storage 
 */
  removeWpiInfo(){
    localStorage.removeItem('wpi');
  }

  /**
   * Set the shop details in a global variable which are access in any component
   * @param shopDetails 
   */
  setShopDetailsGS(shopDetails){ // Draft
      this.shopDetails = shopDetails;
  }

  /**
   *Get the shop details form global variable  
   * @returns 
   */
  getShopDetailsGS(){ //Draft
     return this.shopDetails;
  }

  /**
   * Set the logged in user in a global variable which are access in any component
   */
  setUserDetailsGS(userDetails){ //Draft
      this.loginUserDetails = userDetails;
  }

  /**
   *Get the shop details form global variable  
   * @returns 
   */
  getUserDetailsGS(){  //Draft
     return this.loginUserDetails;
  }

  processDecimalValue(fieldValue, decimalPosition) {
    // Check if the string already contains a decimal
    if (fieldValue.length > decimalPosition - 1) {
      if (fieldValue.includes('.')) {
        return fieldValue; // Return the original string if it already has a decimal
      }
      // Split the string into two parts
      let part1 = fieldValue.slice(0, decimalPosition);
      let part2 = fieldValue.slice(decimalPosition);
      // Insert the decimal
      let result = part1 + '.' + part2;
      return result;
    } else {
      return fieldValue;
    }
  }

  validateFutureDate(fieldValue){
    if (fieldValue) {
      const formattedSelectedDate = moment(fieldValue, 'DD/MM/YYYY');
      const currentDate = moment();
      if (formattedSelectedDate.isAfter(currentDate, 'day')) {
        return true;
      } else if (formattedSelectedDate.isBefore(currentDate, 'day')) {
        return false;
      } 
    }
  }
}
