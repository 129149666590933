<div class="car-panel" id="mainCompotents">
  <div class="panel panel-primary">
    <div class="panel-heading padding-left-15">
      <h3 class="panel-title">Edit Shop</h3>
      <button mat-mini-fab class="custom-btn-back" (click)="globalService.goBack();">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
    <div class="panel-body bgcolor-lightgrey edit-shopblock">
      <!-- Add Customer page start -->
      <div class="row bgcolor-lightgrey form-content">
        <div class="container-fluid">
          <form [formGroup]="edit_shop" (ngSubmit)="EditShop()" id="addShopForm" class="form-inline col-xs-12 col-sm-12 col-md-12 col-lg-12 padding-bottom-15 edit-shop-form" role="form">

            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 form-block">
              <h2 class="fontcolor-blue sub-heading" *ngIf="roleId==1">GENERAL INFORMATION </h2>
              <div class="row" *ngIf="roleId==1">
                <div class="form-group  col-sm-12 col-md-6" *ngIf="currentShopDetails">
                  <div class='relative'>
                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                      <input matInput placeholder="Email Address*" title="Email" formControlName="email" maxlength="200"  class="form-control input-lg custom-form-input" [(ngModel)]= "currentShopDetails.email_address" />
                      <mat-error *ngIf="edit_shop.controls.email.hasError('pattern')">
                        Please enter a valid Email Address
                      </mat-error>
                      <mat-error *ngIf="edit_shop.controls.email.hasError('required')">
                        Email Address is <strong>required</strong>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-sm-12 padding-0">
                    <p class="help-block margin-0">Shop contact email address.</p>
                  </div>
                </div>
                <div class="form-group  col-sm-12 col-md-6" *ngIf="currentShopDetails">
                  <div class='relative'>
                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                      <input matInput placeholder="Company Name*" title="Company Name" formControlName="company_name" maxlength="200"  class="form-control input-lg custom-form-input" [(ngModel)]="currentShopDetails.company_name" />
                      <mat-error *ngIf="edit_shop.controls.company_name.hasError('required')">
                        Company Name is <strong>required</strong>
                      </mat-error>
                    </mat-form-field>

                  </div>
                  <div class="col-sm-12 padding-0">
                    <p class="help-block margin-0">Shop display name.</p>
                  </div>
                </div>
              </div>
              <h2 class="fontcolor-blue sub-heading">SHOP OWNER LOGIN INFORMATION</h2>
              <div class="row">
                <div class="form-group  col-sm-12 col-md-6" *ngIf="currentShopDetails">
                  <div class='relative'>
                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                      <input matInput placeholder="Login Email Address*" title="Login Email Address" formControlName="login_email" maxlength="200" class="form-control input-lg custom-form-input" [(ngModel)]="currentShopDetails.login_email" />
                      <mat-error *ngIf="edit_shop.controls.login_email.hasError('required')">
                        Email Address is<strong>required</strong>
                      </mat-error>
                      <mat-error *ngIf="edit_shop.controls.login_email.hasError('pattern')">
                        Please enter a valid email address.
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-sm-12 padding-0">
                    <p class="help-block margin-0">Shop owner login email address.</p>
                  </div>
                </div>
                <div class="form-group  col-sm-12 col-md-6" *ngIf="currentShopDetails">
                  <div class='relative'>
                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                      <input matInput placeholder="Owner Name*" title="Owner Name" formControlName="owner_name" maxlength="200" class="form-control input-lg custom-form-input" [(ngModel)]="currentShopDetails.owner_name"  />
                      <mat-error *ngIf="edit_shop.controls.owner_name.hasError('required')">
                        Owner Name is <strong>required</strong>
                      </mat-error>
                      <mat-error *ngIf="edit_shop.controls.owner_name.hasError('pattern')">
                        Owner name is invalid. Must not contain any special characters.
                      </mat-error>
                    </mat-form-field>

                  </div>
                  <div class="col-sm-12 padding-0">
                    <p class="help-block margin-0">Shop owner name.</p>
                  </div>
                </div>
              </div>

              <h2 class="fontcolor-blue sub-heading">CONTACT INFORMATION</h2>
              <div class="row">
                <div class="form-group  col-sm-12 col-md-6" *ngIf="currentShopDetails">
                  <div class='relative'>
                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                      <input matInput placeholder="Address Line 1*" title="Address Line 1" formControlName="address_one" maxlength="200" class="form-control input-lg custom-form-input" [(ngModel)]="currentShopDetails.address1"  />
                      <mat-error *ngIf="edit_shop.controls.address_one.hasError('required')">
                        Address Line 1 is <strong>required</strong>
                      </mat-error>
                    </mat-form-field>

                  </div>
                </div>
                <div class="form-group  col-sm-12 col-md-6" *ngIf="currentShopDetails">
                  <div class='relative'>
                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                      <input matInput placeholder="Address Line 2" title="Address Line 2" formControlName="address_two" maxlength="200" class="form-control input-lg custom-form-input" [(ngModel)]="currentShopDetails.address2" />
                    </mat-form-field>

                  </div>
                </div>
              </div>
              <div class="row">
                  
                  <div class="form-group  col-sm-12 col-md-6" *ngIf="currentShopDetails">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                        <input  matInput  placeholder="{{zipLabel}}*"
                         title="{{zipLabel}}"  #zipcode (input)="zipcode.value = zipcode.value.toUpperCase()"
                          formControlName="zipcode"  class="form-control input-lg custom-form-input"
                           maxlength="15" [(ngModel)]= "currentShopDetails.zip" 
                           (blur)="setCountryStateCityViaZipCode()"
                            />
                        <mat-error *ngIf="edit_shop.controls.zipcode.hasError('pattern')">
                          Please enter valid {{zipLabel.toLowerCase()}}
                        </mat-error>
                        <mat-error *ngIf="edit_shop.controls.zipcode.hasError('required')">
                          {{zipLabel}} is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="form-group  col-sm-12 col-md-6 uc-ngx-select">
                  <ngx-select class="display-full margin-bottom-20"       
                     [items]="countries" 
                     [(ngModel)]="selectedCountry" 
                     [ngModelOptions]="{standalone: true}"
                     (select)="handleCountryDropdownChange($event)"
                     placeholder="Select Country">
                  </ngx-select>
                </div>
                
              </div>
            
              <div class="row">
                <div class="form-group  col-sm-12 col-md-6 uc-ngx-select">
                  <ngx-select class="display-full margin-bottom-20"         
                      [items]="states" 
                      [(ngModel)]="selectedState" 
                      [ngModelOptions]="{standalone: true}" 
                      (select)="handleStateDropdownChange($event)"
                      [disabled]="disableStates"
                      placeholder="Select State">
                  </ngx-select>
                  <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
                      <mat-error *ngIf="edit_shop.controls.state.hasError('required')">
                          State is <strong>required</strong>
                      </mat-error>
                  </div>

              </div>
                  <div class="form-group  col-sm-12 col-md-6 uc-ngx-select">
                    <ngx-select class="display-full margin-bottom-20" 
                    [items]="cities" 
                    [(ngModel)]="selectedCity" 
                    [ngModelOptions]="{standalone: true}" 
                    (select)="handleCityDropdownChange($event)"
                    [disabled]="disableCities"
                    placeholder="Select City">
                  </ngx-select>
                      <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
                          <mat-error *ngIf="edit_shop.controls.city.hasError('required')">
                              City is <strong>required</strong>
                          </mat-error>
                      </div>
                  </div>
              </div>
              
              <div class="row">
                <div class="form-group  col-sm-12 col-md-6" *ngIf="currentShopDetails">
                  <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                    <input  matInput placeholder="Business Phone 1" title="Business Phone 1" formControlName="business_phone1"  class="form-control input-lg custom-form-input"  maxlength="15" [textMask]="{mask: phoneMask}" [(ngModel)]= "currentShopDetails.business_phone1" (keyup)="phoneValidation('business_phone1')"/>
                    <mat-error *ngIf="edit_shop.controls.business_phone1.hasError('business_phone1')">
                      Invalid Business Phone 1
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="form-group  col-sm-12 col-md-6" *ngIf="currentShopDetails">
                  <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                    <input  matInput placeholder="Business Phone 2" title="Business Phone 2" formControlName="business_phone2"  class="form-control input-lg custom-form-input"  maxlength="15" [textMask]="{mask: phoneMask}" [(ngModel)]= "currentShopDetails.business_phone2" (keyup)="phoneValidation('business_phone2')"/>
                    <mat-error *ngIf="edit_shop.controls.business_phone2.hasError('business_phone2')">
                      Invalid Business Phone 2
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="form-group  col-sm-12 col-md-6" *ngIf="currentShopDetails">
                  <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                    <input  matInput placeholder="Cell Phone 1" title="Cell Phone 1" formControlName="cell_phone1"  class="form-control input-lg custom-form-input" maxlength="15" [textMask]="{mask: phoneMask}" [(ngModel)]= "currentShopDetails.cell_phone1" (keyup)="phoneValidation('cell_phone1')" />
                    <mat-error *ngIf="edit_shop.controls.cell_phone1.hasError('cell_phone1')">
                      Invalid Cell Phone 1
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="form-group  col-sm-12 col-md-6" *ngIf="currentShopDetails">
                  <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                    <input  matInput placeholder="Cell Phone 2" title="Cell Phone 2" formControlName="cell_phone2"  class="form-control input-lg custom-form-input" maxlength="15" [textMask]="{mask: phoneMask}" [(ngModel)]= "currentShopDetails.cell_phone2" (keyup)="phoneValidation('cell_phone2')"/>
                    <mat-error *ngIf="edit_shop.controls.cell_phone2.hasError('cell_phone2')">
                      Invalid Cell phone 2
                    </mat-error>
                  </mat-form-field>
                </div>

              </div>
              <div class="row">
                <div class="form-group  col-sm-12 col-md-6" *ngIf="currentShopDetails">
                  <div class='relative'>
                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                      <input matInput  placeholder="Enter Website URL" title="Enter Website URL" formControlName="website"  class="form-control input-lg custom-form-input" [(ngModel)]= "currentShopDetails.web_address"  maxlength="200"/>
                      <mat-error *ngIf="edit_shop.controls.website.hasError('pattern')">
                        Please enter a valid Website URL
                      </mat-error>
                    </mat-form-field>

                  </div>
                  <div class="col-sm-12" style="padding:0px;">
                    <p class="help-block" style="margin:0px;">Ex: http://www.websitename.com</p>
                  </div>
                </div>
              </div>

              <h2 class="fontcolor-blue sub-heading">TAXES SETTINGS</h2>
              <div class="row">
                <div class="form-group  col-sm-12 col-md-6" *ngIf="currentShopDetails" [class.hide]="isAllowed('edits','tax')==false">
                  <div class='relative'>
                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                      <input matInput placeholder="Tax Rate" title="Tax Rate" formControlName="tax_rate" class="form-control input-lg custom-form-input" maxlength="10" [(ngModel)]= "currentShopDetails.tax_rate" (keydown)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event);convertIntoDecimal($event,'tax_rate',7,5)" />
                      <mat-error *ngIf="edit_shop.controls.tax_rate.hasError('required')">
                        Tax Rate is <strong>required</strong>
                      </mat-error>
                      <mat-error *ngIf="edit_shop.value.tax_rate > 100">
                        Percentage should not exceed 100.
                      </mat-error>
                      <mat-error *ngIf="edit_shop.controls.tax_rate.hasError('pattern') && !(edit_shop.value.tax_rate > 100)">
                        Tax Rate must be a number with upto 4 decimal places.
                      </mat-error>
                    </mat-form-field>

                  </div>
                  <div class="col-sm-12" style="padding:0px;">
                    <p class="help-block" style="margin:0px;">Enter 0.00 if taxes are not applicable.</p>
                  </div>
                </div>
                <div class="form-group  col-sm-12 col-md-6" *ngIf="isAllowed('views','tax') && isAllowed('edits','tax')==false ">
                  <div class='relative'>
                     <label> Tax Rate </label> : {{ currentShopDetails.tax_rate }}
                  </div>
                </div>

                <div class="form-group  col-sm-12 col-md-6" *ngIf="currentShopDetails && isAllowed('edits','tax')">
                  <div class='relative'>
                    <mat-checkbox class="btn-block" labelPosition="after" (change)="showTaxDefault($event)" [checked]= "this.edit_shop.value.include_tax_default">
                      Include Taxes (Default Setting)
                    </mat-checkbox>
                  </div>
                  <div class="col-sm-12" style="padding:0px;">
                    <p class="help-block" style="margin:0px;">You can control tax inclusion on service tickets also.</p>
                  </div>
                </div>

                <div class="form-group  col-sm-12 col-md-6" *ngIf="currentShopDetails && isAllowed('edits','tax')">
                  <div class='relative'>
                    <mat-checkbox class="btn-block" labelPosition="after" (change)="showLaborTaxExcluded($event)" [checked]= "this.edit_shop.value.labor_tax_excluded">
                      Exclude Labor Taxes (Default Setting)
                    </mat-checkbox>
                  </div>
                  <div class="col-sm-12" style="padding:0px;">
                    <p class="help-block" style="margin:0px;">You can control labor tax inclusion on service tickets also.</p>
                  </div>
                </div>

              </div>

              
              <h2 class="fontcolor-blue sub-heading">SUPPLY CHARGES SETTINGS</h2>
              <div class="row">
                <!-- <div class="col-sm-12 col-md-12"> -->
                  <!-- <div class="col-sm-3 col-md-3 col-lg-2 padding-left-0">
                    <label>Supply Charges in</label>
                  </div> -->
                  <!-- <div class="col-sm-12 col-md-9 col-lg-4 amount-responsive"> -->
                  <div class="col-sm-12">
                    <p>Supply charges will be charged in:</p>
                  </div>  
                  <div class="col-sm-12">
                    <label class="material-radio">
                      <input type="radio" name="supply_charges_disp_type" [checked]="edit_shop.value.supply_charges_disp_type=='1'"  (click)="supplyChargeType($event,1)"  value="1"  />
                      <span class="margin-right-10">Amount</span>
                    </label>
                    <label class="material-radio">
                      <input type="radio" name="supply_charges_disp_type" [checked]="edit_shop.value.supply_charges_disp_type=='2'" (click)="supplyChargeType($event,2)" value="2"   />
                      <span>Percentage</span>
                    </label>
                  </div>
                <!-- </div> -->
                <div class="form-group col-sm-12" [class.col-md-6]="edit_shop.controls['supply_charges_disp_type'].value != 2" [class.col-md-3]="edit_shop.controls['supply_charges_disp_type'].value == 2" *ngIf="currentShopDetails" [class.hide]="isAllowed('edits','add_supplies_invoice')==false">
                  <div class='relative'>
                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                      <input matInput placeholder="Supply Charges" title="Supply Charges" formControlName="supply_charges"  class="form-control input-lg custom-form-input" [(ngModel)]= "currentShopDetails.supplies_charges" (keydown)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event);convertIntoDecimal($event,'supply_charges',7,3)" maxlength="10" />
                      <mat-error *ngIf="edit_shop.controls.supply_charges.hasError('required')">
                        Supply Charges is <strong>required</strong>
                      </mat-error>
                      <mat-error *ngIf="edit_shop.controls.supply_charges.invalid  && edit_shop.value.supply_charges!='' && edit_shop.value.supply_charges < 1">
                        Percentage should be greater than 0.
                      </mat-error>
                      <mat-error *ngIf="edit_shop.value.supply_charges > 100 && edit_shop.controls['supply_charges_disp_type'].value == 2">
                        Percentage should not exceed 100.
                      </mat-error>
                      <mat-error *ngIf="edit_shop.controls.supply_charges.hasError('pattern') && edit_shop.controls['supply_charges_disp_type'].value == 2 && !(edit_shop.value.supply_charges > 100)">
                        Supply Charges must be upto 2 decimal places.
                     </mat-error>
                     <mat-error *ngIf="edit_shop.controls.supply_charges.hasError('pattern') && edit_shop.controls['supply_charges_disp_type'].value == 1">
                      Supply Charges must be upto 2 decimal places.
                   </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-sm-12" style="padding:0px;">
                    <p class="help-block" style="margin:0px;">Enter 0.00 if supply charges are not applicable.</p>
                  </div>
                </div>
                <div class="form-group  col-sm-12 col-md-3" *ngIf="currentShopDetails" [class.hide]="isAllowed('edits','add_supplies_invoice')==false || edit_shop.controls['supply_charges_disp_type'].value != 2">
                  <div class='relative'>
                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                      <input matInput placeholder="Supply Charges Limit" title="Supply Charges Limit" formControlName="supply_charges_limit"  class="form-control input-lg custom-form-input" [(ngModel)]= "currentShopDetails.supply_charges_limit" (keydown)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event);validateNumbersWithDecimal($event,3)" maxlength="10"/>
                      <mat-error *ngIf="edit_shop.controls.supply_charges_limit.hasError('pattern')">
                        Supply Charges must be upto 2 decimal places.
                     </mat-error>
                    </mat-form-field>
                    <div class="col-sm-12" style="padding:0px;">
                      <p class="help-block" style="margin:0px;">Value should always be provided in absolute amount for supply charge limit.</p>
                    </div>
                  </div>
                </div>
                <div class="form-group  col-sm-12" [class.col-md-6]="edit_shop.controls['supply_charges_disp_type'].value != 2" [class.col-md-3]="edit_shop.controls['supply_charges_disp_type'].value == 2" *ngIf="isAllowed('views','add_supplies_invoice') && isAllowed('edits','add_supplies_invoice')==false ">
                  <div class='relative' >
                    <label>  Supply Charges </label> : {{currentShopDetails.supplies_charges}}
                  </div>
                </div>
                <div class="form-group  col-sm-12 col-md-3" *ngIf="isAllowed('views','add_supplies_invoice') && isAllowed('edits','add_supplies_invoice')==false ">
                  <div class='relative' >
                    <label>  Supply Charges Limit </label> : {{currentShopDetails.supply_charges_limit}}
                  </div>
                </div>
                <div class="form-group  col-sm-12 col-md-6" *ngIf="currentShopDetails " [class.hide]="isAllowed('edits','add_supplies_invoice')==false">
                  <div class='relative'>
                    <mat-checkbox class="btn-block" labelPosition="after" (change)="showSupplyCharges($event)" [checked]="edit_shop.value.include_supply_charges">
                      Include Supply Charges (Default Setting)
                    </mat-checkbox>
                  </div>
                  <div class="col-sm-12" style="padding:0px;">
                    <p class="help-block" style="margin:0px;">You can control supply charges inclusion on service tickets also.</p>
                  </div>
                </div>
              </div>

              <h2 class="fontcolor-blue sub-heading">CC FEES</h2>
              <div class="row">
                <!-- <div class="col-sm-12 col-md-12"> -->
                  <!-- <div class="col-sm-3 col-md-3 col-lg-2 padding-left-0">
                    <label>CC Fees</label>
                  </div> -->
                  <!-- <div class="col-sm-9 col-md-9 col-lg-4 amount-responsive"> -->
                  <div class="col-sm-12"><p>CC fees will be charged in:</p></div>
                  <div class="col-sm-12">
                    <label class="material-radio">
                      <input type="radio" name="cc_fee_type" [checked]="edit_shop.value.cc_fee_type=='1'"  (click)="CCChargeType($event,1)"  value="1"  />
                      <span class="margin-right-10">Amount</span>
                    </label>
                    <label class="material-radio">
                      <input type="radio" name="cc_fee_type" [checked]="edit_shop.value.cc_fee_type=='2'" (click)="CCChargeType($event,2)" value="2"   />
                      <span>Percentage</span>
                    </label>
                  </div>
                <!-- </div> -->
                <div class="form-group col-md-6 col-sm-12" *ngIf="currentShopDetails">
                  <div class='relative'>
                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                      <input matInput placeholder="CC Fees" title="CC Fees" formControlName="cc_fee"  class="form-control input-lg custom-form-input" maxlength="15" [(ngModel)]= "currentShopDetails.cc_fee" (keydown)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event);convertIntoDecimal($event,'cc_fee',7,3)"  maxlength="10"/>
                      <mat-error *ngIf="edit_shop.controls.cc_fee.hasError('required')">
                        CC Fee is <strong>required</strong>
                      </mat-error>
                    <mat-error *ngIf="edit_shop.value.cc_fee > 100 && edit_shop.controls['cc_fee_type'].value == 2">
                      Percentage should not exceed 100.
                    </mat-error>
                    <mat-error
                      *ngIf="edit_shop.controls.cc_fee.hasError('pattern') && edit_shop.controls['cc_fee_type'].value == 2 && !(edit_shop.value.cc_fee > 100)">
                      CC Fees must be a number with 2 decimal places.
                    </mat-error>
                    <mat-error *ngIf="edit_shop.controls.cc_fee.hasError('pattern') && edit_shop.controls['cc_fee_type'].value == 1">
                      CC Fees must be a number with 2 decimal places.
                    </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-sm-12" style="padding:0px;">
                    <p class="help-block" style="margin:0px;">Enter 0.00 if CC Fees is not applicable.</p>
                  </div>
                </div>
                <!-- <div class="form-group  col-sm-12 col-md-6" *ngIf="currentShopDetails">
                  <div class='relative'>
                    <mat-checkbox class="btn-block" labelPosition="after" (change)="showCCFees($event)" [checked]="edit_shop.value.cc_fee_included">
                      Include CC Fees (Default Setting)
                    </mat-checkbox>
                  </div>
                  <div class="col-sm-12" style="padding:0px;">
                    <p class="help-block" style="margin:0px;">You can control cc fees inclusion on service tickets also.</p>
                  </div>
                </div> -->
              </div>


              <h2 class="fontcolor-blue sub-heading">BUSINESS LOGO</h2>
              <div class="row">
                <div class="form-group  col-sm-12 col-md-6">
                  <!-- <div class="col-sm-12" style="padding:0px;">
                    <label for="partPic">Business Logo</label>
                  </div> -->

                  <div class="col-sm-12 file-upload-container" style="padding:0px;">
                    <input id="businessLogoImagedata" name="business_logo_imagedata" value="" type="hidden" formControlName="business_logo_imagedata">
                    <mat-toolbar class="mat-elevation-z2 margin-bottom-15 upload-btn relative">
                      <mat-icon class="margin-right-10">cloud_upload</mat-icon>Drag File Here
                      <input type="file" id="businessLogo" name="business_logo" accept="image/*" (change)="getFile($event,'business_logo_imagedata')" formControlname="business_logo_imagedata"/>
                      <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
                    </mat-toolbar>
                    <div class="margin-bottom-5">
                      <p class="help-block" style="margin:0px;">Formats supported : png, jpeg, jpg</p>
                      <p class="help-block" style="margin:0px;">Maximum size : 10MB</p>
                    </div>
                  </div>
                  <div class="col-sm-12" style="padding:0px;">
                    <ul class="list-inline upload-image margin-bottom-0">
                      <li *ngIf="currentBusinesslogo!='' && currentSelectedLogo==''">
                        <a href="javascript:void(0);" class="img-set thumbnail relative">
                          <img class="show-image" src="{{businessLogo}}{{currentBusinesslogo}}" />
                          <i class="fa fa-trash remove-img" (click)="deleteBusinessLogo()"></i>
                          <div class="image-loader" *ngIf="deleteLogo">
                            <mat-progress-spinner color="accent" mode="indeterminate" strokeWidth="2" diameter="30"></mat-progress-spinner>
                          </div>
                        </a>
                      </li>
                      <li *ngIf="currentSelectedLogo!=''">
                        <a href="javascript:void(0);" class="img-set thumbnail relative">
                          <img class="show-image" id="base64image" src="{{currentSelectedLogo}}" />
                          <i class="fa fa-trash remove-img" (click)="deleteCurrentLogo()"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
    
              <h2 class="fontcolor-blue sub-heading">SERVICE REQUEST SETTINGS</h2>
              <div class="row">
                <div class="form-group  col-sm-12 col-md-6">
                  <div class='relative'>
                    <mat-checkbox class="btn-block" labelPosition="after" (change)="skipForever($event)" [checked]="edit_shop.value.skip_forerver==1">
                      Skip Forever
                    </mat-checkbox>
                  </div>
                  <div class="col-sm-12 padding-0">
                    <p class="help-block">Skip tickets assignment.</p>
                  </div>
                  <div class='relative'>
                    <mat-checkbox class="btn-block" labelPosition="after" (change)="handleTimerControl($event)" [checked]="edit_shop.value.timer_control_status=='1'">
                      Turn Off Timer
                    </mat-checkbox>
                  </div>
                  <div class="col-sm-12 padding-0">
                    <p class="help-block">If checked off, timer controls will not be prompted on service request and fill recommendation pages</p>
                  </div>
                  <div class='relative'>
                    <mat-checkbox class="btn-block" labelPosition="after" (change)="allowAvailableByapssApproval($event)" [checked]="edit_shop.value.allow_available=='1'">
                      Bypass Customer Approval
                    </mat-checkbox>
                  </div>
                  <div class="col-sm-12 padding-0">
                    <p class="help-block">Customer approval will be by passed if this is turned on.</p>
                  </div>
                  <div class='relative'>
                    <mat-checkbox class="btn-block" labelPosition="after" (change)="togglePaymentBalancePopup($event)" [checked]="edit_shop.value.payment_balance_popup=='1'">
                      Payment Balance Popup Display
                    </mat-checkbox>
                  </div>
                  <div class="col-sm-12 padding-0">
                    <p class="help-block">While creating a new service request, system will display past due for the selected customer.</p>
                  </div>
                  <div class='relative'>
                    <mat-checkbox class="btn-block" labelPosition="after" (change)="srTitleRequired($event)" [checked]="edit_shop.value.sr_title_required=='1'">
                      SR Title Required
                    </mat-checkbox>
                  </div>
                  <div class="col-sm-12 padding-0">
                    <p class="help-block">Service request's title is required, while creating a new service request.</p>
                  </div>                 
                  <!-- <div class='relative'>
                    <mat-checkbox class="btn-block" labelPosition="after" (change)="toggleNoAddress($event)" [checked]="edit_shop.value.no_customer_address=='1'">
                      No Address (Default Setting)
                    </mat-checkbox>
                  </div> -->
              </div>
              </div>


              <h2 class="fontcolor-blue sub-heading">TICKET ASSIGNMENT SETTINGS</h2>
              <div class="row" >
                  <div class="form-group col-sm-12 col-md-6 uc-ngx-select">
                    <label>Timeslot Suggestion Lookup Days: </label>
                    <ngx-select class="display-full margin-bottom-20" 
                        [items]="lookupDay" 
                        [(ngModel)]="timeslot_suggestions_lookup_days" 
                        [ngModelOptions]="{standalone: true}" 
                        [defaultValue]=""
                        (select)="getSelectedOptionLookUp($event)"
                        placeholder="Select Timeslot Suggestion Lookup Days">
                    </ngx-select>
                    <div class="col-sm-12 padding-0">
                      <p class="help-block margin-0">Check X number of days in employees schedule and provide available timeslots suggestions.</p>
                    </div>
                  </div>
              </div>

              <h2 class="fontcolor-blue sub-heading">CUSTOMER PROFILE SETTINGS</h2>
              <div class="row">
                <div class="form-group  col-sm-12">
                  <div class='relative'>
                    <mat-checkbox class="btn-block" labelPosition="after" (change)="toggleNoAddress($event)" [checked]="edit_shop.value.no_customer_address=='1'">
                      No Address (Default Setting)
                    </mat-checkbox>
                  </div>
                  <div class="col-sm-12 padding-0">
                    <p class="help-block margin-0">If checked off, Address will be optional in customer profile. You can anyway control the validation on customer creation screen.</p>
                  </div>
                </div>
              </div>


              <h2 class="fontcolor-blue sub-heading">TIMEZONE SETTINGS</h2>
              <div class="row">
                <div class="form-group col-sm-12 col-md-6 uc-ngx-select time-zone-select">
                  <label>Timezone: </label>
                  <ngx-select class="display-full" 
                      [items]="timezoneData" 
                      [(ngModel)]="timezone" 
                      [ngModelOptions]="{standalone: true}" 
                      [defaultValue]=""
                      (select)="getSelectedOptionTimezone($event)"
                      placeholder="Select Timezone">
                  </ngx-select>
                  <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
                      <mat-error *ngIf="edit_shop.controls.timezone.hasError('required')">
                          Timezone is <strong>required</strong>
                      </mat-error>
                  </div>
                  <div class="col-sm-12 padding-0 margin-top-5">
                    <p class="help-block margin-0">Select a specific timezone from the list. Changing the timezone would change the date/time site wide.</p>
                  </div>
                </div>
              </div>
      
             <!-- End USER TIME Row -->
              <div class="row">
                <div class=" col-xs-12 form-block">
                    <h2 class="fontcolor-blue sub-heading">WORK SCHEDULE</h2>
                    <mat-error *ngIf="!edit_shop.controls.selectedItems.valid">
                        Please select at least one weekday.
                    </mat-error>

                    <div class="row work-schedule-wrapper" formArrayName="shop_timings"
                        *ngFor="let timing of edit_shop.get('shop_timings')['controls']; let i = index;">
                        <div class="col-sm-12 col-md-12" [formGroupName]="i">
                            <div class="row">
                                <div class="col-sm-2 col-xs-2">
                                    <div class="day-fields">
                                        <ng-container [ngSwitch]="i">
                                          <label *ngSwitchCase="0">Monday</label>
                                          <label *ngSwitchCase="1">Tuesday</label>
                                          <label *ngSwitchCase="2">Wednesday</label>
                                          <label *ngSwitchCase="3">Thursday</label>
                                          <label *ngSwitchCase="4">Friday</label>
                                          <label *ngSwitchCase="5">Saturday</label>
                                          <label *ngSwitchCase="6">Sunday</label>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-sm-1 col-xs-1">
                                    <div class="row">
                                        <div class="form-group weak-checkbox col-xs-12">
                                            <mat-checkbox class="btn-block padding-top-10" labelPosition="after"
                                                formControlName="weekdayid" (change)="updateDefaultTime(i)">
                                            </mat-checkbox>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-9 col-xs-12">
                                    <div class="row">
                                        <div class="form-group  col-xs-6 uc-ngx-select">
                                            <ngx-select class="display-full" [items]="timeSlots" formControlName="start_time"
                                                placeholder="Start Time" optionTextField="text">
                                            </ngx-select>
                                            <mat-error class="dp-error" *ngIf="timing.get('start_time').hasError('required')">
                                              Start Time <strong>required</strong>
                                            </mat-error>
                                            <mat-error  class="dp-error" *ngIf="timing.errors">
                                                {{ timing.errors?.dates }}
                                            </mat-error>
                                        </div>
                                        <div class="form-group  col-xs-6 uc-ngx-select">
                                            <ngx-select class="display-full" [items]="timeSlots" formControlName="close_time"
                                                placeholder="End Time" optionTextField="text">
                                            </ngx-select>
                                            <mat-error class="dp-error" *ngIf="timing.get('close_time').hasError('required')">
                                                End Time <strong>required</strong>
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
              </div>
             <!-- End USER TIME Row -->

              <!-- 
                <div class="row" *ngIf="getShopDetailComplete==false">
                  <div class="form-group  col-sm-12 col-md-6">
                      <div class="row">
                          <div class="col-sm-12">
                    <label class="pull-left">Open time: <span [innerHTML]="openingTime"></span> </label>
                    <label class="pull-right">Close time : <span [innerHTML]="closeingTime"></span> </label>
                    </div>
                  </div>
                    <nouislider [config]="someKeyboardConfig" (change)="rangSliders($event)"></nouislider>
                  </div>
                </div> 
                <div class="row" *ngIf="getShopDetailComplete">
                    <div class="form-group  col-sm-12 col-md-6">
                        <div class="row">
                          <div class="col-sm-12">
                            <label class="pull-left">Open Time: <span [innerHTML]="openingTime"></span> </label>
                            <label class="pull-right">Close Time : <span [innerHTML]="closeingTime"></span> </label>
                          </div>
                        </div>
                      <nouislider [config]="shopTimeConfig" (change)="rangSliders($event)"></nouislider>
                    </div>
                </div>
              -->
      
              <h2 class="fontcolor-blue sub-heading" [class.hide]="isInventory==false">Parts Inventory</h2>
              <div class="row" [class.hide]="isInventory==false">
                <div class="form-group  col-sm-12 col-md-6 ">
                  <label>Low Stock Value:</label>
                  <div class='relative'>
                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                      <input matInput maxlength="15" placeholder="Low Stock Value" title="Low Stock Value" formControlName="default_low_stock_value"  class="form-control input-lg custom-form-input" [(ngModel)]= "currentShopDetails.default_low_stock_value" (keydown)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event)" />
                    </mat-form-field>
                  </div>
                </div>
                <div class="form-group  col-sm-12 col-md-6 ">
                  <label>Low Stock Notification:</label>
                  <div class='relative'>
                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                      <mat-select placeholder="Select Duration" class="form-control input-lg custom-form-input" formControlName="parts_stock_notification" [(ngModel)]= "currentShopDetails.parts_stock_notification" >
                        <mat-option value="999">Week Day</mat-option>
                        <mat-option value="7">Weekly</mat-option>
                        <mat-option value="14">Bi-weekly</mat-option>
                        <mat-option value="30">Monthly</mat-option>
                        <mat-option value="0">None</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="row" [class.hide]="isInventory==false">
                <div class="form-group  col-sm-12 col-md-6">
                  <div class="row">
                    <div class="col-sm-12">
                      <label class="pull-left">Notify time: <span [innerHTML]="notifyTime"></span> </label>
                    </div>
                  </div>
                  <nouislider *ngIf="showDefaultNotifyTime==false" [config]="notifyRangeConfig" (change)="notifyRangSliders($event)"></nouislider>
                  <nouislider *ngIf="showDefaultNotifyTime" [config]="notifyRangeConfig" (change)="notifyRangSliders($event)"></nouislider>
                </div>
                <div class="form-group  col-sm-12 col-md-6 " [class.hide]="currentShopDetails.parts_stock_notification!='999'">
                  <label>Select Weekday:</label>
                  <div class='relative'>
                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                      <mat-select placeholder="Select Weekday" class="form-control input-lg custom-form-input" formControlName="parts_notify_weekday" [(ngModel)]="currentShopDetails.part_notify_weekday" >
                        <mat-option value="">Select Weekday</mat-option>
                        <mat-option value="Monday">Monday</mat-option>
                        <mat-option value="Tuesday">Tuesday</mat-option>
                        <mat-option value="Wednesday">Wednesday</mat-option>
                        <mat-option value="Thursday">Thursday</mat-option>
                        <mat-option value="Friday">Friday</mat-option>
                        <mat-option value="Saturday">Saturday</mat-option>
                        <mat-option value="Sunday">Sunday</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>


              <h2 class="fontcolor-blue sub-heading">EMAIL PREFERENCES SETTINGS</h2>
              <div class="row">
                <div class="form-group  col-sm-12 col-md-6">
                  <div class='relative'>
                    <mat-checkbox class="btn-block" labelPosition="after" (change)="handleNoEmails($event)" [checked]="edit_shop.value.no_emails_owner=='1'">
                      No Emails
                    </mat-checkbox>
                  </div>
                  <div class="col-sm-12 padding-0"><p class="help-block">Turn On/Off email notifications.</p></div>
                </div>
              </div>

              <h2 class="fontcolor-blue sub-heading">PRIVATE REVIEW SETTINGS</h2>
              <div class="row">
                <div class="col-sm-12">
                  <p class="help-block margin-0">The settings below are for private review link to be included in the invoice email.</p>
                </div>
              </div>
              <div *ngIf="currentShopDetails.private_review == 1">
                <div class="row review-settings-row" >
                <div class="form-group  col-sm-12 col-md-6">
                  <label>Review Notes:</label>
                  <div class='relative details-input review-input'>
                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">  
                    <textarea matInput placeholder="Enter Review Notes" formControlName="review_notes"></textarea>
                    <mat-error *ngIf="edit_shop.controls.review_notes.hasError('required')">
                      Review notes is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                  </div>
                </div>
              
                <div class="form-group  col-sm-12 col-md-6">
                  <div class='relative'>
                    <mat-checkbox class="btn-block" labelPosition="after" (change)="handleIncludeReviewNotes($event.checked)" [checked]="edit_shop.value.review_notes_included=='1'">
                      Include in invoice email
                    </mat-checkbox>
                  </div>
                  <div class="col-sm-12 padding-0">
                    <p class="help-block margin-0">If checked off, review notes will be included in invoice email notification.</p>
                  </div>
                </div>
                </div>
                 <div class="row review-url-row">
                <div class="form-group  col-sm-12 col-md-6">
                  <label>Review Url:</label>
                  <div class='relative review-url-input'>
                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">  
                      <input matInput placeholder="Enter Review Url (Example: https://example.com)" formControlName="review_url"  class="form-control input-lg custom-form-input"  />
                      <mat-error *ngIf="edit_shop.controls.review_url.hasError('required')">
                        Review url is <strong>required</strong>
                      </mat-error>
                      <mat-error *ngIf="edit_shop.controls.review_url.hasError('pattern')">
                        Please enter a valid URL
                      </mat-error>
                  </mat-form-field>
                  </div>
                </div>
              
                <div class="form-group  col-sm-12 col-md-6">
                  <div class='relative'>
                    <mat-checkbox class="btn-block" labelPosition="after" (change)="handleIncludeReviewUrl($event.checked)" [checked]="edit_shop.value.review_url_included=='1'">
                      Include in invoice email
                    </mat-checkbox>
                  </div>
                  <div class="col-sm-12 padding-0">
                    <p class="help-block margin-0">If checked off, review notes and url will be included in invoice email notification.</p>
                  </div>
                </div>
                 </div>
               </div>
                <div class="margin-top-15" *ngIf="currentShopDetails.private_review == 0">
                  <app-alert-warning [addonPayloadValue]="gc.ADD_ONS_OBJ.privateReview"></app-alert-warning>
                </div>

              <h2 class="fontcolor-blue sub-heading">EXTERNAL PAYMENT SETTINGS</h2>
              <div class="row">
                <div class="col-sm-12">
                  <p class="help-block margin-0">The settings below are for accepting payment externally and payment link to be included in the invoice email.</p>
                </div>
              </div>
              <div *ngIf="currentShopDetails.external_payment == 1">
                <div class="row payment-settings-row">
                <div class="form-group  col-sm-12 col-md-6">
                  <label>Payment Notes:</label>
                  <div class='relative details-input payment-input'>
                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">  
                    <textarea matInput placeholder="Enter Payment Notes" formControlName="payment_notes"></textarea>
                    <mat-error *ngIf="edit_shop.controls.payment_notes.hasError('required')">
                      Payment notes is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                  </div>
                </div>
              
                <div class="form-group  col-sm-12 col-md-6">
                  <div class='relative'>
                    <mat-checkbox class="btn-block" labelPosition="after" (change)="handleIncludePaymentNotes($event.checked)"  [checked]="edit_shop.value.payment_notes_included=='1'">
                      Include in invoice email
                    </mat-checkbox>
                  </div>
                  <div class="col-sm-12 padding-0">
                    <p class="help-block margin-0">If checked off, payment notes will be included in invoice email notification.</p>
                  </div>
                </div>
                </div>
                <div class="row payment-url-row">
                <div class="form-group  col-sm-12 col-md-6">
                  <label>Payment Url:</label>
                  <div class='relative payment-url-input'>
                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">  
                      <input matInput placeholder="Enter Payment Url (Example: https://example.com)" formControlName="payment_url" class="form-control input-lg custom-form-input"  />
                      <mat-error *ngIf="edit_shop.controls.payment_url.hasError('required')">
                        Payment url is <strong>required</strong>
                      </mat-error>
                      <mat-error *ngIf="edit_shop.controls.payment_url.hasError('pattern')">
                        Please enter a valid URL
                      </mat-error>
                  </mat-form-field>
                  </div>
                </div>
              
                <div class="form-group  col-sm-12 col-md-6">
                  <div class='relative'>
                    <mat-checkbox class="btn-block" labelPosition="after" (change)="handleIncludePaymentUrl($event.checked)" [checked]="edit_shop.value.payment_url_included=='1'">
                      Include in invoice email
                    </mat-checkbox>
                  </div>
                  <div class="col-sm-12 padding-0">
                    <p class="help-block margin-0">If checked off, payment notes and url will be included in invoice email notification.</p>
                  </div>
                </div>
                </div>  
              </div>
              <div class="margin-top-15" *ngIf="currentShopDetails.external_payment == 0">
                <app-alert-warning [addonPayloadValue]="gc.ADD_ONS_OBJ.externalPayment"></app-alert-warning>
              </div>

              <h2 class="fontcolor-blue sub-heading">DFS REMINDER SETTINGS</h2>
              <div class="row">
                <div class="col-sm-12">
                  <p class="help-block margin-0">The settings below are the account level settings for "Due For Service" reminder notifications.</p>
                </div>
              </div>
              <div class="" *ngIf="currentShopDetails.dfs_reminders == 1">
              <div class="row">
                <div class="form-group  col-sm-12 col-md-6">
                  <label>Standard Mileage (SM):</label>
                  <div class='relative'>
                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">  
                      <input matInput placeholder="Enter Standard Mileage" formControlName="standard_mileage" maxlength="10" class="form-control input-lg custom-form-input" (keypress)="convertIntoDecimal($event,'standard_mileage',8,2)" (paste)="validateFieldOnPaste('standard_mileage')"/>
                      <mat-error *ngIf="(edit_shop.get('standard_mileage').hasError('invalidValue') && edit_shop.get('standard_mileage').touched) || edit_shop.get('standard_mileage').status == 'INVALID'">
                          Please enter only numbers or upto one decimal place
                      </mat-error>
                  </mat-form-field>
                  </div>
                </div>
                <div class="col-sm-12">
                  <p class="help-block margin-0" >System will send service reminder notification to customer.</p>
                </div>
              </div>
              <div class="row">
                <div class="form-group  col-sm-12 col-md-6">
                  <label>Standard Days (SD):</label>
                  <div class='relative'>
                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">  
                      <input matInput placeholder="Enter Standard Days" formControlName="standard_days"  maxlength="4" class="form-control input-lg custom-form-input"  (keypress)="validateNumbersNoDecimal($event)" (paste)="validateFieldOnPaste('standard_days')"/>
                      <mat-error *ngIf="(edit_shop.get('standard_days').hasError('invalidValue') && edit_shop.get('standard_days').touched) || edit_shop.get('standard_days').status == 'INVALID'">
                        Please enter only numbers
                    </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-sm-12">
                  <p class="help-block margin-0">System will send service reminder notification to customer.</p>
                </div>
              </div>
              </div>    
              <div class="margin-top-15" *ngIf="currentShopDetails.dfs_reminders == 0">
                <app-alert-warning [addonPayloadValue]="gc.ADD_ONS_OBJ.dfsReminders"></app-alert-warning>
              </div>
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 form-block submit-block">
                  <button type="submit" mat-raised-button class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white" color="accent" title="Submit" style="max-width: 300px;">
                    SUBMIT
                  </button>
                </div>
              </div>
            </div>
            <!-- </div> -->
          </form>
        </div>
      </div>
<!-- Add Customer page end -->
    </div>
  </div>
</div>
