import { Component, OnInit, Inject, ViewChild,ChangeDetectionStrategy } from '@angular/core';
import { GlobalService } from '../../../../environments/global.service';
import { MatPaginator } from '@angular/material/paginator';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort'
import { globalConstants } from '../../../../environments/globalConstants';
import { ViktableComponent, VikSource } from '../../../admin/viktable/viktable.component';
import { MessageviewComponent } from '../messageview/messageview.component';
import { MessageaddComponent } from '../messageadd/messageadd.component';

const MESSAGE_LIST = "shop-message-";

@Component({
  selector: 'app-messagelist',
  templateUrl: './messagelist.component.html',
  styleUrls: ['./messagelist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessagelistComponent extends ViktableComponent implements OnInit {

  public user:any=null
  public param:any={}
  dataSource: any;
  public options:any = {
    user_id: "",
    user_type: "User",
    complaint_status: '',
    request_id:'',
    search_by: "",
    search_keyword: "",
    start: 0,
    limit: 10
  }
  public tablesData = this;
  public displayedColumns=['id','title','added','status','Actions']
  public statuses = ['', 'Created', 'Resolved', 'Closed', 'Unread'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(@Inject(MAT_DIALOG_DATA) public p: any,public gs:GlobalService, public dialogRef: MatDialogRef<MessagelistComponent>, public dialog: MatDialog) {
    super(globalConstants.API_GET_COMPLAINTS_URL, gs);
    this.user=this.gs.getCurrentUser()
    if (this.user) {
      this.sessionKey = MESSAGE_LIST;
      this.url = globalConstants.API_GET_COMPLAINTS_URL;
    }
    this.gs.diaLogObj(this.dialogRef);
    this.param=p

  }
  
  ngOnInit() {
    let isEnable=this.gs.getAcl('complaints','views');
    if(!(isEnable)){
      this.dialogRef.close()
    }
    this.options.request_id=this.param.requestId
    this.reInitOptions()
  }

  ngAfterViewInit() {
      this.setDataTable();
  }
  reInitOptions(){
    this.options.user_type = this.user.user_type;
    this.options.user_id = this.user.id;
    this.options.status = '0';
   }

   setDataTable() {
     if(this.options.convertedToSR){
      this.options.status = '1';
     }else{
      this.options.status = '0';
     }
    this.dataSource = new VikSource(this.tablesData, this.sort, this.paginator);
    this.dataSource.getSortedData = this.sortedData;
  }
  createRecordrecord(record) {
    let vehicleName="";
    if (record.year) {
      vehicleName= record.year ;
    }
    if(record.make != ""){
      vehicleName+= (vehicleName ? " " : "" ) + record.make;
    }
    if(record.model != ""){
      vehicleName+= (vehicleName ? " " : "" ) + record.model
    }
    return {
      id: record.id,
      name: vehicleName,
      request_id: record.request_id,
      title: record.title,
      added_at : this.convertDate(record.add_date),
      ticket_no:record.ticket_no,
      read_status:record.read_status,
      status:this.statuses[record.status],
      check_status:record.status,
    };
  }
  sortedData(): any[] {
    let th: any = this;
    const data = th._exampleDatabase.data.slice();
    if (!th._sort.active || th._sort.direction == '') { return data; }
    return data.sort((a, b) => {
      let propertyA;
      let propertyB;
      switch (th._sort.active) {
        case 'id': propertyA=a.id; propertyB =  b.id; break;
        case 'title': propertyA=a.title; propertyB = b.title; break;
        case 'added': propertyA=new Date(a.added_at); propertyB =  new Date(b.added_at); break;
        case 'status': propertyA=a.status; propertyB =  b.status; break;
        default: return 0;
      }
      if(propertyA=='Invalid Date' || propertyB=='Invalid Date'){
        let date=-1;
        if(propertyB=='Invalid Date'){
            date=1;
        }
        return (date * (th._sort.direction == 'asc' ? 1 : -1));
      }
      let valueA = isNaN(propertyA) ? propertyA.toUpperCase() : propertyA;
      let valueB = isNaN(propertyB) ? propertyB.toUpperCase() : propertyB;

      return (valueA < valueB ? -1 : 1) * (th._sort.direction == 'asc' ? 1 : -1);
    });
  }
  firstList() {
    return this.gs.firstList(this.sessionKey, this.options);
  }
  lastList() {
    return this.gs.lastList(this.sessionKey);
  }
  public convertDate(date){
    return this.gs.getFullFormateDate(date,'G','H');
  }
  getRowStatusColor(status) {
    var statusArr = ['', 'warning-cell', 'info-cell', 'success-cell'];
    return statusArr[status];
  }
  viewComplaintDetails(row){
    let dialogRef = this.dialog.open(MessageviewComponent, {
      panelClass: ['car-dialog-form', 'message-dialog-list'],
      width: '100%',
      data: row 
    });
  }
  addMessage(){
    let row={request_id:this.param.requestId}
    let dialogRef = this.dialog.open(MessageaddComponent, {
        panelClass: ['car-dialog-form'],
        data: row
    })
    dialogRef.afterClosed().subscribe(result => {
      this.setDataTable();
    });
  }

}
