<div id="mainCompotents">
    <div class="panel panel-primary margin-0 shopdashboard ">
        <div class="panel-heading padding-left-15">
            <h3 class="panel-title"> Report </h3>
            <button mat-mini-fab class="custom-btn-back" (click)="globalService.goBack();">
                    <mat-icon>arrow_back</mat-icon>
            </button>
        </div>
        <div class="panel-body bgcolor-lightgrey">
            <div class="total-count-section">
                <div class="filter-search-box">
                    <div class="row">
                        <div class="col-sm-2">
                            <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                                <mat-select class="form-control input-lg custom-form-input"
                                    options="searchByArray"
                                    [(ngModel)]="search_by"
                                    [ngModelOptions]="{standalone: true}"
                                    placeholder="Search By">
                                    <mat-option  *ngFor="let opt of searchByArray" [value]="opt.id">{{opt.text}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-3">
                            <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                                <input  matInput placeholder="Search Keyword" title="Search Keyword" [(ngModel)]="search_keyword" [ngModelOptions]="{standalone: true}" class="form-control input-lg custom-form-input"/>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-3 uc-ngx-select">
                            <ngx-select class="display-full margin-bottom-20" [items]="filterOption"
                            [noAutoComplete]="true"
                            [(ngModel)]="rangeSelected" (select)="changeRangeSelected()">
                            </ngx-select>
                        </div>
                        <div *ngIf="fillActionValue=='bwtDate'">
                            <div class="col-sm-3">
                                <mat-form-field class="car-input btn-block car-datePicker"  floatPlaceholder="never" appearance="fill">
                                    <input (dateChange)="dateChange($event,1)" matInput class="form-control input-lg custom-form-input"  [(ngModel)]="fromDate" [max]="today" [ngModelOptions]="{standalone: true}" placeholder="From Date" [matDatepicker]="filerPicker1" onkeydown="return false;">
                                            <mat-datepicker-toggle matSuffix [for]="filerPicker1" ></mat-datepicker-toggle>
                                    <mat-datepicker #filerPicker1 [touchUi]="setTouchViewValue()"></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col-sm-3">
                                <mat-form-field class="car-input btn-block car-datePicker"  floatPlaceholder="never" appearance="fill">
                                    <input (change)="dateChange($event,2)" matInput class="form-control input-lg custom-form-input"  [(ngModel)]="toDate" [min]="fromDate" [max]="today" [ngModelOptions]="{standalone: true}" placeholder="To Date" [matDatepicker]="filerPicker2" onkeydown="return false;">
                                            <mat-datepicker-toggle matSuffix [for]="filerPicker2" ></mat-datepicker-toggle>
                                    <mat-datepicker #filerPicker2 [touchUi]="setTouchViewValue()"></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-sm-2 form-block">
                            <button type="button" mat-raised-button class="car-btn btn-block text-uppercase btn-lg fontcolor-white mat-raised-button mat-accent" color="accent" title="Search" style="min-width:70px;font-size: 14px; padding: 0 10px; height: 40px;margin-top: -1px; line-height: 15px;" (click)="getFilter()">
                                Search
                            </button>
                        </div>
                        <div class="col-sm-2 form-block">
                            <button type="button" mat-raised-button class="clear-btn car-btn btn-block text-uppercase btn-lg font-light" style="min-width:70px;height: 40px;margin-top: -1px;line-height: 15px; border:0;font-size: 14px;padding: 0 10px;" (click)="clearFilter()">
                                Clear
                            </button>
                        </div>
                    </div>
                    <!-- <div class="row margin-bottom-15">
                        <div class="col-sm-4 col-lg-4">
                            <div class="row">
                                <div class="col-sm-6 form-block">
                                    <button type="button" mat-raised-button class="car-btn btn-block text-uppercase btn-lg fontcolor-white mat-raised-button mat-accent" color="accent" title="Search" style="max-width: 300px; height: 40px;margin-top: -1px; line-height: 15px;" (click)="getFilter()">
                                        Search
                                    </button>
                                </div>
                                <div class="col-sm-6 form-block">
                                    <button type="button" mat-raised-button class="clear-btn car-btn btn-block text-uppercase btn-lg font-light" style="max-width: 500px;height: 40px;margin-top: -1px;line-height: 15px; border:0;" (click)="clearFilter()">
                                        Clear
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="row">
                    <div class="col-sm-4 link">
                        <mat-card class="card-default">
                            <a class="infoTile" href="javascript:void(0);" >
                                <strong [innerHTML]="reportData.total"></strong>
                                <p>TOTAL TICKETS</p>
                            </a>
                        </mat-card>
                    </div>
                    <div class="col-sm-4 link">
                        <mat-card class="card-default">
                            <a class="infoTile" href="javascript:void(0);" >
                                <strong [innerHTML]="reportData.underProcess"></strong>
                                <p>Under Process</p>
                            </a>
                        </mat-card>
                    </div>
                    <div class="col-sm-4 link">
                        <mat-card class="card-default">
                            <a class="infoTile" href="javascript:void(0);" >
                                <strong [innerHTML]="reportData.completed"></strong>
                                <p>Completed</p>
                            </a>
                        </mat-card>
                    </div>
                </div>
            </div>
        </div>
        <div class="padding-left-15 padding-right-15 no-shadow employee-report" *ngIf="jobsData.length > 0 ">
            <div class="report-log-table">
                <table role="table" class="table">
                    <thead role="rowgroup">
                        <tr role="row">
                            <th role="columnheader">CAR #</th>
                            <th role="columnheader">Hourly Rate</th>
                            <th role="columnheader">Hours Worked </th>
                            <th role="columnheader">Amount Payable </th>
                        </tr> 
                    </thead>
                    <tbody role="rowgroup">                   
                        <tr role="row" *ngFor="let job of jobsData">
                            <td role="cell car-id">
                                <a target="_blank" [routerLink]="['/shops/view-service-request/', job.request_id]" class="table-request-number">
                                    {{job.request_number}}
                                    <i class="fa fa-eye" aria-hidden="true"></i>
                                </a>
                            </td>
                            <td role="cell">$ {{job.hour_rate}}</td>
                            <td role="cell" [ngClass]="{'addHrs': job.adjustment_type === '1', 'reduceHrs' : job.adjustment_type === '2' }">
                                <span (click)="editHourWork(job.id)" class="table-request-number" [ngStyle]="{'cursor': isEditPermissions?'pointer':'default'}">
                                    <i class="fa fa-pencil" aria-hidden="true" *ngIf="isEditPermissions"></i>
                                    {{job.hours_worked}}
                                </span>
                            </td>
                            <td role="cell">$ {{job.total_wage}}</td>
                        </tr>
                    </tbody>
                    <tfoot role="rowgroup">
                        <tr role="row">
                            <td role="cell" colspan="2" class="mobile-view-totals"><strong>Total</strong></td>
                            <td role="cell" [innerHTML]="getTotal()"></td>
                            <td role="cell"><strong [innerHTML]="'$ '+totalWages"></strong></td>
                        </tr>
                    </tfoot>
                </table>                
            </div>
        </div>
    </div>
</div>
<app-updateworkhours [data]="selectedSR" (closeTab)="updateWorkHour($event)" *ngIf="selectedSR != ''"></app-updateworkhours>