<div class="panel panel-primary" id="mainCompotents">
    <div class="panel-heading padding-left-15">
        <h3 class="panel-title">LIVE CHAT LIST</h3>
        <button mat-mini-fab class="custom-btn-back" (click)="globalservice.goBack();">
           <mat-icon>arrow_back</mat-icon>
        </button>
    </div>
    <div class="panel-body bgcolor-lightgrey">
        <div class="display-full relative">
            <mat-expansion-panel hideToggle="false" class="search-filter search-employee relative mat-elevation-z1" [expanded]="openSearch">
                <mat-expansion-panel-header collapsedHeight="53px" expandedHeight="53px">
                    <strong><i class="fa fa-search fa-fw"></i>&nbsp;Search Filters</strong>
                </mat-expansion-panel-header>
                <form id="advanceSearchForm" class="collapse in" aria-expanded="true">
                    <div class="panel-body">
                        <div class="col-sm-3">
                            <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                                <input  matInput placeholder="Search Keyword" title="Search Keyword" [(ngModel)]="options.search_keyword" [ngModelOptions]="{standalone: true}" class="form-control input-lg custom-form-input"/>
                            </mat-form-field>
                        </div>

                        <div class="col-sm-3 uc-ngx-select">
                            
                            <ngx-select class="display-full margin-bottom-20" 
                                [items]="searchByStatus" 
                                [noAutoComplete]="true"
                                [(ngModel)]="options.inquiry_status" 
                                [ngModelOptions]="{standalone: true}"
                                placeholder="Select Status">
                            </ngx-select>
                        </div>


                        
                    </div>
                    <mat-action-row class="search-filter-footer">
                      <button mat-raised-button color="accent" type="submit" (click)="search($event)">
                        Search
                      </button>
                    </mat-action-row>
                </form>
            </mat-expansion-panel>
            <button mat-button #clearBtn class="clear-btn" [class.active-search]="isAnyOptionActive(clearBtn)" (click)="clearSearch()">
              Clear Filter
            </button>
        </div>

    <div class="car-datatable uc-datatable mat-elevation-z1">
      <div class="text-right hidden">
        <mat-form-field floatPlaceholder="never" >
          <input matInput #filter placeholder="Filter users">
        </mat-form-field>
      </div>
      <div class="table-responsive relative">
        <mat-table #table [dataSource]="dataSource" matSort class="service-list datatable-loader-bg uc-table-responsive">

          <!-- Color Column -->
          <ng-container matColumnDef="inquiry_id">
            <mat-header-cell *matHeaderCellDef mat-sort-header  data-role="id"> ID </mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="id" data-val="ID"> {{row.inquiry_id}} </mat-cell>
          </ng-container>

          <!-- Color Column -->
          <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="title"> Title </mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="title" data-val="Title"> {{row.title}} </mat-cell>
          </ng-container>

           <!-- Color Column -->
          <ng-container matColumnDef="customer_name">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Customer Name </mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="auto" data-val="Customer Name"> {{row.first_name}}{{row.middle_name? " "+row.middle_name:""}} {{row.last_name}}</mat-cell>
          </ng-container>

        <!-- Color Column -->
          <ng-container matColumnDef="contact_number">
            <mat-header-cell *matHeaderCellDef mat-sort-header  data-role="phone"> Contact No. </mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="phone" data-val="Contact No."><a class="text-black" href="tel:{{row.contact_number}}"> {{row.contact_number}} </a></mat-cell>
          </ng-container>

          <!-- Color Column -->
          <ng-container matColumnDef="customer_email">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Customer Email </mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="auto" data-val="Customer Email"><a class="text-black" href="mailto:{{row.customer_email}}"> {{row.customer_email}} </a></mat-cell>
          </ng-container>

          <!-- Color Column -->
          <ng-container matColumnDef="inquiry_date">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="date"> Date </mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="date" data-val="Date">{{row.inquiry_date}} </mat-cell>
          </ng-container>


          <!-- Color Column -->
          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="status"> Status </mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="status" data-val="Status"> {{row.status}}</mat-cell>
          </ng-container>


          <!-- Color Column -->
          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef data-role="button"> Actions </mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="button">
              <button mat-button class="list-action-btn" color="accent" [matMenuTriggerFor]="menu" disableRipple >
                Action<mat-icon>arrow_drop_down</mat-icon>
              </button>
              <mat-menu #menu="matMenu" class="car-icon-menu disable-no-bg" [overlapTrigger]="false">
                <button class="mat-menu-item" (click)="viewInquiryDetails(row)"> <mat-icon style="color: #000000 !important;">remove_red_eye</mat-icon>View</button>
              </mat-menu>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;" class="mat-row"></mat-row>
        </mat-table>
        <div class="padding-15 text-center"
        [style.display]="getTotalRecords() < 1 ? '' : 'none'">
        No Records Available
      </div>
    </div>
        <div class="display-full relative" [style.display]="getTotalRecords() < 1 ? 'none' : ''">
            <mat-paginator class="car-pagination" #paginator [length]="getTotalRecords()" [pageIndex]="0" [pageSize]="options.limit" [pageSizeOptions]="[5, 10, 25, 100]" (page)="paginateByLimit($event)" >
            </mat-paginator>

            <div class="mat-paginator table-paging">
                <div class="mat-paginator-range-label margin-left-30 margin-right-30">{{options.start+1}} - {{getUpperLimit(options.start + options.limit,getTotalRecords())}} of {{getTotalRecords()}}</div>
                <button mat-icon-button (click)="paginate($event,'previous');" [disabled]="firstList()">
                        <mat-icon>navigate_before</mat-icon>
                </button>
                <button mat-icon-button (click)="paginate($event,'next');" [disabled]="lastList()">
                        <mat-icon>navigate_next</mat-icon>
                </button>
            </div>
        </div>
    </div>
  </div>
</div>
