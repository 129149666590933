<div mat-dialog-title class="relative details-popUp-title">
  Details
  <button mat-mini-fab class="close-modal mat-elevation-z0" (click)="dialogRef.close(isChanged)">
    <mat-icon class="close-icon-lg" aria-label="Example icon-button with a heart icon">close</mat-icon>
  </button>
</div>
<mat-dialog-content class="customer-detail-popup details-content">
  <mat-tab-group #tabNavbar indexToSelect="1" [selectedIndex]="(param.tab-1)">
    <mat-tab label="Vehicle Details" class="font-bold">
      <ng-template mat-tab-label>
        <strong>Vehicle Details</strong>
      </ng-template>
      <div class="padding-15">
        <div class="row text-right action-btn-section" *ngIf="carDetail && carDetail.customer_id == param.customer_id">
            <button mat-raised-button type="button" class="margin-right-5" (click)="carEdit()" title="Edit Vehicle"><span class="tooltip-status text-white fa fa-pencil fa-lg"  ></span></button>
            <button mat-raised-button type="button" class="margin-right-5"  [ngClass]="vehicleInspReportData.vir_found == 0 ? 'disable-vir-btn' : ''" (click)="openVIR()" title="{{handleVIRTitle()}}"><span class="tooltip-status text-white fa fa-solid fa-square-poll-horizontal fa-lg" ></span></button>
            <button mat-raised-button type="button" title="Download Inspection Report" (click)="handleDownloadVIR()" [ngClass]="vehicleInspReportData.vir_found == 0 ? 'disable-vir-btn' : ''" title="{{handleVIRDownloadTitle()}}"><span class="tooltip-status text-white fa fa-download fa-lg" ></span></button>
        </div>
        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Make</label>
          </div>
          <div class="col-sm-6" [innerHTML]="carDetail.make ? carDetail.make : '-' "></div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Year</label>
          </div>
          <div class="col-sm-6" [innerHTML]="carDetail.year ? carDetail.year : '-' "></div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Model</label>
          </div>
          <div class="col-sm-6" [innerHTML]="carDetail.model ? carDetail.model : '-' "></div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Engine Size</label>
          </div>
          <div class="col-sm-6" [innerHTML]="(carDetail.sub_model ? carDetail.sub_model : '-')"></div>
        </div>


        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Vin</label>
          </div>
          <div class="col-sm-6" [innerHTML]="((carDetail.vin) ? carDetail.vin : '-')"></div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Production Date</label>
          </div>
          <div class="col-sm-6" [innerHTML]="((carDetail.production_date) ? carDetail.production_date : '-')"></div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Mileage</label>
          </div>
          <div class="col-sm-6" [innerHTML]="(carDetail.distance_covered ? carDetail.distance_covered : '0')"></div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>License Plate</label>
          </div>
          <div class="col-sm-6" [innerHTML]="((carDetail.license_plate) ? carDetail.license_plate : '-')"></div>
        </div>


        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>State</label>
          </div>
          <div class="col-sm-6" [innerHTML]="((carDetail.state) ? carDetail.state : '-')"></div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Color</label>
          </div>
          <div class="col-sm-6" [innerHTML]="((carDetail.color) ? carDetail.color : '-')"></div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Unit Number</label>
          </div>
          <div class="col-sm-6" [innerHTML]="((carDetail.unit_number) ? carDetail.unit_number : '-')"></div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Driver</label>
          </div>
          <div class="col-sm-6" [innerHTML]="((carDetail.driver) ? carDetail.driver : '-')"></div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Driver Phone Number</label>
          </div>
          <div class="col-sm-6" [innerHTML]="((carDetail.driver_phone_number) ? carDetail.driver_phone_number : '-')">
          </div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Insurance Expiration Date</label>
          </div>
          <div class="col-sm-6" [innerHTML]="(gs.getFullFormateDate(carDetail.insurance_expiration_date,'GD','H'))">
          </div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Registration Expiration Date</label>
          </div>
          <div class="col-sm-6" [innerHTML]="(gs.getFullFormateDate(carDetail.registration_expiration_date,'GD','H'))">
          </div>
        </div>
        <div class="row margin-bottom-10" *ngIf="(gs.getFullFormateDate(carDetail.inspection_expiration_date,'GD','H'))!='-'">
          <div class="col-sm-6">
            <label>Inspection Expiration Date</label>
          </div>
          <div class="col-sm-6" [innerHTML]="(gs.getFullFormateDate(carDetail.inspection_expiration_date,'GD','H'))">
          </div>
        </div>
        <div class="row margin-bottom-10" *ngIf="carDetail && carDetail.transmission">
          <div class="col-sm-6">
            <label>Transmission</label>
          </div>
          <div class="col-sm-6"
            [innerHTML]="((carDetail && carDetail.transmission ) ? (carDetail.transmission ) : '-')"></div>
        </div>
        <div class="row margin-bottom-10" *ngIf="carDetail && carDetail.brake">
          <div class="col-sm-6">
            <label>Brake</label>
          </div>
          <div class="col-sm-6" [innerHTML]="((carDetail && carDetail.brake ) ? (carDetail.brake ) : '-')"></div>
        </div>
        <div class="row margin-bottom-10" *ngIf="carDetail && carDetail.driveline">
          <div class="col-sm-6">
            <label>Driveline</label>
          </div>
          <div class="col-sm-6" [innerHTML]="((carDetail && carDetail.driveline ) ? (carDetail.driveline ) : '-')">
          </div>
        </div>
        <div class="row margin-bottom-10" *ngIf="carDetail && carDetail.gvwr">
          <div class="col-sm-6">
            <label>GVWR</label>
          </div>
          <div class="col-sm-6" [innerHTML]="((carDetail && carDetail.gvwr ) ? (carDetail.gvwr ) : '-')"></div>
        </div>
        <div class="row margin-bottom-10" *ngIf="carDetail && carDetail.wheel_base">
          <div class="col-sm-6">
            <label>Wheel Base</label>
          </div>
          <div class="col-sm-6" [innerHTML]="((carDetail && carDetail.wheel_base ) ? (carDetail.wheel_base ) : '-')">
          </div>
        </div>
        <div class="row margin-bottom-10" *ngIf="carDetail && carDetail.ttire_size">
          <div class="col-sm-6">
            <label>Tire Size</label>
          </div>
          <div class="col-sm-6" [innerHTML]="((carDetail && carDetail.tire_size ) ? (carDetail.tire_size ) : '-')">
          </div>
        </div>
        <div class="row margin-bottom-10" *ngIf="carDetail && carDetail.wheel_lock_location">
          <div class="col-sm-6">
            <label>Wheel Lock Location</label>
          </div>
          <div class="col-sm-6"
            [innerHTML]="((carDetail && carDetail.wheel_lock_location ) ? (carDetail.wheel_lock_location ) : '-')">
          </div>
        </div>
        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>California Emissions</label>
          </div>
          <div class="col-sm-6">
            <p class="pull-left" *ngIf="carDetail.california_emissions!=1"
              [innerHTML]="( carDetail.california_emissions==1 ? 'Yes' : 'No')"></p>
            <ul id="vehicle_picul" class="list-inline margin-bottom-0" *ngIf="carDetail.california_emissions==1">
              <li class="relative view-images">
                <a href="javascript:void(0);" class="thumbnail">
                  <img class="show-image" title="California Emissions Standards"
                    src="assets/img/california-emissions.PNG" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="row margin-bottom-10" >
          <div class="col-sm-6">
            <label>Standard Mileage</label>
          </div>
          <div class="col-sm-6"
            [innerHTML]="carDetail.standard_mileage || '-'">
          </div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Standard Days</label>
          </div>
          <div class="col-sm-6"
            [innerHTML]="carDetail.standard_days || '-'">
          </div>
        </div>
        <div class="row margin-bottom-10" *ngFor="let f of carDetail.custom_fields" [class.hide]="(f.value ? false : true )">
          <div class="col-sm-6">
            <label>{{f.label}}</label>
          </div>
          <div class="col-sm-6" [innerHTML]="getFieldVal(f)"></div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Vehicle Registration Photo</label>
          </div>
          <div class="col-sm-6">
            <div class="pull-left" id="vehicleRegPicView">
              <ul id="vehicle_pic-ul" class="list-inline margin-bottom-0">
                <li class="relative view-images" *ngFor="let item of carDetail.vehicle_registered_pic let i = index">
                  <span class="thumbnail" (click)="imageToolDialog($event,i,3,carDetail.vehicle_registered_pic)">
                    <img class="show-image" src="{{registeredPicture}}{{item.vehicle_registered_pic}}" />
                  </span>
                </li>
              </ul>
              <p *ngIf="carDetail.vehicle_registered_pic && carDetail.vehicle_registered_pic.length == 0" class="pull-left">-</p>
            </div>
          </div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Vehicle Insurance Card Photo</label>
          </div>
          <div class="col-sm-6">
            <div class="pull-left" id="vehicleInsPicView">
              <ul id="vehicle_insured_pic-ul" class="list-inline margin-bottom-0">
                <li class="relative view-images" *ngFor="let item of carDetail.vehicle_insured_pic let i = index">
                  <span class="thumbnail" (click)="imageToolDialog($event,i,2,carDetail.vehicle_insured_pic)">
                    <img class="show-image" src="{{insurance}}{{item.vehicle_insured_pic}}">
                  </span>
                </li>
              </ul>
              <p *ngIf="carDetail.vehicle_insured_pic && carDetail.vehicle_insured_pic.length == 0" class="pull-left">-</p>
            </div>
          </div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Vehicle Photo(s)</label>
          </div>
          <div class="col-sm-6">
            <div class="pull-left" id="vehiclePicView">
              <ul id="vehicle_pic-ul" class="list-inline margin-bottom-0">
                <li class="relative view-images" *ngFor="let item of carDetail.vehicle_pic let i = index">
                  <span class="thumbnail" (click)="imageToolDialog($event,i,1,carDetail.vehicle_pic)">
                    <img class="show-image" src="{{imgPath}}{{item.vehicle_pic}}" />
                  </span>
                </li>
              </ul>
              <p *ngIf="carDetail.vehicle_pic && carDetail.vehicle_pic.length == 0" class="pull-left">-</p>
            </div>
          </div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Vehicle Video(s)</label>
          </div>
          <div class="col-sm-6">
            <div class="pull-left" id="vehicleVidView">
              <ul id="vehicle_video-ul" class="list-inline margin-bottom-0">
                <li *ngFor="let video of carDetail.vehicle_video" class="video-upload relative">
                  <span class="thumbnail margin-bottom-0" (click)="videoShow(video.vehicle_video)">
                    <span class="relative video-bg">
                      <mat-icon class="position-center play-video">play_circle_filled</mat-icon>
                    </span>
                  </span>
                </li>
              </ul>
              <p *ngIf="carDetail.vehicle_video && carDetail.vehicle_video.length == 0" class="pull-left">-</p>
            </div>
          </div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Vehicle Vin Photo</label>
          </div>
          <div class="col-sm-6">
            <div class="pull-left" id="vehicleVinPicView">
              <ul id="vin_photo-ul" class="list-inline margin-bottom-0">
                <li class="relative view-images" *ngIf="carDetail.vin_photo!=''">
                  <span class="thumbnail" (click)="imageToolDialog($event,0,5,carDetail.vin_photo)">
                    <img class="show-image" src="{{vinPicture}}{{carDetail.vin_photo}}">
                  </span>
                </li>
              </ul>
              <p *ngIf="carDetail.vin_photo === ''" class="pull-left">-</p>
            </div>
          </div>
        </div>
        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Other Label Photo</label>
          </div>
          <div class="col-sm-6">
            <div class="pull-left" id="vehicleLabelPicView">
              <ul id="vehicle_pic-ul" class="list-inline margin-bottom-0">
                <li class="relative view-images" *ngFor="let item of labelPhoto; let i = index">
                  <span class="thumbnail" (click)="imageToolDialog($event,i,13,labelPhoto)">
                    <img class="show-image" src="{{VEHICLE_LABEL_PIC}}{{item.label_pic}}" />
                  </span>
                </li>
              </ul>
              <p *ngIf="labelPhoto && labelPhoto.length == 0" class="pull-left">-</p>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="{{ user.profile_type == '2' ? 'Contact' : 'Customer' }} Details">
      <ng-template mat-tab-label>
        <strong>{{ user.profile_type == '2' ? 'Contact' : 'Customer' }} Details</strong>
      </ng-template>
      <div class="padding-15">
        <div class="row text-right action-btn-section" *ngIf="customerDetail.mapping_status == '1'">
          <button mat-raised-button type="button" class="margin-right-5" title="Edit Customer" (click)="customerEdit()"><span class="tooltip-status text-white fa fa-pencil fa-lg"  ></span></button>
        </div>
        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Name</label>
          </div>
          <div class="col-sm-6">{{customerDetail.first_name}} {{customerDetail.middle_name}}
            {{customerDetail.last_name}}</div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Email</label>
          </div>
          <div class="col-sm-6 break-word">
            <a href="mailto:{{customerDetail.email}}" class="text-black">{{customerDetail.email}}</a>
          </div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Address</label>
          </div>
          <div class="col-sm-6">{{customerDetail.address1}}</div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>City</label>
          </div>
          <div class="col-sm-6 break-word">{{customerDetail.city}}</div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>State</label>
          </div>
          <div class="col-sm-6">{{customerDetail.state}}</div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Country</label>
          </div>
          <div class="col-sm-6 break-word">{{customerDetail.country}}</div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Zip Code</label>
          </div>
          <div class="col-sm-6">{{customerDetail.zip}}</div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Home Phone</label>
          </div>
          <div class="col-sm-6 break-word">
            <a href="tel:{{customerDetail.home_phone}}" class="text-black">{{customerDetail.home_phone}}</a>
          </div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Business Phone</label>
          </div>
          <div class="col-sm-6">
            <a href="tel:{{customerDetail.business_phone}}" class="text-black">{{customerDetail.business_phone}}</a>
          </div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Mobile Phone</label>
          </div>
          <div class="col-sm-6 break-word">
            <a href="tel:{{customerDetail.mobile_phone}}" class="text-black">{{customerDetail.mobile_phone}}</a>
          </div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>{{ user.profile_type == '2' ? 'Contact' : 'Customer' }} Labor Rate Type</label>
          </div>
          <div class="col-sm-6">{{customerDetail.customer_type_name}}</div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Tax Exempted</label>
          </div>
          <div class="col-sm-6 break-word">{{customerDetail.tax_exempted}}</div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>{{gs.getBORDName()}}</label>
          </div>
          <div class="col-sm-6">{{customerDetail.business_name}}</div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label></label>
          </div>
          <div class="col-sm-6 break-word">

          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="{{ user.profile_type == '2' ? 'Other Contacts' : 'Contacts' }} Details" class="font-bold">
      <ng-template mat-tab-label>
        <strong>{{ user.profile_type == '2' ? 'Other Contacts' : 'Contacts' }} Details</strong>
      </ng-template>
      <div class="padding-15">
        <div class="row margin-bottom-10" *ngFor="let cont of contacts">
          <div class="col-sm-6">
            <label>{{cont.contact_name}}</label>
          </div>
          <div class="col-sm-6 break-word">
            <a href="tel:{{cont.contact_number}}" class="text-black">{{cont.contact_number}}</a>
          </div>
        </div>
        <div class="row margin-bottom-10 no-result-section" *ngIf="contacts.length==0">
          <div class="col-sm-12 text-center">
            <span class="mat-error">No Contacts Found.</span>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>
<mat-dialog-actions class="inquiry-action relative">
  <button mat-raised-button class="font-bold" (click)="dialogRef.close(isChanged)">Close</button>
</mat-dialog-actions>
