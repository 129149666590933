import {Router, ActivatedRoute, Params, NavigationEnd,CanDeactivate} from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DateAdapter,NativeDateAdapter, MAT_DATE_FORMATS,} from '@angular/material/core';

import {Component, OnInit, ViewChild, Input, ViewEncapsulation, Inject,ChangeDetectorRef ,ChangeDetectionStrategy,OnDestroy,Injectable, AfterViewInit,ElementRef } from '@angular/core';
import {FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';

import {Location, DatePipe} from '@angular/common';
import {Http, Headers, Response, ResponseContentType} from '@angular/http';

import {globalConstants} from "../../../../environments/globalConstants"

import {GlobalService, ModalInfo} from "../../../../environments/global.service"
import {AppComponent} from "../../../app.component"
import { ImagetooldialogComponent } from '../../../imagetooldialog/imagetooldialog.component';
import {VideoShowComponent} from '../../../customer/my-vehicles/video-show/video-show.component';
import { ImagedialogComponent } from '../../../assetsComponent/imagedialog/imagedialog.component';
import {PartstechComponent} from '../../../partstech/partstech.component';
import { RequireApprovalDialogComponent } from '../require-approval-dialog/require-approval-dialog.component';
import  saveAs  from 'file-saver';
import {TechassignmentComponent} from '../techassignment/techassignment.component';
import { NotifyjobComponent } from './../notifyjob/notifyjob.component';
import {trigger, state, style, animate, transition, group} from '@angular/animations';
import { MilesoutComponent } from '../milesout/milesout.component';
import { DetailTabComponent } from '../detail-tab/detail-tab.component';
import { globalFunctions } from '../../../../environments/globalFunctions';
import { StockorderpopupComponent } from '../../shopparts/stockorderpopup/stockorderpopup.component';
import { AddMileageDialogComponent } from '../add-mileage-dialog/add-mileage-dialog.component';
import { SrnotelistComponent } from '../../srnote/srnotelist/srnotelist.component';
import { MessagelistComponent } from '../../shopservicecomplaints/messagelist/messagelist.component';
import { QqviewComponent } from '../../quickquote/qqview/qqview.component';
import { EmissionsInspectionComponent } from '../emissions-inspection/emissions-inspection.component';
import { RedeemCouponComponent } from '../../../redeem-coupon/redeem-coupon.component';
import {​ ServiceHistoryComponent }​ from '../../../shop/shopservicerequest/service-history/service-history.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PaymentDialogComponent } from "../payment-dialog/payment-dialog.component";
import { AddLabelDialogComponent } from '../add-label-dialog/add-label-dialog.component';
import { QuickactionComponent } from '../../quickaction/quickaction.component'
import { AnyPtrRecord } from 'dns';
import {Subscription} from 'rxjs';
// import { CdTimerComponent } from 'angular-cd-timer';
// import { BehaviorSubject } from 'rxjs/BehaviorSubject'
export class AppDateAdapter extends NativeDateAdapter {
  parse(value: any): Date | null {
   if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
     const str = value.split('/');
     const year = Number(str[2]);
     const month = Number(str[1]) - 1;
     const date = Number(str[0]);
     return new Date(year, month, date);
   }
   const timestamp = typeof value === 'number' ? value : Date.parse(value);
   return isNaN(timestamp) ? null : new Date(timestamp);
 }

  format(date: Date, displayFormat: Object): string {
      if (displayFormat == "input") {
          let day = date.getDate();
          let month = date.getMonth() + 1;
          let year = date.getFullYear();
          return this._to2digit(month)+ '/' + this._to2digit(day)  + '/' + year;
      } else {
          return date.toDateString();
      }
  }

  private _to2digit(n: number) {
      return ('00' + n).slice(-2);
  }
}

export const APP_DATE_FORMATS =
{
  parse: {
      dateInput: {month: 'short', year: 'numeric', day: 'numeric'}
  },
  display: {
      // dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
      dateInput: 'input',
      monthYearLabel: { month: 'short', year: 'numeric', day: 'numeric' },
      dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
      monthYearA11yLabel: {year: 'numeric', month: 'long'},
  }
}

declare var jQuery:any;
declare var $ :any;
@Component({
    selector: 'app-view-servicerequest',
    templateUrl: './view-servicerequest.component.html',
    styleUrls: ['./view-servicerequest.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
      DatePipe,
        {
            provide: DateAdapter, useClass: AppDateAdapter
        },
        {
            provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
        }
    ],
    animations: [
      trigger('slideInOut',[
        state('in', style({ 'max-height': '1500px', overflow: 'hidden' })),
        state('out', style({ 'max-height': '0px', overflow: 'hidden' })),
        transition('in => out', [group([
          animate('700ms ease-in-out', style({ 'max-height': '0px', overflow: 'hidden'})),
        ])]),
        transition('out => in', [group([
          animate('700ms ease-in-out', style({ 'max-height': '1500px', overflow: 'hidden'})),
        ])]),
       ])
    ]
})
export class ViewServicerequestComponent implements OnInit,AfterViewInit,OnDestroy {
  
    @ViewChild('viewRequestCoupon') viewRequestCoupon: RedeemCouponComponent;
    @ViewChild('onTheLotCheckbox') onTheLotCheckbox: ElementRef;
    @ViewChild('waitingForPartsCheckbox') waitingForPartsCheckbox: ElementRef;
    @ViewChild('accountantVerificationCheckbox') accountantVerificationCheckbox: ElementRef;
    @ViewChild(QuickactionComponent, { static: false }) quickaction: QuickactionComponent;
    // @ViewChild('empTimer') empTimer: CdTimerComponent;
    public serviceRequestId: number;
    public serviceInfo: FormGroup;
    public showServiceDetail: boolean = false;
    partsOptions = [];
    genericOptions = [];
    public allLabels = [];
    public preSelectLabel=[];
    
    //  public disclaimer = changeDisclaimer | null
    public ObjectJson = {};
    public headingColorFlag={};
    public totalReturnedValue;
    public finalShowAbleAmount;
    public balance = 0;
    public totalAcceptedCalculation = []
    public rejectedJobCalculation = [];
    public grandTotalAcceptedArray = [];
    public grandTotalRejectedArray = [];
    public nonTaxableValue = [];
    public UnTaxedAcceptedValue = 0;
    public totalAcceptedValue ="";
    public totalRejectedValue ="";
    public conservingStatus = [];
    public statusAcceptText:any;
    public statusRejectText:any;
    public vehicle_pic:any;
    public vin_photo:any;
    public dataSources = {data: []};
    public reportDownloadLink='';
    public reportDownloadType=0;
    public subTotal;
    public totalTax;
    public recIds = []
    public jobStatusObj = {
      l1Id:"",
      l2Id:"",
      l3Id:"",
      recommendationId:"",
      status:""
    };
    public applyChanges=false;
    public globalConstants=globalConstants;
    public jobStatuses = [];
    public showPartsTechButton=false;
    public showNexpartButton=false;
    public partstechData=[];
    public partstechOrder=[];
    public nexpartOrder=[];
    public editMode='0';
    public access_timestamp='';
    public showDownload=false;
    public initFormValue=true;

    public customer_id;
    public currentCustomer;
    public currentUserID;
    public userType;
    public requestCustomerDetails;
    public requestCustomerID;
    public getRequireApproval;
    //public authorizeBy;
    //public contactPerson;
    public contactPhoneNumber;
    //public dateTimeOfCall;
    public otherMeans;
    public getApprovalAmount;
    public requestCreatedDate;
    public viewCustomerInfo=false;
    public isFillRecommendation=false;
    public isDisclaimers=false;
    public isAcceptRejectJob=false;
    public taskTrack=true;
    public userTaskTrack:any=0;
    public statuses = ['Appointment', 'Pending', 'Processing', 'Approval Awaited', '', 'Service Completed', 'Completed', 'Approval Completed', 'Completed And Paid', 'Unread Rating', 'Non-CAR','','Work In Progress'];
    public skipTicketAssign=false;
    public techinianStatusDone=false;

    public getRejetedJob=true;

    public requestApprovalDetails: any = [];
    public authorizeBy;
    public contactPerson;
    public contactPersonPhone;
    public dateTimeOfCall;
    public otherMeansOfContact;
    public dateTimeOfCallValue;

    public vinTimeStamp = new Date().getTime();
    public vehicleTimeStamp = new Date().getTime();
    public isSubscribed=true;
    public isNexpartSubscribed=true;
    public isEmployee=false;
    animationState = 'out';
    animationStateApprovalData = 'out';
    public vehicleName :any ="";
    public isInventory=false;
    public recomLock:any={is_locked:0,locker_id:"",locker_name:""};
    public showCostPrice=false;
    public isLaborTax=false;
    public isServiceNote=false
    public serviceDetail:any=null;
    public qqDetail:any;
    public levelPriceObj:any={};
    public hidePrices=true;
    public SrComplete=false;
    couponRedeemConditions:Array<boolean> = [];
    public currentUser:any=null;
    // private isStartTimeAcquaired:boolean = false;
    // private secondsWorked:any = 0;
    //bootstrap modal
    bsModalRef: BsModalRef;
    public currentDate = new Date();
    public userTaskTrackSchedule = new Date();
    public isReportGenerate=false;
    public techData:any = {};
    public isAddInPayment: boolean = false;
    public totalCCfeeValue = 0;
    public netPayable = 0;  
    public businessMetricReport:any = {};
    public profitPermission = false;
    public upload_v = [];
    public subscriptionDataForAPI: Subscription = new Subscription();
    public showLabelsDropdown: boolean;
    public customLabels = [
      {
      "id": "OTL",
      "shop_id": "",
      "label_name": "OTL",
      "add_date": "",
      "modify_date": ""
     },
     {
      "id": "WAITING_FOR_PARTS",
      "shop_id": "",
      "label_name": "Waiting For Parts",
      "add_date": "",
      "modify_date": ""
     },
     {
      "id": "ACCOUNTANT_VERIFIED",
      "shop_id": "",
      "label_name": "Accountant Verified",
      "add_date": "",
      "modify_date": ""
     }
    ];//Revisit add this tempSRLabels in global constant file
  waitingForPartCheckBoxStatus: boolean;
  public PTLaborSettingTypeDOW = 0;
  public PTLaborTooltipSettingType = 0;
    

    constructor(private location: Location, private app: AppComponent,public cdr: ChangeDetectorRef,private formBuilder: FormBuilder,public globalService: GlobalService, public router: Router, private activatedRoute: ActivatedRoute, public dialog: MatDialog, public http:Http, private modalService: BsModalService,private datePipe: DatePipe,) {
        this.globalService.hideTooltipImage();
        this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
        this.currentUserID = this.currentCustomer.id;
        this.userType = this.currentCustomer.user_type;
        this.isSubscribed=this.globalService.isPartstechAllowed();
        this.isNexpartSubscribed=this.globalService.isNexpartAllowed();
        setTimeout(()=>{
        let isEnable=this.globalService.isAllowed(this.app,'service_request','views');
        let aa=this.globalService.getAcl('all_ticket_view','enable');
        if(!isEnable){
          this.router.navigate(['shops/action/forbidden']);
        }
        this.isAcceptRejectJob=this.globalService.getAcl('accept_reject_job','addNew');
        
        this.showCostPrice=this.globalService.getAcl('display_part_cost','views');
        this.cdr.markForCheck();
        // this.cdr.detectChanges();
      },globalConstants.RELOAD_WAIT);
        this.globalService.setMetaData("SHOPS","SERVICE_REQUEST_DETAIL")
        this.isReportGenerate=this.globalService.isUserReportOn();
        /* FIND ALL SERVICE REQUEST APPROVAL */
        // this.setParams();
        // var requestOprs = "user_id=" + this.globalService.getCurrentUser()["id"] + "&user_type=" + this.globalService.getCurrentUser()["user_type"] + "&request_id=" + this.serviceRequestId + "&shop_id=" + this.globalService.getCurrentUser()["shop_id"];
        // this.globalService.callAPI(globalConstants.API_GET_ALL_SERVICE_REQUEST_APPROVAL, requestOprs).subscribe((response) => {
        //     if (response.result == '1') {
        //         if (response.data.length == undefined || response.data.length == 'undefined') {
        //             this.requestApprovalDetails[0] = response.data;
        //         } else {
        //             this.requestApprovalDetails    = response.data;
        //         }
        //     } else {
        //         //this.preAuthorization   = false;
        //     }
        // });
        /* END */

    }
    /* STATUS COLOR */
    getStatusColor() {      
      let status:any = this.serviceInfo.value.is_appointment== '1' && (this.serviceInfo.value.request_status=='1' || this.serviceInfo.value.request_status=='2') ? 0: this.serviceInfo.value.request_status ;
      if(status != -1){
          var statusArr = ['customer-appt', 'warning-cell', 'info-cell', 'awaited-cell', "",  'success-cell', 'success-completed', 'success-cell', 'success-paid', "", "", "", 'work-progress-cell'];
          return statusArr[status];
      }
      else{
          return 'deleted-cell';
      }
    }
    isShowPrevious(){
      // let status=this.serviceInfo.value.request_status;
      // if(status==1 || status==2 || status==12){
      //   return false;
      // }
      return true;
    }

    initFormBuiler(){
      this.serviceInfo = this.formBuilder.group({
        add_date:[],
        address1:[],
        address2:[],
        balance_amount:[],
        business_name:[],
        business_phone:[],
        car_id:[],
        city:[],
        color:[],
        comments:[],
        company_name:[],
        completion_date:[],
        confirmation_token:[],
        country:[],
        customer_id:[],
        customer_type:[],
        disclaimer:[],
        disclaimer_id:[],
        email:[],
        estimated_delivery_date:[],
        feedback_code:[],
        first_name:[],
        grand_total:[],
        has_app_downloaded:[],
        home_phone:[],
        id:[],
        inspection_reports:[],
        insurance_expiration_date:[],
        is_DIY:[],
        is_paid:[],
        is_supplies_charges_included:[],
        is_tax_included:[],
        is_usps_vehicle:[],
        labor_amount:[],
        labor_rate:[],
        last_name:[],
        license_plate:[],
        mail_sent:[],
        make:[],
        middle_name:[],
        mobile_phone:[],
        model:[],
        modify_date:[],
        no_report_performed:[],
        non_taxable_amount:[],
        notes:[],
        notify_accept_reject_jobs_email:[],
        notify_accept_reject_jobs_push:[],
        notify_create_service_facility_car_email:[],
        notify_create_service_facility_car_push:[],
        notify_expiration_dates_email:[],
        notify_expiration_dates_push:[],
        notify_expiration_dates_web:[],
        notify_feedback_email:[],
        notify_feedback_push:[],
        notify_make_changes_car_facility_service_request_email:[],
        notify_make_changes_car_facility_service_request_push:[],
        notify_send_updated_info_to_customer_email:[],
        notify_send_updated_info_to_customer_push:[],
        notify_service_completion_email:[],
        notify_service_completion_push:[],
        notify_to_admin:[],
        notify_to_customer:[],
        parts_amount:[],
        password:[],
        payment_date:[],
        payment_reminder_date:[],
        payment_reminder_status:[],
        present_mileage:[],
        present_mileage_out:[],
        read_status:[],
        recommendation_saved:[],
        registration_expiration_date:[],
        request_documents:[],
        request_id:[],
        request_images:[],
        request_status:[],
        requested_delivery_date:[],
        reset_pwd_token:[],
        schedule_service_date:[],
        service_facility:[],
        service_request_list:[],
        shop_id:[],
        state:[],
        status:[],
        supplies_charges:[],
        suppliescharges:[],
        tax_amount:[],
        tax_exempt_number:[],
        tax_exempted:[],
        tax_rate:[],
        technician:[],
        title:[],
        total_amount:[],
        total_paid:[],
        total_cc_fee:[],
        vin:[],
        year:[],
        zip:[],
        service_facility_name:[],
        request_notes:[],
        is_appointment:[],
        on_the_lot:[false],
        parts_awaited:[false],
        accountant_verification:[false],
        labels:[],
        request_label:[],
        cc_fee_included: ['0']
      });
    }
    isAllowed(action,module?:any){
      if(typeof module!='undefined'){
          return this.globalService.isAllowed(this.app,module,action);
      }
      return false;
    }
    /* animation */
    toggleShowDiv() {
      this.animationState = this.animationState === 'out' ? 'in' : 'out';
    }
    toggleShowApprovalData() {
      this.animationStateApprovalData = this.animationStateApprovalData === 'out' ? 'in' : 'out';
    }
   /**
     * @updatedBy Vikas Kumar
     * @trackID <:CAR-1048>
     * @updated 26-04-2020
     */
    ngOnInit() {
      this.profitPermission = this.globalService.getAcl('display_part_cost','views');
        this.setCouponRedeemConditions();
        this.setParams();
        this.serviceReqApproval()
        this.getAllLabels();
        this.getMetrixReport();
        this.currentUser = this.globalService.getCurrentUser();
        this.isEmployee=this.globalService.isSubscribe('employee');
        this.isInventory = this.globalService.isInventories();
        this.isServiceNote= this.globalService.isSubscribe('service_notes');
        this.getTicketAssignSetting();
        this.getAllTechJobStatus();
        this.isFillRecommendation=this.globalService.isAllowed(this.app,'fill_recommendation','addNew');
        if(this.initFormValue){
          this.initFormBuiler();
        }
       
        let content = "user_id=" + this.globalService.getCurrentUser()["id"] + "&user_type=" + this.globalService.getCurrentUser()["user_type"] + "&service_request_id=" + this.serviceRequestId;
        this.globalService.callAPI(globalConstants.API_GET_SERVICE_REQUEST_VIEW_URL, content).subscribe((response:any) => {
            if (response.result == 1) {
                this.showServiceDetail = true;
                var data = response.data;
                this.serviceDetail= response.data;
                this.editMode=data.edit_mode;
                this.access_timestamp=data.access_timestamp;
                let shop=this.globalService.getCurrentUser();
                let isInvoices=this.globalService.isAllowed(this.app,'invoices','views');
                if(data.shop_id==shop.shop_id && isInvoices){
                  this.showDownload=true;
                }
                if(data.is_report_generation==1){
                  this.taskTrack=true;
                }

                // this.secondsWorked = data.seconds_worked;
                // if(data.work_in_progress == 1){
                //   this.isStartTimeAcquaired = true;
                // }else{
                //   console.log('ngoninit : timer stopped');
                //   if(this.empTimer) this.empTimer.stop();
                // }

                if(data.is_labor_tax_excluded=="1"){
                  this.isLaborTax=true;
                }
                if(this.isAllowed('views','quick_quote') && typeof data.quote_detail!='undefined' && typeof data.quote_detail.quote_id!='undefined'){
                  this.qqDetail=data.quote_detail
                }

                if(data.user_work_tracker=="1" || data.user_work_tracker=="2" || data.user_work_tracker=="3" || data.user_work_tracker=="0"){
                  this.userTaskTrack=data.user_work_tracker;                  
                }else{
                  this.userTaskTrack=-1
                }
                
                if(data.user_work_tracker_schedule_date){
                  this.userTaskTrackSchedule = new Date(data.user_work_tracker_schedule_date);
                }
                 
              if (data.recom_locker_id != this.globalService.getCurrentUser()["id"]) {
                
                if (data.is_recom_locked) {
                  this.recomLock.is_locked = data.is_recom_locked;
                }
                if (data.recom_locker_id) {
                  this.recomLock.locker_id = data.recom_locker_id;
                }
                if (data.recom_locker_name) {
                  this.recomLock.locker_name = data.recom_locker_name
                }

              }
                this.vehicleName = this.globalService.getGlobalVehicleName(data);      
                this.customer_id = data.customer_id;
                this.requestCreatedDate = this.globalService.getFullFormateDate(data.add_date,"G","");
                this.checkAcceptanceRejection(data.service_request_list,data.notify_to_admin);
                this.serviceInfo.controls.add_date.setValue(data.add_date)
                this.serviceInfo.controls.address1.setValue(data.address1)
                this.serviceInfo.controls.address2.setValue(data.address2)
                this.serviceInfo.controls.balance_amount.setValue(data.balance_amount)
                this.serviceInfo.controls.business_name.setValue(data.business_name)
                this.serviceInfo.controls.business_phone.setValue(data.business_phone)
                this.serviceInfo.controls.car_id.setValue(data.car_id)
                this.serviceInfo.controls.city.setValue(data.city)
                this.serviceInfo.controls.color.setValue(data.color)
                this.serviceInfo.controls.comments.setValue(data.comments)
                this.serviceInfo.controls.company_name.setValue(data.company_name)
                this.serviceInfo.controls.completion_date.setValue(this.globalService.getFullFormateDate(data.completion_date,'G','0000-00-00 00:00:00'))
                this.serviceInfo.controls.confirmation_token.setValue(data.confirmation_token)
                this.serviceInfo.controls.country.setValue(data.country)
                this.serviceInfo.controls.customer_id.setValue(data.customer_id)
                this.serviceInfo.controls.customer_type.setValue(data.customer_type);
                this.serviceInfo.controls.disclaimer.setValue(data.disclaimer)
                this.serviceInfo.controls.disclaimer_id.setValue(data.disclaimer_id)
                this.serviceInfo.controls.email.setValue(data.email);

                this.serviceInfo.controls.estimated_delivery_date.setValue(this.globalService.getFullFormateDate(data.estimated_delivery_date,'G','0000-00-00 00:00:00'));

                this.serviceInfo.controls.feedback_code.setValue(data.feedback_code)
                this.serviceInfo.controls.first_name.setValue(data.first_name)
                this.serviceInfo.controls.grand_total.setValue(data.grand_total)
                this.serviceInfo.controls.has_app_downloaded.setValue(data.has_app_downloaded)
                this.serviceInfo.controls.home_phone.setValue(data.home_phone)
                this.serviceInfo.controls.id.setValue(data.id)
                this.serviceInfo.controls.inspection_reports.setValue(data.inspection_reports)
                this.serviceInfo.controls.insurance_expiration_date.setValue(this.globalService.getFullFormateDate(data.insurance_expiration_date,'GD','B'))
                this.serviceInfo.controls.is_DIY.setValue(data.is_DIY)
                this.serviceInfo.controls.is_paid.setValue(data.is_paid)
                this.serviceInfo.controls.is_supplies_charges_included.setValue(data.is_supplies_charges_included)
                this.serviceInfo.controls.is_tax_included.setValue(data.is_tax_included)
                this.serviceInfo.controls.is_usps_vehicle.setValue(data.is_usps_vehicle)
                this.serviceInfo.controls.labor_amount.setValue(data.labor_amount)
                this.serviceInfo.controls.labor_rate.setValue(data.labor_rate)
                this.serviceInfo.controls.last_name.setValue(data.last_name)
                this.serviceInfo.controls.license_plate.setValue(data.license_plate)
                this.serviceInfo.controls.mail_sent.setValue(data.mail_sent)
                this.serviceInfo.controls.make.setValue(data.make)
                this.serviceInfo.controls.middle_name.setValue(data.middle_name)
                this.serviceInfo.controls.mobile_phone.setValue(data.mobile_phone)
                this.serviceInfo.controls.model.setValue(data.model)
                this.serviceInfo.controls.modify_date.setValue(data.modify_date)
                this.serviceInfo.controls.no_report_performed.setValue(data.no_report_performed)
                this.serviceInfo.controls.non_taxable_amount.setValue(data.non_taxable_amount)
                this.serviceInfo.controls.notes.setValue(data.notes)
                this.serviceInfo.controls.notify_accept_reject_jobs_email.setValue(data.notify_accept_reject_jobs_email)
                this.serviceInfo.controls.notify_accept_reject_jobs_push.setValue(data.notify_accept_reject_jobs_push)
                this.serviceInfo.controls.notify_create_service_facility_car_email.setValue(data.notify_create_service_facility_car_email)
                this.serviceInfo.controls.notify_create_service_facility_car_push.setValue(data.notify_create_service_facility_car_push)
                this.serviceInfo.controls.notify_expiration_dates_email.setValue(data.notify_expiration_dates_email)
                this.serviceInfo.controls.notify_expiration_dates_push.setValue(data.notify_expiration_dates_push)
                this.serviceInfo.controls.notify_expiration_dates_web.setValue(data.notify_expiration_dates_web)
                this.serviceInfo.controls.notify_feedback_email.setValue(data.notify_feedback_email)
                this.serviceInfo.controls.notify_feedback_push.setValue(data.notify_feedback_push)
                this.serviceInfo.controls.notify_make_changes_car_facility_service_request_email.setValue(data.notify_make_changes_car_facility_service_request_email)
                this.serviceInfo.controls.notify_make_changes_car_facility_service_request_push.setValue(data.notify_make_changes_car_facility_service_request_push)
                this.serviceInfo.controls.notify_send_updated_info_to_customer_email.setValue(data.notify_send_updated_info_to_customer_email)
                this.serviceInfo.controls.notify_send_updated_info_to_customer_push.setValue(data.notify_send_updated_info_to_customer_push)
                this.serviceInfo.controls.notify_service_completion_email.setValue(data.notify_service_completion_email)
                this.serviceInfo.controls.notify_service_completion_push.setValue(data.notify_service_completion_push)
                this.serviceInfo.controls.notify_to_admin.setValue(data.notify_to_admin)
                this.serviceInfo.controls.notify_to_customer.setValue(data.notify_to_customer)
                this.serviceInfo.controls.parts_amount.setValue(data.parts_amount)
                this.serviceInfo.controls.password.setValue(data.password)
                this.serviceInfo.controls.payment_date.setValue(data.payment_date)
                this.serviceInfo.controls.payment_reminder_date.setValue(data.payment_reminder_date)
                this.serviceInfo.controls.payment_reminder_status.setValue(data.payment_reminder_status)
                this.serviceInfo.controls.present_mileage.setValue(data.present_mileage);
                this.serviceInfo.controls.present_mileage_out.setValue(data.miles_out);
                this.serviceInfo.controls.cc_fee_included.setValue(data.cc_fee_included);
                this.serviceInfo.controls.read_status.setValue(data.read_status)
                this.serviceInfo.controls.recommendation_saved.setValue(data.recommendation_saved)

                this.serviceInfo.controls.registration_expiration_date.setValue(this.globalService.getFullFormateDate(data.registration_expiration_date,'GD','B'))
                this.serviceInfo.controls.request_documents.setValue(data.request_documents)
                this.serviceInfo.controls.request_id.setValue(data.request_id);
                this.serviceInfo.controls.request_notes.setValue(data.request_notes)
                this.serviceInfo.controls.request_images.setValue(data.request_images)
                this.serviceInfo.controls.request_status.setValue(parseInt(data.request_status));
                this.serviceInfo.controls.requested_delivery_date.setValue(this.globalService.getFullFormateDate(data.requested_delivery_date,'G','B'))
                this.serviceInfo.controls.reset_pwd_token.setValue(data.reset_pwd_token)
                this.serviceInfo.controls.schedule_service_date.setValue(this.globalService.getFullFormateDate(data.schedule_service_date,'G','0000-00-00 00:00:00'))
                this.serviceInfo.controls.service_facility.setValue(data.service_facility)
                this.serviceInfo.controls.service_request_list.setValue(data.service_request_list)
                this.prepareSubJobCategoryList();
                this.serviceInfo.controls.shop_id.setValue(data.shop_id)
                this.serviceInfo.controls.state.setValue(data.state)
                this.serviceInfo.controls.status.setValue(data.status)
                this.serviceInfo.controls.supplies_charges.setValue(data.supplies_charges)
                this.serviceInfo.controls.suppliescharges.setValue(data.suppliescharges)
                this.serviceInfo.controls.tax_amount.setValue(data.tax_amount)
                this.serviceInfo.controls.tax_exempt_number.setValue(data.tax_exempt_number)
                this.serviceInfo.controls.tax_exempted.setValue(data.tax_exempted)
                this.serviceInfo.controls.tax_rate.setValue(data.tax_rate)
                this.serviceInfo.controls.technician.setValue(data.technician)
                this.serviceInfo.controls.title.setValue(data.title)
                this.serviceInfo.controls.total_amount.setValue(data.total_amount)
                this.serviceInfo.controls.total_paid.setValue(this.parseFloats(data.total_paid));
                this.serviceInfo.controls.total_cc_fee.setValue(this.parseFloats(data.total_cc_fee));
                this.vehicle_pic=data.vehicle_pic
                this.vin_photo=data.vin_photo
                this.serviceInfo.controls.vin.setValue(data.vin)
                this.serviceInfo.controls.year.setValue(data.year)
                this.serviceInfo.controls.zip.setValue(data.zip);
                this.serviceInfo.controls.service_facility_name.setValue(data.service_facility_name);
                this.serviceInfo.controls.is_appointment.setValue(data.is_appointment);
                this.serviceInfo.controls.on_the_lot.setValue(data.on_the_lot == "1" ? true : false)
                this.serviceInfo.controls.parts_awaited.setValue(data.parts_awaited == "1" ? true : false)
                this.serviceInfo.controls.accountant_verification.setValue(data.accountant_verification == "1" ? true : false)
                this.requestCustomerID = data.customer_id;  
                this.upload_v = [];
                if(data.walk_around_video!=null && data.walk_around_video!='null'){
                  this.upload_v.push(data.walk_around_video);
                }
                /* GET REQUIRE APPROVAL DETAILS */
                this.authorizeBy = data.authorize_by;
                this.contactPerson = data.contact_person;
                this.contactPhoneNumber = data.contact_person_phone_number;
                this.dateTimeOfCall = this.globalService.getFullFormateDate(data.date_time_of_call,'G','H');
                this.otherMeans = data.other_means_of_contacts;
                /* END */
                this.checkPartsTechLaborSetting();
                this.makeStatusStructure();
                this.downloadCarInspectionReport();
                this.updateLevelPrice();
                this.updateAcceptReject(0,0,false);
                this.calculatefinalAmount (this.serviceInfo.value.tax_rate,this.serviceInfo.value.supplies_charges);
                this.subTotal = this.addArray(this.totalAcceptedCalculation).toFixed(2);
                // this.totalTax = ((this.subTotal * this.serviceInfo.value.tax_rate)/100).toFixed(2);
                this.addLaborTimeInRecommendationLevel();
                if((data.is_partstech_user=="1") && this.isSubscribed){
                  this.showPartsTechButton=true;
                  this.getPartstechData();
                }
                if((data.is_nexlink_user=="1") && this.isNexpartSubscribed){
                  this.showNexpartButton=true;
                }
                this.getPartstechOrder();
                this.getNexpartOrder();
            } else {
                this.showServiceDetail = false;
                this.globalService.snackbar("error", response.message);
            }

            let byPassRoute = 'shops/service-inspection-report',prevRoute = this.globalService.reConstructPath(this.app.previousUrl);
            if(prevRoute != byPassRoute){
              let isEnable=this.globalService.isAllowed(this.app,'service_request','views');
              if(isEnable){
              this.startTracker();
              }
            }
            
            /* GET CUSTOMER DETAILS */
            var customerOprs = "user_id="+this.currentUserID+"&user_type="+this.userType+"&customer_id="+ this.customer_id;
            this.globalService.callAPI(globalConstants.API_GET_CUSTOMER_DETAILS, customerOprs).subscribe((response:any) => {
                if (response.result == '1') {
                    this.requestCustomerDetails = response.data;
                    this.getRequireApproval = this.requestCustomerDetails.require_approval;
                    this.getApprovalAmount = this.requestCustomerDetails.approval_amount;
                }
            });
            /* END */

            /* open Payment dialog */
            let fillRecommendationPaymentDialog = localStorage.getItem("fillRecommendationPaymentDialog-"+this.serviceRequestId);
            if(fillRecommendationPaymentDialog=='1' && this.serviceInfo.value.request_status=='6'){
              this.openPaymentDialog();
            }
            /* END */
            this.waitingForPartCheckBoxStatus = (this.serviceInfo.value.request_status!='6' && this.serviceInfo.value.request_status!='8') ? true : false;
            this.getDefaultServiceRequestLabel();
        });

    //   this.globalService.checkValueSetStatus.subscribe((valueStatus)=>{
    //   if(valueStatus){
    //   this.serviceInfo.controls.request_status.setValue('6');
    //   this.cdr.markForCheck();
    //   }
    //  })
    }

    ngAfterViewInit(): void {
      //control timer ops here if needed

    }

    videoShowMain(val): void {
      console.log(val);
      let dialogRef = this.dialog.open(VideoShowComponent, {
        panelClass: 'car-dialog-form',
        width: "800px",
        data: {name: val, url : globalConstants.S3_BUCKET_URL + globalConstants.WALKAROUND_VIDEO}
      });
    
      dialogRef.afterClosed().subscribe(result => {
    
      });
    }

    /**
   * updateOnTheLotStatus
   */
  public updateOnTheLotStatus(value) {
    this.showLabelsDropdown = false;
    let checked: any = value.checked == true ? 1:0;
    this.globalService.callAPI(globalConstants.API_UPDATE_ON_THE_LOT_STATUS, "request_id=" + this.serviceRequestId + "&user_id=" + this.globalService.getCurrentUser()["id"] + "&on_the_lot=" + checked + "&user_type=User").subscribe((response: any) => {
      if (response.result == 1) {
        if (checked == 1) {
          this.preSelectLabel.push('OTL');
          this.showLabelsDropdown = true;
        } else {
          this.removeCustomLabelInPreSelectLabel('OTL');
          this.showLabelsDropdown = true;
        }
        this.globalService.snackbar("success", response.message);
      }
    })
  }

  // get default service request label
  public getDefaultServiceRequestLabel() {
    this.showLabelsDropdown = false;
    this.preSelectLabel = [];
    this.globalService.callAPI(globalConstants.API_GET_SERVICE_REQUEST_LABELS, "request_id=" + this.serviceRequestId + "&user_id=" + this.globalService.getCurrentUser()["id"] + "&user_type=User").subscribe((response: any)=> {
      if (response.result == "1") {
        let labels = response.data;
        if (Array.isArray(labels) && labels.length > 0) {
          labels.forEach((r, v) => {
            this.preSelectLabel.push(r.label_id);
          })
        }
        this.addCustomLabels();
        this.showLabelsDropdown = true;
      }
    })
  }
  addCustomLabels(){
      this.showLabelsDropdown = false;
      this.allLabels.push(this.customLabels[0]); //adding OTL custom label
      this.allLabels.push(this.customLabels[1]);//adding waiting for parts custom label
      if(this.serviceInfo.value.request_status=='6' || this.serviceInfo.value.request_status=='8'){
          this.allLabels.push(this.customLabels[2]); //adding accountant verification custom label
      }
      this.allLabels = this.globalService.sortLabelsListInAlphabeticallyOrder(this.allLabels);
      if(this.serviceInfo.value.on_the_lot == true){
           this.preSelectLabel.push('OTL');
        } 
        if(this.serviceInfo.value.parts_awaited == true){
           this.preSelectLabel.push('WAITING_FOR_PARTS');
        }
        if(this.serviceInfo.value.accountant_verification == true){
           this.preSelectLabel.push('ACCOUNTANT_VERIFIED');
        }
        this.showLabelsDropdown = true;
  }

  getMetrixReport(){
    this.globalService.callAPI(globalConstants.API_SR_STATISTICS, "request_id="+this.serviceRequestId+"&user_id="+this.globalService.getCurrentUser()["id"]+"&user_type=User").subscribe((response:any)=>{
      if(response.result == "1" && 'data' in response){       
        this.businessMetricReport = Object.assign({}, response.data);
      }
    });
  }
  

    // /shops/service-inspection-report/3266
    gotoFillRecommendationPage(){
      if(this.serviceInfo.value.present_mileage == undefined || this.serviceInfo.value.present_mileage == ""){
        this.addMileageDialog(this.serviceInfo.value.distance_covered);
      }else{
        this.router.navigateByUrl("/shops/service-inspection-report/"+this.serviceInfo.value.id)
      }
    }
    /* Add mileage if it is empty in case of fill recomendation */
    addMileageDialog(distanceCovered): void {
      let dialogRef = this.dialog.open(AddMileageDialogComponent, {
          panelClass: ['payment-dialog'],
          data: {request_id:this.serviceInfo.value.id, distance_covered:distanceCovered, action_type:1}
      });

      dialogRef.afterClosed().subscribe(result => {
          if(typeof dialogRef['actionType']!='undefined' && dialogRef['actionType']==1 ){
            this.router.navigateByUrl("/shops/service-inspection-report/"+this.serviceInfo.value.id)
          }
      });
    }
    show(obj){ /*console.log(obj)*/ }
    chkDate(date){
      return this.globalService.isOldDate(date) ? true : false
    }
    ConvertToNumber(str){ return parseInt(str) }
    getTotalPrice(object) {
      var totalPriceForGj = 0,totalPriceForParts = 0,rejectedJobCost = 0, nonTaxableAmount=0;
      var labourCost = 0;
      for(var i=0;i<object.children.length;i++) {
        if(object.children[i].children!=undefined && object.children[i].children.length > 0) {

          // do the level 2 jobs
          this.callToThirdLevelCalculations(object.children[i].children);
        } else {
          if(object.children[i].recommendations!=undefined && object.children[i].recommendations.length > 0) {

            for(var j=0;j<object.children[i].recommendations.length;j++) {
              labourCost = 0;
              var scopedNonTaxedValue = 0;
              labourCost = labourCost + parseFloat(object.children[i].recommendations[j].estimated_labor);
              // calculate price for parts
              for(var k=0;k<object.children[i].recommendations[j].parts.length;k++) {
                if(object.children[i].recommendations[j].parts.length > 0) {
                  totalPriceForParts = parseFloat(totalPriceForParts.toString()) + (object.children[i].recommendations[j].parts[k].part_price * object.children[i].recommendations[j].parts[k].quantity);
                  if(object.children[i].recommendations[j].parts[k].non_taxable == "1" && object.children[i].recommendations[j].approval_status !="2") {
                    scopedNonTaxedValue = scopedNonTaxedValue  + (object.children[i].recommendations[j].parts[k].part_price * object.children[i].recommendations[j].parts[k].quantity);
                  }
                }
              }

              // calculate cost for generic jobs
              for(var k=0;k<object.children[i].recommendations[j].generic_jobs.length;k++) {
                totalPriceForGj = totalPriceForGj + (object.children[i].recommendations[j].generic_jobs[k].price * object.children[i].recommendations[j].generic_jobs[k].quantity);
                if(object.children[i].recommendations[j].generic_jobs[k].non_taxable == "1" && object.children[i].recommendations[j].approval_status !="2") {
                  scopedNonTaxedValue = scopedNonTaxedValue  + (object.children[i].recommendations[j].generic_jobs[k].price * object.children[i].recommendations[j].generic_jobs[k].quantity);
                }
              }

              this.nonTaxableValue.push(scopedNonTaxedValue);
              // console.log("this.nonTaxableValue",  this.nonTaxableValue)
              var totalAmount = parseFloat(totalPriceForParts.toString()) + parseFloat(totalPriceForGj.toString());
              if(this.isLaborTax){
                this.nonTaxableValue.push(labourCost);
              }
              totalAmount=totalAmount+parseFloat(labourCost.toString());
              totalAmount = parseFloat(totalAmount.toString());
              this.levelPriceObj[object.children[i].recommendations[j].id]=totalAmount.toFixed(2)
              if(object.children[i].recommendations[j].approval_status == "2") {
                this.rejectedJobCalculation.push(totalAmount);
                this.nonTaxableValue.splice(this.nonTaxableValue.length-1,1)
              } else {
                this.totalAcceptedCalculation.push(totalAmount);
              }
              //console.log("totalAmount",totalAmount)
              totalAmount = 0.00;
              totalPriceForParts = 0.00;
              totalPriceForGj = 0.00;
            }
            // console.log("accepted",)
          }
        }
      }

      return [this.totalAcceptedCalculation,this.rejectedJobCalculation]
    }

    calculatefinalAmount(tax_rate,supplies_charges) {
      var acceptedValue = this.addArray(this.totalAcceptedCalculation);
      var totalNonTaxedValue = this.addArray(this.nonTaxableValue);
      var taxableValue = acceptedValue - totalNonTaxedValue;
      var finalValue;
      var totalCalculatedAmount 

      if(this.serviceInfo.value.request_status == "6" || this.serviceInfo.value.request_status == "8") {
         this.SrComplete = true;
        finalValue = this.serviceInfo.value.grand_total;
      } else if(this.serviceDetail.is_recommendation_saved == "1" || this.serviceInfo.value.request_status == "2" || this.serviceInfo.value.request_status == "3" ||  this.serviceInfo.value.request_status == "4" || this.serviceInfo.value.request_status == "5" || this.serviceInfo.value.request_status == "7" || this.serviceInfo.value.request_status == "12" ){
         this.SrComplete = false;
        if(this.serviceInfo.value.is_tax_included  == "1") {
          totalCalculatedAmount = acceptedValue + (taxableValue * parseFloat(tax_rate)/100);
        } else {
          totalCalculatedAmount = acceptedValue;
        }

        if(this.serviceInfo.value.is_supplies_charges_included == "1") {
          let isJobRejected=this.isAllRejected();
          finalValue = totalCalculatedAmount + (isJobRejected ? parseFloat(supplies_charges):0);
        } else  {
          finalValue = totalCalculatedAmount
        }
      } else {
        finalValue = this.serviceInfo.value.total_amount;
      }
      this.totalTax=(taxableValue * parseFloat(tax_rate)/100).toFixed(2);
      if(finalValue!=null) {
        this.finalShowAbleAmount = this.parseFloats(finalValue);        
        this.balance=(parseFloat(this.finalShowAbleAmount) - parseFloat(this.serviceInfo.value.total_paid));
        this.calculateCCFee({checked: this.serviceInfo.controls.cc_fee_included.value == '1'});
      }
    }

    callToThirdLevelCalculations(secondLevelChildren) {

      var totalPriceForGj = 0,totalPriceForParts = 0,rejectedJobCost = 0;
      var labourCost = 0;
      let total=0
      for(var i=0;i<secondLevelChildren.length;i++) {
        if(secondLevelChildren[i].recommendations.length > 0) {
          for(var j=0;j<secondLevelChildren[i].recommendations.length;j++) {
            var tobeDeducted = 0;
            var scopedNonTaxedValue = 0;
            totalPriceForGj = 0;
            totalPriceForParts = 0;
            rejectedJobCost = 0;

            for(var k=0;k<secondLevelChildren[i].recommendations[j].parts.length;k++) {
              totalPriceForParts = totalPriceForParts + (secondLevelChildren[i].recommendations[j].parts[k].part_price * secondLevelChildren[i].recommendations[j].parts[k].quantity);
              if(secondLevelChildren[i].recommendations[j].parts[k].non_taxable == "1" && secondLevelChildren[i].recommendations[j].approval_status !="2") {
                scopedNonTaxedValue = scopedNonTaxedValue + (secondLevelChildren[i].recommendations[j].parts[k].part_price * secondLevelChildren[i].recommendations[j].parts[k].quantity)
              }
            }

            for(var k=0;k<secondLevelChildren[i].recommendations[j].generic_jobs.length;k++) {
              totalPriceForGj = totalPriceForGj + (secondLevelChildren[i].recommendations[j].generic_jobs[k].price * secondLevelChildren[i].recommendations[j].generic_jobs[k].quantity);
              if(secondLevelChildren[i].recommendations[j].generic_jobs[k].non_taxable == "1" && secondLevelChildren[i].recommendations[j].approval_status !="2") {
                scopedNonTaxedValue = scopedNonTaxedValue + (secondLevelChildren[i].recommendations[j].generic_jobs[k].price * secondLevelChildren[i].recommendations[j].generic_jobs[k].quantity);
              }
            }

            this.nonTaxableValue.push(scopedNonTaxedValue);
            labourCost = labourCost + parseFloat(secondLevelChildren[i].recommendations[j].estimated_labor);

            var totalAmount = parseFloat(totalPriceForParts.toString()) + parseFloat(totalPriceForGj.toString()) - parseInt(tobeDeducted.toString());
            if(this.isLaborTax){
              this.nonTaxableValue.push(labourCost);
            }
            totalAmount=totalAmount+parseFloat(labourCost.toString());
            totalAmount = parseFloat(totalAmount.toString());
            total+=totalAmount
            if(secondLevelChildren[i].recommendations[j].approval_status == "2") {
              this.nonTaxableValue.splice(this.nonTaxableValue.length-1,1)
              this.rejectedJobCalculation.push(totalAmount)
            } else {
              this.totalAcceptedCalculation.push(totalAmount);
            }
            this.levelPriceObj[secondLevelChildren[i].recommendations[j].id]=totalAmount.toFixed(2)
            tobeDeducted = 0;
            totalAmount = 0;
            totalPriceForParts = 0;
            totalPriceForGj = 0;
            labourCost = 0
          }
        } else {
        }
      }
    }
    getRecommendationIds(){
      var recIds= []
      var service_request = this.serviceInfo.value.service_request_list
      service_request.forEach(function(x) {
          // console.log(x.children);
          x.children.forEach(f => {
            if(typeof f.children!='undefined' && f.children.length > 0) {
              f.children.forEach(function(w){

                recIds.push(w.recommendations[0]["id"])
              })
            } else {

              recIds.push(f.recommendations[0]["id"])
            }
          });
      })
      return recIds
    }
    getAcceptAndRejectCalculationForPage(serviceRequest,returningStatus){
      var total = 0;
      var rejected = 0;
      var approved = 0;
      var finalCalculation = 0;
      serviceRequest.children.forEach(l2child => {

        if(l2child.recommendations.length>0) {
          total = 0;
          var l2childRecommendation = l2child.recommendations[0];
          var approval_status = l2childRecommendation.approval_status;

          // parts calculation for l2

          if(l2childRecommendation.parts.length > 0) {
            l2childRecommendation.parts.forEach((part)=>{
              total = total + (parseFloat(part.part_price) * parseFloat(part.quantity))
            })
          }

          // GJ calculation for l2

          if(l2childRecommendation.generic_jobs.length > 0) {
            l2childRecommendation.generic_jobs.forEach(gj => {
              total = total + (parseFloat(gj.price) * parseFloat(gj.quantity))
            });
          }
          total = total + parseFloat(l2childRecommendation.estimated_labor);
          finalCalculation = finalCalculation + total
          if(approval_status == "2") {
            rejected = rejected + total;
          } else {
            approved = approved + total;
          }
          // this.ObjectJson.push({[serviceRequest.id]:{[l2child.id]:{"total":total,"status":approval_status}}})

        }
        else {
          l2child.children.forEach(l3child=>{

            // console.log("l3.id",l3child.id)
            total = 0;
            var l3childRecommendation = l3child.recommendations[0];
            var approval_status = l3childRecommendation.approval_status;

            if(l3childRecommendation.parts.length > 0) {
              l3childRecommendation.parts.forEach((part)=>{
                total = total + (parseFloat(part.part_price) * parseFloat(part.quantity))
              })
            }
            if(l3childRecommendation.generic_jobs.length > 0) {
              l3childRecommendation.generic_jobs.forEach(gj => {
                total = total + (parseFloat(gj.price) * parseFloat(gj.quantity))
              });
            }
            total = total + parseFloat(l3childRecommendation.estimated_labor);

            finalCalculation = finalCalculation + total
            if(approval_status == "2") {
              rejected = rejected + total;
            } else {
              approved = approved + total;
            }
          });
        }
      });

      if(returningStatus == null ){
        return [approved,rejected]
      }
      // return returningStatus == "accept" ? approved : rejected;
    }
    setParams() {
        this.activatedRoute.params.subscribe((params: Params) => {
            this.serviceRequestId = params["id"];
            this.globalService.srnoteListId(this.serviceRequestId);
        });
    }
    addArray(array) {

      var sum = 0.00;
      if(array!=undefined) {
        for(var i=0;i<array.length;i++) {
          sum = sum + parseFloat(array[i])
        }
      }
      return sum
    }
    fillRecommendation(id) {
        this.router.navigate(["/shops/service-inspection-report/" + id]);
    }
    getBalance(gTotal, bal) {
      if (bal != null) {
          return ((parseFloat(gTotal) - parseFloat(bal)) < 0 ? 0 : (parseFloat(gTotal) - parseFloat(bal))).toFixed(2)
      }
      return gTotal;
    }
    openImageDialog(catType){
        let imgfile;
      if(catType == 1){
        imgfile= this.vehicle_pic;
      }else{
        imgfile= this.vin_photo;
      }
      let dialogRef = this.dialog.open(ImagedialogComponent, {
          panelClass: ['image-dialog'],
          data: {img:imgfile, category:catType}

      });
      imgfile="";
    }
    generateInvoice(rid, total, taxRate, isTaxIncluded,override?:any) {
      //   var conf ='Are you sure you want to mark this request as complete and generate its invoice? Once complete no changes can be made!';
      //   this.globalService.confirmDialog('notifyAdmin', conf).subscribe((r) => {
        
      //   var url=globalConstants.API_GENERATE_INVOICE_URL;

      //   let userId=this.globalService.getCurrentUser()["id"];
      //   let res: any = r;
      //   if (res == 'notifyAdmin') {
      //     this.submitService(rid, total, taxRate, isTaxIncluded,false);
      //   } else {
      //       return false;
      //   }
      // });
      this.milesOut({rid:rid,total:total,tax:taxRate,isTax:isTaxIncluded,fullRequestId: this.serviceDetail.request_id});
    }
    expansionOpen(ele) {
      var target= '#expansion-'+ ele;
      setTimeout(() => {
         var body = $("html, body");
         body.animate({
                scrollTop: jQuery(target).offset().top - 90
          }, 500);
       }, 500);
   }
    disclaimerOpen(): void {
        let dialogRef;
        let th=this;
        let content = "user_id=" + this.globalService.getCurrentUser()["id"] + "&user_type=" + this.globalService.getCurrentUser()["user_type"];
        this.globalService.callAPI(globalConstants.API_GET_DISCLAIMERS_LIST_URL, content).subscribe((response:any) => {
            if (response.result == 1) {
                dialogRef = this.dialog.open(changeDisclaimer, {
                    panelClass: 'car-dialog-form',
                    width: '800px',
                    data: {dataSources: response, requestId: this.serviceRequestId, disclaimer: '',disclaimer_id:th.serviceInfo.value.disclaimer_id}
                });
                dialogRef.afterClosed().subscribe(result => {
                    if(typeof dialogRef["response"] != 'undefined'){
                      th.serviceInfo.controls.disclaimer.setValue(dialogRef["response"]["disclaimer"]);
                      th.serviceInfo.controls.disclaimer_id.setValue(dialogRef["response"]["disclaimer_id"]);
                    }
                    th.cdr.markForCheck();
                    // console.log('The dialog was closed');
                });
            } else {
                this.globalService.snackbar("error", response.message);
            }
        });
    }
    downloadCarInspectionReport() {
      let downloadReport_URL = '';
      let reportData;
      let qryStringUrl = "";
      let data=this.serviceInfo.value.inspection_reports;
      if (data.length) {
        reportData = (data[0].acdelco_report_data != undefined) ? data[0].acdelco_report_data : data[0].usps_report_data;
        let reportType = data[0].report_type;
        if (reportType == '2') {
          downloadReport_URL = globalConstants.API_DOWNLOAD_INSPECTION_REPORT_ACDELCO_URL;
          qryStringUrl += "?report_type="+reportType;
               qryStringUrl += "&acdelco_report_id=" + reportData.id;
          this.reportDownloadType = 2;
        } else if (reportType == '1'){
          downloadReport_URL = globalConstants.API_DOWNLOAD_INSPECTION_REPORT_USPS_URL;
          reportData = data[0].usps_report_data;
          qryStringUrl += "?report_type="+reportType;
               qryStringUrl += "&usps_report_id=" + reportData.id;
          this.reportDownloadType = 1;
        }
        this.reportDownloadLink = downloadReport_URL + qryStringUrl;
      }else{
        this.reportDownloadLink = '';
      }
    }

    invokeDownload(url,type,fileName=''){
      let localFileName = 'DownloadedFile',localNameExt,localName;
      if(type == 'ServiceRequestDocument'){
        localName = fileName.split('.');
        localNameExt = localName[localName.length-1];
        localFileName = 'ServiceRequestDocument.'+localNameExt;
      }else if(type == 'CARInspectionReport'){
        localFileName = 'CARInspectionReport.pdf';
      }
      //console.log(localFileName);
      this.globalService.downloadAsset(url,localFileName);
    }

    makeStatusStructure() {
      var recommendations = []
      var jobs = this.serviceInfo.controls.service_request_list.value;
      // console.log(jobs)
      jobs.filter(function(x){
        x.children.filter(function(y){
          if(y.children!=undefined && y.children.length > 0){
            y.children.filter(function(z){
              recommendations.push(z.recommendations[0])
            })
          } else {
            recommendations.push(y.recommendations[0])
          }
        })
      })
      recommendations.forEach((x)=>{
        if(x.job_level == "2") {
          this.jobStatuses.push({
            l1Id:x.level1ParentId,
            l2Id:x.service_request_list_id,
            l3Id:null,
            recommendationId:x.id,
            status:x.approval_status
          })
        } else if(x.job_level == "3") {
          this.jobStatuses.push({
            l1Id:x.level1ParentId,
            l2Id:x.level2ParentId,
            l3Id:x.service_request_list_id,
            recommendationId:x.id,
            status:x.approval_status
          })
        }
      });
      // console.log(this.jobStatuses)
    }
    checkJobAndApply(){
      var nonresponsedJobs = this.jobStatuses.filter(function(x){return x.status != 1 && x.status!=2})
      if(nonresponsedJobs.length > 0){
        this.globalService.snackbar("error","Please approve/reject jobs")
      }
    }
    updateText(event, rec_id, recommendation,service_request,type){

      // let textChange = <HTMLElement> document.getElementById(event.source.id).childNodes[0].childNodes[1];
      // var f= document.getElementById(event.source.id);
      // var prevbtn = f.offsetParent.children[0].children[0].children[0].children[1];
      // var nxtbtn = f.offsetParent.children[0].children[1].children[0].children[1];

      if(event.value == 'accept'){
        if(event.source.checked){
          // textChange.innerText = 'Accept';
          var job = this.jobStatuses.filter(function(x){x.recommendationId == recommendation.id});
          // if(nxtbtn.textContent == "Undo Reject") {
          //   nxtbtn.textContent = "Reject"
          // }
          this.updateStatusVehicle(rec_id,recommendation,service_request,0,service_request.id,type);
        }else{
          // event.value = 'Undo Accept';
          // textChange.innerText = 'Undo Accept';
          var job = this.jobStatuses.filter(function(x){x.recommendationId == recommendation.id});
          // if(nxtbtn.textContent == "Undo Reject") {
          //   nxtbtn.textContent = "Reject"
          // }
          this.updateStatusVehicle(rec_id,recommendation,service_request,1,service_request.id,type);
        }
      }else{
        if(event.source.checked){
          // this.statusRejectText = 'Reject';
          // textChange.innerText = 'Reject';
          var job = this.jobStatuses.filter(function(x){x.recommendationId == recommendation.id});
          this.updateStatusVehicle(rec_id,recommendation,service_request,0,service_request.id,type);
          // if(prevbtn.textContent == "Undo Accept") {
          //   prevbtn.textContent = "Accept"
          // }
        }else{
          // this.statusRejectText = 'Undo Reject';
          // textChange.innerText = 'Undo Reject';
          var job = this.jobStatuses.filter(function(x){x.recommendationId == recommendation.id});

          // if(prevbtn.textContent == "Undo Accept") {
          //   prevbtn.textContent = "Accept"
          // }
          this.updateStatusVehicle(rec_id,recommendation,service_request,2,service_request.id,type);
        }
      }
      
    }
    updateStatusVehicle(recId,recommendation,service_request, status,level1Id,type){
      let content = 'access_timestamp=' + this.access_timestamp + "&rec_id=" + recId + "&status="+status+"&level1Id="+level1Id;
      this.globalService.callAPI(globalConstants.API_UPDATE_RECOMMENDATION_STATUS_URL, content).subscribe((data:any)=>{
         // console.log("data", data);
        if(data.result == "1"){
          this.access_timestamp=data.access_timestamp;
          this.globalService.snackbar("success", data.message);
          // console.log("new grand total=",data.grand_total)
          this.ObjectJson[service_request.id]["AcceptedValue"] = data.accepted_total.toFixed(2)
          this.ObjectJson[service_request.id]["RejectedValue"] = data.rejected_total.toFixed(2)
          this.finalShowAbleAmount = this.parseFloats(data.grand_total);
          this.balance  = (parseFloat(this.finalShowAbleAmount) - parseFloat(this.serviceInfo.value.total_paid));
          this.calculateCCFee({checked: this.serviceInfo.controls.cc_fee_included.value == '1'});
          this.subTotal = this.parseFloats(data.sub_total);
          //this.totalTax = ((this.subTotal * this.serviceInfo.value.tax_rate)/100).toFixed(2);
          this.totalTax = this.parseFloats(data.tax_total);
          this.serviceInfo.controls['supplies_charges'].setValue(data.supplies_charges);
          this.updateAcceptReject(recId,type,false,false);
          // To apply discount 
          this.applyDiscount(this.serviceDetail.discounts.total_discounted_amount);
        }else{
          this.globalService.snackbar("error", data.message);
        }
      });
    }
    toNumber(value){
      return parseInt(value)
    }
    /* image rotation dialog  */
   imageToolDialog(current,i,type,imgaar): void {
    let dialogRef = this.dialog.open(ImagetooldialogComponent, {
        panelClass: ['imageToolDialog'],
        width: "100%",
        data: {event: current,index:i,imgType:type,imgArray:imgaar,customerId:this.globalService.getCurrentUser()["id"]}
    });

    dialogRef.afterClosed().subscribe(result => {
        //console.log('The dialog was closed');
        if(type == 1){
          this.vehicleTimeStamp = new Date().getTime();
        }else if(type == 5){
          this.vinTimeStamp = new Date().getTime();
        }
    });
    }

    /* Require Approval dialog  */
    requireApprovalDialog(markComplete:boolean = false): void {
        let dialogRef = this.dialog.open(RequireApprovalDialogComponent, {
            panelClass: ['payment-dialog'],
            width:'800px',
            data: {service_request_id: this.serviceRequestId, access_timestamp: this.access_timestamp, user_id: this.globalService.getCurrentUser()["id"], customer_id: this.customer_id, jobStatus: this.getRejetedJob,fullRequestId: this.serviceDetail.request_id}
        });

        dialogRef.afterClosed().subscribe(result => {
            // console.log('The dialog was closed = ', result);
            if(result){
              this.askForServiceCompletion(markComplete);
            }
        });
    }
    /* END */

    getInvoicePdf(){
      let userId=this.globalService.getCurrentUser()["id"];
      let url = globalConstants.API_GENERATE_INVOICE_URL;
      let data={
        "service_request_id":this.serviceRequestId,
        "user_type":"User",
        "invoke_download":1,
        "user_id":userId,
        "is_tax_included":this.serviceInfo.value.is_tax_included,
        "tax_rate":this.serviceInfo.value.tax_rate,
        "total":this.serviceInfo.value.grand_total
      };
      let skipFirst:boolean = true;
      for(let d in data){
        //console.log(d,data[d]);
        if(skipFirst == true){
          url += "?"+d+"="+data[d];
          skipFirst = false;
        }else{
          url += "&"+d+"="+data[d];
        }
      }
       let serviceRequestId = this.serviceInfo.controls.request_id.value;
       this.globalService.downloadFileWithGet(url,serviceRequestId);
       // these code for set the value for complete mark when response going in applicaltion/json  and change the button on top but now no need this code
        // this.serviceInfo.controls.request_status.setValue('6');
        // this.cdr.markForCheck(); 
    }
    acceptAll(){
      this.recIds = this.getRecommendationIds();
        // API_update_all_recommendation_statuses.php
      let content = 'access_timestamp=' + this.access_timestamp + "&rec_ids=" + this.recIds + "&status=1&from_v2=1";
      this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_UPDATE_ALL_RECOMMENDATION_STATUES, content).subscribe((data:any)=>{

          if(data.result==1){
            this.access_timestamp=data.access_timestamp;
            this.updateAcceptReject(0,1,true);
            this.updateLevelPrice();
            this.applyChanges=true;
            this.finalShowAbleAmount = this.parseFloats(data.grand_total);
            this.balance=(parseFloat(this.finalShowAbleAmount) - parseFloat(this.serviceInfo.value.total_paid));
            this.calculateCCFee({checked: this.serviceInfo.controls.cc_fee_included.value == '1'});
            this.subTotal = this.parseFloats(data.sub_total);
            this.totalTax = ((this.subTotal * this.serviceInfo.value.tax_rate)/100).toFixed(2);
            this.serviceInfo.controls['supplies_charges'].setValue(data.supplies_charges);
            // To apply discount 
            this.applyDiscount(this.serviceDetail.discounts.total_discounted_amount);
            this.globalService.snackbar("success", data.message);
            this.finalUpdateToNotifyAdmin(this.serviceRequestId,2);
          }else{
            this.globalService.snackbar("error", data.message);
          }
           // this.ObjectJson[service_request.id]["AcceptedValue"] = data.accepted_total.toFixed(2)
           // this.ObjectJson[service_request.id]["RejectedValue"] = data.rejected_total.toFixed(2)
           
        })
      // this.ngOnInit();
    }
    rejectAll(){
      this.recIds = this.getRecommendationIds();
      let content ='access_timestamp=' + this.access_timestamp + "&rec_ids=" + this.recIds + "&status=2&from_v2=1";
      this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_UPDATE_ALL_RECOMMENDATION_STATUES, content).subscribe((data:any)=>{
        
        if(data.result==1){
          this.access_timestamp=data.access_timestamp;
          this.updateAcceptReject(0,2,true);
          this.updateLevelPrice();
          this.applyChanges=true;
          this.subTotal = this.parseFloats(data.sub_total);
          this.totalTax = ((this.subTotal * this.serviceInfo.value.tax_rate)/100).toFixed(2);
          this.finalShowAbleAmount = this.parseFloats(data.grand_total);
          this.balance=(parseFloat(this.finalShowAbleAmount) - parseFloat(this.serviceInfo.value.total_paid));
          // To apply discount 
          this.calculateCCFee({checked: this.serviceInfo.controls.cc_fee_included.value == '1'});
          this.serviceInfo.controls['supplies_charges'].setValue(data.supplies_charges);
          this.applyDiscount(this.serviceDetail.discounts.total_discounted_amount);
          this.globalService.snackbar("success", data.message);
          this.finalUpdateToNotifyAdmin(this.serviceRequestId,2);
        }else{
          this.globalService.snackbar("error", data.message);
        }
      })
    }

    /* Apply Changes Function */
    finalUpdateToNotifyAdmin(request_id,opr = 1) {
        let conf = opr == 1 ? 'Are you sure you want to continue ?' : 'Do you want to continue and apply the changes ?';
        let note = 'All the jobs are rejected, so this action would remove the supplies charges.';
        let r=this.isAllRejected();
        if(!r){
          conf=conf+'<br/>'+note;
        }
        request_id=this.serviceRequestId;
        if (this.applyChanges) {
          this.checkRejectedJobs();
          this.globalService.confirmDialog('notifyAdmin', conf).subscribe((r:any) => {
            console.log('notify close res = ', r);
            let res: {id:string, markAsComplete:boolean} | undefined = r;
            if (res && res.id == 'notifyAdmin') {
              let r=(parseFloat(this.finalShowAbleAmount) > parseFloat( this.getApprovalAmount));
              if(this.getRequireApproval == '1' && r) {
                // If require approval TRUE
                localStorage.setItem("requestCreatedDate", this.requestCreatedDate);
                this.requireApprovalDialog(res.markAsComplete);
              } else {
                // If require approval FALSE
                let content='access_timestamp='+this.access_timestamp+"&request_id=" + request_id;
                this.globalService.callAPI(globalConstants.API_UPDATE_NOTIFY_TO_ADMIN_URL,content ).subscribe((response:any) => {
                  if (response.result == "1") {
                    this.globalService.snackbar("success", response.message);
                    // For Code 518 - to handle invalid coupon codes
                    // if(response.code == 518  && response.data.discounts_list){
                    //   // console.log('inavlid coupons detected');
                    //   this.viewRequestCoupon.reInitialize(response.data);
                    //   this.viewRequestCoupon.reset();
                    //   this.router.navigate(['shops/list-service-requests']);
                    // }
                    this.askForServiceCompletion(res.markAsComplete);
                  } else {
                    this.globalService.snackbar("error", response.message);
                  }
                });
              }
            }
          });
        } else {
          this.globalService.snackbar("error", 'Please provide the approval for every job otherwise reject them.');
        }
    }
    /* END */

    /* CHECK IF ALL JOBS ARE REJECTED */
    checkRejectedJobs() {
        let serviceRequest = this.serviceInfo.value.service_request_list;
        this.getRejetedJob = true;
        for (let i in serviceRequest) {
            for (let j in serviceRequest[i].children) {
                if (typeof serviceRequest[i].children[j].children!='undefined' && serviceRequest[i].children[j].children.length > 0) {
                    for (let k in serviceRequest[i].children[j].children) {
                        for (let l in serviceRequest[i].children[j].children[k].recommendations) {

                            if(serviceRequest[i].children[j].children[k].recommendations[l].service_request_list_id!='158'){
                                if(serviceRequest[i].children[j].children[k].recommendations[l].approval_status==1){
                                 this.getRejetedJob = false;
                                }
                            }
                        }
                    }
                } else {
                    for (let k in serviceRequest[i].children[j].recommendations) {

                        if(serviceRequest[i].children[j].recommendations[k].approval_status ==1){
                            this.getRejetedJob = false;
                        }
                    }
                }
            }
        }
    }

    /* END OF CHECK */

    /* Change Date Format */
    changeDateFormat(dateValue){
        let DateFormat = 'MM/DD/YYYY hh:mm A';
        return this.globalService.getFullFormateDate(dateValue,DateFormat,'H');
    }

    updateAcceptReject(rId, type, setAll,runCalc = true) {
      let serviceRequest = this.serviceInfo.value.service_request_list;
      this.applyChanges=true;
      for (let i in serviceRequest) {
        for (let j in serviceRequest[i].children) {
          if (typeof serviceRequest[i].children[j].children!='undefined' && serviceRequest[i].children[j].children.length > 0) {
            for (let k in serviceRequest[i].children[j].children) {
              for (let l in serviceRequest[i].children[j].children[k].recommendations) {
                if (setAll) {
                  this.serviceInfo.value.service_request_list[i].children[j].children[k].recommendations[l].approval_status = type;
                } else if (serviceRequest[i].children[j].children[k].recommendations[l].id == rId) {
                  if (serviceRequest[i].children[j].children[k].recommendations[l].approval_status == type) {
                    type = 0;
                  }
                  this.serviceInfo.value.service_request_list[i].children[j].children[k].recommendations[l].approval_status = type;
                }
                if(serviceRequest[i].children[j].children[k].recommendations[l].service_request_list_id!='158'){
                 if(this.serviceInfo.value.service_request_list[i].children[j].children[k].recommendations[l].approval_status==0){
                  this.applyChanges=false;
                 }
                }
              }
            }
          } else {
            for (let k in serviceRequest[i].children[j].recommendations) {
              if (setAll) {
                this.serviceInfo.value.service_request_list[i].children[j].recommendations[k].approval_status = type;
              } else if (serviceRequest[i].children[j].recommendations[k].id == rId) {
                if (serviceRequest[i].children[j].recommendations[k].approval_status == type) {
                  type = 0;
                };
                this.serviceInfo.value.service_request_list[i].children[j].recommendations[k].approval_status = type;
              }
              if(this.serviceInfo.value.service_request_list[i].children[j].recommendations[k].approval_status ==0){
               this.applyChanges=false;
              }
            }
          }
        }
      }
      if(runCalc){
        this.calculatefinalAmount (this.serviceInfo.value.tax_rate,this.serviceInfo.value.supplies_charges);
      }
      this.checkAcceptanceRejection(null,this.serviceInfo.value.notify_to_admin);
    }
    updateLevelPrice(){
         if(this.serviceInfo.value.request_status!="9" && this.serviceInfo.value.request_status!="10" )  {
      for(var i = 0;i<this.serviceInfo.value.service_request_list.length;i++) {
        this.totalReturnedValue = this.getTotalPrice(this.serviceInfo.value.service_request_list[i]);
        var returingObj = this.getAcceptAndRejectCalculationForPage(this.serviceInfo.value.service_request_list[i],null)
        this.ObjectJson[this.serviceInfo.value.service_request_list[i]["id"]]=
        (
          {
            AcceptedValue:returingObj[0].toFixed(2),
            RejectedValue:returingObj[1].toFixed(2)
          }
        )
      }
         }
    }
    /*******video dialog******/
 videoShow(val): void {
  let dialogRef = this.dialog.open(VideoShowComponent, {
      panelClass: 'car-dialog-form',
      width: "800px",
      data: {name: val, url: globalConstants.S3_BUCKET_URL + 'recommendation-videos/'}
  });

  dialogRef.afterClosed().subscribe(result => {

  });
}
checkAcceptanceRejection(serviceRequestList,isNotify){

  let requestList;
  if(serviceRequestList==null){
    requestList=this.serviceInfo.value.service_request_list;
  }else{
    requestList=serviceRequestList;
  }
  for(let i in requestList){
      let isAnyAccepted=false;
      let isAnyRejected=false;
      let isAnyRemain=false;
      let servicerequestChild=requestList[i].children;
      for(let j in servicerequestChild){
          let recommendation=servicerequestChild[j].recommendations;
          for(let k in recommendation){
              if(recommendation[k].approval_status==1){
                  isAnyAccepted=true;
              }else if(recommendation[k].approval_status==2){
                  isAnyRejected=true;
              }else{
                isAnyRemain=true;
              }
          }
          let level3=servicerequestChild[j].children;
          for(let k in level3){
              let recomand3=level3[k].recommendations;
              for(let m in recomand3){
                if (recomand3[m].service_request_list_id == '158') {

                } else if(recomand3[m].approval_status==1){
                      isAnyAccepted=true;
                  }else if(recomand3[m].approval_status==2){
                      isAnyRejected=true;
                  }else{
                    isAnyRemain=true;
                  }
              }
          }
      }
      if (isNotify == '1') {
        if (isAnyAccepted && (!isAnyRejected)) {
          this.headingColorFlag[i] = 2;
        } else if ((!isAnyAccepted) && (isAnyRejected)) {
          this.headingColorFlag[i] = 3;
        } else {
          this.headingColorFlag[i] = 1;
        }
      }else{
        if(isAnyRemain){
          this.headingColorFlag[i] = 3;
        }else{
          this.headingColorFlag[i] = 2;
        }
      }
  }

}
parseFloats(value){
  let val=parseFloat(value);
  if(isNaN(val)){
    return '0.00';
  }
  return val.toFixed(2);
}

 getPartstechData(){
  let content = "user_id=" + this.globalService.getCurrentUser()["id"] + "&user_type=" + this.globalService.getCurrentUser()["user_type"] + "&request_id=" + this.serviceRequestId;
  this.globalService.callAPI(globalConstants.API_GET_PARTSTECH_CART_SERVICE_REQUEST, content).subscribe((response:any) => {
    if(response.result=='1'){
      this.partstechData=response.data;
    }else{
      this.partstechData=[];
    }
  });
 }
 partstech() {
 let user_id=this.globalService.getCurrentUser()["id"] ;
 let userType= this.globalService.getCurrentUser()["user_type"] ;
   let dialogRef = this.dialog.open(PartstechComponent, {
     panelClass: 'partstech-order-dialog',
     data: { dataSources: this.partstechData,request_id: this.serviceRequestId,user_id:user_id,userType:userType}
   });
   dialogRef.afterClosed().subscribe(result => {
     let discard=(typeof dialogRef['discard']=='boolean'?dialogRef['discard']:false);
     let placeOrder=(typeof dialogRef['placeOrder']=='boolean'?dialogRef['placeOrder']:false);
     if(placeOrder||discard){
      // this.getPartstechData();
      // this.getPartstechOrder();
       this.reInit();
      }
   });

 }
 /** get partstech order for this request*/
 getPartstechOrder(){
  let content = "user_id=" + this.globalService.getCurrentUser()["id"] + "&user_type=" + this.globalService.getCurrentUser()["user_type"] + "&request_id=" + this.serviceRequestId;
  this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_PARTSTECH_ORDER_URL, content).subscribe((response:any) => {
    if(response.result=='1'){
      this.partstechOrder=response.data;
    }else{
      this.partstechOrder=[];
    }
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  },(errors)=>{});
 }
 showPartstechOrder(){
  let user_id=this.globalService.getCurrentUser()["id"] ;
  let userType= this.globalService.getCurrentUser()["user_type"] ;
    let dialogRef = this.dialog.open(PartstechComponent, {
      panelClass: 'partstech-order-dialog',
      data: { dataSources: this.partstechOrder,request_id: this.serviceRequestId,user_id:user_id,userType:userType,showOrder:true}
    });
    dialogRef.afterClosed().subscribe(result => {
      let cancelOrder=(typeof dialogRef['cancelOrder']=='boolean'?dialogRef['cancelOrder']:false);
      if(cancelOrder){
      //  this.getPartstechData();
      //  this.getPartstechOrder();
       this.reInit();
      }
    });
 }
 
 /** get partstech order for this request*/
 getNexpartOrder(){
  let content = "user_id=" + this.globalService.getCurrentUser()["id"] + "&user_type=" + this.globalService.getCurrentUser()["user_type"] + "&request_id=" + this.serviceRequestId;
  this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_NEXLINK_ORDERS_URL, content).subscribe((response:any) => {
    if(response.result=='1'){
      this.nexpartOrder=response.data;
    }else{
      this.nexpartOrder=[];
    }
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  },(errors)=>{});
 }
 showNexpartOrder(){
  let user_id=this.globalService.getCurrentUser()["id"] ;
  let userType= this.globalService.getCurrentUser()["user_type"] ;
    let dialogRef = this.dialog.open(PartstechComponent, {
      panelClass: 'partstech-order-dialog',
      data: { dataSources: this.nexpartOrder, orderNow:false, request_id: this.serviceRequestId,user_id:user_id, userType:userType, partShop:globalConstants.PART_SHOPS.NEXPART,showOrder:true }
    });
    dialogRef.afterClosed().subscribe(result => {
      let cancelOrder=(typeof dialogRef['cancelOrder']=='boolean'?dialogRef['cancelOrder']:false);
      if(cancelOrder){
      //  this.getPartstechData();
      //  this.getPartstechOrder();
      }
    });
 }
 workInProgress(){
   let content = "user_id=" + this.globalService.getCurrentUser()["id"] + "&user_type=" + this.globalService.getCurrentUser()["user_type"] + "&service_request_id=" + this.serviceRequestId;
   var conf = "Are you sure you want to continue?";
   this.globalService.confirmDialog('workInProgress', conf).subscribe((r) => {
   let res: any = r;
   if (res == 'workInProgress') {
    this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_REQUEST_SET_TO_WORK_IN_PROGRESS_URL, content).subscribe((response:any) => {
     if(response.result=='1'){
      this.globalService.snackbar("success", response.message);
      this.serviceInfo.controls.request_status.setValue('12');
     }else{
      this.globalService.snackbar("error", response.message);
     }
    });
   }
  });

 }
 editRequest(){
   let reqId=this.serviceRequestId;
   let content = "user_id=" + this.globalService.getCurrentUser()["id"] + "&user_type=" + this.globalService.getCurrentUser()["user_type"] + "&service_request_id=" + this.serviceRequestId;
   var conf = "Are you sure you want to continue?";
   this.globalService.confirmDialog('EditRequest', conf).subscribe((r) => {
   let res: any = r;
   if (res == 'EditRequest') {
    this.editRequestAllow(content,reqId);
  }
});
}

 editRequestAllow(content,reqId){
  this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_REQUEST_EDIT_MODE_URL, content).subscribe((response:any) => {
    if(response.result=='1'){
      this.globalService.snackbar("success", response.message);
      this.router.navigateByUrl('shops/service-inspection-report/'+ reqId);
     }else{
      this.globalService.snackbar("error", response.message);
     }
   });
 }

 convertNltoBr(text){
    return this.globalService.globalNltoBr(text);
 }
 reInit(){
  this.showDownload=false;
  this.showServiceDetail = false;
  this.serviceDetail= null
  this.serviceInfo.reset();
  this.initFormValue=false;
  this.finalShowAbleAmount=0;
  this.subTotal=0;
  this.totalTax=0;
  this.balance=0;
  this.ObjectJson = {};
  this.headingColorFlag={};
  this.balance=0;
  this.totalAcceptedCalculation = []
  this.rejectedJobCalculation = [];
  this.grandTotalAcceptedArray = [];
  this.grandTotalRejectedArray = [];
  this.nonTaxableValue = [];
  this.jobStatuses = [];
  this.partstechData=[];
  this.partstechOrder=[];
  this.ngOnInit();
  this.cdr.markForCheck();
}
getFileIcons(str){
  let ext=str.substr(str.lastIndexOf(".")+1);
  let type=0;
  if(ext!=''){
    type= this.globalService.getFileIcon({type:ext.toLowerCase()});
  }
  // console.log(str,ext,type);
  return type;
 }
  taskTracking(option?) {
    if(!option){
      if(this.taskTrack && (this.userTaskTrack==0 || this.userTaskTrack==2)){
        option = 1;
      }else if(this.taskTrack && this.userTaskTrack==1){
        option = 2
      }else{
        return;
      }
    }    
    let conf = 'Are you sure to continue?';
    this.globalService.confirmDialog('taskTrack', conf).subscribe((r) => {
      let res: any = r;
      if (res == 'taskTrack') {
        this.taskTrackingApi(option)
      }
    }); 

  }
 getStatus() {
  return this.serviceInfo.value.is_appointment== '1' && (this.serviceInfo.value.request_status=='1' || this.serviceInfo.value.request_status=='2') ? 'Appointments': this.statuses[this.serviceInfo.value.request_status] ;
 }
 showAllstatus() {
  if(this.isEmployee==false){
    return false;
  }
   let dialogRef = this.dialog.open(TechassignmentComponent, {
     panelClass: ['car-dialog-form'],
     width: "900px",
     data: {request_id:this.serviceRequestId,techStatus:true}
   });
 }
 ticketAssignment(id,content) {
  if(this.isEmployee==false){
    return false;
  }
   let dialogRef = this.dialog.open(TechassignmentComponent, {
     panelClass: ['car-dialog-form'],
     width: "500px",
     data: { request_id: id ,editMode:true}
   });

   dialogRef.afterClosed().subscribe(result => {
    if(typeof dialogRef["techAssign"]!='undefined' && dialogRef["techAssign"]==true){
      this.editRequestAllow(content,id);
    }else{
      this.globalService.snackbar("error", 'Please assign technician to enable edit mode');
    }
     this.getTicketAssignSetting();
   });
 }

onTicketAssignment($event){ 
  this.techData= $event;
}

techAssignmentOut($event:any){
  if($event.assignedToSelf || 'onlySelf' in this.techData && this.techData.onlySelf && $event){
    this.userTaskTrack  = 0;
    this.startTracker();
  }
  this.techData = {};
}

 getTicketAssignSetting(){
  let data="user_id="+this.currentCustomer.id+"&user_type="+this.currentCustomer.user_type;
  this.globalService.callAPI(globalConstants.API_SKIP_FOREVER,data).subscribe((skipTicket:any)=>{
    if(skipTicket.result=="1"){
      if(typeof skipTicket.data.skip_forever !='undefined' && skipTicket.data.skip_forever=='1'){
          this.skipTicketAssign=false;
      }else{
          this.skipTicketAssign=true;
      }
    }else{
      this.skipTicketAssign=true;
    }
  })
}
getAllTechJobStatus(){
  if(this.isEmployee==false){
    this.techinianStatusDone=true;
    return false;
  }
  let data = "user_id=" + this.globalService.getCurrentUser()["id"] + "&user_type=" + this.globalService.getCurrentUser()["user_type"] + "&request_id=" + this.serviceRequestId;
    this.globalService.callAPI(globalConstants.API_SERVICE_REQUEST_TRACKERS,data).subscribe((r:any)=>{
      this.techinianStatusDone=true;
      if(r.result==1){
        for(var i in r.data){
          if(r.data[i].work_status!='3'){
            this.techinianStatusDone=false;
          }
        }
      }
    })
  }
  taskTrackingApi(option,init?:any,forceStop=null){
    let data = 'user_id=' + this.currentCustomer.id + '&user_type=' + this.currentCustomer.user_type + '&request_id=' + this.serviceRequestId + '&tracker=' + btoa(option);
    if(forceStop==2 || forceStop==3){
      data +="&force_action="+forceStop;
    }
    this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_TRACK_SERVICE_REQUEST,data).subscribe((track:any)=>{
      if(track.result==1){
        this.userTaskTrack=option;
        if(init=='reinit'){
          this.reInit();
        }
        // if(option == 1){
        //   console.log('timer resumed',option);
        //   if(!this.isStartTimeAcquaired){
        //     this.isStartTimeAcquaired = true;
        //     setTimeout(()=>{
        //       this.empTimer.start();
        //     },1000)
        //   }else{
        //     this.empTimer.resume();
        //   }
        // }else {
        //   console.log('timer stopped',option);
        //   if(this.empTimer) this.empTimer.stop();
        // }
        this.globalService.snackbar("success", track.message);
      }else{
        if(track.code==388){
          let conf = 'Are you sure to want Pause/Complete other service request timer?';
          this.globalService.confirmDialog('taskTrackConfirm', conf).subscribe((res) => { 
            if(res){
              this.taskTrackingApi(option,init,res);
            }  
          }); 
        }else{
          this.globalService.snackbar("error", track.message);
        }
      }
    })
  }
  startTracker() {
    if(this.serviceDetail.timer_control_status == '0'){
      if(localStorage.getItem('bypassPopup-'+this.serviceRequestId) == '1') return false;
      if (this.isEmployee && this.taskTrack && (this.userTaskTrack == 0 || this.userTaskTrack == 2)) {
        let dialogRef = this.dialog.open(NotifyjobComponent, {
          panelClass: ['car-dialogbox'],
          width: "300px",
          data: { start: true,taskTrack : this.taskTrack , userTaskTrack :this.userTaskTrack , request_id : this.serviceInfo.value.request_id, id : this.serviceRequestId }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result == 1) {
            this.taskTrackingApi(1,'reinit');
          }else{
            localStorage.setItem('bypassPopup-'+this.serviceRequestId,'1');
          }
        });
      }
    }
  }
  checkDeActive() {
    if (this.serviceDetail.timer_control_status == '0') {
    if(localStorage.getItem('bypassPopup-'+this.serviceRequestId) == '1') return true;
    if (this.isEmployee && (this.userTaskTrack == 1 || this.userTaskTrack == 2)) {
      let dialogRef = this.dialog.open(NotifyjobComponent, {
        panelClass: ['car-dialogbox'],
        width: "300px",
        data: { start: false, taskTrack: this.taskTrack, userTaskTrack: this.userTaskTrack, request_id: this.serviceInfo.value.request_id, id: this.serviceRequestId }
      });
      return dialogRef.afterClosed().map(result => {
        this.dialog.closeAll();
        if (result == 4) {
          return true;
        }else if(result == 1){
          this.taskTrackingApi(result);
          return true;
        }else if(result == 2){
          this.taskTrackingApi(result);
          return true;
        }else if(result == 3){
          this.taskTrackingApi(result);
          return true;
        }
        return false;
      });
    } else {
      return true;
    }
  }else{
    return true;
  }
  }

  printInvoice(){
  
    let url = globalConstants.API_CREATE_ESTIMATE_URL;
    let data={
      "service_request_id":this.serviceInfo.controls.id.value,
      "user_type":this.globalService.getCurrentUser()["user_type"],
      "customer_id":this.serviceInfo.controls.customer_id.value,
      "user_id":this.globalService.getCurrentUser()["id"],
      "car_id":this.serviceInfo.controls.car_id.value,
      "invoke_download":1
    };
    let skipFirst:boolean = true;
    for(let d in data){
      //console.log(d,data[d]);
      if(skipFirst == true){
        url += "?"+d+"="+data[d];
        skipFirst = false;
      }else{
        url += "&"+d+"="+data[d];
      }
    }
    let serviceRequestId = this.serviceInfo.controls.request_id.value;
    this.globalService.downloadFileWithGet(url,serviceRequestId);
   // these code for set the value for complete mark when response going in applicaltion/json  and change the button on top but now no need this code
    // this.serviceInfo.controls.request_status.setValue('6');
    // this.cdr.markForCheck();
  }

  printTechSheet(){
    let url = globalConstants.API_TECHNICIAN_SHEET_URL;
    let data={
      "service_request_id":this.serviceInfo.controls.id.value,
      "user_type":this.globalService.getCurrentUser()["user_type"],
      "customer_id":this.serviceInfo.controls.customer_id.value,
      "user_id":this.globalService.getCurrentUser()["id"],
      "car_id":this.serviceInfo.controls.car_id.value,
      "invoke_download":1
    };
    let skipFirst:boolean = true;
    for(let d in data){
      if(skipFirst == true){
        url += "?"+d+"="+data[d];
        skipFirst = false;
      }else{
        url += "&"+d+"="+data[d];
      }
    }
    let serviceRequestId = this.serviceInfo.controls.request_id.value;
    this.globalService.downloadFileWithGet(url,serviceRequestId);
   // these code for set the value for complete mark when response going in applicaltion/json  and change the button on top but now no need this code
    // this.serviceInfo.controls.request_status.setValue('6');
    // this.cdr.markForCheck();
  }
  
  submitService(rid, total, taxRate, isTaxIncluded, override?: any) {
    var url = globalConstants.API_GENERATE_INVOICE_URL;
    let userId = this.globalService.getCurrentUser()["id"];
    let data = {
      "service_request_id": this.serviceRequestId,
      "user_type": "User",
      "user_id": userId,
      "is_tax_included": isTaxIncluded,
      "tax_rate": taxRate,
      "total": total
    };
    let skipFirst: boolean = true;
    for (let d in data) {
      if (skipFirst == true) {
        url += "?" + d + "=" + data[d];
        skipFirst = false;
      } else {
        url += "&" + d + "=" + data[d];
      }
    }
    if (override == true) {
      url += "&force_override=1";
    }
    this.globalService.getApiCall(url).subscribe((resp) => {
      if (resp.result == "1") {
        this.globalService.snackbar("success", resp.message);
        this.serviceInfo.controls.request_status.setValue('6');
        // this.milesOut();
        this.cdr.markForCheck();
      } else {
        if (resp.code == "393") {
          this.handelConflict(rid, total, taxRate, isTaxIncluded)
        } else {
          this.globalService.snackbar("error", resp.message);
        }
      }
    });
  }
  handelConflict(rid, total, taxRate, isTaxIncluded) {
    let note = 'Are you sure the technician is done with this service and you want to mark the service request as complete?';
    this.globalService.confirmDialog("yes", note).subscribe((res) => {
      let r: any = res;
      if (r == 'yes') {
        this.submitService(rid, total, taxRate, isTaxIncluded, true);
      }
    });
  }
  milesOut(options){
    let existingReminderObj ={
      standardMilage:this.serviceDetail.standard_mileage,
      standardDays:this.serviceDetail.standard_days,
      existingReminder: this.serviceDetail.existing_reminder
   };
   let pvtReviewsSettingsObj = {
    reviewNotes:this.serviceDetail.pvt_reviews_settings.review_notes,
    reviewUrl:this.serviceDetail.pvt_reviews_settings.review_url,
    reviewNotesIncluded:this.serviceDetail.pvt_reviews_settings.review_notes_included,
    reviewUrlIncluded:this.serviceDetail.pvt_reviews_settings.review_url_included,
   };

   let extPaymentSettingsObj = {
    paymentNotes:this.serviceDetail.ext_payment_settings.payment_notes,
    paymentUrl:this.serviceDetail.ext_payment_settings.payment_url,
    paymentNotesIncluded:this.serviceDetail.ext_payment_settings.payment_notes_included,
    paymentUrlIncluded:this.serviceDetail.ext_payment_settings.payment_url_included,
   };
   let permissionObj ={
    privateReview: this.serviceDetail.private_review,
    externalPayment: this.serviceDetail.external_payment,
    dfsReminders: this.serviceDetail.dfs_reminders,
  };
   let dialogRef = this.dialog.open(MilesoutComponent, {
    panelClass: ['car-dialog-form','milesout-panel'],
      data: {
        returnData:false,
        requestId: this.serviceRequestId, 
        miles:this.serviceInfo.value.present_mileage,
        option:options,customer_id: this.customer_id, 
        car_id : this.serviceInfo.value.car_id,
        list:this.serviceInfo.value.service_request_list,
        labor_rate:this.serviceInfo.value.labor_rate,
        existingReminderObj: existingReminderObj,
        pvtReviewsSettingsObj:pvtReviewsSettingsObj,
        extPaymentSettingsObj:extPaymentSettingsObj,
        isWalkinCustomer:this.serviceDetail.is_walkin_customer,
        isWalkinVehicle:this.serviceDetail.is_walkin_customer_vehicle,
        permissionObj:permissionObj
      }
  });
  dialogRef.afterClosed().subscribe(result => {
    if(typeof result!='undefined' && result==1 ){
      this.serviceInfo.controls.present_mileage_out.setValue(dialogRef["miles_out"]);
      this.serviceInfo.controls.request_status.setValue('6');
      this.reInit();
      this.cdr.markForCheck();
      this.openPaymentDialog();
    }
  })
 }
  copyText() {
    var text = this.serviceInfo.value.vin;
    this.globalService.txtCopy(text);
  }
  detailTab(type) {
    let dialogRef = this.dialog.open(DetailTabComponent, {
      panelClass: 'car-dialog-form',
      width: '800px',
      data: { requestId: this.serviceRequestId, customer_id: this.customer_id, tab: type ,car_id : this.serviceInfo.value.car_id }
    });
    dialogRef.afterClosed().subscribe((r: any) => {
      if(r == true){
        this.updateCustomerVehicleData();
      }
    });
  }

  updateCustomerVehicleData() {
    let content = "user_id=" + this.globalService.getCurrentUser()["id"] + "&user_type=" + this.globalService.getCurrentUser()["user_type"] + "&service_request_id=" + this.serviceRequestId;
    this.subscriptionDataForAPI =  this.globalService.callAPI(globalConstants.API_GET_SERVICE_REQUEST_VIEW_URL, content).subscribe((response:any) => {
      if (response.result == 1) {
        var data = response.data;
        this.serviceDetail= response.data;
        this.serviceInfo.controls.address1.setValue(data.address1)
        this.serviceInfo.controls.address2.setValue(data.address2)
        this.serviceInfo.controls.business_name.setValue(data.business_name)
        this.serviceInfo.controls.business_phone.setValue(data.business_phone)
        this.serviceInfo.controls.city.setValue(data.city)
        this.serviceInfo.controls.company_name.setValue(data.company_name)
        this.serviceInfo.controls.country.setValue(data.country)
        this.serviceInfo.controls.email.setValue(data.email);
        this.serviceInfo.controls.first_name.setValue(data.first_name)
        this.serviceInfo.controls.home_phone.setValue(data.home_phone)
        this.serviceInfo.controls.last_name.setValue(data.last_name)
        this.serviceInfo.controls.license_plate.setValue(data.license_plate)
        this.serviceInfo.controls.make.setValue(data.make)
        this.serviceInfo.controls.middle_name.setValue(data.middle_name)
        this.serviceInfo.controls.mobile_phone.setValue(data.mobile_phone)
        this.serviceInfo.controls.present_mileage.setValue(data.present_mileage);
        this.serviceInfo.controls.state.setValue(data.state)
        this.serviceInfo.controls.vin.setValue(data.vin)
      }
    });
  }
  stockInHand(part){
    let q=globalFunctions.hasDecimal(part.stock_quantity,true)+' remaining in stock';
    let ouq=globalFunctions.hasDecimal(part.quantity_overused,true);
    if(parseFloat(ouq)){
      q+=', '+ouq+' short';
    }
    return q;
  }
  goBack(){
    this.location.back();
    // this.router.navigate(['shops/list-service-requests']);
  }
  /** add to stock order que*/
// addStockOrder(recommendation,parts){
//   parts.id=parts.part_id;
//   let dialogRef = this.dialog.open(StockorderpopupComponent, {
//     panelClass: ['car-dialog-form','ticket-dialog'],
//     width: '800px',
//     data: {newAllowed:false,recom:recommendation,request_id:this.serviceInfo.value.request_id,requestId: this.serviceRequestId,part:parts}
//   });
// }
releaseLock(){
  if(this.serviceRequestId){
    let data={request_id:this.serviceRequestId,lock_status:0,force_unlock:1};
    this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_MODIFY_RECOM_LOCK,data).subscribe((res:any)=>{
     if (res.result == 1) {
       this.globalService.snackbar("success", res.message);
       /*this.recomLock.is_locked = 0;
       this.reInit();*/
       this.router.navigateByUrl('shops/service-inspection-report/'+ this.serviceRequestId);
     }else{
      this.globalService.snackbar("error", res.message);
     }
    },(error)=>{});
   }
}
isAllRejected(){
  let requestList=this.serviceInfo.value.service_request_list;
  let isAnyRejected=false;
  let isAnyRemain=false;
  for(let i in requestList){
      let servicerequestChild=requestList[i].children;
      for(let j in servicerequestChild){
          let recommendation=servicerequestChild[j].recommendations;
          for(let k in recommendation){
              if(recommendation[k].approval_status==2){
                  isAnyRejected=true;
              }else{
                  isAnyRemain=true;
                  break;
              }
          }
          if(isAnyRemain){
            break;
          }
          let level3=servicerequestChild[j].children;
          for(let k in level3){
              let recomand3=level3[k].recommendations;
            for (let m in recomand3) {
              if (recomand3[m].service_request_list_id == '158') {

              } else if (recomand3[m].approval_status == 2) {
                isAnyRejected = true;
              } else {
                isAnyRemain = true;
                break;
              }
            }
          }
          if(isAnyRemain){
            break;
          }
      }
      if(isAnyRemain){
        break;
      }
    }
    return isAnyRemain;
}
/** copy recommendation data from jobs */
copyJob(e,data,l1,jl3?:any){
  e.preventDefault();
  let jobId='';
  let name='';
  let v:any=data;
  let l2:any="",l3="";
  if(typeof jl3!='undefined'){
    v=jl3;
    l2=data.name;
    l3=v.name;
    name=l1.name+' / '+data.name+' / '+v.name;
    jobId=v.recommendations[0].service_request_list_id;
  }else{
    name= l1.name+' / '+ data.name;
    l2=v.name;
    jobId=v.recommendations[0].service_request_list_id;
  }
  let vehicleInfo = {
    year: this.serviceDetail.year,
    make: this.serviceDetail.make,
    model: this.serviceDetail.model,
    sub_model: this.serviceDetail.sub_model
  };
  let cbData={id:jobId,name:name,l1:l1.name,l2:l2,l3:l3,recomm:v.recommendations,vehicleInfo};
  this.globalService.copyJob('set',cbData);
}

isCopyEbnable(obj){
  if(typeof obj.children =='undefined'){
    return true;
  }else if(typeof obj.children !='undefined' && obj.children.length==0){
    return true;
  }
  return false;
}
/** return true/false depend upon the presencse of previous status */
  isUsePrevious() {
    let requestList = this.serviceInfo.value.service_request_list;
    let isAnyRejected = false;
    let isAnyAccepted = false;
    for (let i in requestList) {
      let servicerequestChild = requestList[i].children;
      for (let j in servicerequestChild) {
        let recommendation = servicerequestChild[j].recommendations;
        for (let k in recommendation) {
          if (recommendation[k].old_approval_status == 2) {
            isAnyRejected = true;
          } else if (recommendation[k].old_approval_status == 1) {
            isAnyAccepted = true;
            break;
          }
        }
        if (isAnyAccepted || isAnyRejected) {
          break;
        }
        let level3 = servicerequestChild[j].children;
        for (let k in level3) {
          let recomand3 = level3[k].recommendations;
          for (let m in recomand3) {
            if (recomand3[m].service_request_list_id == '158') {

            } else if (recomand3[m].old_approval_status == 2) {
              isAnyRejected = true;
            } else if (recomand3[m].old_approval_status == 1) {
              isAnyAccepted = true;
              break;
            }
          }
        }
        if (isAnyAccepted || isAnyRejected) {
          break;
        }
      }
      if (isAnyAccepted || isAnyRejected) {
        break;
      }
    }
    return ((isAnyAccepted || isAnyRejected) ? true : false);
  }
  /** confirmation of applying previous jobs status */
  usePrevious() {
    var conf = "Are you sure?";
    this.globalService.confirmDialog('applyPrevious', conf).subscribe((r) => {
      let res: any = r;
      if (res == 'applyPrevious') {
        this.applyPrevious();
      }
    });
  }
  /** apply previous status if any on current jobs */
  applyPrevious() {
    let data = { user_id: this.globalService.getCurrentUser()["id"], user_type: this.globalService.getCurrentUser()["user_type"], request_id: this.serviceRequestId, access_timestamp: this.access_timestamp };
    this.globalService.callAPI(globalConstants.API_USE_PREVIOUS_STATUS, data).subscribe((r:any) => {
      if (r.result == 1) {
        this.access_timestamp = r.access_timestamp;
        this.subTotal = this.parseFloats(r.sub_total);
        this.totalTax = this.parseFloats(r.tax_total);
        this.finalShowAbleAmount = this.parseFloats(r.grand_total);
        this.balance=(parseFloat(this.finalShowAbleAmount) - parseFloat(this.serviceInfo.value.total_paid));
        this.calculateCCFee({checked: this.serviceInfo.controls.cc_fee_included.value == '1'});
        this.serviceInfo.controls['supplies_charges'].setValue(r.supplies_charges);
        this.globalService.snackbar("success", r.message);
        this.reInit();
        // call finalUpdateNotifyToAdmin in case if apply changes is not done.
        setTimeout(()=>{
          this.applyChanges = true;
          this.finalUpdateToNotifyAdmin(this.serviceRequestId,2);
        }, 1500);
      } else {
        this.globalService.snackbar("error", r.message);
      }
    }, (error) => { })
  }
  updateWaitingForPartStatus(value){
    this.showLabelsDropdown = false;
    let checked: any = value.checked == true ? 1:0;
    let data={request_id:this.serviceRequestId,user_id:this.globalService.getCurrentUser()["id"],user_type:this.globalService.getCurrentUser()["user_type"],parts_awaited:checked}
    this.globalService.callAPI(globalConstants.API_UPDATE_PARTS_WAITING_STATUS,data).subscribe((res:any)=>{
      if(res.result==1){
        if(checked == 1){
         this.preSelectLabel.push('WAITING_FOR_PARTS');
         this.showLabelsDropdown = true;
      }else{
        this.removeCustomLabelInPreSelectLabel('WAITING_FOR_PARTS');
        this.showLabelsDropdown = true;
      } 
        this.globalService.snackbar("success", res.message);
      }else{
        this.showLabelsDropdown = true;
        this.globalService.snackbar("error", res.message);
      }
    },(error)=>{})
  }
  updateAccountVerifyStatus(value){
    this.showLabelsDropdown = false;
    let checked: any = value.checked == true ? 1:0;
    let data={request_id:this.serviceRequestId,user_id:this.globalService.getCurrentUser()["id"],user_type:this.globalService.getCurrentUser()["user_type"],account_verify:checked}
    this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_UPDATE_ACCOUNT_VERIFY_STATUS,data).subscribe((res:any)=>{
      if(res.result==1){
        if(checked == 1){
          this.preSelectLabel.push('ACCOUNTANT_VERIFIED');
         this.showLabelsDropdown = true;
      }else{
        this.removeCustomLabelInPreSelectLabel('ACCOUNTANT_VERIFIED');
        this.showLabelsDropdown = true;
      }
        this.globalService.snackbar("success", res.message);
      }else{
        this.showLabelsDropdown = true;
        this.globalService.snackbar("error", res.message);
      }
    },(error)=>{})
  }
  /* FIND ALL SERVICE REQUEST APPROVAL */
  serviceReqApproval(){
    var requestOprs = "user_id=" + this.globalService.getCurrentUser()["id"] + "&user_type=" + this.globalService.getCurrentUser()["user_type"] + "&request_id=" + this.serviceRequestId + "&shop_id=" + this.globalService.getCurrentUser()["shop_id"];
    this.globalService.callAPI(globalConstants.API_GET_ALL_SERVICE_REQUEST_APPROVAL, requestOprs).subscribe((response:any) => {
        if (response.result == '1') {
            if (response.data.length == undefined || response.data.length == 'undefined') {
                this.requestApprovalDetails[0] = response.data;
            } else {
                this.requestApprovalDetails    = response.data;
            }
        } else {
            //this.preAuthorization   = false;
        }
    });
  }
  /* END */
  ngOnDestroy(){
    this.globalService.srnoteListId(false);
    localStorage.removeItem('bypassPopup-'+this.serviceRequestId);
    this.subscriptionDataForAPI.unsubscribe();
  }
  /** open service request notes list */
  srnoteList(){
    this.globalService.setServiceNoteOpen()
  }
  messages() {
    let dialogRef = this.dialog.open(MessagelistComponent, {
      panelClass: ['car-dialog-form', 'message-dialog-list'],
      width: '800px',
      data: { request_id: this.serviceInfo.value.request_id, requestId: this.serviceRequestId }
    });
  }
  quickQuoteView() {
    let qq=this.qqDetail
    let dialogRef = this.dialog.open(QqviewComponent, {
      panelClass: ['quickqouotedialog', 'quickqouotview'],
      width: "100%",
      data: { id: qq.quote_id }
    });
  }

  emissionInspection(): void {
    let dialogRef = this.dialog.open(EmissionsInspectionComponent, {
      panelClass: 'emissionInspection-dialog',
      width: "1100px",
      data: { service_request_id: this.serviceRequestId,status:this.serviceInfo.value.request_status }
    });
  }
   /**
     * @createdBy Vikas Kumar
     * @trackID <:CAR-1048>
     * @created 27-04-2020
     */
  priceHide() {
    let isShow=this.globalService.getAcl('display_part_cost','views');
    if(isShow){
      if(this.hidePrices){
        this.hidePrices=false
      }else{
        this.hidePrices=true
      }
    }
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }
  
  /**
   * Function to apply discount to grand total and balance.
   * @param discount - The discount amount to be deducted.
   * @createdBy Shashank Thakur
   * @trackID <:CAR-1571>
   * @created 10-08-2020
   */
  applyDiscount(discount:number){
    this.serviceDetail.discounts.total_discounted_amount = discount;
    let tmpSubTotal = parseFloat(this.subTotal);
    let tmpSC = ((this.serviceInfo.value.is_supplies_charges_included == '1') ? parseFloat(this.serviceInfo.value.supplies_charges) : 0.0);
    let tmpTax = ((this.serviceInfo.value.is_tax_included == '1') ? parseFloat(this.totalTax) : 0.0);
    let totalBeforeDiscount = tmpSubTotal + tmpSC + tmpTax;
    if(totalBeforeDiscount > 0){
      this.finalShowAbleAmount = this.parseFloats(totalBeforeDiscount - discount);
      if(parseFloat(this.finalShowAbleAmount) < 0){
        this.finalShowAbleAmount = this.parseFloats('0');
      }
      
      this.balance = parseFloat(this.finalShowAbleAmount) - parseFloat(this.serviceInfo.value.total_paid);
      this.calculateCCFee({checked: this.serviceInfo.controls.cc_fee_included.value == '1'});
      // if(parseFloat(this.balance) < 0){
      //   this.balance = this.parseFloats('0');
      // }
    }
  }
  
  /**
   * Function to remove a specific amount from totals. 
   * To be used if above function doesn't work as expected
   * @param discount - amount to be subtracted from totals.
   * @createdBy Shashank Thakur
   * @trackID <:CAR-1571>
   * @created 10-08-2020
   */
  removeDiscount(discount:number){
    // this.subTotal = this.parseFloats(parseFloat(this.subTotal) + discount);
    this.finalShowAbleAmount = this.parseFloats(parseFloat(this.finalShowAbleAmount) + discount);
    this.balance = this.balance + discount;
    this.calculateCCFee({checked: this.serviceInfo.controls.cc_fee_included.value == '1'});
  }
  
  /**
   * Function to handle onCouponAdded event of quick-actions component
   * @param coupon - Code of coupon to be added to current service request.
   * @createdBy Shashank Thakur
   * @trackID <:CAR-1571>
   * @created 10-08-2020
   */
  onCouponAdded(coupon:string){
    // console.log('added by popup = ', coupon);
    // call add coupon method of redeem coupon component.
    let body:any = {
      user_type:this.globalService.getCurrentUser()["user_type"],
      user_id:this.globalService.getCurrentUser()["id"],
      request_id:this.serviceRequestId,
      coupon_code:coupon
    }
    this.viewRequestCoupon.addCouponAPI(body);
  }
  
  /**
   * Function to set all the conditions for initializing redeem coupon component.
   */
  setCouponRedeemConditions(){
    this.couponRedeemConditions.push(this.globalService.isSubscribe(globalConstants.SUBSCRIPTION_DISCOUNTS_FEATURE));
  }
  
  // Functions added to match the function in fill recommendation to avoid errors.
  addCouponControls(code:string, opr:number){}
  removeCouponControls(){}
  postSaveAndStay(type){}
  
  openServiceHistoryPopup(){​
    // Material Popup Dialog
    
    // let dialogRef = this.dialog.open(ServiceHistoryComponent, {​
    //   panelClass: 'car-dialog-form',
    //   width:'95%',
    //   data: {​ isPopup: true, car_id:this.serviceDetail.car_id, service_data: this.serviceDetail }​
    // }​);
    // dialogRef.afterClosed().subscribe(result => {​
    //   console.log('history popup closed = ', result);
    // }​);
    let info:any = {
      id:'service-history-'+this.serviceDetail.car_id,
      name: 'Service History View',
      module: 'Service Request'
    }
    let data:any = {​ isPopup: true, carId:this.serviceDetail.car_id, serviceInfo: this.serviceDetail, id: 'service-history-'+this.serviceDetail.car_id };
    this.globalService.openMinimizablePopup(ServiceHistoryComponent, info, data);
    // let isModalPresent:ModalInfo = this.globalService.getBootstrapModalInfo('service-history-'+this.serviceDetail.car_id);
    // let initialState:any;
    // if(!isModalPresent){
    //   initialState = {​ isPopup: true, carId:this.serviceDetail.car_id, serviceInfo: this.serviceDetail, id: 'service-history-'+this.serviceDetail.car_id };
    //   this.bsModalRef = this.modalService.show(ServiceHistoryComponent, {initialState});
    //   this.globalService.setBootstrapModalRef(initialState.id, this.bsModalRef);
    //   this.globalService.setBootstrapModalInfo(initialState.id, {component: ServiceHistoryComponent, data: initialState, name: 'Service History View', id: initialState.id, module:'Service Request'});
    // }
    // else{
    //   initialState = isModalPresent.data;
    //   this.bsModalRef = this.modalService.show(isModalPresent.component, {initialState});
    // }
    // for(let x in initialState){
    //   this.bsModalRef.content[x] = initialState[x];
    // }
  }​

  askForServiceCompletion(markComplete:boolean = false){
    if(this.isAllowed('views','mark_service_complete')){
      if (markComplete) {
        this.generateInvoice(this.serviceInfo.value.request_id,this.serviceInfo.value.grand_total,this.serviceInfo.value.tax_rate,this.serviceInfo.value.is_tax_included);
      }else{
        this.router.navigate(['shops/list-service-requests']);
      }
      // let askToComplete = "Do you want to complete the service request ?";
      // this.globalService.confirmDialog('mark_complete', askToComplete).subscribe((r) => {
      //   let res: any = r;
      // });
    }else{
      this.router.navigate(['shops/list-service-requests']);
    }
  }

  openPaymentDialog() {
    let id = this.serviceRequestId;
    let accessLimit = this.globalService.getAcl('payment');
    if(accessLimit['addNew'] == 1){
      let toAsk = "Do you want to add payments to this service request ?";
      this.globalService.confirmDialog('open_pay',toAsk, 300, {serviceDetail: this.serviceInfo, serviceRequestId: this.serviceRequestId}).subscribe((r:any) => {
        let res:any = r;
        // console.log('res = ', res);
        if(res && res.downloadInvoice){
          this.getInvoicePdf();
        }
        if(res == 'open_pay' || (res && res.id == 'open_pay')){
          let dialogRef = this.dialog.open(PaymentDialogComponent, {
            panelClass: ['payment-dialog'],
            data: { request_id: id, accessLimit: accessLimit,fullRequestId: this.serviceDetail.request_id }
          });
          dialogRef.afterClosed().subscribe(result => {
            this.reInit();
            this.cdr.markForCheck();
            localStorage.removeItem('fillRecommendationPaymentDialog-'+this.serviceRequestId);
          });
        }
        // else if(res && res.id == 'open_pay' && res.downloadInvoice){
        //   this.getInvoicePdf();
        // }
      });
    }
  }

  // Add By Tafzeel //
   getAllLabels(){
    let content = "user_id="+this.globalService.getCurrentUser()["id"];
    this.globalService.callAPI(globalConstants.API_GET_SERVICE_REQUEST_LABELS_MASTER, content).subscribe((res:any)=> {
      if(res.result == 1){
        this.allLabels = res.data;
      }
    },(error)=>{});
  }

  selectedLabel(label) {
    if (label == 'OTL' || label == 'WAITING_FOR_PARTS' || label == 'ACCOUNTANT_VERIFIED') {
      this.updateCustomLabel(true, label);
    } else {
      let label_id: number = label;
      this.preSelectLabel.push(label);
      this.globalService.callAPI(globalConstants.API_UPDATE_SERVICE_REQUEST_LABELS, "request_id=" + this.serviceRequestId + "&user_id=" + this.globalService.getCurrentUser()["id"] + "&label_id=" + label_id + "&user_type=User").subscribe( (response) => {
      })
    }
  }

  removeLabel(label) {
    if (label == 'OTL' || label == 'WAITING_FOR_PARTS' || label== 'ACCOUNTANT_VERIFIED') {
       this.updateCustomLabel(false,label);
    } else {
      let label_id: number = label;
      this.removeCustomLabelInPreSelectLabel(label);
      this.globalService.callAPI(globalConstants.API_DELETE_SERVICE_REQUEST_LABEL, "request_id=" + this.serviceRequestId + "&user_id=" + this.globalService.getCurrentUser()["id"] + "&label_id=" + label_id + "&user_type=User").subscribe( (response)=> {
      })
    }
  }

  updateCustomLabel(checkboxStatus:any,labelType){
    let obj = {
      checked: checkboxStatus
    };
    this.showLabelsDropdown = checkboxStatus;
    if (labelType == 'OTL') {
      this.onTheLotCheckbox['checked']= checkboxStatus;
      this.showLabelsDropdown = true;
      this.updateOnTheLotStatus(obj);
    }else if(labelType == 'WAITING_FOR_PARTS'){
      this.waitingForPartsCheckbox['checked']= checkboxStatus;
      this.showLabelsDropdown = true;
      this.updateWaitingForPartStatus(obj);
    }else if(labelType == 'ACCOUNTANT_VERIFIED'){
      this.accountantVerificationCheckbox['checked']= checkboxStatus;
      this.showLabelsDropdown = true;
      this.updateAccountVerifyStatus(obj);
    }
  }

  removeCustomLabelInPreSelectLabel(labelType:any){
    let i = this.preSelectLabel.findIndex(label => label == labelType);
    if (i >= 0) {
      this.preSelectLabel.splice(i, 1);
    }
  }
  manageLabels(){
    let dialogRef = this.dialog.open(AddLabelDialogComponent, {
        panelClass: 'partstech-order-dialog',
        width: '800px',
        data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
        this.getAllLabels();
    });
}

  autoAssign(forcAction=null) {
    let conf = 'Are you sure want to assign and continue?';
    this.globalService.confirmDialog('autoAssign', conf).subscribe((r) => {
      let res: any = r;
      if (res == 'autoAssign') {
        this.autoAssignWithoutConfirm();
      }
    });    
  }

  autoAssignWithoutConfirm(forcAction=null,stopTimer=null,conflictAction=null){
    let data = { assign_totech: 1, emp_id: this.currentCustomer.id, request_id: this.serviceRequestId, user_id: this.currentCustomer.id, user_type: this.currentCustomer.user_type,force_action:forcAction?forcAction:0 };
    if(stopTimer){
      Object.assign(data,{stop_running_timer:stopTimer});
    }
    if(conflictAction > 0){
      Object.assign(data,{adding_conflict:conflictAction});
    }
    this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_ASSIGN_SERVICE_REQUEST, data).subscribe((tech:any) => {
        if (tech.result == 1 || tech.code=='400') {    
          this.userTaskTrack  = 0;
          if(this.isReportGenerate){
            this.taskTrack = this.isReportGenerate;
            this.taskTrackingApi(1);
          }else{
            this.taskTrackingApi(1); 
          }  
        }else if([730,731,732,734,740].includes(parseInt(tech.code))){
          let code:any = tech.code;
          this.globalService.confirmDialog('techAssign', tech.message).subscribe((resp:any) => {
            let fStatus = {730:1,731:2,732:3,740:4,734:5};
            if(resp=='techAssign'){          
              if([1,2].includes(fStatus[Number(code)])){
                conflictAction = fStatus[Number(code)];
              }          
              this.autoAssignWithoutConfirm(fStatus[Number(code)],stopTimer,conflictAction);
            }
          });
        }else{
          if(tech.code==388){
            let conf = 'Are you sure to want Pause/Complete other service request timer?';
            this.globalService.confirmDialog('taskTrackConfirm', conf).subscribe((res:any) => { 
              if(res){
                this.autoAssignWithoutConfirm(null,res);
              }  
            }); 
          }else{
            this.globalService.snackbar("error", tech.message);
          }  
        }  
    }, (error) => {

    })
  }

  slefTicketAssignment() {    
    /*let dialogRef = this.dialog.open(TechassignmentComponent, {
       panelClass: ['car-dialog-form'],      
       data: { request_id:this.serviceRequestId,data:this.serviceDetail,onlySelf:true}
    });
    dialogRef.afterClosed().subscribe(result => {    
      if(typeof dialogRef["techAssign"]!='undefined' && dialogRef["techAssign"]==true){
          this.userTaskTrack  = 0;
          this.startTracker();
      }
    });*/
    this.techData = { request_id:this.serviceRequestId,data:this.serviceDetail,onlySelf:true};
  }

  OnPaymentAdded(status: string){
    if(status === 'true'){
      this.reInit();
    }
  }

  calculateCCFee(event){    
    let tPaid  = this.serviceDetail.total_paid?parseFloat(this.serviceDetail.total_paid):0;
    if(this.serviceDetail.cc_fee_type == '2'){
      this.totalCCfeeValue  = ((parseFloat(this.finalShowAbleAmount)-tPaid)  * this.serviceDetail.cc_fee)/100;
    }else{
      this.totalCCfeeValue  = this.serviceDetail.cc_fee;
    }
    if(event.checked) {
      this.serviceInfo.controls.cc_fee_included.setValue('1');
    }
    else{
      this.serviceInfo.controls.cc_fee_included.setValue('0');
    }
    this.calculateNetPayable();
  }

  calculateNetPayable(){
    let tPaid  = this.serviceDetail.total_paid?parseFloat(this.serviceDetail.total_paid):0;    
    this.netPayable  = (parseFloat(this.finalShowAbleAmount)-tPaid);
    if(this.serviceInfo.value.cc_fee_included == '1'){    
      this.netPayable  = parseFloat(this.finalShowAbleAmount)-tPaid+parseFloat(this.totalCCfeeValue.toString());
      this.isAddInPayment = true;
    }else{
      this.netPayable  = parseFloat(this.finalShowAbleAmount)-tPaid;
      this.isAddInPayment = false;
    }
  }

  totalNetPayed(amount, ccFee){
    return parseFloat(amount) + parseFloat(ccFee);
  }

   /**
   * function for add the nexpart,partsTech labor time in job level 
   */
  addLaborTimeInRecommendationLevel() {
    var serviceRequestObject = this.serviceInfo.value.service_request_list;
    for (var i = 0; i < serviceRequestObject.length; i++) {
      for (var j = 0; j < serviceRequestObject[i].children.length; j++) {
        if (serviceRequestObject[i].children[j].recommendations[0] != undefined) {
          this.nxtPartAndPartTechLaborTime(serviceRequestObject[i].children[j].recommendations[0]);
        } else {
          if (serviceRequestObject[i].children[j].children != undefined) {
            for (var p = 0; p < serviceRequestObject[i].children[j].children.length; p++) {
              this.nxtPartAndPartTechLaborTime(serviceRequestObject[i].children[j].children[p].recommendations[0]);
            }
          }
        }
      }
    }
  }

  nxtPartAndPartTechLaborTime(recommendation) {
    let nexPartTotalLabor = 0;
    if (recommendation.nexpart_labor.length > 0) {
      for (let a = 0; a < recommendation.nexpart_labor.length; a++) {
        nexPartTotalLabor = nexPartTotalLabor + parseFloat(recommendation.nexpart_labor[a].hours);
      }
    }
    recommendation.nexPartTotalLaborHours = nexPartTotalLabor;

    let partTechTotalLabor = 0;
    if (recommendation.parts.length > 0) {
      for (let j = 0; j < recommendation.parts.length; j++) {
        if (recommendation.parts[j].partstech_cart_item == 1 || recommendation.parts[j].partstech_order_item == 1) {
          partTechTotalLabor = partTechTotalLabor + parseFloat(recommendation.parts[j].partstech_labor_time);
        }
      }
    }
    recommendation.partsTechTotalLaborHours = partTechTotalLabor;
  }

  getPartToolTipTitle(partData: any) {
    if(this.serviceDetail.partstech_labor == 1){
    let tooltipLabor = '';
    if (partData.partstech_cart_item == 1 || partData.partstech_order_item == 1) {
      tooltipLabor = "Labor: " + parseFloat(partData.partstech_labor_time).toFixed(2) + " Hrs";
    } else if (parseFloat(partData.partstech_labor_time) > 0) {
      tooltipLabor= "Labor: " + partData.partstech_labor_time + " Hrs";
    } else if (partData.partstech_labor_time == '' || parseFloat(partData.partstech_labor_time) == 0) {
      tooltipLabor = "Labor: N/A";
    }
     if(this.PTLaborTooltipSettingType !== 0){
         let laborData = "\nNotes: "+ (partData.partstech_labor_notes.length>0? this.setPTLaborNotesAccodingTooltipSetting(partData).join("\n") : 'N/A');
         return tooltipLabor + laborData;
      }else{
        return tooltipLabor;
      }
    }
  }

  setPTLaborNotesAccodingTooltipSetting(partsData) {
    let tooltipOfNotes = [];
    for (let i = 0; i < partsData.partstech_labor_notes.length; i++) {
      if (this.PTLaborTooltipSettingType == 3) {
        tooltipOfNotes.push(partsData.partstech_labor_notes[i].title_and_notes);
      } else if (this.PTLaborTooltipSettingType == 2) {
        tooltipOfNotes.push(partsData.partstech_labor_notes[i].notes);
      } else if (this.PTLaborTooltipSettingType == 1) {
        tooltipOfNotes.push(partsData.partstech_labor_notes[i].title);
      }
    }
    return tooltipOfNotes;
  }

  checkPartsTechLaborSetting() {
    let PTLaborSetting: any = this.serviceDetail.partstech_settings;
    if (PTLaborSetting.chk_pt_labor_job_tooltip == "1" && this.serviceDetail.partstech_labor == 1) {
      if (PTLaborSetting.chk_pt_labor_job_tooltip_notes == '1' && PTLaborSetting.chk_pt_labor_job_tooltip_title == '1') {
        this.PTLaborTooltipSettingType = 3;
      } else if (PTLaborSetting.chk_pt_labor_job_tooltip_notes == '1') {
        this.PTLaborTooltipSettingType = 2;
      } else if (PTLaborSetting.chk_pt_labor_job_tooltip_title == '1') {
        this.PTLaborTooltipSettingType = 1;
      }
    }
  }

  prepareSubJobCategoryList() {
    for (var i = 0; i < this.serviceInfo.value.service_request_list.length; i++) {
      let subJobCategoriesInfo = [];
        for (var j = 0; j < this.serviceInfo.value.service_request_list[i].children.length; j++) {
          if (typeof(this.serviceInfo.value.service_request_list[i].children[j].recommendations[0]) != 'undefined') {
          subJobCategoriesInfo.push(this.serviceInfo.value.service_request_list[i].children[j].name);
        } else {
          if (typeof (this.serviceInfo.value.service_request_list[i].children[j].children) != 'undefined') {
            for (var p = 0; p < this.serviceInfo.value.service_request_list[i].children[j].children.length; p++) {
              subJobCategoriesInfo.push(this.serviceInfo.value.service_request_list[i].children[j].name +" -> "+ this.serviceInfo.value.service_request_list[i].children[j].children[p].name);
            }
          }
        }  
      }
        this.serviceInfo.value.service_request_list[i]['subJobCategories']= subJobCategoriesInfo;
    }
  }
  //click function to open Quick Action menu 
  onQuickActionIconClick() {
    if (this.quickaction) {
      this.quickaction.showOptions = true; // Directly manipulate the child component property
    }
  }
}

@Component({
    templateUrl: 'change-disclaimer.html',
    styleUrls: ['./change-disclaimer.scss'],
    encapsulation: ViewEncapsulation.None
})
export class changeDisclaimer {
    public dataSources = {data: []};
    public requestId: any;
    public disclaimerText: string;
    public disclaimer_id=0;
    constructor( @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<changeDisclaimer>, private globalService: GlobalService, public dialog: MatDialog) {
        this.dataSources = data.dataSources
        this.requestId = data.requestId
        this.disclaimerText = data.disclaimer;
        this.disclaimer_id = data.disclaimer_id;
        this.globalService.diaLogObj(this.dialogRef);
    }
    applyChange(event, disclaimId) {
        // if (event.source.checked) {
            // console.log("event", event);
            let content = "user_id=" + this.globalService.getCurrentUser()["id"] + "&user_type=" + this.globalService.getCurrentUser()["user_type"] + "&disclaimer_id=" + disclaimId + "&request_id=" + this.requestId;
            this.globalService.callAPI(globalConstants.API_APPLY_DISCLAIMER_TO_REQUEST_URL, content).subscribe((response:any) => {
                if (response.result == 1) {
                    this.disclaimerText = response.data.disclaimer;
                    // console.log("disclaimer", this.disclaimerText)
                    this.dialogRef["response"] = response.data;
                    this.disclaimer_id=disclaimId;
                    ///
                    //  this.callDisclaimers();
                    ///
                } else {
                    this.globalService.snackbar("error", response.message);
                }
            });
        // } else {
            // console.log("event", event.source.checked);
        // }
    }
    callDisclaimers() {
        let content = "user_id=" + this.globalService.getCurrentUser()["id"] + "&user_type=" + this.globalService.getCurrentUser()["user_type"];
        this.globalService.callAPI(globalConstants.API_GET_DISCLAIMERS_LIST_URL, content).subscribe((response:any) => {
            if (response.result == 1) {
                this.dataSources = response.data;
            } else {
                this.globalService.snackbar("error", response.message);
            }
        });
    }
    getLimitedText(txt){
      if(txt && txt.length>51){
        return txt.substr(0, 51)+'...';
      }
      return txt;
    }

   

}

