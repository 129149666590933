import {Component, ElementRef, ViewChild, ViewEncapsulation,OnDestroy} from '@angular/core';
import {Router, ActivatedRoute, ParamMap, NavigationEnd} from '@angular/router';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import {DataSource, SelectionModel} from '@angular/cdk/collections';
import {Http, Headers, Response} from '@angular/http';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {globalFunctions} from "../../../../environments/globalFunctions";
import {globalConstants} from "../../../../environments/globalConstants";
import {GlobalService} from "../../../../environments/global.service";
import {HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {Title} from '@angular/platform-browser';
import {lang} from "../../../../environments/lang-en";
import 'rxjs/add/operator/startWith';
import 'rxjs/add/observable/merge';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import {AppComponent} from '../../../app.component';
import {PaymentDialogComponent} from '../../shopservicerequest/payment-dialog/payment-dialog.component';
import {Subscription} from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
@Component({
  selector: 'app-overduereport',
  templateUrl: './overduereport.component.html',
  styleUrls: ['./overduereport.component.scss']
})
export class OverduereportComponent implements OnDestroy {
  displayedColumns = ['request_id','title', 'customer_name','mobile_phone', 'date_completed', 'total_invoice', 'balance_owed', 'last_payment_date', 'actions'];
  public currentCustomer;
  public schedule_service_option
  public requested_delivery_option
  public estimated_delivery_option
  public more_rows;
  public timeScale1=0;
  public timeScale2=0;
  public timeScale3=0;
  public timeScale4=0;
  public timeScale1Balance;
  public timeScale2Balance;
  public timeScale3Balance;
  public timeScale4Balance;
  public isActive: boolean = true;

  public options = {
    user_id: "",
    user_type: "User",
    car_id: localStorage.getItem("carDetailsVehicleID") || "",
    status: localStorage.getItem("status") || '',
    search_keyword: localStorage.getItem("search_keyword") || "",
    start: parseInt(localStorage.getItem("start")) || 0 ,
    limit: parseInt(localStorage.getItem("limit")) || 10 ,
  }
  public statuses = ['Appointment', 'Pending', 'Processing', 'Approval Awaited', '', '', 'Completed', 'Approval Sent', 'Completed', 'Created', 'Created'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<overDueData> = new MatTableDataSource();
  public record :any =[];
  public subscriptionDataForAPI: Subscription = new Subscription();
  recordsTotal: any;
  
  constructor(private location:Location,private app: AppComponent, public router:Router,private http:Http, public titleService: Title,public globalservice:GlobalService, public dialog: MatDialog){
    this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
    this.options.user_id = this.currentCustomer.id;
  }
  getStatus(statusId) {
    return this.statuses[statusId]
  }
  setTouchViewValue(){
      return this.app.touchViewState;
  }
  formatDate(date) {
    if (!new Date(date) || date == null || date == "0000-00-00 00:00:00" || date == '-') { return "-" }
    return (new Date(date).getMonth()+1) + "/" + new Date(date).getDate() + "/" + new Date(date).getFullYear()
  }
  ngOnInit() {
    var isFree=this.globalservice.isFreeSubscription();
    if(isFree){
        this.router.navigate(["shops/dashboard"]);
        return false;
    }
    this.globalservice.setMetaData("SHOPS","OVERDUE_REPORT");
    this.schedule_service_option = ""
    this.requested_delivery_option = ""
    this.estimated_delivery_option = ""
    this.setDataTable();

    var content = globalFunctions.stringifyFormData(this.options, null);
    var callApi = this.globalservice.callAPI(globalConstants.API_SHOP_OVERDUE_REPORT_URL, content);
    this.subscriptionDataForAPI = callApi.subscribe((data:any)=>{
      var timescaleData= data.data;
        this.timeScale1=timescaleData.timescale_1;
        this.timeScale2=timescaleData.timescale_2;
        this.timeScale3=timescaleData.timescale_3;
        this.timeScale4=timescaleData.timescale_4;

        this.timeScale1Balance=timescaleData.timescale_1_balance;
        this.timeScale2Balance=timescaleData.timescale_2_balance;
        this.timeScale3Balance=timescaleData.timescale_3_balance;
        this.timeScale4Balance=timescaleData.timescale_4_balance;
    });
    setTimeout(()=>{
    let isEnable=this.isAllowed('enable','overdue_reports');
    if(!(isEnable )){
        this.router.navigate(['shops/action/forbidden']);
    }
  },globalConstants.RELOAD_WAIT)
    // this.clearSearch();
  }

  viewPart(getId){
      this.router.navigate(['/customer/view-request/', getId]);
  }
  scrolling(){
      this.globalservice.setScrolling('.car-datatable', 1000, -20);
}
  paginateByLimit(event){
    this.globalservice.setScrolling('body', 600, 0);
    this.options.limit = event.pageSize;
    this.options.start = 0;
    this.scrolling();
    this.setDataTable();
  }
  reinitializeOptions (){
    this.options = {
      user_id: this.currentCustomer.id,
      user_type: "User",
      car_id: localStorage.getItem("carDetailsVehicleID") || "",
      status: localStorage.getItem("status") || '',
      search_keyword: localStorage.getItem("search_keyword") || "",
      start: parseInt(localStorage.getItem("start")) || 0 ,
      limit: parseInt(localStorage.getItem("limit")) || 10 ,
    }
  }
  getTotalRecords(){
    return parseInt(localStorage.getItem('totalRecords'));
  }
  paginate(event,type){
    this.scrolling();
    if(type=="next") {
      this.options.start = this.options.start + this.options.limit;
    } else {
      this.options.start = this.options.start - this.options.limit;
    }
    this.setDataTable();
  }
  firstList() {
    if(localStorage.getItem("first_list") == "true" || this.options.limit >= parseInt(localStorage.getItem("totalRecords")))
    return true;
    else
    return false;
  }
  lastList(){
    if(localStorage.getItem("more_rows") == "true") {
      return !true;
    } else {
      return !false;
    }
  }
  getUpperLimit(currentLastLimit,total) {
    if(currentLastLimit > total) {
      return total;
    } else {
      return currentLastLimit;
    }
  }

  downloadCSV(){
    var content = globalFunctions.stringifyFormData(this.options, null);
    var callApi = this.globalservice.callAPI(globalConstants.API_EXPORT_SERVICE_REQUEST_OVERDUE_REPORT_URL, content);
    this.subscriptionDataForAPI = callApi.subscribe((data:any)=>{
      var reportData=data;
      window.location.href = globalConstants.API_DOWNLOAD_REPORTS_OVERDUE_FILE_URL+"?t=1&p="+reportData.csv_url;
     });
  }
  // Payment Dialog box
  paymentDialog(id): void {
    let accessLimit=this.globalservice.isAllowed(this.app,'payment');
    this.globalservice.setAcl(this.app.roles);
      let dialogRef = this.dialog.open(PaymentDialogComponent, {
          panelClass: ['payment-dialog'],
          // position: {top: '50px'},
          width: "1100px",
          data: {request_id: id,obj:this,accessLimit:accessLimit}
      });

      dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
      });
  }
  viewServiceRequest(id) {
    localStorage.setItem("status",this.options.status.toString());
    localStorage.setItem("search_keyword",this.options.search_keyword);
    localStorage.setItem("start",this.options.start.toString());
    localStorage.setItem("limit",this.options.limit.toString());
    this.router.navigate(['shops/view-service-request/',id]);
   }

   /***Check service request*****/
  isAnyOptionActive(button) {
      if (this.options.search_keyword == "" && this.options.status == "" && this.estimated_delivery_option == "" && this.requested_delivery_option == "" && this.schedule_service_option == "" && this.estimated_delivery_option == "") {
          if (button) {button.color = "primary"}
          return false;
      }
      else {
          if (button) {button.color = "accent"}
          return true;
      }
  }
  editRequest(id) {
    this.router.navigate(['customer/edit_request/',id]);
  }

    /* STATUS COLOR */
    getRowStatusColor(status) {
        var statusArr = ['active-cell', 'warning-cell', 'info-cell', 'success-cell', 'info-cell', 'success-cell', 'success-completed', 'success-cell', 'success-paid', ""];
        return statusArr[status];
    }

    decimalTwoDigit(x) {
        return isNaN(x) ? '0.00' : Number.parseFloat(x).toFixed(2);
    }

    ngAfterViewInit() {
      this.dataSource.paginator = this.paginator;
    }
    
    setDataTable(){
      this.record=[];
      var content = globalFunctions.stringifyFormData(this.options, null);
      var callApi = this.globalservice.callAPI(globalConstants.API_GET_SERVEICE_REQUEST_FOR_OVERDUE_REPORT_URL, content);
       callApi.subscribe((data:any)=>{
        if(data.data!=undefined) {
          localStorage.setItem("totalRecords",data.recordsTotal);
  
          if(data.more_rows == "true") {
            localStorage.setItem("more_rows","true");
          } else {
            localStorage.setItem("more_rows","false");
          }
  
          if(data.start >= 0 && data.start <= 10) {
            localStorage.setItem("first_list","true");
          } else {
            localStorage.setItem("first_list","false");
          }
  
          this.recordsTotal = data.data.length;
         
          if(data.data.length==undefined){      
            this.AddRecord(data.data);
            this.dataSource = new MatTableDataSource(this.record);
            this.dataSource.sort = this.sort;
          } else {
            for(var i=0;i<data.data.length;i++){
              this.AddRecord(data.data[i]);
              this.dataSource = new MatTableDataSource(this.record);
               this.dataSource.sort = this.sort;
            }
          }
          localStorage.setItem("status","");
          localStorage.setItem("search_keyword","");
          localStorage.setItem("start","0");
          localStorage.setItem("limit","10");
        } else {
          this.dataSource = new MatTableDataSource(this.record);
          localStorage.setItem("totalRecords","0");
        }
      })
    }

    AddRecord(record){
      var data = this.createRecordrecord(record);
        this.record.push(data);
    }
    
    /** create record, that show on order queue list */
    createRecordrecord(record) {
      return {
        request_id: record.request_id,
        title: record.title,
        customer_name: record.first_name + " " + record.last_name,
        mobile_phone:record.mobile_phone,
        date_completed:record.completion_date,
        last_payment_date:record.last_payment_date,
        balance_owed:record.balance,
        total_invoice:record.grand_total,
        status:record.status,
        id:record.id,
      };
    }
  isAllowed(action,module?:any){
    if(typeof module!='undefined'){
        return this.globalservice.isAllowed(this.app,module,action);            
    }
    return false;
}

ngOnDestroy(){
  this.subscriptionDataForAPI.unsubscribe();
}
}

export interface overDueData {
  mobile_phone: string;
  customer_name: string;
  date_completed: string;
  last_payment_date: string;
  balance_owed: string;
  request_id: string;
  title: string;
  total_invoice: string;
  id:string;
}

