import { Component, OnInit, ChangeDetectorRef, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { globalConstants } from "../../../../environments/globalConstants"
import { GlobalService } from "../../../../environments/global.service"
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { UsercontactsComponent } from '../../../usercontacts/usercontacts.component';
import {IDatePickerConfig} from 'ng2-date-picker';
import * as moment from 'moment';
@Component({
  selector: 'app-addemployee',
  templateUrl: './addemployee.component.html',
  styleUrls: ['./addemployee.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddemployeeComponent implements OnInit, OnDestroy {
  public addNewEmployee: FormGroup;
  public shopDetails: any;
  public selectedCountry: any;
  public selectedState: any;
  public selectedCity: any;
  public countries: any = [];
  public states: any = [];
  public cities: any = [];
  public disableStates: any;
  public disableCities: any;
  public base64value: any = "";
  public phoneMask: any = globalConstants.phoneMask;
  public submitted: boolean = false;
  public currentLoginUserInfo: any;
  public employeeTypeList: any = [];
  public userInfo: any = '';
  public imgTypes: any = globalConstants.FORMAT_SUPPORTED_FOR_IMAGE;
  public imgSize: any = globalConstants.MAX_ALLOWED_IMAGE_DISPLAYED_TEXT;
  public docType: any = globalConstants.FORMAT_FOR_DOC + ', pdf, ' + this.imgTypes;
  public employeeEmailExistsInfo: any = null;
  public maxDate: any = new Date(new Date());
  public emgContacts: any = [];
  public employeePhoto: any = '';
  public employeeDocs: any = [];
  public employeeLicenseDocs: any = [];
  public timeSlots: any = [];
  public shopTimeLines: any = [];
  public hourSlots: any = globalConstants.HOUR_SLOTS;
  public minSlots: any = globalConstants.MINUTES_SLOTS;
  public zipcodeLabel: any = 'Zip Code';
  public addressPlaceHolder: any = 'Address Line 1 *'; //CAR-262 adding dynamic placeholder
  public subscriptionDataForAPI: Subscription = new Subscription();
  public addressCheckbox : boolean = true;
  public isFromZipCode: boolean = false;
  public datePickerConfig: IDatePickerConfig = {
    format: 'DD/MM/YYYY',
    closeOnSelect: true,
    max:moment(new Date()).format("DD/MM/YYYY")
  };
  public isFutureDate: boolean = false;
  public reSetEmergencyContactList: boolean = false

  constructor(
    private ref: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private router: Router,
    private globalService: GlobalService,
    public dialog: MatDialog
  ) {
    this.globalService.setMetaData("SHOPS", "ADD_NEW_EMPLOYEE");
    setTimeout(() => {
      let isEnable = this.globalService.getAcl('employees', 'addNew');
      if (!(isEnable)) {
        this.router.navigate(['shops/action/forbidden']);
      }
    }, globalConstants.RELOAD_WAIT);

  }

  ngOnInit() {
    this.timeSlots = globalConstants.TIME_SLOTS;
    this.globalService.getSetItems('userAlreadyExist', '');
    this.shopDetails = JSON.parse(localStorage.getItem("shop-details"));
    this.currentLoginUserInfo = this.globalService.getCurrentUser();
    if (this.currentLoginUserInfo) {
      this.addEmployeeFormBuilder();
      this.getCountries();
      let employeeTypeDDPlaceHolder =[{
           id:'',
           name:'Select Type'
      }];
      this.userInfo = "user_id=" + this.currentLoginUserInfo.id + "&user_type=" + this.currentLoginUserInfo.user_type + "&shop_id=" + this.currentLoginUserInfo.shop_id;
      this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_USER_TYPES, this.userInfo).subscribe((data: any) => {
        if (data.result == '1') {
          this.employeeTypeList = employeeTypeDDPlaceHolder.concat(data.data);
        }
      });
      this.getStates(this.shopDetails.country_code);
      this.handleAddress1chkValidation(this.shopDetails.no_customer_address);
    } else {
      this.router.navigate(["/shops/login"]);
    }
    this.addNewEmployee.get("country").valueChanges.subscribe(country_name => {
      if (country_name && Array.isArray(this.countries)) {
        let zRegex = this.countries.find(res => (res.country_id == country_name || res.country_name == country_name));
        if (zRegex && 'zipcode_regex' in zRegex) {
          this.zipcodeLabel = zRegex.zipcode_label;
          this.addNewEmployee.controls.zipcode.setValidators([Validators.required, Validators.pattern(zRegex.zipcode_regex)]);
          this.addNewEmployee.controls.zipcode.updateValueAndValidity();
        }
      }
    });
  }

  addEmployeeFormBuilder(){
    this.addNewEmployee = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(globalConstants.EMAIL_REGEX)]],
      first_name: ['', [Validators.required, Validators.pattern(globalConstants.NAME_REGEX)]],
      middle_name: ['', [Validators.pattern(globalConstants.NAME_REGEX)]],
      last_name: ['', [Validators.required, Validators.pattern(globalConstants.NAME_REGEX)]],
      address_one: ['', Validators.required],
      address_two: [''],
      country: [],
      state: ['',Validators.required],
      city: ['',Validators.required],
      zipcode: ['', [Validators.required, Validators.pattern(globalConstants.DIGIT_ONLY_REGEX)]],
      mobile_phone: ['', [Validators.required, Validators.minLength(10), this.globalService.mobileNumberValidation]],
      dob: ['', [Validators.required]],
      date_of_birth: [''],
      employee_type: ['',[Validators.required]],
      enable_report: [''],
      hours_week: ['', Validators.required],
      hours_wages: ['', Validators.required],
      week_wages: ['', Validators.required],
      user_contacts: [''],
      emp_photo: [''],
      driving_license: [''],
      emp_documents: [''],
      user_type: this.currentLoginUserInfo.user_type,
      user_id: this.currentLoginUserInfo.id,
      job_type: ['0'],
      weekday_timings: this.formBuilder.array([]),
      selectedItems: [false, Validators.required],
      max_hour: ['00'],
      max_min: ['00']
    });
    this.timings.valueChanges.subscribe((time) => {
      this.addNewEmployee.controls.selectedItems.setValue(this.getWeekDaysID(time));
    });
    this.addNewEmployee.get("email").valueChanges.subscribe(email => { 
        let emailRxg =  globalConstants.EMAIL_REGEX;
        if(email == '' || emailRxg.test(email) == false ){
          this.employeeEmailExistsInfo = null;
        }
    })
     this.setEmployeeTimeInForm();
     this.getShopTimeLine();
  
  }


  setEmployeeTimeInForm(){
    for (let i of [0, 1, 2, 3, 4, 5, 6]) {
      this.timings.push(this.formBuilder.group({
        weekdayid: [false],
        start_time: [''],
        close_time: [''],
        is_disable: [false]
      }, { validator: this.compareEndTimerWithStartTime('start_time', 'close_time') }));
      this.timings.controls[i].get('weekdayid').valueChanges.subscribe(res => {
        if (res) {
          this.timings.controls[i].get('start_time').setValidators([Validators.required]);
          this.timings.controls[i].get('close_time').setValidators([Validators.required]);
        } else {
          this.timings.controls[i].get('start_time').setValue("");
          this.timings.controls[i].get('close_time').setValue("");
          this.timings.controls[i].get('start_time').clearValidators();
          this.timings.controls[i].get('close_time').clearValidators();
        }
        this.timings.controls[i].get('start_time').updateValueAndValidity();
        this.timings.controls[i].get('close_time').updateValueAndValidity();
      });
    }
  }

  //add Form array form weekday timer 
  get timings(): FormArray {
    return this.addNewEmployee.get('weekday_timings') as FormArray;
  }

  getWeekDaysID(items) {
    let selectedItems = items.filter((item) => item.weekdayid).map((item) => item.id);
    return selectedItems.length ? selectedItems : null;
  }

  compareEndTimerWithStartTime(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let f = group.controls[from];
      let t = group.controls[to];
      if (f.value && t.value && (f.value > t.value || f.value == t.value)) {
        return {
          dates: "Start Time should be less than End Time"
        };
      }
      return {};
    }
  }

  getShopTimeLine() {
    this.userInfo = "user_id=" + this.currentLoginUserInfo.id + "&user_type=" + this.currentLoginUserInfo.user_type + "&shop_id=" + this.currentLoginUserInfo.shop_id;
    this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_SHOP_TIMELINE_URL, this.userInfo).subscribe((data: any) => {
      if (data.result == '1') {
        this.shopTimeLines = data.data;
        if (Array.isArray(this.shopTimeLines) && this.shopTimeLines.length) {
          this.shopTimeLines.map((data, index) => {
            if (data.is_checked == 0) {
              this.timings.controls[data.weekday_id].get('weekdayid').setValue(false);
              this.timings.controls[data.weekday_id].get('is_disable').setValue(true);
              this.timings.controls[data.weekday_id].get('weekdayid').updateValueAndValidity();
              this.timings.controls[data.weekday_id].get('start_time').setValue('');
              this.timings.controls[data.weekday_id].get('start_time').updateValueAndValidity();
              this.timings.controls[data.weekday_id].get('start_time').disable();
              this.timings.controls[data.weekday_id].get('close_time').setValue('');
              this.timings.controls[data.weekday_id].get('close_time').updateValueAndValidity();
              this.timings.controls[data.weekday_id].get('close_time').disable();
            } else {
              this.timings.controls[data.weekday_id].get('weekdayid').setValue(true);
              this.timings.controls[data.weekday_id].get('start_time').setValue(moment(data.open_time, ["hh:mm:ss"]).format("HH:mm"));
              this.timings.controls[data.weekday_id].get('close_time').setValue(moment(data.close_time, ["hh:mm:ss"]).format("HH:mm"));
            }
          });
        }
      }
    });
  }

  checkEmailAddressExistsOrNot() {
    let email = this.addNewEmployee.controls.email;
    let errorEmail = false;
    if (email.hasError('required') || email.hasError('pattern')) {
      errorEmail = true;
      this.employeeEmailExistsInfo = null;
    }
    var payload = this.userInfo + "&email=" + this.addNewEmployee.value.email + "&mobile_phone=" + this.addNewEmployee.value.mobile_phone;
    this.subscriptionDataForAPI = (!errorEmail) && this.globalService.callAPI(globalConstants.API_CHECK_USER_INFORMATION_URL, payload).subscribe((data: any) => {
      if (data.result == 1) {
        this.employeeEmailExistsInfo = data;
        this.addNewEmployee.get('email').setErrors({"alreadyExit": true});
      } else {
        this.employeeEmailExistsInfo = null;
      }
    })
  }

  goToCustomerInfoPage(email) {
    this.globalService.getSetItems('userAlreadyExist', email);
    this.router.navigateByUrl('shops/employee/list');
  }
  addDOBAndDocumentDataInForm() {
    if (this.addNewEmployee.controls.dob.value) {
      let d = new Date(this.addNewEmployee.controls.dob.value);
      let fDate = (d.getMonth() + 1) + '/' + d.getDate() + '/' + d.getFullYear();
      this.addNewEmployee.controls.date_of_birth.setValue(fDate);
    }

    this.addNewEmployee.controls.user_contacts.setValue(JSON.stringify(this.emgContacts));
    let emp_documents = [];
    if (this.employeeDocs.length) {
      for (var i in this.employeeDocs) {
        emp_documents.push({ name: this.employeeDocs[i].name, fileType: this.employeeDocs[i].type });
      }
    }
    this.addNewEmployee.controls.emp_documents.setValue(JSON.stringify(emp_documents));
    let driving_license = [];
    if (this.employeeLicenseDocs.length) {
      for (var i in this.employeeLicenseDocs) {
        driving_license.push({ name: this.employeeLicenseDocs[i].name, fileType: this.employeeLicenseDocs[i].type });
      }
    }

    this.addNewEmployee.controls.driving_license.setValue(JSON.stringify(driving_license));
  }
  addEmployee(submitBtType: any) {
    if (this.addNewEmployee.valid && !this.isFutureDate) {
      this.addDOBAndDocumentDataInForm();
      //var stringifiedFormData = globalFunctions.stringifyFormData(this.addNewEmployee.value, null);
      var data = new FormData();
      for (let i in this.addNewEmployee.value) {
        if (i == 'weekday_timings' && Array.isArray(this.addNewEmployee.value[i])) {
          let wTimings = this.addNewEmployee.value[i];
          wTimings.map((data, index) => {
            if (!data['weekdayid']) {
              data['start_time'] = "";
              data['close_time'] = "";
            }
            return data['weekdayid'] = index;
          });
          data.append(i, JSON.stringify(wTimings));
        } else {
          data.append(i, this.addNewEmployee.value[i]);
        }
      }
      let maxOverTime = Number((Number(this.addNewEmployee.controls.max_hour.value) * 60) + Number(this.addNewEmployee.controls.max_min.value));
      data.append('max_overtime', maxOverTime.toString());
      this.globalService.formData(globalConstants.API_ADD_SHOP_USER, data).subscribe((data: any) => {
        var resultData = data;
        if (resultData.result == 1) {
          if (submitBtType == 'submit&Close') {
            this.router.navigate(['shops/employee/list']);
          } else if (submitBtType == 'Submit&Add') {
            this.globalService.snackbar('success', resultData.message);
            this.addNewEmployee.reset();
            this.addNewEmployee.controls.employee_type.setValue('');
            this.addNewEmployee.markAllAsTouched();
            this.addNewEmployee.controls.weekday_timings = this.formBuilder.array([])
            this.timings.valueChanges.subscribe((time) => {
              this.addNewEmployee.controls.selectedItems.setValue(this.getWeekDaysID(time));
            });
            this.addNewEmployee.controls.job_type.setValue('0');
            this.setEmployeeTimeInForm();
            this.getShopTimeLine();
            this.addNewEmployee.controls.user_type.setValue(this.currentLoginUserInfo.user_type);
            this.addNewEmployee.controls.user_id.setValue(this.currentLoginUserInfo.id);
            this.addNewEmployee.controls.zipcode.setValue(this.shopDetails.zip);
            let country_name = this.shopDetails.country
            this.reSetEmergencyContactList = true;
            let zRegex = this.countries.find(res => (res.country_id == country_name || res.country_name == country_name));
            if (zRegex && 'zipcode_regex' in zRegex) {
              this.zipcodeLabel = zRegex.zipcode_label;
              this.addNewEmployee.controls.zipcode.setValidators([Validators.required, Validators.pattern(zRegex.zipcode_regex)]);
              this.addNewEmployee.controls.zipcode.updateValueAndValidity();
            }
            this.selectedState = '';
            this.selectedCity = '';
            this.selectedCountry = this.shopDetails.country;
            this.disableCities = true;
            this.getStates(this.shopDetails.country);
            /** Delete Images */
            this.documentDelete('image');
            this.employeeDocs = []
            this.employeeLicenseDocs = [];
            this.handleAddress1chkValidation(this.shopDetails.no_customer_address);
            window.scrollTo(0, 0);
            /** Delete images stop */
          }
        } else {
          this.globalService.snackbar('error', resultData.message);
        }
      });
    } else {
      if(this.addNewEmployee.value.employee_type == ''){
        const control = this.addNewEmployee.get('employee_type');
        control.markAsTouched();
      }
      if(this.addNewEmployee.value.state == ''){
          this.addNewEmployee.get('state').markAsTouched();
      }
      if(this.addNewEmployee.value.city == ''){
        this.addNewEmployee.get('city').markAsTouched();
      }
      this.addNewEmployee.get('dob').markAsTouched();
      this.submitted = true;
      this.globalService.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
    }
  }

  checkIfValid() {
    if (this.addNewEmployee.valid) {
      this.submitted = false;
    }
  }

  enableReport(event) {
    if (event.checked) {
      this.addNewEmployee.controls.enable_report.setValue(1);
    } else {
      this.addNewEmployee.controls.enable_report.setValue(0);
    }
  }

  allowDecimalNumber(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;
    return true;
  }

  disableKey(event) {
    if (event.ctrlKey == true && (event.which == '67' || event.which == '86' || String.fromCharCode(event.which).toLowerCase() == 's')) {
      event.preventDefault();
    }
  }

  handleCountryDropdownChange(countryId) {
    this.getStates(countryId);
    let countryData = this.countries.find(countryInfo => countryId == countryInfo.id);
    this.addNewEmployee.controls.country.setValue(countryData.country_name);
    this.addNewEmployee.controls.state.setValue('');
    this.addNewEmployee.controls.city.setValue('');
    this.selectedState = '';
    this.selectedCity = '';
  }
  handleStateDropdownChange(stateId) {
    if(stateId !== ''){
      this.getCities(stateId);
      let stateData = this.states.find(stateInfo => stateId == stateInfo.id);
      this.addNewEmployee.controls.state.setValue(stateData.state_name);
      this.addNewEmployee.controls.city.setValue('');
      this.selectedCity = 'Select City';
    }else{
      this.addNewEmployee.controls.state.setValue('');
      this.addNewEmployee.controls.city.setValue('');
      this.selectedCity = 'Select City';
    }
  }
  handleCityDropdownChange(cityName) {
    if(cityName !== 'Select City'){
      this.addNewEmployee.controls.city.setValue(cityName);
      this.selectedCity = cityName;
    }else{
      this.addNewEmployee.controls.city.setValue('');
      this.selectedCity = 'Select City';
    }
  }


  getCountries() {
    this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_COUNTRIES_LIST, "opr=1").subscribe((data: any) => {
      this.countries = data.data;
      for (var i in this.countries) {
        this.countries[i].id = this.countries[i].country_id;
        this.countries[i].text = this.countries[i].country_name;
      }
      this.selectedCountry = this.shopDetails.country_code;
      document.getElementById('countryId').click();
      this.addNewEmployee.controls.country.setValue(this.shopDetails.country);
      this.addNewEmployee.controls.zipcode.setValue(this.shopDetails.zip);
    });
  }

  /**
   *
   * @param event Get State by Country Value
   */
  getStates(countryID) {
    if (typeof (countryID) == 'undefined' || countryID == "") {
      this.disableStates = true;
      this.disableCities = true;
      this.cities = [];
    } else {
      this.disableCities = true;
      this.cities = [
        { id: "", text: "Select City" }
      ];
      let stateDDLPlaceholder = [
        {
          id: "",
          state_id: "",
          state_name: "Select State",
          country_id: "",
          latitude: "",
          longitude: "",
          tax_rate: "",
          state_code: ""
        }
      ];
      this.states = [];
      this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_STATES_URL, "opr=1&country_id=" + countryID).subscribe((data: any) => {
        var responseData = data;
        this.disableStates = false;
        if (responseData.result == "1") {
          this.states = stateDDLPlaceholder.concat(responseData.data);
          for (var i in this.states) {
            this.states[i].id = this.states[i].state_id;
            this.states[i].text = this.states[i].state_name;
          }
          document.getElementById('stateId').click();
          if(!this.isFromZipCode){
          let shopState = this.shopDetails.state;
          const state = this.states.filter(state => state.state_name == shopState);
          if (state.length > 0) {
              this.selectedState = state[0].state_id;
              this.addNewEmployee.controls.state.setValue(shopState);
              this.getCities(state[0].state_id);
          } else {
              this.addNewEmployee.controls.state.setValue('');
          }
        }
        } else {
          this.states = [];
          this.cities = [];
          this.disableStates = false;
          this.selectedState = [{ id: "", text: "No state found" }];
          this.disableCities = true;
        }
      });
    }
  }

  /**
   * Get Cities by State Id
   * @param event
   */
  getCities(stateId) {
    if (typeof (stateId) == 'undefined' || stateId == "") {
      this.cities = [];
      this.disableCities = true;
    } else {
      this.cities = [];
      let cityDDLPlaceholder = [{
        id: "",
        city_id: "",
        city_name: "Select City",
        state_id: "",
        country_id: "",
        latitude: "",
        longitude: ""
      }];
      this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_CITIES_URL, "opr=1&state_id=" + stateId).subscribe((data: any) => {
        var responseData = data;
        if (responseData.result == "1") {
          this.disableCities = false;
          this.cities = cityDDLPlaceholder.concat(responseData.data);
          for (var i in this.cities) {
            this.cities[i].id = this.cities[i].city_name;
            this.cities[i].text = this.cities[i].city_name;
          }
          document.getElementById('cityId').click();
          if (!this.isFromZipCode) {
            let shopCity = this.shopDetails.city;
            const city = this.cities.filter(city => city.city_name == shopCity);
            if (city.length > 0) {
              this.selectedCity = city[0].city_name;
              this.addNewEmployee.controls.city.setValue(shopCity);
            } else {
              this.addNewEmployee.controls.city.setValue('');
            }
          }
        } else {
          this.cities = [{ id: "", text: "No city found" }];
          this.disableCities = false;
        }
      })
    }
  }

  addContacts() {
    let dialogRef = this.dialog.open(UsercontactsComponent, {
      panelClass: 'car-dialog-form',
      width: "800px",
      data: { user_id: this.currentLoginUserInfo.id, user_type: this.currentLoginUserInfo.user_type, contacts: this.emgContacts, callAPI: false, reSetList:this.reSetEmergencyContactList }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.emgContacts = dialogRef['data'];
    });
  }

  uploadPicture(event, type, docType?: any) {
    let valid = this.globalService.validateFiles(type, event.target.files);
    if (valid == false) {
      this.removeFileValues();
      return false;
    }
    let data = new FormData();
    let url = globalConstants.API_NEW_SERVICE_REQUEST_FILE;
    let file = event.target.files[0];
    let fileType = this.globalService.getFileIcon(file);
    let reader = new FileReader();
    data.append(type, file);
    data.append('user_id', this.currentLoginUserInfo.id);
    data.append('user_type', this.currentLoginUserInfo.user_type);
    this.globalService.formData(url, data).subscribe((resData: any) => {
      if (resData.result == 1) {
        if (type == 'image') {
          this.addNewEmployee.controls.emp_photo.setValue(resData.data.name);
          reader.onload = (e) => {
            this.employeePhoto = <string>e.target['result'];
            this.ref.detectChanges();
          }
          reader.readAsDataURL(file);
        } else if (type == 'doc' && docType == 1) {
          if (fileType == 1) {
            reader.onload = (e) => {
              resData.data.type = fileType;
              resData.data.src = e.target['result'];
              this.employeeDocs.push(resData.data);
              this.ref.detectChanges();
            }
            reader.readAsDataURL(file);
          } else {
            resData.data.type = fileType;
            this.employeeDocs.push(resData.data);
          }
        } else if (type == 'doc' && docType == 2) {
          if (fileType == 1) {
            reader.onload = (e) => {
              resData.data.type = fileType;
              resData.data.src = e.target['result'];
              this.employeeLicenseDocs.push(resData.data);
              this.ref.detectChanges();
            }
            reader.readAsDataURL(file);
          } else {
            resData.data.type = fileType;
            this.employeeLicenseDocs.push(resData.data);
          }
        }
      } else {
        this.globalService.snackbar("error", resData.message);
      }
    });
  }
  removeFileValues() {
    let file: any = document.querySelectorAll('input[type="file"]');
    if (typeof file.length != 'undefined') {
      file.length && file.forEach(function (x) {
        x.value = '';
      })
    }
  }
  documentDelete(type, index?: any, docType?: any) {
    if (type == 'image') {
      this.addNewEmployee.controls.emp_photo.setValue('');
      this.employeePhoto = '';
    } else if (type == 'doc' && docType == 1) {
      this.employeeDocs.splice(index, 1);
    } else if (type == 'doc' && docType == 2) {
      this.employeeLicenseDocs.splice(index, 1);
    }
    this.removeFileValues();
  }

  updateWagesValue(key) {
    let data = this.addNewEmployee.value[key];
    let output = Number(data);
    if (isNaN(output)) {
      data = data.split('.');
      let val = (typeof data[0] != 'undefined' ? data[0] : '0.00');
      this.addNewEmployee.get(key).setValue(val);
    } else {
    }
    let hWeek = this.addNewEmployee.controls.hours_week.value;
    if (hWeek == '') {
      this.addNewEmployee.controls.hours_wages.setValue('');
      this.addNewEmployee.controls.week_wages.setValue('');
    }
    let hWage = this.addNewEmployee.controls.hours_wages.value;
    let wWage = this.addNewEmployee.controls.week_wages.value;
    /*if(hWeek!='' && wWage!='' && (hWeek>0) && (key=='week_wages'||key=='hours_week')){
    let val:any=(parseFloat(wWage)/parseFloat(hWeek));
     if(!isNaN(val)){
       val=val.toFixed(2);
     }
     this.addNewEmployee.controls.hours_wages.setValue(val)
   }else */
    if ((hWeek != '' && hWeek != null) && (hWage != '' && hWage != null) && (key == 'hours_wages' || key == 'hours_week')) {
      let val: any = (parseFloat(hWage) * parseFloat(hWeek));
      if (!isNaN(val)) {
        val = val.toFixed(2);
      }
      this.addNewEmployee.controls.week_wages.setValue(val)
    }
  }

  applyToAll(index) {
    for (let i of [0, 1, 2, 3, 4, 5, 6]) {
      if (i != index && this.timings.controls[i].get('weekdayid').value == true) {
        this.timings.controls[i].get('start_time').setValue(this.timings.controls[index].get('start_time').value);
        this.timings.controls[i].get('close_time').setValue(this.timings.controls[index].get('close_time').value);
        this.timings.controls[i].get('start_time').updateValueAndValidity();
        this.timings.controls[i].get('close_time').updateValueAndValidity();
      }
    }
  }

  /* Address Field Validation */
  handleAddress1chkValidation(obj) {
    if (obj.checked) {
      this.addNewEmployee.get('address_one').setValidators([Validators.required]); //Set Required Validator
      this.addNewEmployee.get('address_one').updateValueAndValidity();
      this.addressPlaceHolder = 'Address Line 1 *';
      this.addressCheckbox = true;
    } else {
      this.addNewEmployee.get('address_one').clearValidators(); // Clear All Validators
      this.addNewEmployee.get('address_one').updateValueAndValidity();
      this.addressPlaceHolder = 'Address Line 1';
      this.addressCheckbox = false;
    }
  }


  handleSelfCheckinAutoFill(roleId: any) {
    if (roleId == 5) {
      this.addNewEmployee.controls.first_name.setValue('Self Checkin');
      this.addNewEmployee.controls.last_name.setValue("User");
      this.addNewEmployee.controls.dob.setValue((new Date()).toISOString());
      this.addNewEmployee.controls.hours_wages.setValue(0);
      this.addNewEmployee.controls.hours_week.setValue(0);
      this.addNewEmployee.controls.week_wages.setValue(0);
      this.addNewEmployee.controls.mobile_phone.setValidators([]);
      this.addNewEmployee.controls.mobile_phone.updateValueAndValidity();
      this.addNewEmployee.controls.employee_type.setValue(roleId);
      this.autoFillShopInfo();
    } else {
      this.addNewEmployee.controls.mobile_phone.setValidators([Validators.required, Validators.minLength(10)]);
      this.addNewEmployee.controls.mobile_phone.updateValueAndValidity();
      this.addNewEmployee.controls.employee_type.setValue(roleId);
    }
  }

  autoFillShopInfo() {
    this.addNewEmployee.controls.address_one.setValue(this.shopDetails.address1);
    this.addNewEmployee.controls.address_two.setValue(this.shopDetails.address2);
    this.addNewEmployee.controls.zipcode.setValue(this.shopDetails.zip);
    this.addNewEmployee.controls.country.setValue(this.shopDetails.country);
    this.addNewEmployee.controls.state.setValue(this.shopDetails.state);
    this.selectedCountry = this.shopDetails.country_code;
    this.getStates(this.shopDetails.country_code);
    this.selectedState = this.shopDetails.state_id;
    this.getCities(this.shopDetails.state_id);
    this.addNewEmployee.controls.city.setValue(this.shopDetails.city);
    this.selectedCity = this.shopDetails.city;
  }
  setCountryStateCityViaZipCode() {
    let currentLoginUser = JSON.parse(localStorage.getItem("loggedInUser"));
    let payload = {
      user_id: currentLoginUser.id,
      user_type: "User",
      zip_code: this.addNewEmployee.controls.zipcode.value
    }
    this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_STATE_CITY_VIA_ZIPCODE, payload).subscribe((response: any) => {
      if (response.result == 1) {
        if (response.data.country_info.country_id !== 0
          && response.data.state_info.state_id !== 0
          && response.data.city_info.city_id !== 0) {
          this.isFromZipCode = true;
          this.selectedCountry = response.data.country_info.country_code;
          this.addNewEmployee.controls.country.setValue(response.data.country_info.country_name);
          this.getStates(response.data.country_info.country_code);
          this.selectedState = response.data.state_info.state_id;
          this.addNewEmployee.controls.state.setValue(response.data.state_info.state_name);
          this.getCities(response.data.state_info.state_id);
          this.selectedCity = response.data.city_info.city_name;
          this.addNewEmployee.controls.city.setValue(response.data.city_info.city_name);
        }
      }
    })
  }

    /* END */
    handleDOB(obj, picker) {
      this.isFutureDate = this.globalService.validateFutureDate(this.addNewEmployee.value.dob);
      if (typeof obj == 'undefined') {
        obj = picker.inputElementValue;
      }
      if (obj != '') {
        picker.api.moveCalendarTo(obj);
      }
    }

  ngOnDestroy() {
    if( this.subscriptionDataForAPI){
    this.subscriptionDataForAPI.unsubscribe();
    }
  }
}
