import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import {globalFunctions} from "../../../environments/globalFunctions";
import {globalConstants} from "../../../environments/globalConstants";
import {GlobalService} from "../../../environments/global.service";
import {Router, ActivatedRoute, ParamMap, NavigationEnd, Params} from '@angular/router';
import { LoaderService } from 'src/app/base/services';

@Component({
  selector: 'app-changeshopspassword',
  templateUrl: './changeshopspassword.component.html',
  styleUrls: ['./changeshopspassword.component.scss']
})
export class ChangeshopspasswordComponent implements OnInit {
    public changePassword;
    public request;
    public change_pass: FormGroup;
    public state = 'inactive';
    hide:boolean = false;
    hide1:boolean = false;
    constructor(
        private formBuilder: FormBuilder,
        public globalservice: GlobalService, 
        private router: Router,
        public loaderService: LoaderService
    ) {
        this.change_pass = this.formBuilder.group({
            old_password: ['', [Validators.required, Validators.minLength(6)]],
            new_password: ['', [Validators.required, Validators.minLength(6)]],
            confirm_password: ['', Validators.required],
            user_type: this.globalservice.getCurrentUser()["user_type"],
            user_id: []
        }, {validator: this.confirmPasswordValidator})
    }
    
    toggleState() {
        this.state = this.state === 'active' ? 'inactive' : 'active';
    }
    
    ngOnInit() {
        this.loaderService.hide();
    }

    ChangePassword() {
        if (this.change_pass.valid) {
            this.change_pass.controls.user_id.setValue(this.globalservice.getCurrentUser()["id"]);
            let formdata = this.change_pass.value;
            formdata.old_password = formdata.old_password.replaceAll('+','%2B');
            formdata.new_password = formdata.new_password.replaceAll('+','%2B');
            formdata.confirm_password = formdata.confirm_password.replaceAll('+','%2B');
            this.globalservice.callAPI(globalConstants.API_CHANGE_USER_PASSWORD_URL, formdata).subscribe(
            (data:any) => {
                this.request = data;
                if (this.request.result == "1") {
                    this.router.navigate(["/shops/dashboard"]);
                    this.globalservice.snackbar("success", this.request.message);
                } else {
                    this.globalservice.snackbar("error", this.request.message);
                }
            });
        }
    }

    confirmPasswordValidator(c: AbstractControl) {
        if (c.get('new_password').value !== c.get('confirm_password').value) {
            c.get('confirm_password').setErrors({"matched": true})
            return {matched: true};
        } else {
            c.get('confirm_password').valid;
        }
    }

}
