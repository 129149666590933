<div class="panel panel-primary" id="mainCompotents">
    <div class="panel-heading padding-left-15">
        <h3 class="panel-title">PARTS LOGS</h3>
        <button mat-mini-fab class="custom-btn-back" (click)="globalService.goBack();">
           <mat-icon>arrow_back</mat-icon>
        </button>
    </div>
    <div class="panel-body bgcolor-lightgrey">
        <div class="display-full relative">
            <mat-expansion-panel hideToggle="false" class="search-filter search-employee relative">
                <mat-expansion-panel-header collapsedHeight="53px" expandedHeight="53px">
                    <strong><i class="fa fa-search fa-fw"></i>&nbsp;Search Filters</strong>
                </mat-expansion-panel-header>
                <form id="advanceSearchForm" class="collapse in" aria-expanded="true">
                    <div class="panel-body">

                        <div class="col-sm-3 uc-ngx-select ">
                            <ngx-select class="margin-bottom-20" [items]="selectlists" [(ngModel)]="options.search_by" [ngModelOptions]="{standalone: true}"
                            placeholder="Search By" [noAutoComplete]="true" >
                            </ngx-select>
                        </div>

                        <div class="col-sm-3 col-lg-2" *ngIf="options.search_by=='bwtDate'">
                                <mat-form-field class="car-input btn-block car-datePicker"  floatPlaceholder="never">
                                    <input (dateChange)="dateChange($event,1)" matInput class="form-control input-lg custom-form-input"  [(ngModel)]="fromDate" [max]="today" [ngModelOptions]="{standalone: true}" placeholder="Log From Date" [matDatepicker]="filerPicker1" onkeydown="return false;">
                                            <mat-datepicker-toggle matSuffix [for]="filerPicker1" ></mat-datepicker-toggle>
                                    <mat-datepicker #filerPicker1 [touchUi]="setTouchViewValue()"></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col-sm-3 col-lg-2" *ngIf="options.search_by=='bwtDate'">
                                <mat-form-field class="car-input btn-block car-datePicker"  floatPlaceholder="never">
                                    <input (change)="dateChange($event,2)" matInput class="form-control input-lg custom-form-input"  [(ngModel)]="toDate" [min]="fromDate" [max]="today" [ngModelOptions]="{standalone: true}" placeholder="Log to Date" [matDatepicker]="filerPicker2" onkeydown="return false;">
                                            <mat-datepicker-toggle matSuffix [for]="filerPicker2" ></mat-datepicker-toggle>
                                    <mat-datepicker #filerPicker2 [touchUi]="setTouchViewValue()"></mat-datepicker>
                                </mat-form-field>
                            </div>

                    </div>
                    <mat-action-row class="search-filter-footer">
                        <button mat-raised-button color="accent" type="submit" (click)="search($event)">
                                Search
                              </button>
                    </mat-action-row>
                </form>
            </mat-expansion-panel>
            <button mat-button class="clear-btn" #clearBtn (click)="clearSearch()" [class.active-search]="checkFormEmpty(clearBtn)">
                        Clear Filter
                    </button>
        </div>
        <div class="car-datatable uc-datatable mat-elevation-z1">
            <div class="table-responsive relative ">
                <mat-table #table [dataSource]="dataSource" matSort class="datatable-loader-bg uc-table-responsive">

                    <ng-container matColumnDef="date_formate">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Date </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto" data-val="Date"> {{row.date_formate || '-'}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Name </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto" data-val="Name"> {{row.name || '-'}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="designation">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Designation </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto" data-val="Designation"> {{row.designation || '-'}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="remark">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Remarks </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto" data-val="Remarks"> {{row.remark || '-'}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="Action">
                        <mat-header-cell *matHeaderCellDef mat--header data-role="button"> Action </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="button">
                            <button *ngIf="row.actions=='Updation'" mat-button class="list-action-btn" [matMenuTriggerFor]="menu" disableRipple>
                               Action<mat-icon>arrow_drop_down</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu" class="car-icon-menu" [overlapTrigger]="false">
                                <a class="mat-menu-item" (click)="viewDetail(row.id)"><i class="fa fa-eye"></i>View Detail</a>
                            </mat-menu>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
                <div class="padding-15 text-center" [style.display]="getTotalRecords() < 1 ? '' : 'none'"> No Records Available </div>
            </div>
            <div class="display-full relative" [style.display]="getTotalRecords() < 1 ? 'none' : ''">
                <mat-paginator class="car-pagination" #paginator [length]="getTotalRecords()" [pageIndex]="0" [pageSize]="options.limit"
                    [pageSizeOptions]="[5, 10,25, 100]" (page)="paginateByLimit($event)">
                </mat-paginator>

                <div class="mat-paginator table-paging">
                    <div class="mat-paginator-range-label margin-left-30 margin-right-30">{{options.start+1}} - {{getUpperLimit(options.start + options.limit,getTotalRecords())}} of {{getTotalRecords()}}</div>
                    <button mat-icon-button (click)="paginate($event,'previous');" [disabled]="firstList()">
                            <mat-icon>navigate_before</mat-icon>
                    </button>
                    <button mat-icon-button (click)="paginate($event,'next');" [disabled]="lastList()">
                            <mat-icon>navigate_next</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>