<div class="panel panel-primary ServiceCalendar-mainWrap" id="mainCompotents">
  <div class="panel-heading padding-left-15">
    <span>
      <h3 class="panel-title">SERVICE CALENDAR</h3>
    </span>
    <span>
      <a href="javascript:;" (click)="listView()" class="list-btn" title="Service Request List">
        <i class="fa fa-bars"></i>
      </a>
    </span>
  </div>
  <div class="panel-body bgcolor-lightgrey calender-searchSection">
    <div class="display-full relative">
      <mat-expansion-panel hideToggle="false" class="search-filter relative search-employee mat-elevation-z1">
        <mat-expansion-panel-header collapsedHeight="53px" expandedHeight="53px">
          <strong>
            <i class="fa fa-search fa-fw"></i>&nbsp;Search Filters</strong>
        </mat-expansion-panel-header>
        <form id="advanceSearchForm" class="collapse in" aria-expanded="true">
          <div class="panel-body">

            <div class="col-sm-3 uc-ngx-select">
              <ngx-select class="display-full margin-bottom-20" [items]="searchByStatus" [(ngModel)]="options.status"
                [ngModelOptions]="{standalone: true}" [noAutoComplete]="true" placeholder="Select Status"></ngx-select>
            </div>

            <div class="col-sm-3" *ngIf="allTicketsView && options.status!='4'">
              <mat-form-field class="car-input" floatPlaceholder="never" appearance="fill" style="width: 100%;">
                <input placeholder="Search Employee" matInput title="Select Employee"
                  class="form-control input-lg custom-form-input" [(ngModel)]="empSelected" [formControl]="myControl"
                  [matAutocomplete]="auto" style=" height: 40px;" />
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="SetOptionAsEmployeeId($event)"
                  [displayWith]="empDisplayFn.bind(this)">
                  <mat-option class="employee-dropdown" *ngFor="let option of filterOptionsSet | async" [value]="option">
                    {{ option.first_name }}
                    {{ option.last_name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <span>
                <button type="button" class="search_btn" mat-button *ngIf="empSelected" matSuffix mat-icon-button
                  aria-label="Clear" (click)="resetAutocomplete('employee');">
                  <mat-icon id="matico">close</mat-icon>
                </button>
              </span>
            </div>

            <div class="col-sm-3" *ngIf="options.status=='1' || options.status==''">
              <mat-form-field class="car-input" floatPlaceholder="never" appearance="fill" style="width: 100%;">
                <input [(ngModel)]="custSelected" placeholder="Search Customer" matInput title="Search Customer"
                  class="form-control input-lg custom-form-input" [formControl]="customerControl"
                  [matAutocomplete]="customer" style=" height: 40px;" />
                <mat-autocomplete #customer="matAutocomplete" (optionSelected)="SetOptionAsCustomerId($event)"
                  [displayWith]="custDisplayFn.bind(this)">
                  <mat-option class="customer-dropdown" *ngFor="let option of filterCustomerSet | async" [value]="option">
                    {{ option.name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <span>
                <button type="button" class="search_btn" mat-button *ngIf="custSelected" matSuffix mat-icon-button
                  aria-label="Clear" (click)="resetAutocomplete('customer');">
                  <mat-icon id="matico">close</mat-icon>
                </button>
              </span>
            </div>

            <div class="col-sm-3 uc-ngx-select details-input" *ngIf="options.status=='1'||options.status==''">
              <ngx-select [autoClearSearch]="true" class="display-full margin-bottom-20 custom-form-input"
                [(ngModel)]="options.service_label" [ngModelOptions]="{standalone: true}" [multiple]="true"
                [items]="allLabels" optionTextField="label_name" placeholder="Search Labels"></ngx-select>
            </div>

          </div>
          <mat-action-row class="search-filter-footer">
            <button mat-raised-button color="accent" type="submit" (click)="loadCalendarEvents()">
              Search
            </button>
          </mat-action-row>
        </form>
      </mat-expansion-panel>
      <button mat-button class="clear-btn" (click)="clearSearch()">
        Clear Filter
      </button>
    </div>

    <div class="display-full relative">
      <div
        [ngClass]="{'help-block mt-0 click-note':true, 'text-red': !isCompletedIncluded, 'text-green':isCompletedIncluded}"
        (click)="toggleCompletedRequests($event)">
        Note:
        <span *ngIf="!isCompletedIncluded">This list EXCLUDES Completed service requests.</span>
        <span *ngIf="isCompletedIncluded">This list now INCLUDES Completed Service Requests.</span>
      </div>
    </div>

    <!-- <div class="example-sidenav-content" *ngIf="(sidePanelContent | json) != ({} | json)">
      <button type="button" mat-button (click)="drawer.toggle()">
        Toggle sidenav
      </button>
    </div> -->

    <div class="row calender-dashboard" style="margin-top: 0px; margin-bottom: 10px;">
      <div class="col-sm-4 calender-header">
        <div class="btn-group" style="float: left;">
          <div class="btn btn-primary" (click)="CalendarNextPrevEvent('prev')">
            Previous
          </div>
          <div class="btn btn-outline-secondary" (click)="CalendarNextPrevEvent('today')">
            Today
          </div>
          <button class="btn btn-primary" (click)="CalendarNextPrevEvent('next')">
            Next
          </button>
        </div>
      </div>

      <div class="col-sm-4 calender-header">
        <div class="date-wrapper py-3">
          <div class="date-display">{{CalendarMonth}}</div>
        </div>
      </div>

      <div class="col-sm-4 calender-header" style="z-index: 0;">
        <div class="btn-group" style="float: right;">
          <div class="btn btn-primary" (click)="calenderSelectedView('month')"
            [ngClass]="{'active': calenderSelectedEvent === 'month'}">
            Month
          </div>
          <div class="btn btn-primary" (click)="calenderSelectedView('week')"
            [ngClass]="{'active': calenderSelectedEvent === 'week'}">
            Week
          </div>
          <button class="btn btn-primary" (click)="calenderSelectedView('day')"
            [ngClass]="{'active': calenderSelectedEvent === 'day'}">
            Day
          </button>
        </div>
      </div>
    </div>
    <div class="popup-sidebar-wrapper">
      <mat-drawer-container autosize end class="side-panel-wrappers ticket-assignment service-calendar">
        <mat-drawer #drawer class="ticket-sidenav" position="end" mode="over">
            <div class="side-panel-block" *ngIf="(sidePanelContent | json) != ({} | json)">
                <ng-container [ngSwitch]="sidePanelContent.event_type">
                <h4 *ngSwitchCase="'1'">Service Request Appt. Detail</h4>
                <h4 *ngSwitchCase="'2'">Ticket Assignment Detail</h4>
                <h4 *ngSwitchCase="'5'">Leave Detail</h4>
                </ng-container>
                <!-- <div class="cross-btn">
                <button (click)="drawer.toggle()" type="button">x</button>
                </div> -->
                <div class="btn-block header" >
                    <ul class="padding-0 margin-0" style="float: right;">
                  
                        <li *ngIf="sidePanelContent.event_type=='1' || sidePanelContent.event_type=='2'">
                            <button type="button" class=" btn" (click)="viewSchedule(sidePanelContent)"
                            title="View Details"><i class="fa fa-eye" aria-hidden="true"></i></button>
                        </li>
                        <li *ngIf="sidePanelContent.event_type=='1' || sidePanelContent.event_type=='2'">
                            <button type="button" class=" btn" (click)="newTechAssignment()"
                            *ngIf="isEmployee && isVisible() && sidePanelContent.event_type=='1' && sidePanelContent.request_status != '10' && sidePanelContent.request_status != '8' && sidePanelContent.request_status != '6'"
                            title="Assign"><i class="fa fa-user" aria-hidden="true"></i></button>
                        </li>
                 
                        <li *ngIf="sidePanelContent.event_type=='5'" >
                            <div *ngIf="sidePanelContent.event_type=='5' && isAllowed('apply_employee_leaves','deletes') && isVisible()">
                                <button type="button" class=" btn" (click)="removeLeave()" title="Delete"><i class="fa fa-trash" aria-hidden="true"></i></button>
                            </div>
                        </li>
                     
                        <li>
                            <button class="btn" (click)="drawer.toggle()" type="button" title="Close"><i class="fa fa-times" aria-hidden="true"></i></button>
                        </li>
                    </ul>
                </div>
                <div class="assignment-block ">
                <ng-container *ngIf="sidePanelContent.event_type=='1' || sidePanelContent.event_type=='2'">
                    <div class="row margin-bottom-5 thumbnail-image-box">
                    <div class="col-sm-5 col-xs-5 car-col">
                        <div class="car-img-block">
                        <img alt="car" src="{{imgPath}}default-car.png" *ngIf="sidePanelContent.vehicle_pic == ''">
                        <img alt="car" src="{{imgPath}}{{sidePanelContent.vehicle_pic}}"
                            *ngIf="sidePanelContent.vehicle_pic != ''">
                        </div>
                    </div>
                    <div class="col-sm-7 col-xs-7">
                        <div class="row margin-bottom-5">
                            <div class="col-sm-12 col-xs-12" (click)="NavigateTo(sidePanelContent.request_id)">
                                <span style="color:#eb1e4c;cursor: pointer;">{{sidePanelContent.service_request_id}}</span>
                            </div>
                            <div class="col-sm-12 col-xs-12">
                                <span class="font-weight-bold">{{ sidePanelContent.service_request_title }}</span>
                            </div>
                        </div>
                        <div class="row margin-bottom-5">
                            <div class="col-sm-12 col-xs-12">
                                <span>{{ convertISODate(sidePanelContent.requested_delivery_date) | date:'M/d/yyyy, h:mm a'}}
                                <button
                                    *ngIf="(sidePanelContent.request_status == 11 || sidePanelContent.request_status == 1) && isAllowed('service_request','edits')"
                                    (click)="SrUpdate('appt_date')" class="tooltip-status text-red fa fa-pencil"
                                    title="Edit date" type="button"></button>
                                </span>
                            </div>
                        </div>
                        <div class="row margin-bottom-5">
                            <div class="col-sm-10 col-xs-10">
                                <span>Est. {{sidePanelContent.est_hours}} hours</span>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="row margin-bottom-5">
                        <div class="col-sm-4 col-xs-4">
                            <label>Cust. Name</label>
                        </div>
                        <div class="col-sm-1 col-xs-1 p-0">
                            <label>:</label>
                        </div>
                        <div class="col-sm-7 col-xs-7">
                            <span>{{sidePanelContent.customer_fname}} {{sidePanelContent.customer_lname}}</span>
                        </div>
                    </div>
                    <div class="row margin-bottom-5">
                        <div class="col-sm-4 col-xs-4">
                            <label>VIN</label>
                        </div>
                        <div class="col-sm-1 col-xs-1 p-0">
                            <label>:</label>
                        </div>
                        <div class="col-sm-7 col-xs-7">
                            <span>{{sidePanelContent.vin || 'N/A'}}</span>
                        </div>
                    </div>
                    <div class="row margin-bottom-5">
                        <div class="col-sm-4 col-xs-4">
                            <label>License Plate</label>
                        </div>
                        <div class="col-sm-1 col-xs-1 p-0">
                            <label>:</label>
                        </div>
                        <div class="col-sm-7 col-xs-7">
                            <span>{{sidePanelContent.license_plate || 'N/A'}}</span>
                        </div>
                    </div>
                    <div class="row margin-bottom-5">
                        <div class="col-sm-4 col-xs-4">
                            <label>Vehicle</label>
                        </div>
                        <div class="col-sm-1 col-xs-1 p-0">
                            <label>:</label>
                        </div>
                        <div class="col-sm-7 col-xs-7">
                           <span>{{gs.getGlobalVehicleName(sidePanelContent)||"N/A"}}</span>
                          </div>
                    </div>
                    <div class="technicians margin-bottom-10">
                        <div class="row margin-bottom-5">
                            <div class="col-sm-12 col-xs-12">
                                <label>Technicians :</label>
                            </div>
                        </div>
                    <ng-container *ngIf="technicianList && technicianList.length > 0">
                        <ng-container *ngFor="let tech of technicianList;let i=index">
                        <div class="row margin-bottom-10" *ngIf="i < 3 && sidePanelContent.event_type=='1'">
                            <div class="col-sm-5 col-xs-5">
                            <label>{{tech.first_name}} {{tech.last_name}}:</label>
                            </div>
                            <div class="col-sm-7 col-xs-7">
                            <span>{{ convertMinutes(tech.allocated_time) }} | {{ convertMinutes(tech.consumed_time)
                                }}</span>
                            <i [ngStyle]="{'color': (tech.consumed_time > tech.allocated_time) ? '#f00000' : '#000000'}"
                                *ngIf="tech.work_status=='1' || tech.work_status=='2'" class="fa far fa-clock"></i>
                            </div>
                            <div class="col-sm-12 col-xs-12" *ngIf="tech.start_date && tech.end_date">
                            <span>{{ convertISODate(tech.start_date) | date:'M/d/yyyy, h:mm a' }} - {{
                                convertISODate(tech.end_date) | date:'M/d/yyyy, h:mm a' }}</span>
                            </div>
                        </div>
                        <div class="row margin-bottom-10"
                            *ngIf="sidePanelContent.event_type=='2' && tech.id==sidePanelContent.usr_sr_id">
                            <div class="col-sm-5 col-xs-5">
                            <label>{{tech.first_name}} {{tech.last_name}} :</label>
                            </div>
                            <div class="col-sm-7 col-xs-7">
                            <span>{{ convertMinutes(tech.allocated_time) }} | {{ convertMinutes(tech.consumed_time)
                                }}</span>
                            <i [ngStyle]="{'color': (tech.consumed_time > tech.allocated_time) ? '#f00000' : '#000000'}"
                                *ngIf="tech.work_status=='1' || tech.work_status=='2'" class="fa far fa-clock"></i>
                            </div>
                            <div class="col-sm-12 col-xs-12" *ngIf="tech.start_date && tech.end_date">
                            <span>{{ convertISODate(tech.start_date) | date:'M/d/yyyy, h:mm a' }} - {{
                                convertISODate(tech.end_date) | date:'M/d/yyyy, h:mm a' }}</span>
                            </div>
                        </div>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="technicianList && technicianList.length==0">
                        <div class="row margin-bottom-10">
                        <div class="col-sm-12 col-xs-12 no-data">
                            <p>No technican assigned.</p>
                        </div>
                        </div>
                    </ng-container>
                    <div class="row" *ngIf="technicianList && technicianList.length > 3">
                        <div class="col-sm-12 col-xs-12 text-right"
                        *ngIf="isEmployee && (sidePanelContent.request_status != 10)">
                        <a href="javascript:void(0)" (click)="viewAssignee(sidePanelContent.request_id)">More</a>
                        </div>
                    </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="sidePanelContent.event_type=='5'">
                    <div class="row margin-bottom-5 thumbnail-image-box">
                    <div class="col-sm-4 col-xs-4">
                        <label>Employee Name</label>
                    </div>
                    <div class="col-sm-1 col-xs-1 p-0">
                        <label>:</label>
                    </div>
                    <div class="col-sm-7 col-xs-7">
                        <span>{{sidePanelContent.emp_fname | titlecase}} {{sidePanelContent.emp_lname | titlecase}}</span>
                    </div>
                    </div>
                    <div class="row margin-bottom-5">
                    <div class="col-sm-4 col-xs-4">
                        <label>Title</label>
                    </div>
                    <div class="col-sm-1 col-xs-1 p-0">
                        <label>:</label>
                    </div>
                    <div class="col-sm-7 col-xs-7">
                        <span>{{sidePanelContent.leave_reason | titlecase}}</span>
                    </div>
                    </div>
                    <div class="row margin-bottom-5">
                    <div class="col-sm-4 col-xs-4">
                        <label>Leave Type</label>
                    </div>
                    <div class="col-sm-1 col-xs-1 p-0">
                        <label>:</label>
                    </div>
                    <div class="col-sm-7 col-xs-7">
                        <span>{{sidePanelContent.title | titlecase}}</span>
                    </div>
                    </div>
                    <div class="row margin-bottom-5">
                    <div class="col-sm-4 col-xs-4">
                        <label>Applied For</label>
                    </div>
                    <div class="col-sm-1 col-xs-1 p-0">
                        <label>:</label>
                    </div>
                    <div class="col-sm-7 col-xs-7" [ngSwitch]="sidePanelContent.leave_duration">
                        <span *ngSwitchCase="'h'">Half Day</span>
                        <span *ngSwitchCase="'f'">Full Day</span>
                    </div>
                    </div>
                    <div class="row margin-bottom-5">
                    <div class="col-sm-4 col-xs-4">
                        <label>Date & Time</label>
                    </div>
                    <div class="col-sm-1 col-xs-1 p-0">
                        <label>:</label>
                    </div>
                    <div class="col-sm-7 col-xs-7">
                        <span *ngIf="sidePanelContent.leave_duration=='f'">{{
                        convertISODate(sidePanelContent.leave_start_date)
                        | date:'M/d/yyyy' }} - {{ convertISODate(sidePanelContent.leave_end_date) | date:'M/d/yyyy' }}
                        </span>
                        <span *ngIf="sidePanelContent.leave_duration=='h'">{{ convertISODate(sidePanelContent.start_date) |
                        date:'M/d/yyyy' }} | {{ convertISODate(sidePanelContent.start_date) | date:'h:mm a' }} - {{
                        convertISODate(sidePanelContent.end_date) | date:'h:mm a' }} </span>
                    </div>
                    </div>
                    <div class="row margin-bottom-5">
                    <div class="col-sm-4 col-xs-4">
                        <label>Status</label>
                    </div>
                    <div class="col-sm-1 col-xs-1 p-0">
                        <label>:</label>
                    </div>
                    <div class="col-sm-7 col-xs-7" [ngSwitch]="sidePanelContent.leave_status">
                        <span color="accent" *ngSwitchCase="1">Applied</span>
                        <span color="primary" *ngSwitchCase="2">Approved</span>
                        <span color="warn" *ngSwitchCase="3">Rejected</span>
                    </div>
                    </div>
                    <div class="row margin-bottom-5"
                    *ngIf="sidePanelContent.leave_notes && sidePanelContent.leave_notes.length > 0">
                    <div class="col-sm-4 col-xs-4">
                        <label>Leave Notes</label>
                    </div>
                    <div class="col-sm-1 col-xs-1 p-0">
                        <label>:</label>
                    </div>
                    <div class="col-sm-7 col-xs-7">
                        <span>{{ sidePanelContent.leave_notes }}</span>
                    </div>
                    </div>
                    <div class="row margin-bottom-5"
                    *ngIf="sidePanelContent.attachments && sidePanelContent.attachments.length > 0">
                    <div class="col-sm-4 col-xs-4">
                        <label>Documents</label>
                    </div>
                    <div class="col-sm-1 col-xs-1 p-0">
                        <label>:</label>
                    </div>
                    <div class="col-sm-7 col-xs-7">
                        <ul class="list-inline upload-image margin-bottom-0">
                        <li *ngFor="let doc of sidePanelContent.attachments;let i=index;">
                            <a href="javascript:void(0);" (click)="imageToolDialog($event,0,14,[sidePanelContent.attachments[i]])" *ngIf="['jpg','jpeg','png','JPEG','JPG','PNG'].includes(doc.filetype)" class="img-set thumbnail relative">
                            <img class="show-image" src="{{docImgPath}}{{doc.filename}}" />
                            </a>
                            <a href="javascript:void(0);" (click)="invokeDownload(docPath+doc.filename,'EmployeeLeaveDocument',doc.filename)" *ngIf="['pdf','docx','doc','PDF','DOCX','DOC'].includes(doc.filetype)" class="img-set thumbnail relative">
                            <span class="fa far fa-file-pdf fa-4x" *ngIf="['pdf','PDF'].includes(doc.filetype)"></span>
                            <span class="fa far fa-file-word fa-4x" *ngIf="['docx','doc','DOCX','DOC'].includes(doc.filetype)"></span>
                            </a>
                        </li>
                        </ul>
                    </div>
                    </div>
                </ng-container>
                </div>
                <div class="footer">
                    <div class="row action-btns">
                        <div class="col-sm-4 col-xs-12"></div>
                        <div class="col-sm-8 col-xs-12"
                            *ngIf="sidePanelContent.event_type=='1' || sidePanelContent.event_type=='2'">
                            <!-- <p><button type="button" class="action-btn btn"  title="Edit">Edit</button></p> -->
                            <p><button type="button" class="action-btn btn" (click)="viewSchedule(sidePanelContent)"
                                title="View Details">View</button></p>
                            <p><button type="button" class="action-btn btn" (click)="newTechAssignment()"
                                *ngIf="isEmployee && isVisible() && sidePanelContent.event_type=='1' && sidePanelContent.request_status != '10' && sidePanelContent.request_status != '8' && sidePanelContent.request_status != '6'"
                                title="Assign">Assign</button></p>
                        </div>
                        <div class="col-xs-12" *ngIf="sidePanelContent.event_type=='5' && isAllowed('apply_employee_leaves','deletes') && isVisible()">
                            <p>
                                <button type="button" class="action-btn btn" (click)="removeLeave()" title="Delete">Delete</button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </mat-drawer>
        <div #serviceCalendar class="service-calendar">
          <!-- <div class="display-full relative"></div> -->
        </div>
      </mat-drawer-container>
    </div>
  </div>
</div>
<div class="color-info">
  <ul>
    <li style="background-color:#fd82ad5e;">Appointments</li>
    <li style="background-color:#b1a3e8a6;">Assign Tech.</li>
    <li style="background-color:#92929e;">Internal Notes</li>
    <li style="background-color:#faf2cc;">Pending</li>
    <li style="background-color:#c4e3f3;">Processing</li>
    <li style="background-color:#e1e114;">Approval Awaited</li>
    <li style="background-color:#d0e9c6;">Approval Completed</li>
    <li style="background-color:#b4eab4;">Work In Progress</li>
    <li style="background-color:#ada;">Completed</li>
    <li style="background-color:#52bab3;">Completed And Paid</li>
    <li style="background-color:#faaa69;">Holidays</li>
    <li style="background-color:#a7edfd;">Employee Leaves</li>
    <li style="background-color:#f1a19fab;">Shop Busy Day</li>
  </ul>
</div>

<!--
Ticket Assignment SidePanel integration
-->
<app-tech-assignment [data]="techData" (closeTab)="techAssignmentOut($event)" *ngIf="(techData | json) != '{}'"></app-tech-assignment>