<!-- Show the google ads banner or subscribed message -->
<!--<div class="subscription-alert alert-success" role="alert">You are a subscribed customer.</div>-->
  <div class="col-xs-12  subscription-alert-section padding-left-0 padding-right-0" *ngIf="showPermissionBanner" style="float: none;">
    <mat-toolbar class="subscription-alert-bar" >
        <div class="subscription-content">
            Browser notifications are disabled. Enable them so you don't miss new updates. 
        </div>
        <div class="subscription-btn">
          <button mat-raised-button class="font-bold mat-elevation-z0" color="accent" (click)="askPermissionForNotifications()">
            <span>Enable Notifications</span>
          </button>
        </div>
      </mat-toolbar>
      <p></p>
</div>
<div id="mainCompotents">
<div class="panel panel-primary margin-0 shopdashboard" id="dashboardQuickLinks">
    <div class="panel-heading padding-left-15">
        <h3 class="panel-title">
            Shop Dashboard
        </h3>
    </div>
    <div class="panel-body">
        <!-- fleet dashboard start-->
        <div class="total-count-section" id="total-count" *ngIf="profileType==2">
            <div class="row link-merge">
                <div class="col-sm-12 margin-bottom-25" *ngIf="prefCustomer.length==0">
                    <div class="text-center">
                        <button type="button" (click)="viewCustomers()"
                            class="font-bold mat-elevation-z0 mat-raised-button mat-accent">Select Customers</button>
                        <p>You have not selected any customer in preferred list.</p>
                    </div>
                </div>
                <div class="fleet">
                    <div class="row show-fleet-sm margin-0" *ngIf="prefCustomer.length > 0">
                        <div class="col-sm-12">
                            <mat-accordion class="new-request-expension view-request margin-bottom-10 display-full"
                                displayMode="flat" hideToggle="false">
                                <mat-expansion-panel hideToggle="true" *ngFor="let cus of prefCustomer;let a=index"
                                    id="expansion-{{a+1}}">
                                    <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px">
                                        {{getCORBName(cus)}}
                                    </mat-expansion-panel-header>
                                    <mat-card class="customer-card">
                                        <button type="button" (click)="addCar(cus)"
                                            class="font-bold mat-raised-button mat-accent hide" title="Add Vehicle">ADD
                                            Vehicle</button>
                                        <button type="button" (click)="newRequest(cus)"
                                            class="font-bold mat-raised-button mat-accent" title="New Request">New Request</button>
                                        <button type="button" (click)="listCars(cus)" class="font-bold mat-raised-button mat-accent"
                                            title="View All Vehicles">View All Vehicles</button>
                                        <button type="button" (click)="viewAllServiceRequests(cus)"
                                            class="font-bold mat-raised-button mat-accent" title="Service Requests">Service
                                            Requests</button>
            
                                    </mat-card>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>
                    <div class="row margin-0">
                        <ng-container *ngIf="prefCustomer.length > 0">
                            <div class="col-sm-3 show-fleet-md" *ngFor="let cus of prefCustomer;let i=index">
                                <mat-card class="customer-card">
                                    <mat-card-header>
                                        <mat-card-title class="title">{{getCORBName(cus)}}</mat-card-title>
                                    </mat-card-header>
                                    <mat-card-content>
                                        <button type="button" (click)="addCar(cus)"
                                            class="font-bold mat-raised-button mat-accent hide" title="Add Vehicle">ADD
                                            Vehicle</button>
                                        <button type="button" (click)="newRequest(cus)"
                                            class="font-bold mat-raised-button mat-accent" title="New Request">New Request</button>
                                        <button type="button" (click)="listCars(cus)" class="font-bold mat-raised-button mat-accent"
                                            title="View All Vehicles">View All Vehicles</button>
                                        <button type="button" (click)="viewAllServiceRequests(cus)"
                                            class="font-bold mat-raised-button mat-accent" title="Service Requests">Service
                                            Requests</button>
            
                                    </mat-card-content>
                                </mat-card>
                            </div>
                        </ng-container>
                        <div class="col-sm-12 show-fleet-sm margin-bottom-10">
                            <button type="button" (click)="toggleBlocks()"
                                class="display-full font-bold mat-raised-button mat-accent">Tiles Show/Hide</button>
                        </div>
                        <div class="col-sm-3 link collapse fleet-card" *ngIf="isAllowed('views','service_request')">
                            <mat-card class="card-default">
                                <div class="infoTile lnk" (click)="getTotalTickets()">
                                    <p><strong *ngIf="shopStatistics!=undefined">{{shopStatistics["totalTickets_count"]}}</strong>
                                        <a href="javascript:void(0)" title="Total Requests">TOTAL REQUESTS</a></p>      
                                </div>
                            </mat-card>
                        </div>
                        <div class="col-sm-3 link collapse fleet-card" *ngIf="isAllowed('views','service_request')">
                            <mat-card class="card-default">
                                <div class="infoTile lnk" (click)="getNewRequests()">
                                    <p><strong *ngIf="shopStatistics!=undefined">{{shopStatistics["totalAppointments"]}}</strong> <a
                                            href="javascript:void(0)" title="Appointments">APPOINTMENTS</a></p>
                                    <p class="infotile-subtitle" *ngIf="shopStatistics!=undefined"><a href="javascript:void(0)"
                                            (click)="unreadAppt()"
                                            title="Unread">{{shopStatistics["totalAppointments_unread_count"]}} UNREAD</a></p>
                                </div>
                                <div class="infoTile lnk" (click)="getPendingTickets()">
                                    <p><strong
                                            *ngIf="shopStatistics!=undefined">{{shopStatistics["totalPendTickets_count"]}}</strong>
                                        <a href="javascript:void(0)" title="Pending">PENDING</a></p>
                                </div>
                            </mat-card>
                        </div>
                        <div class="col-sm-3 link collapse fleet-card" *ngIf="isAllowed('views','service_request')">
                            <mat-card class="card-default">
                                <div class="infoTile lnk" (click)="getUnderProcessTickets()">
                                    <p><strong id="underProcessTicketsCount"
                                            *ngIf="shopStatistics!=undefined">{{shopStatistics["totalUProcessTickets_count"]}}</strong>
                                        <a href="javascript:void(0)" title="Under Process">UNDER PROCESS</a></p>
                                    <p class="infotile-subtitle" *ngIf="shopStatistics!=undefined">
                                        <a href="javascript:void(0)" (click)="getAwaitedTickets(3)"><span
                                                [innerHTML]="shopStatistics['approvalAwait'] +' AWAITED'" title="Awaited"></span>
                                        </a>/
                                        <a href="javascript:void(0)" (click)="getAwaitedTickets(7)"><span
                                                [innerHTML]="shopStatistics['approvalCmpl']+' AUTHORIZED'"
                                                title="Authorized"></span></a>/
                                        <a href="javascript:void(0)" (click)="getWorkInProgress()"><span
                                                [innerHTML]="shopStatistics['workInprogress'] + ' WIP' "
                                                title="Work in Progress"></span> </a>
                                    </p>
                                </div>
                                <div class="infoTile lnk" (click)="getCompletedTickets()">
                                    <p><strong id="completedTicketsCount"
                                            *ngIf="shopStatistics!=undefined">{{shopStatistics["totalCompletedTickets_count"]}}</strong>
                                        <a href="javascript:void(0)" title="Completed">COMPLETED</a></p>
                                </div>
                            </mat-card>
                        </div>
                        <div class="col-sm-3 link collapse fleet-card" *ngIf="isInternalNotes || isServiceNotes">
                            <mat-card class="card-default">
                                <div class="infoTile lnk" *ngIf="isInternalNotes" (click)="internalNotes()">
                                    <p><strong *ngIf="shopStatistics!=undefined">{{shopStatistics["totalInternalNotes"]}}</strong>
                                        <a href="javascript:void(0)" title="Internal Notes">Internal Notes</a></p>
                                    <p class="infotile-subtitle" *ngIf="shopStatistics!=undefined"><a href="javascript:void(0)"
                                            (click)="getUnreadInternalNote()"
                                            title="Unread">{{shopStatistics["totalInternalNotes_unread_count"]}} UNREAD</a></p>
                                </div>
                                <div class="infoTile lnk" *ngIf="isServiceNotes" (click)="serviceNotes()">
                                    <p><strong *ngIf="shopStatistics!=undefined">{{shopStatistics["totalServiceNotes"]}}</strong> <a
                                            href="javascript:void(0)" title="Service Based Notes">Service Based Notes</a></p>
                                    <p class="infotile-subtitle" *ngIf="shopStatistics!=undefined"><a href="javascript:void(0)"
                                            (click)="getUnreadServiceNote()"
                                            title="Unread">{{shopStatistics["totalServiceNotes_unread_count"]}} UNREAD</a></p>
                                </div>
                            </mat-card>
                        </div>
                        <div class="col-sm-3 link collapse fleet-card"
                            *ngIf="isAllowed('views','complaints') || isAllowed('views','inquiries')">
                            <mat-card class="card-default">
                                <div class="infoTile lnk" (click)="getComplaints()" *ngIf="isAllowed('views','complaints')">
                                    <p><strong id="complaintsCount"
                                            *ngIf="shopStatistics!=undefined">{{shopStatistics["totalShopComplaints_count"]}}</strong>
                                        <a href="javascript:void(0)" title="Messages">MESSAGES</a></p>
                                    <p class="infotile-subtitle" *ngIf="shopStatistics!=undefined"><a href="javascript:void(0)"
                                            (click)="getUnreadComplaints()"
                                            title="Unread">{{shopStatistics["totalShopComplaints_unread_count"]}} UNREAD</a></p>
                                </div>
                                <div class="infoTile lnk" (click)="getInquiries()" *ngIf="isAllowed('views','inquiries')">
                                    <p><strong id="inquiriesCount"
                                            *ngIf="shopStatistics!=undefined">{{shopStatistics["totalShopInquiries_count"]}}</strong>
                                        <a href="javascript:void(0)" title="Live Chat">LIVE CHAT</a></p>
                                    <p id="inquiriesUnreadCount" class="infotile-subtitle" *ngIf="shopStatistics!=undefined"><a
                                            href="javascript:void(0)" (click)="getUnreadInquiries()"
                                            title="Unread">{{shopStatistics["totalShopInquiries_unread_count"]}} UNREAD</a></p>
                                </div>
                            </mat-card>
                        </div>
                        <div class="col-sm-3 link collapse hide">
                            <mat-card class="card-default" (click)="reporting()">
                                <div class="infoTile" href="javascript:void(0);">
                                    <p><a href="javascript:void(0)" title="Reporting">Reporting</a></p>
                                </div>
                            </mat-card>
                        </div>
                        <div class="col-sm-3 link collapse hide">
                            <mat-card class="card-default" (click)="transfer()">
                                <a class="infoTile" href="javascript:void(0);">
                                    <p title="Transfer">Transfer</p>
                                </a>
                            </mat-card>
                        </div>
                    </div>
                </div>
            
            </div>
        </div>
        <!-- fleet dashboard end-->
        <!-- total-count Section start -->
        <div class="row col-sm-12 show-fleet-sm margin-bottom-10" *ngIf="profileType==1">
            <button type="button" (click)="toggleBlocks()" class="display-full font-bold mat-raised-button mat-accent">{{showHideTxt}} Dash Tiles</button>
        </div>
        <div class="total-count-section" id="total-count" *ngIf="profileType==1">
            <div class="row link-merge">
                <div class="col-sm-3 link collapse">
                    <mat-card class="card-default">
                        <div class="infoTile" [class.lnk]="isQuickQuote && isAllowed('views','quick_quote')" (click)="getTotalTickets()">
                            <p><strong *ngIf="shopStatistics!=undefined">{{shopStatistics["totalTickets_count"]}}</strong> <a
                                    href="javascript:void(0)" title="Total Requests">TOTAL REQUESTS</a></p>       
                        </div>
                        <div class="infoTile" [class.lnk]="isQuickQuote && isAllowed('views','quick_quote')" *ngIf="isQuickQuote && isAllowed('views','quick_quote')">
                            <p (click)="quickQuote(1)"><strong
                                    *ngIf="shopStatistics!=undefined">{{shopStatistics["totalQuotes_count"]}}</strong> <a
                                    href="javascript:void(0)" title="Quick Quotes">QUICK QUOTES</a></p>
                            <p (click)="quickQuote(2)" class="infotile-subtitle" *ngIf="shopStatistics!=undefined"><a
                                    href="javascript:void(0)" title="Converted">{{shopStatistics["totalQuotes_converted_count"]}}
                                    CONVERTED</a></p>
                        </div>
                    </mat-card>
                </div>
                <div class="col-sm-3 link collapse" *ngIf="isAllowed('views','service_request')">
                    <mat-card class="card-default" (click)="getNewRequests()">
                        <div class="infoTile" >
                            <p><strong id="totalNewRequestsCount" *ngIf="shopStatistics!=undefined">{{shopStatistics["totalAppointments"]}}</strong> <a href="javascript:void(0);" title="Appointments">APPOINTMENTS</a></p>
                            <p class="infotile-subtitle" *ngIf="shopStatistics!=undefined"><a href="javascript:void(0)" (click)="unreadAppt()" title="Unread">{{shopStatistics["totalAppointments_unread_count"]}} UNREAD</a></p>
                        </div>
                    </mat-card>
                </div>
                <div class="col-sm-3 link collapse" *ngIf="isAllowed('views','service_request')">
                    <mat-card class="card-default" (click)="getPendingTickets()">
                        <div class="infoTile" >
                            <p><strong id="pendingTicketsCount" *ngIf="shopStatistics!=undefined">{{shopStatistics["totalPendTickets_count"]}}</strong> <a href="javascript:void(0);" title="Pending">PENDING</a></p>
                        </div>
                    </mat-card>
                </div>
                <div class="col-sm-3 link collapse" *ngIf="isAllowed('views','service_request')">
                    <mat-card class="card-default" (click)="getUnderProcessTickets()">
                        <div class="infoTile lnk">
                            <p><strong id="underProcessTicketsCount" *ngIf="shopStatistics!=undefined">{{shopStatistics["totalUProcessTickets_count"]}}</strong> <a href="javascript:void(0)" title="Under Process">UNDER PROCESS</a></p>
                            <p class="infotile-subtitle" *ngIf="shopStatistics!=undefined"><a href="javascript:void(0)" (click)="getAwaitedTickets(3)"><span [innerHTML]="shopStatistics['approvalAwait'] +' AWAITED'" title="Awaited"></span> </a>/  <a href="javascript:void(0)" (click)="getAwaitedTickets(7)"><span [innerHTML]="shopStatistics['approvalCmpl']+' AUTHORIZED'" title="Authorized"></span> </a>/ <a href="javascript:void(0)" (click)="getWorkInProgress()"><span [innerHTML]="shopStatistics['workInprogress'] + ' WIP' " title="Work in Progress"></span> </a></p>
                        </div>
                    </mat-card>
                </div>
                <div class="col-sm-3 link collapse" *ngIf="isAllowed('views','service_request')">
                    <mat-card class="card-default" (click)="getCompletedTickets()">
                        <div class="infoTile">
                            <p><strong id="completedTicketsCount" *ngIf="shopStatistics!=undefined">{{shopStatistics["totalCompletedTickets_count"]}}</strong> <a href="javascript:void(0)" title="Completed">COMPLETED</a></p>
                            <p class="infotile-subtitle" *ngIf="shopStatistics!=undefined"><a href="javascript:void(0)" (click)="getCompletedNotPaidTickets()" title="Not Paid">{{shopStatistics["totalUnpaidTickets_count"]}} NOT PAID</a></p>
                       </div>
                    </mat-card>
                </div>
                <div class="col-sm-3 link collapse" *ngIf="isAllowed('views','feedback')">
                    <mat-card class="card-default" (click)="getAllFeedbacks()">
                        <div class="infoTile">
                            <p><strong id="feedbacksCount" *ngIf="shopStatistics!=undefined">{{shopStatistics["totalShopFeedback_count"]}}</strong> <a href="javascript:void(0)" title="Ratings">RATINGS</a></p>
                            <p class="infotile-subtitle" *ngIf="shopStatistics!=undefined"><a href="javascript:void(0)" (click)="getFeedbacks()" title="Unread">{{shopStatistics["totalShopFeedback_unread_count"]}} UNREAD</a></p>
                        </div>
                    </mat-card>
                </div>
                <div class="col-sm-3 link collapse"  *ngIf="isAllowed('views','complaints') || isAllowed('views','inquiries')">
                    <mat-card class="card-default">
                        <div class="infoTile lnk" (click)="getComplaints()" *ngIf="isAllowed('views','complaints')">
                            <p><strong id="complaintsCount" *ngIf="shopStatistics!=undefined">{{shopStatistics["totalShopComplaints_count"]}}</strong> <a href="javascript:void(0)" title="Messages">MESSAGES</a></p>
                            <p class="infotile-subtitle" *ngIf="shopStatistics!=undefined"><a href="javascript:void(0)" (click)="getUnreadComplaints()" title="Unread">{{shopStatistics["totalShopComplaints_unread_count"]}} UNREAD</a></p>
                        </div>
                        <div class="infoTile lnk" (click)="getInquiries()" *ngIf="isAllowed('views','inquiries')">
                            <p><strong id="inquiriesCount" *ngIf="shopStatistics!=undefined">{{shopStatistics["totalShopInquiries_count"]}}</strong> <a href="javascript:void(0)" title="Live Chat">LIVE CHAT</a></p>
                            <p id="inquiriesUnreadCount" class="infotile-subtitle" *ngIf="shopStatistics!=undefined"><a href="javascript:void(0)" (click)="getUnreadInquiries()" title="Unread">{{shopStatistics["totalShopInquiries_unread_count"]}} UNREAD</a></p>
                        </div>
                    </mat-card>
                </div>
                
                <div class="col-sm-3 link collapse" *ngIf="isInternalNotes || isServiceNotes">
                    <mat-card class="card-default">
                        <div class="infoTile" *ngIf="isInternalNotes" [class.lnk]="isInternalNotes && isServiceNotes" (click)="internalNotes()">
                            <p><strong *ngIf="shopStatistics!=undefined">{{shopStatistics["totalInternalNotes"]}}</strong> <a
                                    href="javascript:void(0)" title="Internal Notes">Internal Notes</a></p>
                            <p class="infotile-subtitle" *ngIf="shopStatistics!=undefined"><a href="javascript:void(0)"
                                    (click)="getUnreadInternalNote()" title="Unread">{{shopStatistics["totalInternalNotes_unread_count"]}} UNREAD</a></p>
                        </div>
                        <div class="infoTile" [class.lnk]="isInternalNotes && isServiceNotes" *ngIf="isServiceNotes" (click)="serviceNotes()">
                            <p><strong *ngIf="shopStatistics!=undefined">{{shopStatistics["totalServiceNotes"]}}</strong> <a href="javascript:void(0)" title="Service Based Notes">Service Based Notes</a></p>
                            <p class="infotile-subtitle" *ngIf="shopStatistics!=undefined"><a href="javascript:void(0)" (click)="getUnreadServiceNote()" title="Unread">{{shopStatistics["totalServiceNotes_unread_count"]}} UNREAD</a></p>
                        </div>
                     </mat-card>
                </div>
            </div>
        </div>
        <!-- total-count Section end -->
        <!-- Easy Check In Section start-->
        <div class="row text-center padding-top-25" *ngIf="isAllowed('addNew','check_in')">
            <div class="col-sm-12">
                <form [formGroup]="easyCheckin" (ngSubmit)="formEasyCheckIn()" novalidate class="form-inline search-option" id="formEasyCheckIn">
                    <div class="form-group heading-title">
                        <label class="fontcolor-blue" for="">Easy Check In</label>
                    </div>
                    <div class="form-group easy-checkin">
                        <div class="uc-ngx-select car-input">
                            <ngx-select class="margin-bottom-20" *ngIf="isAllowed('views','view_customer_info')==true"  [items]="selectlists" formControlName="search_by" (keyup)="checkIfValid()"
                            placeholder="Select" optionTextField="name" (select)="onSelectListChange($event)">
                            </ngx-select>
                            <ngx-select class="margin-bottom-20" *ngIf="isAllowed('views','view_customer_info')==false"  [items]="customerInfoList" formControlName="search_by" (keyup)="checkIfValid()"
                            placeholder="Select" optionTextField="name" (select)="onSelectListChange($event)">
                            </ngx-select>
                            <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
                            <mat-error *ngIf="easyCheckin.controls.search_by.hasError('required')">
                                    Select field is <strong>required</strong>
                            </mat-error>
                            </div>
                        </div>

                        <mat-form-field class="car-input " floatPlaceholder="never" hideRequiredMarker="false" *ngIf="!licensePlate" appearance="fill">
                            <input matInput placeholder="search keyword" title="Search Keyword" formControlName="search_keyword"  class="form-control input-lg custom-form-input" (keyup)="checkIfValid()" />
                            <mat-error *ngIf="easyCheckin.controls.search_keyword.hasError('required')">
                                Search keyword is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="car-input " floatPlaceholder="never" hideRequiredMarker="false" *ngIf="licensePlate">
                            <input matInput placeholder="Last Name" title="Last Name" formControlName="search_keyword"  class="form-control input-lg custom-form-input" (keyup)="checkIfValid()" />
                            <mat-error *ngIf="easyCheckin.controls.search_keyword.hasError('required')">
                                search keyword is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="car-input " floatPlaceholder="never" hideRequiredMarker="false" *ngIf="licensePlate">
                            <input matInput placeholder="License Plate" title="License Plate" formControlName="search_keyword2"  class="form-control  custom-form-input" (keyup)="checkIfValid()">
                            <mat-error *ngIf="easyCheckin.controls.search_keyword2.hasError('required')">
                                license plate is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    <span class="text-left">
                        <button type="submit" mat-raised-button id="btnEasyChkinForm" class="car-btn btn-block text-uppercase btn-lg search-btn font-bold fontcolor-white" color="accent" title="Submit">
                            <i class="fa fa-search"></i>
                        </button>
                    </span>
                </div>
            </form>
        </div>
    </div>
    <!-- Easy Check In Section end-->

    <!-- dashboard topnav link section start-->
    <div class="container-fluid padding-0" *ngIf="profileType==1">
        <mat-tab-group class="dashboard-topnav-links" headerPosition="below" *ngIf="isAllowed('addNew','customer') || isAllowed('views','customer') || isAllowed('addNew','part') || isAllowed('addNew','generic_job') || isAllowed('views','service_request')">
            <mat-tab label="Quick Actions" showPaginationControls="true" *ngIf="isAllowed('addNew','customer') || isAllowed('views','customer') ||isAllowed('addNew','part') || isAllowed('addNew','generic_job')">
                <!-- Quick actions links -->
                <div class="col-sm-12 dashboard-top-menu active" id="quickActionsContent">
                    <div class="row text-center dashboard-circle-links">
                        <div class="col-xs-6 col-sm-3 col-md-2" *ngIf="isAllowed('addNew','customer')">
                            <a href="javascript:void(0);" class="dashboard-circle" title="Add New Customer" (click)="addNewCustomer()">
                                <span class="inner-circle"><i class="fa fa-plus"></i></span>
                                <span class="btn-block">New Customer</span>
                            </a>
                        </div>
                        <div class="col-xs-6 col-sm-3 col-md-2" *ngIf="isAllowed('views','customer')" >
                            <a href="javascript:void(0);" class="dashboard-circle" title="View Customers" (click)="viewCustomers()">
                                <span class="inner-circle"><i class="fa fa-users"></i></span>
                                <span class="btn-block">View Customers</span>
                            </a>
                        </div>
                        <div class="col-xs-6 col-sm-3 col-md-2" *ngIf="isAllowed('addNew','service_request')" >
                            <a href="javascript:void(0);" class="dashboard-circle" title="Quick SR" (click)="handleQuickSRCreation()">
                                <span class="inner-circle"><i class="fa fa-plus-circle"></i></span>
                                <span class="btn-block" >Quick SR</span>
                            </a>
                        </div>
                        <div class="col-xs-6 col-sm-3 col-md-2" *ngIf="isAllowed('views','service_request')">
                            <a href="javascript:void(0);"  class="dashboard-circle" title="Job Board" (click)="navigateToJobBoard()">
                                <span class="inner-circle img-flex"><img class="width-50" src="../assets/img/workflow-black.svg"></span>
                                <span class="btn-block" >Job Board</span>
                            </a>
                        </div>
                    </div>
                </div>
                <!-- Quick actions links -->
            </mat-tab>
            <mat-tab label="Service Requests" *ngIf="isAllowed('views','service_request')">
                <!-- Service Requests links -->
                <div class="col-sm-12 dashboard-top-menu" id="serviceRequestsContent">
                    <div class="row text-center dashboard-circle-links">
                        <div class="col-xs-6 col-sm-3 col-md-2">
                            <a href="javascript:void(0);"  class="dashboard-circle" title="Appointments" (click)="getNewRequests()">
                                <span class="inner-circle">
                                    <span class="fa-stack fa-lg">
                                        <i class="fa fa-certificate fa-stack-2x"></i>
                                        <i style="color:#ffffff; font-size:9px;line-height:31px;font-weight:bold;" class="fa fa-stack-1x">New</i>
                                    </span>
                                </span>
                                <span class="btn-block">Appointments</span>
                            </a>
                        </div>
                        <div class="col-xs-6 col-sm-3 col-md-2">
                            <a href="javascript:void(0);" class="dashboard-circle" title="Fill Inspection Reports" (click)="getUnderProcessTickets()">
                                <span class="inner-circle"><i class="fa fa-book"></i></span>
                                <span class="btn-block">Fill Inspection Reports</span>
                            </a>
                        </div>
                        <div class="col-xs-6 col-sm-3 col-md-2">
                            <a href="javascript:void(0);" class="dashboard-circle" title="Current Inspection Reports" (click)="getAwaitedTickets(5)">
                                <span class="inner-circle"><i class="fa fa-file-text"></i></span>
                                <span class="btn-block" >Current&nbsp;Inspection Reports</span>
                            </a>
                        </div>
                        <div class="col-xs-6 col-sm-3 col-md-2">
                            <a href="javascript:void(0);" class="dashboard-circle" title="Completed Requests" (click)="getCompletedNotPaidTickets()">
                                <span class="inner-circle"><i class="fa fa-history"></i></span>
                                <span class="btn-block">Completed&nbsp;Requests</span>
                            </a>
                        </div>
                    </div>
                </div>
                <!-- Service Requests links -->
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
</div>

    <!--*ngIf="isAllowed('views','shop_acc_info')"-->

<div class="panel panel-primary margin-top-20" *ngIf="isAllowed('views','shop_acc_info')">
    <div class="well well-lg address-detail-section">
        <a href="javascript:void(0);"  *ngIf="isAllowed('edits','shop_acc_info')" (click)="editProfile()" class="btn-edit"><i class="fa fa-lg fa-edit fa-fw"></i>Edit</a>
        <address *ngIf="shopDetails!=undefined" class="notranslate">
            <h4 class="break-word"><strong>{{shopDetails["company_name"]}}</strong></h4>
            <span class="break-word">{{shopDetails["address1"]}}</span><span class="break-all" *ngIf="shopDetails['address2']!=''">, {{shopDetails["address2"]}}</span>
            <br><span class="inline-display">{{shopDetails["city"]}}<span *ngIf="shopDetails['state']">,</span></span>
            <span class="inline-display"> {{shopDetails["state"]}}<span *ngIf="shopDetails['zip']">,</span></span>
            <span class="inline-display"> {{shopDetails["zip"]}}</span>
            <br>{{shopDetails["country"]}}<br>
            <span title="Phone">P:</span>
            <a class="text-black" href="tel:{{shopDetails['business_phone1']}}" [innerHTML]='shopDetails["business_phone1"]'></a> <span *ngIf="shopDetails['business_phone2']">,&nbsp;</span>
            <a class="text-black" href="tel:{{shopDetails['business_phone2']}}" [innerHTML]='shopDetails["business_phone2"]'></a><br>
            <div *ngIf='shopDetails["cell_phone1"]!=null && shopDetails["cell_phone2"] !=null'>
            <span title="Mobile">M:</span>
            <a class="text-black" href="tel:{{shopDetails['cell_phone1']}}" [innerHTML]='shopDetails["cell_phone1"]'></a> <span *ngIf="shopDetails['cell_phone2']">,&nbsp;</span> <a href="tel:{{shopDetails['cell_phone2']}}" [innerHTML]='shopDetails["cell_phone2"]'></a>
          </div>
        </address>
        <address *ngIf="shopDetails != undefined" class="margin-bottom-0">
            <strong class="break-word">{{shopDetails["owner_name"]}}</strong><br><a href="mailto:{{shopDetails['email_address']}}" class="break-word">{{shopDetails["email_address"]}}</a>
            <br><a href="{{shopDetails['web_address']}}" class="break-word" target="_blank">{{shopDetails["web_address"]}}</a>
            <h4 class="ad-title">Timezone : <span [innerHTML]="shopDetails['timezone']"></span></h4>
            <!-- <h4><span >Shop Timing :</span> <span [innerHTML]="openingTime"></span> </h4> -->
            <h4><span >Shop Timing </span> </h4> 
            <mat-chip-list *ngIf="shopDetails.shop_timeline">
                <ng-container *ngFor="let timeline of shopDetails.shop_timeline;let i=index" >
                    <mat-chip *ngIf="timeline.is_checked==1">                        
                            <ng-container [ngSwitch]="timeline.weekday_id">
                                <label *ngSwitchCase="0">Monday</label>
                                <label *ngSwitchCase="1">Tuesday</label>
                                <label *ngSwitchCase="2">Wednesday</label>
                                <label *ngSwitchCase="3">Thursday</label>
                                <label *ngSwitchCase="4" class="margin-zero">Friday</label>
                                <label *ngSwitchCase="5">Saturday</label>
                                <label *ngSwitchCase="6">Sunday</label>
                            </ng-container>
                            &nbsp;{{ convertTime(timeline.open_time) }} - {{ convertTime(timeline.close_time) }}
                    </mat-chip> 
                </ng-container>              
            </mat-chip-list>
                        
        </address>
    </div>
</div>

<div class="panel panel-primary margin-top-20" *ngIf="roleID!='3' && roleID!='4'">
    <div class="well well-lg address-detail-section">
        <h4><strong>Current Plan Details</strong></h4>
        <!-- if no plan is taking yet i.e shop is newly created  revisit-->
        <!-- <div *ngIf="isSubscribed=='false'" class="subscription-alert-section">
            <mat-toolbar class="subscription-alert-bar margin-bottom-5" (click)="gotoShopSubscription()">
                <div class="subscription-content">Your subscription is over. Please resubscribe to avail facilities.</div>
                <div class="subscription-btn">
                    <button mat-raised-button class="font-bold mat-elevation-z0 margin-right-0" color="accent">
                        <i class="fa fa-money margin-right-5" aria-hidden="true"></i>
                        <span>Subscribe Now</span>
                    </button>
                </div>
            </mat-toolbar>
       </div>  -->

    <!-- if plan is taking yet i.e shop is already take the plan-->
    <div *ngIf="isSubscribed=='true'" class="subscription-alert-section">
        <p  class="margin-0"><span>Plan Name : </span> <span
                [innerHTML]="subscriptionPlanName"></span><label class="text-danger"
                *ngIf="isPlanExpiredOrCancelled == 1 ||isPlanExpiredOrCancelled == 2 ">&nbsp;(Inactive)</label></p>
        <p class="margin-0"><span>Plan Description : </span> <span
                [innerHTML]="subscriptionDesc"></span></p>
        <p class="margin-0"><span>Duration : </span> <span [innerHTML]="subscriptionDuration"></span>
            <ng-container *ngIf="subscriptionDurationType!='days'"> <span *ngIf="subscriptionDuration=='1'"> Month</span>
                <span *ngIf="subscriptionDuration!='1'"> Months</span></ng-container> <span
                *ngIf="subscriptionDurationType=='days'"> Days</span>
        </p>
        <p ><span>Expiry Date : </span> <span [innerHTML]="expiryDate"></span></p>
        <button mat-raised-button class="font-bold mat-elevation-z0" color="accent"
            style="margin-bottom: 5px" (click)="gotoShopSubscription()">
            <span *ngIf="isPlanExpiredOrCancelled == 0">Upgrade Subscription</span>
            <span *ngIf="isPlanExpiredOrCancelled == 2 || isPlanExpiredOrCancelled == 1 ">Renew Subscription</span>
        </button>
        <span *ngIf="isPlanExpiredOrCancelled == 0 && displayCCDetails">
            <button mat-raised-button class="font-bold mat-elevation-z0" color="accent"
                style="margin-bottom: 5px"  (click)="cancelSubscription(shopID)">
                <span>Cancel Subscription</span>
            </button>
        </span>
    </div>
        <div *ngIf="displayCCDetails" class="cc-details-section">
            <h4 class="ad-title"><strong>CC Details</strong></h4>
            <p>CC Number : {{ccMaskedNumber}}</p>
            <p>
               <button mat-raised-button class="font-bold mat-elevation-z0" color="accent" style="margin-bottom: 5px" (click)="updateCCDetails(ccPaymentToken)">
                    <span>Update CC Details</span>
                </button>
            </p>
            <p>
                <strong>Note:</strong> If you want to update your existing CC info. Please click on above "Update CC Details" button and fill all the mandatory fields and update. After Update, all the recurring and new payment will be made using new CC. For your kind information that our system does not save any sensitive/CC information.
            </p>
            <a href="https://www.braintreegateway.com/merchants/nhwv2y5vrvgmgh2y/verified" target="_blank">
                <img class="img-responsive braintree-imgs" src="https://s3.amazonaws.com/braintree-badges/braintree-badge-wide-dark.png"/>
            </a>
        </div>
        <div *ngIf="isSubscribed=='false'" class="subscription-alert-section margin-top-20">
<!--            <p class="margin-0">You don't have any subscription yet.</p>-->
            <mat-toolbar class="subscription-alert-bar" (click)="gotoShopSubscription()">
            <div class="subscription-content">
              You don't have any subscription yet. So please
            </div>
            <div class="subscription-btn">
              <button mat-raised-button class="font-bold mat-elevation-z0" color="accent">
                <i class="fa fa-money margin-right-5" aria-hidden="true"></i>
                <span>Subscribe Now</span>
              </button>
            </div>
          </mat-toolbar>
            <!--<p class="margin-0">Please <a href="https://beta.completeautoreports.com/shops/subscription">Subscribe Now</a>.</p>-->
        </div>
        
    </div>
</div>
</div>
