<div class="panel panel-primary" id="mainCompotents">
    <div class="panel-heading padding-left-15">
        <h3 class="panel-title">{{ currentCustomer.profile_type == '2' ? 'CONTACT' : 'CUSTOMER' }} INFORMATION</h3>
        <button mat-mini-fab class="custom-btn-back" (click)="globalService.goBack();">
           <mat-icon>arrow_back</mat-icon>
        </button>
    </div>
    <div class="panel-body bgcolor-lightgrey">
        <div class="car-datatable mat-elevation-z1">
            <div class="table-responsive relative">
                <form [formGroup]="listCar" class="form-inline signup-form" method="POST" enctype="multipart/form-data" autocomplete="off">
                    <mat-table #table [dataSource]="dataSource" matSort class="datatable-loader-bg uc-table-responsive mat-table">
                        <!-- Color Column -->
                        <ng-container matColumnDef="Name">
                            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="name"> Name </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-role="name" data-val="Name"> {{row.name}} </mat-cell>
                        </ng-container>

                        <!-- Color Column -->
                        <ng-container matColumnDef="Email">
                            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Email </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-role="auto" data-val="Email"> {{(row.email)}} </mat-cell>
                        </ng-container>

                        <!-- Color Colu`mn -->
                        <ng-container matColumnDef="Address">
                            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Address </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-role="auto" data-val="Address">{{row.address !="" ? row.address : "-"}}</mat-cell>
                        </ng-container>

                        <!-- Color Column -->
                        <ng-container matColumnDef="City">
                            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> City </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-role="auto" data-val="City">{{row.city !="" ? row.city : "-"}}</mat-cell>
                        </ng-container>

                        <!-- Color Column -->
                        <ng-container matColumnDef="State">
                            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> State </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-role="auto" data-val="State"> {{row.state !="" ? row.state : "-"}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="Country">
                            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Country </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-role="auto" data-val="Country"> {{row.country !="" ? row.country : "-"}} </mat-cell>
                        </ng-container>


                        <ng-container matColumnDef="Mobile Number">
                            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="phone"> Mobile No. </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-role="phone" data-val="Mobile No."> {{row.mobile !="" ? row.mobile : "-"}} </mat-cell>
                        </ng-container>

                    <!-- Color Column -->
                    <ng-container matColumnDef="Actions">
                        <mat-header-cell *matHeaderCellDef  data-role="button"> Actions </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="button">
                            <button mat-button class="list-action-btn" [matMenuTriggerFor]="menu" disableRipple >
                                Action<mat-icon>arrow_drop_down</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu" class="car-icon-menu" [overlapTrigger]="false">
                                <a mat-menu-item (click) = "newServiceRequestForCustomer(row.id)"><i class="fa fa-wrench"></i>New Service Request</a>
                            </mat-menu>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
            </form>

            <div class="padding-15 text-center"
                 [style.display]="getTotalRecords() < 1 ? '' : 'none'">
                 No Records Available
        </div>
    </div>
    <div class="display-full relative" [style.display]="getTotalRecords() < 1 ? 'none' : ''">
        <mat-paginator class="car-pagination hidden" #paginator [length]="getTotalRecords()" [pageIndex]="0" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" (page)="paginateByLimit($event)" >
        </mat-paginator>

        <div class="mat-paginator table-paging hidden">
            <div class="mat-paginator-range-label margin-left-30 margin-right-30">{{options.start+1}} - {{getUpperLimit(options.start + options.limit,getTotalRecords())}} of {{getTotalRecords()}}</div>
            <button mat-icon-button (click)="paginate($event,'previous');" [disabled]="firstList()">
                    <mat-icon>navigate_before</mat-icon>
            </button>
            <button mat-icon-button (click)="paginate($event,'next');" [disabled]="lastList()">
                    <mat-icon>navigate_next</mat-icon>
            </button>
        </div>
    </div>
</div>
</div>
</div>
