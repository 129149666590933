import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {GlobalService} from '../../../../environments/global.service';
import {globalFunctions} from '../../../../environments/globalFunctions';
import {globalConstants} from '../../../../environments/globalConstants';

@Component({
    selector: 'app-new-customer-type',
    templateUrl: './new-customer-type.component.html',
    styleUrls: ['./new-customer-type.component.scss']
})
export class NewCustomerTypeComponent {

    public newCustomerType: FormGroup;
    constructor(public dialogRef: MatDialogRef<NewCustomerTypeComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder, private globalService: GlobalService) {
        this.newCustomerType = this.formBuilder.group({
            add_customer_type_name: ['', Validators.required],
            add_labor_rate: ['', Validators.required],
            user_type: this.globalService.getCurrentUser()["user_type"],
            user_id: this.globalService.getCurrentUser()["id"]
        });
        this.globalService.diaLogObj(this.dialogRef);
    }
    onlyNumeric(event) {
        return globalFunctions.onlyDecimalNumberKey(event);
    }
    saveNewCustomerType(cusType) {
        console.log("form submit", cusType);
        Object.keys(this.newCustomerType.controls).map((controlName) => {
            this.newCustomerType.get(controlName).markAsTouched({onlySelf: true});
        });
        if (this.newCustomerType.valid) {
            let content = globalFunctions.stringifyFormData(this.newCustomerType.value, null)
            if (cusType == 1) {
                this.dialogRef.disableClose;
                this.callAPIFn(content);
                this.newCustomerType.reset()
                Object.keys(this.newCustomerType.controls).map((controlName) => {
                    this.newCustomerType.get(controlName).reset();
                });
            } else {
                this.callAPIFn(content);
                this.dialogRef.close();
            }
        } else {
            this.globalService.snackbar("error", globalConstants.FORM_SUBMISSION_VALIDATION_WARNING)
        }
    }
    callAPIFn(formData) {
        this.globalService.callAPI(globalConstants.API_ADD_CUSTOMER_TYPE_URL, formData).subscribe((response:any) => {
            if (response.result == 1) {
                this.globalService.snackbar("success", response.message);
            } else {
                this.globalService.snackbar("error", response.message);
            }
        });
    }
}
