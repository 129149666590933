<ng-container *ngIf="!isPopup && !isQuickMode">
  <div id="mainCompotents">
    <div class="main-content" id="dashboard-content">
      <div class="shopaddnewdiscount">
        <div class="panel panel-primary" id="shopaddnewdiscountPanel">
          <div class="panel-heading padding-left-15">
            <h3 class="panel-title">Create Coupon Code</h3>
            <button mat-mini-fab class="custom-btn-back" (click)="globalService.goBack();">
              <mat-icon>arrow_back</mat-icon>
          </button>
          </div>
          <div class="panel-body bgcolor-lightgrey">
            <!-- Add New discount page start -->
            <div id="addNewDiscount" class="row bgcolor-lightgrey form-content">
              <div class="container-fluid">
                <ng-container *ngTemplateOutlet="addCouponFormTemplate"></ng-container>
              </div>
            </div>
            <!-- Add new discount end -->
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!-- Popup view for this component -->
<ng-container *ngIf="isPopup && !isQuickMode">
  <div mat-dialog-title class="relative">
    Create Coupon Code
    <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
      <mat-icon class="notranslate" aria-label="Example icon-button with a heart icon">close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <div class="center-block" style="max-width: 800px;">
      <div id="addNewDiscount" class="row bgcolor-lightgrey form-content">
        <div class="container-fluid">
          <ng-container *ngTemplateOutlet="addCouponFormTemplate"></ng-container>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="inquiry-action relative">
    <div class="row sticky-section">
      <div class="btn-wrap col-sm-2 col-lg-4 col-md-4 col-xs-12 text-right">
        <button mat-raised-button mat-dialog-close class="font-bold">Close</button>
      </div>
      <div class="btn-wrap col-sm-10 col-lg-8 col-xs-12 col-md-8 text-left">
        <button type="button" mat-raised-button class="font-bold mat-success" color="success" title="Switch to Advanced Mode" (click)="toggleViewMode()">Switch to Quick Mode</button>
        <button type="button" mat-raised-button class="font-bold" color="accent" title="Submit & Close" [disabled]="submitted && rulesSubmitted" (click)="addDiscountCoupon(1)">
          Submit &amp; Close
        </button>
        <button type="button" mat-raised-button class="font-bold" color="accent" title="Submit & Add Another" [disabled]="submitted && rulesSubmitted" (click)="addDiscountCoupon(2)">
          Submit &amp; Add Another
        </button>
      </div>
    </div>
  </mat-dialog-actions>
</ng-container>

<!-- Popup view for Quick Mode -->
<ng-container *ngIf="isPopup && isQuickMode">
  <div mat-dialog-title class="relative">
    Create Quick Coupon Code
    <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
      <mat-icon class="notranslate" aria-label="Example icon-button with a heart icon">close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <div class="center-block" style="max-width: 800px;">
      <div id="addNewDiscount" class="row bgcolor-lightgrey form-content">
        <div class="container-fluid">
          <ng-container *ngTemplateOutlet="addCouponFormTemplateQuickMode"></ng-container>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="inquiry-action relative">
    <div class="row sticky-section">
      <div class="btn-wrap col-sm-2 col-lg-4 col-md-4 col-xs-12 text-right">
        <button mat-raised-button mat-dialog-close class="font-bold">Close</button>
      </div>
      <div class="btn-wrap col-sm-10 col-lg-8 col-xs-12 col-md-8 text-left">
        <button type="button" mat-raised-button class="font-bold mat-success" color="success" title="Switch to Advanced Mode" (click)="toggleViewMode()">Switch to Advanced Mode</button>
        <button type="button" mat-raised-button class="font-bold" color="accent" title="Submit & Close" [disabled]="submitted && rulesSubmitted" (click)="addDiscountCoupon(1)">
          Submit &amp; Apply
        </button>
      </div>
    </div>
    <!-- <button type="button" mat-raised-button class="font-bold" color="accent" title="Submit & Add Another" style="max-width: 300px;" [disabled]="submitted && rulesSubmitted" (click)="addDiscountCoupon(2)">
      Submit &amp; Add Another
    </button> -->
  </mat-dialog-actions>
</ng-container>

<ng-template #addCouponFormTemplate>
  <form [formGroup]="shopAddDiscount" novalidate id="addDiscountForm" class="form-inline col-sm-12" role="form" method="POST" enctype="multipart/form-data" (ngSubmit)="addDiscountCoupon(1)">
    <mat-accordion *ngIf="conditionTypes && conditionTypes.length > 0" class="new-request-expension view-request form-parts margin-bottom-10 display-full" displayMode="flat" hideToggle="false" >
      <mat-expansion-panel [expanded]="rulesAccordion" hideToggle="true" id="expansion-rules" >
        <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px" id="expansionHead-rules" (click)="expandAccordion(rulesAccordion);" >
          Rules
        </mat-expansion-panel-header>
        <div class="row">
          <div class="form-group col-sm-12 col-md-12">
            <app-discount-rules #addDiscountRules [rule]="rule" [condition-types]="conditionTypes" [submitted]="rulesSubmitted" (onValid)="areRulesValid($event)" (onRuleRemoved)="updateSubmitted($event)"></app-discount-rules>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion class="new-request-expension view-request form-parts margin-bottom-10 display-full" displayMode="flat" hideToggle="false" >
      <mat-expansion-panel [expanded]="codeDetailsAccordion" hideToggle="true" id="expansion-rules" >
        <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px" id="expansionHead-rules" (click)="expandAccordion(codeDetailsAccordion);" >
          Coupon Code Details
        </mat-expansion-panel-header>
        <div class="col-xs-12 form-block details-input">
          <div class="row">
            <div class="form-group col-md-6 col-sm-12">
              <label for="discount_name">Name<span class="asterisk">*</span></label>
              <div class="relative details-input">
                <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                  <input matInput placeholder="Coupon Name" title="Name" formControlName="discount_name"
                    value="" class=" input-lg custom-form-input" (keydown)="checkIfValid()" required />
                  <mat-error *ngIf="shopAddDiscount.controls.discount_name.hasError('required')">
                    This field cannot be empty
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="form-group col-md-6 col-sm-12">
              <label for="discount_code">Code<span class="asterisk">*</span></label>
              <div class="relative">
                <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                  <input id="discount_code" matInput placeholder="Coupon Code" title="Name" formControlName="discount_code"
                    value="" class=" input-lg custom-form-input" required />
                  <mat-error>
                    <span *ngIf="shopAddDiscount.controls.discount_code.hasError('required')">
                      This field cannot be empty
                    </span>
                    <span *ngIf="shopAddDiscount.controls.discount_code.hasError('couponExists')">
                      This coupon code already exists.
                    </span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-sm-12 col-md-6">
              <label for="start_date">Start Date<span class="asterisk">*</span></label>
              <mat-form-field class="car-input btn-block car-datePicker" floatPlaceholder="never" appearance="fill">
                <input matInput class="form-control input-lg custom-form-input" formControlName="startDateObj" placeholder="Choose a date"
                [min]="minStartDate" (dateChange)="handleStartDateChange();checkIfValid();" [matDatepicker]="picker1" (focus)="picker1.open()" maxlength="25" required onkeydown="return false;">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
                <mat-error *ngIf="shopAddDiscount.controls.startDateObj.hasError('required')">
                  This field cannot be empty
                </mat-error>
              </mat-form-field>
            </div>
            <div class="form-group col-sm-12 col-md-6">
              <label for="end_date">End Date<span class="asterisk">*</span></label>
              <mat-form-field class="car-input btn-block car-datePicker" floatPlaceholder="never" appearance="fill">
                <input matInput class="form-control input-lg custom-form-input" formControlName="endDateObj" placeholder="Choose a date"
                [min]="minEndDate" (dateChange)="checkIfValid();" [matDatepicker]="picker2" (focus)="picker2.open()" maxlength="25" [attr.required]="(this.shopAddDiscount.controls['no_end_date'].value == 1)?'':null"
                  onkeydown="return false;">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
                <mat-error *ngIf="shopAddDiscount.controls.endDateObj.hasError('required')">
                  This field cannot be empty
                </mat-error>
              </mat-form-field>
              <mat-checkbox class="no-end-date" (change)="checkIfValid()" formControlName="no_end_date" value="1">No End Date</mat-checkbox>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion class="new-request-expension view-request form-parts margin-bottom-10 display-full" displayMode="flat" hideToggle="false" >
      <mat-expansion-panel [expanded]="actionAccordion" hideToggle="true" id="expansion-rules" >
        <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px" id="expansionHead-rules" (click)="expandAccordion(actionAccordion);" >
          Action
        </mat-expansion-panel-header>
        <div class="col-xs-12 form-block details-input">
          <div class="row">
            <div class="form-group col-md-6 col-sm-6">
              <label for="type">Type</label>
              <mat-select class="form-control input-lg custom-form-input" formControlName="type" placeholder="Type" (change)="toggleTypeValidation();checkIfValid();">
                <mat-option value="1">Fixed Amount</mat-option>
                <mat-option value="2">Percentage</mat-option>
              </mat-select>
            </div>
            <div class="form-group col-md-6 col-sm-6">
              <label for="type">Value ({{ (shopAddDiscount.get('type').value == 1 ? '$' : '%') }})<span class="asterisk">*</span> </label>
              <div class="relative">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                  <input matInput placeholder="Value" title="Value" formControlName="type_value" value=""
                    class="input-lg custom-form-input" maxlength="8"
                    (keydown)="disableKey($event);checkIfValid()" (keypress)="onlyDecimalNumberKey($event)"
                    required />
                  <mat-error *ngIf="shopAddDiscount.controls.type_value.hasError('required') ">
                    This field cannot be empty
                  </mat-error>
                  <mat-error *ngIf="shopAddDiscount.controls.type_value.invalid  && shopAddDiscount.value.type_value!='' && isNotNumber(shopAddDiscount.value.type_value)">
                    Type value is required (must be a number with 2 decimal places).
                  </mat-error>
                  <mat-error *ngIf="shopAddDiscount.controls.type_value.invalid  && shopAddDiscount.value.type_value!='' && shopAddDiscount.value.type_value < 1">
                    Type value is should be greater than 0.
                  </mat-error>
                  <mat-error *ngIf="shopAddDiscount.controls.type_value.invalid  && shopAddDiscount.value.type_value!='' && shopAddDiscount.value.type_value > 100 && shopAddDiscount.controls['type'].value == 2">
                    Percentage Value should not exceed 100.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="relative" [class.hide]="shopAddDiscount.controls['type'].value == 1">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                  <input matInput placeholder="Maximum Redemption Limit ($)" title="Maximum Redemption Limit" formControlName="max_redemption_limit" value=""
                    class="input-lg custom-form-input" maxlength="8"
                    (keydown)="disableKey($event);checkIfValid()" (keypress)="onlyDecimalNumberKey($event)"
                    [attr.required]="(shopAddDiscount.controls['type'].value == 2)?true:null" />
                  <mat-error *ngIf="shopAddDiscount.controls.max_redemption_limit.hasError('required') ">
                    This field cannot be empty
                  </mat-error>
                  <mat-error *ngIf="shopAddDiscount.controls.max_redemption_limit.invalid  && shopAddDiscount.value.max_redemption_limit!='' && isNotNumber(shopAddDiscount.value.max_redemption_limit)">
                    Maximum redemption limit is required (must be a number with 2 decimal places).
                  </mat-error>
                  <mat-error *ngIf="shopAddDiscount.controls.max_redemption_limit.invalid  && shopAddDiscount.value.max_redemption_limit!='' && shopAddDiscount.value.max_redemption_limit < 1">
                    Type value is should be greater than 0.
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-6 col-sm-6">
              <label for="type">Usage Type</label>
              <mat-select class="form-control input-lg custom-form-input" formControlName="multiple_usage" placeholder="Type" (change)="toggleUsageValidation();checkIfValid();">
                <mat-option value="1">One Time</mat-option>
                <mat-option value="2">Multiple Times</mat-option>
              </mat-select>
            </div>
            <div class="col-md-12 col-sm-12">
              <div class="customer-info" *ngIf="isPopup && selectedCustomer">
                <span class="help-block text-red">This coupon is only applicable for {{ selectedCustomer.label }}</span>
              </div>
            </div>
          </div>
          <div class="row" [class.hide]="shopAddDiscount.controls['multiple_usage'].value != 2">
            <div class="form-group col-md-6 col-sm-12 center">
              <label for="usage_limit">Total no. of usages</label>
              <div class="relative">
                <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                  <input matInput placeholder="Total no. of usages" title="Total no. of usages"
                    formControlName="usage_limit" class=" input-lg custom-form-input"
                    (keydown)="disableKey($event);checkIfValid();" (keypress)="onlyNumberKey($event);" />
                  <mat-error *ngIf="shopAddDiscount.controls.usage_limit.hasError('required')">
                    This field cannot be empty
                  </mat-error>
                  <mat-error *ngIf="shopAddDiscount.controls.usage_limit.invalid  && shopAddDiscount.value.usage_limit!='' && shopAddDiscount.value.usage_limit < 1">
                    Type value is should be greater than 0.
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="form-group col-md-6 col-sm-12">
              <label for="usage_per_customer_limit">Total no. of usages per person</label>
              <div class="relative">
                <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                  <input matInput placeholder="Total no. of usages per person" title="Total no. of usages per person"
                    formControlName="usage_per_customer_limit" class=" input-lg custom-form-input" (keydown)="disableKey($event);checkIfValid();"
                    (keypress)="onlyNumberKey($event);" />
                  <mat-error *ngIf="shopAddDiscount.controls.usage_per_customer_limit.hasError('required')">
                    This field cannot be empty
                  </mat-error>
                  <mat-error *ngIf="shopAddDiscount.controls.usage_per_customer_limit.invalid  && shopAddDiscount.value.usage_per_customer_limit!='' && shopAddDiscount.value.usage_per_customer_limit < 1">
                    Type value is should be greater than 0.
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-6 col-sm-12">
              <label for="discountStatus">Status</label>
              <mat-select class="form-control input-lg custom-form-input"
                [(value)]="discountStatus" placeholder="Status">
                  <mat-option *ngFor="let opt of statusList" [value]="opt.id">{{opt.text}}</mat-option>
              </mat-select>
            </div>
            <div class="form-group col-md-6 col-sm-12">
              <label for="is_combinational">Can be used with other coupon(s)</label>
              <mat-select class="form-control input-lg custom-form-input" placeholder="Can be used with other coupon(s)" formControlName="is_combinational">
                <mat-option [value]="'0'">No</mat-option>
                <mat-option [value]="'1'">Yes</mat-option>
              </mat-select>
            </div>
          </div>
          <div [ngClass]="{'row':true, 'hide':isPopup}" *ngIf="customerData && customerData.length > 0">
            <div class="form-group col-md-6 col-sm-12 uc-ngx-select">
              <label for="customers">Customer(s)</label>
              <ngx-select class="display-full mb-50 select-customer-dropdown" [items]="customerData" formControlName="customers" placeholder="Select Customer(s)" optionValueField="id" optionTextField="label" [multiple]="true" [autoClearSearch]="true">
                <!-- <ng-template ngx-select-option ngx-select-option-selected let-option>
                  {{option.data.name}}({{option.data.email}})
                </ng-template> -->
              </ngx-select>
            </div>
          </div>
          <div [ngClass]="{'row':true, 'hide':!showApplyOnClose}" *ngIf="isPopup">
            <div class="form-group col-md-6 col-sm-12">
              <mat-checkbox class="no-end-date" formControlName="to_apply" value="1">Apply on close</mat-checkbox>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <div class="row" *ngIf="!isPopup">
      <div class="col-xs-12 form-block submit-block">
        <button type="submit" mat-raised-button
          class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white" color="accent"
          title="Submit & Close" style="max-width: 300px;" [disabled]="submitted && rulesSubmitted">
          SUBMIT &amp; CLOSE
        </button>
        <button type="button" mat-raised-button
          class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white" color="accent"
          title="Submit & Add Another" style="max-width: 300px;" [disabled]="submitted && rulesSubmitted"
          (click)="addDiscountCoupon(2)">
          SUBMIT &amp; ADD ANOTHER
        </button>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #addCouponFormTemplateQuickMode>
  <form [formGroup]="shopAddDiscount" novalidate id="addDiscountForm" class="form-inline col-sm-12" role="form" method="POST" enctype="multipart/form-data" (ngSubmit)="addDiscountCoupon(1)">
    <mat-accordion *ngIf="conditionTypes && conditionTypes.length > 0" class="new-request-expension view-request form-parts margin-bottom-10 display-full hide" displayMode="flat" hideToggle="false" >
      <mat-expansion-panel [expanded]="rulesAccordion" hideToggle="true" id="expansion-rules" >
        <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px" id="expansionHead-rules" (click)="expandAccordion(rulesAccordion);" >
          Rules
        </mat-expansion-panel-header>
        <div class="row">
          <div class="form-group col-sm-12 col-md-12">
            <app-discount-rules #addDiscountRules [rule]="rule" [condition-types]="conditionTypes" [submitted]="rulesSubmitted" (onValid)="areRulesValid($event)" (onRuleRemoved)="updateSubmitted($event)"></app-discount-rules>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <div class="col-xs-12 form-block">
      <div class="row">
        <div class="form-group col-md-6 col-sm-12">
          <label for="discount_code">Code</label>
          <div class="relative details-input">
            <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
              <input id="discount_code" matInput placeholder="Coupon Code" title="Name" formControlName="discount_code"
                value="" class=" input-lg custom-form-input" required />
              <mat-error>
                <span *ngIf="shopAddDiscount.controls.discount_code.hasError('required')">
                  This field cannot be empty
                </span>
                <span *ngIf="shopAddDiscount.controls.discount_code.hasError('couponExists')">
                  This coupon code already exists.
                </span>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="form-group col-sm-12 col-md-6">
          <label for="end_date">End Date</label>
          <mat-form-field class="car-input btn-block car-datePicker" floatPlaceholder="never" appearance="fill">
            <input matInput class="form-control input-lg custom-form-input" formControlName="endDateObj" placeholder="Choose a date"
            [min]="minEndDate" (dateChange)="checkIfValid();" [matDatepicker]="picker2" (focus)="picker2.open()" maxlength="25" [attr.required]="(this.shopAddDiscount.controls['no_end_date'].value == 1)?'':null"
              onkeydown="return false;">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
            <mat-error *ngIf="shopAddDiscount.controls.endDateObj.hasError('required')">
              This field cannot be empty
            </mat-error>
          </mat-form-field>
          <mat-checkbox class="no-end-date" (change)="checkIfValid()" formControlName="no_end_date" value="1">No End Date</mat-checkbox>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6 col-sm-6">
          <label for="type">Type</label>
          <mat-select class="form-control input-lg custom-form-input" formControlName="type" placeholder="Type" (change)="toggleTypeValidation();checkIfValid();">
            <mat-option value="1">Fixed Amount</mat-option>
            <mat-option value="2">Percentage</mat-option>
          </mat-select>
        </div>
        <div class="form-group col-md-6 col-sm-6">
          <label for="type">Value ({{ (shopAddDiscount.get('type').value == 1 ? '$' : '%') }}) </label>
          <div class="relative details-input">
            <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
              <input matInput placeholder="Value" title="Value" formControlName="type_value" value=""
                class="input-lg custom-form-input" maxlength="8"
                (keydown)="disableKey($event);checkIfValid()" (keypress)="onlyDecimalNumberKey($event)"
                required />
              <mat-error *ngIf="shopAddDiscount.controls.type_value.hasError('required') ">
                This field cannot be empty
              </mat-error>
              <mat-error *ngIf="shopAddDiscount.controls.type_value.invalid  && shopAddDiscount.value.type_value!='' && isNotNumber(shopAddDiscount.value.type_value)">
                Type value is required (must be a number with 2 decimal places).
              </mat-error>
              <mat-error *ngIf="shopAddDiscount.controls.type_value.invalid  && shopAddDiscount.value.type_value!='' && shopAddDiscount.value.type_value < 1">
                Type value is should be greater than 0.
              </mat-error>
              <mat-error *ngIf="shopAddDiscount.controls.type_value.invalid  && shopAddDiscount.value.type_value!='' && shopAddDiscount.value.type_value > 100 && shopAddDiscount.controls['type'].value == 2">
                Percentage Value should not exceed 100.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="relative details-input" [class.hide]="shopAddDiscount.controls['type'].value == 1">
            <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
              <input matInput placeholder="Maximum Redemption Limit ($)" title="Maximum Redemption Limit" formControlName="max_redemption_limit" value=""
                class="input-lg custom-form-input" maxlength="8"
                (keydown)="disableKey($event);checkIfValid()" (keypress)="onlyDecimalNumberKey($event)"
                [attr.required]="(shopAddDiscount.controls['type'].value == 2)?true:null" />
              <mat-error *ngIf="shopAddDiscount.controls.max_redemption_limit.hasError('required') ">
                This field cannot be empty
              </mat-error>
              <mat-error *ngIf="shopAddDiscount.controls.max_redemption_limit.invalid  && shopAddDiscount.value.max_redemption_limit!='' && isNotNumber(shopAddDiscount.value.max_redemption_limit)">
                Maximum redemption limit is required (must be a number with 2 decimal places).
              </mat-error>
              <mat-error *ngIf="shopAddDiscount.controls.max_redemption_limit.invalid  && shopAddDiscount.value.max_redemption_limit!='' && shopAddDiscount.value.max_redemption_limit < 1">
                Type value is should be greater than 0.
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>