import {Component, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {AbstractControl, FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import {Router, ActivatedRoute, ParamMap, NavigationEnd, Params} from '@angular/router';
import {Http, Headers, Response} from '@angular/http';
import {HttpHeaders} from '@angular/common/http';
import {globalFunctions} from "../../../environments/globalFunctions"
import {globalConstants} from "../../../environments/globalConstants"
import {GlobalService} from "../../../environments/global.service"
import {lang} from "../../../environments/lang-en"
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {TextMaskModule} from 'angular2-text-mask';
const EMAIL_REGEX = globalConstants.EMAIL_REGEX;
import { NgxSelectModule, INgxSelectOptions } from 'ngx-select-ex';
declare var jQuery:any;
declare var $ :any;
@Component({
    selector: 'app-edituserprofile',
    templateUrl: './edituserprofile.component.html',
    styleUrls: ['./edituserprofile.component.scss']
})
export class EdituserprofileComponent implements OnInit {
    public isCollapsed: boolean = true;
    public editUserProfile: FormGroup;
    public currentUserDetails: any={};
    // public defaultCountry :any;
    public states: any = [];
    public cities: any = [];
    public countries :any = [];
    public selectedCountry:any
    public selectedState :any;
    public selectedCity:any
    public disableStates;
    public disableCities;
    public base64value = "";
    public selectedKey = "";
    public phoneMask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    public taxIncluded: boolean;
    public supplyChargesIncluded: boolean;
    public currentUserInfo;
    public customerTypesOptions;
    public defaultCustomerType;
    public languages=[];
    public defaultLang:any ='English';
    public customerGrid=1
    public roleId=0;
    public additionalColumn = [
        { 'text': 'Req. Id','default':true, 'pos': 0, 'id': 'request_id' },
        { 'text': 'Title','default':true, 'pos': 1, 'id': 'title','before':'business_name','after':'request_id' },
        { 'text': 'Account','default':true, 'pos': 2, 'id': 'business_name','before':'vehicle','after':'title' },
        { 'text': 'Vehicle','default':true, 'pos': 3, 'id': 'vehicle','before':'appointment_date','after':'business_name' },
        { 'text': 'App. Date','default':true, 'pos': 4, 'id': 'appointment_date','before':'service_date','after':'vehicle' },
        { 'text': 'Service Date','default':true, 'pos': 5, 'id': 'service_date','before':'estimated_delivery_date','after':'appointment_date' },
        { 'text': 'Est. Delivery Date','default':true, 'pos': 6, 'id': 'estimated_delivery_date','before':'unit_number','after':'service_date' },
        { 'text': 'Unit Number','default':false, 'pos': 7, 'id': 'unit_number','before':'status','after':'estimated_delivery_date'  },
        { 'text': 'On The Lot','default':false, 'pos': 8, 'id': 'on_the_lot','before':'license_plate','after':'unit_number'  },
        { 'text': 'License Plate','default':false, 'pos': 9, 'id': 'license_plate','before':'status','after':'on_the_lot'  },
        { 'text': 'VIN','default':false, 'pos': 10, 'id': 'vin','before':'license_plate' },
    ];
    public preSelectCol=[]
    public zipLabel = 'Zip Code';
    public subscriptionDataForAPI: Subscription = new Subscription();
 /**
   * @author Vikas Kumar custom Column
   * @trackID <:CAR-1388>
   * @created 21-04-2020
   */
    constructor(
        private formBuilder: FormBuilder, 
        private route: ActivatedRoute, 
        public titleService: Title, 
        private router: Router, 
        public globalService: GlobalService
        ) {

        let userInfo = JSON.parse(localStorage.getItem("loggedInUser"))
        if (userInfo) {
            this.getCountries();
            this.currentUserInfo = userInfo;
            this.roleId          = this.currentUserInfo.role_id        
            this.globalService.setMetaData("SHOPS","EDIT_MY_PROFILE")
        } else {
            this.router.navigate(["/shops/login"]);
        }
    }

    /**
    * update Zip codee validation
    */
    updateZipCodeVailation(){
         this.editUserProfile.get("country").valueChanges.subscribe(country_name => {                
            if(country_name && Array.isArray(this.countries)){                
                let zRegex = this.countries.find(res=>(res.country_id==country_name || res.country_name==country_name));
                if(zRegex && 'zipcode_regex' in zRegex){
                    this.zipLabel = zRegex.zipcode_label;
                    this.editUserProfile.controls.zipcode.setValidators([Validators.required, Validators.pattern(zRegex.zipcode_regex)]);
                    this.editUserProfile.controls.zipcode.updateValueAndValidity();
                }          
            }                
        });
    }
 /**
   * @author Vikas Kumar custom Column
   * @trackID <:CAR-1388>
   * @created 21-04-2020
   */
    getUserDetails(){
        var shopContent = "user_id=" + this.globalService.getCurrentUser()["id"] + "&user_type=" + this.globalService.getCurrentUser()["user_type"];
        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_USER_DETAIL_URL, shopContent).subscribe((data:any) => {
            if(data.result=="1"){
            this.currentUserDetails = data.data;
            // this.defaultCountry = this.currentUserDetails.country;
            this.selectedCity = this.currentUserDetails.city;
            this.defaultLang=this.currentUserDetails.current_lang;
            this.editUserProfile.controls.enable_language.setValue(data.data.lang_enable);
            this.editUserProfile.controls.lang_enable.setValue(data.data.lang_enable);
            this.editUserProfile.controls.current_lang.setValue(data.data.current_lang);
            this.editUserProfile.controls.original_language.setValue(data.data.original_lang);
            this.editUserProfile.controls.enable_report.setValue(Number(data.data.is_report_generation));
            /* SET COUNTRY ID TO GET STATES */
            let selectedCountryForForm = 'USA';
            this.selectedCountry = 'USA';
            if(this.currentUserDetails.country != "") {
                //this.selectedCountry = this.currentUserDetails.country_id+"|"+this.currentUserDetails.country;
                this.selectedCountry = this.currentUserDetails.country_id;
                selectedCountryForForm = this.currentUserDetails.country;
            } 
            this.editUserProfile.controls.country.setValue(selectedCountryForForm);
            this.getStates(this.selectedCountry);

            /* SET STATE ID TO GET CITIES */
            if(this.currentUserDetails.state != "") {
                //this.selectedState = this.currentUserDetails.state_id+"|"+this.currentUserDetails.state;
                this.selectedState = this.currentUserDetails.state_id;
            } else {
                this.selectedState = "";
            }
            this.editUserProfile.controls.state.setValue(this.currentUserDetails.state);
            this.getCities(this.selectedState);
            this.editUserProfile.controls.city.setValue(this.currentUserDetails.city);
            let col=this.currentUserDetails.service_list_items;
            if(col.length>0){
                let custCol=col.split(',')
                custCol.forEach((r,v)=>{
                    this.preSelectCol.push(r);
                })
            }else{
                this.editUserProfile.controls.service_list_items.setValue('');
            }
            if(typeof this.currentUserDetails.listing_view!='undefined'){
                this.customerGrid=this.currentUserDetails.listing_view
                this.editUserProfile.controls.listing_view.setValue(this.currentUserDetails.listing_view)
            }
          }
        /* END */
        });
    }

    ngOnInit() {
        this.editUserProfile = this.formBuilder.group({
        email: [{value: this.currentUserInfo.email, disabled: true}, [Validators.required, Validators.pattern(EMAIL_REGEX)]],
        first_name: ['', [Validators.required,Validators.pattern(globalConstants.NAME_REGEX)]],
        middle_name: ['',Validators.pattern(globalConstants.NAME_REGEX)],
        last_name: ['', [Validators.required,Validators.pattern(globalConstants.NAME_REGEX)]],
        address_one: ['',Validators.required],
        address_two: [''],
        city: ['',Validators.required],
        state: ['',Validators.required],
        zipcode: ['', [Validators.required, Validators.pattern("^[0-9]+$")]],
        country: [''],
        cell_phone1: ['',[Validators.required,Validators.pattern(globalConstants.MOBILE_REGEX)]],
        user_type: this.globalService.getCurrentUser()["user_type"],
        user_id: this.globalService.getCurrentUser()["id"],
        lang_enable:[''],
        current_lang:[''],
        enable_language:[''],
        original_language:[''],
        listing_view:[],
        service_list_items:[],
        enable_report:0
       });  
        this.getUserDetails();
        this.getLang();
    }

    
    // /* Home Phone Validity Check */ not using remove when cleanup
    // checkHomePhoneValidity() {
    //     let home_phone = this.editUserProfile.value.cell_phone1;
    //      if(home_phone !="") {
    //         let phoneResult = this.editUserProfile.value.cell_phone1.replace(/\D+/g, "").length >= 10 ? false : {minLength: true};
    //         if(phoneResult == false) { 
    //             this.editUserProfile.controls.cell_phone1.setErrors(null);
    //             this.submitted = false;
    //         } else { 
    //             this.editUserProfile.controls.cell_phone1.setErrors({"phoneError": true});
    //         }
    //     } else { 
    //         this.editUserProfile.controls.cell_phone1.setErrors(null);
    //         this.submitted = false;
    //     }    
    // }
    
    editProfile() {
        this.stateValidation();
        if (this.editUserProfile.valid) {
            var stringifiedFormData = globalFunctions.stringifyFormData(this.editUserProfile.value, null);
            this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_EDIT_USER_PROFILE_URL, stringifiedFormData).subscribe((data:any) => {
                var resultData = data;
                if (resultData.result == 1) {
                    let user=JSON.parse(localStorage.getItem('loggedInUser'));
                    user.lang_enable=resultData.data.lang_enable;
                    user.current_lang=resultData.data.current_lang;
                    user.original_lang=resultData.data.original_lang;
                    localStorage.setItem('loggedInUser',JSON.stringify(user));
                    this.globalService.snackbar('success', resultData.message);
                    this.router.navigate(['/shops/dashboard']);
                } else {
                    this.globalService.snackbar('error', resultData.message);
                }
            });
        } else {
            this.globalService.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
        }
    }

    getCountries() {
        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_COUNTRIES_LIST, "opr=1").subscribe((data: any) => {
            if (data.result == "1") {
                this.countries = data.data;
                for (var i in this.countries) {
                    this.countries[i].id = this.countries[i].country_id;
                    this.countries[i].text = this.countries[i].country_name;
                }
            } else {
                this.countries = [];
            }
            this.updateZipCodeVailation();
        });
    }
    /* GET PREFERE CUSTOMERS COUNTRY/STATES/CITY */

    getStates(countryID) {
        if (typeof(countryID) == 'undefined' || countryID == "") {
            this.disableStates = true;
            this.disableCities = true;
            this.cities=[];
        } else {
            this.disableCities = true;
            this.cities=[
                { id: "", text: "Select City" }
            ];
            let stateDDLPlaceholder = [
                { 
                    id: "",
                    state_id: "",
                    state_name: "Select State",
                    country_id: "",
                    latitude: "",
                    longitude: "",
                    tax_rate: "",
                    state_code: ""
                 }
            ];
            this.states = [];
            this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_STATES_URL, "opr=1&country_id=" + countryID).subscribe((data:any) => {
                var responseData = data;
                this.disableStates = false;
                if (responseData.result == "1") {
                    this.states  = stateDDLPlaceholder.concat(responseData.data);
                    for(var i in this.states){
                        this.states[i].id=this.states[i].state_id;
                        this.states[i].text=this.states[i].state_name;  
                   }
                } else {
                    this.states = [];
                    this.cities = [];
                    this.disableStates = false;
                    this.selectedState = [{ id: "", text: "No state found" }];
                    this.disableCities = true;
                }
            });
        }
    }

    getCities(stateId) {
        if (typeof(stateId) == 'undefined' || stateId == "") {
            this.cities = [];
            this.disableCities = true;
        } else {
           this.cities = [];
           let cityDDLPlaceholder=[{
            id: "",
            city_id: "",
            city_name: "Select City",
            state_id: "",
            country_id: "",
            latitude: "",
            longitude: ""
            }];
            this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_CITIES_URL, "opr=1&state_id=" + stateId).subscribe((data:any) => {
                var responseData = data;
                if (responseData.result == "1") {
                    this.disableCities = false;
                        this.cities = cityDDLPlaceholder.concat(responseData.data);
                        for(var i in this.cities){
                            this.cities[i].id=this.cities[i].city_name;
                            this.cities[i].text=this.cities[i].city_name;          
                        }
                } else {
                    this.cities = [{id: "", text: "No city found"}];
                    this.disableCities = false;
                }
            })
        }
    }
    
    handleCountryDropdownChange(countryId){
        this.disableStates = true;
        this.getStates(countryId);
        let countryData = this.countries.find(countryInfo => countryId == countryInfo.id);
        this.editUserProfile.controls.country.setValue(countryData.country_name);
        this.editUserProfile.controls.state.setValue('');
        this.editUserProfile.controls.city.setValue('');
        this.selectedState = '';
        this.selectedCity = '';
    } 

    handleStateDropdownChange(stateId) {
        if (stateId !== '') {
            this.disableCities = true;
            this.getCities(stateId);
            let stateData = this.states.find(stateInfo => stateId == stateInfo.id);
            this.editUserProfile.controls.state.setValue(stateData.state_name);
            this.editUserProfile.controls.city.setValue('');
            this.selectedCity = 'Select City';
        } else {
            this.editUserProfile.controls.state.setValue('');
            this.editUserProfile.controls.city.setValue('');
            this.selectedCity = 'Select City';
        }
    }
    
    handleCityDropdownChange(cityName) {
        if (cityName !== 'Select City') {
            this.editUserProfile.controls.city.setValue(cityName);
            this.selectedCity = cityName;
        }else{
            this.editUserProfile.controls.city.setValue(''); 
            this.selectedCity = 'Select City';
        }
    }
    /* Customer Type Validation */
    customerTypeValidation() {
        let cType = this.editUserProfile.value.state;
        if(cType !="") {
            let cTypeValue = this.editUserProfile.value.state.length >= 1 ? false : {required: true};
            if(cTypeValue == false) {
                this.editUserProfile.controls.state.setErrors(null);
            } else {
                this.editUserProfile.controls.state.setErrors({"required": true});
            }
        } else {
            this.editUserProfile.controls.state.setErrors({"required": true});
        }
    }
    
    stateValidation() {
        //if(this.states.length > 0) {
            this.globalService.validateState(this.editUserProfile, ['state','city']);
        //}
    }
    getLang(){
        this.languages= ['English','Afrikaans','Albanian','Amharic','Arabic','Armenian','Azerbaijani','Basque','Belarusian','Bengali','Bosnian','Bulgarian','Catalan','Cebuano','Chichewa','Chinese (Simplified)','Chinese (Traditional)','Corsican','Croatian','Czech','Danish','Dutch','Esperanto','Estonian','Filipino','Finnish','French','Frisian',
        'Galician','Georgian','German','Greek','Gujarati','Haitian Creole','Hausa','Hawaiian','Hebrew','Hindi','Hmong','Hungarian','Icelandic','Igbo','Indonesian','Irish','Italian','Japanese','Javanese','Kannada','Kazakh','Khmer','Korean','Kurdish (Kurmanji)','Kyrgyz','Lao','Latin','Latvian','Lithuanian','Luxembourgish','Macedonian',
        'Malagasy','Malay','Malayalam','Maltese','Maori','Marathi','Mongolian','Myanmar (Burmese)','Nepali','Norwegian','Pashto','Persian','Polish','Portuguese','Punjabi','Romanian','Russian','Samoan','Scots Gaelic','Serbian','Sesotho','Shona','Sindhi','Sinhala','Slovak','Slovenian','Somali','Spanish','Sundanese','Swahili','Swedish',
        'Tajik','Tamil','Telugu','Thai','Turkish','Ukrainian','Urdu','Uzbek','Vietnamese','Welsh','Xhosa','Yiddish','Yoruba','Zulu'];
    }
    setDefaultLang(lang){
        var container = document.getElementById('google_translate_element');
        var select = container.getElementsByTagName('select')[0];
        let sLang=$('#google_translate_element select:first > option:contains('+lang+')').val()
        $('#google_translate_element select:first').val(sLang);
        this.editUserProfile.controls.current_lang.setValue(lang);
        this.globalService.triggerHtmlEvent(select, 'change');
        this.globalService.triggerHtmlEvent(select, 'change');
    }
    langEnable(obj){
        var select = document.getElementById('google_translate_element');
        if(obj.checked){
            select.style.visibility='visible';
            this.editUserProfile.controls.lang_enable.setValue(1);
        }else{
            select.style.visibility='hidden';
            this.editUserProfile.controls.lang_enable.setValue(0);
        }
    }
    showOriginal(obj){
        if(obj.checked){
           this.globalService.setGLangCss('#goog-gt-tt{}');
           this.editUserProfile.controls.original_language.setValue(1);
        }else{
           this.globalService.setGLangCss(this.globalService.GLANGSTYLE);
           this.editUserProfile.controls.original_language.setValue(0);
        }
    }
    cgridChange($e) {
        if ($e && $e.value) {
            this.editUserProfile.controls.listing_view.setValue($e.value)
        }
    }
    selectedCol(evt){
    } 
    removeColOfSRList(evt){
        let i = this.preSelectCol.findIndex(label => label == evt);
        if (i >= 0) {
          this.preSelectCol.splice(i, 1);
        }
    }
    
    setCountryStateCityViaZipCode(){
        let payload = {
             user_id: this.currentUserInfo.id,
             user_type: "User",
             zip_code: this.editUserProfile.controls.zipcode.value
        }
        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_STATE_CITY_VIA_ZIPCODE,payload).subscribe((response:any)=>{
            if(response.result == 1){
                if(response.data.country_info.country_id !== 0 
                    && response.data.state_info.state_id !== 0 
                    &&  response.data.city_info.city_id !== 0 ){
                    this.selectedCountry = response.data.country_info.country_code;
                    this.editUserProfile.controls.country.setValue(response.data.country_info.country_name);
                    this.getStates(response.data.country_info.country_code);
                    this.selectedState = response.data.state_info.state_id;
                    this.editUserProfile.controls.state.setValue(response.data.state_info.state_name);
                    this.getCities(response.data.state_info.state_id);
                    this.selectedCity = response.data.city_info.city_name;
                    this.editUserProfile.controls.city.setValue(this.selectedCity);
                }
            }
      })
    }

    ngOnDestroy(){
        this.subscriptionDataForAPI.unsubscribe();
    }  
}
