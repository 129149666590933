<div class="panel panel-primary" id="mainCompotents">
  <div class="panel-heading padding-left-15">
    <h3 class="panel-title">PART LOG DETAILS</h3>
    <button mat-mini-fab class="custom-btn-back" (click)="globalService.goBack();">
        <mat-icon>arrow_back</mat-icon>
      </button>
  </div>
  <!---->
  <div class="panel-body bgcolor-lightgrey">
    <!-- <h3 class="margin-top-0">LOG DETAILS</h3> -->
    <div class="table-responsive">
      <table class="table table-striped table-hover table-bordered ">

        <thead>
          <tr>
            <th>Name</th>
            <th>Old Value</th>
            <th>New value</th>
          </tr>
        </thead>

        <tbody>
          <tr [class.part-detail--bg-red]="logDetailOld.name!=logDetailNew.name">
            <td>Name </td>
            <td>{{( logDetailOld.name ? logDetailOld.name : '-' )}}</td>
            <td>{{( logDetailNew.name ? logDetailNew.name : '-' )}}</td>
          </tr>
          <tr [class.part-detail--bg-red]="logDetailOld.price!=logDetailNew.price">
            <td>Price </td>
            <td>{{( logDetailOld.price ? logDetailOld.price : '-' )}}</td>
            <td>{{( logDetailNew.price ? logDetailNew.price : '-' )}}</td>
          </tr>
          <tr [class.part-detail--bg-red]="logDetailOld.cost!=logDetailNew.cost">
            <td>Cost </td>
            <td>{{( logDetailOld.cost ? logDetailOld.cost : '-')}}</td>
            <td>{{( logDetailNew.cost ? logDetailNew.cost : '-')}}</td>
          </tr>
          <tr [class.part-detail--bg-red]="logDetailOld.vendor!=logDetailNew.vendor">
            <td>Vendor </td>
            <td>{{( logDetailOld.vendor ? logDetailOld.vendor : '-')}}</td>
            <td>{{( logDetailNew.vendor ? logDetailNew.vendor : '-')}}</td>
          </tr>
          <tr [class.part-detail--bg-red]="logDetailOld.manufacturer!=logDetailNew.manufacturer">
            <td>Manufacturer </td>
            <td>{{( logDetailOld.manufacturer ? logDetailOld.manufacturer : '-')}}</td>
            <td>{{( logDetailNew.manufacturer ? logDetailNew.manufacturer : '-')}}</td>
          </tr>
          <tr [class.part-detail--bg-red]="logDetailOld.description!=logDetailNew.description">  
            <td>Description </td>
            <td>{{( logDetailOld.description ? logDetailOld.description : '-')}}</td>
            <td>{{( logDetailNew.description ? logDetailNew.description : '-')}}</td>
          </tr>
          <tr [class.part-detail--bg-red]="logDetailOld.part_number!=logDetailNew.part_number">
              <td>Part Number </td>
              <td>{{( logDetailOld.part_number ? logDetailOld.part_number : '-')}}</td>
              <td>{{( logDetailNew.part_number ? logDetailNew.part_number : '-')}}</td>
            </tr>
            <tr [class.part-detail--bg-red]="logDetailOld.oem_part_number!=logDetailNew.oem_part_number">
                <td>OEM Number </td>
                <td>{{( logDetailOld.oem_part_number ? logDetailOld.oem_part_number : '-')}}</td>
                <td>{{( logDetailNew.oem_part_number ? logDetailNew.oem_part_number : '-')}}</td>
              </tr>
              <tr  [class.part-detail--bg-red]="logDetailOld.non_taxable!=logDetailNew.non_taxable">
                  <td>Non Taxable </td>
                  <td> 
                    <span *ngIf="logDetailOld.non_taxable==1">Yes</span>
                    <span *ngIf="logDetailOld.non_taxable==0">No</span>
                  </td>
                  <td>
                    <span *ngIf="logDetailNew.non_taxable==1">Yes</span>
                    <span *ngIf="logDetailNew.non_taxable==0">No</span>
                  </td>
                </tr>
                <tr [class.part-detail--bg-red]="logDetailOld.quantity!=logDetailNew.quantity">
                  <td>Quantity </td>
                  <td>{{( logDetailOld.quantity ? logDetailOld.quantity : '-')}}</td>
                  <td>{{( logDetailNew.quantity ? logDetailNew.quantity : '-')}}</td>
                </tr>
                <tr [class.part-detail--bg-red]="logDetailOld.low_stock_value!=logDetailNew.low_stock_value">
                  <td>Low Stock Value </td>
                  <td>{{( logDetailOld.low_stock_value ? logDetailOld.low_stock_value : '-')}}</td>
                  <td>{{( logDetailNew.low_stock_value ? logDetailNew.low_stock_value : '-')}}</td>
                </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>