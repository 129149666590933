<div class="panel panel-primary car-list-panel" id="dashboardQuickLinks">
  <div class="panel-heading padding-left-15">
    <h3 class="panel-title">Car Details</h3>
    <button mat-mini-fab class="custom-btn-back edit-btn" title="Edit Vehcile" *ngIf="carCustomerId == carDetails.customer_id"
      (click)="editCar(carCustomerId,vehicleId,countryName)">
      <i class="fa fa-edit fa-lg margin-right-5"></i>
    </button>
    <button mat-mini-fab class="custom-btn-back" (click)="globalService.goBack();">
      <mat-icon>arrow_back</mat-icon>
    </button>
  </div>
  <div class="panel-body bgcolor-lightgrey">
    <!-- Login page start -->
    <div id="signupContent" class="row bgcolor-lightgrey">
      <div class="container-fluid">
        <form id="editCarForm" class="form-inline col-xs-12 signup-form car-details-form" role="form" method="POST"
          enctype="multipart/form-data">
          
          <div class="service-history-btn">
            <button mat-raised-button color="accent" type="button" (click)="openServiceHistoryPopup()" 
              title="View Service History">
              <!-- <i class="fa fa-history fa-lg"></i> -->
              <img [src]="gc.SERVICE_HISTORY_ICON_HOVER" class="history-icon" />
              <!-- <span class="hidden-xs">&nbsp; View Service History</span> -->
            </button>
            <button mat-raised-button color="accent" type="button" *ngIf="showingInpReport"  title="{{handleVIRTitle()}}" [ngClass]="vehicleInspReportData.vir_found == 0 ? 'disable-vir-btn' : ''" (click)="openVIR()">
              <i class="fa fa-solid fa-square-poll-horizontal fa-lg" aria-hidden="true"></i>
            </button>
            <button mat-raised-button color="accent" type="button" *ngIf="showingInpReport" title="{{handleVIRDownloadTitle()}}" [ngClass]="vehicleInspReportData.vir_found == 0 ? 'disable-vir-btn' : ''"
              (click)="handleDownloadVIR()" title="Download Inspection Report"><i class="fa fa-download fa-lg" aria-hidden="true"></i>
            </button>
          </div>
          <div class="custom-grid-view padding-15">
              <div class="row row-flex">
                <div class="col-sm-6 col-xs-12 margin-bottom-10 padding-0">

                  <div class="col-sm-6 col-xs-12">
                    <label>Make</label>
                  </div>
                  <div class="col-sm-6 col-xs-12">
                    <p class="pull-left break-word" id="make">{{(car.value.make ? car.value.make:'-')}}</p>
                  </div>
                </div>
                <div class="col-sm-6 col-xs-12 margin-bottom-10 padding-0">
                  <div class="col-sm-6 col-xs-12">
                    <label>Year</label>
                  </div>
                  <div class="col-sm-6 col-xs-12">
                    <p class="pull-left" id="year">{{(car.value.year ? car.value.year : '-')}}</p>
                  </div>
                </div>
                <div class="col-sm-6 col-xs-12 margin-bottom-10 padding-0">
                  <div class="col-sm-6 col-xs-12">
                    <label>Model</label>
                  </div>
                  <div class="col-sm-6 col-xs-12">
                    <p class="pull-left break-word" id="model">{{(car.value.model ? car.value.model : '-')}}</p>
                  </div>
                </div>
                <div class="col-sm-6 col-xs-12 margin-bottom-10 padding-0">
                  <div class="col-sm-6 col-xs-12">
                    <label>Engine Size</label>
                  </div>
                  <div class="col-sm-6 col-xs-12">
                    <p class="pull-left" id="subModel">{{(car.value.sub_model) ? car.value.sub_model : '-'}}</p>
                  </div>
                </div>

                <div class="col-sm-6 col-xs-12 margin-bottom-10 padding-0">
                  <div class="col-sm-6 col-xs-12">
                    <label>Vin</label>
                  </div>
                  <div class="col-sm-6 col-xs-12">
                    <p class="pull-left break-word" id="vin" data-toggle="tooltip" data-validation="[OPTIONAL]">
                      {{(car.value.vin) ? car.value.vin : '-'}}</p>
                  </div>
                </div>
                <div class="col-sm-6 col-xs-12 margin-bottom-10 padding-0">
                  <div class="col-sm-6 col-xs-12">
                    <label>Production Date</label>
                  </div>
                  <div class="col-sm-6 col-xs-12">
                    <p class="pull-left" id="productionDate">{{(productionDate) ? productionDate : '-'}}</p>
                  </div>
                </div>

                <div class="col-sm-6 col-xs-12 margin-bottom-10 padding-0">
                  <div class="col-sm-6 col-xs-12">
                    <label>Mileage</label>
                  </div>
                  <div class="col-sm-6 col-xs-12">
                    <p class="pull-left break-word" id="distance">{{car.value.distance_covered ? car.value.distance_covered : '0'}}
                    </p>
                  </div>
                </div>
                <div class="col-sm-6 col-xs-12 margin-bottom-10 padding-0">
                  <div class="col-sm-6 col-xs-12">
                    <label>License Plate</label>
                  </div>
                  <div class="col-sm-6 col-xs-12">
                    <p class="pull-left break-word text-uppercase" id="licensePlate"
                      data-validation="[NOTEMPTY]" >
                      {{car.value.license_plate ? car.value.license_plate : '-'}}</p>
                  </div>
                </div>

                <div class="col-sm-6 col-xs-12 margin-bottom-10 padding-0">
                  <div class="col-sm-6 col-xs-12">
                    <label>State</label>
                  </div>
                  <div class="col-sm-6 col-xs-12">
                    <p class="pull-left" id="color">{{car.value.state ? car.value.state : '-'}}</p>
                  </div>
                </div>
                <div class="col-sm-6 col-xs-12 margin-bottom-10 padding-0">
                  <div class="col-sm-6 col-xs-12">
                    <label>Color</label>
                  </div>
                  <div class="col-sm-6 col-xs-12">
                    <p class="" id="color" data-validation="[OPTIONAL]">{{car.value.color ? car.value.color : '-'}}</p>
                  </div>
                </div>

                <div class="col-sm-6 col-xs-12 margin-bottom-10 padding-0">
                  <div class="col-sm-6 col-xs-12" >
                    <label>Unit Number</label>
                  </div>
                  <div class="col-sm-6 col-xs-12">
                    <p class="pull-left break-word" id="unitNumber">{{car.value.unit_number ? car.value.unit_number : '-'}}</p>
                  </div>
                </div>
                <div class="col-sm-6 col-xs-12 margin-bottom-10 padding-0">
                  <div class="col-sm-6 col-xs-12">
                    <label>Driver</label>
                  </div>
                  <div class="col-sm-6 col-xs-12">
                    <p class="pull-left break-word" id="driver">{{car.value.driver ? car.value.driver : '-'}}</p>
                  </div>
                </div>

                <div class="col-sm-6 col-xs-12 margin-bottom-10 padding-0" >
                  <div class="col-sm-6 col-xs-12" >
                    <label>Driver Phone Number</label>
                  </div>
                  <div class="col-sm-6 col-xs-12" >
                    <p class="pull-left" id="driverPhoneNumber">
                      {{car.value.driver_phone_number ? car.value.driver_phone_number : '-'}}</p>
                  </div>
                </div>
                <div class="col-sm-6 col-xs-12 margin-bottom-10 padding-0">
                  <div class="col-sm-6 col-xs-12">
                    <label>Insurance Expiration Date</label>
                  </div>
                  <div class="col-sm-6 col-xs-12">
                    <p class="pull-left" id="insuranceExpirationDate">{{car.value.insurance_expiration_date}} </p>
                  </div>
                </div>

                <div class="col-sm-6 col-xs-12 margin-bottom-10 padding-0">
                  <div class="col-sm-6 col-xs-12">
                    <label>Registration Expiration Date</label>
                  </div>
                  <div class="col-sm-6 col-xs-12">
                    <p class="pull-left" id="registrationExpirationDate">{{car.value.registration_expiration_date}} </p>
                  </div>
                </div>
                <div class="col-sm-6 col-xs-12 margin-bottom-10 padding-0" *ngIf="carDetails && globalService.getFullFormateDate(carDetails.inspection_expiration_date,'GD','H')!='-'">
                  <div class="col-sm-6 col-xs-12">
                    <label>Inspection Expiration Date</label>
                  </div>
                  <div class="col-sm-6 col-xs-12">
                    <p class="pull-left"
                      [innerHTML]="carDetails && globalService.getFullFormateDate(carDetails.inspection_expiration_date,'GD','H')">
                    </p>
                  </div>
                </div>

                <div class="col-sm-6 col-xs-12 margin-bottom-10 padding-0" *ngIf="carDetails && carDetails.transmission">
                  <div class="col-sm-6 col-xs-12">
                    <label>Transmission</label>
                  </div>
                  <div class="col-sm-6 col-xs-12">
                    <p class="pull-left"
                      [innerHTML]="((carDetails && carDetails.transmission ) ? (carDetails.transmission ) : '-')"></p>
                  </div>
                </div>
                <div class="col-sm-6 col-xs-12 margin-bottom-10 padding-0" *ngIf="carDetails && carDetails.brake">
                  <div class="col-sm-6 col-xs-12">
                    <label>Brake</label>
                  </div>
                  <div class="col-sm-6 col-xs-12">
                    <p class="pull-left" [innerHTML]="(carDetails && carDetails.brake ? carDetails.brake : '-')"></p>
                  </div>
                </div>
                <div class="col-sm-6 col-xs-12 margin-bottom-10 padding-0" *ngIf="carDetails && carDetails.driveline">
                  <div class="col-sm-6 col-xs-12">
                    <label>Driveline</label>
                  </div>
                  <div class="col-sm-6 col-xs-12">
                    <p class="pull-left"
                      [innerHTML]="((carDetails && carDetails.driveline ) ? (carDetails.driveline ) : '-')"></p>
                  </div>
                </div>
                <div class="col-sm-6 col-xs-12 margin-bottom-10 padding-0" *ngIf="carDetails && carDetails.gvwr">
                  <div class="col-sm-6 col-xs-12">
                    <label>GVWR</label>
                  </div>
                  <div class="col-sm-6 col-xs-12">
                    <p class="pull-left" [innerHTML]="( carDetails && carDetails.gvwr ? carDetails.gvwr : '-')"></p>
                  </div>
                </div>

                <div class="col-sm-6 col-xs-12 margin-bottom-10 padding-0" *ngIf="carDetails && carDetails.wheel_base">
                  <div class="col-sm-6 col-xs-12">
                    <label>Wheel Base</label>
                  </div>
                  <div class="col-sm-6 col-xs-12">
                    <p class="pull-left"
                      [innerHTML]="((carDetails && carDetails.wheel_base ) ? (carDetails.wheel_base ) : '-')"></p>
                  </div>
                </div>
                <div class="col-sm-6 col-xs-12 margin-bottom-10 padding-0" *ngIf="carDetails && carDetails.tire_size">
                  <div class="col-sm-6 col-xs-12">
                    <label>Tire Size</label>
                  </div>
                  <div class="col-sm-6 col-xs-12">
                    <p class="pull-left"
                      [innerHTML]="( carDetails && carDetails.tire_size ? carDetails.tire_size : '-')">
                    </p>
                  </div>
                </div>

                <div class="col-sm-6 col-xs-12 margin-bottom-10 padding-0" *ngIf="carDetails && carDetails.wheel_lock_location">
                  <div class="col-sm-6 col-xs-12">
                    <label>Wheel Lock Location</label>
                  </div>
                  <div class="col-sm-6 col-xs-12">
                    <p class="pull-left"
                      [innerHTML]="((carDetails && carDetails.wheel_lock_location ) ? (carDetails.wheel_lock_location ) : '-')">
                    </p>
                  </div>
                </div>
                  <div class="col-sm-6 col-xs-12 margin-bottom-10 padding-0">
                  <div class="col-sm-6 col-xs-12">
                    <label>California Emissions</label>
                  </div>
                  <div class="col-sm-6 col-xs-12">
                    <p class="pull-left" *ngIf="carDetails && carDetails.california_emissions!=1"
                      [innerHTML]="( carDetails && carDetails.california_emissions==1 ? 'Yes' : 'No')"></p>
                    <ul id="vehicle_picul" class="list-inline margin-bottom-0"
                      *ngIf="carDetails && carDetails.california_emissions==1">
                      <li class="relative view-images">
                        <a href="javascript:void(0);" class="thumbnail">
                          <img class="show-image" title="California Emissions Standards"
                            src="assets/img/california-emissions.PNG" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-sm-6 col-xs-12 margin-bottom-10 padding-0">
                  <div class="col-sm-6 col-xs-12">
                    <label>Standard Mileage</label>
                  </div>
                  <div class="col-sm-6 col-xs-12">
                    <p class="pull-left break-word"
                      [innerHTML]="carDetails.standard_mileage || '-'"></p>
                  </div>
                </div>

                <div class="col-sm-6 col-xs-12 margin-bottom-10 padding-0">
                  <div class="col-sm-6 col-xs-12">
                    <label>Standard Days</label>
                  </div>
                  <div class="col-sm-6 col-xs-12">
                    <p class="pull-left"
                    [innerHTML]="carDetails.standard_days || '-'"></p>
                  </div>
                </div>
                <!-- Custom Field List Start-->

              <div class="col-sm-6 col-xs-12 margin-bottom-10 padding-0" *ngFor="let f of customFields;let i=index" [class.hide]="(f.value ? false : true )">
                <div class="col-sm-6 col-xs-12">
                    <label>{{f.label}}</label>
                </div>
                  <div class="col-sm-6 col-xs-12">
                    <p class="pull-left" [innerHTML]="getFieldVal(f)"></p>
                  </div>
              </div>

                <!-- Custom Field List End-->
              </div>
                <div class="row row-flex">
                <div class="col-sm-6 col-xs-12 margin-bottom-10 padding-0">
                  <div class="col-sm-6 col-xs-12">
                    <label>Vehicle Registration Photo</label>
                  </div>
                  <div class="col-sm-6 col-xs-12">
                    <div class="pull-left" id="vehicleRegPicView">
                      <ul id="vehicle_pic-ul" class="list-inline margin-bottom-0">
                        <li class="relative view-images" *ngFor="let item of vehicleRegisteredPic let i = index">
                          <a href="javascript:void(0);" class="thumbnail"
                            (click)="imageToolDialog($event,i,3,vehicleRegisteredPic);$event.stopPropagation();">
                            <img class="show-image" src="{{registeredPicture}}{{item.vehicle_registered_pic}}" />
                          </a>
                        </li>
                      </ul>
                        <p *ngIf="vehicleRegisteredPic && vehicleRegisteredPic.length == 0" class="pull-left">-</p>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-xs-12 margin-bottom-10 padding-0">
                  <div class="col-sm-6 col-xs-12">
                    <label>Vehicle Insurance Card Photo</label>
                  </div>
                  <div class="col-sm-6 col-xs-12">
                    <div class="pull-left" id="vehicleInsPicView">
                      <ul id="vehicle_insured_pic-ul" class="list-inline margin-bottom-0">
                        <li class="relative view-images" *ngFor="let item of vehicleInsuredPic let i = index">
                          <a href="javascript:void(0);" class="thumbnail"
                            (click)="imageToolDialog($event,i,2,vehicleInsuredPic);$event.stopPropagation();">
                            <img class="show-image" src="{{insurance}}{{item.vehicle_insured_pic}}">
                          </a>
                        </li>
                      </ul>
                      <p *ngIf="vehicleInsuredPic && vehicleInsuredPic.length == 0" class="pull-left">-</p>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6 col-xs-12 margin-bottom-10 padding-0">
                  <div class="col-sm-6 col-xs-12">
                    <label>Vehicle Photo(s)</label>
                  </div>
                  <div class="col-sm-6 col-xs-12">
                    <div class="pull-left" id="vehiclePicView">
                      <ul id="vehicle_pic-ul" class="list-inline margin-bottom-0">
                        <li class="relative view-images" *ngFor="let item of vPic let i = index">
                          <a href="javascript:void(0);" class="thumbnail" (click)="imageToolDialog($event,i,1,vPic);$event.stopPropagation();">

                            <img class="show-image" src="{{imgPath}}{{item.vehicle_pic}}" />
                          </a>
                        </li>
                      </ul>
                      <p *ngIf="vPic && vPic.length == 0" class="pull-left">-</p>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-xs-12 margin-bottom-10 padding-0">
                  <div class="col-sm-6 col-xs-12">
                    <label>Vehicle Video(s)</label>
                  </div>
                  <div class="col-sm-6 col-xs-12">
                    <div class="pull-left" id="vehicleVidView">
                      <ul id="vehicle_video-ul" class="list-inline margin-bottom-0">
                        <li class="relative video-upload" *ngFor="let video of videoList">
                          <a class="thumbnail margin-bottom-0" href="javascript:void(0)"
                            (click)="videoShow(video.vehicle_video)">
                            <span class="video-bg">
                              <mat-icon class="position-center play-video">play_circle_filled</mat-icon>
                            </span>
                          </a>
                        </li>
                      </ul>
                      <p *ngIf="videoList && videoList.length == 0" class="pull-left">-</p>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6 col-xs-12 margin-bottom-10 padding-0">
                  <div class="col-sm-6 col-xs-12">
                    <label>Vehicle Vin Photo</label>
                  </div>
                  <div class="col-sm-6 col-xs-12">
                    <div class="pull-left" id="vehicleVinPicView">
                      <ul id="vin_photo-ul" class="list-inline margin-bottom-0">
                        <li class="relative view-images" *ngIf="vinPhoto!=''">
                          <a href="javascript:void(0);" class="thumbnail"
                            (click)="imageToolDialog($event,0,5,vinPhoto);$event.stopPropagation();">
                            <img class="show-image" src="{{vinPicture}}{{vinPhoto}}">
                          </a>
                        </li>
                      </ul>
                      <p *ngIf="vinPhoto === ''" class="pull-left">-</p>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-xs-12 margin-bottom-10 padding-0">
                  <div class="col-sm-6 col-xs-12">
                    <label>Other Label Photo</label>
                  </div>
                  <div class="col-sm-6 col-xs-12">
                    <div class="pull-left" id="vehicleLabelPicView">
                      <ul id="vehicle_pic-ul" class="list-inline margin-bottom-0">
                        <li class="relative view-images" *ngFor="let item of labelPhoto; let i = index">
                          <a href="javascript:void(0);"(click)="imageToolDialog($event,i,13,labelPhoto);$event.stopPropagation();" class="thumbnail">
                            <img class="show-image" src="{{VEHICLE_LABEL_PIC}}{{item.label_pic}}" />
                          </a>
                        </li>
                      </ul>
                      <p *ngIf="labelPhoto && labelPhoto.length == 0" class="pull-left">-</p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          
        </form>
      </div>
    </div>
    <!-- Login page end -->

    
  </div>
</div>
