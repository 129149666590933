<div class="car-panel" id="mainCompotents">
  <div class="panel panel-primary">
    <div class="panel-heading padding-left-15">
      <h3 class="panel-title">EDIT PROFILE</h3>
      <button mat-mini-fab class="custom-btn-back" (click)="globalService.goBack();">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
    <div class="panel-body bgcolor-lightgrey edit-profile">
      <!-- Add Customer page start -->
      <div class="row  form-content">
        <div class="container-fluid">
          <form [formGroup]="editUserProfile" (ngSubmit)="editProfile()" id="addShopForm" class="form-inline col-xs-12 col-sm-12 col-md-12 col-lg-12 padding-bottom-15" role="form">

            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 form-block">
              <h2 class="fontcolor-blue sub-heading">LOGIN INFORMATION</h2>
              <div class="row">
                <div class="form-group col-sm-12 col-md-4">
                  <mat-form-field class="car-input btn-block" floatPlaceholder="never"  hideRequiredMarker="false" appearance="fill">
                    <input  matInput placeholder="Email Address" formControlName="email"  class="form-control input-lg custom-form-input" />
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 form-block">
                  <h2 class="fontcolor-blue sub-heading">PERSONAL INFORMATION</h2>
                  <div class="row">
                    <div class="form-group col-sm-12 col-md-4" *ngIf="currentUserDetails">
                      <mat-form-field class="car-input btn-block" floatPlaceholder="never" hideRequiredMarker="false" appearance="fill">
                        <input matInput placeholder="First Name" title="First Name" formControlName="first_name"  class="form-control input-lg custom-form-input" [(ngModel)]="currentUserDetails.first_name">
                        <mat-error *ngIf="editUserProfile.controls.first_name.hasError('required')">
                          First Name is <strong>required</strong>
                        </mat-error>
                        <mat-error *ngIf="editUserProfile.controls.first_name.hasError('pattern')">
                          First name is not valid. Must not contain any special characters.
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="form-group col-sm-12 col-md-4" *ngIf="currentUserDetails">
                      <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                        <input  matInput placeholder="Middle Name" title="Middle Name" formControlName="middle_name"  class="form-control input-lg custom-form-input" [(ngModel)]="currentUserDetails.middle_name">
                        <mat-error *ngIf="editUserProfile.controls.middle_name.hasError('pattern')">
                          Middle name is invalid. Must not contain any special characters.
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="form-group col-sm-12 col-md-4" *ngIf="currentUserDetails">
                      <mat-form-field class="car-input btn-block" floatPlaceholder="never" hideRequiredMarker="false" appearance="fill">
                        <input  matInput placeholder="Last Name" title="Last Name" formControlName="last_name"  class="form-control input-lg custom-form-input" required [(ngModel)]="currentUserDetails.last_name">
                        <mat-error *ngIf="editUserProfile.controls.last_name.hasError('required')">
                          Last Name is <strong>required</strong>
                        </mat-error>
                        <mat-error *ngIf="editUserProfile.controls.last_name.hasError('pattern')">
                           Last name is invalid. Must not contain any special characters.
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 form-block">
                  <h2 class="fontcolor-blue sub-heading">CONTACT INFORMATION</h2>
                </div>
              </div>
              <div class="row">
                <div class="form-group  col-sm-12 col-md-6" *ngIf="currentUserDetails">
                  <div class='relative'>
                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                      <input matInput placeholder="Address Line 1*" title="Address Line 1" formControlName="address_one"  class="form-control input-lg custom-form-input" [(ngModel)]="currentUserDetails.address1" />
                      <mat-error *ngIf="editUserProfile.controls.address_one.hasError('required')">
                        Address Line 1 is
                        <strong>required</strong>
                      </mat-error>
                    </mat-form-field>

                  </div>
                </div>
                <div class="form-group  col-sm-12 col-md-6" *ngIf="currentUserDetails">
                  <div class='relative'>
                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                      <input matInput placeholder="Address Line 2" title="Address Line 2" formControlName="address_two"  class="form-control input-lg custom-form-input" [(ngModel)]="currentUserDetails.address2" />
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group  col-sm-12 col-md-6" *ngIf="currentUserDetails">
                  <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                    <input  matInput  placeholder="{{zipLabel}}*" 
                    title="{{zipLabel}}" 
                    #zipcode (input)="zipcode.value = zipcode.value.toUpperCase()" 
                    formControlName="zipcode"  
                    class="form-control input-lg custom-form-input" 
                    maxlength="15" [(ngModel)]= "currentUserDetails.zip"
                   (blur)="setCountryStateCityViaZipCode()"
                   />
                    <mat-error *ngIf="editUserProfile.controls.zipcode.hasError('pattern')">
                      Please enter valid {{zipLabel.toLowerCase()}}
                    </mat-error>
                    <mat-error *ngIf="editUserProfile.controls.zipcode.hasError('required')">
                      {{zipLabel}} is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="form-group  col-sm-12 col-md-6 uc-ngx-select" >
                    <ngx-select class="display-full margin-bottom-20"       
                        [items]="countries" 
                        [(ngModel)]="selectedCountry" 
                        [ngModelOptions]="{standalone: true}"
                        (select)="handleCountryDropdownChange($event)"
                        placeholder="Select Country">
                    </ngx-select>
                </div>
              </div>
              <div class="row">
                <div class="form-group  col-sm-12 col-md-6 uc-ngx-select">
                  <ngx-select class="display-full margin-bottom-20"         
                      [items]="states" 
                      [(ngModel)]="selectedState" 
                      [ngModelOptions]="{standalone: true}" 
                      (select)="handleStateDropdownChange($event)"
                      [disabled]="disableStates"
                      placeholder="Select State">
                  </ngx-select>
                  <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
                      <mat-error *ngIf="editUserProfile.controls.state.hasError('required')">
                          State is <strong>required</strong>
                      </mat-error>
                  </div>

              </div>
                <div class="form-group  col-sm-12 col-md-6 uc-ngx-select" >
                   <ngx-select class="display-full margin-bottom-20" 
                        [items]="cities" 
                        [(ngModel)]="selectedCity" 
                        [ngModelOptions]="{standalone: true}" 
                        (select)="handleCityDropdownChange($event)"
                        [disabled]="disableCities"
                        placeholder="Select City">
                    </ngx-select>
                    <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
                        <mat-error *ngIf="editUserProfile.controls.city.hasError('required')">
                            City is <strong>required</strong>
                        </mat-error>
                    </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group  col-sm-12 col-md-6" *ngIf="currentUserDetails">
                  <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                    <input  matInput placeholder="Mobile Phone*" title="Mobile Phone" formControlName="cell_phone1"  class="form-control input-lg custom-form-input" [textMask]="{mask: phoneMask}" [(ngModel)]= "currentUserDetails.cell_phone1" />
                    <mat-error *ngIf="editUserProfile.controls.cell_phone1.hasError('required')">
                      Mobile Phone is
                      <strong>required</strong>
                    </mat-error>
                   
                    <mat-error *ngIf="editUserProfile.controls.cell_phone1.hasError('pattern')">
                      Invalid Mobile Phone
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="form-group col-sm-12 col-md-6">
                  <mat-checkbox class="btn-block margin-top-10" labelPosition="after"
                    (change)="showOriginal($event)" [checked]="currentUserDetails.original_lang==1">
                    Show Original Text
                  </mat-checkbox>
                </div>
              </div>
              <div class="row">
                <div class="form-group  col-sm-12 col-md-6 uc-ngx-select" >
                   <ngx-select class="display-full margin-bottom-20"
                        [items]="languages" 
                        [(ngModel)]="defaultLang" 
                        [ngModelOptions]="{standalone: true}" 
                        [defaultValue]="defaultLang"
                        (select)="setDefaultLang($event)"
                        placeholder="Select Language">
                    </ngx-select>
                </div> 
                <div class="form-group col-sm-12 col-md-6">
                    <mat-checkbox class="btn-block margin-top-10" labelPosition="after"
                      (change)="langEnable($event)" [checked]="currentUserDetails.lang_enable==1">
                      Enable Language Change
                    </mat-checkbox>
                  </div>
                  
              </div>
              <h2 class="fontcolor-blue sub-heading">Grid View</h2>
              <div class="row">
                <label class="col-md-3">Customer List View:</label>
                <div class="col-md-9">
                  <!-- <mat-radio-group class="example-radio-group" [(ngModel)]="customerGrid" [ngModelOptions]="{standalone: true}"> -->
                    <mat-radio-group class="example-radio-group" [value]="customerGrid">
                    <mat-radio-button class="example-radio-button" (change)="cgridChange($event)" value="1">Grid View   </mat-radio-button>
                    <mat-radio-button class="example-radio-button" (change)="cgridChange($event)" value="2">Mobile View   </mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
              <div class="row margin-top-10">
                <label class="col-md-3">Default Service Request list Heading:</label>
                <div class="col-md-9 uc-ngx-select">
                  <!-- <mat-form-field>
                    <mat-select multiple>
                      <mat-option *ngFor="let cc of additionalColumn" [value]="cc.id">{{cc.text}}</mat-option>
                      
                    </mat-select>
                  </mat-form-field> -->
                  <ngx-select [defaultValue]="preSelectCol" [autoClearSearch]="true" class="display-full margin-bottom-20" 
                  formControlName="service_list_items" [multiple]="true" [items]="additionalColumn" 
                  (select)="selectedCol($event)" (remove)="removeColOfSRList($event)" 
                  placeholder="Select Column"></ngx-select>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 form-block submit-block">
                  <button type="submit" mat-raised-button class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white" color="accent" title="Submit" style="max-width: 300px;">
                    SUBMIT
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- Add Customer page end -->
    </div>
  </div>
</div>
