import {Component, OnInit, ViewChild,ChangeDetectorRef,OnDestroy, ViewEncapsulation} from '@angular/core';
import {AbstractControl, FormGroup, FormControl, FormBuilder, Validators, FormArray} from '@angular/forms';
import {Router, ActivatedRoute, ParamMap, NavigationEnd, Params} from '@angular/router';
import {Http, Headers, RequestOptions, Response} from '@angular/http';
import {HttpHeaders} from '@angular/common/http';
import {globalFunctions} from "../../../environments/globalFunctions"
import {globalConstants} from "../../../environments/globalConstants"
import {GlobalService} from "../../../environments/global.service"
import {lang} from "../../../environments/lang-en"
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {TextMaskModule} from 'angular2-text-mask';
import {MatDialog} from '@angular/material/dialog';
import {AppComponent} from '../../app.component';
import { ImagecroppieComponent } from '../../imagecroppie/imagecroppie.component';
import { NgxSelectModule, INgxSelectOptions } from 'ngx-select-ex';
import { DefaultFormatter } from 'ng2-nouislider';
import * as moment from 'moment';
export class TimeFormatter implements DefaultFormatter {
    to(value: number): string {
      let h = Math.floor(value / 60);
      let m = Math.floor(value % 60);
      let values = [h, m];
      let timeString: string = '';
      values.forEach((_, i) => {
        if(values[i] < 10) {
          timeString += '0';
        }
        timeString += values[i].toFixed(0);
        if(i < 1) {
          timeString += ':';
        }
      });
      return timeString;
    };
    from(value: string): number {
      let v = value.split(':').map(parseInt);
      let time: number = 0;
      time += v[0] * 60;
      time += v[1] * 60;
      return parseInt(value);
    }
  }
@Component({
  selector: 'app-editshopprofile',
  templateUrl: './editshopprofile.component.html',
  styleUrls: ['./editshopprofile.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditshopprofileComponent implements OnInit,OnDestroy {
  public isCollapsed: boolean = true;
  public edit_shop: FormGroup;
  public currentShopDetails: any={};
  public countries: any = [];
  public states: any = []
  public cities: any = [];
  public selectedCountry;
  public selectedState;
  public selectedCity;
  public base64value = "";
  public selectedKey = "";
  public phoneMask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public taxIncluded: boolean;
  public supplyChargesIncluded: boolean;
  public businessLogo = globalConstants.S3_BUCKET_URL + globalConstants.BUSINESS_LOGO;
  public currentBusinesslogo = '';
  public currentSelectedLogo = '';
  public deleteLogo: boolean = false;
    public disableStates;
    public disableCities;
    public customerTypesOptions;
    public defaultCustomerType;
    public defaultOpenTime=600;
    public defaultCloseTime=1140;
    public someKeyboardConfig: any = {
        behaviour: 'drag',
        connect: true,
        start: [this.defaultOpenTime,this.defaultCloseTime],
        keyboard: true,  // same as [keyboard]="true"
        step: 15,
        pageSteps: 50,  // number of page steps, defaults to 10
        range: {
          min: 0,
          max: 1440
        },
        pips: {
          mode: 'values',
          density: 4,
          values: [0,60,120,180,240,300,360,420,480,540,600,660,720,780,840,900,960,1020,1080,1140,1200,1260,1320,1380],
          format: new TimeFormatter(),
          stepped: true
        },
        tooltips:[true,true]
      };
      public openingTime='';
      public closeingTime='';
      public getShopDetailComplete=false;
      public shopTimeConfig=this.someKeyboardConfig;
      public timezone='';
      public timezoneData=[];
      public timeslot_suggestions_lookup_days = '';
      public lookupDay = [{"id":"3","text":"3"},{"id":"5","text":"5"},{"id":"7","text":"7"}];
      public allowBypass;
      public roleId=0;
      public defaultNotifyTime=60;
      public notifyRangeConfig: any = {
        behaviour: 'drag',
        start: [this.defaultNotifyTime],
        keyboard: true,  // same as [keyboard]="true"
        step: 15,
        pageSteps: 50,  // number of page steps, defaults to 10
        range: {
          min: 0,
          max: 1440
        },
        pips: {
          mode: 'values',
          density: 4,
          values: [0,60,120,180,240,300,360,420,480,540,600,660,720,780,840,900,960,1020,1080,1140,1200,1260,1320,1380],
          format: new TimeFormatter(),
          stepped: true
        },
        tooltips:[true]
      };
      public notifyTime="";
      public showDefaultNotifyTime=false;
      public isInventory=false;
      public customerGrid=1
      public timeSlots:any = []; 
      public zipLabel = 'Zip Code';
      public subscriptionDataForAPI: Subscription = new Subscription();
      public currentLoginUserInfo: any;
      public gc = globalConstants;
      
    constructor(public app:AppComponent,private ref:ChangeDetectorRef,private formBuilder: FormBuilder, private route: ActivatedRoute, public titleService: Title, private http: Http, private router: Router, public globalService: GlobalService,public dialog: MatDialog) {
        this.timeSlots = globalConstants.TIME_SLOTS;
        this.currentLoginUserInfo = JSON.parse(localStorage.getItem("loggedInUser"));
        if (this.currentLoginUserInfo) {
            this.roleId=this.currentLoginUserInfo.role_id;
            this.edit_shop = this.formBuilder.group({
                company_name: ['', Validators.required],
                owner_name: ['', [Validators.required,Validators.pattern(globalConstants.NAME_REGEX)]],
                address_one: ['', Validators.required],
                address_two: [''],
                city: [''],
                state: [''],
                frm_state: [''],
                zipcode: ['', [Validators.required, Validators.pattern("^[0-9]+$")]],
                country: [''],
                frm_country: [''],
                business_phone1: [''],
                business_phone2: [''],
                cell_phone1: [''],
                cell_phone2: [''],
                shop_number: [''],
                email: ['', [Validators.required, Validators.pattern(globalConstants.EMAIL_REGEX)]],
                website: ['', [Validators.pattern(globalConstants.WEB_URL_REGEX)]],//
                licenses: [''],
                add_date: [''],
                modify_date: [''],
                status: [''],
                tax_rate: ['', [Validators.required, Validators.pattern(/^-?\d+(?:\.\d+)?$/),Validators.min(0),Validators.max(100)]],
                supply_charges: ['', [Validators.required, Validators.pattern(/^-?\d+(?:\.\d+)?$/)]],
                supply_charges_limit: ['', [Validators.pattern(/^-?\d+(?:\.\d+)?$/)]],
                shop_fax: [''],
                business_logo_imagedata: [''],
                include_tax_default: [''],
                tax_setting_status:[JSON.parse(localStorage.getItem("loggedInUser"))["tax_setting_status"]],
                include_supply_charges: [''],
                user_type: this.globalService.getCurrentUser()["user_type"],
                user_id: this.globalService.getCurrentUser()["id"],
                shop_id: this.globalService.getCurrentUser()["shop_id"],
                skip_forerver:[''],
                open_time:[''],
                close_time:[''],
                timezone:[''],
                timeslot_suggestions_lookup_days: [''],
                allow_available:[''],
                login_email:['', [Validators.required,Validators.pattern(globalConstants.EMAIL_REGEX)]],
                default_low_stock_value : [''],
                parts_stock_notification : [''],
                parts_notification_time : [''],
                parts_notify_weekday : [''],
                labor_tax_excluded: [''],
                listing_view:[],
                payment_balance_popup:[''],
                no_customer_address:[''],
                sr_title_required:[''],
                supply_charges_disp_type:[''],
                no_emails_owner:[''],
                shop_timings:this.formBuilder.array([]),
                selectedItems: [false,Validators.required],
                cc_fee: ['', [Validators.required, Validators.pattern(/^-?\d+(?:\.\d+)?$/)]],
                cc_fee_type:['1'],
                review_notes:[''],
                review_notes_included:[''],
                review_url:['',Validators.pattern(globalConstants.WEB_URL_REGEX)],
                review_url_included:[''],
                payment_notes:[''],
                payment_notes_included:[''],
                payment_url:['',Validators.pattern(globalConstants.WEB_URL_REGEX)],
                payment_url_included:[''],
                standard_mileage: ['', [Validators.pattern(/^\d+(\.\d)?$/)]], // Updated pattern for initial validation
                standard_days:['', [Validators.pattern(/^\d*$/)]],
                timer_control_status:[]
            });
            this.timings.valueChanges.subscribe((v) => {
                this.edit_shop.controls.selectedItems.setValue(this.mapItems(v));
            });
                   
            for(let i of [0,1,2,3,4,5,6]){
               this.timings.push(this.formBuilder.group({
                 weekdayid  : [false],
                 start_time : [''],
                 close_time : ['']
               },{validator: this.dateLessThan('start_time', 'close_time')}));
               this.timings.controls[i].get('weekdayid').valueChanges.subscribe(res=>{
                 if(res){
                   this.timings.controls[i].get('start_time').setValidators([Validators.required]);
                   this.timings.controls[i].get('close_time').setValidators([Validators.required]);
                 }else{
                   this.timings.controls[i].get('start_time').setValue("");
                   this.timings.controls[i].get('close_time').setValue("");
                   this.timings.controls[i].get('start_time').clearValidators();
                   this.timings.controls[i].get('close_time').clearValidators();
                 }
                 this.timings.controls[i].get('start_time').updateValueAndValidity();
                 this.timings.controls[i].get('close_time').updateValueAndValidity();
               });
             }

             this.subscriptionDataForAPI =  this.globalService.callAPI(globalConstants.API_GET_COUNTRIES_LIST, "opr=1").subscribe((data:any) => {
                this.countries = data.data;
                /* FOR SEARCH DROPDOWN BOX SELECT */
                for(var i in this.countries){
                    this.countries[i].id=this.countries[i].country_id;
                    this.countries[i].text=this.countries[i].country_name;                                        
                }
                /* END FOR SELECT */
                this.getShopProfileDetails();
                this.updateZipCodeVailation();
            })
          
            
        } else {
            this.router.navigate(["/shops/login"]);
        }
        setTimeout(()=>{
          let isEnable=this.globalService.getAcl('shop_acc_info','edits');
          if(!(isEnable)){
            this.router.navigate(['shops/action/forbidden']);
          }
        },globalConstants.RELOAD_WAIT);
        this.rangSliders([this.defaultOpenTime,this.defaultCloseTime]);
    }

      /**
      * update Zip codee validation
     */
      updateZipCodeVailation(){
        this.edit_shop.get("country").valueChanges.subscribe(country_name => {                
            if(country_name && Array.isArray(this.countries)){                 
                let zRegex = this.countries.find(res=>(res.country_id==country_name || res.country_name==country_name));
                if(zRegex && 'zipcode_regex' in zRegex){
                    this.zipLabel = zRegex.zipcode_label;
                    this.edit_shop.controls.zipcode.setValidators([Validators.required, Validators.pattern(zRegex.zipcode_regex)]);
                    this.edit_shop.controls.zipcode.updateValueAndValidity();
                }          
            }                
        });
    }

    mapItems(items) {
        let selectedItems = items.filter((item) => item.weekdayid).map((item) => item.id);
        return selectedItems.length ? selectedItems : null;
    }
    
    get timings(): FormArray {
        return this.edit_shop.get('shop_timings') as FormArray;
    }
    
    dateLessThan(from: string, to: string) {
        return (group: FormGroup): {[key: string]: any} => {
          let f = group.controls[from];
          let t = group.controls[to];
          if (f.value &&  t.value && (f.value > t.value || f.value == t.value)) {
            return {
              dates: "Start Time should be less than End Time"
            };
          }
          return {};
        }
    }

    getShopProfileDetails(){
        const shopContent = "user_id=" + this.globalService.getCurrentUser()["id"] + "&user_type=" + this.globalService.getCurrentUser()["user_type"] + "&shop_id=" + this.globalService.getCurrentUser()["shop_id"];
        this.subscriptionDataForAPI =  this.globalService.callAPI(globalConstants.API_SHOP_DETAILS, shopContent).subscribe((data:any) => {
            if(data.result=="1"){
            this.currentShopDetails = data.data;
            this.timezone=this.currentShopDetails.time_zone;
            this.getTimezone(this.currentShopDetails.country_id);
            this.edit_shop.controls.timezone.setValue(this.timezone);
            this.timeslot_suggestions_lookup_days = this.currentShopDetails.timeslot_suggestions_lookup_days;
            this.edit_shop.controls.timeslot_suggestions_lookup_days.setValue(this.timeslot_suggestions_lookup_days);
            this.allowBypass = this.currentShopDetails.allow_bypass;
            /* SET COUNTRY ID TO GET STATE */
            let selectedCountryForForm = 'USA';
            this.selectedCountry = 'USA';
            if (this.currentShopDetails.country != "") {
                //this.selectedCountry = this.currentShopDetails.country_id + "|" + this.currentShopDetails.country;
                this.selectedCountry = this.currentShopDetails.country_id;
                selectedCountryForForm = this.currentShopDetails.country;
            } 
            this.edit_shop.controls.country.setValue(selectedCountryForForm);
            this.getStates(this.selectedCountry);

            /* SET STATE ID TO GET CITY NAME */

            if (this.currentShopDetails.state != "") {
                //this.selectedState = this.currentShopDetails.state_id + "|" + this.currentShopDetails.state;
                this.selectedState = this.currentShopDetails.state_id;
            } else {
                this.selectedState = '';
            }

            this.edit_shop.controls.state.setValue(this.currentShopDetails.state);
            if(this.currentShopDetails.skip_forever==1){
                this.edit_shop.controls.skip_forerver.setValue(this.currentShopDetails.skip_forever);                
            }else{
                this.edit_shop.controls.skip_forerver.setValue(0);
            }

            if (this.currentShopDetails.allow_bypass == 1) {
                this.edit_shop.controls.allow_available.setValue(1);
            } else {
                this.edit_shop.controls.allow_available.setValue(0);
            }

            if(this.currentShopDetails.timer_control_status ==1){
                this.edit_shop.controls.timer_control_status.setValue(this.currentShopDetails.timer_control_status);                
            }else{
                this.edit_shop.controls.timer_control_status.setValue(0);
            }

            if(this.currentShopDetails.payment_balance_popup==1){
                this.edit_shop.controls.payment_balance_popup.setValue(this.currentShopDetails.payment_balance_popup);                
            }else{
                this.edit_shop.controls.payment_balance_popup.setValue(0);
            }

            if(this.currentShopDetails.no_customer_address==1){
                this.edit_shop.controls.no_customer_address.setValue(this.currentShopDetails.no_customer_address);                
            }else{
                this.edit_shop.controls.no_customer_address.setValue(0);
            }

            if(this.currentShopDetails.sr_title_required==1){
                this.edit_shop.controls.sr_title_required.setValue(this.currentShopDetails.sr_title_required);                
            }else{
                this.edit_shop.controls.sr_title_required.setValue(0);
            }

            if(this.currentShopDetails.no_emails_owner==1){
                this.edit_shop.controls.no_emails_owner.setValue(this.currentShopDetails.no_emails_owner);                
            }else{
                this.edit_shop.controls.no_emails_owner.setValue(0);
            }
            this.edit_shop.controls.review_notes.setValue(this.currentShopDetails.pvt_reviews_settings.review_notes);
            if(this.currentShopDetails.pvt_reviews_settings.review_notes_included == 1){
                this.handleIncludeReviewNotes(true);
            }else{
                this.handleIncludeReviewNotes(false);
            }

            this.edit_shop.controls.review_url.setValue(this.currentShopDetails.pvt_reviews_settings.review_url);
            if(this.currentShopDetails.pvt_reviews_settings.review_url_included == 1){
                this.handleIncludeReviewUrl(true);
            }else{
                this.handleIncludeReviewUrl(false);
            }

            this.edit_shop.controls.payment_notes.setValue(this.currentShopDetails.ext_payment_settings.payment_notes);
            if(this.currentShopDetails.ext_payment_settings.payment_notes_included == 1){
                this.handleIncludePaymentNotes(true);
            }else{
                this.handleIncludePaymentNotes(false);
            }

            this.edit_shop.controls.payment_url.setValue(this.currentShopDetails.ext_payment_settings.payment_url);
            if(this.currentShopDetails.ext_payment_settings.payment_url_included == 1){
                this.handleIncludePaymentUrl(true);
            }else{
                this.handleIncludePaymentUrl(false);
            }

            this.edit_shop.controls.standard_days.setValue(this.currentShopDetails.standard_days);
            this.edit_shop.controls.standard_mileage.setValue(this.currentShopDetails.standard_mileage);
            
            

            this.setShopTimes();
            this.getCities(this.selectedState);
            this.selectedCity = this.currentShopDetails.city;
            this.edit_shop.controls.city.setValue(this.currentShopDetails.city);

            this.currentBusinesslogo = this.currentShopDetails.business_logo;

            if (this.currentShopDetails.is_taxes_included == 1) {
                this.taxIncluded = true;
                this.edit_shop.controls.include_tax_default.setValue(1);
            } else {
                this.taxIncluded = false;
                this.edit_shop.controls.include_tax_default.setValue(0);
            }

          
            if (this.currentShopDetails.is_labor_tax_excluded == 1) {              
                this.edit_shop.controls.labor_tax_excluded.setValue('1');
            } else {               
                this.edit_shop.controls.labor_tax_excluded.setValue('0');
            }

            if (this.currentShopDetails.is_supplies_charges_included == 1) {
                this.supplyChargesIncluded = true;
                this.edit_shop.controls.include_supply_charges.setValue(1);
            } else {
                this.supplyChargesIncluded = false;
                this.edit_shop.controls.include_supply_charges.setValue(0);
            }
            this.edit_shop.controls.login_email.setValue(this.currentShopDetails.login_email);
            this.edit_shop.controls.company_name.setValue(this.currentShopDetails.company_name);
            this.edit_shop.controls.email.setValue(this.currentShopDetails.email_address);
            if(this.currentShopDetails.listing_view){
                this.customerGrid=this.currentShopDetails.listing_view
                this.edit_shop.controls.listing_view.setValue(this.currentShopDetails.listing_view)
            }
            this.updateGeneralInfoValidation();
            this.partNotifyTime();
            this.edit_shop.controls.supply_charges_disp_type.setValue(this.currentShopDetails.supply_charges_disp_type);
            if(this.currentShopDetails.supply_charges_disp_type == 2){
                this.edit_shop.controls.supply_charges_limit.setValue(this.currentShopDetails.supply_charges_limit);
                this.edit_shop.get('supply_charges').setValidators([
                    Validators.required,
                    Validators.min(0),
                    Validators.max(100),
                    Validators.pattern(/^-?\d+(?:\.\d+)?$/)
                ]);
                this.edit_shop.get('supply_charges').updateValueAndValidity();
            }
            this.edit_shop.controls.cc_fee_type.setValue(this.currentShopDetails.cc_fee_type);
            if(this.currentShopDetails.cc_fee_type == 2){
                this.edit_shop.get('cc_fee').setValidators([
                    Validators.required,
                    Validators.min(0),
                    Validators.max(100),
                    Validators.pattern(/^-?\d+(?:\.\d+)?$/)
                ]);
                this.edit_shop.get('cc_fee').updateValueAndValidity();
            }

            if(this.currentShopDetails.shopTimeLine && Array.isArray(this.currentShopDetails.shopTimeLine)){
                let uTime = {};        
                this.currentShopDetails.shopTimeLine.map((res,index)=>{ 
                  if(res.is_checked==1){       
                    uTime = {weekdayid:true,start_time:moment(res.open_time, ["hh:mm:ss"]).format("HH:mm"),close_time:moment(res.close_time, ["hh:mm:ss"]).format("HH:mm")}; 
                    this.timings.controls[index].patchValue(uTime);
                  }  
                });
            } 
          }
        });
    }

    stateValidation() {
        this.globalService.validateState(this.edit_shop, ['state','city']);
    }

    supplyChargeType(e, type) {
        this.edit_shop.controls.supply_charges_disp_type.setValue(type);
        if(type == 1){
            this.edit_shop.get('supply_charges').setValidators([Validators.required,Validators.pattern(/^-?\d+(?:\.\d+)?$/)]);
            this.edit_shop.get('supply_charges_limit').clearValidators();
            this.edit_shop.get('supply_charges').updateValueAndValidity();
            this.edit_shop.get('supply_charges_limit').updateValueAndValidity();
        }else{
            this.edit_shop.get('supply_charges').setValidators([
                Validators.required,
                Validators.min(0),
                Validators.max(100),
                Validators.pattern(/^-?\d+(?:\.\d+)?$/)
            ]);
            this.edit_shop.get('supply_charges_limit').setValidators(Validators.pattern(/^-?\d+(?:\.\d+)?$/));
            this.edit_shop.get('supply_charges_limit').updateValueAndValidity();
            this.edit_shop.get('supply_charges').updateValueAndValidity();
        }
    }

    CCChargeType(e, type) {
        this.edit_shop.controls.cc_fee_type.setValue(type);
        if(type == 1){
            this.edit_shop.get('cc_fee').setValidators([Validators.required,Validators.pattern(/^-?\d+(?:\.\d+)?$/)]);
            this.edit_shop.get('cc_fee').updateValueAndValidity();
        }else{
            this.edit_shop.get('cc_fee').setValidators([
                Validators.required,
                Validators.min(0),
                Validators.max(100),
                Validators.pattern(/^-?\d+(?:\.\d+)?$/)
            ]);
            this.edit_shop.get('cc_fee').updateValueAndValidity();
        }
    }
    
  validationForPhoneNumber(c: AbstractControl) {
    if (c.get('business_phone1') != null) {
      if (c.get('business_phone1').value.replace(/\D+/g, "").length <= 10) {
        c.get('business_phone1').setErrors({"invalidFormat": true});
        return {invalidFormat: true};
      } else {
        c.get('business_phone1').setErrors({"invalidFormat": false});
        c.get('business_phone1').valid;

      }
    }
  }

  ngOnInit() {
    this.globalService.setMetaData("SHOPS","EDIT_PROFILE");
    this.isInventory = this.globalService.isInventories();
  }

    EditShop() {
        this.stateValidation();
        if (this.edit_shop.valid) {
            /* BLANK COUNTRY CHECK */
            /*
            if (this.edit_shop.value.frm_country == undefined || this.edit_shop.value.frm_country == 'undefined') {
              this.edit_shop.controls.country.setValue('');
              this.edit_shop.controls.state.setValue('');
              this.edit_shop.controls.city.setValue('');
            } else {
              this.edit_shop.controls.country.setValue(this.customerCountryName);
            }

            if (this.customerStateName == undefined || this.customerStateName == '' || this.edit_shop.value.frm_state == "") {
              this.edit_shop.controls.state.setValue('');
              this.edit_shop.controls.city.setValue('');
            } else {
              this.edit_shop.controls.state.setValue(this.customerStateName);
            }

            if (this.edit_shop.value.city == 'undefined' || this.edit_shop.value.city == undefined || this.edit_shop.value.city == "null" || this.edit_shop.value.city == "" || this.edit_shop.controls.city == null) {
                this.edit_shop.controls.city.setValue('');
            } */


            //var stringifiedFormData = globalFunctions.stringifyFormData(this.edit_shop.value, null);
            if(!this.edit_shop.value.timezone || this.edit_shop.value.timezone == null || typeof(this.edit_shop.value.timezone) == 'undefined'){
                this.edit_shop.patchValue({timezone : globalConstants.DEFAULT_TIME_ZONE});
            }
            if(!this.edit_shop.value.timeslot_suggestions_lookup_days || this.edit_shop.value.timeslot_suggestions_lookup_days == null){
                this.edit_shop.patchValue({timeslot_suggestions_lookup_days : ''});
            }
            var headers = new Headers();
            var data = new FormData();

            for(let i in this.edit_shop.value){
                if(i=='shop_timings' && Array.isArray(this.edit_shop.value[i])){
                  let wTimings = this.edit_shop.value[i];
                  wTimings.map((data,index)=>{
                    if(!data['weekdayid']){
                        data['start_time'] = "";
                        data['close_time'] = "";
                    }
                    return data['weekdayid'] = index; 
                  });
                  data.append(i, JSON.stringify(wTimings));
                }else{
                  data.append(i, this.edit_shop.value[i]);
                }        
            }
            this.globalService.formData(globalConstants.API_EDIT_SHOP_PROFILE_URL, data).subscribe((data:any) => {
                const resultData = data;
                if (resultData.result == "1") {
                    var loggedInUserResponse = JSON.parse(localStorage.getItem("loggedInUser"));
                    loggedInUserResponse.tax_setting_status = "1";
                    localStorage.setItem("loggedInUser",JSON.stringify(loggedInUserResponse));
                    this.router.navigate(["/shops/dashboard"]);
                    this.globalService.snackbar('success', resultData.message);
                } else {
                    this.globalService.snackbar('error', resultData.message);
                }
            });
        } else {
            this.globalService.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
        }
    }


    showTaxDefault(event) {
      if (event.checked) {
        this.edit_shop.controls.include_tax_default.setValue('1');
      } else {
        this.edit_shop.controls.include_tax_default.setValue('0');
      }
    }

    showLaborTaxExcluded(event) {
        if (event.checked) {
          this.edit_shop.controls.labor_tax_excluded.setValue('1');
        } else {
          this.edit_shop.controls.labor_tax_excluded.setValue('0');
        }
    }
  

    showSupplyCharges(event) {
      if (event.checked) {
        this.edit_shop.controls.include_supply_charges.setValue('1');
      } else {
        this.edit_shop.controls.include_supply_charges.setValue('0');
      }
    }

    onlyDecimalNumberKey(event) {
        let charCode = (event.which) ? event.which : event.keyCode;
        if (charCode != 46 && charCode > 31
          && (charCode < 48 || charCode > 57))
          return false;
          return true;
    }

    disableKey(event) {
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if (event.ctrlKey == true && (event.which == '67' || event.which == '86' || String.fromCharCode(event.which).toLowerCase() == 's')) {
            event.preventDefault();
        }
    }

    getFile(evt, key) {
        this.selectedKey = key;
        var valid = this.globalService.validateFiles('image', evt.target.files);
        if (valid) {
            this.imageCroppieDialog(evt.target.files[0]);
            // reader.readAsDataURL(file);
        }
    }

      // _handleReaderLoaded(readerEvt) {
      //     var binaryString = readerEvt.target.result;
      //     this.base64value = (binaryString);
      //     // this.currentSelectedLogo = binaryString;
      //     if (this.selectedKey == "business_logo_imagedata") {
      //         this.edit_shop.controls.business_logo_imagedata.setValue(this.base64value);
      //     }
      // }

        /**
   *
   * @param event Get State by Country Value
   */
  getStates(countryID) {
    if (typeof(countryID) == 'undefined' || countryID == "") {
        this.disableStates = true;
        this.disableCities = true;
        this.cities=[];
    } else {
        this.disableCities = true;
        this.cities=[
            { id: "", text: "Select City" }
        ];
        let stateDDLPlaceholder = [
            { 
                id: "",
                state_id: "",
                state_name: "Select State",
                country_id: "",
                latitude: "",
                longitude: "",
                tax_rate: "",
                state_code: ""
             }
        ];
        this.states = [];
        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_STATES_URL, "opr=1&country_id=" + countryID).subscribe((data:any) => {
            var responseData = data;
            this.disableStates = false;
            if (responseData.result == "1") {
                this.states  = stateDDLPlaceholder.concat(responseData.data);
                for(var i in this.states){
                    this.states[i].id=this.states[i].state_id;
                    this.states[i].text=this.states[i].state_name;  
               }
            } else {
                this.states = [];
                this.cities = [];
                this.disableStates = false;
                this.selectedState = [{ id: "", text: "No state found" }];
                this.disableCities = true;
            }
        });
    }
}

  /**
   * Get Cities by State Id
   * @param event
   */
  getCities(stateId) {
    if (typeof(stateId) == 'undefined' || stateId == "") {
        this.cities = [];
        this.disableCities = true;
    } else {
       this.cities = [];
       let cityDDLPlaceholder=[{
        id: "",
        city_id: "",
        city_name: "Select City",
        state_id: "",
        country_id: "",
        latitude: "",
        longitude: ""
        }];
        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_CITIES_URL, "opr=1&state_id=" + stateId).subscribe((data:any) => {
            var responseData = data;
            if (responseData.result == "1") {
                this.disableCities = false;
                    this.cities = cityDDLPlaceholder.concat(responseData.data);
                    for(var i in this.cities){
                        this.cities[i].id=this.cities[i].city_name;
                        this.cities[i].text=this.cities[i].city_name;          
                    }
            } else {
                this.cities = [{id: "", text: "No city found"}];
                this.disableCities = false;
            }
        })
    }
}

   /* FOR COUNTRY/STATES/CITY SELECT */
    
   handleCountryDropdownChange(countryId) {
    this.getStates(countryId);
    let countryData = this.countries.find(countryInfo => countryId == countryInfo.id);
    this.edit_shop.controls.country.setValue(countryData.country_name);
    this.edit_shop.controls.state.setValue('');
    this.edit_shop.controls.city.setValue(''); 
    this.edit_shop.controls.timezone.setValue('');
    this.timezone = '';
    this.selectedState = '';
    this.selectedCity = '';
    this.getTimezone(countryId);
    this.edit_shop.get('timezone').setValidators([Validators.required]);
    this.edit_shop.get('timezone').updateValueAndValidity();
}

handleStateDropdownChange(stateId) {
    if (stateId !== '') {
        this.getCities(stateId);
        let stateData = this.states.find(stateInfo => stateId == stateInfo.id);
        this.edit_shop.controls.state.setValue(stateData.state_name);
        this.edit_shop.controls.city.setValue('');
        this.selectedCity = 'Select City';
    } else {
        this.edit_shop.controls.state.setValue('');
        this.edit_shop.controls.city.setValue('');
        this.selectedCity = 'Select City';
    }
}

    handleCityDropdownChange(cityName) {
        if (cityName !== 'Select City') {
            this.edit_shop.controls.city.setValue(cityName);
            this.selectedCity = cityName;
        } else {
            this.edit_shop.controls.city.setValue('');
            this.selectedCity = 'Select City';
        }
    }

    deleteBusinessLogo() {
        this.deleteLogo = true;
        this.globalService.imageLoader();
        let user_id = this.edit_shop.value.user_id;
        let shop_id = this.edit_shop.value.shop_id;
        this.globalService.confirmDialog(shop_id, 'Are you sure you want to delete this image?').subscribe((res) => {
            if (res != null) {
                let stringifiedFormData = 'user_id=' + user_id + '&shop_id=' + shop_id;
                this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_DELETE_BUSINESS_LOGO_URL, stringifiedFormData).subscribe((data:any) => {
                    const message = data.message;
                    if (data.result == "1") {
                        this.currentBusinesslogo = '';
                        this.globalService.snackbar('success', message);
                    } else {
                        this.globalService.snackbar("error", message);
                    }
                    this.deleteLogo = false;
                });
            }
        });

    }

    deleteCurrentLogo() {
        this.currentSelectedLogo = '';
        this.edit_shop.controls.business_logo_imagedata.setValue('');
    }

    phoneValidation(key) {
      this.globalService.preferedServicesPhoneValidity(this.edit_shop, key);
    }
    
    imageCroppieDialog(data): any {
        let dialogRef = this.dialog.open(ImagecroppieComponent, {
            panelClass: ['image-cropp-dialog'],
            width: '900px',
          data: {imgfile: data}
        });

        dialogRef.afterClosed().subscribe(result => {
        if(result.data != null){
                this.currentSelectedLogo =result.data;
                this.edit_shop.controls.business_logo_imagedata.setValue(result.data);
                this.ref.detectChanges();
          }
            let ele=<HTMLInputElement>document.getElementById('businessLogo');
            ele.value='';
        });
    }
    isAllowed(action,module?:any){
        if(typeof module!='undefined'){
            return this.globalService.getAcl(module,action);            
        }
        return false;
    }
    skipForever(event) {
        if (event.checked == true) {
            this.edit_shop.controls.skip_forerver.setValue(1);
        } else {
            this.edit_shop.controls.skip_forerver.setValue(0);
        }
    }
    
    /* BYPASS APPROVAL POPUP WHEN CLICK ON APPLY CHANGES BUTTON ON SERVICE REQUEST VIEW */
    allowAvailableByapssApproval(event) {
        if (event.checked == true) {
            this.edit_shop.controls.allow_available.setValue(1);
        } else {
            this.edit_shop.controls.allow_available.setValue(0);
        }
    }
    /* END */
    rangSliders(event){

        let h:any = Math.floor(event[0] / 60);
        let m:any = Math.floor(event[0] % 60);
        if((m).toString().length<=1){
            m='0'+m;
        }
        if((h).toString().length<=1){
            h='0'+h;
        }
        this.edit_shop.controls.open_time.setValue(h+':'+m);
        let AMPM=' AM';
        if(h >= 12){
            h=(h-12 ? h-12:h);
            AMPM=' PM';
        }
        if((h).toString().length<=1){
            h='0'+h;
        }
        this.openingTime=h+':'+m+AMPM;
        
        let h1:any = Math.floor(event[1] / 60);
        let m1:any = Math.floor(event[1] % 60);
        if((m1).toString().length<=1){
            m1='0'+m1;
        }
        if((h1).toString().length<=1){
            h1='0'+h1;
        }
        this.edit_shop.controls.close_time.setValue(h1+':'+m1);
        let AMPM1=' AM';
        if(h1 >= 12){
            h1=( h1-12 ? h1-12 : h1);
            AMPM1=' PM';
        }
        if((h1).toString().length<=1){
            h1='0'+h1;
        }
        this.closeingTime=h1+':'+m1+AMPM1;
        
    }
    /** shop time start */
    setShopTimes() {
        if (this.currentShopDetails.open_time != '00' && this.currentShopDetails.open_time.indexOf(':') != -1) {
            let v = this.currentShopDetails.open_time.split(':');
            this.defaultOpenTime = (parseInt(v[0]) * 60) + parseInt(v[1]);
            if (this.currentShopDetails.close_time != '00' && this.currentShopDetails.close_time.indexOf(':') != -1) {
                let close = this.currentShopDetails.close_time.split(':');//.map(parseInt);
                this.defaultCloseTime = (parseInt(close[0]) * 60) + parseInt(close[1]);
            }
            this.rangSliders([this.defaultOpenTime, this.defaultCloseTime]);
        }
        this.shopTimeConfig.start=[this.defaultOpenTime, this.defaultCloseTime];
        this.getShopDetailComplete=true;
    }
    /** shop time end */

    /** shop timezone start */
    getSelectedOptionTimezone(event){
        if(event !== ''){
            this.edit_shop.controls.timezone.setValue(event);
        }else{
            this.edit_shop.controls.timezone.setValue('');
        }
    }

    getSelectedOptionLookUp(event){
        this.edit_shop.controls.timeslot_suggestions_lookup_days.setValue(event);
    }
    /** shop timezone end */
    /** get all timezone start */
    getTimezone(Id){        
        let data={country_id:Id ,user_type: this.globalService.getCurrentUser()["user_type"],user_id: this.globalService.getCurrentUser()["id"]};
        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_TIMEZONE,data).subscribe((zones:any)=>{
            if(zones.result=='1'){
                let timeZoneDDLPlaceHolder =[{
                    text: "Select Time Zone", 
                    id: ""
                }];
                this.timezoneData=timeZoneDDLPlaceHolder.concat(zones.data);
            }else{
                this.timezoneData=[];
            }
            this.edit_shop.controls.timezone.setValue(this.timezone);
        });
    }
    /** get all timezone end */    
    /* part notify slider*/
    notifyRangSliders(event){
        
        let h:any = Math.floor(event / 60);
        let m:any = Math.floor(event % 60);
        if((m).toString().length<=1){
            m='0'+m;
        }
        if((h).toString().length<=1){
            h='0'+h;
        }
        this.edit_shop.controls.parts_notification_time.setValue(h+':'+m);
        let AMPM=' AM';
        if(h >= 12){
            h=( h-12 ? h-12 : h);
            AMPM=' PM';
        }
        if((h).toString().length<=1){
            h='0'+h;
        }
        this.notifyTime=h+':'+m+AMPM;
    }
    partNotifyTime(){
        let notifyTime=this.currentShopDetails.part_notify_time;
        if(notifyTime && notifyTime.indexOf(":")!=-1){
            let t = this.currentShopDetails.part_notify_time.split(':');
            let time = (parseInt(t[0]) * 60) + parseInt(t[1]);
            this.defaultNotifyTime=time;
            this.notifyRangSliders(time);
            this.notifyRangeConfig.start=[time];
            this.showDefaultNotifyTime=true;
        }else{
            this.notifyRangSliders(this.defaultNotifyTime);
        }
    }
    
    /**
     * Function to toggle and set the value of payment_balance_popup key in the form.
     */
    togglePaymentBalancePopup(event) {
        if (event.checked == true) {
            this.edit_shop.controls.payment_balance_popup.setValue(1);
        } else {
            this.edit_shop.controls.payment_balance_popup.setValue(0);
        }
    }

    toggleNoAddress(event) {
        if (event.checked == true) {
            this.edit_shop.controls.no_customer_address.setValue(1);
        } else {
            this.edit_shop.controls.no_customer_address.setValue(0);
        }
    }
    srTitleRequired(event) {
        if (event.checked == true) {
            this.edit_shop.controls.sr_title_required.setValue(1);
        } else {
            this.edit_shop.controls.sr_title_required.setValue(0);
        }
    }
    handleNoEmails(event){
        if (event.checked == true) {
            this.edit_shop.controls.no_emails_owner.setValue(1);
        } else {
            this.edit_shop.controls.no_emails_owner.setValue(0);
        }
    }
    
    updateDefaultTime(i:number){
        if(this.timings.controls[i].get('weekdayid').value){
            this.timings.controls[i].get('start_time').setValue("");
            this.timings.controls[i].get('close_time').setValue("");    
        }
    }

    setCountryStateCityViaZipCode(){
        let payload = {
             user_id: this.currentLoginUserInfo.id,
             user_type: "User",
             zip_code: this.edit_shop.controls.zipcode.value
        }
        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_STATE_CITY_VIA_ZIPCODE,payload).subscribe((response:any)=>{
            if(response.result == 1){
                if(response.data.country_info.country_id !== 0 
                    && response.data.state_info.state_id !== 0 
                    &&  response.data.city_info.city_id !== 0 ){
                    this.selectedCountry = response.data.country_info.country_code;
                    let oldNewCustomerCountry = this.edit_shop.value.country;
                    this.edit_shop.controls.country.setValue(response.data.country_info.country_name);
                    this.getStates(response.data.country_info.country_code);
                    this.selectedState = response.data.state_info.state_id;
                    this.edit_shop.controls.state.setValue(response.data.state_info.state_name);
                    this.getCities(response.data.state_info.state_id);
                    this.selectedCity = response.data.city_info.city_name;
                    this.edit_shop.controls.city.setValue(this.selectedCity);
                    if (oldNewCustomerCountry !== this.selectedCountry) {
                        this.edit_shop.controls.timezone.setValue('');
                        this.timezone = '';
                        this.getTimezone(response.data.country_info.country_code);
                        this.edit_shop.get('timezone').setValidators([Validators.required]);
                        this.edit_shop.get('timezone').updateValueAndValidity();
                    }
                }
            }
      })
    }

    ngOnDestroy(){
        this.subscriptionDataForAPI.unsubscribe();
    }

    handleIncludeReviewNotes(event:any){
        if (event == true) {
            this.edit_shop.controls.review_notes_included.setValue(1);
            this.edit_shop.get('review_notes').setValidators([Validators.required]);
            this.edit_shop.get('review_notes').updateValueAndValidity();
        } else {
            this.edit_shop.controls.review_notes_included.setValue(0);
            this.edit_shop.get('review_notes').clearValidators();
            this.edit_shop.get('review_notes').updateValueAndValidity();
        }
        
    }

    handleIncludeReviewUrl(event:any){
        if (event == true) {
            this.edit_shop.controls.review_url_included.setValue(1);
            this.edit_shop.get('review_url').setValidators([Validators.required,Validators.pattern(globalConstants.WEB_URL_REGEX)]);    
        } else {
            this.edit_shop.controls.review_url_included.setValue(0);
            this.edit_shop.get('review_url').setValidators(Validators.pattern(globalConstants.WEB_URL_REGEX));
        }
        this.edit_shop.get('review_url').updateValueAndValidity();
    }

    handleIncludePaymentNotes(event:any){
        if (event == true) {
            this.edit_shop.controls.payment_notes_included.setValue(1);
            this.edit_shop.get('payment_notes').setValidators([Validators.required]);
            this.edit_shop.get('payment_notes').updateValueAndValidity();
        } else {
            this.edit_shop.controls.payment_notes_included.setValue(0);
            this.edit_shop.get('payment_notes').clearValidators();
            this.edit_shop.get('payment_notes').updateValueAndValidity();
        }
    }

    handleIncludePaymentUrl(event:any){
        if (event == true) {
            this.edit_shop.controls.payment_url_included.setValue(1);
            this.edit_shop.get('payment_url').setValidators([Validators.required,Validators.pattern(globalConstants.WEB_URL_REGEX)]);
        } else {
            this.edit_shop.controls.payment_url_included.setValue(0);
            this.edit_shop.get('payment_url').setValidators(Validators.pattern(globalConstants.WEB_URL_REGEX));
        }
        this.edit_shop.get('payment_url').updateValueAndValidity();
    }

    validateNumbersNoDecimal(event) {
        return globalFunctions.validateNumbersNoDecimal(event);
      }

    validateNumbersWithDecimal(event,limit) {
        return globalFunctions.validateNumbersWithDecimal(event,limit);
    }

    /**
   * validates the form control's value on paste event.
   * ensure that validation messages are displayed if the pasted value is invalid.
   */
  validateFieldOnPaste(controlName: string) {
    const control = this.edit_shop.get(controlName);

    control.markAsTouched();
  }
    
    updateGeneralInfoValidation() {
        if (this.roleId == 1) {
            this.edit_shop.get('email').setValidators([Validators.required, Validators.pattern(globalConstants.EMAIL_REGEX)]);
            this.edit_shop.get('company_name').setValidators([Validators.required]);
        } else {
            this.edit_shop.get('email').clearValidators();
            this.edit_shop.get('company_name').clearValidators();
        }
        this.edit_shop.get('email').updateValueAndValidity();
        this.edit_shop.get('company_name').updateValueAndValidity();
    }

    handleTimerControl(event) {
        if (event.checked == true) {
            this.edit_shop.controls.timer_control_status.setValue(1);
        } else {
            this.edit_shop.controls.timer_control_status.setValue(0);
        }
    }

    convertIntoDecimal(event,frmControlName,beforeDecimalDigit,digitAfterDecimal){
        let fieldValue = this.edit_shop.get(frmControlName).value
        let result= this.globalService.processDecimalValue(fieldValue,beforeDecimalDigit);
        this.edit_shop.get(frmControlName).setValue(result);
        return globalFunctions.validateNumbersWithDecimal(event,digitAfterDecimal);
      }
}

