<div>
    <div class="panel panel-primary">
        <div class="panel-heading  padding-left-15 list-customer-car">
            <h3 class="panel-title">LIST {{ currentCustomer.profile_type == '2' ? 'CONTACT' : 'CUSTOMER' }} CARS</h3>
            <button type="button" name="btncsv" class="btn btn-success btn-download-csv"  title="Download CSV File" (click)="downloadCSV()">Download CSV File</button>
            <button mat-mini-fab class="custom-btn-back" (click)="globalService.goBack();">
                <mat-icon>arrow_back</mat-icon>
            </button>
        </div>
        <div class="panel-body bgcolor-lightgrey">
            <div class="row">
                <div class="col-md-4"></div>
                <div class="col-md-2 text-right" *ngIf="!isCountryValid() || currentCustomer.subscription_data.partstech_db != '1'"></div>
                <div class="col-md-2 text-right"></div>
                <div class="col-md-2 text-right">
                    <p><a href="javascript:void(0);" class="hover-link" (click) = "addCar(sendParams)"><i class="fa fa-plus fa-lg margin-right-5"></i>Add New Car</a>
                    </p>
                </div>
                <div class="col-md-2 text-right">
                    <p><a href="javascript:void(0)" class="hover-link" (click)="scanVINDialog(sendParams,listCustomerID)"><i class="fa fa-barcode fa-lg margin-right-5"></i>VIN Scan</a>
                    </p>
                </div>
                <div class="col-md-2 text-right" *ngIf="isCountryValid() && currentCustomer.subscription_data.partstech_db == '1'">
                    <p><a href="javascript:void(0)" class="hover-link" (click)="openLicensePlateDialog(sendParams,listCustomerID)"><i class="fa fa-car fa-lg margin-right-5"></i>License Plate</a>
                    </p>
                </div>
            </div>
            <div id="customerInformation" *ngIf="viewCustomerInfo" class="jumbotron" style="padding:25px;">
                <h3>{{ currentCustomer.profile_type == '2' ? 'Contact' : 'Customer' }} Information</h3>
                <hr style>
                <div class="row">
                    <div class="col-xs-12 col-sm-6">
                        <div class="row form-control-static">
                            <div class="col-xs-6"><label class="pull-left">{{ currentCustomer.profile_type == '2' ? 'Contact' : 'Customer' }} Name: </label></div>
                            <div class="col-xs-6">
                                <span class="pull-left break-word" *ngIf="carCustomerDetails">
                                    {{carCustomerDetails.first_name}} {{carCustomerDetails.last_name}}
                                </span>
                            </div>
                        </div>
                        <div class="row form-control-static">
                            <div class="col-xs-6"><label class="pull-left">Address: </label></div>
                            <div class="col-xs-6" *ngIf="carCustomerDetails">
                                <span class="break-word">{{getCustomerAddress(carCustomerDetails) || '-'}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6">
                        <div class="row form-control-static">
                            <div class="col-xs-6"><label class="pull-left">Email: </label></div>
                            <div class="col-xs-6 break-all">
                                <span class="pull-left" *ngIf="carCustomerDetails">
                                    <a class="text-black" href="mailto:{{carCustomerDetails.email}}"> {{carCustomerDetails.email}}</a>
                                </span>
                            </div>
                        </div>
                        <div class="row form-control-static">
                            <div class="col-xs-6"><label class="pull-left">Contact Number: </label></div>
                            <div class="col-xs-6" *ngIf="carCustomerDetails">
                                <span><a class="text-black" href="tel:{{carCustomerDetails.mobile_phone}}" id="mobilePhone">{{carCustomerDetails.mobile_phone}}</a></span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="list-group margin-bottom-15" id="myCarsListView" *ngFor="let currentCustomerVehicle of currentCustomerVehicles">
                 <div class="list-group-item relative">
                    <div class="row list-row" (click)="viewCar(carCustomerDetails.id,currentCustomerVehicle.id)">
                        <div class="col-xs-5 col-sm-2">
                            <img class="img-responsive" src="{{imgPath}}default-car.png" *ngIf="currentCustomerVehicle.vehicle_pic == ''">
                            <img class="img-responsive" src="{{imgPath}}{{currentCustomerVehicle.vehicle_pic}}" *ngIf="currentCustomerVehicle.vehicle_pic != ''">
                        </div>
                        <div class="col-xs-7 col-sm-10">
                            <h2 class="car-name">{{currentCustomerVehicle.car_name}} &nbsp;&nbsp;
                                <mat-chip color="primary" selected="true" *ngIf="carCustomerDetails && currentCustomerVehicle && currentCustomerVehicle.owner_id != carCustomerDetails.id">Temp</mat-chip>
                                <mat-chip color="primary" selected="true" *ngIf="currentCustomerVehicle.is_walkin_customer_vehicle == '1'">Walkin</mat-chip>
                            </h2>
                            <p class="car-info" style="text-transform:uppercase;"><span [innerHTML]="currentCustomerVehicle.license_plate ? currentCustomerVehicle.license_plate : 'N/A' "></span> <span *ngIf="currentCustomerVehicle.unit_number">({{currentCustomerVehicle.unit_number}})</span></p>
                            <p class="car-info" [innerHTML]="currentCustomerVehicle.color ? currentCustomerVehicle.color : 'N/A' "></p>
                        </div>
                    </div>
                    <button mat-icon-button class="settings-btn" [matMenuTriggerFor]="menu">
                        <mat-icon>view_module</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu" class="car-icon-menu">
                        <!-- <button mat-menu-item (click)="viewCarHistory(currentCustomerVehicle.customer_id,currentCustomerVehicle.id)"><i class="fa fa-list"></i>View History </button> -->
                        <button mat-menu-item (click)="openServiceHistoryPopup(currentCustomerVehicle)">
                            <!-- <i class="fa fa-list"></i> -->
                            <img [src]="gc.SERVICE_HISTORY_ICON" style="width:16px; margin-right:5px;" />
                            View History 
                        </button>
                        <button mat-menu-item (click)="viewCar(carCustomerDetails.id,currentCustomerVehicle.id)"><i class="fa fa-file-text"></i>View</button>
                        <button mat-menu-item (click)="editCar(carCustomerDetails.id,currentCustomerVehicle.id,currentCustomerVehicle.is_walkin_customer_vehicle)" *ngIf="carCustomerDetails && currentCustomerVehicle && currentCustomerVehicle.owner_id == carCustomerDetails.id"><i class="fa fa-edit" ></i>Edit</button>
                        <button mat-menu-item (click)="deleteCar(currentCustomerVehicle.id,carCustomerDetails.id,currentCustomerVehicle.is_walkin_customer_vehicle)" *ngIf="carCustomerDetails && currentCustomerVehicle && currentCustomerVehicle.owner_id == carCustomerDetails.id"><i class="fa fa-trash"></i>Delete</button>
                        <button mat-menu-item (click)="makeNewRequest(currentCustomerVehicle.id,carCustomerDetails.id,currentCustomerVehicle.vin)"><i class="fa fa-plus"></i>New Request</button>
                    </mat-menu>
                </div>
            </div>
            <div class="subscription-box" *ngIf="carCustomerDetails && carCustomerDetails.is_sublet === '0' && carLength == 1">
                <div class="btn-lg alert-danger disabled">
                    <div class="subscription-content">
                        No cars added yet. <br /><a href="javascript:void(0)" class="btn mat-success" mat-raised-button style="min-width: 150px;" (click) = "addCar(sendParams)">Add Now</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- New Footer with social media links-->
    </div>
</div>