import { Component, OnInit, Inject, ViewChild,OnDestroy } from '@angular/core';
import { GlobalService } from '../../../../environments/global.service';
import { globalConstants } from '../../../../environments/globalConstants';
// import { MatDialog, MatPaginator, MatSort, MatDatepickerInputEvent } from '@angular/material';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatDatepickerInputEvent } from '@angular/material/datepicker'
import { AddinternalnoteComponent } from '../addinternalnote/addinternalnote.component';
import { ViktableComponent, VikSource } from '../../../admin/viktable/viktable.component';
import { ViewnoteComponent } from '../viewnote/viewnote.component';
import { Router } from '@angular/router';
import { EditnoteComponent } from '../editnote/editnote.component';
const UPLOAD_LIST = "internal-note-";
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-internalnotes',
  templateUrl: './internalnotes.component.html',
  styleUrls: ['./internalnotes.component.scss']
})
export class InternalnotesComponent extends ViktableComponent implements OnInit {

  public user: any = {};
  displayedColumns = ['ID','Name','status', 'Added On', 'Actions'];
  status=['Created','Assigned','Resolved','Unread'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: any;
  public tablesData = this;
  public options:any = {
    user_type: "User",
    user_id: "",
    search_by: "",
    keyword: "",
    datef:'',
    datet:'',
    from:'',
    to:'',
    status:'',
    start: 0,
    limit: 10
  }
  url = "";
  searchByArray=[{id:1,text:'Title'},{id:2,text:'Creator'},{id:3,text:'Comment'}]
  public isInternalNotes=false;
  public subscriptionDataForAPI: Subscription = new Subscription();

  constructor(public router: Router,public gs: GlobalService, public dialog: MatDialog) {
    super(globalConstants.API_GET_INTERNAL_NOTE, gs);
    this.user = this.gs.getCurrentUser();
    this.isInternalNotes= this.gs.isSubscribe('internal_notes');
    if (this.user) {
      this.sessionKey = UPLOAD_LIST;
      this.url = globalConstants.API_GET_INTERNAL_NOTE;
      this.gs.setMetaData("SHOPS", "INTERNAL_NOTES_LIST");
    } else {
      this.router.navigate(['shops']);
    }
  }

  ngOnInit() {
    if(!this.isInternalNotes){
      this.router.navigate(['shops/dashboard']);
    }
    this.reInit();
    let status=this.gs.localGetter('status',UPLOAD_LIST)
    if(status){
      this.options.status=parseInt(status);
    }
   
  }

  ngAfterViewInit(){
    this.setDataTable();
  }
  /** open Add Notes */
  internalNotes() {
    let dialogRef = this.dialog.open(AddinternalnoteComponent, {
      panelClass: 'car-dialog-form',
      width: "800px",
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result.result==1){
        this.clearSearch();
      }
    });
  }
  /** get status */
  getStatus(i){
    return (typeof this.status[i]!='undefined' ? this.status[i]:'-')
  }
  /** init options */
  reInit() {
    this.options = this.gs.localGetterSetter(this.options, this.options, UPLOAD_LIST);
    this.options.user_type = this.user.user_type;
    this.options.user_id = this.user.id;
  }
  /** set data source of smart batch part list */
  setDataTable() {
    this.dataSource = new VikSource(this.tablesData, this.sort, this.paginator);
    this.dataSource.getSortedData = this.sortedData;
  }
  /** create record, that show on smart batch part list */
  createRecordrecord(rec) {
    return {
      id: rec.id,
      name: rec.title,
      status:rec.status,
      added_on: this.gs.getFullFormateDate(rec.add_date, 'GD', 'H'),
      read_status:rec.read_status,
      added_by:rec.added_by
    };
  }
  /** sort smart batch part list */
  sortedData(): any[] {
    let th: any = this;
    const data = th._exampleDatabase.data.slice();
    if (!th._sort.active || th._sort.direction == '') { return data; }
    return data.sort((a, b) => {
      let propertyA;
      let propertyB;
      switch (th._sort.active) {
        case 'ID': propertyA = a.id; propertyB = b.id; break
        case 'Name': propertyA = a.name; propertyB = b.name; break;
        case 'Added On': propertyA = a.added_on; propertyB = b.added_on; break;
        case 'status': propertyA = a.added_on; propertyB = b.added_on; break;
        default: return 0;
      }
      let valueA = isNaN(propertyA) ? propertyA.toUpperCase() : propertyA;
      let valueB = isNaN(propertyB) ? propertyB.toUpperCase() : propertyB;

      return (valueA < valueB ? -1 : 1) * (th._sort.direction == 'asc' ? 1 : -1);
    });
  }
  /**
   * @updatedBy Vikas Kumar
   * @trackID <:CAR-1520>
   * @updated 22-04-2020
   * @see Function clear search
   */
  clearSearch() {
    this.gs.localGetterSetter(null,this.options,UPLOAD_LIST);
    this.reInit();
    this.options.status=""
    this.options.keyword = "";
    this.setDataTable();
  }
  search() {
    this.options.start = 0;
    if (this.options.datef != "" && this.options.datef != null) {
      this.options.from = this.dateFormatWithoutTime(this.options.datef)
    }
    if (this.options.datet != "" && this.options.datet != null) {
      this.options.to = this.dateFormatWithoutTime(this.options.datet)
    }
    this.setDataTable();
  }
  /** note mark as read */
  viewNote(row){
    console.log(row);
    if(row.read_status==1){
      this.redirectToView(row)
    }else{
      let data = { user_id: this.user.id,user_type: this.user.user_type,note_id:row.id,status:1};
      let url=globalConstants.API_ADD_INTERNAL_NOTE_COMMENT;
      this.subscriptionDataForAPI = this.gs.callAPI(url, data).subscribe((r:any) => {
        if (r.result == 1) {
          this.redirectToView(row)
        }else if(r.code=499){
          this.redirectToView(row)
        }
      }, (error) => { })
    }
  }
  /** redirect to view internal note detail */
  redirectToView(row){
    // this.router.navigate(['shops/internalnote/view/'+row.id])
    this.gs.localGetterSetter('internal-note', row.id,'');
    let dialogRef = this.dialog.open(ViewnoteComponent, {
        panelClass: 'dialog-view-note',
    });
  }
  markAsResolved(row){
    let data = { user_id: this.user.id, user_type: this.user.user_type, note_id: row.id };
    /** mark as resolved API change */
    // this.gs.callAPI(globalConstants, data).subscribe((r) => {
    //   if (r.result == 1) {
    //     this.gs.snackbar("success", r.message);
    //   }else{
    //     this.gs.snackbar("error", r.message);
    //   }
    // }, (error) => { })
  }
   /** redirect to edit internal note detail */
  edit(row){
    // this.router.navigate(['shops/internalnote/edit/'+row.id])
    let dialogRef = this.dialog.open(EditnoteComponent, {
      panelClass: 'car-dialog-form',
      width: "800px",
      data: row
    });
    dialogRef.afterClosed().subscribe(result => {
       if(result==2){
        this.redirectToView(row)
      }
    });
  }
  /***search active*****/
  isAnyOptionActive(button?: any) {
    if (this.options.search_by == "" && this.options.keyword == "" && this.options.datef == "" && this.options.datet == "" && this.options.status == "") {
      if (button) { button.color = "primary" }
      return false;
    } else {
      if (button) { button.color = "accent" }
      return true;
    }
  }
  setTouchViewValue() {
    return true;
  }
  dateFormatWithoutTime(dateValue) {
    return ((new Date(dateValue).getMonth()+1) < 10 ? '0' : '')+(new Date(dateValue).getMonth()+1) + "/" + (new Date(dateValue).getDate() < 10 ? '0' : '')  + new Date(dateValue).getDate() + "/" + new Date(dateValue).getFullYear();
  }
  setDate(event: MatDatepickerInputEvent<Date>) {
    if(event.value){
      this.options.datet = event.value
    }
  }

  ngOnDestroy(){
    this.subscriptionDataForAPI.unsubscribe();
}  
}
