import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from '../../../environments/global.service';
import { globalConstants } from '../../../environments/globalConstants';
@Component({
  selector: 'app-tempCustomerApproval',
  templateUrl: './tempCustomerApproval.component.html',
})
export class TempCustomerApprovalComponent implements OnInit {

  customer_id;
  shop_id;
  isLinkUsed = 0; 
  constructor(private route: ActivatedRoute, public globalService: GlobalService,public router: Router) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.customer_id = params['cid'];
      this.shop_id = params['sid'];
    });
    this.allow(1);
  }

  allow(isLinkExpireCheck = 0){
    var opr = "user_type=User&customer_id="+this.customer_id+"&shop_id="+this.shop_id+"&isLinkExpireCheck="+isLinkExpireCheck;
    this.globalService.callAPI(globalConstants.API_UPDATE_CUSTOMER_MAPPING_STATUS, opr).subscribe((data:any) => {
      if (data.result == 1) {
        if(isLinkExpireCheck == 0){
          this.globalService.snackbar('success', data.message);
          this.back();
        }
      } else {
          this.isLinkUsed = 1;
          if(isLinkExpireCheck == 0){
            this.globalService.snackbar('error', data.message);
          }
      }
    }) 
  }

  back(){
    window.location.href = '/shops/dashboard';
  }
}
