 export const globalFunctions = {

  stringifyFormData(object,userType){
    var total_keys = Object.keys(object).length;
    var stringifiedFormData = "";
    for(var i=0;i < total_keys;i++) {
      let val=(typeof Object.values(object)[i] =="undefined" ? "" : Object.values(object)[i]);
      var objectPair = Object.keys(object)[i]+"="+val;
      objectPair = objectPair+"&";
      stringifiedFormData = stringifiedFormData + objectPair;
    }
    if(userType!=null) {
      return (stringifiedFormData +'user_type='+ userType)
    } else {
      return (stringifiedFormData)
    }
  },
   onlyDecimalNumberKey(event) {
    let e = <KeyboardEvent> event;
    return /^\d+(\.\d{1,2})?$/.test(e.key) || e.key === '.';
    },
    formatDate(unformattedDate) {
      return (new Date(unformattedDate).getMonth() + 1) + "/" + new Date(unformattedDate).getDate() + "/" +  new Date(unformattedDate).getFullYear()
    },
    getFormattedDateTime(dt){
      var dateArr = dt.split(" ");
      var date = dateArr[0];
      var time = dateArr[1];
      var formattedDateArr = date.split('-');
      var formattedTimeArr = time.split(':');
      
      var year = formattedDateArr[0];
      var month = formattedDateArr[1];
      var day = formattedDateArr[2];
      
      var h = formattedTimeArr[0];
      var m = formattedTimeArr[1];
      var s = formattedTimeArr[2];
      
      var ampm = "AM";
    
      if (Number(h) >= 12) {
        h = Number(h)-12;
        ampm = "PM";
      }
      if (Number(h) == 0) {
        h = 12;
      }
      
      if(Number(h) < 10){
        h = '0' + Number(h);
      }
      if(Number(m) < 10 && Number(m) > 0){
        m = '0' + Number(m);
      }
      if(Number(s) < 10 && Number(s) > 0){
        s = '0' + Number(s);
      }
      
      var formattedDate = month+"/"+day+"/"+year;
      var formattedTime = h+":"+m+":"+s+" "+ampm;
      if(year == '0000' || month == '00' || day == '00'){
        return "-";
      }
      else{
        return formattedDate + " " + formattedTime;
      }
    },
    checkIfObjectIsEmpty(obj) {

       var total_keys = Object.keys(obj).length;
       var isEmpty = 0;
       for(var i =0;i<total_keys;i++) {
           if(Object.values(obj)[i] == "") {
               isEmpty ++;
           }
       }
       
       return (isEmpty == total_keys) ?  true : false
    },
    getCurrentTimeStamp(){
      var sec=Math.floor((new Date().getTime())/1000);
      return Math.floor(sec/60);
    },
    hasDecimal(value,isNumber?:boolean){
      if(isNaN(value)){
        value="0.00";
      }
      let v =( isNumber && (! isNaN(value)) ? parseFloat(value).toString() : value);
      let l= v.split('.');
      if(l.length == 2){
          let n=parseFloat(v);
          return (isNaN(n) ? '0' : n.toFixed(2) );
      }
      return v;
    },
   carColors() {
     let colors = [
       {
          id:'',
          text :'Select Color'
       },
       {
         id: 'White',text: "White/White Pearl",
       },
       {
         id: 'Black',text: "Black",
       },
       {
         id: 'Silver',text: "Silver",
       },
       {
         id: 'Red',text: "Red",
       },
       {
         id: 'Gray',text: "Gray",
       },
       {
         id: 'Blue',text: "Blue",
       },
       {
         id: 'Brown',text: "Beige/Brown",
       },
       {
         id: 'Green',text: "Green",
       },
       {
         id: 'Yellow',text: "Yellow/Gold",
       }
     ]
     return colors;
   },
   wheelLock() {
     let lockLocation = [
       { id:"Glove Box",text: "Glove Box" },
       { id:"Trunk",text: "Trunk" }
     ]
     return lockLocation
   },
   customFieldType(){
    return [
      { id:'textbox',text:'Textbox'},
      { id:'checkbox',text:'Checkbox'},
      { id:'radio',text:'Radio'},
      { id:'dropdown',text:'Dropdown'}
    ]
   },
   requestOrigin(){
    return [
      {value:1,label:'Email'},
      {value:2,label:'Phone'},
      {value:3,label:'Support Ticket'}
    ]
   },
   supportChat(){
    let win:any=window;
    if(win.LCAPIV){
      win.Tawk_API.toggle();
    }else{
      win.initLC()
      win.Tawk_API.toggle();
    }
   },

   convertMinutesIntoDayHoursMinutes(num){
    if(num){
      let d = Math.floor(num/1440); // 60*24
      let h = Math.floor((num-(d*1440))/60);
      let m = Math.round(num%60); 
      let tTime = ""; 
      if(d>0) tTime += d + "D ";
      if(h>0) tTime += h + "H ";
      if(m>0) tTime += m+"M";
      return tTime;     
    }else{
      return "-";
    }  
  },

  validateNumbersNoDecimal(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode = 46 && charCode > 31
        && (charCode < 48 || charCode > 57))
        return false;
    return true;
  },

  validateNumbersWithDecimal(event,digitsAfterDecimal) {  //digitsAfterDecimal is plus one how mach digit we want to allow after decimal
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } else {
      var charLength: any = event.target.value.length;
      var index: any = event.target.value.indexOf('.');
      if (index > 0) {
        var CharAfterDecimal = (charLength + 1) - index;
        if (CharAfterDecimal > digitsAfterDecimal) {
          return false;
        }
      }
      return true;
    }
}
}
