<div class="panel panel-primary edit-ignore-mobileNo" id="mainCompotents">
    <div class="panel-heading padding-left-50">
        <h3 class="panel-title">Edit SMS Ignore Mobile No.</h3>
        <button mat-mini-fab class="custom-btn-back" (click)="globalService.goBack();">
            <mat-icon>arrow_back</mat-icon>
        </button>
    </div>
    <div class="panel-body bgcolor-lightgrey padding-0" style="border:none;">
        <!-- Login page start -->
        <div class="bgcolor-lightgrey form-content">
            <div class="container-fluid">
                <form class="form-inline col-xs-12 padding-bottom-15" role="form" [formGroup]="EditMobileNo" novalidate
                    (ngSubmit)="onSubmit()">
                    <div class=" col-xs-12 form-block">
                        <div class="row">
                            <div class="form-group  col-sm-12 col-md-4">
                                <label for="partner_key">Title</label>
                                <div class='relative'>
                                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                                        <input matInput placeholder="Title" title="Title" formControlName="name"
                                            class="form-control input-lg custom-form-input" maxlength="100">
                                        <mat-error *ngIf="EditMobileNo.controls.name.hasError('required')">
                                            Title is<strong>required</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group  col-sm-12 col-md-4">
                                <label for="partner_id">Mobile Number</label>
                                <div class='relative'>
                                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                                        <input matInput placeholder="Mobile Number*" title="Mobile Number"
                                            formControlName="phone_number"
                                            class="form-control input-lg custom-form-input" maxlength="15"
                                            [textMask]="{mask: phoneMask}" />
                                        <mat-error *ngIf="EditMobileNo.controls.phone_number.hasError('required')">
                                            Mobile Number is <strong>required</strong>
                                        </mat-error>
                                        <mat-error
                                            *ngIf="EditMobileNo.controls.phone_number.hasError('minLength') && !EditMobileNo.controls.phone_number.hasError('required')">
                                            Invalid Mobile Number
                                        </mat-error>
                                    </mat-form-field>

                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 form-block submit-block">
                                <button type="submit" mat-raised-button
                                    class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white"
                                    color="accent" title="Submit" style="max-width: 300px;" >
                                    SUBMIT
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>