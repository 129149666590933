import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {Http, Headers, Response,RequestOptions} from '@angular/http';
import {Router} from '@angular/router';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  private errors: GlobalErrorHandler[] = [];
  public current_url = window.location.hostname;
  public dashboardURLs = ["/customer/dashboard","/shops/dashboard","/admin/dashboard"]
  //enable mailing here
  public enable_error_mails = false;
  private get router() { return this.injector.get(Router); }
  constructor(private injector: Injector,private http:Http) {
    super();
    if(this.current_url == "shops.completeautoreports.com") {
      this.enable_error_mails = false;
    }
  }
  handleError(error:any) {
    console.log("this is error",error)
    try{
      console.log(error)
      if (error instanceof TypeError) {
        console.log(error.message ? error.message : error.toString());
      }
      if (error instanceof GlobalErrorHandler) {
        this.addError(error);
      }
      else {
        if(error.rejection instanceof GlobalErrorHandler) {
          this.addError(error.rejection);
        }
        else {
          // super.handleError(error);
          setTimeout(()=>{
            console.log("Inside settimeout");
            if(this.enable_error_mails) {
              var headers = new Headers();
              var form = new FormData();
              error.stack = error.stack + ' URL :' + window.location;
              form.append("error", error.stack);
              headers.set('Accept', 'application/json');
              var liveURL = "https://dev.completeautoreports.com/API/API_report_error_v2.php";
              var local = "http://localhost/CAR/API/API_report_error_v2.php";
              var redirect_to = window.location.pathname.split("/")[1] + "";
              console.log("this is path name",window.location.pathname)
              if(redirect_to != 'error'){
                // send to error page of error is on dashboard page to avoid dashboard looping
                if(this.dashboardURLs.indexOf(window.location.pathname) != -1){
                  this.trackReloads(window.location)
                } else {
                 console.log("Inside settimeout else");
                // else send mail and redirect to dashboard once
                // this.http.post(liveURL,form,{headers:headers}).subscribe((res)=>{
                // window.location.href = redirect_to
                // })
                }
              }
            } else {
              // intermittent mac issue
              console.log("this is error ok",error.ok)
              if(error == "Response with status: 0 for URL: null" || !error.ok) {
                // window.location.reload()
              }
            }
          },500);
        }
      }
      throw error;
    }
    catch(exp){
      console.log('exception = ', exp);
    }
  }
     
  addError(error: GlobalErrorHandler) {
    this.errors.push(error);
    // console.log(this.errors)
  }

  trackReloads(page_url:any){
    // detect current user and get the user type
    var loggedInUserType = page_url.pathname.split("/")[1] + "";
    // check  if localstorage already present
    var isAlreadyReloaded:any = localStorage.getItem("error-reload-count");
    var parsedData = JSON.parse(isAlreadyReloaded)
    if(isAlreadyReloaded!=null && isAlreadyReloaded!=undefined){
      // get total times re-loaded
      var totalReloadedCount = JSON.parse(isAlreadyReloaded).reload_count;
      // create object
      var obj = {
        page_url:loggedInUserType,
        reload_count:totalReloadedCount
      }
      // check if page is loaded 5 times
      if(parseInt(totalReloadedCount) > 3 && parsedData.page_url == loggedInUserType) {
        // logout logged in user whose dashboard showed error
        // localStorage.removeItem("loggedIn"+parsedData.page_url[0].toUpperCase()+parsedData.page_url.substr(1));
        // remove reload info localstorage
        localStorage.removeItem("error-reload-count")
        // open error window
        // var w = window.open("/error", '_self');
        // w.focus()
        // use router to redirect on error page
        localStorage.setItem("error-page-url",loggedInUserType)
        this.router.navigateByUrl("/error")
      } else {
        // increment the existing reload count
        totalReloadedCount = obj.reload_count + 1;
        obj.reload_count = totalReloadedCount;
        localStorage.setItem("error-reload-count",JSON.stringify(obj));
        window.location.reload()
      }
    } else {
      // create localstorage
       obj = {
        page_url: page_url.pathname.split("/")[1] + "",
        reload_count:0
      }
       localStorage.setItem("error-reload-count",JSON.stringify(obj))
    }

  }
}
