<div class="panel panel-primary employee-list" id="mainCompotents">
    <div class="panel-heading padding-left-15">
        <h3 class="panel-title">Employee LIST</h3>
    </div>
  <div class="panel-body">
        <div class="row">
            
            <div class="col-sm-12 text-right">
                <p><a [routerLink]="['/shops/employee/add']" class="hover-link" title="Add Employee"><i class="fa fa-plus fa-lg margin-right-5"></i>Add Employee</a> </p>
            </div>
        </div>
        <div class="display-full relative">
            <mat-expansion-panel hideToggle="false" class="search-filter search-employee relative">
                <mat-expansion-panel-header collapsedHeight="53px" expandedHeight="53px">
                <strong>
                    <i class="fa fa-search fa-fw"></i>&nbsp;Search Filters</strong>
                </mat-expansion-panel-header>
                <form id="advanceSearchForm" class="collapse in" aria-expanded="true" (ngSubmit)="search($event)">

                <div class="panel-body">
                    <div class="col-sm-3 uc-ngx-select">
                        <ngx-select class="display-full margin-bottom-20" [items]="searchOn" [(ngModel)]="options.search_by" [ngModelOptions]="{standalone: true}"
                        placeholder="Select Type" (select)="searchChange($event)" [noAutoComplete]="true">
                    </ngx-select>   

                    </div>
                    <div class="col-sm-3" *ngIf="options.search_by!='dob'">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                        <input matInput placeholder="Search Keyword" title="Search Keyword" [(ngModel)]="options.search_keyword" [ngModelOptions]="{standalone: true}"  class="form-control input-lg custom-form-input" />
                    </mat-form-field>
                    </div>
                    <div class="col-sm-3" *ngIf="options.search_by=='dob'">
                        <mat-form-field class="car-input btn-block car-datePicker" floatPlaceholder="never" appearance="fill">
                        <input matInput class="form-control input-lg custom-form-input" [(ngModel)]="options.search_keyword" [ngModelOptions]="{standalone: true}" placeholder="Date of Birth"
                        [matDatepicker]="picker2" (focus)="picker2.open()" />
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <mat-action-row class="search-filter-footer">
                    <button mat-raised-button color="accent" type="submit">
                    Search
                    </button>
                </mat-action-row>
                </form>
            </mat-expansion-panel>
            <button mat-button class="clear-btn" #clearBtn (click)="clearSearch()" [class.active-search]="checkFormEmpty(clearBtn)">
                Clear Filter
            </button>
        </div>

        <!-- grid and list view icon -->
        <div class="grid-list-view-icon row">
           <div class="col-sm-12">
                <ul class="list-inline d-flex flex-row mx-5">
                    <li class="list-inline-item one">
                        <div class="action-icon-color" [class.active]="isListView" title="List" (click)="changeView()" >
                            <i class="fa fa-list" aria-hidden="true"></i>
                        </div>
                    </li>
                    <li class="list-inline-item">
                        <div class="action-icon-color" [class.active]="!isListView" title="Grid" (click)="changeView()" >
                            <i class="fa fa-th" aria-hidden="true"></i>
                        </div>
                    </li>
                </ul>
           </div>
        </div>

        <!-- Employee Grid View -->
        <div class="row" *ngIf="!isListView && getTotalRecords() > 0" >
        <div class="col-md-4 col-sm-6 col-xs-12" *ngFor="let row of gridDataList" >
            <div class="flip-card">
                <div class="flip-main">
                    <div class="recto">
                        <div class="x-card">
                            <div class="x-card-body text-center pb-2">
                                <img class="img-sq-100" onerror="this.onerror=null;this.src='assets/img/default-avatar.png';" *ngIf="row.photo!=''" src="{{docImgPath}}{{row.photo}}" alt="{{row.name}}">
                                <img class="img-sq-100" *ngIf="row.photo==''" src="assets/img/default-avatar.png" alt="{{row.name}}">
                                <div class="">
                                    <h4 class="x-card-title">{{row.name}}</h4>
                                    <p class="x-card-text mb-1">
                                        <label>Address:</label>
                                        <span>{{row.address}}</span>
                                    </p>
                                    <span class="mr-1">
                                        <label>City:</label>
                                        <span>{{row.city}}</span>
                                    </span>
                                    <span class="">
                                        <label>Country:</label>
                                        <span>{{row.country}}</span>
                                    </span>
                                    
                                    <div class="padding-top">
                                        <p class="btn list-action-btn mat-button text-white">{{ getDesignation(row.role_status) }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="verso rounded">
                        <div class="x-card">
                            <div class="x-card-body text-center">
                                <h4 class="x-card-title">{{row.name}}  <span>({{ getDesignation(row.role_status) }})</span></h4>
                                <div class="padding-top">
                                    <p class="x-card-text mb-2"><a href="mailto:{{row.email}}" class="text-black">{{(row.email)}} </a> </p>
                                    <p class="mb-2"><a class="text-black" href="tel:{{row.mobile}}"> {{row.mobile !="" ? row.mobile : "-"}}</a></p>
                                    <p class="mb-2">
                                        <label> Default:</label>
                                       
                                    </p>
                                </div>
                                <div class="">
                                   
                                </div>                              
                                <ul class="list-inline d-flex flex-row mx-5">
                                    <li class="list-inline-item" *ngIf="isAllowed('views')" (click)="actionOnEmp('view',row.empcode)">
                                        <a class="action-icon-color" title="View" >
                                            <i class="fa fa-eye"></i>
                                        </a>
                                    </li>
                                    <li class="list-inline-item" *ngIf="(row.empcode!=currentUser.id || currentUser.role_id=='1') && isAllowed('edits')" (click)="actionOnEmp('edit',row.empcode)">
                                        <a class="action-icon-color" title="Edit" >
                                            <i class="fa fa-edit"></i>
                                        </a>
                                    </li>
                                    <li class="list-inline-item" *ngIf="row.empcode!=currentUser.id && isAllowed('deletes')" (click)="actionOnEmp('delete',row.empcode)">
                                        <a class="action-icon-color" title="Delete" >
                                            <i class="fa fa-trash"></i>
                                        </a>
                                    </li>
                                    <li class="list-inline-item" *ngIf="row.empcode!=currentUser.id" (click)="actionOnEmp('resetPwd',row.empcode)">
                                        <a class="action-icon-color" title="Reset Password" >
                                            <i class="fa fa-shield"></i>
                                        </a>
                                    </li>
                                    <li class="list-inline-item" *ngIf="row.empcode!=currentUser.id && isSetPermissionAllowed()" (click)="actionOnEmp('permission',row.empcode)">
                                        <a class="action-icon-color" title="Set Permissions" >
                                            <i class="fa fa-lock"></i>
                                        </a>
                                    </li>
                                    <li class="list-inline-item" *ngIf="isAllowed('views') && isTechnicianAllowed()" (click)="actionOnEmp('techreport',row.empcode)">
                                        <a class="action-icon-color" title="Report" >
                                            <i class="fa fa-solid fa-square-poll-horizontal"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <!-- Employee Grid View -->

        <div class="car-datatable uc-datatable mat-elevation-z1 employee-table">
            <div class="table-responsive relative" *ngIf="isListView" >
                <form [formGroup]="empList" class="form-inline signup-form" method="POST" enctype="multipart/form-data" autocomplete="off">
                    <!-- Employee List View -->
                    <mat-table #table [dataSource]="dataSource" matSort class="datatable-loader-bg uc-table-responsive" >
                        <!-- Color Column -->

                        <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="name"> Name </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="name" data-val="Name"> {{row.name}} </mat-cell>
                        </ng-container>

                        <!-- Color Column -->
                        <ng-container matColumnDef="email">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Email </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto" data-val="Email">
                            <a class="text-black" href="mailto:{{row.email}}"> {{(row.email)}} </a>
                        </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="mobile">
                            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="phone"> Phone No. </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-role="phone" data-val="Phone No.">
                            <a class="text-black" href="tel:{{row.mobile}}"> {{row.mobile !="" ? row.mobile : "-"}}</a>
                            </mat-cell>
                        </ng-container>
                        <!-- Color Colu`mn -->
                        <ng-container matColumnDef="role_status">
                        <mat-header-cell *matHeaderCellDef data-role="roleStatus"> Designation </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="roleStatus" data-val="Designation">{{ getDesignation(row.role_status) }}</mat-cell>
                        </ng-container>

                        <!-- Color Column -->
                        <ng-container matColumnDef="login">
                        <mat-header-cell *matHeaderCellDef data-role="roleStatus"> Login Enabled </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="roleStatus" data-val="Login Enabled">
                            <mat-button-toggle *ngIf="row.empcode!=currentUser.id" color="accent" [checked]="row.login_status == 1" (change)="setStatus($event, row)" class="status-btn" [class.enable]="row.login_status == 1 || row.status=='Enabled'">
                                {{row.login_status == 0 ? "Disabled":"" }}
                                {{row.login_status == 1 ? "Enabled" :  "" }}
                                {{row.login_status == 2 ? "Reactivate" :  "" }}
                            </mat-button-toggle>
                        </mat-cell>
                        </ng-container>

                        <!-- Color Column -->
                        <ng-container matColumnDef="default">
                        <mat-header-cell *matHeaderCellDef data-role="status"> Default </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="status" data-val="Default"> {{row.default_status == '1' ? 'Yes' : 'No'}} </mat-cell>
                        </ng-container>


                        <!-- Color Column -->
                        <ng-container matColumnDef="Actions">
                        <mat-header-cell *matHeaderCellDef data-role="button"> Actions </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="button">
                            <button mat-button class="list-action-btn" [matMenuTriggerFor]="menu" disableRipple>
                            Action
                            <mat-icon>arrow_drop_down</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu" class="car-icon-menu" [overlapTrigger]="false">
                            <a class="mat-menu-item" *ngIf="isAllowed('views')" (click)="actionOnEmp('view',row.empcode)"><i class="fa fa-eye"></i>View</a>
                            <a class="mat-menu-item" *ngIf="(row.empcode!=currentUser.id || currentUser.role_id=='1') && isAllowed('edits')" (click)="actionOnEmp('edit',row.empcode)"><i class="fa fa-edit"></i>Edit</a>
                            <a class="mat-menu-item" *ngIf="row.empcode!=currentUser.id && isAllowed('deletes')" (click)="actionOnEmp('delete',row.empcode)"><i class="fa fa-trash"></i>Delete</a>
                            <a class="mat-menu-item" *ngIf="row.empcode!=currentUser.id" (click)="actionOnEmp('resetPwd',row.empcode)"><i class="fa fa-shield"></i>Reset Password</a>
                            <a class="mat-menu-item" *ngIf="(row.empcode!=currentUser.id && row.role_status !=='5') && isSetPermissionAllowed()" (click)="actionOnEmp('permission',row.empcode)"><i class="fa fa-lock"></i>Set Permissions</a>
                            <a class="mat-menu-item" *ngIf="isAllowed('views') && isTechnicianAllowed()" (click)="actionOnEmp('techreport',row.empcode)"><i class="fa fa-solid fa-square-poll-horizontal"></i>Report</a>
                            </mat-menu>
                        </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    </mat-table>
                    <!-- Employee List View -->                  
                </form>
                <div class="padding-15 text-center" [style.display]="getTotalRecords() < 1 ? '' : 'none'">
                No Records Available
                </div>
            </div>

            <div class="display-full relative" [style.display]="getTotalRecords() < 1 ? 'none' : ''">
                <mat-paginator class="car-pagination" #paginator [length]="getTotalRecords()" [pageIndex]="0" [pageSize]="options.limit"
                [pageSizeOptions]="[5, 10, 25, 100]" (page)="paginateByLimit($event)">
                </mat-paginator>

                <div class="mat-paginator table-paging">
                <div class="mat-paginator-range-label margin-left-30 margin-right-30">{{options.start+1}} - {{getUpperLimit(options.start + options.limit,getTotalRecords())}} of {{getTotalRecords()}}</div>
                <button mat-icon-button (click)="paginate($event,'previous');" [disabled]="firstList()">
                    <mat-icon>navigate_before</mat-icon>
                </button>
                <button mat-icon-button (click)="paginate($event,'next');" [disabled]="lastList()">
                    <mat-icon>navigate_next</mat-icon>
                </button>
                </div>
            </div>
        </div>  
</div>
