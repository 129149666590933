<form class="form-horizontal" autocomplete="off" novalidate [formGroup]="ccForm" (ngSubmit)="payNow();" *ngIf="displayForm==true">
    <div mat-dialog-title class="relative">
        Credit Card Information
        <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0" (click)="close()">
            <mat-icon class="notranslate" aria-label="Example icon-button with a heart icon">close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <div class="display-full margin-bottom-5">            
            <div class="col-sm-7">
                <label for="ccnumber"><strong>Subscription Plan :</strong></label>
                <span> {{planName}}</span>
            </div>
            <div class="col-sm-5">
                <label for="cvv"><strong>Total Amount :</strong></label>
                <span> $ {{planAmount}}</span>
            </div>
        </div>
        <div class="display-full margin-bottom-5">            
            <div class="col-sm-12">
                <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                    <input matInput type="text" placeholder="Name on card" formControlName="cc_name" class="form-control input-lg custom-form-input btn-block"/>
                    <mat-error *ngIf="ccForm.controls.cc_name.hasError('required')">
                        Name on Card is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="display-full margin-bottom-5">            
            <div class="col-sm-8 relative">
                <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                    <input matInput id="cardnumber" type="text" placeholder="Card Number" formControlName="cc_number" class="form-control input-lg custom-form-input btn-block" maxlength="19" (keypress)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event)" (keyup)="handleCardTypeImg()"/>
                    <mat-error *ngIf="ccForm.controls.cc_number.hasError('required')">
                        Credit Card number is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
                <span id="cardIcon" style="position: absolute;top: 0;right: 16px;overflow: hidden;width: 62px;height: 38px;"></span>
               
            </div>            
            <div class="col-sm-4">
                <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                    <input matInput type="text" placeholder="CVV" formControlName="cvv" class="form-control input-lg custom-form-input btn-block" maxlength="4" (keypress)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event)" />
                    <mat-error *ngIf="ccForm.controls.cvv.hasError('required')">
                        CVV number is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="display-full margin-bottom-5">  
            <div class="col-sm-6">
                <div class="row">
                    <div class="col-sm-6">                        
                        <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                            <input matInput type="text" placeholder="MM" formControlName="exp_month"  class="form-control input-lg custom-form-input btn-block" maxlength="2" (keypress)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event)" />
                            <mat-error *ngIf="ccForm.controls.exp_month.hasError('required')">
                                Month is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6">
                        <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                            <input matInput type="text" placeholder="YY" formControlName="exp_year"  class="form-control input-lg custom-form-input btn-block" maxlength="2" (keypress)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event)" />
                            <mat-error *ngIf="ccForm.controls.exp_year.hasError('required')">
                                Year is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div> 
            <div class="col-sm-6">
                <img src="../../../assets/img/creditcardlogo.png" class="img-responsive" alt="creditcardlogo"/>
            </div>
        </div>    
        <div class="display-full margin-bottom-5" *ngIf="showCouponCode">
            <div class="col-sm-3">
                <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                    <input matInput type="text" placeholder="Coupon Code" class="form-control input-lg custom-form-input btn-block" [(ngModel)]="coupon_code" [ngModelOptions]="{standalone: true}" />
                </mat-form-field>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="inquiry-action relative">
        <div class="text-left col-sm-7">
            <a href="https://www.braintreegateway.com/merchants/nhwv2y5vrvgmgh2y/verified" target="_blank">
                <img class="img-responsive" src="https://s3.amazonaws.com/braintree-badges/braintree-badge-wide-dark.png" style="max-width:280px;height:39px" border="0"/>
            </a>
        </div>
        <div class="text-right col-sm-5">
            <button type="button" mat-raised-button mat-dialog-close class="font-bold" (click)="close()">Close</button>
            <button type="submit" mat-raised-button class="font-bold" color="accent">Subscribe Now</button>
        </div> 
    </mat-dialog-actions>
</form>

<form class="form-horizontal credit-card-form" autocomplete="off" novalidate [formGroup]="ccFormExisting" (ngSubmit)="payNowWithNewCC()" *ngIf="displayForm==false">
    <div mat-dialog-title class="relative">
        Credit Card Information
        <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0" (click)="close()">
            <mat-icon class="notranslate close-icon-lg">close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <div class="display-full margin-bottom-5">            
            <div class="col-sm-7">
                <label for="ccnumber"><strong>Subscription Plan :</strong></label>
                <span> {{planName}}</span>
            </div>
            <div class="col-sm-5">
                <label for="cvv"><strong>Total Amount :</strong></label>
                <span> $ {{planAmount}}</span>
            </div>
        </div>
        
        <div class="display-full margin-bottom-5">            
            <div class="col-sm-6 relative">
                <label for="ccnumber"><strong>CC Number :</strong></label>
                <span> {{ccMaskedNumber}}</span>
               
            </div>            
        </div>
        
        <div class="display-full margin-bottom-5">            
            <div class="col-sm-12">
                <mat-radio-group class="select-option">
                    <!--(change) = "toggleTextField($event,'')"-->
                    <mat-radio-button class="margin-right-15" value="1" [checked]="defaultCheck" (change) = "changePaymetForm($event)">Use existing card</mat-radio-button>
                    <mat-radio-button value="2" [checked]="!defaultCheck" (change) = "changePaymetForm($event)">Use new card</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        
        <!-- PAYMENT FORM -->
        
            <div class="display-full" *ngIf="displayNewForm">
                 <div class="display-full margin-bottom-5">            
                    <div class="col-sm-12">
                        <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                            <input matInput type="text" placeholder="Name on card" formControlName="cc_name" class="form-control input-lg custom-form-input btn-block"  />
                            <mat-error *ngIf="ccFormExisting.controls.cc_name.hasError('required')">
                                Name on Card is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="display-full margin-bottom-5">            
                    <div class="col-sm-8 relative">
                        <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                            <input matInput id="cardnumber" type="text" placeholder="Card Number" formControlName="cc_number" class="form-control input-lg custom-form-input btn-block" maxlength="19" (keypress)="disableKey($event);" (keypress)="onlyDecimalNumberKey($event)" (keyup)="handleCardTypeImg()"/>
                            <mat-error *ngIf="ccFormExisting.controls.cc_number.hasError('required')">
                                Credit Card number is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                        <span id="cardIcon" style="position: absolute;top: 0;right: 16px;overflow: hidden;width: 62px;height: 38px;"></span>

                    </div>            
                    <div class="col-sm-4">
                        <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                            <input matInput type="text" placeholder="CVV" formControlName="cvv" class="form-control input-lg custom-form-input btn-block" maxlength="4" (keypress)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event)" />
                            <mat-error *ngIf="ccFormExisting.controls.cvv.hasError('required')">
                                CVV number is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="display-full margin-bottom-5">  
                    <div class="col-sm-6">
                        <div class="row">
                            <div class="col-sm-6">                        
                                <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                                    <input matInput type="text" placeholder="MM" formControlName="exp_month"  class="form-control input-lg custom-form-input btn-block" maxlength="2" (keypress)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event)" />
                                    <mat-error *ngIf="ccFormExisting.controls.exp_month.hasError('required')">
                                        Month is <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-sm-6">
                                <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                                    <input matInput type="text" placeholder="YY" formControlName="exp_year"  class="form-control input-lg custom-form-input btn-block" maxlength="2" (keypress)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event)" />
                                    <mat-error *ngIf="ccFormExisting.controls.exp_year.hasError('required')">
                                        Year is <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div> 
                    <div class="col-sm-6">
                        <img src="../../../assets/img/creditcardlogo.png" class="img-responsive" alt="creditcardlogo"/>
                    </div>
                </div>  
            </div>
            <div class="display-full margin-bottom-5" *ngIf="showCouponCode">
                <div class="col-sm-3">
                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill"> 
                        <input matInput type="text" placeholder="Coupon Code" class="form-control input-lg custom-form-input btn-block" [(ngModel)]="coupon_code" [ngModelOptions]="{standalone: true}"  maxlength="100"    />
                    </mat-form-field>
                </div>
            </div>
        <!-- END -->

    </mat-dialog-content>
    <mat-dialog-actions class="inquiry-action relative">
        <div class="text-left col-sm-7">
            <a href="https://www.braintreegateway.com/merchants/nhwv2y5vrvgmgh2y/verified" target="_blank">
                <img class="img-responsive" src="https://s3.amazonaws.com/braintree-badges/braintree-badge-wide-dark.png" style="max-width:280px;height:39px" border="0"/>
            </a>
        </div>
        <div class="text-right col-sm-5" *ngIf="displayNewForm">
            <button type="button" mat-raised-button mat-dialog-close class="font-bold" (click)="close()">Close</button>
            <button type="submit" mat-raised-button class="font-bold" color="accent" >Subscribe Now</button>
        </div> 
        <div class="text-right col-sm-5" *ngIf="!displayNewForm">
            <button type="button" mat-raised-button mat-dialog-close class="font-bold" (click)="close()">Close</button>
            <button type="button" mat-raised-button class="font-bold" color="accent"  (click)="payNowWithExistingCC()" >Subscribe Now</button>
        </div>
    </mat-dialog-actions>
</form>  
