<!-- <div mat-dialog-title class="text-center">{{ !isStepOneCompleted?'CC AUTHORIZATION & EULA AGREEMENT':'EULA AGREEMENT' }}
</div> -->

<div mat-dialog-title class="text-center margin-bottom-5">
  <div class="row">
    <div class="col-xs-12 text-center title-col flex">
      <div class="eula-title"><span>{{ (!isStepOneCompleted || !isShopProfileSetupCompleted) ? agreementTitle: 'End User License Agreement' }}</span></div>
         <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0"*ngIf="showCrossIcon">
         <mat-icon  class="notranslate"  (click)="handleCloseCcAuthDialog()">close</mat-icon>
      </button>
   </div>
  </div>
</div>
<mat-dialog-content class="overflow-data eula-agreement" [ngClass]="{ 'profile-contant': isprofileStep }">
  <mat-horizontal-stepper class="ccauth-stepper" linear #stepper>
    <mat-step *ngIf="!isStepOneCompleted" [stepControl]="ccAuthForm" [editable]="isEditable">
      <form class="form-horizontal ccauth-payment" autocomplete="off" [formGroup]="ccAuthForm" (ngSubmit)="onUpdateCCInfo();">
        <ng-template class="margin-bottom-5 no-event " matStepLabel><span class="hidden-xs">CC AUTHORIZATION</span><span class="visible-xs">CC AUTH</span></ng-template>
        <div class="row ccauth-payment-wrap padding-top-10 margin-0">
          <div class="col-sm-12">
            <div class="row margin-0 padding-sm-bottom-10 trial-text-section">
              <div class="col-xs-4 col-sm-2 free-trial"><span class="font-bold">Free Trial</span></div>
              <div class="col-xs-8 col-sm-10 text-right payment-security-col hidden-xs">
                <span><i class="fa fa-shield"></i> <span>&nbsp; Secure payments with</span>&nbsp;&nbsp;<img src="/assets/img/PPBT_Logo_black.svg"></span>
              </div>
            </div>
            <div class="row display-full margin-bottom-5 margin-top-10">
              <div class="col-sm-12">
                <ul class="ccauth-payment-facilities">
                  <li>You are enabling the 14 days <span class="font-bold">free trail</span> period.</li>
                  <li>Credit card is used for <span class="font-bold">account verification</span> and will <span class="font-bold">not be charged</span> for duration of free trial.</li>
                  <li>Credit card <span class="font-bold">will be charged once the trial period ends</span> unless cancelled before trial period ends.</li>
                </ul>
              </div>
            </div>
           <div class="row margin-0 plan-details-wrap">
              <div class="col-sm-6 border cc-details-section">
                <div class="row margin-0">
                  <div class="col-sm-12 font-bold padding-left-0 padding-bottom-10">Payment Method</div>
                  <div class="col-sm-12 margin-bottom-15 payment-security-col visible-xs padding-0"><span><i class="fa fa-shield"></i> <span>&nbsp; Secure payments with</span>&nbsp;&nbsp;<img src="/assets/img/PPBT_Logo_black.svg"></span></div>
                </div>
                <div class="row border margin-0 accepted-cards">
                  <div class="col-xs-5 col-sm-6 font-bold padding-left-10">Credit Card</div>
                  <div class="col-xs-7 col-sm-6 text-right cc-images-col">
                    <img src="/assets/img/creditcardlogo.png" class="img-responsive" alt="creditcardlogo" />
                  </div>
                </div>
                <div class="row margin-top-15">
                  <div class="col-sm-12">
                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                      <input matInput type="text" placeholder="Name on card" formControlName="cc_name"
                        class="form-control input-lg custom-form-input btn-block" maxlength="150" />
                      <mat-error *ngIf="ccAuthForm.controls.cc_name.hasError('required')">
                        Name on Card is <strong>required</strong>
                      </mat-error>
                      <mat-error *ngIf="ccAuthForm.controls.cc_name.hasError('pattern')">
                        Name is invalid. Must not contain any special characters.
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                      <input matInput id="cardnumber" type="text" placeholder="Card Number" formControlName="cc_number"
                        class="form-control input-lg custom-form-input btn-block" maxlength="19" required (keyup)="handleCardTypeImg()" (keypress)="onlyDecimalNumberKey($event)" (paste)="validateFieldOnPaste('cc_number')"/>
                      <mat-error *ngIf="ccAuthForm.controls.cc_number.hasError('required')">
                        Credit Card number is <strong>required</strong>
                      </mat-error>
                      <mat-error *ngIf="ccAuthForm.controls.cc_number.hasError('pattern') && ccAuthForm.get('cc_number').touched">
                        Invalid Card Number
                      </mat-error>
                    </mat-form-field>
                    <span id="cardIcon" class="card-icon"></span>
                  </div>
                </div>
                <div class="row">
                
                    
                      <div class="col-xs-6 col-sm-4">
                        <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                          <input matInput type="text" placeholder="MM" formControlName="exp_month"
                            class="form-control input-lg custom-form-input btn-block" maxlength="2" (keypress)="onlyDecimalNumberKey($event)" (paste)="validateFieldOnPaste('exp_month')"/>
                          <mat-error *ngIf="ccAuthForm.controls.exp_month.hasError('required')">
                            Month is <strong>required</strong>
                          </mat-error>
                          <mat-error *ngIf="ccAuthForm.controls.exp_month.hasError('pattern') && ccAuthForm.get('exp_month').touched">
                            Invalid Card Month
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-xs-6 col-sm-4">
                        <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                          <input matInput type="text" placeholder="YY" formControlName="exp_year"
                            class="form-control input-lg custom-form-input btn-block" maxlength="2" (keypress)="onlyDecimalNumberKey($event)" (paste)="validateFieldOnPaste('exp_year')"/>
                          <mat-error *ngIf="ccAuthForm.controls.exp_year.hasError('required')">
                            Year is <strong>required</strong>
                          </mat-error>
                          <mat-error *ngIf="ccAuthForm.controls.exp_year.hasError('pattern') && ccAuthForm.get('exp_year').touched">
                            Invalid Card Year
                          </mat-error>
                        </mat-form-field>

                      </div>
                      <div class="col-xs-12 col-sm-4">
                        <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                          <input matInput type="text" placeholder="CVV" formControlName="cvv"
                            class="form-control input-lg custom-form-input btn-block" maxlength="4" (keypress)="onlyDecimalNumberKey($event)" (paste)="validateFieldOnPaste('ccv')"/>
                          <mat-error *ngIf="ccAuthForm.controls.cvv.hasError('required')">
                            CVV number is <strong>required</strong>
                          </mat-error>
                          <mat-error *ngIf="ccAuthForm.controls.cvv.hasError('pattern') && ccAuthForm.get('ccv').touched">
                            Invalid CVV Number
                          </mat-error>
                        </mat-form-field>
                      </div>
                </div>
                <div class="row coupon-code-section">
                  <div class="col-xs-5 col-md-4 margin-bottom-15 font-bold">Coupon Code</div>
                  <div class="col-xs-7 col-md-8 text-right" *ngIf="!removeCouponCode"><span class="apply-coupon-btn font-bold" (click)="toggleCouponInput()">Add Coupon</span></div>

                  <div class="col-xs-7 col-md-8" *ngIf="removeCouponCode">
                    <div class="row coupon-input-row">
                        <mat-form-field class="car-input btn-block code-field" floatPlaceholder="never" appearance="fill">
                          <input class="input-sm form-control custom-form-input" [(ngModel)]="couponCode" matInput type="text" placeholder="Coupon Code" [ngModelOptions]="{standalone: true}" maxlength="100">
                        </mat-form-field>
                        <mat-icon  class="notranslate close-icon" (click)="handleRemoveCoupon()">close</mat-icon>
                    </div>
                    
                  </div>
                </div>
                <div class="row submit-btn complete-subscription-section">
                  <div class="col-xs-12 col-sm-8 col-lg-7 margin-top-10">
                    <button type="submit" mat-raised-button class="font-bold submit-btn btn-block mat-raised-button mat-accent text-uppercase" title="Start Free Trial"
                    color="accent">Start Free Trial</button>
                  </div>
                </div>
                <div class="row checkbox-row margin-0 visible-xs">
                  <div class="col-sm-4 padding-sm-left-0">
                    <mat-checkbox [checked]="true" disabled>100% Secure Payments</mat-checkbox>
                  </div>
                  <div class="col-sm-8 padding-sm-left-0">
                    <mat-checkbox [checked]="true" disabled>256-Bit Bank Level Security</mat-checkbox>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 border plan-details-section">
                <app-subscription-plan-preview class="row"></app-subscription-plan-preview>
              </div>
           </div>
           <div class="row self-authorization-row">
            <div class="col-sm-12 padding-sm-0 margin-top-15">
              <p class="margin-top-10"><b>I, the undersigned cardholder, authorize the merchant known as CompleteAutoReports (CAR)
                to charge my
                credit card for purchases related to services.</b> I agree that my information may be saved by the merchant
              for future payments and understand that this can be revoked at any time.</p>
           
            </div>
            <!-- <div class="col-sm-12 text-right next-btn-payment">
              <button type="submit" mat-raised-button class="font-bold" [disabled]="!ccAuthForm.valid"
                color="accent">Next</button>
            </div> -->
           </div>
           <div class="row checkbox-row hidden-xs">
            <div class="col-sm-4 padding-sm-left-0">
              <mat-checkbox [checked]="true" disabled>100% Secure Payments</mat-checkbox>
            </div>
            <div class="col-sm-8 padding-sm-left-0">
              <mat-checkbox [checked]="true" disabled>256-Bit Bank Level Security</mat-checkbox>
            </div>
           </div>
          </div>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="eulaFormGroup" [editable]="isEditable">
      <form [formGroup]="eulaFormGroup" class="form-horizontal" autocomplete="off" (ngSubmit)="OnEULAAgggrement();">
        <ng-template class="margin-bottom-10 no-event" matStepLabel><span class="hidden-xs">EULA AGREEMENT</span><span class="visible-xs">EULA</span></ng-template>
        <div class="serviceOrder-form">
          <div class="alert-text"><p class="font-bold">Scroll down to bottom to review EULA terms and conditions <span><i class="fa fa-arrow-down"></i></span></p></div>
          <div class="main-heading">
            <img class="img-responsive" src="assets/img/big_logo.png" title="logo image"
              alt="Complete Auto Reports logo image" />
            <h1 class="sub-heading">SASS SERVICE ORDER FORM</h1>
          </div>
          <div class="form-content">
            <div class="form-table">
              <div class="row table-column flex-sm">
                <div class="col-sm-6">
                  <div class="row">
                    <div class="col-xs-5 col-lg-3">Customer</div>
                    <div class="col-xs-7 col-lg-9 break-word">
                      {{ shopDetail.company_name || 'N/A' }}
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 ">
                <div class="row">
                  <div class="col-xs-5 col-lg-3">Contact</div>
                  <div class="col-xs-7 col-lg-9 break-word">{{ shopDetail.owner_name }} </div>
                </div>
                </div>
              </div>
              <div class="row table-column">
                <div class="col-sm-6">
                  <div class="row">
                    <div class="col-xs-5 col-lg-3">Address</div>
                    <div class="col-xs-7 col-lg-9 break-word">
                      <span>{{ shopDetail.address1 || 'N/A' }}</span>
                      <span *ngIf="shopDetail.address2!=''">, {{shopDetail.address2}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 ">
                  <div class="row">
                    <div class="col-xs-5 col-lg-3">Phone</div>
                    <div class="col-xs-7 col-lg-9"> {{ shopDetail.business_phone1 }}</div>
                  </div>
                </div>
              </div>
              <div class="row table-column flex-sm">
                <div class="col-sm-6">
                  <div class="row">
                    <div class="col-xs-5 col-lg-3"></div>
                    <div class="col-xs-7 col-lg-9 break-word"> 
                      <span  *ngIf="shopDetail.city!=''">{{ shopDetail.city }},</span> 
                      <span  *ngIf="shopDetail.state!=''"> {{ shopDetail.state }},</span>
                      <span  *ngIf="shopDetail.country!=''"> {{ shopDetail.country}} - </span>
                      <span *ngIf="shopDetail.zip!=''"> {{ shopDetail.zip}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="row">
                    <div class="col-xs-5 col-lg-3">Email</div>
                    <div class="col-xs-7 col-lg-9 break-all">
                      {{ shopDetail.email_address }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row table-column CAR-info">
                <div class="col-sm-12 info">
                  <strong class="fw-800">Services: Complete Auto Reports, CAR for short, is an invoicing
                    platform for small to midsize auto repair facilities.</strong>
                  To be known going forward as (the “Service(s)”).
                </div>
              </div>
              <div class="row table-column equal-height-cards">
                <div class="col-sm-6" *ngIf="(subscriptionDetail | json) != ({} | json)">
                  <div class="">
                    <p *ngIf="subscriptionDetail.trialPeriod == 1">You are enabling <strong class="fw-800">The 14 Days Trail</strong> for the following plan:</p>
                    <strong class="fw-800">{{ subscriptionDetail.duration == 12?'Yearly':'Monthly' }} Fees:</strong><span *ngIf="shopDetail.profile_type == 1"> $ {{ subscriptionDetail.amount }}</span>
                  </div>
                  <!-- <div class="">{{ subscriptionDetail.duration }} {{ subscriptionDetail.duration_type }}</div> -->
                  <div class="">
                    Payable in advance, subject to the terms of Section 4 herein.
                    <p>Recurring billing for the selected plan will start on {{handleNxtBillingDtFormat(subscriptionDetail)}}.</p>
                    <p>Cancellation must be completed before renewal period.</p>
                  </div>
                </div>
                <div class="col-sm-6" *ngIf="(subscriptionDetail | json) != ({} | json)">
                  <div class="">
                    <strong class="fw-800">{{ subscriptionDetail.subscription_name }}:</strong>
                  </div>
                  <div class="">{{ subscriptionDetail.subscription_desc }}</div>
                </div>
              </div>
              <div class="row table-column">
                  <div class="col-sm-12 ">
                    <div class="margin-bottom-15">
                      <strong class="fw-800">Service Capacity: </strong>
                      One Account on CAR Server. Customer support is relative to Service
                      Term.
                    </div>
                    <div class="margin-top-5 margin-bottom-5 text-justify">
                      <span>There may be usage charges for excessive space used on a monthly
                        basis. This cost is never to exceed the amount you presently pay
                        monthly in addition to your monthly fee. Each case is take on a
                        case by case basis. Storage included is 1GB of customer data
                        allowed per month, $25 per 1GB extra
                      </span>
                    </div>
                </div>
              </div>
              <div class="row table-column border-bottom">
                <div class="col-sm-12">
                  <div class="text-justify">
                    <span><strong class="fw-800">Implementation Services:</strong> Company
                      will use commercially reasonable efforts to provide Customer the
                      services described in the Statement of Work (“SOW”) attached as
                      Exhibit A hereto (“Support Terms”), and Customer shall pay Company
                      the Implementation Fee in accordance with the terms herein.
                    </span>
                  </div>
                  <div>
                    <span><strong class="fw-800">Implementation Fee (one-time):</strong>
                      $ 0</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-12 col-xs-12 service-agreement">
                  <h2 class="sub-heading padding-top-30 padding-bottom-10">
                    SAAS SERVICES AGREEMENT
                  </h2>
                  <div class="agreement-info">
                    <span>This SaaS Services Agreement (“Agreement”) is entered into on this {{ todayDate| date:'dd' }}
                      day of  {{  todayDate| date:'MMM'  }}, {{  todayDate| date:'yyyy'  }}, (the “Effective Date”) between Complete Auto Reports LLC.
                      with a place of business at 701 N Stiles St, Linden NJ, and the
                      Customer listed above.<u>This Agreement includes and incorporates the above Order Form, as
                        well as the attached Terms and Conditions and contains, among
                        other things, warranty disclaimers, liability limitations and use
                        limitations</u>. There shall be no force or effect to any terms of any related
                      purchase order or similar form even if signed by the parties after
                      the date hereof.</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-12 col-xs-12 margin-top-20 tc-section">
                  <h2 class="sub-heading"><u>TERMS AND CONDITIONS</u></h2>
                </div>
              </div>
              <div class="row tc-row">
                <div class="col-sm-12 col-md-12 col-xs-12">
                  <h2 class="sub-heading margin-top-30 margin-bottom-10">
                    1. SAAS SERVICES AND SUPPORT
                  </h2>
                  <p class="text-center">1.1</p>
                  <div class="margin-top-15 margin-bottom-15">
                    <span>Subject to the terms of this Agreement, Company will use
                      commercially reasonable efforts to provide Customer the Services
                      As part of the registration process, Customer will identify an
                      administrative username and password for Customers Company
                      account. Company reserves the right to refuse registration of, or
                      cancel passwords it deems inappropriate.
                    </span>
                  </div>
                  <p class="text-center">1.2</p>
                  <div class="margin-top-15 margin-bottom-15">
                    <span>Subject to the terms hereof, Company will provide Customer with
                      reasonable technical support services in accordance with the terms
                      set forth in Exhibit A.</span>
                  </div>
                  <h2 class="sub-heading margin-top-30 margin-bottom-10">
                    2. RESTRICTIONS AND RESPONSIBILITIES
                  </h2>
                  <p class="text-center">2.1</p>
                  <div class="margin-top-15 margin-bottom-15">
                    <span>Customer will not, directly or indirectly: reverse engineer,
                      decompile, disassemble or otherwise attempt to discover the source
                      code, object code or underlying structure, ideas, know-how or
                      algorithms relevant to the Services or any software, documentation
                      or data related to the Services (“Software”); modify, translate,
                      or create derivative works based on the Services or any Software
                      (except to the extent expressly permitted by Company or authorized
                      within the Services); use the Services or any Software for
                      timesharing or service bureau purposes or otherwise for the
                      benefit of a third; or remove any proprietary notices or labels.
                      With respect to any Software that is distributed or provided to
                      Customer for use on Customer premises or devices, Company hereby
                      grants Customer a non-exclusive, non-transferable,
                      non-sublicensable license to use such Software during the Term
                      only in connection with the Services.</span>
                  </div>
                  <p class="text-center">2.2</p>
                  <div class="margin-top-15 margin-bottom-15">
                    <span>Further, Customer may not remove or export from the United States
                      or allow the export or re-export of the Services, Software or
                      anything related thereto, or any direct product thereof in
                      violation of any restrictions, laws or regulations of the United
                      States Department of Commerce, the United States Department of
                      Treasury Office of Foreign Assets Control, or any other United
                      States or foreign agency or authority. As defined in FAR section
                      2.101, the Software and documentation are “commercial items” and
                      according to DFAR section 252.227-7014(a)(1) and (5) are deemed to
                      be “commercial computer software” and “commercial computer
                      software documentation.” Consistent with DFAR section 227.7202 and
                      FAR section 12.212, any use modification, reproduction, release,
                      performance, display, or disclosure of such commercial software or
                      commercial software documentation by the U.S. Government will be
                      governed solely by the terms of this Agreement and will be
                      prohibited except to the extent expressly permitted by the terms
                      of this Agreement.
                    </span>
                  </div>
                  <p class="text-center">2.3</p>
                  <div class="margin-top-15 margin-bottom-15">
                    <span>Customer represents, covenants, and warrants that Customer will
                      use the Services only in compliance with Company standard
                      published policies then in effect (the “Policy”) and all
                      applicable laws and regulations. [Customer hereby agrees to
                      indemnify and hold harmless Company against any damages, losses,
                      liabilities, settlements and expenses (including without
                      limitation costs and attorney fees) in connection with any claim
                      or action that arises from an alleged violation of the foregoing
                      or otherwise from Customer use of Services. Although Company has
                      no obligation to monitor Customer use of the Services, Company may
                      do so and may prohibit any use of the Services it believes may be
                      (or alleged to be) in violation of the foregoing.</span>
                  </div>
                  <p class="text-center">2.4</p>
                  <div class="margin-top-15 margin-bottom-15">
                    <span>Customer shall be responsible for obtaining and maintaining any
                      equipment and ancillary services needed to connect to, access or
                      otherwise use the Services, including, without limitation, modems,
                      hardware, servers, software, operating systems, networking, web
                      servers and the like (collectively, “Equipment”). Customer shall
                      also be responsible for maintaining the security of the Equipment,
                      Customer account, passwords (including but not limited to
                      administrative and user passwords) and files, and for all uses of
                      Customer account or the Equipment with or without Customers
                      knowledge or consent</span>
                  </div>

                  <h2 class="sub-heading margin-top-30 margin-bottom-10">
                    3. CONFIDENTIALITY; PROPRIETARY RIGHTS
                  </h2>
                  <p class="text-center">3.1</p>
                  <div class="margin-top-15 margin-bottom-15">
                    <span>Each party (the “Receiving Party”) understands that the other
                      party (the “Disclosing Party”) has disclosed or may disclose
                      business, technical or financial information relating to the
                      Disclosing Partys business (hereinafter referred to as
                      “Proprietary Information” of the Disclosing Party). Proprietary
                      Information of Company includes non-public information regarding
                      features, functionality and performance of the Service.
                      Proprietary Information of Customer includes non-public data
                      provided by Customer to Company to enable the provision of the
                      Services (“Customer Data”). The Receiving Party agrees: (i) to
                      take reasonable precautions to protect such Proprietary
                      Information, and (ii) not to use (except in performance of the
                      Services or as otherwise permitted herein) or divulge to any third
                      person any such Proprietary Information. The Disclosing Party
                      agrees that the foregoing shall not apply with respect to any
                      information after five (5) years following the disclosure thereof
                      or any information that the Receiving Party can document (a) is or
                      becomes generally available to the public, or (b) was in its
                      possession or known by it prior to receipt from the Disclosing
                      Party, or (c) was rightfully disclosed to it without restriction
                      by a third party, or (d) was independently developed without use
                      of any Proprietary Information of the Disclosing Party or (e) is
                      required to be disclosed by law.</span>
                  </div>
                  <p class="text-center">3.2</p>
                  <div class="margin-top-15 margin-bottom-15">
                    <span>Customer shall own all right, title and interest in and to the
                      Customer Data, as well as any data that is based on or derived
                      from the Customer Data and provided to Customer as part of the
                      Services. Company shall own and retain all right, title and
                      interest in and to (a) the Services and Software, all
                      improvements, enhancements or modifications thereto, (b) any
                      software, applications, inventions or other technology developed
                      in connection with Implementation Services or support, and (c) all
                      intellectual property rights related to any of the
                      foregoing.</span>
                  </div>
                  <p class="text-center">3.3</p>
                  <div class="margin-top-15 margin-bottom-15">
                    <span>Notwithstanding anything to the contrary, Company shall have the
                      right collect and analyze data and other information relating to
                      the provision, use and performance of various aspects of the
                      Services and related systems and technologies (including, without
                      limitation, information concerning Customer Data and data derived
                      therefrom), and Company will be free (during and after the term
                      hereof) to (i) use such information and data to improve and
                      enhance the Services and for other development, diagnostic and
                      corrective purposes in connection with the Services and other
                      Company offerings, and (ii) disclose such data solely in aggregate
                      or other de-identified form in connection with its business. No
                      rights or licenses are granted except as expressly set forth
                      herein.</span>
                  </div>
                  <h2 class="sub-heading margin-top-30 margin-bottom-10">
                    4. PAYMENT OF FEES
                  </h2>
                  <p class="text-center">4.1</p>
                  <div class="margin-top-15 margin-bottom-15">
                    <span>Customer will pay Company the then applicable fees described in
                      the Order Form for the Services and Implementation Services in
                      accordance with the terms therein (the “Fees”). If Customer s use
                      of the Services exceeds the Service Capacity set forth on the
                      Order Form or otherwise requires the payment of additional fees
                      (per the terms of this Agreement), Customer shall be billed for
                      such usage and Customer agrees to pay the additional fees in the
                      manner provided herein. Company reserves the right to change the
                      Fees or applicable charges and to institute new charges and Fees
                      at the end of the Initial Service Term or then-current renewal
                      term, upon thirty (30) days prior notice to Customer (which may be
                      sent by email). If Customer believes that Company has billed
                      Customer incorrectly, Customer must contact Company no later than
                      60 days after the closing date on the first billing statement in
                      which the error or problem appeared, in order to receive an
                      adjustment or credit. Inquiries should be directed to Company s
                      customer support department.</span>
                  </div>
                  <p class="text-center">4.2</p>
                  <div class="margin-top-15 margin-bottom-15">
                    <span>Company may choose to bill through an invoice, in which case,
                      full payment for invoices issued in any given month must be
                      received by Company thirty (30) days after the mailing date of the
                      invoice. Unpaid amounts are subject to a finance charge of 1.5%
                      per month on any outstanding balance, or the maximum permitted by
                      law, whichever is lower, plus all expenses of collection and may
                      result in immediate termination of Service. Customer shall be
                      responsible for all taxes associated with Services other than U.S.
                      taxes based on Company s net income.</span>
                  </div>

                  <h2 class="sub-heading margin-top-30 margin-bottom-10">
                    5. TERM AND TERMINATION
                  </h2>
                  <p class="text-center">5.1</p>
                  <div class="margin-top-15 margin-bottom-15">
                    <span>Subject to earlier termination as provided below, this Agreement
                      is for the Initial Service Term as specified in the Order Form,
                      and shall be automatically renewed for additional periods of the
                      same duration as the Initial Service Term (collectively, the
                      “Term”), unless either party requests termination at least thirty
                      (30) days prior to the end of the then-current term.</span>
                  </div>
                  <p class="text-center">5.2</p>
                  <div class="margin-top-15 margin-bottom-15">
                    <span>n addition to any other remedies it may have, either party may
                      also terminate this Agreement upon thirty (30) days notice (or
                      without notice in the case of nonpayment), if the other party
                      materially breaches any of the terms or conditions of this
                      Agreement. Customer will pay in full for the Services up to and
                      including the last day on which the Services are provided. Upon
                      any termination, Company will make all Customer Data available to
                      Customer for electronic retrieval for a period of thirty (30)
                      days, but thereafter Company may, but is not obligated to, delete
                      stored Customer Data. All sections of this Agreement which by
                      their nature should survive termination will survive termination,
                      including, without limitation, accrued rights to payment,
                      confidentiality obligations, warranty disclaimers, and limitations
                      of liability.
                    </span>
                  </div>
                  <h2 class="sub-heading margin-top-30 margin-bottom-10">
                    6. WARRANTY AND DISCLAIMER
                  </h2>
                  <div class="margin-top-15 margin-bottom-15">
                    <span>Company shall use reasonable efforts consistent with prevailing
                      industry standards to maintain the Services in a manner which
                      minimizes errors and interruptions in the Services and shall
                      perform the Implementation Services in a professional and
                      workmanlike manner. Services may be temporarily unavailable for
                      scheduled maintenance or for unscheduled emergency maintenance,
                      either by Company or by third-party providers, or because of other
                      causes beyond Companys reasonable control, but Company shall use
                      reasonable efforts to provide advance notice in writing or by
                      e-mail of any scheduled service disruption. HOWEVER, COMPANY DOES
                      NOT WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR FREE;
                      NOR DOES IT MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE
                      OBTAINED FROM USE OF THE SERVICES. EXCEPT AS EXPRESSLY SET FORTH
                      IN THIS SECTION, THE SERVICES AND IMPLEMENTATION SERVICES ARE
                      PROVIDED “AS IS” AND COMPANY DISCLAIMS ALL WARRANTIES, EXPRESS OR
                      IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
                      MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE AND
                      NON-INFRINGEMENT.</span>
                  </div>
                  <h2 class="sub-heading margin-top-30 margin-bottom-10">
                    7. INDEMNITY
                  </h2>
                  <div class="margin-top-15 margin-bottom-15">
                    <span>Company shall hold Customer harmless from liability to third
                      parties resulting from infringement by the Service of any United
                      States patent or any copyright or misappropriation of any trade
                      secret, provided Company is promptly notified of any and all
                      threats, claims and proceedings related thereto and given
                      reasonable assistance and the opportunity to assume sole control
                      over defense and settlement; Company will not be responsible for
                      any settlement it does not approve in writing. The foregoing
                      obligations do not apply with respect to portions or components of
                      the Service (i) not supplied by Company, (ii) made in whole or in
                      part in accordance with Customer specifications, (iii) that are
                      modified after delivery by Company, (iv) combined with other
                      products, processes or materials where the alleged infringement
                      relates to such combination, (v) where Customer continues
                      allegedly infringing activity after being notified thereof or
                      after being informed of modifications that would have avoided the
                      alleged infringement, or (vi) where Customer’s use of the Service
                      is not strictly in accordance with this Agreement. If, due to a
                      claim of infringement, the Services are held by a court of
                      competent jurisdiction to be or are believed by Company to be
                      infringing, Company may, at its option and expense (a) replace or
                      modify the Service to be non-infringing provided that such
                      modification or replacement contains substantially similar
                      features and functionality, (b) obtain for Customer a license to
                      continue using the Service, or (c) if neither of the foregoing is
                      commercially practicable, terminate this Agreement and Customer’s
                      rights hereunder and provide Customer a refund of any prepaid,
                      unused fees for the Service</span>
                  </div>

                  <h2 class="sub-heading margin-top-30 margin-bottom-10">
                    8. LIMITATION OF LIABILITY
                  </h2>
                  <div class="margin-top-15 margin-bottom-15">
                    <span>NOT WITH STANDING ANYTHING TO THE CONTRARY, EXCEPT FOR BODILY
                      INJURY OF A PERSON, COMPANY AND ITS SUPPLIERS (INCLUDING BUT NOT
                      LIMITED TO ALL EQUIPMENT AND TECHNOLOGY SUPPLIERS), OFFICERS,
                      AFFILIATES, REPRESENTATIVES, CONTRACTORS AND EMPLOYEES SHALL NOT
                      BE RESPONSIBLE OR LIABLE WITH RESPECT TO ANY SUBJECT MATTER OF
                      THIS AGREEMENT OR TERMS AND CONDITIONS RELATED THERETO UNDER ANY
                      CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHER THEORY: (A) FOR
                      ERROR OR INTERRUPTION OF USE OR FOR LOSS OR INACCURACY OR
                      CORRUPTION OF DATA OR COST OF PROCUREMENT OF SUBSTITUTE GOODS,
                      SERVICES OR TECHNOLOGY OR LOSS OF BUSINESS; (B) FOR ANY INDIRECT,
                      EXEMPLARY, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES; (C) FOR
                      ANY MATTER BEYOND COMPANY’S REASONABLE CONTROL; OR (D) FOR ANY
                      AMOUNTS THAT, TOGETHER WITH AMOUNTS ASSOCIATED WITH ALL OTHER
                      CLAIMS, EXCEED THE FEES PAID BY CUSTOMER TO COMPANY FOR THE
                      SERVICES UNDER THIS AGREEMENT IN THE 12 MONTHS PRIOR TO THE ACT
                      THAT GAVE RISE TO THE LIABILITY, IN EACH CASE, WHETHER OR NOT
                      COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</span>
                  </div>
                  <h2 class="sub-heading margin-top-30 margin-bottom-10">
                    9. MISCELLANEOUS
                  </h2>
                  <div class="margin-top-15 margin-bottom-15">
                    <span>If any provision of this Agreement is found to be unenforceable
                      or invalid, that provision will be limited or eliminated to the
                      minimum extent necessary so that this Agreement will otherwise
                      remain in full force and effect and enforceable. This Agreement is
                      not assignable, transferable or sublicensable by Customer except
                      with Company’s prior written consent. Company may transfer and
                      assign any of its rights and obligations under this Agreement
                      without consent. This Agreement is the complete and exclusive
                      statement of the mutual understanding of the parties and
                      supersedes and cancels all previous written and oral agreements,
                      communications and other understandings relating to the subject
                      matter of this Agreement, and that all waivers and modifications
                      must be in a writing signed by both parties, except as otherwise
                      provided herein. No agency, partnership, joint venture, or
                      employment is created as a result of this Agreement and Customer
                      does not have any authority of any kind to bind Company in any
                      respect whatsoever. In any action or proceeding to enforce rights
                      under this Agreement, the prevailing party will be entitled to
                      recover costs and attorneys’ fees. All notices under this
                      Agreement will be in writing and will be deemed to have been duly
                      given when received, if personally delivered; when receipt is
                      electronically confirmed, if transmitted by facsimile or e-mail;
                      the day after it is sent, if sent for next day delivery by
                      recognized overnight delivery service; and upon receipt, if sent
                      by certified or registered mail, return receipt requested.</span>
                  </div>
                  <div class="margin-top-15 margin-bottom-15">
                    <span>Customer agrees and authorizes Company to use Customer s name,
                      likeness and location information in Company s promotional efforts
                      including, but not limited to, social media and other marketing
                      materials.
                    </span>
                  </div>
                  <div class="margin-top-15 margin-bottom-15">
                    <span>This Agreement shall be governed by the laws of the State of New
                      Jersey without regard to its conflict of laws provisions. Any
                      suit, action or proceeding arising out of or related to the terms
                      of this agreement will be brought and maintained exclusively in
                      the United States District Court for the District of New Jersey or
                      in the Superior Court of the State of New Jersey.</span>
                  </div>
                </div>
              </div>
              <hr />
              <div class="row tc-row padding-top-15">
                <div class="col-sm-12 col-md-12 col-xs-12">
                  <div class="margin-top-15 margin-bottom-15">
                    <span>I agree to above terms and conditions. Please type your name in
                      confirming that you agree.</span>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="padding-top-5 padding-bottom-5">
                    <span><strong class="fw-800">Complete Auto Reports, LLC</strong></span>
                  </div>
                  <div class="padding-top-5 padding-bottom-5">
                    <span><strong class="fw-800">701 N Stiles Street</strong></span>
                  </div>
                  <div class="padding-top-5 padding-bottom-5">
                    <span><strong class="fw-800">Linden, NJ 07036</strong></span>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="padding-top-5 padding-bottom-5">
                    <span><strong class="fw-800">[Customer]</strong></span>
                  </div>
                  <div class="padding-top-5 padding-bottom-5">
                    <span>Name: &nbsp; &nbsp;
                      <span class="padding-left-5 break-word">{{ shopDetail.company_name || 'N/A' }}</span></span>
                  </div>
                  <div class="padding-top-5 padding-bottom-5">
                    <span>Signature: </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row cc-auth-stickySection">
            <div class="col-xs-12 col-sm-12 col-md-5">
              <div class="row">
                <div class="col-sm-12 padding-right-0">
                  <!-- <label class="checkbox-label checkbox-bigger"> <input type="checkbox" formControlName="terms"> I agree to above terms and conditions.</label> -->
                  <mat-checkbox formControlName="terms" class="terms-checkbox">I agree to above terms and conditions.</mat-checkbox>
                  <mat-error class="tc-error-msg" *ngIf="eulaFormGroup.controls.agree.hasError('required')">
                    Terms and Conditions is <strong>required</strong>
                  </mat-error>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-5">
              <div class="row">
                <div class="col-sm-12">
                  <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                    <input matInput type="text" placeholder="Enter your name" formControlName="user_name" required
                      class="form-control input-lg custom-form-input btn-block" />
                    <mat-error *ngIf="eulaFormGroup.controls.user_name.hasError('required')">
                      Name is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>  
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 text-right">
              <button type="submit" mat-raised-button class="font-bold" [disabled]="!eulaFormGroup.valid"
                color="accent">Next </button>
            </div>
          </div>
        </div>
        <!-- <hr /> -->
      </form>
    </mat-step>
    <mat-step *ngIf="!isShopProfileSetupCompleted" [editable]="isEditable"> 
      <ng-template class="no-event" matStepLabel><span class="hidden-xs">PROFILE SETUP</span><span class="visible-xs">PROFILE</span></ng-template>
      <app-complete-profile-setup (handleSkipShopProfileSetup)="skipShopProfileStepInEULA()" class="ccauth-profile-setUp"></app-complete-profile-setup>
    </mat-step>
    <mat-step [editable]="isEditable">
      <ng-template class="no-event" matStepLabel>DONE</ng-template>
      <div class="display-full margin-bottom-5">
        <div class="col-sm-12 text-center margin-top-20">
          <span>
            <i class="fa fa-check-circle" style="font-size: 64px;color:#5cb85c;" aria-hidden="true"></i>
          </span>
          <h2 class="title">Success!</h2>
          <p class="text-center" *ngIf="!isStepOneCompleted">All the services will be added to your account within 1-2 hours. Once services will be
            assigned, you will get a notification.</p>
        </div>
      </div>
      <div class="text-right">
        <button mat-raised-button class="font-bold" (click)="refresh()" color="accent">Done</button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</mat-dialog-content>