<div class="panel panel-primary" id="mainCompotents">
        <div class="panel-heading padding-left-15">
            <h3 class="panel-title">SMART BATCH LIST</h3>
            <!-- <button mat-mini-fab class="custom-btn-back" (click)="gs.goBack();">
               <mat-icon>arrow_back</mat-icon>
            </button> -->
            <button mat-mini-fab class="custom-btn-back btn-part-list" title="Parts List"  (click)="goToPartsList();">
                <i class="fa fa-fw fa-list margin-right-5"></i><span class="hidden-xs">PARTS LIST</span>
            </button>
        </div>
        <div class="panel-body bgcolor-lightgrey">
            <div class="display-full relative">
                <mat-expansion-panel hideToggle="false" class="search-filter search-employee relative" [expanded]="openSmartBatchSearch">
                    <mat-expansion-panel-header collapsedHeight="53px" expandedHeight="53px">
                        <strong><i class="fa fa-search fa-fw"></i>&nbsp;Search Filters</strong>
                    </mat-expansion-panel-header>
                    <form id="advanceSearchForm" class="collapse in" aria-expanded="true">
                        <div class="panel-body">
                            <div class="col-sm-3 uc-ngx-select">
                                
                                <ngx-select class="display-full margin-bottom-20" 
                                    [items]="searchByArray" 
                                    [noAutoComplete]="true"
                                    [(ngModel)]="options.search_by" 
                                    [ngModelOptions]="{standalone: true}"
                                    placeholder="Search By">
                                </ngx-select>
                            </div>
    
                            <div class="col-sm-3">
                                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                                    <input  matInput placeholder="Search Keyword" title="Search Keyword" [(ngModel)]="options.search_keyword" [ngModelOptions]="{standalone: true}" class="form-control input-lg custom-form-input"/>
                                </mat-form-field>
                            </div> 
                        </div>
                        <mat-action-row class="search-filter-footer">
                          <button mat-raised-button color="accent" type="submit" (click)="search($event)">
                            Search
                          </button>
                        </mat-action-row>
                    </form>
                </mat-expansion-panel>
                <button mat-button  class="clear-btn" #clearBtn (click)="clearSearch()" [class.active-search]="checkFormEmptys()">
                    Clear Filter
                </button>
            </div>
    
            <div class="car-datatable uc-datatable mat-elevation-z1">
                <div class="table-responsive relative">
                    <mat-table #table [dataSource]="dataSource" matSort class="datatable-loader-bg uc-table-responsive">
    
                        <!-- Color Column -->
                        <ng-container matColumnDef="Part Type">
                            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Part Type </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-role="auto" data-val="Part Type"> {{row.name || '-'}} </mat-cell>
                        </ng-container>
    
                        <!-- Color Column -->
                        <ng-container matColumnDef="Smart Batch Number">
                            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Smart Batch Number </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-role="auto" data-val="Smart Batch Number"> {{(row.part_number)}} </mat-cell>
                        </ng-container>
    
                        <!-- Color Column -->
                        <ng-container matColumnDef="Actions">
                            <mat-header-cell *matHeaderCellDef data-role="button"> Actions </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-role="button">
                                <button mat-button class="list-action-btn" [matMenuTriggerFor]="menu" disableRipple >
                                    Action<mat-icon>arrow_drop_down</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu" class="car-icon-menu" [overlapTrigger]="false">
                                    <a class="mat-menu-item" (click)="edit(row.id)"><i class="fa fa-edit"></i>Edit</a>
                                    <!-- <a class="mat-menu-item" (click)="addNewPart(row.part_number)"><i class="fa fa-plus"></i>Add New Part</a> -->
                                </mat-menu>
                            </mat-cell>
                        </ng-container>
    
                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    </mat-table>
                    <div class="padding-15 text-center"
                         [style.display]="getTotalRecords() < 1 ? '' : 'none'">
                         No Records Available
                </div>
            </div>
            <div class="display-full relative" [style.display]="getTotalRecords() < 1 ? 'none' : ''">
                <mat-paginator class="car-pagination" #paginator [length]="getTotalRecords()" [pageIndex]="0" [pageSize]="options.limit" [pageSizeOptions]="[5, 10,25, 100]" (page)="paginateByLimit($event)" >
                </mat-paginator>
    
                <div class="mat-paginator table-paging">
                    <div class="mat-paginator-range-label margin-left-30 margin-right-30">{{options.start+1}} - {{getUpperLimit(options.start + options.limit,getTotalRecords())}} of {{getTotalRecords()}}</div>
                    <button mat-icon-button (click)="paginate($event,'previous');" [disabled]="firstList()">
                            <mat-icon>navigate_before</mat-icon>
                    </button>
                    <button mat-icon-button (click)="paginate($event,'next');" [disabled]="lastList()">
                            <mat-icon>navigate_next</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
    </div>
    