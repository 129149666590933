import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { GlobalService } from '../../../../environments/global.service';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { globalConstants } from '../../../../environments/globalConstants';
import { ImagetooldialogComponent } from '../../../imagetooldialog/imagetooldialog.component';
import { globalFunctions } from '../../../../environments/globalFunctions';

@Component({
  selector: 'app-messageview',
  templateUrl: './messageview.component.html',
  styleUrls: ['./messageview.component.scss']
})
export class MessageviewComponent implements OnInit {

  public user: any = null
  public param: any = {}
  public shopAddComment: FormGroup;
  public serviceReadStatus = '';
  public serviceInfo: any;
  public serviceInfoComments: any = [];
  public serviceInfoStatus;
  public read_status;
  public statuses = ['', 'Created', 'Resolved', 'Closed', 'Unread'];
  public complaintImgThumbUrl: string = globalConstants.S3_BUCKET_URL + globalConstants.VEHICLE_COMPLAINT_PIC + globalConstants.IMG_THUMB;

  constructor(@Inject(MAT_DIALOG_DATA) public p: any, private formBuilder: FormBuilder, public gs: GlobalService, public dialogRef: MatDialogRef<MessageviewComponent>, public dialog: MatDialog) {

    this.user = this.gs.getCurrentUser()
    this.gs.diaLogObj(this.dialogRef);
    this.param = p
    let isEnable = this.gs.getAcl('complaints', 'views');
    if (!(isEnable)) {
      this.dialogRef.close()
    }
    this.initForm()
  }

  ngOnInit() {
    let content = "user_id=" + this.user.id + "&user_type=" + this.user.user_type + "&service_complaint_id=" + this.param.id + "&read_status=" + this.serviceReadStatus;
    this.gs.callAPI(globalConstants.API_GET_SERVICE_COMPLAINT_VIEW_URL, content).subscribe((response:any) => {
      if (response.result == 1) {
        // console.log("service", response.data);
        this.serviceInfo = response.data;
        this.serviceInfoStatus = response.data.status;
        this.shopAddComment.controls.add_comment_request_id.setValue(this.serviceInfo.request_id);
        this.shopAddComment.controls.service_complaint_customer_id.setValue(this.serviceInfo.user_id);
        if (this.serviceInfo.comments != null) {
          if (this.serviceInfo.comments && this.serviceInfo.comments.length) {
            this.serviceInfoComments = this.serviceInfo.comments;
          } else {
            this.serviceInfoComments[0] = this.serviceInfo.comments;
          }
        }
        let vehicleName = "";
        if (this.serviceInfo.year) {
          vehicleName = this.serviceInfo.year;
        }
        if (this.serviceInfo.make != "") {
          vehicleName += (vehicleName ? "/" : "") + this.serviceInfo.make;
        }
        if (this.serviceInfo.model != "") {
          vehicleName += (vehicleName ? "/" : "") + this.serviceInfo.model
        }
        this.serviceInfo.vehicleName = (vehicleName ? vehicleName : 'N/A');

      } else {
        this.gs.snackbar("error", response.message);
      }

    });
  }
  initForm() {
    this.shopAddComment = this.formBuilder.group({
      service_complaint_id: this.param.id,
      service_complaint_customer_id: [''],
      user_type: this.user.user_type,
      user_id: this.user.id,
      complaint_comment: ['', Validators.required],
      complaint_resolver: [''],
      add_comment_request_id: [],
      is_comment: [true],
    })
  }
  getStatus(statusId) {
    return this.statuses[statusId]
  }
  getProperDate(value) {
    return this.gs.getFullFormateDate(value, 'G', 'B');
  }
  
  showTaxExempted(event) {
    if (event.checked) {
      this.shopAddComment.controls.complaint_resolver.setValue(true);
    } else {
      this.shopAddComment.controls.complaint_resolver.setValue(false);
    }
  }
  /* image rotation dialog  */
  imageToolDialog(current, i, type, imgaar): void {
    let dialogRef = this.dialog.open(ImagetooldialogComponent, {
      panelClass: ['imageToolDialog'],
      width: "100%",
      data: { event: current, index: i, imgType: type, imgArray: imgaar, customerId: this.gs.getCurrentUser()["id"] }
    });
  }
  addComment() {
    Object.keys(this.shopAddComment.controls).map((controlName) => {
      this.shopAddComment.get(controlName).markAsTouched({ onlySelf: true });
    });
    if (this.shopAddComment.valid) {
      var stringifiedFormData = globalFunctions.stringifyFormData(this.shopAddComment.value, null);
      this.gs.callAPI(globalConstants.API_ADD_COMPLAINT_URL, stringifiedFormData).subscribe((data:any) => {
        var message = data.message;
        if (data.result == "1") {
          this.gs.snackbar('success', message);
          this.shopAddComment.controls.complaint_comment.reset();
          this.ngOnInit();
        } else {
          this.gs.snackbar('error', message);
        }
      },(error)=>{ });
    } else {
      this.gs.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
    }
  }

}
