import { Component, OnInit, ViewChild, Pipe, Inject, OnDestroy } from '@angular/core';
import { AbstractControl, FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router';
import { Http, Headers, Response } from '@angular/http';
import { HttpHeaders } from '@angular/common/http';
import { globalFunctions } from '../../../../environments/globalFunctions';
import { globalConstants } from '../../../../environments/globalConstants';
import { GlobalService } from '../../../../environments/global.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { DataSource, SelectionModel } from '@angular/cdk/collections';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ScrollToService } from 'ng2-scroll-to-el';
import {Subscription} from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';

const SHOP_EMP_LIST = 'shop-emp-';

@Component({
  selector: 'app-listemployee',
  templateUrl: './listemployee.component.html',
  styleUrls: ['./listemployee.component.scss']
})
export class ListemployeeComponent implements OnInit, OnDestroy {
  public empList: FormGroup;
  displayedColumns = ['name', 'email', 'mobile', 'role_status', 'login', 'default', 'Actions'];
  public searchOn=[{id:'firstName',text:'First Name'},{id:'lastName',text: 'Last Name'}, {id:'dob',text:'Birthday'}, {id:'email',text:'Email'}, {id:'enabled',text:'Enabled'},{id:'disabled',text:'Disabled'},{id:'deleted',text:'Deleted'}];
  public more_rows;
  public currentUser;
  public options = {
    user_type: "",
    user_id: "",
    search_keyword: "",
    search_by:'0',
    empdob:'',
    start: 0,
    limit: 10,
  };

  public isListView:boolean = true;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public docImgPath = globalConstants.S3_BUCKET_URL + globalConstants.EMP_DOCUMENT + globalConstants.IMG_THUMB;
  public subscriptionDataForAPI: Subscription = new Subscription();
  dataSource: MatTableDataSource<UserData> = new MatTableDataSource();
  public record :any =[];
  recordsTotal: any;
  gridDataList: any;
  constructor(public dialog: MatDialog,private formBuilder: FormBuilder, private scrollService: ScrollToService, private globalService: GlobalService, private router: Router) {
    setTimeout(()=>{
      let isEnable=this.globalService.getAcl('employees','enable');
      if(!(isEnable)){
          this.router.navigate(['shops/action/forbidden']);
      }
    },globalConstants.RELOAD_WAIT);
    this.globalService.setMetaData("SHOPS","EMPLOYEE_LIST")
    if(localStorage.getItem(SHOP_EMP_LIST + 'isListView')!=undefined){
      this.isListView = parseInt(localStorage.getItem(SHOP_EMP_LIST + 'isListView'))==1?true:false;
    }   
  }

  ngOnInit() {
    this.empList = this.formBuilder.group({
      user_id: [''],
    });
    let search_keyword = this.globalService.getSetItems('userAlreadyExist');
    this.currentUser = this.globalService.getCurrentUser();
    this.options.user_id = this.currentUser.id;
    this.options.user_type = this.currentUser.user_type;
    this.options.search_keyword = (search_keyword ? search_keyword : '');
    this.options.search_by = (search_keyword ? 'emails' : '0');
    this.setDataTable();
  }

     /** define the paginator in after init the page  */
     ngAfterViewInit() {
      this.dataSource.paginator = this.paginator;
    }

  changeView(){
    this.isListView = !this.isListView;
    this.globalService.localGetterSetter("isListView", this.isListView?1:0, SHOP_EMP_LIST);
  }

  getTotalRecords() {
    return parseInt(localStorage.getItem(SHOP_EMP_LIST + 'totalRecords'));
  }

  paginate(event, type) {
    this.scrolling();
    if (type == "next") {
      this.options.start = this.options.start + this.options.limit;
    } else {
      this.options.start = this.options.start - this.options.limit;
    }
    this.setDataTable();
  }

  firstList() {
    return this.globalService.firstList(SHOP_EMP_LIST, this.options);
  }

  lastList() {
    return this.globalService.lastList(SHOP_EMP_LIST);
  }
  getUpperLimit(currentLastLimit, total) {
    if (currentLastLimit > total) {
      return total
    } else {
      return currentLastLimit
    }
  }
  paginateByLimit(event) {
    this.scrolling();
    this.options.limit = event.pageSize;
    this.options.start = 0;
    this.setDataTable();
  }
  setDataTable() { 
    this.record = [];  
    // this.dataSource = new EmpDataSource(new EmpData(this.options, this.globalService), this.sort, this.paginator);
    var content = globalFunctions.stringifyFormData(this.options, null);
    var callApi = this.globalService.callAPI(globalConstants.API_GET_USERS, content);
    callApi.subscribe((data:any) => {
      if (data.data != undefined) {
        let moreRows = 'false';
        let firstList = 'false';
        this.globalService.localGetterSetter("totalRecords", data.recordsTotal, SHOP_EMP_LIST);
        if (data.more_rows == "true") {
          moreRows = "true";
        }
        this.globalService.localGetterSetter("more_rows", moreRows, SHOP_EMP_LIST);
        if (data.start >= 0 && data.start <= 5) {
          firstList = "true";
        }
        this.globalService.localGetterSetter("first_list", firstList, SHOP_EMP_LIST);
        if (data.data.length != undefined) {
          this.recordsTotal = data.data.length;
          for (var i = 0; i < data.data.length; i++) {
            this.AddRecord(data.data[i])
          }
          this.dataSource = new MatTableDataSource(this.record)
          this.dataSource.sort = this.sort;
          this.gridDataList = this.record;
          this.globalService.localGetterSetter("current-visible", this.recordsTotal, SHOP_EMP_LIST);
        } else {
          this.globalService.localGetterSetter("current-visible", '1', SHOP_EMP_LIST)
          this.recordsTotal = "1";
          this.AddRecord(data.data)
          this.gridDataList = this.record
          this.dataSource = new MatTableDataSource(this.record)
          this.dataSource.sort = this.sort;
        }
        this.globalService.localGetterSetter(null, this.options, SHOP_EMP_LIST);
      } else {
        this.gridDataList = this.record
        this.dataSource = new MatTableDataSource(this.record)
        this.globalService.localGetterSetter("totalRecords", '0', SHOP_EMP_LIST);
      }
      this.globalService.getSetItems('userAlreadyExist', '');
    });
 
  }

  /** its user add the record via using create r */
AddRecord(record){
  var data = this.createRecord(record);
    this.record.push(data);
}

createRecord(record) {
  return {
    empcode: record.id,
    name: record.first_name + ' ' + record.last_name,
    email: record.email,
    mobile: record.cell_phone1,
    role_status: record.role_status,
    login_status: record.login_status,
    default_status: record.default_status,
    city:record.city,
    country:record.country,
    address:record.address1+" "+record.address2,
    photo:record.photo
  }
}
  scrolling() {
    this.scrollService.scrollTo('.car-datatable', 1000, -20);
  }
  searchChange(event){
    this.options.search_keyword = '';
    this.options.empdob = '';
  }
  search(event) {
    this.options.start = 0;
    if(this.options.search_by=='dob'){
      let d = new Date(this.options.search_keyword);
      let m=(d.getMonth() + 1).toString();
      let md=(d.getDate()).toString();
      this.options.empdob = d.getFullYear()+'-' +('00'+m).slice(m.length) + '-' + ('00'+md).slice(md.length);
    }
    this.setDataTable();
  }
  clearSearch() {
    this.globalService.getSetItems('userAlreadyExist', '');
    this.globalService.localGetterSetter(null, this.options, SHOP_EMP_LIST);
    this.reInitOptions();
    this.setDataTable();
  }
  reInitOptions() {
    this.options = this.globalService.localGetterSetter(this.options, this.options, SHOP_EMP_LIST);
    this.options.user_id = this.currentUser.id;
    this.options.user_type = this.currentUser.user_type;
    this.options.search_keyword = '';
  }
  checkFormEmpty(button) {
    if (this.options.search_keyword == "") {
      if (button) { button.color = "primary" }
      return false;
    }
    else {
      if (button) { button.color = "accent" }
      return true;
    }
  }
  actionOnEmp(event, id?: any) {
    if (event == 'edit') {
      this.router.navigate(['shops/employee/edit/' + id]);
    } else if (event == 'view') {
      this.router.navigate(['shops/employee/view/' + id]);
    } else if (event == 'permission') {
      this.router.navigate(['shops/employee/permissions/' + id]);
    } else if (event == 'delete') {
      this.empDelete(id);
    }else if (event == 'resetPwd'){
      this.resetPwd(id);
    } else if (event == 'techreport') {
      this.router.navigate(['shops/employee/report/' + id]);
    }
  }
  empDelete(id) {
    let data = 'dataType=delete&emp_id=' + id + '&user_id=' + this.currentUser.id + '&user_type=' + this.currentUser.user_type;
    this.globalService.confirmDialog('Delete', 'Are you sure you want to delete?').subscribe((res) => {
      let r: any = res;
      if (r == 'Delete') {
        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_MANAGE_USERS_STATUS, data).subscribe((res:any) => {
          if (res.result == 1) {
            this.globalService.snackbar('success', res.message);
            this.setDataTable();
          } else {
            this.globalService.snackbar('error', res.message);
          }
        });
      }
    });
  }
  setStatus(obj, row) {
    let loginStatus=['enable','disable','reactivate'];
    let data = 'dataType=disable&emp_id=' + row.empcode + '&user_id=' + this.currentUser.id + '&user_type=' + this.currentUser.user_type;
    this.globalService.confirmDialog('Disable', 'Are you sure you want to '+loginStatus[row.login_status]+'?').subscribe((res) => {
      let r: any = res;
      if (r == 'Disable') {
        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_MANAGE_USERS_STATUS, data).subscribe((res:any) => {
          if (res.result == 1) {
            this.globalService.snackbar('success', res.message);
            this.setDataTable();
          } else {
            this.globalService.snackbar('error', res.message);
          }
        });
      }
    });
  }
  resetPwd(id) {
    let dialogRef = this.dialog.open(resetPassword, {
      panelClass: 'car-dialog-form',
      width: "800px",
      data: { emp_id: id }
    });
    dialogRef.afterClosed().subscribe(result => {  });
  }
  isAllowed(action){
    return this.globalService.getAcl('employees',action);
  }
  isManageAdvisory(){
    return this.globalService.getAcl('manage_advisors','edits');
  }
  isManageTechnician(){
    return this.globalService.getAcl('manage_technicians','edits');
  }
  isSetPermissionAllowed(){
    return this.globalService.getAcl('shop_roles_permission','edits');
  }
  isTechnicianAllowed(){
    return ( this.currentUser.role_id != 4 ? true : false );
  }
  getDesignation(id){
    return (typeof globalConstants.ROLES[id] != 'undefined' ? globalConstants.ROLES[id] :'');
  }

  ngOnDestroy(){
    this.subscriptionDataForAPI.unsubscribe();
  }  
}
export interface UserData {
  empcode: any;
  name: any;
  email: any;
  mobile: any;
  role_status: boolean;
  login_status: boolean;
  default_status: boolean;
  city:string,
  country:string,
  address:string,
  photo:string
}



@Component({
  template: '<div mat-dialog-title class="relative">{{popUpTitle}}<button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">'+
    '<mat-icon class="notranslate close-icon-lg">close</mat-icon>'+
  '</button></div><mat-dialog-content><div class="center-block reset-section" style="max-width: 600px;">'+
    '<form [formGroup]="newPassword" class="form-horizontal form-control-normal"><p>{{note}}</p>'+
    '<div class="display-full margin-bottom-5" *ngIf="note==\'\'">'+
    '<label class="col-sm-3 reset-padding control-label"><strong>New Password</strong></label>'+
    '<div class="col-sm-9"><mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">'+
    '<input matInput type="password" placeholder="New Password" formControlName="password" class="form-control input-lg custom-form-input btn-block" />      </mat-form-field>    </div>  </div>'+
    '<div class="display-full margin-bottom-5" *ngIf="note==\'\'">'+
    '<label class="col-sm-3 reset-padding control-label"><strong>Confirm Password</strong></label>'+
    '<div class="col-sm-9"><mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">'+
    '<input matInput type="password" placeholder="Confirm Password" formControlName="repassword" class="form-control input-lg custom-form-input btn-block" />      </mat-form-field>    </div>  </div>'+
    '<div class="display-full margin-bottom-5" *ngIf="note!=\'\'">'+
    '<label class="col-sm-3 control-label"><strong>Password</strong></label>'+
    '<div class="col-sm-9"><mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">'+
    '<input matInput type="password" placeholder=" Password" formControlName="password" class="form-control input-lg custom-form-input btn-block" required/>      </mat-form-field>    </div>  </div>'+
    '</form></div></mat-dialog-content>'+
    '<div class="col-sm-12 reset-buttonSection"><div class="col-sm-6"> </div><div class="col-sm-6">'+
    ' <button mat-raised-button *ngIf="note!=\'\'" (click)="authorize()" class="font-bold margin-right-10" color="accent">Submit</button> '+
    ' <button mat-raised-button *ngIf="note==\'\'" (click)="reset()" class="font-bold margin-right-10" color="accent">Reset</button> '+
    ' <button mat-raised-button mat-dialog-close class="font-bold">Close</button></div></div>'
})
export class resetPassword  implements OnDestroy {
  public newPassword: FormGroup;
  public popUpTitle='USER AUTHENTICATION';
  public note='Please enter your login password for authentication to change employee password.';
  public subscriptionDataForAPI: Subscription = new Subscription();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<resetPassword>, private formBuilder: FormBuilder, private gs: GlobalService) {
    this.newPassword = this.formBuilder.group({
      password:[''],
      repassword:[''],
      emp_id:data.emp_id,
      user_type: this.gs.getCurrentUser()["user_type"],
      user_id: this.gs.getCurrentUser()["id"],
      dataType:'authorize'
    });
    this.gs.diaLogObj(this.dialogRef);
  }
  authorize(){
    this.newPassword.controls.repassword.setValue(this.newPassword.value.password);
    let formdata = this.newPassword.value;
    formdata.password = formdata.password.replaceAll('+','%2B');
    formdata.repassword = formdata.repassword.replaceAll('+','%2B');
    this.subscriptionDataForAPI = this.gs.callAPI(globalConstants.API_RESET_SHOP_USER_PWD_URL,formdata).subscribe((res:any)=>{
      if(res.result=='1'){
      this.popUpTitle='Reset Password';
      this.note='';
      this.newPassword.controls.password.setValue('');
      this.newPassword.controls.repassword.setValue('');
      }else{
        this.gs.snackbar("error", res.message);
      }
    });
  }
  reset(){
    this.newPassword.controls.dataType.setValue('reset');
    let formdata = this.newPassword.value;
    formdata.password = formdata.password.replaceAll('+','%2B');
    formdata.repassword = formdata.repassword.replaceAll('+','%2B');
    this.subscriptionDataForAPI = this.gs.callAPI(globalConstants.API_RESET_SHOP_USER_PWD_URL,formdata).subscribe((res:any)=>{
      if(res.result=='1'){
        this.gs.snackbar("success", res.message);
        this.dialogRef.close();
      }else{
        this.gs.snackbar("error", res.message);
      }
    });
  }

  ngOnDestroy(){
    this.subscriptionDataForAPI.unsubscribe();
  } 
}