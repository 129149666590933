<div class="grid-box-wrapper" [ngClass]="selectedFlag">
  <div class="row srId-section">
    <div class="col-xs-7 col-sm-7 padding-left-10 badge-wrap">
      <mat-chip-list class="mat-badge">
        <mat-chip class="cursor-pointer mat-chip-selected margin-0 {{ getSRStatusColor() }}" selected="true">
          <span class="font-bold" (click)="openServiceRequestViewPage(serviceReqData.id)">{{serviceReqData.request_id}}</span>
          <span class="font-bold"></span>
        </mat-chip>
      </mat-chip-list>
      <div class="color-dropdown">
        <button [matMenuTriggerFor]="menu" class="color-btn text-white">
          <i class="fa fa-font-awesome" aria-hidden="true"></i>
        </button>
        <mat-menu #menu="matMenu" class="mat-dropdown-menu color-items" [overlapTrigger]="false" xPosition="before">
          <button mat-menu-item class="gray" (click)="setSRFlag('gray')">Gray</button>
          <button mat-menu-item class="blue" (click)="setSRFlag('blue')">Blue</button>
          <button mat-menu-item class="teal" (click)="setSRFlag('teal')">Teal</button>
          <button mat-menu-item class="green" (click)="setSRFlag('green')">Green</button>
          <button mat-menu-item class="yellow" (click)="setSRFlag('yellow')">Yellow</button>
          <button mat-menu-item class="red" (click)="setSRFlag('red')">Red</button>
          <button mat-menu-item class="purple" (click)="setSRFlag('purple')">Purple</button>
          <button mat-menu-item class="no-color" (click)="setSRFlag('no-color')">Remove Flag</button>
        </mat-menu>
      </div>
      <div *ngIf="serviceReqData.recom_locker_id!= 0 && serviceReqData.recom_locker_id!=currentCustomer.id">
        <i class="fa far fa-circle-user userIcon" [tooltip]="currentUserInfoTooltip"></i>
        <tooltip-content #currentUserInfoTooltip  placement="bottom">
           {{serviceReqData.recom_locker_username}}
      </tooltip-content>
      </div>

      <div *ngIf="jobPause && serviceReqDataComingFormPage== 'myWorkLoad'">
        <i class="fa fa-play userIcon" (click)="resumeTheJob(1)" [tooltip]="jobStartInWorkColumnTooltip"></i>
        <tooltip-content #jobStartInWorkColumnTooltip  placement="bottom">
            Start
         </tooltip-content>
      </div>
      <div *ngIf="jobResume && serviceReqDataComingFormPage=='myWorkLoad'">
        <i class="fa fa-pause userIcon" (click)="pausedTheJob(2)" [tooltip]="jobStartTooltip"></i>
        <tooltip-content #jobStartTooltip  placement="bottom">
          Pause
       </tooltip-content>
      </div>
      <div *ngIf="jobStart && serviceReqDataComingFormPage=='myWorkLoad'">
        <i class="fa fa-play userIcon" (click)="pausedTheJob(1)" [tooltip]="jobStartInNotWorkColumnTooltip"></i>
        <tooltip-content #jobStartInNotWorkColumnTooltip  placement="bottom">
          Start
       </tooltip-content>
      </div>
      <div *ngIf="(jobResume) && serviceReqDataComingFormPage=='myWorkLoad'">
        <i class="fa fa-check userIcon" (click)="pausedTheJob(3)" [tooltip]="jobCompleteTooltip"></i>
        <tooltip-content #jobCompleteTooltip  placement="bottom">
          Complete
       </tooltip-content>
      </div>

    </div>
    <div class="col-xs-4 col-sm-4 created-section text-right f-12">
      <div>Created</div>
      <div>{{makeProperDateFormate(serviceReqData.add_date,'B')}}</div>
    </div>
    
    <div class="col-xs-1 col-sm-1 dot-btn-section">
      <button class="mat-icon-button dot-btn" mat-icon-button [matMenuTriggerFor]="actionMenu" disableRipple>
        <mat-icon class="mat-icon material-icons text-red">more_vert</mat-icon>
      </button>
      <mat-menu #actionMenu="matMenu" class="car-icon-menu disable-no-bg" [overlapTrigger]="false" xPosition="before">
        <button class="mat-menu-item" disableRipple type="button" *ngIf="isAllowed('edits')" (click)="openEditServiceRequestPage(serviceReqData.id,serviceReqData.customer_id)" [disabled]="(serviceReqData.status >= 6)" title="Edit Request"> <mat-icon>mode_edit</mat-icon>Edit</button>
        <button class="mat-menu-item" disableRipple type="button" *ngIf="isAllowed('views')" (click)="openServiceRequestViewPage(serviceReqData.id)" title="View Request"> <mat-icon>remove_red_eye</mat-icon>View</button>
        <button class="mat-menu-item" disableRipple type="button" *ngIf="isAllowed('addNew','mark_request_under_pr') && serviceReqData.status == 1" (click)="underProcess(serviceReqData)" title="Under Process"> <i class="fa fa-regular fa-square-check"></i>Under Process</button>
        <button class="mat-menu-item" disableRipple type="button" *ngIf="isAllowed('addNew','fill_recommendation')" (click)="openFillRecommendationPage(serviceReqData)" [disabled]="(serviceReqData.status >= 6)" title="Fill Recommendation"> <i class="fa fa-gears"></i>Fill Recommendation</button>
        <button class="mat-menu-item" disableRipple type="button" *ngIf="isAllowed('addNew','fill_recommendation') && getStatusOfSendButton() " (click)="sendServiceRequest(serviceReqData)" title="Send Updated Information to Customer"> <i class="fa far fa-envelope"></i>Send</button>
        <button class="mat-menu-item" disableRipple type="button" *ngIf="isAllowed('views','mark_service_complete') && getStatusOfCompleteButton()" (click)="completeServiceRequest(serviceReqData)" title="Service Completion"> <i class="fa far fa-square-check"></i>Complete</button>
        <button class="mat-menu-item" disableRipple type="button"  *ngIf="isAllowed('views','view_service_history')" (click)="showServiceHistoryPopup(serviceReqData)" title="View History"> 
            <img [src]="gc.SERVICE_HISTORY_ICON" style="width:16px; margin-right:5px;" />  View History
        </button>
        <button class="mat-menu-item" disableRipple type="button" *ngIf="isViewAssignee() && isEmployee && (serviceReqData.status!= 10) " (click)="viewAssignee(serviceReqData.id)" title="View Assignee"> <i class="fa fa-ticket"></i>View Assignee </button>
        <button class="mat-menu-item" disableRipple type="button" *ngIf="isVisible() && isEmployee && (serviceReqData.status!= 10) " (click)="ticketAssignment(serviceReqData.id,serviceReqData)" title="Ticket Assignment"> <i class="fa fa-ticket"></i>Ticket Assignment </button>
        <button class="mat-menu-item" disableRipple *ngIf="isAllowed('views','payment')" (click)="showPaymentDialog(serviceReqData.id)" type="button" title="Payments"> <i class="fa fa-usd"></i> Payments</button>
        <button class="mat-menu-item" disableRipple type="button" *ngIf="isViewAssigneeAllowed()" (click)="openViewActivityLogsPage(serviceReqData.id)" title="View Activity Logs"> <i class="fa fa-eye"></i>View Activity Logs </button>
        <button class="mat-menu-item" disableRipple type="button" *ngIf="isServiceNote" (click)="showSRNoteList(serviceReqData.id)" title="View Notes"> <i class="fa fa-solid fa-pen-to-square"></i>View Notes </button>
        <button class="mat-menu-item" disableRipple type="button" *ngIf="isAllowed('views','complaints')" (click)="ShowAddMessageDialog(serviceReqData)" title="Add Message"> <i class="fa fa-plus"></i>Add Message</button>
        <button class="mat-menu-item" disableRipple type="button" *ngIf="isAllowed('edits', 'reopen_service_ticket') && (serviceReqData.status == 6 || serviceReqData.status == 8) && (serviceReqFormColumn=='invoicedColumn' || serviceReqFormColumn=='paidColumn')" (click)="reOpenServiceRequest(serviceReqData)" title="Reopen Request"><i class="fa fa-refresh"></i>Reopen</button>
        <button class="mat-menu-item" disableRipple type="button" *ngIf="isAllowed('deletes', 'service_request')"  (click)="softDelete()" title="Delete Request"> <i class="fa fa-trash"></i>Delete</button>
      </mat-menu>
    </div>
  </div>
  <div class="row padding-top-5" *ngIf="serviceReqData.title !==''">
    <div class="col-md-12 col-xs-12">
      <span class="f-14">{{serviceReqData.title || 'N/A'}}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-1 col-md-1 icon-section">
      <span class="f-icons" title="Service Schedule Date"><i class="fa fa-regular fa-calendar-days"></i></span>
    </div>
    <div class="col-xs-6 col-md-5 padding-right-0 padding-left-5">
      <div class="margin-bottom-5 margin-top-5">
        <span class="f-14 padding-left-5">{{makeProperDateFormate(serviceReqData.requested_delivery_date,'H')}}</span>
      </div>
    </div>
    <div class="col-xs-4 col-md-6  padding-left-0 duration-section">
      <div class="margin-bottom-5 margin-top-5">
        <span class="f-icons" title="Estimated Time"><i class="fa fa-regular fa-clock"></i></span>
        <span class="f-14 padding-left-5">{{serviceReqData.est_hours}} Hr</span>
      </div>
    </div>
  </div>
  <div class="row mym-section">
    <div class="col-xs-1 col-md-1 icon-section">
      <span class="f-icons" title="Vehicle"><i class="mob-no fa fa-car"
          (click)="openViewCarPopUp(serviceReqData,1)"></i></span>
    </div>
    <div class="col-xs-10 col-md-11  padding-right-0 padding-left-5">
      <div class="margin-0">
        <span class="f-14 padding-left-5" title="{{getVehicleName(serviceReqData) || 'N/A'}}">{{getVehicleName(serviceReqData) || 'N/A'}}</span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-1 col-md-1 icon-section">
      <span class="f-icons" title="Customer Name"><i class="fa fa-user mob-no"
          (click)="openViewCarPopUp(serviceReqData,2)"></i></span>
    </div>
    <div class="col-xs-5 col-md-5 padding-right-0 padding-left-10">
      <div class="margin-bottom-5 margin-top-5">

        <span class="f-14">{{serviceReqData.first_name}} {{serviceReqData.last_name}}</span>
      </div>
    </div>
    <div class="col-xs-5 col-md-6 padding-right-0 mobNo-section">
      <div class="margin-bottom-5 margin-top-5" *ngIf="isAllowed('views','view_customer_info')">
        <span><a class="f-14 mob-no"><span title="Contact Number"><i class="fa fa-phone"></i></span> {{serviceReqData.mobile_phone}}</a></span>
      </div>
    </div>
  </div>
  
  <div class="row list-section" >
    <div class="col-sm-12 srList-menu">
      <ul class="srMenu-lsit" *ngIf="serviceReqData.labels.length>0">
        <li class="list-item selected" *ngFor="let label of serviceReqData.labels">{{label.label_name}} &nbsp;<span>
          <i class="fa fa-times-circle" *ngIf="label.label_id !== 'OTL' && label.label_id !== 'WAITING_FOR_PARTS' &&  label.label_id !== 'ACCOUNTANT_VERIFIED'" (click)="removeLabel(label.label_id)"></i>
          <i class="fa fa-times-circle" *ngIf="label.label_id == 'OTL'" (click)="removeOnTheLotLabel()"></i>
          <i class="fa fa-times-circle" *ngIf="label.label_id == 'WAITING_FOR_PARTS'" (click)="removeWaitingForPartLabel()"></i>
          <i class="fa fa-times-circle" *ngIf="label.label_id == 'ACCOUNTANT_VERIFIED'" (click)="removeAccountantVerifiedLabel()"></i>
        </span></li>
      </ul>
      <button (click)="addLabelsInSR()" class="addlabel-btn" title="Add Label"><i class="fa fa-plus-circle" [ngClass]="(serviceReqData.labels.length !== 0 || serviceReqData.on_the_lot ==1 || serviceReqData.parts_awaited == 1 || serviceReqData.accountant_verification == 1 ) ?'top-margin' : ''"></i>
        <span *ngIf="serviceReqData.labels.length==0 && serviceReqData.on_the_lot == 0 && serviceReqData.parts_awaited == 0 && serviceReqData.accountant_verification == 0"> Add Label</span>
      </button>
    </div>
  </div>
  <div class="row iconBlocks-sections">
    <div class="col-sm-12">
      <ul class="block-list">
        <li class="blocks mob-no" *ngIf="isServiceNote" (click)="showSRNoteList(serviceReqData.id)"><i class="fa fa-edit"></i></li>
        <li class="blocks img-block mob-no" *ngIf="serviceReqData.is_partstech == 1 && isAllowed('views','partstech_order')" (click)="handlePartTechOrder(serviceReqData.id)"><img class="partstech-logo" src="../../../../../assets/img/partstech.svg"></li>
        <li class="blocks img-block mob-no" *ngIf="serviceReqData.is_nexpart == 1 && isAllowed('views','nexpart_order')" (click)="handleNextPartOrder(serviceReqData.id)"><img class="nextpart-logo" src="../../../../../assets/img/nextpart-logo.svg"></li>
        <li class="blocks block-separator" *ngIf="serviceReqData.is_videos == 1 || serviceReqData.is_photos == 1 || serviceReqData.is_documents == 1 || (serviceReqData.accepted_rejected_jobs.accepted > 0 || serviceReqData.accepted_rejected_jobs.rejected > 0)"></li>
        <li class="blocks" *ngIf="serviceReqData.is_videos == 1"><i class="fa fa-brands fa-youtube"></i></li>
        <li class="blocks" *ngIf="serviceReqData.is_photos == 1"><i class="fa fa-image"></i></li>
        <li class="blocks" *ngIf="serviceReqData.is_documents == 1"><i class="fa fa-file-text"></i></li>
        <li class="blocks ar-block" *ngIf="serviceReqData.accepted_rejected_jobs.accepted > 0 || serviceReqData.accepted_rejected_jobs.rejected > 0">
          <span><i class="fa fa-wrench"></i></span>&nbsp;
          <span class="font-bold text-success no"> {{serviceReqData.accepted_rejected_jobs.accepted}}</span>&nbsp;
          <span class="separator">|</span>
          <span class="font-bold text-danger no"> {{serviceReqData.accepted_rejected_jobs.rejected}}</span>
        </li>
      </ul>
    </div>
  </div>
  <div class="row padding-top-5">
    <div class="col-xs-6 col-md-7">
      <div class="notification-text" >
        <div class="t1-class light-pink-color" *ngFor="let employee of serviceReqData.employee_timers" [ngClass]="employee.work_status == 0 ? 'red-border' : ''|| employee.work_status == 1 ? 'yellow-border' : ''|| employee.work_status == 2 ? 'yellow-border' : '' || employee.work_status == 3 ? 'green-border' : ''">
          <span class="text-icon" data-bs-toggle="tooltip" data-bs-placement="right" title="Consumed Time: {{employee.totalHour}}, Allocated Time: {{getEmployeeHoursWorkedValue(employee.allocated_time)}}">{{returnFirstCharcterInName(employee.first_name,employee.last_name)}}</span>
          <div class="check-icon">
            <i class="fa fa-play" aria-hidden="true" *ngIf="employee.work_status == 0"></i>     <!-- assign job not start -->
            <i class="fa fa-regular fa-clock" aria-hidden="true" *ngIf="employee.work_status == 1"></i>     <!-- start job, timer is runing -->
            <i class="fa fa-pause" aria-hidden="true" *ngIf="employee.work_status == 2"></i>     <!-- paused job -->
            <i class="fa fa-check" aria-hidden="true" *ngIf="employee.work_status == 3"></i>       <!-- completed Job -->
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-3 col-md-3 paidUnpaid-section">
      <p class="text-danger" *ngIf="isAllowed('views','display_part_cost')">$ {{serviceReqData.grand_total | number : '1.2-2'}}</p>
    </div>
    <!-- <div class="col-xs-3 col-md-2 padding-right-10 text-center">
      <div class="msg-box">
         <a href="#"><img class="" src="../../../../assets/img/sms-icon-theme.svg" /></a>
      </div>
    </div> -->
  </div>

</div>
<app-tech-assignment [data]="techData" (closeTab)="techAssignmentOut($event)" *ngIf="(techData | json) != '{}'"></app-tech-assignment>