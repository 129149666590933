<div mat-dialog-title class="relative">
  Apply For Leave
  <button mat-mini-fab mat-dialog-close class="close-modal notranslate mat-elevation-z0">
    <mat-icon aria-label="Example icon-button with a heart icon">close</mat-icon>
  </button>
</div>
<mat-dialog-content class="overflow-data">
  <div class="center-block" style="max-width: 830px;">
    <form class="form-horizontal form-control-normal" autocomplete="off" (onSubmit)="onSubmit()" [formGroup]="leave">
      <div class="display-full margin-bottom-5">
        <label class="col-sm-3 control-label"><strong>Title</strong></label>
        <div class="col-sm-9">
          <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
            <input matInput type="text" placeholder="Title" formControlName="title"
              class="form-control input-lg custom-form-input btn-block" />
            <mat-error *ngIf="leave.controls.title.hasError('required') ">
              This field cannot be empty
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="display-full margin-bottom-5 uc-ngx-select">
        <label class="col-sm-3 control-label"><strong>Select Employee</strong></label>
        <div class="col-sm-9 relative">
          <ngx-select [autoClearSearch]="true" class="display-full margin-bottom-20" formControlName="emp_id"
            [multiple]="false" [items]="employes" placeholder="Select Employee" [noAutoComplete]="true"></ngx-select>
          <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
            <mat-error
              *ngIf="(leave.controls.emp_id.touched || leave.controls.emp_id.dirty) && leave.controls.emp_id.hasError('required')">
              This field cannot be empty
            </mat-error>
          </div>
        </div>
      </div>
      <div class="display-full margin-bottom-5">
        <label class="col-sm-3 control-label"><strong>Leave Type</strong></label>
        <div class="col-sm-9 relative uc-ngx-select">
          <ngx-select [autoClearSearch]="true" class="display-full margin-bottom-20" formControlName="leave_type"
            [multiple]="false" [items]="leaveTypes" placeholder="Select Leave Type" [noAutoComplete]="true"></ngx-select>
          <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
            <mat-error
              *ngIf="(leave.controls.leave_type.touched || leave.controls.leave_type.dirty) &&  leave.controls.leave_type.hasError('required')">
              This field cannot be empty
            </mat-error>
          </div>
        </div>
      </div>
      <div class="display-full margin-bottom-5">
        <label class="col-sm-3 control-label"><strong>Leave Notes</strong></label>
        <div class="col-sm-9">
          <mat-form-field class="btn-block car-input leave-note-input" floatPlaceholder="never" appearance="fill">
            <textarea matInput placeholder="Add a leave notes" name="leave_notes" formControlName="leave_notes" rows="3"
              #notes maxlength="500"></textarea>
            <mat-hint align="end">{{notes.value?.length || 0}}/500 Characters Max</mat-hint>
          </mat-form-field>
          <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
            <mat-error
              *ngIf="(leave.controls.leave_notes.touched || leave.controls.leave_notes.dirty) && leave.controls.leave_notes.hasError('required')">
              This field cannot be empty
            </mat-error>
          </div>
        </div>
      </div>
      <div class="display-full margin-bottom-5">
        <label class="col-sm-3 control-label"><strong>Apply For</strong></label>
        <div class="col-sm-9">
          <mat-form-field class="btn-block car-input mat-form-field-apply" floatPlaceholder="never" appearance="fill">
            <mat-select class="form-control input-lg custom-form-input" placeholder="Select Apply For"
              formControlName="leave_duration">
              <mat-option value="">Select Status</mat-option>
              <mat-option value="f">Full Time</mat-option>
              <mat-option value="h">Half Time</mat-option>
            </mat-select>
          </mat-form-field>
          <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
            <mat-error
              *ngIf="(leave.controls.leave_duration.touched || leave.controls.leave_duration.dirty) && leave.controls.leave_duration.hasError('required')">
              This field cannot be empty
            </mat-error>
          </div>
        </div>
      </div>

      <div class="display-full margin-bottom-15">
        <label class="col-sm-3 control-label"><strong>Date</strong></label>
        <div class="col-sm-4">
          <div class="vlio-datepicker relative">
            <dp-date-picker mode="day" placeholder="Start Date" formControlName="start_date" [config]="datePickerConfig"
              theme="dp-material dp-main" class="btn-block" #daySPicker></dp-date-picker>
            <button type="button" mat-icon-button (click)="daySPicker.api.open();">
              <mat-icon>date_range</mat-icon>
            </button>
          </div>
          <mat-error
            *ngIf="(leave.controls.start_date.touched || leave.controls.start_date.dirty) && leave.controls.start_date.hasError('required')">
            This field cannot be empty
          </mat-error>
          <mat-error *ngIf="leave.errors">
            {{ leave.errors?.dates }}
          </mat-error>
        </div>
        <div class="col-sm-4" *ngIf="leave.controls.leave_duration.value=='f'">
          <div class="vlio-datepicker relative">
            <dp-date-picker mode="day" placeholder="End Date" formControlName="end_date" [config]="datePickerConfig"
              theme="dp-material dp-main" class="btn-block" #dayEPicker></dp-date-picker>
            <button type="button" mat-icon-button (click)="dayEPicker.api.open();">
              <mat-icon>date_range</mat-icon>
            </button>
          </div>
          <mat-error
            *ngIf="(leave.controls.end_date.touched || leave.controls.end_date.dirty) &&  leave.controls.end_date.hasError('required')">
            This field cannot be empty
          </mat-error>
        </div>
      </div>

      <div class="display-full margin-bottom-15" *ngIf="leave.controls.leave_duration.value=='h'">
        <label class="col-sm-3 control-label"><strong>Time</strong></label>
        <div class="col-sm-4 uc-ngx-select">
          <ngx-select class="display-full" [items]="timeSlots" formControlName="leave_start_time"
            placeholder="Start Time" optionTextField="text">
          </ngx-select>
          <mat-error
            *ngIf="(leave.controls.leave_start_time.touched || leave.controls.leave_start_time.dirty) && leave.controls.leave_start_time.hasError('required')">
            This field cannot be empty
          </mat-error>
        </div>
        <div class="col-sm-4 uc-ngx-select">
          <ngx-select class="display-full" [items]="timeSlots" formControlName="leave_end_time" placeholder="End Time"
            optionTextField="text">
          </ngx-select>
          <mat-error
            *ngIf="(leave.controls.leave_end_time.touched || leave.controls.leave_end_time.dirty) &&  leave.controls.leave_end_time.hasError('required')">
            This field cannot be empty
          </mat-error>
          <div class="f-error">
            <mat-error *ngIf="error.tError">End time can't before start time.</mat-error>
          </div>
        </div>
      </div>

      <div class="display-full margin-bottom-15">
        <label class="col-sm-3 control-label"><strong>Upload documents</strong></label>
        <div class="col-sm-9 file-upload-container">
          <mat-toolbar class="mat-elevation-z2 margin-bottom-15 upload-btn relative">
            <mat-icon class="margin-right-10">cloud_upload</mat-icon>
            Drag File Here
            <input type="file" id="sattachments" (change)="uploadPicture($event)"  />
            <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
          </mat-toolbar>
          <input id="attachments" name="attachments" value="" type="hidden" formControlName="attachments">
          <p class="help-block" style="margin:0px;">Formats supported : {{docType}}</p>
          <p class="help-block" style="margin:0px;">Maximum size : {{imgSize}}</p>
          <ul class="list-inline upload-image margin-bottom-0">
            <li *ngFor="let doc of docs;let i=index">
              <a href="javascript:void(0);" class="img-set thumbnail relative">
                  <img class="show-image" *ngIf="doc.type==1" src="{{doc.src}}" />
                  <span class="fa far fa-file-pdf fa-4x" *ngIf="doc.type==3"></span>
                  <span class="fa far fa-file-word fa-4x" *ngIf="doc.type==4"></span>
                <i class="fa fa-trash remove-img" (click)="delete(i)"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="inquiry-action relative">
  <div class="text-right flexBtns">
    <button mat-raised-button (click)="onSubmit()" class="font-bold mat-raised-button" color="accent">Submit</button>
    <button mat-raised-button mat-dialog-close class="font-bold">Close</button>
  </div>
</mat-dialog-actions>