<div class="newAppointment-form">
  <form (ngSubmit)="addNewAppt()" [formGroup]="newAppt" method="POST" enctype="multipart/form-data" autocomplete="off">
    <div class="form-group col-sm-6 col-md-6">
      <div class="col-md-5">Service Request Title:</div>
      <div class="col-md-7">
        <mat-form-field class="car-input display-initial" floatPlaceholder="never" appearance="fill" hideRequiredMarker="false">
          <div class="relative">
            <input matInput placeholder="SR Title*" title="SR Title" maxlength="200" formControlName="request_title"
              class="form-control input-lg custom-form-input" />
          </div>
          <mat-error *ngIf="newAppt.controls.request_title.hasError('required')">
            This field cannot be empty
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="form-group col-sm-6 col-md-6">
      <div class="col-md-5">Est. Service Time:</div>
      <div class="col-md-7">
        <mat-form-field class="car-input display-initial" floatPlaceholder="never" appearance="fill" hideRequiredMarker="false">
          <div class="relative">
            <input matInput [placeholder]="estimateServiceTime" title="Estimated Hours" maxlength="6" formControlName="est_hours"
            (keypress)="onlyNumericKey($event)" class="form-control input-lg custom-form-input" />
          </div>
          <mat-error *ngIf="newAppt.controls.est_hours.hasError('required')">
            This field cannot be empty
          </mat-error>
        </mat-form-field>
      </div>
    </div>


    <div class="form-group col-sm-6 col-md-6 datepicker-section">
      <div class="col-md-5">Requested Date:</div>
      <div class="col-md-7">
        <div class="vlio-datepicker relative">
          <dp-date-picker mode="daytime" [config]="datePickerConfig" [disabled]="false" theme="dp-material dp-main" class="btn-block"
            formControlName="request_date" #dayPicker></dp-date-picker>
          <button type="button" [disabled]="false" mat-icon-button (click)="dayPicker.api.open();">
            <mat-icon>date_range</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div class="form-group col-sm-6 col-md-6">
      <div class="col-md-5">Present Mileage:</div>
      <div class="col-md-7">
        <mat-form-field class="car-input display-initial" floatPlaceholder="never" appearance="fill" hideRequiredMarker="false">
          <div class="relative">
            <input matInput placeholder="{{distCovered}}" title="Distance Covered"  maxlength="10" formControlName="distance_covered"
            (keypress)="onlyNumericKey($event)" class="form-control input-lg custom-form-input" type="text" />
          </div>
          <mat-error *ngIf="newAppt.controls.distance_covered.hasError('required')">
            This field cannot be empty
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="form-group col-sm-12 col-md-12">
      <div class="col-md-5">Request Notes:</div>
      <div class="col-md-12 details-input">
        <mat-form-field class="car-input btn-block notes-input" floatPlaceholder="never" appearance="fill">
          <textarea matInput placeholder="Request Notes" title="Request notes" rows="5" maxlength="1000"
            formControlName="request_notes"></textarea>
        </mat-form-field>
        <p *ngIf="currentShop.profile_type == '1'" class="tax-note text-red">Customer can see anything you write here
        <p>
      </div>
    </div>

    <div class="form-group col-sm-12 col-md-12">
      <div class="col-sm-12">
        <div class="btn-wrap pull-right">
          <button type="button" class="font-bold mat-elevation-z0 mat-raised-button mat-accent" (click)="addNewAppt(1)"
            title="Submit">Create Appt</button>
          <button type="button" class="font-bold mat-elevation-z0 mat-raised-button mat-accent" *ngIf="isAllowed('addNew','fill_recommendation')" (click)="addNewAppt(2)"
          title="Submit">Create Appt & goto SR</button>
        </div>
      </div>
    </div>
  </form>
</div>