import {Component, OnInit, ViewEncapsulation,ChangeDetectorRef,OnDestroy} from '@angular/core';
import {FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import {Router, ActivatedRoute, ParamMap, NavigationEnd, Params} from '@angular/router';
import {DpDatePickerModule} from 'ng2-date-picker';
import {Http, Headers,RequestOptions, Response} from '@angular/http';
import {HttpHeaders} from '@angular/common/http';
import {globalFunctions} from "../../../../environments/globalFunctions"
import {globalConstants} from "../../../../environments/globalConstants"
import {lang} from "../../../../environments/lang-en"
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {TextMaskModule} from 'angular2-text-mask';
import {GlobalService} from "../../../../environments/global.service"
import {AppComponent} from "../../../app.component"
import {IDatePickerConfig} from 'ng2-date-picker';
import {DatePipe} from '@angular/common';
import {NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { MatDialog } from '@angular/material/dialog';

const EMAIL_REGEX = globalConstants.EMAIL_REGEX;
import { ShopcarvinscanComponent } from '../shopcarvinscan/shopcarvinscan.component';
import { NgxSelectModule, INgxSelectOptions } from 'ngx-select-ex';
import { ShopcarLicensePlateComponent } from '../shopcarlicenseplate/shopcarlicenseplate.component';
import { ExtrafieldsComponent } from '../extrafields/extrafields.component';
import { ExtrafieldListComponent } from '../extrafield-list/extrafield-list.component';
import { SearchcarComponent } from '../searchcar/searchcar.component';

export class AppDateAdapter extends NativeDateAdapter {
   parse(value: any): Date | null {
    if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
      const str = value.split('/');
      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);
      return new Date(year, month, date);
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

   format(date: Date, displayFormat: Object): string {
       if (displayFormat == "input") {
           let day = date.getDate();
           let month = date.getMonth() + 1;
           let year = date.getFullYear();
           return this._to2digit(month)+ '/' + this._to2digit(day)  + '/' + year;
       } else {
           return date.toDateString();
       }
   }

   private _to2digit(n: number) {
       return ('00' + n).slice(-2);
   }
}

export const APP_DATE_FORMATS =
{
   parse: {
       dateInput: {month: 'short', year: 'numeric', day: 'numeric'}
   },
   display: {
       // dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
       dateInput: 'input',
       monthYearLabel: { month: 'short', year: 'numeric', day: 'numeric' },
       dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
       monthYearA11yLabel: {year: 'numeric', month: 'long'},
   }
}

@Component({
  selector: 'app-addcar',
  templateUrl: './addcar.component.html',
  styleUrls: ['./addcar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    DatePipe,
    {
      provide: DateAdapter,
      useClass: AppDateAdapter,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS,
    },
  ],
})
export class AddcarComponent implements OnInit, OnDestroy {
  public currentCustomer;
  public currentCustomerName;
  public currentCustomerVehicles;
  public carMakeList;
  public isCollapsed: boolean = true;
  public car: FormGroup;
  public customerEdit: FormGroup;
  public customerServiceCenter: FormGroup;
  public customerRoadsideAssistance: FormGroup;
  public customerInsurance: FormGroup;
  public customerWarranty: FormGroup;
  public carMakes;
  public carModels: any = [];
  public years: any = [];
  public shopDetail: any;
  public loginUserDetails: any;
  public gc = globalConstants;
  public request;
  public dateMask = [
    /[1-9]/,
    /[1-9]/,
    /[1-9]/,
    /[1-9]/,
    '/',
    /[1-9]/,
    /[1-9]/,
    '/',
    /[1-9]/,
    /[1-9]/,
  ];
  public base64value = '';
  public selectedKey = '';
  public EnterNewMakeButton = true;
  public EnterNewYearButton = true;
  public EnterNewModelButton = true;
  public UseExistingMakeButton = false;
  public UseExistingYearButton = false;
  public UseExistingModelButton = false;
  public EnterNewMake = false;
  public EnterNewYear = false;
  public EnterNewModel = false;
  public UseExisitngMake = true;
  public UseExistingYear = true;
  public UseExisitngModel = true;
  public showSubscriptionMessage = false;
  public states;
  public countries: any = [];
  public sendRequest = false;
  public addNewColor = true;
  public phoneMask = [
    /[1-9]/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
  public NumOnly = [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
  mobHeight: any;
  mobWidth: any;
  public newVIN = '';
  public vinError: boolean = false;
  public shopCustomerID;
  public shopCustomerDetails;
  public idFromUrl;
  public disabledYear;
  public disabledModel;
  public userDetails;
  public userCountryID;
  public addVinConfirmation: any;
  public imgPath =
    globalConstants.S3_BUCKET_URL +
    globalConstants.VEHICLE_PICTURES +
    globalConstants.IMG_THUMB;
  public vehicle_registered_pic = '';
  public vehicle_insured_pic = '';
  public vehicle_pic = '';
  public vehicle_vin_pic = '';
  public vehicle_video = '';
  public getAddCarParams;
  public customerCountryName;
  public customerCountryID;
  datePickerConfig: IDatePickerConfig = {
    format: 'MM/YYYY',
    closeOnSelect: true,
  };
  public VinObj;
  public carMakeData;
  public carModelYearData;
  public carProductionDateData;
  public carBodyStyleData;
  public carModelData;
  public carEngineCylindersData;
  public carEngineDisplacementData;
  public carModalNameData;
  public scannedVinNumber;
  public carTrimData;
  public sendParams: any;
  public displayCountryName;
  public FORMAT_SUPPORTED_FOR_IMAGE =
    globalConstants.FORMAT_SUPPORTED_FOR_IMAGE;
  public FORMAT_SUPPORTED_FOR_VIDEO =
    globalConstants.FORMAT_SUPPORTED_FOR_VIDEO;
  public MAX_ALLOWED_IMAGE_SIZE = globalConstants.MAX_ALLOWED_IMAGE_SIZE;
  public MAX_ALLOWED_IMAGE_DISPLAYED_TEXT =
    globalConstants.MAX_ALLOWED_IMAGE_DISPLAYED_TEXT;
  public MAX_ALLOWED_VIDEO_SIZE = globalConstants.MAX_ALLOWED_VIDEO_SIZE;
  public MAX_ALLOWED_VIDEO_DISPLAYED_TEXT =
    globalConstants.MAX_ALLOWED_VIDEO_DISPLAYED_TEXT;

  public statesArray = [''];
  public vstates = [''];
  public stateBlank;
  public disableStates;
  public colorsArray = [''];
  public carColors = [
    {
       id:'',
       text :'Select Color'
    },
    {
      id: 'White',
      text: 'White/White Pearl',
    },
    {
      id: 'Black',
      text: 'Black',
    },
    {
      id: 'Silver',
      text: 'Silver',
    },
    {
      id: 'Red',
      text: 'Red',
    },
    {
      id: 'Gray',
      text: 'Gray',
    },
    {
      id: 'Blue',
      text: 'Blue',
    },
    {
      id: 'Brown',
      text: 'Beige/Brown',
    },
    {
      id: 'Green',
      text: 'Green',
    },
    {
      id: 'Yellow',
      text: 'Yellow/Gold',
    },
  ];

  public disableYears;
  public disableModels;
  public yearsValue;
  public makesValue;
  public modelsValue;
  public vehicle_registered_pics = [];
  public vehicle_videos = [];
  public vehicle_pics = [];
  public vehicle_insured_pics = [];
  public isMultipleFile = true;
  public validCar = false;
  public defaultState;
  public shopState;
  public shopCountry;
  public shopCountryId;
  public licensePlateData;
  public licensePlate;
  public customerStateName;
  public wheelLock = [];
  public antWheellocklocation = true;
  public customFieldList = [];
  public otherLabelsTemps = [];
  public vehicleByVin;
  public vehicleByLicence;
  public subscriptionDataForAPI: Subscription = new Subscription();
  public labourRatesType: any;
  public selectedYear : any = '';
  public selectedModel: any = '';

  constructor(
    private ref: ChangeDetectorRef,
    public appComponent: AppComponent,
    private dateAdapter: DateAdapter<Date>,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public titleService: Title,
    private http: Http,
    private router: Router,
    public globalService: GlobalService,
    public dialog: MatDialog
  ) {
    if (localStorage.getItem('shop_state')) {
      this.shopState = localStorage.getItem('shop_state');
    }
    if (localStorage.getItem('shop_country_id')) {
      this.shopCountryId = localStorage.getItem('shop_country_id');
    }
    if (localStorage.getItem('shop_country')) {
      this.shopCountry = localStorage.getItem('shop_country');
    }
    if (localStorage.getItem('loggedInUser')) {
      this.currentCustomer = JSON.parse(localStorage.getItem('loggedInUser'));
      this.getAddCarParams = localStorage.getItem('shopCustomerID');
      var splitParamsValues = this.getAddCarParams.split(',');
      this.shopCustomerID = splitParamsValues[0];
      this.customerCountryID = splitParamsValues[1];
      this.customerStateName = splitParamsValues[2];
      this.getVehicleCount();
      this.getCustomerVehicles();
      //this.makeDropDown(carModelInfo);
      this.disabledModel = true;
      this.disabledYear = true;
      this.disableYears = true;
      this.disableModels = true;
      //this.getSliderData();
      this.getFormBuilderData();
      //if shop countryId not found then to find countryId
      if (
        this.shopCountryId == '' ||
        this.shopCountryId == null ||
        this.shopCountryId == 'null' ||
        this.shopCountryId == undefined
      ) {
        this.displayCountryName = true;
        this.subscriptionDataForAPI = this.globalService
          .callAPI(globalConstants.API_GET_COUNTRIES_LIST, 'opr=1')
          .subscribe((data: any) => {
            this.countries = data.data;
            /* FOR V2 SELECT */
            if (this.customerStateName || this.customerCountryID) {
              let shopCountry = this.countries.filter(
                (country) => country.country_id == this.customerCountryID
              );
              if (shopCountry.length > 0) {
                this.shopCountryId = shopCountry[0].country_id;
                this.getStates();
              }
            } else {
              let shopCountry = this.countries.filter(
                (country) => country.country_name == 'USA'
              );
              this.shopCountryId = shopCountry[0].country_id;
              this.getStates();
            }
            /* END FOR V2 SELECT */
          });
        //this.car.get('State').setValidators(null);
        //this.car.get('State').updateValueAndValidity();
      } else {
        this.getStates();
      }

      var colorList = [];
      for (var i in this.carColors) {
        this.carColors[i].id = this.carColors[i].id;
        this.carColors[i].text = this.carColors[i].text;
        colorList.push(this.carColors[i]);
        this.colorsArray[this.carColors[i].id] = this.carColors[i].text;
      }
      this.carColors = colorList;

      /* VIN SCAN FUNCTIONALITY */
      if (
        localStorage.getItem('VinValues') != '' &&
        localStorage.getItem('VinValues') != undefined
      ) {
        this.VinObj = JSON.parse(localStorage.getItem('VinValues'));
        //console.log("All Values", this.VinObj);
        this.scannedVinNumber = localStorage.getItem('VinNumber');
        this.EnterNewMake = true;
        this.EnterNewYear = true;
        this.EnterNewModel = true;

        this.UseExisitngMake = false;
        this.UseExistingYear = false;
        this.UseExisitngModel = false;

        this.EnterNewMakeButton = false; //hide Existing Make
        this.UseExistingMakeButton = true; //show Another
        this.EnterNewYearButton = false; //show Existing Year
        this.UseExistingYearButton = false; //Hide Another
        this.EnterNewModelButton = false; //show Existing Model
        this.UseExistingModelButton = false; //Hide Another

        var obj = [];
        this.setVinData();
        var trim = '';
        trim = this.carTrimData ? ' ' + this.carTrimData : '';
        if (this.carModelData && this.carBodyStyleData) {
          this.carModalNameData =
            this.carModelData + ' ' + trim + ' ' + this.carBodyStyleData;
        }

        if (
          this.carModelYearData != '' &&
          typeof this.carModelYearData != 'undefined'
        ) {
          this.carProductionDateData = '01/' + this.carModelYearData;
          //this.car.controls.production_date.setValue(this.carProductionDateData);
        } else {
          //this.car.controls.production_date.setValue('');
          this.carProductionDateData = '';
        }
        localStorage.removeItem('VinValues');
        localStorage.removeItem('VinNumber');
      }

      /* License Plate FUNCTIONALITY */
      if (
        localStorage.getItem('licensePlateData') != '' &&
        localStorage.getItem('licensePlateData') != undefined
      ) {
        let self = this;
        this.licensePlateData = JSON.parse(
          localStorage.getItem('licensePlateData')
        );
        this.scannedVinNumber = this.licensePlateData.vin;
        this.EnterNewMake = true;
        this.EnterNewYear = true;
        this.EnterNewModel = true;

        this.UseExisitngMake = false;
        this.UseExistingYear = false;
        this.UseExisitngModel = false;

        this.EnterNewMakeButton = false; //hide Existing Make
        this.UseExistingMakeButton = true; //show Another
        this.EnterNewYearButton = false; //show Existing Year
        this.UseExistingYearButton = false; //Hide Another
        this.EnterNewModelButton = false; //show Existing Model
        this.UseExistingModelButton = false; //Hide Another

        Object.entries(this.licensePlateData).forEach(([key, val]) => {
          if (key == 'body') {
            this.carBodyStyleData = val;
          } else if (key == 'license_plate') {
            this.licensePlate = val;
            this.car.patchValue({ license_plate: val });
          } else if (key == 'make') {
            this.carMakeData = val;
            this.car.patchValue({ make: val });
          } else if (key == 'model') {
            this.carModelData = val;
            this.car.patchValue({ model: val });
          } else if (key == 'vin') {
            this.car.patchValue({ vin: val });
          } else if (key == 'year') {
            this.carModelYearData = val;
            this.car.patchValue({ year: val });
          } else if (key == 'engine_size') {
            this.carEngineDisplacementData = val;
            this.car.patchValue({ sub_model: val });
          } else if (key == 'state_id') {
            this.defaultState = val;
          } else if (key == 'state_name') {
            this.car.patchValue({ State: val });
          } else if (key == 'wheel_base') {
            this.car.patchValue({ wheel_base: val });
          } else if (key == 'tire_size') {
            this.car.patchValue({ tire_size: val });
          } else if (key == 'driveline') {
            this.car.patchValue({ driveline: val });
          }
        });
        var trim = '';
        trim = this.carTrimData ? ' ' + this.carTrimData : '';
        if (this.carModelData && this.carBodyStyleData) {
          this.carModalNameData =
            this.carModelData + ' ' + trim + ' ' + this.carBodyStyleData;
        }

        if (
          this.carModelYearData != '' &&
          typeof this.carModelYearData != 'undefined'
        ) {
          this.carProductionDateData = '01/' + this.carModelYearData;
          this.car.controls.production_date.setValue(
            this.carProductionDateData
          );
        } else {
          this.car.controls.production_date.setValue('');
          this.carProductionDateData = '';
        }
      }

      /* END */
      if (this.router.url == '/shops/add-car') {
        this.subscriptionDataForAPI = this.globalService
          .callAPI(globalConstants.API_GET_CAR_MAKES, 'opr=1')
          .subscribe((data: any) => {
            this.carMakeList = globalConstants.carMakeDDLPlaceHolder.concat(data.data);
            /* FOR SEARCH BOX DROPDOWN */
            for (var i in this.carMakeList) {
              this.carMakeList[i].id = this.carMakeList[i].make;
              this.carMakeList[i].text = this.carMakeList[i].make;
            }
          });
      }
    } else {
      this.router.navigate(['/shops']);
    }
    this.isMultipleFile = this.globalService.isMultifileAllowed();
    this.globalService.setMetaData('SHOPS', 'ADD_NEW_CAR');
  }
  isCustFieldAllowed() {
    return this.globalService.getAcl('custom_vehicle_fields', 'addNew');
  }

  getFormBuilderData() {
    this.car = this.formBuilder.group({
      vin: [''],
      production_date: [''],
      year: ['', [Validators.minLength(4), Validators.pattern('^[12][0-9]{3}$')]],
      make: [''],
      model: [''],
      sel_make: [''],
      sel_year: [''],
      sel_model: [''],
      sub_model: [''],
      mileage: ['', [Validators.pattern('^[0-9]*$')]],
      license_plate: [''],
      color: [''],
      select_color: [''],
      unit_number: [''],
      driver: [''],
      driver_phone_number: [''],
      distance_covered: ['', [Validators.pattern(/^\d+(\.\d)?$/)]],
      vehicle_registered_pic: [''],
      vehicle_insured_pic: [''],
      vehicle_vin_pic: [''],
      vehicle_pic: [''],
      vehicle_video: [''],
      //registration_expiration_date: [''],
      registration_expiration_date_frm: [''],
      //insurance_expiration_date: [''],
      insurance_expiration_date_frm: [''],
      vin_photo: [''],
      State: [''],
      user_type: [this.currentCustomer.user_type],
      user_id: [this.currentCustomer.id],
      customer_id: [this.shopCustomerID],
      old_customer_id: [''],
      usps_vehicle: [''],
      form_usps_vehicle: [''],
      is_make: [1],
      is_year: [1],
      is_model: [1],
      is_color: [1],
      is_license: [1],
      inspection_expiration_date_frm: [''],
      inspection_expiration_date: [''],
      california_emmissions: [''],
      transmission: [''],
      brake: [''],
      driveline: [''],
      gvwr: [''],
      wheel_base: [''],
      tire_size: [''],
      wheel_lock_location: [''],
      custom_fields: [''],
      other_labels_temp: [''],
      customer_type_id: [''],
      standard_mileage: ['', [Validators.pattern(/^\d+(\.\d)?$/)]],
      standard_days: ['', [Validators.pattern(/^\d*$/)]],
      // vehicle_video_temp:['']
    });
  }
  autoFillProductionDate() {
    if (this.car.value.year != '' && this.car.value.year.length == 4) {
      let makeDate = '01' + '/' + this.car.value.year;
      this.car.controls.production_date.setValue(makeDate);
    }
  }

  getStates() {
    this.displayCountryName = false;
    let country = this.customerStateName
      ? this.customerCountryID
      : this.shopCountryId;
    var statesOpr = 'country_id=' + country + '&opr=1';
    let stateDDLPlaceholder =[{
      country_id: "",
      id: "",
      latitude: "",
      longitude: "",
      state_code: "",
      state_id: "",
      state_name: " Select State",
      tax_rate: ""
    }]
    this.subscriptionDataForAPI = this.globalService
      .callAPI(globalConstants.API_GET_STATES_URL, statesOpr)
      .subscribe((data: any) => {
        this.states = stateDDLPlaceholder.concat(data.data);
        for (var i in this.states) {
          this.states[i].id = this.states[i].state_id;
          this.states[i].text = this.states[i].state_name;
          this.statesArray[this.states[i].state_id] = this.states[i].state_name;
        }

        if (
          localStorage.getItem('licensePlateData') == undefined ||
          !localStorage.getItem('licensePlateData')
        ) {
          // if(this.customerStateName){
          //     const carState = this.states.filter(state => state.state_name == this.customerStateName);
          //     if(carState.length > 0) {
          //         this.defaultState = carState[0].state_id;
          //         this.car.controls.State.patchValue( this.shopState);
          //     }
          // }else{
          //     const carState = this.states.filter(state => state.state_name ==  this.shopState);
          //     if(carState.length > 0) {
          //         this.defaultState = carState[0].state_id;
          //         this.car.controls.State.patchValue( this.shopState);
          //     } else {
          //         this.car.controls.State.setValue('');
          //     }
          // }
          this.setSelectedOptionState();
        } else {
          localStorage.removeItem('licensePlateData');
        }
      });
  }

  /* STATES LIST IN SEARCH BOX DROP DOWN */
  getSelectedColor(opt) {
    if(opt !== ''){
      this.car.controls.select_color.setValue(this.colorsArray[opt]);
      this.car.controls.color.setValue(opt);
    }else{
      this.car.controls.select_color.setValue('');
      this.car.controls.color.setValue('');
    }
    // this.globalService.validateState(this.car, ['select_color']);
    this.checkIfValid();
  }

  /*colorValidation() {
        this.globalService.validateState(this.car, ['select_color']);
        this.checkIfValid();
    }*/
  /* CHANGE MAKE */
  getSelectedOptionMakes(opt) {
    if (opt !== 'Select Make') {
      this.disabledModel = true;
      this.disabledYear = true;
      this.makesValue = opt;
      this.car.controls.sel_make.setValue(opt);
      this.car.controls.make.setValue(opt);
      this.car.controls.sel_year.setValue('');
      this.car.controls.year.setValue('');
      this.car.controls.sel_model.setValue('');
      this.car.controls.model.setValue('');
      this.selectedYear = 'Select Year';
      this.selectedModel = 'Select Model';
      this.getYears(opt);
      this.getModelList('');
    } else {
      this.disabledModel = true;
      this.disabledYear = true;
      this.makesValue = opt;
      this.car.controls.sel_make.setValue('');
      this.car.controls.make.setValue('');
      this.car.controls.sel_year.setValue('');
      this.car.controls.year.setValue('');
      this.car.controls.sel_model.setValue('');
      this.car.controls.model.setValue('');
      this.selectedYear = 'Select Year';
      this.selectedModel = 'Select Model';
    }

    this.checkIfValid();
  }
  /* CHANGE YEARS */
  getSelectedOptionYears(opt) {
    if (opt !== 'Select Year') {
      this.disabledModel = false;
      this.car.controls.sel_model.setValue('');
      this.car.controls.model.setValue('');
      this.car.controls.sel_year.setValue(opt);
      // this.yearsValue = opt; //extra code 
      this.car.controls.year.setValue(opt);
      this.selectedModel = 'Select Model';
      this.getModelList(opt);
      let makeDate = '01' + '/' + opt;
      this.car.controls.production_date.setValue(makeDate);
          this.checkVinValidity();
    } else {
      this.disabledModel = true;
      this.car.controls.sel_model.setValue('');
      this.car.controls.model.setValue('');
      this.car.controls.sel_year.setValue('');
      // this.yearsValue = opt; // extra code 
      this.car.controls.year.setValue('');
      this.selectedModel = 'Select Model';
      this.car.controls.production_date.setValue('');
    }
    this.checkIfValid();
  }

  /* CHANGE MODELS */
  getSelectedOptionModels(opt) {
    if (opt !== 'Select Model') {
      this.modelsValue = opt;
      this.car.controls.sel_model.setValue(opt);
      this.car.controls.model.setValue(opt);
    } else {
      this.modelsValue = '';
      this.car.controls.sel_model.setValue('');
      this.car.controls.model.setValue('');
    }
    // this.globalService.validateState(this.car, ['sel_model']);
    this.checkIfValid();
  }

  /* STATES LIST IN SEARCH BOX DROP DOWN */
  getSelectedOptionState(opt) {
    if(opt !== ''){
    this.car.controls.State.setValue(this.statesArray[opt]);
    // this.globalService.validateState(this.car, ['State']);
    this.checkIfValid();
    this.checkLicence();
    }else{
      this.car.controls.State.setValue('');
      this.checkIfValid();
    }
  }

  stateValidation() {
    // let validationFields = [];
    // if(this.addNewColor){
    //     validationFields.push('select_color');
    // }else{
    //     validationFields.push('color');
    // }
    // if(this.displayCountryName == true) {
    // this.globalService.validateState(this.car, validationFields);
    // } else {
    // validationFields.push('State');
    // this.globalService.validateState(this.car, validationFields);
    // }
    this.checkIfValid();
  }

  stateValidationWithVinScan() {
    let colorControl = this.addNewColor ? ['select_color'] : ['color'];
    // if(this.displayCountryName == true) {
    //     this.globalService.validateState(this.car, colorControl);
    // } else {
    //     colorControl.push('State');
    //     this.globalService.validateState(this.car, colorControl);
    // }
    this.checkIfValid();
  }

  getCustomerVehicles() {
    var content = 'customer_id=' + this.shopCustomerID;
    this.currentCustomerVehicles = [];
    this.request = this.globalService
      .callAPI(globalConstants.API_GET_CUSTOMER_VEHICLES_URL, content)
      .subscribe((data: any) => {
        var response = data;
        if (response.result == 1) {
          if (response.data.length == undefined) {
            this.currentCustomerVehicles[0] = response.data;
          } else {
            this.currentCustomerVehicles = response.data;
          }
        } else {
          this.currentCustomerVehicles = [];
        }
      });
  }

  ngOnInit() {
  /**
   * Retrieve the logged-in user's details from local storage, construct a payload, and call the API to get shop details. 
   * Assign the returned shop details to the 'shopDetail' property.
   */
    this.loginUserDetails = JSON.parse(localStorage.getItem("loggedInUser"));
    var payload = "user_id=" + this.loginUserDetails.shop_id + "&user_type=" + this.loginUserDetails.user_type + "&shop_id=" + this.loginUserDetails.shop_id;
    this.globalService.callAPI(globalConstants.API_SHOP_DETAILS, payload).subscribe((data: any) => {
      this.shopDetail = data.data;
    });

    this.wheelLock = globalFunctions.wheelLock();
    this.getFormBuilderData();
    if (this.getCurrentRoute() == '/shops/add_cars') {
      //this.titleService.setTitle(lang.TITLE_CUSTOMER_ADD_CAR);
    } else if (this.getCurrentRoute() == '/shops/list_cars') {
      //this.titleService.setTitle(lang.TITLE_CUSTOMER_LIST_CAR);
    } else {
      //this.titleService.setTitle(lang.TITLE_CUSTOMER_DASHBOARD);
    }
    this.mobWidth = window.innerWidth;
    this.autoFillProductionDate();
    this.getCustomFields();
    this.getShopCustomerDetails();
    this.getLabourType();
  }

  getLabourType() {
    var currentCustomer: any = JSON.parse(localStorage.getItem('loggedInUser'));
    var stringifiedFormData =
      'user_type=' +
      currentCustomer.user_type +
      '&user_id=' +
      currentCustomer.id +
      '&shop_id=' +
      currentCustomer.shop_id;
    this.globalService
      .callAPI(globalConstants.API_GET_CUSTOMER_TYPES, stringifiedFormData)
      .subscribe((data) => {
        this.labourRatesType = data.data;
        this.labourRatesType.unshift({
          id: '0',
          type: 'Please Select Labor Rate',
        });
      });
  }

  setOptionOfLabourType(opt) {
    this.car.controls.customer_type_id.setValue(opt);
  }

  onlyNumericKey(event) {
    return globalFunctions.onlyDecimalNumberKey(event);
  }

  getCurrentRoute() {
    return this.router.url;
  }

  public isCountryValid() {
    if (this.customerCountryID) {
      return this.globalService.checkValidCountry(this.customerCountryID);
    } else {
      return false;
    }
  }

  checkIfValid() {
    let x = [
      'sel_make',
      'sel_year',
      'sel_model',
      'select_color',
      'color',
      'license_plate',
      'State',
    ];
    this.validCar = false;
    let lp = this.globalService.isFleet();
    if (this.EnterNewMake || this.EnterNewYear || this.EnterNewModel) {
      this.validCar = true;
    }
    for (const key in this.car.controls) {
      if (x.indexOf(key) != -1) {
        this.car.get(key).clearValidators();
        this.car.get(key).setErrors(null);
        this.car
          .get(key)
          .updateValueAndValidity({ onlySelf: false, emitEvent: false });
      }
    }
    if (this.validCar) {
      this.car.get('select_color').setValidators(null);
      this.car.get('color').setValidators(null);
      this.car.get('license_plate').setValidators(null);
      this.car.get('State').setValidators(null);
    } else {
      this.car.get('color').setValidators([Validators.required]);
      this.car.get('select_color').setValidators([Validators.required]);
      this.car.get('license_plate').setValidators([Validators.required]);
      this.car.get('State').setValidators([Validators.required]);
      this.car.get('license_plate').markAsTouched();
    }
    if (this.EnterNewMake) {
      this.car.get('sel_make').setValidators(null);
    } else {
      !this.validCar &&
        this.car.get('sel_make').setValidators([Validators.required]);
    }
    if (this.EnterNewYear) {
      this.car.get('sel_year').setValidators(null);
    } else {
      !this.validCar &&
        this.car.get('sel_year').setValidators([Validators.required]);
    }
    if (this.EnterNewModel) {
      this.car.get('sel_model').setValidators(null);
    } else {
      !this.validCar &&
        this.car.get('sel_model').setValidators([Validators.required]);
    }

    if (!this.validCar) {
      if (this.addNewColor) {
        this.car.get('select_color').setValidators([Validators.required]);
        this.car.get('color').setValidators(null);
      } else {
        this.car.get('select_color').setValidators(null);
        this.car.get('color').setValidators([Validators.required]);
      }
      if (this.displayCountryName == true) {
        this.car.get('State').setValidators(null);
      } else {
        this.car.get('State').setValidators([Validators.required]);
      }
    }

    if (lp) {
      this.car.get('license_plate').setValidators(null);
    }
    for (const key in this.car.controls) {
      if (x.indexOf(key) != -1) {
        this.car.get(key).updateValueAndValidity();
      }
    }
    this.car.controls.is_make.setValue(
      this.EnterNewMake || this.validCar ? '0' : '1'
    );
    this.car.controls.is_year.setValue(
      this.EnterNewYear || this.validCar ? '0' : '1'
    );
    this.car.controls.is_model.setValue(
      this.EnterNewModel || this.validCar ? '0' : '1'
    );
    this.car.controls.is_color.setValue(
      this.EnterNewMake || this.validCar ? '0' : '1'
    );
    this.car.controls.is_license.setValue(
      this.EnterNewMake || this.validCar ? '0' : '1'
    );
    if (
      this.car.value.production_date == '' ||
      typeof this.car.value.production_date == 'undefined'
    ) {
      this.car.controls.production_date.setValue('');
    }
  }

  /* Home Phone Validity Check */
  checkHomePhoneValidity() {
    Window['errorObj'] = this.car;
    let home_phone = this.car.value.driver_phone_number;
    if (home_phone != '') {
      let phoneResult =
        this.car.value.driver_phone_number.replace(/\D+/g, '').length >= 10
          ? false
          : { minLength: true };
      if (phoneResult == false) {
        this.car.controls.driver_phone_number.setErrors(null);
      } else {
        this.car.controls.driver_phone_number.setErrors({ phoneError: true });
      }
    } else {
      this.car.controls.driver_phone_number.setErrors(null);
    }
  }

  dateFormatWithoutTime(dateValue) {
    return (
      new Date(dateValue).getFullYear() +
      '-' +
      (new Date(dateValue).getMonth() + 1 < 10 ? '0' : '') +
      (new Date(dateValue).getMonth() + 1) +
      '-' +
      (new Date(dateValue).getDate() < 10 ? '0' : '') +
      new Date(dateValue).getDate()
    );
  }

  AddCar(opr = 1) {
    //console.log("FORM VALUE", this.car.value);
    if (
      localStorage.getItem('VinValues') != '' &&
      localStorage.getItem('VinValues') != undefined
    ) {
      this.stateValidationWithVinScan();
    } else {
      this.stateValidation();
    }
    if (this.car.valid) {
      var insExpiryDate;
      if (
        this.car.value.insurance_expiration_date_frm != undefined &&
        this.car.value.insurance_expiration_date_frm != ''
      ) {
        insExpiryDate = this.dateFormatWithoutTime(
          this.car.value.insurance_expiration_date_frm
        );
      } else {
        insExpiryDate = '';
      }

      var regExpiryDate;
      if (
        this.car.value.registration_expiration_date_frm != undefined &&
        this.car.value.registration_expiration_date_frm != ''
      ) {
        regExpiryDate = this.dateFormatWithoutTime(
          this.car.value.registration_expiration_date_frm
        );
      } else {
        regExpiryDate = '';
      }
      let inspeExpiryDate: any = '';
      if (
        this.car.value.inspection_expiration_date_frm != undefined &&
        this.car.value.inspection_expiration_date_frm != ''
      ) {
        inspeExpiryDate =
          new Date(
            this.car.value.inspection_expiration_date_frm
          ).getFullYear() +
          '-' +
          (new Date(this.car.value.inspection_expiration_date_frm).getMonth() +
            1 <
          10
            ? '0'
            : '') +
          (new Date(this.car.value.inspection_expiration_date_frm).getMonth() +
            1) +
          '-' +
          (new Date(this.car.value.inspection_expiration_date_frm).getDate() <
          10
            ? '0'
            : '') +
          new Date(this.car.value.inspection_expiration_date_frm).getDate();
        this.car.controls.inspection_expiration_date.setValue(inspeExpiryDate);
      }

      if (this.car.value.production_date == undefined) {
        this.car.controls.production_date.setValue('');
      }
      if (
        this.car.value.sub_model == undefined ||
        this.car.value.sub_model == '' ||
        this.car.value.sub_model == 'undefined'
      ) {
        this.car.controls.sub_model.setValue('');
      }

      var formData = new FormData();
      for (var i in this.car.value) {
        if (typeof this.car.value[i] == 'undefined') {
          formData.append(i, '');
        } else {
          formData.append(i, this.car.value[i]);
        }
      }
      if (this.vehicle_videos.length > 0) {
        for (var i in this.vehicle_videos) {
          formData.append('vehicle_video_temp[]', this.vehicle_videos[i].name);
        }
      }
      if (this.vehicle_insured_pics.length > 0) {
        for (var i in this.vehicle_insured_pics) {
          formData.append(
            'vehicle_insured_pic_temp[]',
            this.vehicle_insured_pics[i].name
          );
        }
      }
      if (this.vehicle_pics.length > 0) {
        for (var i in this.vehicle_pics) {
          formData.append('vehicle_pic_temp[]', this.vehicle_pics[i].name);
        }
      }
      if (this.vehicle_registered_pics.length > 0) {
        for (var i in this.vehicle_registered_pics) {
          formData.append(
            'vehicle_registered_pic_temp[]',
            this.vehicle_registered_pics[i].name
          );
        }
      }
      if (this.otherLabelsTemps.length > 0) {
        for (var i in this.otherLabelsTemps) {
          formData.append('other_labels_temp[]', this.otherLabelsTemps[i].name);
        }
      }
      formData.append('insurance_expiration_date', insExpiryDate);
      formData.append('registration_expiration_date', regExpiryDate);
      // formData.append('inspection_expiration_date', inspeExpiryDate)
      let custFields = [];
      if (this.customFieldList.length) {
        for (let n in this.customFieldList) {
          let cf = this.customFieldList[n];
          let t: any = { id: cf.id, value: cf.value };
          custFields.push(t);
        }
        formData.append('custom_fields', JSON.stringify(custFields));
      }
      //var stringifiedFormData = globalFunctions.stringifyFormData(this.car.value, null);
      if (this.car.value.vin != '' || this.validCar) {
        this.car.controls.vin.setErrors(null);
        this.submitAddCar(formData, opr);
        //}
      } else {
        this.globalService
          .confirmDialog(
            1,
            'You have not entered the VIN Number. You will not be able to create a service request without it. Do you still want to continue without entering it?'
          )
          .subscribe((res) => {
            this.addVinConfirmation = res;
            if (this.addVinConfirmation == 1) {
              this.submitAddCar(formData, opr);
            }
          });
      }
    } else {
      // this.submitted = true;
      /*if (this.car.controls.vin.hasError && this.car.value.vin != "") {
                this.globalService.snackbar('error', "Vin number issued after 1981 should be of 17 digits max");
            } else {*/
      this.globalService.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
      //}
    }
  }

  isString(value) {
    return typeof value == 'string';
  }

  trim(value) {
    return this.isString(value)
      ? value.replace(/^\s*/, '').replace(/\s*$/, '')
      : value;
  }

  getVehicleCount() {
    this.request = this.globalService
      .callAPI(
        globalConstants.API_GET_VEHICLE_COUNT_URL,
        'customer_id=' + this.shopCustomerID
      )
      .subscribe((data: any) => {
        var response = data;
        if (response.code == 148 || response.code == 147) {
          this.showSubscriptionMessage = true;
        }
      });
  }

  getYears(event) {
    this.carModels = [];
    if (event == undefined || event == '') {
      this.years = [];
      this.car.controls.year.reset();
      this.car.controls.year.disable();
      this.car.controls.model.reset();
      this.car.controls.model.disable();
      this.disabledYear = true;
      this.disabledModel = true;
    } else {
      this.car.controls.year.reset();
      this.car.controls.year.enable();
      this.years = [];
      //this.globalService.callAPI(globalConstants.API_GET_YEAR_LIST, "opr=1&make="+this.car.value.make).subscribe((data) => {
      this.subscriptionDataForAPI = this.globalService
        .callAPI(globalConstants.API_GET_YEAR_LIST, 'opr=1&make=' + event)
        .subscribe((data: any) => {
          if (data.result == '1') {
            this.disabledYear = false;
            if (data.data.length != undefined) {
              this.years = [];
              this.years = globalConstants.carYearDDLPlaceHolder.concat(data.data);
              /* FOR SEARCH BOX DROPDOWN SELECT */
              for (var i in this.years) {
                this.years[i].id = this.years[i].year;
                this.years[i].text = this.years[i].year;
              }
              /* END FOR V2 SELECT */
            } else {
              this.years = [];
              this.years[0] = globalConstants.carYearDDLPlaceHolder.concat(data.data);
              /* FOR SEARCH BOX DROPDOWN SELECT */
              this.years.id = this.years.year;
              this.years.text = this.years.year;
            }
          } else {
            this.car.controls.year.disable();
            this.disabledYear = true;
          }
        });
    }
  }

  getModelList(event) {
    if (event == undefined || event == '') {
      this.disabledModel = true;
      this.disableYears = true;
      this.carModels = [
        {
          body_style: "",
          id: "Select Model",
          make: "",
          model: "Select Model",
          text: "Select Model",
          trim: "",
          year: ""
        }
      ];
      this.car.controls.model.reset();
      // this.car.controls.model.disable();
    } else {
      this.car.controls.model.reset();
      this.car.controls.model.enable();
      this.subscriptionDataForAPI = this.globalService
        .callAPI(
          globalConstants.API_GET_MODELS_LIST_URL,
          'opr=1&make=' + this.makesValue + '&year=' + event
        )
        .subscribe((data: any) => {
          if (data.result == '1') {
            this.disabledModel = false;
            if (data.data.length == undefined) {
              this.carModels = [];
              this.carModels[0] = globalConstants.carModelDDLPlaceholder.concat(data.data);
              /* FOR SEARCH BOX DROPDOWN SELECT */
              this.carModels.id =
                this.carModels.model +
                (this.carModels.trim ? ' ' + this.carModels.trim : '') +
                (this.carModels.body_style
                  ? ' ' + this.carModels.body_style
                  : '');
              this.carModels.text =
                this.carModels.model +
                (this.carModels.trim ? ' ' + this.carModels.trim : '') +
                (this.carModels.body_style
                  ? ' ' + this.carModels.body_style
                  : '');
              /* END FOR V2 SELECT */
            } else {
              this.carModels = [];
              this.carModels = globalConstants.carModelDDLPlaceholder.concat(data.data);
              /* FOR SEARCH BOX DROPDOWN SELECT */
              for (var i in this.carModels) {
                this.carModels[i].id =
                  this.carModels[i].model +
                  (this.carModels[i].trim ? ' ' + this.carModels[i].trim : '') +
                  (this.carModels[i].body_style
                    ? ' ' + this.carModels[i].body_style
                    : '');
                this.carModels[i].text =
                  this.carModels[i].model +
                  (this.carModels[i].trim ? ' ' + this.carModels[i].trim : '') +
                  (this.carModels[i].body_style
                    ? ' ' + this.carModels[i].body_style
                    : '');
                //this.statesArray[this.years[i].state_id]=(this.years[i].state_name);
              }
              /* END FOR V2 SELECT */
            }
          } else {
            this.disabledModel = true;
            this.disableModels = true;
          }
        });
    }
  }

  getFile(evt, key) {
    this.selectedKey = key;
    var files = evt.target.files;
    var file = files[0];
    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64value = btoa(binaryString);
    if (this.selectedKey == 'vin_photo') {
      this.car.value.vin_photo = this.base64value;
    } else if (this.selectedKey == 'vehicle_registered_pic') {
      this.car.value.vehicle_registered_pic = this.base64value;
    } else if (this.selectedKey == 'vehicle_insured_pic') {
      this.car.value.vehicle_insured_pic = this.base64value;
    } else if (this.selectedKey == 'vehicle_pic') {
      this.car.value.vehicle_pic = this.base64value;
    } else if (this.selectedKey == 'vehicle_video') {
      this.car.value.vehicle_video = this.base64value;
    } else if (this.selectedKey == 'vehicle_vin_pic') {
      this.car.value.vin_photo = this.base64value;
    }
  }

  getShopCustomerDetails() {
    var opr =
      'user_type=' +
      this.currentCustomer.user_type +
      '&user_id=' +
      this.currentCustomer.id +
      '&customer_id=' +
      this.shopCustomerID;
    this.subscriptionDataForAPI = this.globalService
      .callAPI(globalConstants.API_GET_CUSTOMER_DETAILS, opr)
      .subscribe((data: any) => {
        this.shopCustomerDetails = data.data;
      });
  }

  checkVIN() {
    let vin = this.car.controls.vin.value;
    if (vin && vin.length >= 17) {
      let state = this.car.controls.State.value;
      let opr =
        'user_type=' +
        this.currentCustomer.user_type +
        '&user_id=' +
        this.currentCustomer.id +
        '&vin=' +
        vin +
        '&state=' +
        state +
        '&customer_id=' +
        this.shopCustomerID +
        '&partstech_check=1';
      this.subscriptionDataForAPI = this.globalService
        .callAPI(globalConstants.API_CHECK_VEHICLE_INFO, opr)
        .subscribe((data: any) => {
          if (data.result == '1') {
            this.vehicleByVin = data.data;
            if (this.vehicleByVin && 'vehicle_info' in this.vehicleByVin) {
              this.searchCarDialog(this.vehicleByVin, 'vin');
            } else {
              //TODO we will do things here
              localStorage.setItem(
                'VinValues',
                JSON.stringify(this.vehicleByVin)
              );
              localStorage.setItem('VinNumber', vin);
              this.fillVinData(this.vehicleByVin);
            }
          } else {
            if (data.code == 528) {
              this.globalService
                .confirmDialog('confirm', data.message)
                .subscribe((res: any) => {
                  if (res != null) {
                    this.car.patchValue({ vin: '' });
                  }
                });
            } else {
              this.globalService.snackbar('error', data.message);
            }
          }
        });
    }
  }

  checkLicence() {
    let license_plate = this.car.controls.license_plate.value;
    if (license_plate) {
      let stateName = this.car.controls.State.value;
      let opr =
        'user_type=' +
        this.currentCustomer.user_type +
        '&user_id=' +
        this.currentCustomer.id +
        '&license_plate=' +
        license_plate +
        '&state_name=' +
        stateName +
        '&customer_id=' +
        this.shopCustomerID;
      let carState = this.states.filter(
        (state) => state.state_name == stateName
      );
      if (carState.length > 0) {
        opr = opr + '&state=' + carState[0].state_code + '&partstech_check=1';
      } else {
        opr = opr + '&state=&partstech_check=1';
      }
      this.subscriptionDataForAPI = this.globalService
        .callAPI(globalConstants.API_CHECK_VEHICLE_INFO, opr)
        .subscribe((data: any) => {
          if (data.result == '1') {
            this.vehicleByLicence = data.data;
            if (
              'code' in data &&
              data.code == '638' &&
              'vehicle_info' in data
            ) {
              this.searchCarDialog({ vehicle_info: data.vehicle_info }, 'vin');
            } else if (
              this.vehicleByLicence &&
              'vehicle_info' in this.vehicleByLicence
            ) {
              this.searchCarDialog(this.vehicleByLicence, 'licence');
            } else {
              localStorage.setItem(
                'licensePlateData',
                JSON.stringify(this.vehicleByLicence)
              );
              this.fillLicecencePlateData(this.vehicleByLicence);
            }
          } else {
            if (data.code == 528) {
              this.globalService
                .confirmDialog('confirm', data.message)
                .subscribe((res) => {
                  if (res != null) {
                    this.car.patchValue({ license_plate: '' });
                  }
                });
            } else {
              this.globalService.snackbar('error', data.message);
            }
          }
        });
    }
  }

  fillVinData(result) {
    if (
      localStorage.getItem('VinValues') != '' &&
      localStorage.getItem('VinValues') != undefined
    ) {
      this.VinObj = JSON.parse(localStorage.getItem('VinValues'));
      this.scannedVinNumber = localStorage.getItem('VinNumber');
      this.EnterNewMake = true;
      this.EnterNewYear = true;
      this.EnterNewModel = true;

      this.UseExisitngMake = false;
      this.UseExistingYear = false;
      this.UseExisitngModel = false;

      /* this.EnterNewMakeButton = false;
            this.EnterNewYearButton = false;
            this.EnterNewModelButton = false;

            this.UseExistingMakeButton = true;
            this.UseExistingYearButton = true;
            this.UseExistingModelButton = true; */

      this.EnterNewMakeButton = false; //hide Existing Make
      this.UseExistingMakeButton = true; //show Another
      this.EnterNewYearButton = false; //show Existing Year
      this.UseExistingYearButton = false; //Hide Another
      this.EnterNewModelButton = false; //show Existing Model
      this.UseExistingModelButton = false; //Hide Another

      this.licensePlate = '';
      this.car.patchValue({ license_plate: '' });
      this.setSelectedOptionState();
      /*if(this.customerStateName){
                const carState = this.states.filter(state => state.state_name == this.customerStateName);
                if(carState.length > 0) {
                    this.defaultState = carState[0].state_id;
                    this.car.controls.State.patchValue( this.shopState);
                } 
            }else{
                const carState = this.states.filter(state => state.state_name ==  this.shopState);
                if(carState.length > 0) {
                    this.defaultState = carState[0].state_id;
                    this.car.controls.State.patchValue( this.shopState);
                } else {
                    this.car.controls.State.setValue('');
                }
            }*/
      var obj = [];
      this.setVinData();
      var trim = '';
      trim = this.carTrimData ? ' ' + this.carTrimData : '';
      if (this.carModelData && this.carBodyStyleData) {
        this.carModalNameData =
          this.carModelData + ' ' + trim + ' ' + this.carBodyStyleData;
      }

      if (
        this.carModelYearData != '' &&
        typeof this.carModelYearData != 'undefined'
      ) {
        this.carProductionDateData = '01/' + this.carModelYearData;
        this.car.controls.production_date.setValue(this.carProductionDateData);
      } else {
        this.car.controls.production_date.setValue('');
        this.carProductionDateData = '';
      }
      localStorage.removeItem('VinValues');
      localStorage.removeItem('VinNumber');
    }
  }

  fillLicecencePlateData(result) {
    if (
      (localStorage.getItem('licensePlateData') != '' &&
        localStorage.getItem('licensePlateData') != undefined) ||
      result
    ) {
      if (result) {
        this.licensePlateData = result;
      } else {
        this.licensePlateData = localStorage.getItem('licensePlateData');
      }
      this.scannedVinNumber = this.licensePlateData.vin;
      this.EnterNewMake = true;
      this.EnterNewYear = true;
      this.EnterNewModel = true;

      this.UseExisitngMake = false;
      this.UseExistingYear = false;
      this.UseExisitngModel = false;

      this.EnterNewMakeButton = false; //hide Existing Make
      this.UseExistingMakeButton = true; //show Another
      this.EnterNewYearButton = false; //show Existing Year
      this.UseExistingYearButton = false; //Hide Another
      this.EnterNewModelButton = false; //show Existing Model
      this.UseExistingModelButton = false; //Hide Another
      localStorage.removeItem('state');
      Object.entries(this.licensePlateData).forEach(([key, val]) => {
        if (key == 'body') {
          this.carBodyStyleData = val;
        } else if (key == 'license_plate') {
          this.licensePlate = val;
          this.car.patchValue({ license_plate: val });
        } else if (key == 'make') {
          this.carMakeData = val;
          this.car.patchValue({ make: val });
        } else if (key == 'model') {
          this.carModelData = val;
          this.car.patchValue({ model: val });
        } else if (key == 'vin') {
          this.car.patchValue({ vin: val });
        } else if (key == 'year') {
          this.carModelYearData = val;
          this.car.patchValue({ year: val });
        } else if (key == 'engine_size') {
          this.carEngineDisplacementData = val;
          this.car.patchValue({ sub_model: val });
        } else if (key == 'state_id') {
          this.defaultState = val;
        } else if (key == 'state_name') {
          this.car.patchValue({ State: val });
        } else if (key == 'wheel_base') {
          this.car.patchValue({ wheel_base: val });
        } else if (key == 'tire_size') {
          this.car.patchValue({ tire_size: val });
        } else if (key == 'driveline') {
          this.car.patchValue({ driveline: val });
        }
      });
      var trim = '';
      trim = this.carTrimData ? ' ' + this.carTrimData : '';
      if (this.carModelData && this.carBodyStyleData) {
        this.carModalNameData =
          this.carModelData + ' ' + trim + ' ' + this.carBodyStyleData;
      }

      if (
        this.carModelYearData != '' &&
        typeof this.carModelYearData != 'undefined'
      ) {
        this.carProductionDateData = '01/' + this.carModelYearData;
        this.car.controls.production_date.setValue(this.carProductionDateData);
      } else {
        this.car.controls.production_date.setValue('');
        this.carProductionDateData = '';
      }
      localStorage.removeItem('licensePlateData');
    }
  }

  searchCarDialog(customerVehcileInfo, searchBy) {
    let status = 1;
    if (this.shopCustomerID == customerVehcileInfo.vehicle_info.customer_id) {
      status = 1;
    } else if (
      this.shopCustomerID != customerVehcileInfo.vehicle_info.customer_id
    ) {
      status = 2;
    }
    if (this.shopCustomerDetails.is_sublet == '1') {
      status = 3;
    }
    const dialogRef = this.dialog.open(SearchcarComponent, {
      panelClass: ['car-dialog-form'],
      data: {
        status: status,
        customerVehcileInfo: customerVehcileInfo,
        shopCustomerDetails: this.shopCustomerDetails,
        currentCustomer: this.currentCustomer,
        searchBy: searchBy,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('Pop up Closed', result);
    });
  }

  enterNewMake() {
    this.EnterNewMake = true;
    this.EnterNewYear = true;
    this.EnterNewModel = true;

    if (this.EnterNewMakeButton == true) {
      this.EnterNewMakeButton = false; //show Existing Make
      this.UseExistingMakeButton = true; //Hide Another
      this.EnterNewYearButton = false; //Hide Existing Year
      this.UseExistingYearButton = false; //Hide Another
      this.EnterNewModelButton = false; //Hide Existing Model
      this.UseExistingModelButton = false; //Hide Another
    } else {
      this.EnterNewMakeButton = true; //hide Existing Make
      this.UseExistingMakeButton = false; //show Another
      this.EnterNewYearButton = true; //show Existing Year
      this.UseExistingYearButton = false; //Hide Another
      this.EnterNewModelButton = true; //show Existing Model
      this.UseExistingModelButton = false; //Hide Another

    }
    if (this.disabledYear == false || this.disabledModel == false) {
      this.disabledYear = true;
      this.disabledModel = true;
    } else {
      this.disabledYear = false;
      this.disabledModel = false;
    }
    this.checkIfValid();
  }
  useExitingMake() {
    if (
      localStorage.getItem('VinValues') != '' &&
      localStorage.getItem('VinValues') != undefined
    ) {
      this.getYears(this.carMakeData);
    }

    if (this.makesValue != '') {
      this.disabledYear = true;
    }
    if (this.modelsValue != '') {
      this.disabledModel = true;
    }

    this.EnterNewMake = false;
    this.EnterNewYear = false;
    this.EnterNewModel = false;
    this.car.controls.year.reset();
    this.EnterNewMakeButton = true; //hide Existing Make
    this.UseExistingMakeButton = false; //show Another
    this.EnterNewYearButton = true; //show Existing Year
    this.UseExistingYearButton = false; //Hide Another
    this.EnterNewModelButton = true; //show Existing Model
    this.UseExistingModelButton = false; //Hide Another
    this.checkIfValid();
  }
  enterNewYear() {
    this.EnterNewYear = true;
    this.EnterNewModel = true;
    this.EnterNewYearButton = false; //show Existing Year
    this.UseExistingYearButton = true; //Hide Another
    this.EnterNewModelButton = false; //show Existing Model
    this.UseExistingModelButton = false; //Hide Another
    this.checkIfValid();
  }
  useExitingYear() {
    this.EnterNewYear = false;
    this.EnterNewModel = false;
    this.EnterNewYearButton = true; //show Existing Year
    this.UseExistingYearButton = false; //Hide Another
    this.EnterNewModelButton = true; //show Existing Model
    this.UseExistingModelButton = false; //Hide Another
    this.car.controls.model.reset();
    this.checkIfValid();
  }
  enterNewModel() {
    this.EnterNewModel = true;
    this.EnterNewModelButton = false; //show Existing Model
    this.UseExistingModelButton = true; //Hide Another
    this.checkIfValid();
  }
  useExitingModel() {
    this.EnterNewModel = false;
    this.UseExistingModelButton = false;
    this.EnterNewModelButton = true;
    this.car.controls.model.reset();
    this.checkIfValid();
  }

  enableAddNewColor() {
    this.addNewColor = true;
  }

  disableAddNewColor() {
    this.addNewColor = false;
  }

  deleteCar(car_id) {
    this.subscriptionDataForAPI = this.globalService
      .callAPI(
        globalConstants.API_DELETE_CUSTOMER_VEHICLE_URL,
        'customer_id=' + this.currentCustomer.id + '&vehicle_id=' + car_id
      )
      .subscribe((data) => {
        this.globalService.snackbar('error', data['message']);
        this.getCustomerVehicles();
      });
  }

  checkVinValidity() {
    var year_selected = this.car.value.year;
    var vin_number: string = this.car.value.vin;
    if (vin_number != '' && vin_number != undefined) {
      if (year_selected > 1981 && !this.EnterNewYear) {
        if (vin_number.length >= 17) {
          let rs = vin_number.substr(0, 17);
          // console.log('if'+ rs);
          this.car.controls['vin'].setValue(rs);
          this.car.controls.vin.setErrors(null);
        } else {
          // console.log('else');
          this.car.controls.vin.setErrors({ YearError: true });
        }
      } else {
        this.car.controls.vin.setErrors(null);
      }
      //            if (year_selected > 1981 && vin_number.length < 17 && !this.EnterNewYear) {
      //                this.car.controls.vin.setErrors({"YearError": true});
      //            } else {
      //               this.car.controls.vin.setErrors(null);
      //            }
    }
  }
  onInput(obj) {
    obj.targetElement.value = this.dateAdapter.toIso8601(
      new Date(obj.target.value)
    );
  }

  viewCar(carId) {
    this.router.navigate(['/customer/view_car', carId]);
  }

  transferOwnship(id) {
    setTimeout(() =>
      this.router.navigate(['/customer/transfer-ownership/', id])
    );
  }

  makeNewRequest(carId) {
    setTimeout(() => this.router.navigate(['/customer/new_request/', carId]));
  }

  initTransfer() {
    this.subscriptionDataForAPI = this.globalService
      .callAPI(
        globalConstants.API_TRANSFER_OWNERSHIP_URL,
        'customer_id=' + this.currentCustomer.id + '&vin=' + this.car.value.vin
      )
      .subscribe((data: any) => {
        this.globalService.snackbar('success', data.message);
      });
  }

  setTouchViewValue() {
    return this.appComponent.touchViewState;
  }

  changeListner(event, rendering_tag_id) {
    var html = '';
    var base64image = '';
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
      let valid = true;
      if (rendering_tag_id == 'vehicle_video') {
        valid = this.globalService.validateFiles('video', event.target.files);
      } else {
        valid = this.globalService.validateFiles('image', event.target.files);
      }
      if (valid == false) {
        return false;
      }
      //if (file.type == "image/png" || file.type == "image/jpeg" || file.type == 'video/mp4' || file.type == "video/quicktime") {
      if (
        rendering_tag_id == 'vehicle_video'
          ? file.size < this.MAX_ALLOWED_VIDEO_SIZE
          : file.size < this.MAX_ALLOWED_IMAGE_SIZE
      ) {
        var reader = new FileReader();
        var base64value = '';
        let base64valuec;
        reader.onload = (e) => {
          base64valuec = e.target['result'];
          if (rendering_tag_id == 'vehicle_video') {
            if (file.type == 'video/mp4' || file.type == 'video/quicktime') {
              this.vehicle_video = 'added';
              this.car.controls.vehicle_video.setValue(file);
              this.ref.detectChanges();
            } else {
              this.globalService.snackbar(
                'error',
                'Please upload only mp4 & quicktime format of video.'
              );
              let ele = <HTMLInputElement>(
                document.getElementById('add' + rendering_tag_id)
              );
              ele.value = '';
              this.car.controls.vehicle_video.setValue('');
            }
          } else if (rendering_tag_id == 'vehicle_registered_pic') {
            if (
              file.type == 'image/png' ||
              file.type == 'image/jpeg' ||
              file.type == 'image/jpg'
            ) {
              this.vehicle_registered_pic = base64valuec;
              this.car.controls.vehicle_registered_pic.setValue(file);
              this.ref.detectChanges();
            } else {
              let ele = <HTMLInputElement>(
                document.getElementById('add' + rendering_tag_id)
              );
              ele.value = '';
              this.vehicle_registered_pic = '';
              this.car.controls.vehicle_registered_pic.setValue('');
              this.globalService.snackbar(
                'error',
                'Please upload only png, jpg & jpeg format of image.'
              );
            }
          } else if (rendering_tag_id == 'vehicle_insured_pic') {
            if (
              file.type == 'image/png' ||
              file.type == 'image/jpeg' ||
              file.type == 'image/jpg'
            ) {
              this.vehicle_insured_pic = base64valuec;
              this.car.controls.vehicle_insured_pic.setValue(file);
              this.ref.detectChanges();
            } else {
              let ele = <HTMLInputElement>(
                document.getElementById('add' + rendering_tag_id)
              );
              ele.value = '';
              this.vehicle_insured_pic = '';
              this.car.controls.vehicle_insured_pic.setValue('');
              this.globalService.snackbar(
                'error',
                'Please upload only png, jpg & jpeg format of image.'
              );
            }
          } else if (rendering_tag_id == 'vehicle_pic') {
            if (
              file.type == 'image/png' ||
              file.type == 'image/jpeg' ||
              file.type == 'image/jpg'
            ) {
              this.vehicle_pic = base64valuec;
              this.car.controls.vehicle_pic.setValue(file);
              this.ref.detectChanges();
            } else {
              let ele = <HTMLInputElement>(
                document.getElementById('add' + rendering_tag_id)
              );
              ele.value = '';
              this.vehicle_pic = '';
              this.car.controls.vehicle_pic.setValue('');
              this.globalService.snackbar(
                'error',
                'Please upload only png, jpg & jpeg format of image.'
              );
            }
          } else if (rendering_tag_id == 'vehicle_vin_pic') {
            if (
              file.type == 'image/png' ||
              file.type == 'image/jpeg' ||
              file.type == 'image/jpg'
            ) {
              this.vehicle_vin_pic = base64valuec;
              this.car.controls.vehicle_vin_pic.setValue(file);
              this.ref.detectChanges();
            } else {
              let ele = <HTMLInputElement>(
                document.getElementById('add' + rendering_tag_id)
              );
              ele.value = '';
              this.vehicle_vin_pic = '';
              this.car.controls.vehicle_vin_pic.setValue('');
              this.globalService.snackbar(
                'error',
                'Please upload only png, jpg & jpeg format of image.'
              );
            }
          }
        };
        reader.readAsDataURL(event.target.files[0]);
      } else {
        let ele = <HTMLInputElement>(
          document.getElementById('add' + rendering_tag_id)
        );
        ele.value = '';
        if (rendering_tag_id == 'vehicle_video') {
          this.globalService.snackbar(
            'error',
            'Video should be less then ' +
              this.MAX_ALLOWED_VIDEO_DISPLAYED_TEXT +
              '.'
          );
        } else {
          this.globalService.snackbar(
            'error',
            'Image should be less then ' +
              this.MAX_ALLOWED_IMAGE_DISPLAYED_TEXT +
              '.'
          );
        }
      }
    }
  }
  deleteImg(type, index) {
    let ele = <HTMLInputElement>document.getElementById('add' + type);
    if (ele) {
      ele.value = '';
    }
    let base64valuec = '';
    if (type == 'vehicle_video') {
      this.vehicle_videos.splice(index, 1);
      this.car.controls.vehicle_video.setValue('');
    } else if (type == 'vehicle_registered_pic') {
      this.vehicle_registered_pics.splice(index, 1);
      this.car.controls.vehicle_registered_pic.setValue('');
    } else if (type == 'vehicle_insured_pic') {
      this.vehicle_insured_pics.splice(index, 1);
      this.car.controls.vehicle_insured_pic.setValue('');
    } else if (type == 'vehicle_pic') {
      this.vehicle_pics.splice(index, 1);
      this.car.controls.vehicle_pic.setValue('');
    } else if (type == 'vehicle_vin_pic') {
      this.vehicle_vin_pic = base64valuec;
      this.car.controls.vehicle_vin_pic.setValue('');
    } else if (type == 'other_labels_temp') {
      this.otherLabelsTemps.splice(index, 1);
    }
  }

  /*
    getCountryIDByName(id) {
        var name = ""
        console.log("ID--",id)
        for (var i = 0; i < this.countries.length; i++) {
            if (id == this.countries[i]["country_name"]) {
                name = this.countries[i]["country_id"];
                break;
            }
        }
        return name;
    } */

  checkUSPS(event) {
    if (event.checked) {
      this.car.controls.form_usps_vehicle.setValue(1);
      this.car.controls.usps_vehicle.setValue('on');
    } else {
      this.car.controls.form_usps_vehicle.setValue(0);
      this.car.controls.usps_vehicle.setValue('off');
    }
  }

  /* VIN SCAN POPUP */
  scanVINDialog(sendParams, oprs): void {
    let dialogRef = this.dialog.open(ShopcarvinscanComponent, {
      panelClass: ['car-dialog-form'],
      // position: {top: '50px'},
      width: '800px',
      data: { send_params: sendParams, oprs: oprs },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.fillVinData(result);
    });
  }

  /* Licence Plate POPUP */
  public openLicensePlateDialog(sendParams, oprs): void {
    localStorage.removeItem('licensePlateData');
    const dialogRef = this.dialog.open(ShopcarLicensePlateComponent, {
      panelClass: ['car-dialog-form', 'ticket-dialog'],
      // position: {top: '50px'},
      width: '800px',
      data: { send_params: sendParams, oprs: oprs },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.fillLicecencePlateData(result);
    });
  }

  /* END */
  goTo(obj, picker) {
    if (typeof obj == 'undefined') {
      obj = picker.inputElementValue;
    }
    if (obj != '') {
      picker.api.moveCalendarTo(obj);
    }
  }
  addMultipleImg(event, rendering_tag_id) {
    let multi = true;
    let valid = true;
    if (!this.isMultipleFile) {
      multi = false;
    }
    let ele = <HTMLInputElement>(
      document.getElementById('add' + rendering_tag_id)
    );
    if (rendering_tag_id == 'vehicle_registered_pic') {
      if (!multi && this.vehicle_registered_pics.length != 0) {
        valid = false;
      }
    } else if (rendering_tag_id == 'vehicle_video') {
      if (!multi && this.vehicle_videos.length != 0) {
        valid = false;
      }
    } else if (rendering_tag_id == 'vehicle_pic') {
      if (!multi && this.vehicle_pics.length != 0) {
        valid = false;
      }
    } else if (rendering_tag_id == 'vehicle_insured_pic') {
      if (!multi && this.vehicle_insured_pics.length != 0) {
        valid = false;
      }
    } else if (rendering_tag_id == 'other_labels_temp') {
      if (!multi && this.otherLabelsTemps.length != 0) {
        valid = false;
      }
    }
    if (valid == false) {
      this.globalService.snackbar(
        'error',
        'Update your subscription to upload multiple files.'
      );
      if (ele) {
        ele.value = '';
      }
      return false;
    }
    let cFiles = { src: '', type: 0, name: '' };
    if (event.target.files && event.target.files[0]) {
      if (rendering_tag_id == 'vehicle_video') {
        valid = this.globalService.validateFiles('video', event.target.files);
      } else {
        valid = this.globalService.validateFiles('image', event.target.files);
      }
      if (valid == false) {
        if (ele) {
          ele.value = '';
        }
        event.target.value = '';
        return false;
      }
      let file = event.target.files[0];
      if (valid) {
        if (file.size) {
          let data = new FormData();
          let url = globalConstants.API_NEW_SERVICE_REQUEST_FILE;
          data.append('customer_id', this.shopCustomerID);
          if (rendering_tag_id == 'vehicle_video') {
            data.append('video', file);
          } else {
            data.append('image', file);
          }

          let FileType = this.globalService.getFileIcon(file);
          cFiles.type = FileType;
          if (rendering_tag_id != 'vehicle_video') {
            let reader = new FileReader();
            reader.onload = (e) => {
              cFiles.src = <string>e.target['result'];
            };
            reader.readAsDataURL(event.target.files[0]);
          }

          this.globalService.formData(url, data).subscribe((response: any) => {
            let resData = response;
            if (resData.result == 1) {
              this.checkFileUploadLimit(cFiles, resData, rendering_tag_id);

              event.target.value = '';
            } else {
              if (ele) {
                ele.value = '';
              }
              this.globalService.snackbar('error', resData.message);
            }
          });
        } else {
          if (ele) {
            ele.value = '';
          }
          this.globalService.snackbar('error', 'Please upload a valid file.');
        }
      } else {
        if (ele) {
          ele.value = '';
        }
        this.globalService.snackbar('error', 'Please upload a valid file.');
      }
      event.target.value = '';
    }
  }
  checkFileUploadLimit(cFiles, resData, rendering_tag_id) {
    let multi = true;
    if (!this.isMultipleFile) {
      multi = false;
    }
    if (rendering_tag_id == 'vehicle_registered_pic') {
      cFiles.name = resData.data.name;
      if (!multi) {
        this.vehicle_registered_pics = [];
      }
      this.vehicle_registered_pics.push(cFiles);
    } else if (rendering_tag_id == 'vehicle_video') {
      cFiles.name = resData.data.name;
      if (!multi) {
        this.vehicle_videos = [];
      }
      this.vehicle_videos.push(cFiles);
    } else if (rendering_tag_id == 'vehicle_pic') {
      cFiles.name = resData.data.name;
      if (!multi) {
        this.vehicle_pics = [];
      }
      this.vehicle_pics.push(cFiles);
    } else if (rendering_tag_id == 'vehicle_insured_pic') {
      cFiles.name = resData.data.name;
      if (!multi) {
        this.vehicle_insured_pics = [];
      }
      this.vehicle_insured_pics.push(cFiles);
    } else if (rendering_tag_id == 'other_labels_temp') {
      cFiles.name = resData.data.name;
      if (!multi) {
        this.otherLabelsTemps = [];
      }
      this.otherLabelsTemps.push(cFiles);
    }
  }
  submitAddCar(formData, opr) {
    this.globalService
      .formData(globalConstants.API_ADD_VEHICLE_URL, formData)
      .subscribe(
        (data) => {
          var message = data.message;
          if (data.result == '1') {
            if (opr == 1) {
              localStorage.setItem('listCustomerID', this.shopCustomerID);
              localStorage.removeItem('licensePlateData');
              this.router.navigate(['shops/list-cars']);
              this.globalService.snackbar('success', message);
            } else {
              this.router.navigate([
                'shops/new-service-request/' +
                  this.shopCustomerID +
                  '/' +
                  data.data.id,
              ]);
            }
          } else {
            this.globalService.snackbar('error', message);
            if (data.code == '311' || data.code == '310') {
              this.checkVIN();
              // this.globalService.confirmDialog(311, "The VIN you trying is already owned by another user. Do you want to send him a request to transfer its ownership to you?").subscribe((data) => {
              //     if (data == null) {
              //         // console.log("No")
              //     } else {
              //         // console.log("Yes");
              //         this.newVIN = this.car.value.vin
              //         this.sendRequest = true;
              //     }
              // });
            } else if (data.code == '312' || data.code == '313') {
              this.checkLicence();
            }
          }
          this.getVehicleCount();
          this.getCustomerVehicles();
        },
        (error) => {}
      );
  }

  // set state if customer set state value otherwise set shop profile state value.
  public setSelectedOptionState() {
    let StateName = '';
    var content = 'customer_id=' + this.shopCustomerID;
    this.subscriptionDataForAPI = this.globalService
      .callAPI(globalConstants.API_GET_CUSTOMER_DETAILS, content)
      .subscribe((data: any) => {
        StateName = data.data.state;
        if (this.shopState && StateName == '') {
          StateName = this.shopState;
        }
        if (StateName) {
          const carState = this.states.filter(
            (state) => state.state_name == StateName
          );
          if (carState.length > 0) {
            this.defaultState = carState[0].state_id;
            this.car.patchValue({ State: carState[0].state_name });
          } else {
            this.defaultState = '';
            this.car.controls.State.setValue('');
          }
        }
      });
  }
  getLockLocation(opt) {
    this.checkIfValid();
  }
  californiaEmmissions(t) {
    if (t.checked) {
      this.car.controls.california_emmissions.setValue(1);
    } else {
      this.car.controls.california_emmissions.setValue(0);
    }
    this.checkIfValid();
  }
  /** add custom fields */
  addMoreFields() {
    let dialogRef = this.dialog.open(ExtrafieldsComponent, {
      panelClass: ['car-dialog-form', 'extrafields-dialog-form'],
      width: '800px',
      data: {},
    });
    dialogRef.afterClosed().subscribe((r: any) => {
      if (r && typeof r.id != 'undefined') {
        r.value = '';
        this.customFieldList.push(r);
      }
    });
  }
  chooseCustmField(v, i) {
    this.customFieldList[i].value = v;
  }
  getCustomFields() {
    let data = {
      user_type: this.currentCustomer.user_type,
      user_id: this.currentCustomer.id,
    };
    this.subscriptionDataForAPI = this.globalService
      .callAPI(globalConstants.API_GET_CUSTOM_VEHICLE_FIELD, data)
      .subscribe(
        (r: any) => {
          if (r.result == 1) {
            this.customFieldList = r.data;
          }
        },
        (error) => {}
      );
  }
  fieldList() {
    let dialogRef = this.dialog.open(ExtrafieldListComponent, {
      panelClass: ['car-dialog-form', 'extrafields-dialog-form'],
      width: '800px',
      data: {},
    });
    dialogRef.afterClosed().subscribe((r: any) => {
      this.getCustomFields();
    });
  }
  /**
   * @trackID <:CAR-1312> PartsTech VIN Scan
   * @trackID <:CAR-1319> PartsTech VIN Scan add more field
   * @since 2019-12-31
   * @updated 2020-01-15
   * @param Vehicle Detail(VinObj)
   * @return set/update various entity
   */
  setVinData() {
    Object.entries(this.VinObj).forEach(([key, val]) => {
      if (key == 'body') {
        this.carBodyStyleData = val;
      } else if (key == 'license_plate') {
        this.licensePlate = val;
        this.car.patchValue({ license_plate: val });
      } else if (key == 'make') {
        this.carMakeData = val;
        this.car.patchValue({ make: val });
      } else if (key == 'model') {
        this.carModelData = val;
        this.car.patchValue({ model: val });
      } else if (key == 'vin') {
        this.car.patchValue({ vin: val });
      } else if (key == 'year') {
        this.carModelYearData = val;
        this.car.patchValue({ year: val });
      } else if (key == 'engine_size') {
        this.carEngineDisplacementData = val;
        this.car.patchValue({ sub_model: val });
      } else if (key == 'state_id') {
        this.defaultState = val;
      } else if (key == 'state_name') {
        this.car.patchValue({ State: val });
      } else if (key == 'wheel_base') {
        this.car.patchValue({ wheel_base: val });
      } else if (key == 'tire_size') {
        this.car.patchValue({ tire_size: val });
      } else if (key == 'driveline') {
        this.car.patchValue({ driveline: val });
      }
    });
  }

  ngOnDestroy() {
    this.subscriptionDataForAPI.unsubscribe();
  }

  validateNumbersNoDecimal(event) {
    return globalFunctions.validateNumbersNoDecimal(event);
  }

  validateNumbersWithDecimal(event) {
    return globalFunctions.validateNumbersWithDecimal(event, 2);
  }

  /**
   * validates the form control's value on paste event.
   * ensure that validation messages are displayed if the pasted value is invalid.
   */
  validateFieldOnPaste(controlName: string) {
    const control = this.car.get(controlName);

    control.markAsTouched();
  }

  convertIntoDecimal(event,frmControlName,beforeDecimalDigit,digitAfterDecimal){
    let fieldValue = this.car.get(frmControlName).value
    let result= this.globalService.processDecimalValue(fieldValue,beforeDecimalDigit);
    this.car.get(frmControlName).setValue(result);
    return globalFunctions.validateNumbersWithDecimal(event,digitAfterDecimal);
  }
}
