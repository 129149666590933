import { Component, OnInit, ViewEncapsulation, Output, Input, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GlobalService } from '../../../../environments/global.service';
import { globalFunctions } from '../../../../environments/globalFunctions';
import { DetailTabComponent } from '../../shopservicerequest/detail-tab/detail-tab.component';
import { globalConstants } from '../../../../environments/globalConstants';
import { PartstechComponent } from 'src/app/partstech/partstech.component';
import { AppComponent } from 'src/app/app.component';
import {Router, ActivatedRoute,Params} from '@angular/router';
import { ServiceHistoryComponent } from '../../shopservicerequest/service-history/service-history.component';
import { PaymentDialogComponent } from '../../shopservicerequest/payment-dialog/payment-dialog.component';
import { MessageaddComponent } from '../../shopservicecomplaints/messageadd/messageadd.component';
import { AddMileageDialogComponent } from '../../shopservicerequest/add-mileage-dialog/add-mileage-dialog.component';
import { TechassignmentComponent } from '../../shopservicerequest/techassignment/techassignment.component';
import { AddLabelsDialogComponent } from '../../boards/add-labels-dialog/add-labels-dialog.component';
import  { UserAuthenticationDialogComponent } from '../../shopservicerequest/user-authentication-dialog/user-authentication-dialog.component';

@Component({
  selector: 'app-service-card',
  templateUrl: './service-card.component.html',
  styleUrls: ['./service-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ServiceCardComponent implements OnInit {
  @Input() serviceReqData : any;
  @Input() serviceReqDataComingFormPage :any;
  @Input() serviceReqFormColumn:any;
  @Input() serviceReqFormPage:any;
  public currentCustomer : any;
  public gc:any = globalConstants;
  public modulesName='service_request';
  public isServiceNote: any;
  public selectedFlag: string = '';
  @Output() reDrawJobBoard = new EventEmitter<any>();
  @Output() handleSendSRFunctionality = new EventEmitter<any>();
  @Output() handleCompleteSRFunctionality = new EventEmitter<any>();
  @Output() deleteSRCardWithoutDrawBoard = new EventEmitter<any>();
  @Output() reOpenSRWithOutreDrawBoard = new EventEmitter<any>();
  public jobStart :boolean = false;
  public jobResume : boolean = false;
  public jobPause: boolean = false;
  public isEmployee: boolean = false;
  public techData:any ={};
  public customLabels = [
    {
    "label_name": "OTL",
    "label_id": "OTL",
   },
   {
    "label_name": "Waiting For Parts",
    "label_id": "WAITING_FOR_PARTS",
   },
   {
    "label_name": "Accountant Verified",
    "label_id": "ACCOUNTANT_VERIFIED",
   }
  ];
  constructor(
    public dialog: MatDialog,
    public globalService: GlobalService,
    private app: AppComponent,
    public router: Router,
  ) { }

  ngOnInit(): void {
    this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
    this.isServiceNote= this.globalService.isSubscribe('service_notes');
    this.isEmployee=this.globalService.isSubscribe('employee');
    this.selectedFlag = this.serviceReqData.job_board_flag;
    this.statusOfJob();
    this.addCustomLabel();
  }

  addCustomLabel(){
    if (this.serviceReqData.on_the_lot == "1") {
      this.serviceReqData.labels.push(this.customLabels[0]);
    }
    if (this.serviceReqData.parts_awaited == "1") {
      this.serviceReqData.labels.push(this.customLabels[1]);
    }
    if (this.serviceReqData.accountant_verification == "1") {
      this.serviceReqData.labels.push(this.customLabels[2]);
    }
    const uniqueAllLabels = [...new Map(this.serviceReqData.labels.map(label => [label.label_id, label])).values()];
    this.serviceReqData.labels = uniqueAllLabels;
  }
  setSRFlag(flagValue: string) {
    if (flagValue === 'no-color') {
      this.selectedFlag = '';
    } else {
      this.selectedFlag = flagValue;
    }
     let object ={
      user_id:this.currentCustomer.id,
      user_type:'User',
      request_id: this.serviceReqData.id,
      flag_value: this.selectedFlag
     }
     this.globalService.callAPI(globalConstants.API_SET_SR_FLAG_ON_JOB_BOARD, object).subscribe((res: any) => {
     })
  }

  openViewCarPopUp(data, tabInfo) {
    let dialogRef = this.dialog.open(DetailTabComponent, {
      panelClass: 'car-dialog-form',
      width: "800px",
      data: { requestId: data.request_id, customer_id: data.customer_id, tab: tabInfo, car_id: data.car_id }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  getSRStatusColor() {
    let status: any = this.serviceReqData.is_appointment == '1' && (this.serviceReqData.status == '1' || this.serviceReqData.status == '2') ? 0 : this.serviceReqData.status;
    if (status != -1) {
      var statusArr = ['badge-appointment', 'badge-pending', 'badge-inprogress', 'badge-approval-awaitted', "", 'badge-completed', 'badge-completed', 'badge-completed', 'badge-paid', "", "", "", 'badge-work-in-progress'];
      return statusArr[status];
    } else {
      return 'deleted-cell';
    }
  }
  
  getStatusOfSendButton(){
    return this.serviceReqData.is_appointment !== '1' &&(this.serviceReqData.status == '2') ? true : false;
  }

  getStatusOfCompleteButton(){
    return this.serviceReqData.is_appointment !== '1' &&(this.serviceReqData.status == '2' || this.serviceReqData.status == '3' || this.serviceReqData.status == '12') ? true : false;
  }
  openServiceRequestViewPage(SRID: any) {
      window.open('/shops/view-service-request/' + SRID, "_blank");
  }

   /** open service request notes list */
 showSRNoteList(SRID) {
    this.globalService.srnoteListId(SRID);     
 }

 ngOnDestroy(){
    this.globalService.srnoteListId(false);
 }

 handlePartTechOrder(SRRequestID:any){
  let content = 'user_id=' + this.currentCustomer.id + '&user_type=' + this.currentCustomer.user_type + '&request_id=' + SRRequestID;
  this.globalService.callAPI(globalConstants.API_GET_PARTSTECH_ALL_CART_URL, content).subscribe((r:any) => {
    if (r.result == 1) {
      let dialogRef = this.dialog.open(PartstechComponent, {
        panelClass: 'partstech-order-dialog',
        width:'900px',
        data: { dataSources: r.data,orderNow:true, request_id: SRRequestID, user_id: this.currentCustomer.id, userType: this.currentCustomer.user_type }
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }
  });
 }

 handleNextPartOrder(SRRequestID:any){
  let content = 'user_id=' + this.currentCustomer.id + '&user_type=' + this.currentCustomer.user_type + '&request_id=' + SRRequestID;
  this.globalService.callAPI(globalConstants.API_GET_NEXLINK_ORDERS_URL, content).subscribe((r:any) => {
    if (r.result == 1) {
      let dialogRef = this.dialog.open(PartstechComponent, {
        panelClass: 'partstech-order-dialog',
        width:'900px',
        data: { dataSources: r.data,orderNow:false, request_id:SRRequestID, user_id: this.currentCustomer.id, userType: this.currentCustomer, partShop:globalConstants.PART_SHOPS.NEXPART,showOrder:true }
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }
  });
 }

 isAllowed(action,module?:any){
  if(typeof module!='undefined'){
      return this.globalService.isAllowed(this.app,module,action);
  }
    return this.globalService.isAllowed(this.app,this.modulesName,action);
 }

 isViewAssigneeAllowed(){
  return ( this.currentCustomer.role_id != 4 ? true : false );
}

openEditServiceRequestPage(rid,cusid) {
  window.open('shops/edit-service-request/'+ rid +"/"+cusid, "_blank");
}

openFillRecommendationPage(srInfo){
  if(srInfo.present_mileage == undefined || srInfo.present_mileage == "" || srInfo.present_mileage == 0){
    this.addMileageDialog(srInfo,srInfo.distance_covered,'2');
  } else {
    this.reportIsOn(srInfo,"/shops/service-inspection-report/" + srInfo.id);
  }
}

showServiceHistoryPopup(serviceInfo){​
  let info:any = {
    id:'service-history-'+serviceInfo.car_id,
    name: 'Service History View',
    module: 'Service Request'
  }
  let data:any = {​isPopup: true, carId:serviceInfo.car_id, serviceInfo:serviceInfo, id: 'service-history-'+serviceInfo.car_id };
  this.globalService.openMinimizablePopup(ServiceHistoryComponent, info, data);
}


  showPaymentDialog(id): void {
    let accessLimit = this.globalService.isAllowed(this.app, 'payment');
    this.globalService.setAcl(this.app.roles);
    let dialogRef = this.dialog.open(PaymentDialogComponent, {
      panelClass: ['payment-dialog'],
      data: { request_id: id, obj: this, accessLimit: accessLimit,fullRequestId: this.serviceReqData.request_id }
    });
    dialogRef.afterClosed().subscribe(result => {
         this.reDrawJobBoard.emit();
    });
  }

  ShowAddMessageDialog(row){
    let param={request_id:row.id}
    let dialogRef = this.dialog.open(MessageaddComponent, {
        panelClass: ['car-dialog-form'],
        data: param
    });
    dialogRef.afterClosed().subscribe(result => {
    });
}

openViewActivityLogsPage(id){
   window.open('/shops/service-request/logs/'+id)
}


/* Add mileage if it is empty in case of under process and fill recomendation */
addMileageDialog(row,distanceCovered,actionType): void {  
  let dialogRef = this.dialog.open(AddMileageDialogComponent, {
      panelClass: ['payment-dialog'],
      data: {request_id:row.id, distance_covered:distanceCovered, action_type:actionType}
  });

  dialogRef.afterClosed().subscribe(result => {
      if(result == 1){
        this.reDrawJobBoard.emit(); 
        this.reportIsOn(row,"/shops/service-inspection-report/" + row.id);
      }
  });
}
reportIsOn(row,url) {
   this.redirectUrl(url);
  //  if(row.check_action_status==6 ||row.check_action_status==8){
  //   this.redirectUrl(url);
  // }else if (!this.isReportGenerate) {
  //   this.redirectUrl(url);
  // } else if (typeof row.is_assign != 'undefined' && row.is_assign == 1) {
  //   this.redirectUrl(url);
  // } else if (typeof row.is_assign != 'undefined' && row.is_assign == 0 && row.is_assign == 0) {
  //   this.redirectUrl(url);
  //   ///this.autoAssign(row.id, url);
  // }
}

redirectUrl(url) {
    window.open(url);
 }

 underProcess(SRInfo:any){
     let object = {
       serviceRequestData: SRInfo,
       sourceColumnName : "apptAndPendingList",
       targetColumnName : "inProgressColumn"
     }
     this.handleSendSRFunctionality.emit(object);
 }

 sendServiceRequest(SRInfo:any){
      let object ={
           serviceRequestData : SRInfo,
           sourceColumnName : "inProgressColumn",
           targetColumnName : "approvalAwaitedColumn"
      };
      this.handleSendSRFunctionality.emit(object);
 }

 completeServiceRequest(SRInfo:any){  
       let sourecsColName ='';
       if(SRInfo.status == '2'){
         sourecsColName ="inProgressColumn";
       }else if(SRInfo.status == '3'){
          sourecsColName ="approvalAwaitedColumn";
       }else if(SRInfo.status == '12'){
          sourecsColName = "workInProgressColumn"
       }
       let object = {
        serviceRequestData : SRInfo,
        sourceColumnName : sourecsColName,
        targetColumnName : "paidColumn"
       };  
       this.handleCompleteSRFunctionality.emit(object);
 }

   returnFirstCharcterInName(fistName:any,lastName:any){
        let singleName = fistName.charAt(0)+lastName.charAt(0);
        return singleName;
   }

   statusOfJob(){
        let technicianData = this.serviceReqData.employee_timers;
        for (let index = 0; index < technicianData.length; index++) {
             if(technicianData[index].user_id == this.currentCustomer.id){
                  if(technicianData[index].work_status == 1){
                      this.jobResume = true;
                  } else if(technicianData[index].work_status == 2){
                      this.jobPause = true;
                  } else if(technicianData[index].work_status == 0){
                      this.jobStart = true;
                  }
             }
          
        }
   }

   resumeTheJob(option){
       this.showConfirmationBox(option);
   }

   pausedTheJob(option){
       this.showConfirmationBox(option);
   }

   showConfirmationBox(option:any){
    let conf = 'Are you sure to continue?';
    this.globalService.confirmDialog('taskTrack', conf).subscribe((r) => {
      let res: any = r;
      if (res == 'taskTrack') {
        this.taskTrackingApi(option);
      }
    }); 
   }

   taskTrackingApi(option,init?:any,forceStop=null){
    let data = 'user_id=' + this.currentCustomer.id + '&user_type=' + this.currentCustomer.user_type + '&request_id=' + this.serviceReqData.id + '&tracker=' + btoa(option);
    if(forceStop==2 || forceStop==3){
      data +="&force_action="+forceStop;
    }
     this.globalService.callAPI(globalConstants.API_TRACK_SERVICE_REQUEST,data).subscribe((track:any)=>{
      if(track.result==1){
          this.reDrawJobBoard.emit();

        this.globalService.snackbar("success", track.message);
      }else{
        if(track.code==388){
          let conf = 'Are you sure you want to Pause/Complete other service request timer?';
          this.globalService.confirmDialog('taskTrackConfirm', conf).subscribe((res) => { 
            if(res){
              this.taskTrackingApi(option,init,res);
            }  
          }); 
        }else{
          this.globalService.snackbar("error", track.message);
        }
      }
    })
  }

  isViewAssignee(){
    return ( this.currentCustomer.role_id != 4 ? true : false );
  }

  viewAssignee(id) {
    if (this.isEmployee == false) {
      return false;
    }
    let dialogRef = this.dialog.open(TechassignmentComponent, {
      panelClass: ['car-dialog-form'],
      width: "900px",
      data: { request_id: id, techStatus: true }
    });
  }

  isVisible(){
    if(this.serviceReqData.status == 6 || this.serviceReqData == 8){
        return false;
    }
    return ( this.currentCustomer.role_id != 4 ? true : false );
  }

  ticketAssignment(id,SRDetail:Object ={}){
    if(this.isEmployee==false){
        return false;
    }
    this.techData= {request_id: id,data:SRDetail};
  }

  techAssignmentOut($event: any) {
    // this.techData = {};
    if ($event == true) {
      setTimeout(() => this.getTicketAssignSetting(), 1000);
    }
    this.techData = {};
  }

  getTicketAssignSetting(){
    let data="user_id="+this.currentCustomer.id+"&user_type="+this.currentCustomer.user_type;
    this.globalService.callAPI(globalConstants.API_SKIP_FOREVER,data).subscribe((skipTicket:any)=>{
        if(skipTicket.result=="1"){
          this.reDrawJobBoard.emit();
        }
    })
}

makeProperDateFormate(date:any,formate){
  let DateFormat=this.globalService.DateFormat();
  if(formate == 'H'){
      return this.globalService.getFullFormateDate(date,DateFormat,formate);
  }else {
      let dateFormatBeforeSpilt = this.globalService.getFullFormateDate(date,DateFormat,formate).split(' ');
      return dateFormatBeforeSpilt[0];
  }
}

addLabelsInSR(){
  let dialogRef = this.dialog.open(AddLabelsDialogComponent, {
    panelClass: 'car-dialog-form',
    width: "800px",
    data: {serviceReqInfo:this.serviceReqData}
  });
  dialogRef.afterClosed().subscribe(result => {
    if(result){
       this.serviceReqData = result.SRData;
       this.addCustomLabel();
    }
  });
}

removeLabel(event:any){
  let label_id : number = event;
  this.globalService.callAPI(globalConstants.API_DELETE_SERVICE_REQUEST_LABEL,"request_id="+this.serviceReqData.id+"&user_id="+this.globalService.getCurrentUser()["id"]+"&label_id="+label_id+ "&user_type=User").subscribe((response:any) => {
     if(response.result ==1){
      this.serviceReqData.labels = response.data.labels;
      this.addCustomLabel();
     }else{
      this.globalService.snackbar("error",response.message);
     }
  })
}

  softDelete() {
    let dialogRef = this.dialog.open(UserAuthenticationDialogComponent, {
      panelClass: 'car-dialog-form',
      width: "800px"
    });
    dialogRef.afterClosed().subscribe(
      (result) => {
        if (result && result.passwordOK) {
          this.continueDelete();
        }
      },
      (err) => { }
    );
  }

  continueDelete() {
    let data: any = {
      user_id: this.currentCustomer.id,
      user_type: this.currentCustomer.user_type,
      request_id: this.serviceReqData.id
    };
    this.globalService.callAPI(globalConstants.API_MASTER_DELETE_SERVICE_TICKET, data)
      .subscribe((data: any) => {
        var message = data.message;
        if (data.result == "1") {
          this.globalService.snackbar('success', message);
          this.deleteSRCardWithoutDrawBoard.emit({
           "columnName": this.serviceReqFormColumn,
           "serviceReqId": this.serviceReqData.id
          }
            );
        } else {
          this.globalService.snackbar("error", message);
        }
      }, (error) => { });
  }

  getEmployeeHoursWorkedValue(num){
      return globalFunctions.convertMinutesIntoDayHoursMinutes(num);
  }

  reOpenServiceRequest(SRInfo: any) {
    let conf: string = "Are you sure you want to re-open "+ SRInfo.request_id+ " service request?";
    this.globalService.confirmDialog('ConfirmReopen', conf).subscribe((r) => {
      let res: any = r;
      if (res == 'ConfirmReopen') {
        // execute delete code
        continueReOpen();
      } else {
        return false;
      }
    });

    let continueReOpen = () => {
      let data: any = {
        user_id: this.currentCustomer.id,
        user_type: this.currentCustomer.user_type,
        request_id: SRInfo.id
      };
      this.globalService.callAPI(globalConstants.API_MASTER_REVERT_SERVICE_TICKET_URL, data)
        .subscribe((data: any) => {
          var message = data.message;
          if (data.result == "1") {
            this.globalService.snackbar('success', message);
            this.reOpenSRWithOutreDrawBoard.emit({
              "access_timestamp": data.data.access_timestamp,
              "columnName": this.serviceReqFormColumn,
              "serviceReqId": this.serviceReqData.id
             })
          } else {
            this.globalService.snackbar("error", message);
          }
        }, (error) => { });
    }
  }

  removeOnTheLotLabel(){
     let customLabelRemoveObj={
         labelType: "on_the_lot",
         APIURL : globalConstants.API_UPDATE_ON_THE_LOT_STATUS
     };
     this.removeCustomLabels(customLabelRemoveObj);
  }

  removeWaitingForPartLabel(){
    let customLabelRemoveObj = {
      labelType: "parts_awaited",
      APIURL: globalConstants.API_UPDATE_PARTS_WAITING_STATUS
    };
    this.removeCustomLabels(customLabelRemoveObj);
  }

  removeAccountantVerifiedLabel(){
    let customLabelRemoveObj = {
      labelType: "account_verify",
      APIURL: globalConstants.API_UPDATE_ACCOUNT_VERIFY_STATUS
    };
    this.removeCustomLabels(customLabelRemoveObj);
  }

  removeCustomLabels(customLabelRemoveObj:any){
    this.globalService.callAPI(customLabelRemoveObj.APIURL,"request_id="+this.serviceReqData.id+"&user_id="+this.currentCustomer.id+"&"+customLabelRemoveObj.labelType+"="+0+ "&user_type=User")
    .subscribe((response: any) => {
      if (response.result == 1) {
        this.globalService.snackbar("success", response.message);
        if (customLabelRemoveObj.labelType == 'on_the_lot') {
          this.serviceReqData.on_the_lot = 0;
          this.removeCustomLabelInCard('OTL');
          this.addCustomLabel();
          if(this.serviceReqFormPage !== undefined && this.serviceReqFormPage == 'OnTheLotPage'){
            this.deleteSRCardWithoutDrawBoard.emit({
              "columnName": this.serviceReqFormColumn,
              "serviceReqId": this.serviceReqData.id
             });
          }
        } else if (customLabelRemoveObj.labelType == 'parts_awaited') {
          this.serviceReqData.parts_awaited = 0;
          this.removeCustomLabelInCard('parts_awaited');
          this.addCustomLabel();
        } else if (customLabelRemoveObj.labelType == 'account_verify') {
          this.serviceReqData.accountant_verification = 0;
          this.removeCustomLabelInCard('account_verify');
          this.addCustomLabel();
        }
      }
    })
  }

  removeCustomLabelInCard(labelType: any) {
    let i = this.serviceReqData.labels.findIndex(label => label.label_id == labelType);
    if (i >= 0) {
      this.serviceReqData.labels.splice(i, 1);
    }
  }

  getVehicleName(vehicleInfo:any){
    return this.globalService.getGlobalVehicleName(vehicleInfo);
  }
}
