<div class="panel panel-primary add-vehicle-component" id="dashboardQuickLinks">
    <div class="panel-heading padding-left-15">
        <h3 class="panel-title">Add New Car</h3>
        <div class="flex-btns">
            <button mat-mini-fab title="Vin Scan" class="custom-btn-back vin-btn"  (click)="scanVINDialog(getAddCarParams,'carPage')">
                <i class="fa fa-barcode fa-lg"></i>
                 <span class="hidden-xs"> VIN Scan</span>
            </button>
            <button mat-mini-fab title="License plate" class="custom-btn-back license-btn"  *ngIf="isCountryValid() && currentCustomer.subscription_data.partstech_db == '1'"  (click)="openLicensePlateDialog(getAddCarParams,'carPage')">
                <i class="fa fa-car fa-lg"></i>
                 <span class="hidden-xs"> License Plate</span>
                
            </button>
            <button mat-mini-fab class="custom-btn-back" (click)="globalService.goBack();">
                <mat-icon>arrow_back</mat-icon>
            </button>
        </div>
    </div>
    
    
    <div class="panel-body bgcolor-lightgrey car-body">
        <!-- Login page start -->
        <div id="signupContent" class="row bgcolor-lightgrey">
            <div class="container-fluid">
                <!-- <div class="row" *ngIf="shopCustomerDetails && shopCustomerDetails.is_sublet == '1'">
                    <div class="form-group col-sm-12 col-md-12 text-center">
                        <img src="assets/img/car-default-img.png" width="200px">
                       <p> Please select License Plate or VIN Scan option from header to add new car.</p>
                    </div>
                </div> -->
                <form [formGroup]="car" id="addMyCar" name="addMyCar" (ngSubmit)="AddCar()" class="form-inline col-xs-12 padding-bottom-15 add-vehicle-form" method="POST" enctype="multipart/form-data" autocomplete="off">
                    <div class="form-block">
                        <div class="row">
                            <!-- MAKE -->
                            <div class="form-group col-sm-12 col-md-6">
                                <label for="make">Make*</label>
                                <div class='relative uc-ngx-select' *ngIf="!EnterNewMake">
                                    <ngx-select class="display-full margin-bottom-20" 
                                        formControlname="sel_make"        
                                        [items]="carMakeList" 
                                        (select)="getSelectedOptionMakes($event)"
                                        placeholder="Select Make">
                                    </ngx-select>
                                    <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
                                        <mat-error *ngIf="car.controls.sel_make.hasError('required')">
                                            This field cannot be empty
                                        </mat-error>
                                    </div>
                                    <a href="javascript:void(0);" color="accent" class="others-link make-link" (click)="enterNewMake()" *ngIf="EnterNewMakeButton">Another Make</a>
                                </div>    
                                <div class='relative' *ngIf="EnterNewMake">
                                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" *ngIf="EnterNewMake" appearance="fill">
                                        <input matInput placeholder="Make" title="Make" formControlName="make" maxlength="200" class="form-control input-lg custom-form-input" (keyup)="checkIfValid()" [ngModel]="carMakeData" />
                                        <mat-error *ngIf="car.controls.make.hasError('required')">
                                            This field cannot be empty
                                        </mat-error>
                                    </mat-form-field>
                                    <a href="javascript:void(0);" class="others-link make-link" (click)="useExitingMake()" *ngIf="UseExistingMakeButton">Existing Make</a>

                                </div>
                                
                            </div>
                            <!-- YERAS -->
                            <div class="form-group col-sm-12 col-md-6">
                                <label for="year">Year*</label>
                                <div class='relative uc-ngx-select' *ngIf="!EnterNewYear">
                                    <ngx-select class="display-full margin-bottom-20" 
                                        [(ngModel)]="selectedYear"  
                                        [ngModelOptions]="{standalone: true}"           
                                        [items]="years" 
                                        (select)="getSelectedOptionYears($event)"
                                        [disabled]="disabledYear"
                                        placeholder="Select Year">
                                    </ngx-select>
                                    <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
                                        <mat-error *ngIf="car.controls.sel_year.hasError('required')">
                                            This field cannot be empty
                                        </mat-error>
                                    </div>
                                    <a href="javascript:void(0);" class="others-link year-link" (click)="enterNewYear()" *ngIf="EnterNewYearButton">Another Year</a>
                                </div>
                                
                                <div class='relative' *ngIf="EnterNewYear">
                                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" *ngIf="EnterNewYear" appearance="fill">
                                        <input matInput placeholder="Year" title="Year" formControlName="year" [disabled]="disabledYear"  class="form-control input-lg custom-form-input" maxlength="4" (keypress)="onlyNumericKey($event)" (keyup)="checkIfValid();autoFillProductionDate();checkVinValidity()" [ngModel]="carModelYearData" />
                                        <mat-error *ngIf="car.controls.year.hasError('required')">
                                            This field cannot be empty
                                        </mat-error>
                                        <mat-error *ngIf="car.controls.year.hasError('pattern') && car.get('year').touched">
                                            Invalid year
                                        </mat-error>
                                        <mat-error *ngIf="car.controls.year.hasError('minLength') ">
                                            Invalid year
                                        </mat-error>
                                    </mat-form-field>
                                    <a href="javascript:void(0);" class="others-link year-link" (click)="useExitingYear()" *ngIf="UseExistingYearButton">Existing Year</a>
                                </div>
                                
                            </div>
                            
                    </div>
                        
                <div class="row">
                    <div class="form-group col-sm-12 col-md-6">
                        <label for="model">Model*</label>
                        <div class='relative uc-ngx-select' *ngIf="!EnterNewModel">
                            <ngx-select class="display-full margin-bottom-20" 
                                [(ngModel)]="selectedModel"
                                [ngModelOptions]="{standalone: true}"       
                                [items]="carModels" 
                                (select)="getSelectedOptionModels($event)"
                                [disabled]="disabledModel"
                                placeholder="Select Model">
                            </ngx-select>
                            <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
                                <mat-error *ngIf="car.controls.sel_model.hasError('required')">
                                    This field cannot be empty
                                </mat-error>
                            </div>
                            <a href="javascript:void(0);" class="others-link model-link" (click)="enterNewModel()" *ngIf="EnterNewModelButton">Another Model</a>
                        </div>
                        <div class='relative' *ngIf="EnterNewModel">
                            <mat-form-field class="btn-block car-input" floatPlaceholder="never" *ngIf="EnterNewModel" appearance="fill">
                                <input matInput placeholder="Model" [disabled]="disabledModel" title="Model" formControlName="model"  class="form-control input-lg custom-form-input" maxlength="200" (keyup)="checkIfValid()" [ngModel]="carModalNameData" />
                                <mat-error *ngIf="car.controls.model.hasError('required')">
                                    This field cannot be empty
                                </mat-error>
                            </mat-form-field>
                            <a href="javascript:void(0);" class="others-link model-link" (click)="useExitingModel()" *ngIf="UseExistingModelButton">Existing Model</a>
                        </div>
                         
                </div>
                    
                <div class="form-group col-sm-12 col-md-6">
                    <label for="subModel">Engine Size</label>
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                        <input  matInput placeholder="Engine Size" title="Engine Size" formControlName="sub_model"  class="form-control input-lg custom-form-input" maxlength="255" [ngModel]="carEngineDisplacementData"/>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-sm-12 col-md-6">
                    <label for="vin">Vin</label>
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                        <input matInput placeholder="Vin" title="Vin" formControlName="vin" (blur)="checkVIN()" class="form-control input-lg custom-form-input text-uppercase" maxlength="17" (keyup)="checkIfValid();checkVinValidity()" [ngModel]="scannedVinNumber!=undefined?scannedVinNumber:''"/>
                        <mat-error *ngIf="car.controls.vin.hasError('patern') ">
                            Invalid Vin Number
                        </mat-error>

                        <mat-error *ngIf="car.controls.vin.hasError('YearError')">
                            Vin Issued After 1981 should have maximum 17 characters
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-group col-sm-12 col-md-6 production-date-grp">
                  <label for="">Production Date (<span>First select year, then month.</span>)
                      <span class="question-mark" matTooltipPosition="above" matTooltipClass="red-tooltip" matTooltipHideDelay="3000"
                       matTooltip="Production dates are usually referred to by the month and year the vehicle was built. To learn more about production dates and where to find them. Click Here">?</span>
                  </label>
                    <div class="vlio-datepicker relative">
                        <dp-date-picker mode="month" placeholder='MM/YYYY' (open)="goTo($event,dayPicker)" (onChange)="goTo($event,dayPicker)" theme="dp-material dp-main" class="btn-block" [config]="datePickerConfig" formControlName="production_date" #dayPicker  [ngModel]="carProductionDateData"></dp-date-picker>
                        <button type="button" class="calendar-icon" mat-icon-button (click)="dayPicker.api.open();">
                            <mat-icon class="mat-icon">date_range</mat-icon>
                        </button>
                    </div>
                    <div class="dp-error mat-error" *ngIf="!(car.controls.production_date.valid) && (car.value.production_date!='') && (car.value.production_date!=undefined)">
                        <p>Invalid Production Date</p>
                    </div>
                </div>
                

            </div>
            <div class="row">
                <div class="form-group col-sm-12 col-md-6">
                    <label for="distance">Mileage</label>
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                        <input matInput placeholder="Mileage" title="Mileage" formControlName="distance_covered" class="form-control input-lg custom-form-input" (keypress)="validateNumbersWithDecimal($event)" maxlength="10" />
                        <mat-error *ngIf="(car.get('distance_covered').hasError('pattern') && car.get('distance_covered').touched)">
                            Please enter only numbers or upto one decimal place
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="form-group col-sm-12 col-md-3">
                    <label for="licensePlate">License Plate*</label>
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                        <input matInput placeholder="License Plate" title="License Plate" formControlName="license_plate" (blur)="checkLicence()" class="form-control input-lg custom-form-input" maxlength="15" [(ngModel)]="licensePlate" (keyup)="checkIfValid()" style="text-transform:uppercase;" />
                        <mat-error *ngIf="car.controls.license_plate.hasError('required')">
                            License plate can't be empty
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="form-group col-sm-12 col-md-3 uc-ngx-select">
                    <label for="">State* </label>
                    <ngx-select class="display-full margin-bottom-20" 
                        [defaultValue]="defaultState"
                        formControlname="State"        
                        [items]="states" 
                        (select)="getSelectedOptionState($event)"
                        [(ngModel)]="defaultState" 
                        [ngModelOptions]="{standalone: true}" 
                        placeholder="Select State">
                    </ngx-select>
                    <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
                        <mat-error *ngIf="car.controls.State.hasError('required')">
                            This field cannot be empty
                        </mat-error>
                    </div>

                </div>
            </div>
        <div class="row">
            <div class="form-group col-sm-12 col-md-6 form-group--minheight">
                <label for="color">Color*</label>
                <div class='relative uc-ngx-select' *ngIf="addNewColor">
                    <ngx-select class="display-full margin-bottom-20" 
                        formControlname="select_color"        
                        [items]="carColors" 
                        (select)="getSelectedColor($event)"
                        placeholder="Choose color">
                    </ngx-select>
                    <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper" *ngIf="addNewColor">
                        <mat-error *ngIf="car.controls.select_color.hasError('required')">
                            This field cannot be empty
                        </mat-error>
                    </div>
                    <a href="javascript:void(0);" class="others-link" (click)="disableAddNewColor()" *ngIf="addNewColor">Other Color </a>
                </div>
                
                <div class='relative' *ngIf="!addNewColor">
                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                        <input  matInput placeholder="Color" title="Color" formControlName="color" class="form-control input-lg custom-form-input" *ngIf="!addNewColor" (keyup)="checkIfValid()" maxlength="50"/>
                        <mat-error *ngIf="car.controls.color.hasError('required') && !addNewColor">
                            This field cannot be empty
                        </mat-error>
                    </mat-form-field>
                    <a href="javascript:void(0);" class="others-link" (click)="enableAddNewColor()" *ngIf="!addNewColor">Existing Color</a>
                </div>
                
                
            </div>
            <div class="form-group col-sm-12 col-md-6">
                <label for="unitNumber">Unit Number</label>
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                    <input    matInput placeholder="Unit Number" title="Unit Number" formControlName="unit_number" class="form-control input-lg custom-form-input" maxlength="50" />
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-sm-12 col-md-6">
                <label for="driver">Driver</label>
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                    <input  matInput placeholder="Driver" title="Driver" formControlName="driver" class="form-control input-lg custom-form-input" maxlength="255" />
                </mat-form-field>
            </div>
            <div class="form-group col-sm-12 col-md-6">
                <label for="driverPhoneNumber">Driver Phone Number</label>
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                    <input  matInput placeholder="Driver Phone Number" title="Driver Phone Number" formControlName="driver_phone_number" class="form-control input-lg custom-form-input" [textMask]="{mask: phoneMask}" (keyup)="checkIfValid();checkHomePhoneValidity()"/>
                            <mat-error *ngIf="car.controls.driver_phone_number.hasError('phoneError')">
                        Invalid driver phone number
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-sm-12 col-md-6">
                <label for="registrationExpirationDate">Registration Expiration Date</label>
                <mat-form-field class="car-input btn-block car-datePicker"  floatPlaceholder="never" appearance="fill">
                    <input matInput class="form-control input-lg custom-form-input" formControlName="registration_expiration_date_frm" placeholder="Choose a date" [matDatepicker]="picker1" (focus)="picker1.open()" maxlength="25" onkeydown="return false;">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1 [touchUi]="setTouchViewValue()"></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="form-group col-sm-12 col-md-6">
                <label for="insuranceExpirationDate">Insurance Expiration Date</label>
                <mat-form-field class="car-input btn-block car-datePicker"  floatPlaceholder="never" appearance="fill">
                    <input matInput class="form-control input-lg custom-form-input" formControlName="insurance_expiration_date_frm" placeholder="Choose a date" [matDatepicker]="picker2" (focus)="picker2.open()" maxlength="25" onkeydown="return false;">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2 [touchUi]="setTouchViewValue()"></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
                <div class="form-group col-sm-12 col-md-6">
                    <label for="inspection_expiration_date">Inspection Expiration Date</label>
                    <mat-form-field class="car-input btn-block car-datePicker" floatPlaceholder="never" appearance="fill">
                        <input matInput placeholder="Inspection Expiration Date" title="Inspection Expiration Date" formControlName="inspection_expiration_date_frm" [matDatepicker]="picker3" (focus)="picker3.open()" maxlength="25" class="form-control input-lg custom-form-input text-uppercase" (keyup)="checkIfValid()"  />
                        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                        <mat-datepicker #picker3 [touchUi]="setTouchViewValue()"></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="form-group col-sm-12 col-md-6">
                    <label for="transmission">Transmission</label>
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                        <input matInput placeholder="Transmission" title="Transmission" formControlName="transmission" maxlength="100" class="form-control input-lg custom-form-input" (keyup)="checkIfValid()"  />
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-sm-12 col-md-6">
                    <label for="brake">Brake</label>
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                        <input matInput placeholder="Brake" title="Brake" formControlName="brake" class="form-control input-lg custom-form-input" maxlength="100" (keyup)="checkIfValid()"  />
                    </mat-form-field>
                </div>
                <div class="form-group col-sm-12 col-md-6">
                    <label for="driveline">Driveline</label>
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                        <input matInput placeholder="Driveline" title="Driveline" formControlName="driveline" class="form-control input-lg custom-form-input" maxlength="100" (keyup)="checkIfValid()"  />
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-sm-12 col-md-6">
                    <label for="gvwr">GVWR</label>
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                        <input matInput placeholder="GVWR" title="GVWR" formControlName="gvwr" class="form-control input-lg custom-form-input" maxlength="100" (keyup)="checkIfValid()"  />
                    </mat-form-field>
                </div>
                <div class="form-group col-sm-12 col-md-6">
                    <label for="wheel_base">Wheel Base</label>
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                        <input matInput placeholder="Wheel Base" title="Wheel Base" formControlName="wheel_base" class="form-control input-lg custom-form-input" maxlength="100" (keyup)="checkIfValid()"  />
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-sm-12 col-md-6">
                    <label for="tire_size">Tire Size</label>
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                        <input matInput placeholder="Tire Size" title="Tire Size" formControlName="tire_size" class="form-control input-lg custom-form-input " maxlength="100" (keyup)="checkIfValid()"  />
                    </mat-form-field>
                </div>
                <div class="form-group col-sm-12 col-md-6">
                    <label for="wheel_lock_location">Wheel Lock Location</label>
                    <div class='relative uc-ngx-select' *ngIf="antWheellocklocation">
                        <ngx-select class="display-full margin-bottom-20" formControlName="wheel_lock_location" [items]="wheelLock"
                            (select)="getLockLocation($event)" placeholder="Select Lock Location">
                        </ngx-select>
                        <a href="javascript:void(0);" color="accent" class="others-link" (click)="antWheellocklocation=(!antWheellocklocation)">Other Location</a>
                    </div>
                    <div class='relative' *ngIf="!antWheellocklocation">
                        <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                            <input matInput placeholder="Wheel Lock Location" title="Wheel Lock Location" formControlName="wheel_lock_location" class="form-control input-lg custom-form-input" maxlength="100"
                                (keyup)="checkIfValid()" />
                        </mat-form-field>
                        <a href="javascript:void(0);" color="accent" class="others-link" (click)="antWheellocklocation=(!antWheellocklocation)">Another Location</a>
                    </div>
                </div>
            </div>
            <div class="row">
                    <div class="form-group col-sm-12 col-md-6">
                        <mat-checkbox class="btn-block" labelPosition="after" (change)="californiaEmmissions($event)" formControlName="california_emmissions" >California Emissions</mat-checkbox>
                    </div>
                    <div class="form-group col-sm-12 col-md-6">
                        <label for="make">Default Labor Rate:</label>
                        <div class='relative uc-ngx-select'>
                            <ngx-select class="display-full margin-bottom-20" optionTextField="type"
                                formControlname="customer_type_id"        
                                [items]="labourRatesType" 
                                (select)="setOptionOfLabourType($event)"
                                placeholder="Select Default Labor Rate">
                            </ngx-select>
                        </div>
                    </div>
            </div>
            <div class="row" *ngIf="isCustFieldAllowed()">
                <div class="col-sm-12">
                    <h2 class="fontcolor-blue sub-heading more-fields-heading">More Fields
                        <button type="button" mat-raised-button class="font-bold fontcolor-white" color="accent" (click)="fieldList()" title="Custom Fields">Custom Fields</button>
                    </h2>
                </div>
            </div>
            <div class="row row-flex" *ngIf="isCustFieldAllowed() && customFieldList.length">
                <div class="form-group col-sm-12 col-md-6" *ngFor="let field of customFieldList;let i=index">
                    <label for="tire_size" *ngIf="field.type!='checkbox'">{{field.label}}<span *ngIf="field.is_mandatory==1">*</span></label>
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never" *ngIf="field.type=='textbox'" appearance="fill">
                        <input matInput placeholder="{{field.label}}" title="{{field.label}}" [(ngModel)]="customFieldList[i].value" [ngModelOptions]="{standalone: true}" class="form-control input-lg custom-form-input " maxlength="200" (keyup)="checkIfValid()"  />
                    </mat-form-field>
                    <div class='relative' *ngIf="field.type=='radio'">
                        <mat-radio-group class="example-radio-group" [(ngModel)]="customFieldList[i].value"
                            [ngModelOptions]="{standalone: true}">
                            <mat-radio-button class="example-radio-button margin-right-10" *ngFor="let opt of field.field_options" [value]="opt.id">
                                {{opt.value}} </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class='relative' *ngIf="field.type=='checkbox'">
                        <mat-checkbox class="btn-block" [(ngModel)]="customFieldList[i].value"
                            [ngModelOptions]="{standalone: true}" labelPosition="after">
                            {{field.label}}
                        </mat-checkbox>
                    </div>
                    <div class='relative uc-ngx-select'  *ngIf="field.type=='dropdown'">
                        <ngx-select class="display-full margin-bottom-20" [items]="field.field_options" [(ngModel)]="customFieldList[i].value" [ngModelOptions]="{standalone: true}"
                        optionTextField="value" optionValueField="id" (select)="chooseCustmField($event,i)" placeholder="Select {{field.label}}">
                        </ngx-select>
                    </div>
                </div>
            </div>

            <h2 class="fontcolor-blue sub-heading dfs-title">DFS REMINDER SETTINGS</h2>
            <div class="row">
              <div class="col-sm-12">
                <p class="help-block margin-0">The settings below are the vehicle level settings for "Due For Service" reminder notifications.</p>
              </div>
            </div>
            <div *ngIf="shopDetail?.dfs_reminders == 1">
                <div class="row">
                    <div class="form-group  col-sm-12 col-md-6">
                      <label>Standard Mileage (SM):</label>
                      <div class='relative'>
                        <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">  
                          <input matInput placeholder="Enter Standard Mileage" formControlName="standard_mileage" maxlength="10"  class="form-control input-lg custom-form-input"  (keypress)="convertIntoDecimal($event,'standard_mileage',8,2)" (paste)="validateFieldOnPaste('standard_mileage')"/>
                          <mat-error *ngIf="(car.get('standard_mileage').hasError('invalidValue') && car.get('standard_mileage').touched) || car.get('standard_mileage').status == 'INVALID'">
                            Please enter only numbers or upto one decimal place
                        </mat-error>
                      </mat-form-field>
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <p class="help-block margin-0" >System will send service reminder notification to customer.</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group  col-sm-12 col-md-6">
                      <label>Standard Days (SD):</label>
                      <div class='relative'>
                        <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">  
                          <input matInput placeholder="Enter Standard Days" formControlName="standard_days" class="form-control input-lg custom-form-input" maxlength="4" (keypress)="validateNumbersNoDecimal($event)"(paste)="validateFieldOnPaste('standard_days')"/>
                          <mat-error *ngIf="(car.get('standard_days').hasError('invalidValue') && car.get('standard_days').touched) || car.get('standard_days').status == 'INVALID'">
                            Please enter only numbers
                        </mat-error>
                      </mat-form-field>
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <p class="help-block margin-0">System will send service reminder notification to customer.</p>
                    </div>
                  </div>
            </div> 
            
            <div *ngIf="shopDetail?.dfs_reminders == 0">
                <app-alert-warning [addonPayloadValue]="gc.ADD_ONS_OBJ.dfsReminders"></app-alert-warning>
              </div>

        <div class="row margin-top-25">
            <div class="form-group col-sm-12 col-md-6 upload-image">
                <label for="vehicleRegPic">Vehicle Registration Photo</label>
                <mat-toolbar class="mat-elevation-z2 margin-bottom-5 upload-btn relative">
                    <mat-icon class="margin-right-10">cloud_upload</mat-icon>
                    Drag File Here
                    <input type="file" id="addvehicle_registered_pic" (change)="addMultipleImg($event,'vehicle_registered_pic')" name="vehicle_registered_pic"  accept="image/*"/>
                    <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
                </mat-toolbar>
                <div class="margin-bottom-5">
                    <p class="help-block" style="margin:0px;">Formats supported : {{FORMAT_SUPPORTED_FOR_IMAGE}}</p>
                    <p class="help-block" style="margin:0px;">Maximum size : {{MAX_ALLOWED_IMAGE_DISPLAYED_TEXT}}</p>
                </div>
                <ul id="reg_photo-ul" class="list-inline margin-bottom-0 margin-top-5">
                    <li class="relative view-images" *ngFor="let img of vehicle_registered_pics;let i=index">
                        <a href="javascript:void(0);" class="thumbnail"><img class="show-image" src="{{img.src}}"><i class="fa fa-trash remove-img" (click)="deleteImg('vehicle_registered_pic',i)"></i></a>
                    </li>
                </ul>
            </div>
            <div class="form-group col-sm-12 col-md-6 upload-image">
                <label for="vehicleInsPic">Vehicle Insurance Card Photo</label>
                <mat-toolbar class="mat-elevation-z2 margin-bottom-5 upload-btn relative">
                    <mat-icon class="margin-right-10">cloud_upload</mat-icon>
                    Drag File Here
                    <input type="file" id="addvehicle_insured_pic" (change)="addMultipleImg($event,'vehicle_insured_pic')" name="vehicle_insured_pic" accept="image/*"/>
                    <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
                </mat-toolbar>
                <div class="margin-bottom-5">
                    <p class="help-block" style="margin:0px;">Formats supported : {{FORMAT_SUPPORTED_FOR_IMAGE}}</p>
                    <p class="help-block" style="margin:0px;">Maximum size : {{MAX_ALLOWED_IMAGE_DISPLAYED_TEXT}}</p>
                </div>
                <ul id="insurance_photo-ul" class="list-inline margin-bottom-0 margin-top-5">
                    <li class="relative view-images" *ngFor="let ins of vehicle_insured_pics;let i=index">
                        <a href="javascript:void(0);" class="thumbnail"><img class="show-image" src="{{ins.src}}"><i class="fa fa-trash remove-img" (click)="deleteImg('vehicle_insured_pic',i)"></i></a>
                    </li>
                </ul>
                <!-- <input type="file" id="vehicleInsPic" name="vehicle_insured_pic" placeholder="Vehicle Insured Pic"  (change)="getFile($event,'vehicle_insured_pic')"> -->
            </div>
        </div>
        <div class="row">
            <div class="form-group col-sm-12 col-md-6 upload-image">
                <label for="vehiclePic">Vehicle Photo</label>
                <mat-toolbar class="mat-elevation-z2 margin-bottom-5 upload-btn relative">
                    <mat-icon class="margin-right-10">cloud_upload</mat-icon>
                    Drag File Here
                    <input type="file" id="addvehicle_pic" (change)="addMultipleImg($event,'vehicle_pic')" name="vehicle_pic" accept="image/*"/>
                    <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
                </mat-toolbar>
                <div class="margin-bottom-5">
                    <p class="help-block" style="margin:0px;">Formats supported : {{FORMAT_SUPPORTED_FOR_IMAGE}}</p>
                    <p class="help-block" style="margin:0px;">Maximum size : {{MAX_ALLOWED_IMAGE_DISPLAYED_TEXT}}</p>
                </div>
                <ul id="vehicle_photo-ul" class="list-inline margin-bottom-0 margin-top-5">
                    <li class="relative view-images" *ngFor="let vic of vehicle_pics;let i=index">
                        <a href="javascript:void(0);" class="thumbnail"><img class="show-image" src="{{vic.src}}"><i class="fa fa-trash remove-img" (click)="deleteImg('vehicle_pic',i)"></i></a>
                    </li>
                </ul>
                <!-- <input type="file" #fileInput formControlname="vehicle_pic" placeholder="Vehicle Pic" (change)="getFile($event,'vehicle_pic')"> -->
            </div>
            <div class="form-group col-sm-12 col-md-6 upload-image">
                <label for="vehicleVideo">Vehicle Video</label>
                <mat-toolbar class="mat-elevation-z2 margin-bottom-5 upload-btn relative">
                    <mat-icon class="margin-right-10">cloud_upload</mat-icon>
                    Drag File Here
                    <input type="file" id="addvehicle_video" (change)="addMultipleImg($event,'vehicle_video')" name="vehicle_video" />
                    <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
                </mat-toolbar>
                <div class="margin-bottom-5">
                    <p class="help-block" style="margin:0px;">Formats supported : {{FORMAT_SUPPORTED_FOR_VIDEO}}</p>
                    <p class="help-block" style="margin:0px;">Maximum size : {{MAX_ALLOWED_VIDEO_DISPLAYED_TEXT}}</p>
                </div>
                <ul id="vin_video-ul" class="list-inline margin-bottom-0 margin-top-5">
                    <li class="relative video-upload" *ngFor="let vid of vehicle_videos;let i=index">
                        <a href="javascript:void(0);" class="thumbnail margin-bottom-0">
                            <span class="video-bg">
                                <mat-icon class="position-center play-video">play_circle_filled</mat-icon>
                                <i class="fa fa-trash remove-img" (click)="deleteImg('vehicle_video',i)"></i>
                            </span>
                        </a>
                    </li>
                </ul>
                <!-- <input type="file" formControlname="vehicle_video" placeholder="Vehicle Video"(change)="getFile($event,'vehicle_video')"> -->
            </div>
        </div>
        <div class="row">
            <div class="form-group col-sm-12 col-md-6 upload-image">
                <label for="vehicleVinPic">Vehicle Vin Photo</label>
                <mat-toolbar class="mat-elevation-z2 margin-bottom-5 upload-btn relative">
                    <mat-icon class="margin-right-10">cloud_upload</mat-icon>
                    Drag File Here
                    <input type="file" id="addvehicle_vin_pic" (change)="changeListner($event,'vehicle_vin_pic')" name="vehicle_vin_pic" accept="image/*"/>
                    <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
                </mat-toolbar>
                <div class="margin-bottom-5">
                    <p class="help-block" style="margin:0px;">Formats supported : {{FORMAT_SUPPORTED_FOR_IMAGE}}</p>
                    <p class="help-block" style="margin:0px;">Maximum size : {{MAX_ALLOWED_IMAGE_DISPLAYED_TEXT}}</p>
                </div>
                <ul id="vin_photo-ul" class="list-inline margin-bottom-0 margin-top-5">
                    <li class="relative view-images" *ngIf="vehicle_vin_pic!=''">
                        <a href="javascript:void(0);" class="thumbnail"><img class="show-image" src="{{vehicle_vin_pic}}"><i class="fa fa-trash remove-img" (click)="deleteImg('vehicle_vin_pic',0)"></i></a>
                    </li>
                </ul>
                <!-- <input type="file" formControlname="vehicle_vin_pic" (change)="getFile($event,'vehicle_vin_pic')"> -->
            </div>
            <div class="form-group col-sm-12 col-md-6">
                <label for="vehicleInsPic">Other Labels Photo</label>
                <div class="upload-image" id="upload-img">
                    <mat-toolbar class="mat-elevation-z2 margin-bottom-5 upload-btn relative">
                        <mat-icon class="margin-right-10">cloud_upload</mat-icon>
                        Drag File Here
                        <input type="file" id="addother_labels_temp" (change)="addMultipleImg($event,'other_labels_temp')" name="other_labels_temp" accept="image/*"/>
                        <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
                    </mat-toolbar>
                    <div class="margin-bottom-5">
                        <p class="help-block" style="margin:0px;">Formats supported : {{FORMAT_SUPPORTED_FOR_IMAGE}}</p>
                        <p class="help-block" style="margin:0px;">Maximum size : {{MAX_ALLOWED_IMAGE_DISPLAYED_TEXT}}</p>
                    </div>
                    <ul id="other_labels_temp-ul" class="list-inline margin-bottom-0 margin-top-5">
                        <li class="relative view-images" *ngFor="let ins of otherLabelsTemps; let index = index">
                            <a href="javascript:void(0);" class="thumbnail"><img class="show-image" src="{{ins.src}}"><i class="fa fa-trash remove-img" (click)="deleteImg('other_labels_temp',index)"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-sm-12 col-md-12">
                 <mat-checkbox class="btn-block" labelPosition="after" (change)="checkUSPS($event)" formControlName="form_usps_vehicle" >
                    USPS Vehicle
                </mat-checkbox>
            </div>
        </div> 
    </div>
    <div class="row">
        <div class="col-xs-12 col-md-3 submit-block">
            <button type="submit" mat-raised-button class="car-btn btn-block text-uppercase  btn-lg font-bold fontcolor-white" color="accent" title="Submit"  style="max-width: 300px;">SUBMIT</button>
        </div>
        <div class="col-xs-12 col-md-4 submit-block">
            <button type="button" mat-raised-button (click)="AddCar(2)" class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white" color="accent" title="Submit & Create Request" style="max-width: 300px;">Submit & Create Request</button>
        </div>
    </div>
</form>
</div>
</div>
</div>
</div>