import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalService } from '../../../../environments/global.service';
import { globalConstants } from '../../../../environments/globalConstants';

@Component({
  selector: 'app-messageadd',
  templateUrl: './messageadd.component.html',
  styleUrls: ['./messageadd.component.scss']
})
export class MessageaddComponent implements OnInit {

  public user: any = null
  public param: any = {}
  public addMessage: FormGroup;
  public serviceReadStatus = '';
  public serviceInfo: any;
  public serviceInfoComments: any = [];
  public serviceInfoStatus;
  public read_status;
  public submitted = false;
  public statuses = ['', 'Created', 'Resolved', 'Closed', 'Unread'];
  public currentCustomer: any = {}
  public base64value = "";
  public selectedKey = "";
  public currentComplaintPicture = '';

  constructor(@Inject(MAT_DIALOG_DATA) public p: any, private formBuilder: FormBuilder, public gs: GlobalService, public dialogRef: MatDialogRef<MessageaddComponent>) {

    this.user = this.gs.getCurrentUser()
    this.gs.diaLogObj(this.dialogRef);
    this.param = p
    let isEnable = this.gs.getAcl('complaints', 'views');
    if (!(isEnable)) {
      this.dialogRef.close()
    }
    this.initForm()
  }
  ngOnInit() {

  }
  initForm() {
    this.addMessage = this.formBuilder.group({
      add_complaint_title: ['', Validators.required],
      add_complaint_description: ['', Validators.required],
      complaint_pic: [''],
      add_complaint_request_id: [this.param.request_id],
      user_type: this.user.user_type,
      user_id: this.user.id,
    })
  }

  getFile(evt, key) {
    this.selectedKey = key;
    var files = evt.target.files;
    var validImage= this.gs.validateFiles('image', evt.target.files);
    var file = files[0];
    if (files && file && validImage) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsDataURL(file);
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64value = (binaryString);
    this.currentComplaintPicture = binaryString;
    if (this.selectedKey == "complaint_pic") {
      this.addMessage.controls.complaint_pic.setValue(this.base64value);
    }
  }

  deleteComplaintPic() {
    this.currentComplaintPicture = '';
    this.addMessage.controls.complaint_pic.setValue('');
    let ele = <HTMLInputElement>document.getElementById('complaintPic');
    ele.value = '';
  }
  addComplaint() {
    if (this.addMessage.valid) {
      var data = new FormData();
      for (let i in this.addMessage.value) {
        data.append(i, this.addMessage.value[i]);
      }
      this.gs.formData(globalConstants.API_ADD_COMPLAINT_URL, data).subscribe((r) => {
        if (r.result == 1) {
          this.dialogRef.close();
          this.gs.snackbar("success", r.message);
        } else {
          this.gs.snackbar("error", r.message);
        }
      },(error)=>{ });
    } else {
      this.submitted = false;
    }
  }

}
