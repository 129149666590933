import { Component, OnInit, ViewChild } from '@angular/core';
import { ViktableComponent, VikSource } from '../../../admin/viktable/viktable.component';
import { MatPaginator} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {  MatDialog } from '@angular/material/dialog';
import { GlobalService } from '../../../../environments/global.service';
import { globalConstants } from '../../../../environments/globalConstants';
import { Router } from '@angular/router';
import { DetailTabComponent } from '../detail-tab/detail-tab.component';
import { MatTableDataSource } from '@angular/material/table';

const INSPECT_LIST = "shop-inspectlist-";

@Component({
  selector: 'app-inspection-list',
  templateUrl: './inspection-list.component.html',
  styleUrls: ['./inspection-list.component.scss']
})
export class InspectionListComponent extends ViktableComponent implements OnInit {
  
  public user:any=null
  
  public tablesData = this;
  public searchEnabled : boolean = false;
  public displayedColumns=['cust_name','name','registration_date','insurance_date','inspection_date','Actions']
  public searchByStatus = [];
  public searchByArray = []
  public options:any = {
    user_id: "",
    user_type: "User",
    status: '',
    search_by: "",
    keyword: "",
    inspection: 0,
    registration: 0,
    insurance: 0,
    start: 0,
    limit: 10
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<InspectionData> = new MatTableDataSource();
  public record :any =[];
  constructor(public gs: GlobalService, public router: Router,public dialog: MatDialog) {
    super(globalConstants.API_GET_INSPECTION_LIST, gs);
    this.user=this.gs.getCurrentUser()
    if (this.user) {
      this.sessionKey = INSPECT_LIST;
      this.url = globalConstants.API_GET_INSPECTION_LIST;
    } else {
      this.router.navigate(["/shops"]);
    }

    this.searchByArray = [
      { id: "1", text: "Expired" },
      { id: "2", text: "Coming up in 7 days" },
      { id: "3", text: "Coming up in 30 days" },
      { id: "4", text: "Coming up in 60 days" }
    ];
  }

  ngOnInit() {
    this.gs.setMetaData("SHOPS", "INSPECT_LIST");
    this.reInitOptions()
    this.setDataTable();
  }
  reInitOptions(){
    
    this.options.user_type = this.user.user_type;
    this.options.user_id = this.user.id;
    this.options.status = '0';
   }

   setDataTable() {
    // this.dataSource = new VikSource(this.tablesData, this.sort, this.paginator);
    // this.dataSource.getSortedData = this.sortedData;
    this.record = [];
    var content = this.options;
      this.detailData={};
      this.resultData=[];
      
      this.isGetData=false
      var callApi = this.gs.callAPI(this.url, content);
      callApi.subscribe((data:any) => {
        console.log("this is qiuck ",data)
        this.isGetData=true
        if(this.returnData && data[this.returnData]){
          this.detailData=data[this.returnData];
        }
        if (data.data != undefined) {
          let moreRows = 'false';
          let firstList = 'false';
          this.gs.localGetterSetter("totalRecords", data.recordsTotal, this.sessionKey);
          if (data.more_rows == "true") {
            moreRows = "true";
          }
          this.gs.localGetterSetter("more_rows", moreRows, this.sessionKey);
          if (data.start >= 0 && data.start <= 5) {
            firstList = "true";
          }
          this.gs.localGetterSetter("first_list", firstList, this.sessionKey);
          if (data.data.length != undefined) {
            
            this.recordsTotal = data.data.length;
            console.log("this is data.data 1", this.recordsTotal)
            for (var i = 0; i < data.data.length; i++) {
              this.AddRecord(data.data[i])
            }
            console.log("this is recode in table",this.record)
            this.dataSource = new MatTableDataSource(this.record)
            this.dataSource.sort = this.sort;
          } else {
            console.log("this is data.data 2")
            this.recordsTotal = "1";
            this.AddRecord(data.data)
            this.dataSource = new MatTableDataSource(this.record)
            this.dataSource.sort = this.sort;
          }
          this.gs.localGetterSetter(null, this.options, this.sessionKey);
        } else {
          this.dataSource = new MatTableDataSource(this.record);
          this.gs.localGetterSetter("totalRecords", '0', this.sessionKey);
        }
      },(error)=>{
        this.gs.localGetterSetter("totalRecords", '0', this.sessionKey);
      })
  }

  AddRecord(record){
    var data = this.createRecordrecord(record);
      this.record.push(data);
  }
  createRecordrecord(record) {
    return {
      id: record.id,
      cust_name:(record.first_name+' '+record.last_name),
      name: this.gs.getGlobalVehicleName(record)||"-",
      added_at : this.convertDate(record.add_date),
      inspection_date : this.convertDate(record.inspection_expiration_date,'GD'),
      insurance_date : this.convertDate(record.insurance_expiration_date,'GD'),
      registration_date : this.convertDate(record.registration_expiration_date,'GD'),
      inspeDays_left : (record.inspection_days_left!='0'?record.inspection_days_left:0),
      insDays_left : (record.insurance_days_left!='0'?record.insurance_days_left:0),
      regDays_left : (record.registration_days_left!='0'?record.registration_days_left:0),
      customer_id:record.customer_id
    };
  }

  formatVehicle(record){
    let name='';
    if(record.make){
      name=record.make
    }
    if(record.year){
      name=name+' '+record.year
    }
    if(record.model){
      name=name+' '+record.model
    }
    return (name ? name : 'N/A')
  }
 

  convertDate(date,format?:any){
    return this.gs.getFullFormateDate(date,(format?format:'G'),'H');
  }

  isAnyOptionActive(button?: any) {
    if (this.options.search_by == "" && this.options.keyword == "") {
      return false;
    }else {
      return true;
    }
  }

  search() {
    this.options.start = 0;
    if(this.options.inspection){
      this.options.inspection = 1;
    }else{
      this.options.inspection = 0;
    }
    if(this.options.registration){
      this.options.registration = 1;
    }else{
      this.options.registration = 0;
    }
    if(this.options.insurance){
      this.options.insurance = 1;
    }else{
      this.options.insurance = 0;
    }
    this.setDataTable();
  }

  clearSearch() {
    this.options.user_type = this.user.user_type;
    this.options.user_id = this.user.id;
    this.options.status = '';
    this.options.keyword = '';
    this.options.search_by = '';
    this.options.start = 0;
    this.options.inspection = '';
    this.options.registration = '';
    this.options.insurance = '';
    this.setDataTable();
  }

  view(row) {
    localStorage.setItem("carDetailsCustomerID", row.customer_id);
    localStorage.setItem("carDetailsVehicleID", row.id);
    this.router.navigate(['shops/view-car']);
  }

  isExpired(day,exin?:any){
    let d=parseInt(day)
    let r:any='';
    if((!isNaN(d))){
      if(d && d<=7){
        r= 7;
      }
      if(d>7 && d<=30){
        r=30;
      }
      if(d>30 && d<=60){
        r=60;
      }
      if(d>60){
        r= 'not';
      }      
    }

    // let fr=(r?r:'-ed')
    return '-'+(d ? r : 'ed')
  }
  /** day or days accoding to day count */
  showDays(day,t){
    if(t){
      return (day ? day + ( day == 1 ? ' Day':' Days') :'Expired')
    }else{
      return (day ? (day>999?'999+':day) + ( day == 1 ? ' Day':' Days') :'Expired')
    }
  }
  /** show "Not provided" instead of "-" */
  dateProvided(d){
    return (d!='-'? d : 'Not provided')
  }
  /**show customer detail */
  cutomerDetail(row,type){
    let dialogRef = this.dialog.open(DetailTabComponent, {
      panelClass: 'car-dialog-form',
      width: '800px',
      data: { customer_id: row.customer_id, tab: type ,car_id : row.id }
    });
  }

}

export interface InspectionData {
  cust_name: any;
  inspection_date: any;
  registration_date:any;
  insurance_date: any;
  name:any;
  days_left: any;
}