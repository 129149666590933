<div class="panel panel-primary margin-0" id="mainCompotents">
    <div class="panel-heading padding-left-15 business-wrapper">
       <div class="row">
            <div class="col-xs-7">
                <h3 class="panel-title">
                    Business Metrics
                </h3>
            </div>
            <div class="col-xs-5">
                <div class="" style="text-align: right;">
                    <button class="settingsIcon text-white fa fa-download" title="Export Report" (click)="exportInPDF()" type="button"></button>
                    <button class="settingsIcon text-white fa fa-times-circle" title="Excluded Vehicle List" type="button"
                        (click)="excludedVehicleSideBar = true">
                    </button>
                    <button [matMenuTriggerFor]="menu" class="settingsIcon text-white"><i class="fa fa-cog"></i></button>
                    <mat-menu #menu="matMenu" class="mat-dropdown-menu business-metrics" [overlapTrigger]="false" xPosition="before">
                        <button mat-menu-item routerLink="../advance-report">Advanced Report</button>
                        <button mat-menu-item>Business Metrics</button>                   
                        <button mat-menu-item routerLink="../../reports-stats-servicerequests">Sales Report</button>                        
                    </mat-menu>
                    <button class="settingsIcon text-white fa fa-filter" title="Filters" type="button"
                        (click)="searchFilterSideBar = true">
                    </button>
                </div>
            </div>
       </div>
    </div>
    <div class="panel-body white">
        <div class="bgcolor-lightgrey" *ngIf="reportData">
            <div class="filter-search-box bussiness-report">
                <div class="row">
                    <div class="col-md-12 col-sm-12">
                        <div class="help-block">How effective is your shop at selling work?</div>
                    </div>
                </div>
                <ul class="bgcolor-white margin-bottom-15">
                    <li>
                        <div class="infoTile">
                            <strong>Total SR <i matTooltip="Total SR paid (Total SR paid with rejected jobs)" mat-tooltip-panel-above class="tooltip-status fa fa-question" aria-hidden="true"></i></strong>
                            <p class="margin-bottom-0">{{reportData.total_no_sr}}<ng-container *ngIf="reportData.rejected_no_sr && reportData.rejected_no_sr > 0" >({{reportData.rejected_no_sr}})</ng-container></p>
                        </div>
                    </li>
                    <li>
                        <div class="infoTile">
                            <strong>Hours Presented <i matTooltip="Total of Nexpart Labor time and Estimated Time" mat-tooltip-panel-above class="tooltip-status fa fa-question" aria-hidden="true"></i></strong>
                            <p class="margin-bottom-0">{{reportData.hour_presented}}</p>
                        </div>
                    </li>
                    <li>
                        <div class="infoTile">
                            <strong>Hours Sold <i matTooltip="Total of accepted jobs for all SR" mat-tooltip-panel-above class="tooltip-status fa fa-question" aria-hidden="true"></i></strong>
                            <p class="margin-bottom-0">{{reportData.hour_sold}}</p>
                        </div>
                    </li>
                    <li>
                        <div class="infoTile">
                            <strong>Close Ratio <i matTooltip="Hours Sold/Hours Presented" mat-tooltip-panel-above class="tooltip-status fa fa-question" aria-hidden="true"></i></strong>
                            <p class="margin-bottom-0">{{reportData.close_ratio}}</p>
                        </div>
                    </li>
                    <li>
                        <div class="infoTile">
                            <strong>Effective Labor Rate <i matTooltip="Total Labor Sales/Hours Sold" mat-tooltip-panel-above class="tooltip-status fa fa-question" aria-hidden="true"></i></strong>
                            <p class="margin-bottom-0">{{ reportData.effective_labor | currency:'USD':'symbol-narrow' }}</p>
                        </div>
                    </li>
                </ul>
    
                <div class="row">
                    <div class="col-md-12 col-sm-12">
                        <div class="help-block">What are your overall shop metrics?</div>
                    </div>
                </div>
                <ul class="bgcolor-white margin-bottom-15">
                    <li>
                        <div class="infoTile">
                            <strong>Avg. SR (Sales) <i matTooltip="Grand Total-Tax/Total SR" mat-tooltip-panel-above class="tooltip-status fa fa-question" aria-hidden="true"></i></strong>
                            <p class="margin-bottom-0">{{reportData.avg_sr_sales | currency:'USD':'symbol-narrow' }}</p>
                        </div>
                    </li>
                    <li>
                        <div class="infoTile">
                            <strong>Avg. SR (Profit) <i matTooltip="Total Sale(Exclude tax)-Cost/Total SR" mat-tooltip-panel-above class="tooltip-status fa fa-question" aria-hidden="true"></i></strong>
                            <p class="margin-bottom-0"> {{reportData.avg_sr_profit | currency:'USD':'symbol-narrow' }}</p>
                        </div>
                    </li>
                    <li>
                        <div class="infoTile">
                            <strong>Avg. SR (Profit Margin) <i matTooltip="Divide GP by Revenue,multiply by 100" mat-tooltip-panel-above class="tooltip-status fa fa-question" aria-hidden="true"></i></strong>
                            <p class="margin-bottom-0">{{reportData.avg_sr_profit_margin}}%</p>
                        </div>
                    </li>
                    <li>
                        <div class="infoTile">
                            <strong>Gross Sales <i matTooltip="(Total Sales-Taxes)/No. of Labor/Hours Sold" mat-tooltip-panel-above class="tooltip-status fa fa-question" aria-hidden="true"></i></strong>
                            <p class="margin-bottom-0">{{reportData.gross_sales | currency:'USD':'symbol-narrow' }} / hour</p>
                        </div>
                    </li>
                    <li>
                        <div class="infoTile">
                            <strong>Gross Profit <i matTooltip="Gross profit for date range / hours open in date range / salaried employees working that day" mat-tooltip-panel-above class="tooltip-status fa fa-question" aria-hidden="true"></i></strong>
                            <p class="margin-bottom-0"> {{reportData.gross_profit | currency:'USD':'symbol-narrow' }} / hour</p>
                        </div>
                    </li>
                </ul>
    
                <div class="row margin-bottom-15">
                    <div class="col-md-5 col-sm-12 SR-summary">
                        <div class="help-block">SR Summary</div>
                        <div class="bgcolor-white" style="width:100%; padding:0 !important;">
                            <table class="table table-striped" *ngIf="(reportData | json) != ({} | json)" >
                                <thead role="rowgroup">
                                    <tr role="row">
                                        <th role="columnheader">Metric</th>
                                        <th role="columnheader">Amount</th>
                                    </tr>
                                </thead>
                               <tbody role="rowgroup">
                                    <tr role="row">
                                        <td role="cell">Sales</td>
                                        <td role="cell"><b>{{ reportData.total_revenue | currency:'USD':'symbol-narrow' }}</b></td>
                                    </tr>
                                    <tr role="row">
                                        <td role="cell">Taxes Collected</td>
                                        <td role="cell"><b>{{ reportData.taxable_amount | currency:'USD':'symbol-narrow' }}</b></td>
                                    </tr>
                                    <tr role="row">
                                        <td role="cell">Discount</td>
                                        <td role="cell"><b>{{ reportData.total_discount | currency:'USD':'symbol-narrow'}}</b></td>
                                    </tr>
                                    <tr role="row">
                                        <td role="cell">Supply Charges</td>
                                        <td role="cell"><b>{{ reportData.supplies_charges | currency:'USD':'symbol-narrow' }}</b></td>
                                    </tr>
                                    <tr role="row" class="total" >
                                        <td role="cell" class="total-text"><b>Total</b></td>
                                        <td role="cell" class="total-amount"><b>{{ totalSummary(reportData.total_revenue,reportData.taxable_amount,reportData.total_discount,reportData.supplies_charges) | currency:'USD':'symbol-narrow'}}</b></td>
                                    </tr>
                               </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-md-7 col-sm-12 SR-summary">
                        <div class="help-block">Sales by payment methods</div>
                        <div class="bgcolor-white" style="width: 100%;padding:0 !important;">
                            <table class="table table-striped" *ngIf="(reportData | json) != ({} | json)" >
                                <thead>
                                    <tr>
                                        <th>Payment Methods</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tr>
                                    <td>Cash</td>
                                    <td><b>{{reportData.payment_method.cash | currency:'USD':'symbol-narrow' }}</b></td>
                                </tr>
                                <tr>
                                    <td>Cheque</td>
                                    <td><b>{{reportData.payment_method.cheque | currency:'USD':'symbol-narrow'}}</b></td>
                                </tr>
                                <tr>
                                    <td>Credit Card</td>
                                    <td><b>{{reportData.payment_method.credit_card | currency:'USD':'symbol-narrow'}}</b></td>
                                </tr>
                                <tr>
                                    <td>Other</td>
                                    <td><b>{{reportData.payment_method.other | currency:'USD':'symbol-narrow'}}</b></td>
                                </tr>
                                <tr class="total">
                                    <td role="cell" class="total-text-in-desktop"><b>Total</b></td>
                                    <td><b>{{ totalPaymentMethod(reportData.payment_method.cash,reportData.payment_method.cheque,reportData.payment_method.credit_card,reportData.payment_method.other) | currency:'USD':'symbol-narrow' }}</b></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row margin-bottom-15">
                    <div class="col-md-7 col-sm-12 sales-after-discounts">
                        <div class="help-block">Sales after discounts</div>
                        <div class="bgcolor-white" style="width: 100%;padding:0 !important;">
                            <table class="table table-striped bs-table" *ngIf="(reportData | json) != ({} | json)" >
                                <thead>
                                    <tr>
                                        <th>Metric</th>
                                        <th>Cost</th>
                                        <th>Sold</th>
                                        <th>Difference</th>
                                    </tr>
                                </thead>
                                <tr>
                                    <td>Labor</td>
                                    <td><b>{{reportData.labor.cost | currency:'USD':'symbol-narrow' }}</b></td>
                                    <td><b>{{reportData.labor.price | currency:'USD':'symbol-narrow' }}</b></td>
                                    <td><b>{{calDiff(reportData.labor.price,reportData.labor.cost)  | currency:'USD':'symbol-narrow'}}</b></td>
                                </tr>
                                <tr>
                                    <td>Parts</td>
                                    <td><b>{{reportData.parts.cost | currency:'USD':'symbol-narrow'}}</b></td>
                                    <td><b>{{reportData.parts.price | currency:'USD':'symbol-narrow'}}</b></td>
                                    <td><b>{{calDiff(reportData.parts.price,reportData.parts.cost) | currency:'USD':'symbol-narrow'}}</b></td>
                                </tr>
                                <tr>
                                    <td>Generic Jobs</td>
                                    <td><b>{{reportData.genric_job.cost | currency:'USD':'symbol-narrow'}}</b></td>
                                    <td><b>{{reportData.genric_job.price | currency:'USD':'symbol-narrow'}}</b></td>
                                    <td><b>{{calDiff(reportData.genric_job.price,reportData.genric_job.cost) | currency:'USD':'symbol-narrow'}}
                                    </b></td>
                                </tr>
                                <tr class="total" >
                                    <td role="cell" class="total-text-in-desktop"><b>Total</b></td>
                                    <td><b>{{ totalSaleDiscount(reportData.labor.cost,reportData.parts.cost,reportData.genric_job.cost) | currency:'USD':'symbol-narrow' }}</b></td>
                                    <td><b>{{ totalSaleDiscount(reportData.labor.price,reportData.parts.price,reportData.genric_job.price) | currency:'USD':'symbol-narrow'}}</b></td>
                                    <td><b>{{ totalSaleDiscount(calDiff(reportData.labor.price,reportData.labor.cost),calDiff(reportData.parts.price,reportData.parts.cost),calDiff(reportData.genric_job.price,reportData.genric_job.cost)) | currency:'USD':'symbol-narrow' }}</b></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-excluded-vehicles [excludedVehicles]="excludedVehicles"  (closeTab)="excludedOut($event)" *ngIf="excludedVehicleSideBar"></app-excluded-vehicles>
<app-add-excluded-vehicles [excludeVehicleList]="excludedVehicles" (closeTab)="addexcludedOut($event)" *ngIf="addExcludedVehicleSideBar"></app-add-excluded-vehicles>
<app-search-filter [data]="options" [type]="'business-metrics'" (closeTab)="searchFilterOutput($event)" *ngIf="searchFilterSideBar"></app-search-filter>