<div class="row margin-0 live-chat-wrapper" [class.display-none]="addNoneClass">
  <div class="component-popup">
    <div class="modal-header">
      <div class="row margin-0">
        <div class="col-sm-6 col-md-6 col-lg-6">
          <h4 class="modal-title pull-left margin-10 margin-left-15">Live Chat</h4>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6">
          <button type="button" class="close cross-btn" aria-label="Close" (click)="toggleState(3)">
            <span aria-hidden="true">&times;</span>
          </button>
          <button type="button" class="close" (click)="toggleState(2)" id="customer-live-chat-close-btn">
            <span aria-hidden="true">&minus;</span>
          </button>
        </div>
      </div>
    </div>
  </div>


  <div>
    <div class="col-md-3 first-col border-top" *ngIf="showChatContact">
      <app-customer-contacts [refreshConversation]="refreshConversationInLeft.asObservable()"
        [updateBadgeInChatView]="updateBadgeInChatView.asObservable()" [onMobileScreen]="!isDesktopViewActive"
        [class.display-none]="addNoneClass" (newSelectContact)="updateSelectedContactInfo($event)">
      </app-customer-contacts>
    </div>

    <!-- CHAT AREA STARTS -->
    <div [class.display-none]="addNoneClass" class="col-md-6 border-top padding-left-0 padding-right-0"
      [hidden]="!showChatArea">
      <app-chat-area [onMobileScreen]="!isDesktopViewActive" (BackToContact)="onBackToContact()"
        (refeshConversations)="refeshConversations($event)" (SelectContactForInfo)="contactViewInMobile()"
        (updateBadge)="updateBadge($event)" [conversation]="selectedConversation.asObservable()" [closeModalListener]="emitCloseModal.asObservable()">
      </app-chat-area>
    </div>
    <!-- CHAT AREA ENDS -->

    <div [class.display-none]="addNoneClass" class="col-md-3 third-col border-top" [hidden]="!showChatInfo">
      <app-chat-info-area [onMobileScreen]="!isDesktopViewActive" (backToChatArea)="onBackToChatArea()"
        [conversation]="selectedConversation.asObservable()">
      </app-chat-info-area>
    </div>
  </div>

</div>