import { Component, OnInit, Inject, ViewEncapsulation, Optional} from '@angular/core';
import { MAT_DIALOG_DATA,MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GlobalService } from '../../../../environments/global.service';
import { globalConstants } from '../../../../environments/globalConstants';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-change-customer-vehicle',
  templateUrl: './change-customer-vehicle.component.html',
  styleUrls: ['./change-customer-vehicle.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class ChangeCustomerVehicleComponent implements OnInit {
  public currentCustomerData: any;
  public createCustomerScreenState :any = 1;
  public currentLoginUser:any=null;
  public addNewCustomerForm: FormGroup; 
  public searchCustomerForm:FormGroup;
  public customersList:any= [];
  public showCustomerSearchError: boolean = false;
  public vehiclesList:any=[]
  public mobileMask =  globalConstants.phoneMask;
  public checkVehicleUpdate : boolean = false;
  public selectedVehicleInfo : any  =null;
  public isViaVehiclesSearch: any = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public globalService:GlobalService,
    public dialog: MatDialog, 
    private formBuilder: FormBuilder, 
    public dialogRef: MatDialogRef<ChangeCustomerVehicleComponent> 
    ) {
    this.currentLoginUser=this.globalService.getCurrentUser();
    this.currentCustomerData = data;
  }

  ngOnInit() {
    if(this.currentCustomerData.openForm == 'formVehicle'){
       this.customersList=[{
        customer_id: this.currentCustomerData.customerId,
        search_by:'0',
        search_keyword:''
       }];
       this.getCustomerVehicles(this.customersList[0]);  
    }
    this.addNewCustomerForm = this.formBuilder.group({
      first_name: ['',[Validators.required]],
      last_name: ['',[Validators.required]],
      mobile: ['',[Validators.required,Validators.pattern(globalConstants.MOBILE_REGEX)]],
      email: ['',[Validators.pattern(globalConstants.EMAIL_REGEX)]],
      mapping_status: ['1'],
      user_id:this.currentLoginUser.id
    })
    
    this.searchCustomerForm=this.formBuilder.group({
      last_name: [''],
      mobile: [''],
      plate: [''],
      contact: [''],
      user_id:this.currentLoginUser.id
    })
  }

  handleChangeEmailAddress(){
    let email=this.addNewCustomerForm.controls.email;
    if(this.addNewCustomerForm.value.email =='' ||email.hasError('pattern')){
    }else{
      var payload = "email=" + this.addNewCustomerForm.value.email.replaceAll('+','%2B'); 
      this.findCustomerViaEmailOrMobileNumber(payload);
    }
   
  }

  handleChangeMobileNumber(){
    let mobile=this.addNewCustomerForm.controls.mobile;
    if(mobile.hasError('required') || mobile.hasError('pattern')){
    }else{
      var payload = "mobile_phone=" + this.addNewCustomerForm.value.mobile;
      this.findCustomerViaEmailOrMobileNumber(payload);
    }
  }

  findCustomerViaEmailOrMobileNumber(payload) {
    this.globalService.callAPI(globalConstants.API_CHECK_CUSTOMER_INFORMATION_URL, payload).subscribe((res: any) => {
      if (res.result == 1) {
        this.createCustomerScreenState = 3;
        let payload ={
           customer_id : res.customer_id,
           search_by:'0',
           search_keyword:''
        }
        this.getCustomerVehicles(payload);
      }
    })
  }

  findCustomer(data:any){
    let payload=this.globalService.formsData(data);
    this.globalService.formData(globalConstants.API_QUICKER_CUSTOMER_SEARCH, payload).subscribe((response:any) => {
        if (response.result == 1) {
          this.customersList = response.customer;        
          this.createCustomerScreenState = 3;
          if(Array.isArray(this.customersList) && this.customersList.length ==1){
            this.getCustomerVehicles(
              {
              customer_id:this.customersList[0].id,
              search_by:'0',
              search_keyword:''
              }
            );
          }
        }else{
          this.globalService.snackbar('error',response.message);
        }
    })
  }
  /** search customer */
  search() {
    if (this.searchCustomerForm.valid && (this.searchCustomerForm.value.mobile || this.searchCustomerForm.value.last_name || this.searchCustomerForm.value.plate || this.searchCustomerForm.value.contact)) {
      this.findCustomer(this.searchCustomerForm.value);
    }else{
        this.showCustomerSearchError = true;
    }
  }
  
  /* Get Customer Vehicles */
  getCustomerVehicles(customerData,addBlankInfo=false){
    if (addBlankInfo) {
      var payload = "user_id=" + this.currentLoginUser.id + "&user_type=" + this.currentLoginUser.user_type + "&customerId=" + customerData.id + "&search_by=" + '' + "&search_keyword=" + '';
    } else {
      var payload = "user_id=" + this.currentLoginUser.id + "&user_type=" + this.currentLoginUser.user_type + "&customerId=" + customerData.customer_id + "&search_by=" + customerData.search_by + "&search_keyword=" + customerData.search_keyword;
    }
    this.globalService.callAPI(globalConstants.API_QUICKER_CUSTOMER_SEARCH, payload).subscribe((response:any) => {
      if (response.result == 1) {
        this.customersList = response.customer ;
        if(this.customersList[0].id == this.currentCustomerData.customerId){
            this.customersList[0].vehicleId = this.currentCustomerData.vehicleId;
        }
        setTimeout(() => {
          this.vehiclesList  = response.vehicles;
          this.checkVehicleUpdate = true;      
          this.createCustomerScreenState = 3;
        }, 100);
         
        console.log("this is vehicles",this.vehiclesList);
        
      } else {
        this.checkVehicleUpdate = true;
        this.customersList =[customerData];
        this.vehiclesList = [];
      }
    })
  }

  /** add new customer */
  addNewCustomer(){
    if(this.addNewCustomerForm.valid){
      let payload=this.globalService.formsData(this.addNewCustomerForm.value);
      this.globalService.formData(globalConstants.API_QUICKAPPT_ADD_CUSTOMER,payload).subscribe((response:any)=>{
        if(response.result==1){
          this.customersList=(typeof response.data !='undefined' ? response.data : response.customer);
          this.checkVehicleUpdate = true; 
          this.globalService.snackbar("success", response.message);
          this.createCustomerScreenState=3;
        }else{
          this.globalService.snackbar("error", response.message);
        }
      })
    }
  }

  goToCustomerSearchScreen(sectionType){
   this.vehiclesList     = [];
   this.customersList    = [];
   this.createCustomerScreenState=sectionType;
   this.searchCustomerForm.reset();
   this.addNewCustomerForm.reset();
   this.searchCustomerForm.controls.user_id.setValue(this.currentLoginUser.id);
   this.addNewCustomerForm.controls.user_id.setValue(this.currentLoginUser.id);
   this.checkVehicleUpdate = false; 
   this.selectedVehicleInfo = null; 
   this.showCustomerSearchError = false;  
  }

  isAllowed(module,action){
    return this.globalService.getAcl(module,action);
  }

  handleVehicleSection(vehicleInfo){ 
      if(vehicleInfo !== null){
        if(vehicleInfo.id !==this.currentCustomerData.vehicleId){
          this.selectedVehicleInfo = vehicleInfo;
        }
      }else{
        this.selectedVehicleInfo = null;
      }
  }

  handleCustomerVehicleConfirm(){             
        let payload = {
            user_id: this.currentLoginUser.id,
            user_type: this.currentLoginUser.user_type,
            customer_id : this.customersList[0].id,
            vehicle_id : this.selectedVehicleInfo.id,
            request_id : this.currentCustomerData.serviceRequestId
        }
        this.globalService.callAPI(globalConstants.API_CHANGE_CUSTOMER_VEHICLE_IN_SR,payload).subscribe((response:any) =>{
             if(response.result == '1'){
              this.dialogRef.close({
                status:"success",
              });
             }else{
                 this.globalService.snackbar("error",response.message);
             }
        })
  }

  handleAddOrSearchCustomerScreen(screenType:any){
      this.goToCustomerSearchScreen(screenType);
      this.createCustomerScreenState = screenType;
  }

  handleVehicleSearch(event) {
    let object = {
      customer_id: event.customer_id,
      search_by: event.search_by,
      search_keyword: event.search_keyword
    };
    this.isViaVehiclesSearch = true;
    this.getCustomerVehicles(object)
  }
}
