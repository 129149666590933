<div mat-dialog-title class="relative">
  Edit Internal Note
  <button mat-mini-fab mat-dialog-close class="close-modal notranslate mat-elevation-z0">
    <mat-icon aria-label="Example icon-button with a heart icon">close</mat-icon>
  </button>
</div>
<mat-dialog-content class="editnote-dialog">
  <div class="center-block" style="max-width: 800px;">
    <form class="form-horizontal form-control-normal" autocomplete="off" (ngSubmit)="editNotes(0)" [formGroup]="notes">
      <div class="display-full margin-bottom-5">
        <label class="col-sm-3 control-label"><strong>Title<span class="asterisk">*</span></strong></label>
        <div class="col-sm-9">
          <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
            <input matInput type="text" placeholder="Title" formControlName="title" class="form-control input-lg custom-form-input btn-block" />
            <mat-error *ngIf="notes.controls.title.hasError('required') ">
              This field cannot be empty
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="display-full margin-bottom-5">
        <label class="col-sm-3 control-label"><strong>Description<span class="asterisk">*</span></strong></label>
        <div class="col-sm-9 details-input">
          <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
            <textarea matInput placeholder="Description" rows="5" formControlName="description"></textarea>
            <mat-error *ngIf="notes.controls.description.hasError('required') ">
              This field cannot be empty
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="display-full datepicker-section">
        <label class="col-sm-3 control-label"><strong>Notification Date</strong></label>
        <div class="col-sm-9">
          <div class="vlio-datepicker relative">
            <dp-date-picker mode="daytime" placeholder="Choose Date i.e {{minDates}}" formControlName="notify_date"
              [config]="datePickerConfig" theme="dp-material dp-main" class="btn-block" #dayPicker></dp-date-picker>
            <button type="button" mat-icon-button (click)="dayPicker.api.open();">
              <mat-icon>date_range</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="display-full margin-bottom-5" *ngIf="isEmployee">
        <label class="col-sm-3 control-label"><strong>Status</strong></label>
        <div class="col-sm-9">
          <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
            <mat-select class="form-control input-lg custom-form-input" placeholder="Select status"
              formControlName="status" (change)="selectedEmps($event)">
              <mat-option value="0">Select status</mat-option>
              <mat-option value="1">Assigned</mat-option>
              <mat-option value="2">Resolved</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="display-full margin-bottom-5 uc-ngx-select" *ngIf="isEmployee">
        <label class="col-sm-3 control-label"><strong>Assign to</strong></label>
        <div class="col-sm-9 relative details-input">
          <ngx-select [defaultValue]="assignees" (remove)="getSelected($event)" [autoClearSearch]="true"
            class="display-full margin-bottom-20" formControlName="assignees" [multiple]="true" [items]="employes"
            placeholder="Select Employes"></ngx-select>
          <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
            <mat-error *ngIf="notes.controls.assignees.hasError('required')">
              This field cannot be empty
            </mat-error>
          </div>
        </div>
      </div>
      <div class="display-full margin-bottom-5">
        <label class="col-sm-3 control-label"><strong>Documents &amp; Images</strong></label>
        <div class="col-md-9">
          <mat-toolbar class="mat-elevation-z2 margin-bottom-15 upload-btn relative">
            <mat-icon class="margin-right-10">cloud_upload</mat-icon>
            Drag File Here
            <input type="file" id="addInternalNotesDoc" name="addInternalNotesDoc" (change)="changeListner($event)" />
            <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
          </mat-toolbar>
          <p style="margin:0px;font-size:14px;" class="help-block">Format(s) supported : {{FORMAT_SUPPORTED}}</p>
          <p style="margin:0px;font-size:14px;" class="help-block">Maximum size : 10MB</p>
          <ul class="list-inline margin-left-0 upload-image">
            <li *ngFor="let doc of noteImgDocs;let i=index" class="relative view-images">
              <a href="javascript:void(0);" class="img-set thumbnail relative pdf-img">
                <img class="show-image show-border" src="{{doc.src}}" *ngIf="doc.type==1">
                <span class="fa far fa-file-pdf fa-4x" *ngIf="doc.type==3"></span>
                <span class="fa far fa-file-word fa-4x" *ngIf="doc.type==4"></span>
                <div class="image-loader">
                  <mat-progress-spinner color="accent" mode="indeterminate" strokeWidth="2" diameter="30">
                  </mat-progress-spinner>
                </div>
                <i class="fa fa-trash remove-img" (click)="deleteImgDoc(i,1)"></i>
              </a>
            </li>
          </ul>
          <ul class="list-inline margin-left-0 upload-image" *ngIf="noteDetail && noteDetail.docs.length">
            <li *ngFor="let doc of noteDetail.docs;let i=index" class="relative view-images">
              <a href="javascript:void(0);" class="img-set thumbnail relative pdf-img">
                <img class="show-image show-border" src="{{imgPath}}{{noteDocPath}}thumb/{{doc.filename}}"
                  *ngIf="doc.doc_type==1">
                <span class="fa far fa-file-pdf fa-4x" *ngIf="doc.doc_type==3"></span>
                <span class="fa far fa-file-word fa-4x" *ngIf="doc.doc_type==4"></span>
                <div class="image-loader">
                  <mat-progress-spinner color="accent" mode="indeterminate" strokeWidth="2" diameter="30">
                  </mat-progress-spinner>
                </div>
                <i class="fa fa-trash remove-img" (click)="deleteImgDoc(i,2,doc)"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="row hide">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 form-block submit-block">
          <button type="submit" mat-raised-button
            class="car-btn btn-block text-uppercase btn-lg font-light fontcolor-white" color="accent" title="Submit"
            style="max-width: 300px;">
            SUBMIT
          </button>
        </div>
      </div>
    </form>

  </div>
</mat-dialog-content>
<mat-dialog-actions class="inquiry-action relative flexBtn3">
  <button mat-raised-button (click)="editNotes(1)" class="font-bold mat-raised-button" color="accent" title="Submit & Close">Submit & Close</button>
  <button mat-raised-button (click)="editNotes(2)" class="font-bold mat-raised-button" color="accent" title="Submit & View">Submit & View</button>
  <button mat-raised-button mat-dialog-close class="font-bold" title="Close">Close</button>
</mat-dialog-actions>