<div mat-dialog-title class="relative qq-dialog-title">
  <span class="title-span">Quick Quote Creation</span>
  <button mat-mini-fab (click)="qqClose()" class="close-modal mat-elevation-z0">
    <mat-icon class="notranslate" aria-label="Example icon-button with a heart icon">close</mat-icon>
  </button>
</div>
<mat-dialog-content class="quickQuote-creation">
  <ng-container *ngTemplateOutlet="quickQuoteCreationTemplate"></ng-container>
</mat-dialog-content>
<mat-dialog-actions class="inquiry-action relative hide">
  <div class="text-right">
    <button type="button" mat-raised-button mat-dialog-close class="font-bold" title="Close">Close</button>
  </div>
</mat-dialog-actions>

<ng-template #quickQuoteCreationTemplate>
   <!-- remove when doing code clean up start -->
  <!-- <div class="panel panel-primary account-activation" *ngIf="!isShopDataAssigned">
    <div class="panel-body bgcolor-lightgrey">
      <div class="col-sm-12 text-green">
        <h2 class="title">Account Activation is In Progress</h2>
        <p>{{shopDataNote}}</p>
        <button type="button" mat-raised-button mat-dialog-close class="font-bold mat-accent"
          title="Close">Close</button>
      </div>
    </div>
  </div> -->
  <!-- end -->

  <div class="panel panel-primary qq-customerSelection" *ngIf="isShopDataAssigned">
    <div class="panel-heading">
      <h3 class="panel-title">
        Customer Selection
      </h3>
    </div>
    <div class="panel-body bgcolor-lightgrey">
      <div class="row" *ngIf="customer">
        <div class="col-sm-12 info-view-wrap">
          <div class="edit-customer-btn-wrap text-right">
            <button mat-button title="Edit Customer Profile"
              class="btn mat-elevation-z0 mat-raised-button mat-accent edit-customer-btn"
              *ngIf="gs.getAcl('customer','edits')  && customer.mapping_status == '1'" (click)="editCustomer(customer)">
              <span class="hidden-xs">
                Edit
              </span>
              <span class="hidden-lg hidden-sm hidden-md">
                <i class="fa fa-pencil"></i>
              </span>
            </button>
          </div>
          <span class="info-view"> <span>Name </span>:
            <span>
              {{customer.first_name}} {{customer.last_name}}
            </span>
          </span>
          <span class="info-view"><span>
              Email
            </span>:
            <span>
              <a class="text-black" href="mailto:{{customer.email}}">{{customer.email}}</a>
            </span>

          </span>
          <span class="info-view">
            <span>
              Mobile
            </span>:
            <span>
              <a class="text-black" href="tel:{{customer.mobile_phone}}">{{customer.mobile_phone !="" ?
                customer.mobile_phone : "-"}}</a>
            </span>
          </span>
        </div>
      </div>
      <div class="row" *ngIf="(!customer && !editMode)">
        <div class="col-lg-12 col-sm-12 col-md-6 search-wrap" *ngIf="searchOrNew">
          <div class="wrap">
            <h4>
              Search Existing Customer:
            </h4>
            <form [formGroup]="srchForm" (submit)="search()" novalidate method="POST" enctype="multipart/form-data"
              autocomplete="off">
              <div class="form-group col-sm-12 ">
                <div class="field-wrap">
                  <label>Email</label>
                  <mat-form-field class="car-input " floatPlaceholder="never" hideRequiredMarker="false" appearance="fill">

                    <div class="relative">
                      <input matInput placeholder="Email" title="Customer Email"
                        class="form-control input-lg custom-form-input" formControlName="email" maxlength="200"/>
                    </div>
                    <mat-error *ngIf="srchForm.controls.email.hasError('pattern')">
                      Please enter a valid email address
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <span class="col-sm-12 or">
                OR
              </span>
              <div class="form-group col-sm-12">
                <div class="field-wrap">
                  <label>Mobile No.</label>
                  <mat-form-field class="car-input " floatPlaceholder="never" hideRequiredMarker="false" appearance="fill">
                    <div class="relative">
                      <input matInput placeholder="Mobile No." title="Customer Mobile No."
                        [textMask]="{mask: mobileMask}" (keyup)="phoneValidation($event,'mobile',true)"
                        formControlName="mobile" class="form-control input-lg custom-form-input" />
                    </div>
                    <mat-error *ngIf="srchForm.controls.mobile.hasError('mobile')">
                      Invalid phone number
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="btn-wrap">
                    <button type="submit" [disabled]="submit"
                      class="font-bold search-btn mat-elevation-z0 mat-raised-button mat-accent"
                      title="Submit">Submit</button>
                    <button type="button"
                      class="font-bold new-customer-btn mat-elevation-z0 mat-raised-button mat-success"
                      (click)="searchOrNew=false" title="Add New Customer">Add New Customer</button>
                    <button *ngIf="openChatWindow" type="button"
                      class="font-bold new-customer-btn mat-elevation-z0 mat-raised-button mat-success"
                      (click)="supportRequest()" title="Open Support Request">Open Support Request</button>
                  </div>
                </div>
              </div>
            </form>

          </div>
        </div>
        <div class="col-lg-12 col-sm-12 col-md-6 customer-wrap" *ngIf="!searchOrNew">
          <div class="wrap">
            <h4>
              Add New Customer:
            </h4>
            <form (ngSubmit)="addNewCustomer()" [formGroup]="newCustomer" method="POST" enctype="multipart/form-data"
              autocomplete="off">
              <div class="form-group col-sm-6 col-md-6">
                <label>First Name</label>

                <mat-form-field class="car-input " floatPlaceholder="never" hideRequiredMarker="false" appearance="fill">
                  <div class="relative">
                    <input matInput placeholder="First Name*" title="First Name" formControlName="first_name"
                      class="form-control input-lg custom-form-input" maxlength="100"/>
                  </div>
                  <mat-error *ngIf="newCustomer.controls.first_name.hasError('required')">
                    This field cannot be empty
                  </mat-error>
                </mat-form-field>
              </div>


              <div class="form-group col-sm-6 col-md-6">
                <label>Last Name</label>
                <mat-form-field class="car-input " floatPlaceholder="never" hideRequiredMarker="false" appearance="fill">
                  <div class="relative">
                    <input matInput placeholder="Last Name*" title="Last Name" formControlName="last_name"
                      class="form-control input-lg custom-form-input" maxlength="100"/>
                  </div>
                  <mat-error *ngIf="newCustomer.controls.last_name.hasError('required')">
                    This field cannot be empty
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="form-group col-sm-6 col-md-6">
                <label>Email Address</label>
                <mat-form-field class="car-input " floatPlaceholder="never" hideRequiredMarker="false" appearance="fill">
                  <div class="relative">
                    <input matInput placeholder="Email Address* (Optional)" title="Email" formControlName="email"
                      class="form-control input-lg custom-form-input"  (blur)="checkEmail()" maxlength="200"/>
                  </div>
                  <mat-error *ngIf="newCustomer.controls.email.hasError('pattern') && !newCustomer.controls.email.hasError('required')">
                    Please enter a valid email address
                  </mat-error>
                </mat-form-field>
                <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper" *ngIf="checkUserEmail && !newCustomer.controls.email.hasError('required') && !newCustomer.controls.email.hasError('pattern')">
                  <div class="ng-tns-c12-29 ng-trigger ng-trigger-transitionMessages"
                    style="opacity: 1; transform: translateY(0%);">
                    <p class="mat-error ng-tns-c11-28" role="alert">
                      Email is <strong>already exist. </strong> <a href="javascript:void(0)" *ngIf="!isAutoGeneratedEmail"
                        (click)="customerInformationPage(getEmailCheck.customer_id,'email')" target="_self">Click Here.</a>
                    </p>
                  </div>
                </div>
              </div>

              <div class="form-group col-sm-6 col-md-6">
                <label>Mobile No.</label>
                <mat-form-field class="car-input " floatPlaceholder="never" hideRequiredMarker="false" appearance="fill">
                  <div class="relative">
                    <input matInput placeholder="Mobile No.*" title="Mobile No." formControlName="mobile"
                      [textMask]="{mask: mobileMask}"  (blur)="checkMobilePhone()" 
                      class="form-control input-lg custom-form-input" />
                  </div>
                  <mat-error *ngIf="newCustomer.controls.mobile.hasError('required')">
                    This field cannot be empty
                  </mat-error>
                  <mat-error *ngIf="newCustomer.controls.mobile.hasError('minLength') && !newCustomer.controls.mobile.hasError('required')">
                    Invalid phone number
                  </mat-error>
                </mat-form-field>
                <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper" *ngIf="checkUserMobile && !newCustomer.controls.mobile.hasError('minLength') && !newCustomer.controls.mobile.hasError('required')">
                  <div class="ng-tns-c12-29 ng-trigger ng-trigger-transitionMessages"
                    style="opacity: 1; transform: translateY(0%);">
                    <p class="mat-error ng-tns-c11-28 large-text" role="alert">
                      Mobile No. is <strong>already exist. </strong> <a href="javascript:void(0)"
                        (click)="customerInformationPage(getMobileCheck.customer_id,'mobile')" target="_self">Click Here.</a>
                    </p>
                  </div>
                </div>
              </div>


              <div class="row">
                <div class="col-sm-12">
                  <div class="btn-wrap">
                    <button type="button" class="font-bold mat-elevation-z0 mat-raised-button mat-accent"
                      (click)="addNewCustomer()" [disabled]="!this.newCustomer.valid || checkUserEmail || checkUserMobile" title="Submit">Submit</button>
                    <button type="button" class="font-bold search-btn mat-elevation-z0 mat-raised-button mat-success"
                      (click)="searchOrNew=true" title="Search Customer">Search Customer</button>
                    <button *ngIf="openChatWindow" type="button"
                      class="font-bold new-customer-btn mat-elevation-z0 mat-raised-button mat-success"
                      (click)="supportRequest()" title="Open Support Request">Open Support Request</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="panel panel-primary" *ngIf="isShopDataAssigned">
    <div class="panel-heading">
      <h3 class="panel-title view-title">
        Vehicle Selection & Notes
      </h3>
      <div class="btn-wrap" style="display: flex;">

        <button mat-button *ngIf="(seletedCar && seletedCar.id) && (!editMode)" title="View All Vehicle"
          (click)="changeCar()" class="btn fafa-list btn-list list-action-btn" style="padding-left: 0;">
          <span class="hidden-xs">
            View All
          </span>
          <span class="hidden-lg hidden-sm hidden-md">
            <i class="fa fa-list-ul"></i>
          </span>
        </button>
        <button mat-button *ngIf="(!editMode)" class="list-action-btn top-right-radius" color="accent" title="Add Vehicle"
          [matMenuTriggerFor]="menu" disableRipple>
          <span class="hidden-xs">
            Add Vehicle
          </span>
          <span class="hidden-lg hidden-sm hidden-md">
            <i class="fa fa-car"></i>
          </span>
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
        <mat-menu #menu="matMenu" class="car-icon-menu disable-no-bg" xPosition="before" [overlapTrigger]="false">
          <button class="mat-menu-item quote-dropdown" (click)="vinScan()" title="I have VIN Number" disableRipple
            type="button"> <i class="fa fa-barcode fa-lg margin-right-5"></i>I have VIN Number</button>
          <button class="mat-menu-item quote-dropdown" (click)="plateScan()" title="I have License Plate" disableRipple
            type="button"> <i class="fa fa-car fa-lg margin-right-5"></i>I have License Plate</button>
          <button class="mat-menu-item quote-dropdown" (click)="addNewCar()" title="Make/Year/Model" disableRipple
            type="button"> <i class="fa far fa-calendar-check margin-right-5"></i>Make/Year/Model</button>
        </mat-menu>
      </div>


    </div>
    <div class="panel-body bgcolor-lightgrey">
      <div class="list-wrap" *ngIf="vechile.length" [class.hide]="addVehicle">
        <div class="alert alert-warning" [class.hide]="seletedCar && seletedCar.id!=''">
          <p>Note: Please select a vehicle to continue.</p>
        </div>
        <div class="list-group" *ngFor="let car of vechile" [class.hide]="seletedCar && seletedCar.id!=car.id"
          [class.single-list-group]="(seletedCar && seletedCar.id==car.id)" [class.margin-bottom-15]="(!seletedCar)">
          <div class="list-group-item relative" (click)="selectVechile(car)"
            [class.border-color-dark]="seletedCar && car.id==seletedCar.id">
            <div class="row list-row">
              <div class="col-xs-5 col-sm-2">
                <img class="img-responsive" src="{{imgPath}}default-car.png"
                  *ngIf="car.vehicle_pic == '' || car.vehicle_pic == null">
                <img class="img-responsive" src="{{imgPath}}{{car.vehicle_pic}}"
                  *ngIf="car.vehicle_pic != '' && car.vehicle_pic != null">
              </div>
              <div class="col-xs-7 col-sm-10">
                <h2 class="car-name">
                  {{car.car_name}} &nbsp;&nbsp;
                  <mat-chip color="primary" selected="true" *ngIf="car && customer && car.customer_id != customer.id">Temp</mat-chip>
                  <mat-chip color="primary" selected="true"
                  *ngIf="car.is_walkin_customer_vehicle == '1'">Walkin</mat-chip>
                </h2>
                <p class="car-info" style="text-transform:uppercase;"><span
                    [innerHTML]="car.license_plate ? car.license_plate : 'N/A' "></span> <span
                    *ngIf="car.unit_number">({{car.unit_number}})</span></p>
                <p class="car-info" [innerHTML]="car.vin ? car.vin : 'N/A' "></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row margin-bottom-0 margin-top-10" [class.hide]="!(seletedCar && seletedCar.id!='')">
        <div class="col-sm-12 hide">
          <div class="form-group">
            <label class="col-sm-13 col-lg-12 control-label padding-0">Notes:</label>
            <div class="col-sm-12 notes-input padding-0">
              <mat-form-field class="car-input btn-block note-input" floatPlaceholder="never" appearance="fill">
                <textarea matInput placeholder="Notes" title="Notes" rows="5"></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <!-- Car Details Start -->
      <div class="row bgcolor-lightgrey" style="overflow-x:auto;" style="display: none;">
        <div class="container-fluid">
          <form class="form-inline col-xs-12 signup-form" role="form" method="POST">

            <table class="table table-responsive">
              <tbody>
                <tr>
                  <td colspan="2"><label for="make">Make</label></td>
                  <td>
                    <p class="pull-left" id="make"></p>
                  </td>
                  <td><label for="year">Year</label></td>
                  <td>
                    <p class="pull-left" id="year"></p>
                  </td>
                </tr>
                <tr>
                  <td colspan="2"><label for="model">Model</label></td>
                  <td>
                    <p class="pull-left" id="model"></p>
                  </td>
                  <td><label for="subModel">Engine Size</label></td>
                  <td>
                    <p class="pull-left" id="subModel"></p>
                  </td>
                </tr>
                <tr>
                  <td colspan="2"><label for="vin">Vin</label></td>
                  <td>
                    <p class="pull-left" id="vin" data-toggle="tooltip"></p>
                  </td>
                  <td><label for="productionDate">Production Date</label></td>
                  <td>
                    <p class="pull-left" id="productionDate"></p>
                  </td>
                </tr>
                <tr>
                  <td colspan="2"><label for="distance">Mileage</label></td>
                  <td>
                    <p class="pull-left" id="distance"></p>
                  </td>
                  <td><label for="licensePlate">License Plate</label></td>
                  <td>
                    <p class="pull-left" id="licensePlate" style="text-transform:uppercase;"></p>
                  </td>
                </tr>

                <tr>
                  <td colspan="2"><label for="color">State</label></td>
                  <td>
                    <p class="pull-left" id="color"></p>
                  </td>
                  <td><label for="color">Color</label></td>
                  <td>
                    <p class="" id="color"></p>
                  </td>
                </tr>

                <tr>
                  <td colspan="2"><label for="unitNumber">Unit Number</label></td>
                  <td>
                    <p class="pull-left" id="unitNumber"></p>
                  </td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <!--<tr>
                                <td colspan="2"><label for="color">Color</label></td>
                                <td><p class="pull-left" id="color">{{car.value.color ? car.value.color : '-'}}</p></td>
                                <td><label for="unitNumber">Unit Number</label></td>
                                <td><p class="" id="unitNumber" data-validation="[OPTIONAL]">{{car.value.unit_number ? car.value.unit_number : '-'}}</p></td>
                            </tr>-->
                <tr>
                  <td colspan="2"><label for="driver">Driver</label></td>
                  <td>
                    <p class="pull-left" id="driver"></p>
                  </td>
                  <td><label for="driverPhoneNumber">Driver Phone Number</label></td>
                  <td>
                    <p class="pull-left" id="driverPhoneNumber"></p>
                  </td>
                </tr>
                <tr>
                  <td colspan="2"><label for="insuranceExpirationDate">Insurance Expiration Date</label></td>
                  <td>
                    <p class="pull-left"></p>
                  </td>
                  <td><label for="registrationExpirationDate">Registration Expiration Date</label></td>
                  <td>
                    <p class="pull-left"></p>
                  </td>
                </tr>
                <tr>
                  <td colspan="3"><label for="vehicleRegPic">Vehicle Registration Photo</label></td>
                  <td colspan="3"><label for="vehicleInsPic">Vehicle Insurance Card Photo</label></td>
                </tr>
                <tr>
                  <td colspan="3">
                    <div class="pull-left" id="vehicleRegPicView">
                      <ul id="vehicle_pic-ul" class="list-inline margin-bottom-0">
                        <li class="relative view-images">
                          <a href="javascript:void(0);" class="thumbnail">
                            <img class="show-image" src="" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td colspan="3">
                    <div class="pull-left" id="vehicleInsPicView">
                      <ul id="vehicle_insured_pic-ul" class="list-inline margin-bottom-0">
                        <li class="relative view-images">
                          <a href="javascript:void(0);" class="thumbnail">
                            <img class="show-image" src="">
                          </a>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="3"><label for="vehiclePic">Vehicle Photo(s)</label></td>
                  <td colspan="3"><label for="vehicleVideo">Vehicle Video(s)</label></td>
                </tr>
                <tr>
                  <td colspan="3">
                    <div class="pull-left" id="vehiclePicView">
                      <ul id="vehicle_pic-ul" class="list-inline margin-bottom-0">
                        <li class="relative view-images">
                          <a href="javascript:void(0);" class="thumbnail">
                            <img class="show-image" src="" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td colspan="3">
                    <div class="pull-left" id="vehicleVidView">
                      <ul id="vehicle_video-ul" class="list-inline margin-bottom-0">
                        <li class="video-upload relative">
                          <a href="javascript:void(0)" class="thumbnail margin-bottom-0">
                            <span class="relative video-bg">
                              <mat-icon class="position-center play-video">play_circle_filled</mat-icon>
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="3"><label for="vehicleVinPic">Vehicle Vin Photo </label></td>
                </tr>
                <tr>
                  <td colspan="3">
                    <div class="pull-left" id="vehicleVinPicView">
                      <ul id="vin_photo-ul" class="list-inline margin-bottom-0">
                        <li class="relative view-images">
                          <a href="javascript:void(0);" class="thumbnail">
                            <img class="show-image" src="">
                          </a>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
      </div>
      <!-- Car Details End -->
      <div class="row" *ngIf="vechile.length==0 || addVehicle">
        <div class="col-sm-12">

          <p class="vehicle-txt text-center">
            No Vehicles Found.
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- Previous Quotes Start -->
  <app-qqprevious *ngIf="showPreviousQuote" [customer]="customer" [editMode]="param"
    [seletedCar]="seletedCar"></app-qqprevious>
  <!-- Previous Quotes End -->
  <app-addjobs *ngIf="customer && seletedCar" (isEditMode)="enableEdit($event)" [editMode]="param"
    [qqdialogRef]="dialogRef" [customer]="customer" [seletedCar]="seletedCar"></app-addjobs>
</ng-template>