import {Component, OnInit,OnDestroy ,Inject} from '@angular/core';
import {FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {globalConstants} from "../../../environments/globalConstants";
import {GlobalService} from "../../../environments/global.service";
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {AppComponent} from '../../app.component'
import { MessagingService } from '../../messaging.service';
import  * as $ from "jquery";
import * as moment from 'moment';

@Component({
    selector: 'app-shopdashboard',
    templateUrl: './shopdashboard.component.html',
    styleUrls: ['./shopdashboard.component.scss']
})
export class ShopdashboardComponent  implements OnInit,OnDestroy  {
    public shopStatistics: any={};
    public shopDetails: any={};
    public licensePlate = false;
    public easyCheckin: FormGroup;
    public submitted = false;
    public forgotPassword = false;
    public showLoginPanel = true;
    public loginError = false;
    public checkinValues:any;
    public idFromUrl:any;
    public user_type :any;
    public checkIn = false;
    public selectlists: any[];
    public currentCustomer:any;
    public isSubscribed:any;
    public subscriptionAmount:any;
    public expiryDate:any;
    public subscriptionPlanName:any;
    public planExpired:any;
    public subscriptionDesc:any;
    public subscriptionDuration:any;
    public subscriptionDurationType:any;
    public quick_quote:any;
    public shopID:any;
    public ccInfoResult:any;
    public ccMaskedNumber:any;
    public ccPaymentToken:any;
    public displayCCDetails:any;
    public roleID:any;
    public customerInfoList=[{id: 2,name: "License Plate"}];
    public openingTime='- to -';
    public closeingTime='-';
    public profileType:any=0;
    public prefCustomer:any=[];
    public isInternalNotes=false;
    public isServiceNotes=false;
    public showHideTxt='Show';
    public isQuickQuote=false
    public fleetSelectlists:any=[];
    public subscriptionDataForAPI: Subscription = new Subscription();
    public showPermissionBanner : boolean = false;
    public isPlanExpiredOrCancelled: any = 0;
    setParams() {
        this.subscriptionDataForAPI = this.activatedRoute.params.subscribe((params: Params) => {
            this.idFromUrl = params["shopname"];
        });
    }

    constructor(
         public app: AppComponent,
         private activatedRoute: ActivatedRoute,
         private formBuilder: FormBuilder,
         public titleService: Title,
         private router: Router, 
         private globalService: GlobalService, 
         public dialog: MatDialog,
         private msgService: MessagingService
         ) {
        this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
        if(this.currentCustomer) {
            this.shopID          = this.currentCustomer.shop_id;
            this.isSubscribed    = this.currentCustomer.is_subscribed;
            this.roleID          = this.currentCustomer.role_id;
            
            if(this.isSubscribed) {
                this.subscriptionAmount     = this.currentCustomer.subscription_data.amount;
                this.expiryDate             = this.currentCustomer.subscription_data.expiry_date;
                
                if (this.expiryDate!="0000-00-00 00:00:00") {
                    let currentDate = new Date();
                    if(new Date(currentDate) > new Date(this.expiryDate)) {
                        this.isPlanExpiredOrCancelled = 2;
                        this.expiryDate   = this.globalService.getFullFormateDate(this.currentCustomer.subscription_data.expiry_date, "GD", "")
                    } else {
                        this.isPlanExpiredOrCancelled = 0;
                        this.expiryDate  = this.globalService.getFullFormateDate(this.currentCustomer.subscription_data.expiry_date, "GD", "")
                    }
                }
                if(this.currentCustomer.subscription_data.order_status == '1'){
                    this.isPlanExpiredOrCancelled = 1;
                }
                
                this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_SHOP_SUBSCRIPTION_TYPES_URL, "opr=1&user_id=" + this.globalService.getCurrentUser().id).subscribe((data:any) => {
                    if (data.result == '1') {
                        if(data.data.length>0){
                            this.subscriptionPlanName = data.data[0].subscription_name;
                            this.subscriptionDesc = data.data[0].subscription_desc;
                            this.subscriptionDuration = data.data[0].duration;
                            this.quick_quote = data.data[0].quick_quote;
                            this.subscriptionDurationType = data.data[0].duration_type;
                        }
                    } 
                })
                /* GET CC DETAILS */
                const resquestdata = "user_id=" + this.globalService.getCurrentUser().id+ "&shop_id=" + this.globalService.getCurrentUser().shop_id+ "&user_type=" + this.globalService.getCurrentUser().user_type;
                this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_BT_CC_INFO_URL, resquestdata).subscribe((data:any) => {
                    if (data.result == '1') {
                        if(data.data!= null) {
                            this.ccInfoResult = data.data;
                            this.displayCCDetails = true;
                            this.ccMaskedNumber = this.ccInfoResult.maskedNumber;
                            this.ccPaymentToken = this.ccInfoResult.payment_token;
                        } else {
                            this.displayCCDetails = false;
                        }
                    } else {
                        this.displayCCDetails = false;
                    }
                });
            } 
            this.isQuickQuote = this.globalService.isSubscribe('quick_quote');
        }else{
            this.router.navigate(["shops"]);
        }

      this.globalService.setMetaData("SHOPS","DASHBOARD");
      this.app.showCommonHeader=true;
        this.setParams();
        let user=this.globalService.getCurrentUser();
        if(user){
        let userType = user.user_type;
        // this.app.getMenuForShops();
        this.easyCheckin = this.formBuilder.group({
            user_type: "User",
            user_id: this.globalService.getCurrentUser()["id"],
            start: 0,
            limit: 10,
            search_keyword: ['', Validators.required],
            search_by: [''],
            search_keyword2: ['' ]
        });
    
        this.selectlists = [
            {
                id: 1,
                name: "Last Name",
            },
            {
                id: 2,
                name: "License Plate",
            },
            {
                id: 3,
                name: "Phone Number(Last 4 digits)",
            },
            {
                id: 4,
                name: "Business Name",
            },           
            {
                id: 6,
                name: "Unit Number",
            },
            {
                id: 7,
                name: "Vin",
            }
        ]
        this.fleetSelectlists = [
            {
                id: 2,
                name: "License Plate",
            },
            {
                id: 4,
                name: "Department Name",
            },
            {
                id: 6,
                name: "Unit Number",
            },
            {
                id: 7,
                name: "Vin",
            }
        ]

        let data={user_id:this.currentCustomer.id,user_type:this.currentCustomer.user_type};
        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_SHOP_SETTINGS,data).subscribe((r:any)=>{
            if(r.result==1){
                localStorage.setItem('loggedInUser',JSON.stringify(r.data));
                this.checkForNotes();
                this.app.cdr.markForCheck();
            }
        },(error)=>{ this.checkForNotes() });
     }
    }
    toggleBlocks(){
        let eles=$('.link.col-sm-3').hasClass('collapse');
        if(eles){
            $('.link.col-sm-3').removeClass('collapse');
            this.showHideTxt='Hide'
        }else{
            $('.link.col-sm-3').addClass('collapse');
            this.showHideTxt='Show';
        }
    }

    /* REDIRECT TO SUBSCRIPTION PAGE */
    gotoShopSubscription() {
        this.router.navigate(['/shops/subscription']);
    }
    
    /* CANCEL SUBSCRIPTION */
    cancelSubscription(shop_id){

        this.globalService.confirmDialog(shop_id, 'Do you really want to cancel your subscription?').subscribe((res) => {
            if (res != null) {
                var stringifiedFormData = "user_type=" + this.currentCustomer.user_type + "&user_id=" + this.currentCustomer.id+ "&shop_id=" + shop_id;
                this.subscriptionDataForAPI =  this.globalService.callAPI(globalConstants.API_CANCEL_BT_SHOP_SUBSCRIPTION_URL, stringifiedFormData).subscribe(
                (data:any) => {
                    var message = data.message;
                    if (data.result == "1") {
                        this.isPlanExpiredOrCancelled = 2;
                        var addNewKey = JSON.parse(localStorage.getItem("loggedInUser"));
                        addNewKey.subscription_data.order_status = '1';
                        localStorage.setItem("loggedInUser", JSON.stringify(addNewKey));
                        this.globalService.snackbar('success', message);
                    } else {
                        this.globalService.snackbar("error", message);
                        this.isPlanExpiredOrCancelled = 0;
                    }
                });
            }
        });
    }
    
    /* UPDATE CC DETAILS */
    updateCCDetails(paymentToken){
        let dialogRef;
        dialogRef = this.dialog.open(updateCCDetail, {
            disableClose:true,
            panelClass: 'car-dialog-form'
            
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result.data != "" && result.data != "3"){
                this.ccMaskedNumber = result.data;
            }        
        });
    }
    
    checkKeywor2Validation(control: FormControl) {
        let search_keyword2 = control.value;
        return search_keyword2;
    }

    ngOnInit() {
            if(this.currentCustomer.role_id == '5'){
                this.router.navigate(['shops/self-checkin']);
            }
            const shopID = "user_id=" + this.globalService.getCurrentUser()["id"] + "&user_type=" + this.globalService.getCurrentUser()["user_type"] + "&shop_id=" + this.globalService.getCurrentUser()["shop_id"];
            this.statistics();
            this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_SHOP_DETAILS_ON_DASHBOARD, shopID).subscribe((res:any) => {
             if(res.result == "1"){
              this.shopDetails = res.data;
              this.globalService.setShopDetails(this.shopDetails);
              /*** store shop timezone for add customer */
              localStorage.setItem('shop_timezone', this.shopDetails.time_zone);
              //store some shop details for default values in add customer etc.
              localStorage.setItem('shop_state', this.shopDetails.state);
              localStorage.setItem('shop_country', this.shopDetails.country);
              localStorage.setItem('shop_country_Id', this.shopDetails.country_id);
              this.globalService.localGetterSetter('is_labor_tax_excluded',this.shopDetails.is_labor_tax_excluded,'');
                // verify latest uploaded build
              this.globalService.verifyBuildVersion('shop', this.shopDetails.web_app_version_shops);
              if (typeof this.shopDetails.shop_timing != 'undefined') {
                    let shopTiming = this.shopDetails.shop_timing;
                    if (shopTiming) {
                        let v = shopTiming.open_time.split(':');
                        let defaultOpenTime = (parseInt(v[0]) * 60) + parseInt(v[1]);
                        let close = shopTiming.close_time.split(':');
                        let defaultCloseTime = (parseInt(close[0]) * 60) + parseInt(close[1]);
                        this.shopTiming([defaultOpenTime, defaultCloseTime]);
                    }
                }
              }
            },(error)=>{});
          this.checkDeviceAndPermission();
    }

    checkDeviceAndPermission(){
        let isIPhoneDevice = this.msgService.checkDevice();
         if(isIPhoneDevice){
             console.log("This device doesn't support notifications.");
         }else{
           let permissionStatus = this.msgService.checkNotificationPermission();
           if(permissionStatus == 'granted' ){
               this.msgService.getFCMToken();
           }else if(permissionStatus == 'default'){
               this.showPermissionBanner = true;
           } else if(permissionStatus == 'denied'){
                this.showPermissionBanner = false;
           }
         }
    }

    askPermissionForNotifications(){
          this.msgService.getPermission();
          this.showPermissionBanner = false;
    }
    checkForNotes(){
        this.isInternalNotes= this.globalService.isSubscribe('internal_notes');
        this.isServiceNotes= this.globalService.isSubscribe('service_notes');
        this.checkIn=this.globalService.isAllowed(this.app,'check_in','addNew');
        if(this.currentCustomer && typeof this.currentCustomer.profile_type!='undefined'){
            this.profileType=this.currentCustomer.profile_type;
        }
        if(this.profileType==2){
            this.selectlists=this.fleetSelectlists;
        }
    }

    formEasyCheckIn() {
        this.globalService.validateState(this.easyCheckin, ['search_by']);
        if (this.easyCheckin.valid) {
            //var stringifiedFormData = globalFunctions.stringifyFormData(this.easyCheckin.value, null);
            localStorage.setItem("easyCheckinData", JSON.stringify(this.easyCheckin.value));
            localStorage.setItem("openFilter", "1");
            this.router.navigate(['shops/easy-check-in']);
        } else {
            this.submitted = true;
            //this.mandatoryMsg();
        }
    }

    mandatoryMsg() {
        this.globalService.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
    }

    checkIfValid() {
        if (this.easyCheckin.valid) {
            this.submitted = false;
        }
    }

    onSelectListChange(list) {
        if (list == 5) {
            this.licensePlate = true;
            //this.easyCheckin.controls["search_keyword2"].setValidators([Validators.required]);
            this.easyCheckin.get("search_keyword2").setValidators([Validators.required]);
        } else {
            this.licensePlate = false;
            this.easyCheckin.get("search_keyword2").clearValidators();
            this.easyCheckin.get("search_keyword2").updateValueAndValidity();
        }
    }

    editProfile() {
        this.router.navigate(['shops/edit-shop']);
    }

    getTotalTickets() {
        localStorage.setItem("shop-servicestatus", "");
        localStorage.setItem("openFilter", "0");
        this.router.navigate(['shops/list-service-requests']);
    }

    getNewRequests() {
        localStorage.setItem("shop-servicestatus", "11");
        localStorage.setItem("openFilter", "1");
        this.router.navigate(['shops/list-service-requests']);
    }

    getPendingTickets() {
        localStorage.setItem("shop-servicestatus", "1");
        localStorage.setItem("openFilter", "1");
        this.router.navigate(['shops/list-service-requests']);
    }

    getUnderProcessTickets() {
        localStorage.setItem("shop-servicestatus", "2");
        localStorage.setItem("openFilter", "1");
        this.router.navigate(['shops/list-service-requests']);
    }

    getAwaitedTickets(option?:any) {
        localStorage.setItem("shop-servicestatus", option);
        localStorage.setItem("openFilter", "1");
        this.router.navigate(['shops/list-service-requests']);
    }

    getCompletedTickets() {
        localStorage.setItem("shop-servicestatus", "8");
        localStorage.setItem("openFilter", "1");
        this.router.navigate(['shops/list-service-requests']);
    }

    getCompletedNotPaidTickets() {
        localStorage.setItem("shop-servicestatus", "6");
        this.router.navigate(['shops/list-service-requests']);
    }

    getFeedbacks() {
        localStorage.setItem("shop-servicestatus", "9");
        localStorage.setItem("shop-servicesearch_by", "");
        localStorage.setItem("openFilter", "1");
        this.router.navigate(['shops/list-service-requests']);
    }

    getAllFeedbacks() {
        localStorage.setItem("shop-servicestatus", "feedback");
        localStorage.setItem("openFilter", "1");
        this.router.navigate(['shops/list-service-requests']);
    }

    getComplaints() {
        localStorage.setItem("shop-service-complain-complaint_status", "");
        localStorage.setItem("openFilter", "0");
        this.router.navigate(['shops/manage-service-complaints']);
    }

    getUnreadComplaints() {
        localStorage.setItem("shop-service-complain-complaint_status", "4");
        localStorage.setItem("openFilter", "1");
        this.router.navigate(['shops/manage-service-complaints']);
    }

    getInquiries() {
        localStorage.setItem("shop-inquiries-inquiry_status", "");
        localStorage.setItem("openFilter", "0");
        this.router.navigate(['shops/list-shop-inquiries']);
    }

    getUnreadInquiries() {
        localStorage.setItem("shop-inquiries-inquiry_status", "3");
        localStorage.setItem("openFilter", "1");
        this.router.navigate(['shops/list-shop-inquiries']);
    }

    addNewCustomer() {
        this.router.navigate(['shops/add-customer']);
    }

    viewCustomers() {
        this.router.navigate(['shops/list-customers']);
    }

    addNewPart() {
        this.router.navigate(['shops/add-part']);
    }

    addGenericJobs() {
        this.router.navigate(['shops/new-generic-job']);
    }

    navigateToJobBoard(){
        this.router.navigate(['shops/boards/job-board'])
    }
    handleQuickSRCreation(){
        let payload = {
            user_id: this.currentCustomer.id,
            user_type: this.currentCustomer.user_type
          }
         this.globalService.callAPI(globalConstants.API_CREATE_QUICK_SERVICE_REQUEST, payload).subscribe((response: any) => {
            if (response.result == '1') {
              localStorage.setItem("msgForSnackBar",response.message);
              this.router.navigateByUrl("/shops/service-inspection-report/" + response.data.request_id);
            } else {
              this.globalService.snackbar("error", response.message);
            }
        })
    }
    getLoggedInUserDetail() {
        if (localStorage.getItem("loggedInUser") != null && localStorage.getItem("loggedInUser") != undefined) {
            return JSON.parse(localStorage.getItem("loggedInUser")).first_name;
        } else {
            return ""
        }
    }
    isAllowed(action,module?:any){
        if(typeof module!='undefined'){
            return this.globalService.isAllowed(this.app,module,action);
        }
        return false;
    }
    easyCheckInSelect():any[]{
        let viewCustomerInfo= this.globalService.isAllowed(this.app,'view_customer_info','views');
        let list =[];
        if(viewCustomerInfo==false){
            list = [{id : 2,name: "License Plate"}];
        }else{
            list = this.selectlists;
        }
        return list;
    }
/** Shop open close time  */
    shopTiming(event){

        let h:any = Math.floor(event[0] / 60);
        let m:any = Math.floor(event[0] % 60);
        if((m).toString().length<=1){
            m='0'+m;
        }
        if((h).toString().length<=1){
            h='0'+h;
        }
        let AMPM=' AM';
        if(h>=12){
            h=( h-12 ? h-12 : h);
            AMPM=' PM';
        }
        if((h).toString().length<=1){
            h='0'+h;
        }
        this.openingTime=h+':'+m+AMPM;
        
        let h1:any = Math.floor(event[1] / 60);
        let m1:any = Math.floor(event[1] % 60);
        if((m1).toString().length<=1){
            m1='0'+m1;
        }
        if((h1).toString().length<=1){
            h1='0'+h1;
        }
        let AMPM1=' AM';
        if(h1>=12){
            h1=( h1-12 ? h1-12 : h1);
            AMPM1=' PM';
        }
        if((h1).toString().length<=1){
            h1='0'+h1;
        }
        this.closeingTime=h1+':'+m1+AMPM1;
        this.openingTime=this.openingTime+' to '+ this.closeingTime;
    }
    getWorkInProgress() {
        localStorage.setItem("shop-servicejobStatus", "process");
        localStorage.setItem("openFilter", "1");
        this.router.navigate(['shops/list-service-requests']);
    }
    userTypeTechnician(){
        let user=this.globalService.getCurrentUser();
        if(user.role_id==globalConstants.ROLE_ID_TECHNICIAN){
            return true;
        }
        return false;
    }
    unreadAppt(){
        localStorage.setItem("shop-servicestatus", "13");
        localStorage.setItem("openFilter", "1");
        this.router.navigate(['shops/list-service-requests']);
    }
    /** open internal Notes */
    internalNotes(){
        this.router.navigate(['shops/internalnote/list']);
    }
    reporting(){
        this.router.navigate(['shops/reports-stats-servicerequests']);
    }
    newRequest(cus){
        // localStorage.setItem("customer_country", customer_country);
        localStorage.setItem("VinValues", '');
        this.router.navigate(['shops/new-service-request/'+cus.customer_id]);
    }
    /** add preferred customer car */
    addCar(cus){
        let param=cus.customer_id+','+cus.country_id+','+cus.state;
        localStorage.setItem("shopCustomerID", param);
        localStorage.setItem("VinValues", "");
        localStorage.setItem("VinNumber", "");
        this.router.navigate(['shops/add-car']);
    }
    /** goto car list */
    listCars(cus) {
        localStorage.setItem("listCustomerID", cus.customer_id);
        this.router.navigate(['shops/list-cars']);
    }
    /** view particualr customer service request */
    viewAllServiceRequests(cus) {
        localStorage.setItem("shop-servicesearch_keyword", cus.first_name+' '+cus.last_name);
        localStorage.setItem("customer_id", cus.customer_id);
        localStorage.setItem("openFilter", "1");
        this.router.navigate(['shops/list-service-requests']);
    }
    /** remove customer from preferred list */
    removeFav(row){
        this.globalService.confirmDialog("yes", "Are you sure?").subscribe((res) => {
            let r: any = res;
            if (r == "yes") {
                let data = { user_id: this.currentCustomer.id, user_type: this.currentCustomer.user_type, customer_id: row.customer_id, opr: 1 };
                this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_PREFERRED_CUSTOMERS, data).subscribe((r:any) => {
                    if (r.result == 1) {
                        this.statistics()
                        this.globalService.snackbar('success', r.message);
                    } else {
                        this.globalService.snackbar("error", r.message);
                    }
                }, (error) => { });
            }
        });
    }
    /** get dashboard statistics */
    statistics() {
        const content = "user_id=" + this.globalService.getCurrentUser()["id"] + "&user_type=" + this.globalService.getCurrentUser()["user_type"] + "&shop_id=" + this.globalService.getCurrentUser()["shop_id"];
        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_DASHBOARD_STATS_URL, content).subscribe((res:any) => {
            if (res.result == 1) { 
                this.shopStatistics = res.data;
                if (res.data && typeof res.data.preferred_customers != 'undefined') {
                    this.prefCustomer = res.data.preferred_customers
                }
            }
        }, (error) => { });
    }
    /** service based Notes */
    serviceNotes(){
        localStorage.setItem("shop-servicesearch_by", "10");
        localStorage.setItem("openFilter", "1");
        this.router.navigate(['shops/list-service-requests']);
    }
    transfer(){
        
    }
    getUnreadInternalNote(){
        this.globalService.localGetterSetter('internal-note-status',3,'')
    }
    getUnreadServiceNote(){
        this.globalService.localGetterSetter('shop-serviceserv_notes_unread',1,'')
    }
    quickQuote(type){
        this.globalService.getSetItems('convertedToSR',type,"shop-quickquote-")
        this.router.navigate(['shops/quick-quotes']);
    }
    getCORBName(cus){
        return (cus.business_name? cus.business_name: cus.first_name+' '+cus.middle_name+' '+cus.last_name);
    }
    convertTime(timeSlot){
        return  moment(timeSlot, ["hh:mm:ss"]).format("hh:mm A");
    }
    
    ngOnDestroy(){
        this.subscriptionDataForAPI.unsubscribe();
    }
}

/************
    Add CC FORM  Dialog 
***************/
@Component({
  templateUrl: 'update-credit-card.html',
})

export class updateCCDetail {
    public ccForm: FormGroup;
    public currentCustomer:any;
    public currentCustomerName:any;
    public first_name:any;
    public last_name:any;
    public email:any;
    public address1:any;
    public city:any;
    public state:any;
    public zip:any;
    public country:any;
    public cell_phone1:any;
    public user_type:any;
    public user_id:any;
    public planName:any;
    public planAmount:any;
    public shop_id:any;
    public paymentSuccess:any;
    public subscriptionMessage:any;
    
    public isSubscribeld:any;
    public displayForm:any;
    public cardHolderName:any;
    public CardType:any;
    public ccMaskedNumber:any;
    public ccExpiryDay:any;
    public ccInfoResult:any;
    public defaultCheck:any;
    public displayNewForm:any;
    public paymentToken:any;
    public btCustomerID:any;
    public plan_id:any;
    public mailChimp:any;
    public subscriptionID:any;
    public subscriptionType:any;
    public btSubscriptionID:any;
    public subscriptionDataForAPI: Subscription = new Subscription();
    constructor(
         private router: Router,
         public dialogRef: MatDialogRef<updateCCDetail>,
         @Inject(MAT_DIALOG_DATA) public data: any, 
         private formBuilder: FormBuilder, 
         private globalService: GlobalService
         ) {
        this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
        this.currentCustomerName = this.currentCustomer.first_name+" "+this.currentCustomer.last_name;
        /* USER LOGIN DETAILS */
        this.first_name     = this.currentCustomer.first_name;
        this.last_name      = this.currentCustomer.last_name;
        this.email          = this.currentCustomer.email;
        this.address1       = this.currentCustomer.address1;
        this.city           = this.currentCustomer.city;
        this.state          = this.currentCustomer.state;
        this.country        = this.currentCustomer.country;
        this.zip            = this.currentCustomer.zip;
        this.cell_phone1    = this.currentCustomer.cell_phone1;
        this.user_type      = this.currentCustomer.user_type;
        this.user_id        = this.currentCustomer.id;
        this.shop_id        = this.currentCustomer.shop_id;
        this.isSubscribeld  = this.currentCustomer.is_subscribed
        this.globalService.diaLogObj(this.dialogRef);
        if(this.isSubscribeld == 'true'){

            this.defaultCheck = true;
            let resquestdata = "user_id=" + this.globalService.getCurrentUser().id+ "&shop_id=" + this.globalService.getCurrentUser().shop_id+ "&user_type=" + this.globalService.getCurrentUser().user_type;
            this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_BT_CC_INFO_URL, resquestdata).subscribe((data:any) => {
                if (data.result == '1') {
                    this.ccInfoResult = data.data;
                    this.cardHolderName = this.ccInfoResult.cardholderName;
                    this.CardType       = this.ccInfoResult.cardType;
                    this.ccMaskedNumber = this.ccInfoResult.maskedNumber;
                    this.ccExpiryDay    = this.ccInfoResult.expirationDate;
                    this.paymentToken   = this.ccInfoResult.payment_token;
                    this.btCustomerID   = this.ccInfoResult.bt_customer_id;
                    this.btSubscriptionID = this.currentCustomer.subscription_data.bt_subscription_id
                    this.formInitWithNewCC();
                } 
            });
        } else {
            //this.displayForm = true;
            //this.defaultCheck = false;
            this.formInitWithNewCC();
        }
         //this.formInitWithNewCC();
        /* END */
        //this.planName = data.plan_name;
        //this.planAmount = parseFloat(data.plan_amount).toFixed(2);
    }
    
    formInitWithNewCC(){
        this.ccForm = this.formBuilder.group({
            cc_name: ['', [Validators.required]],
            cc_number: ['',[Validators.required, Validators.pattern("^[0-9]*$")]],
            cvv: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
            exp_month: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
            exp_year: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
            first_name: [this.first_name],
            last_name: [this.last_name],
            email: [this.email],
            address1: [this.address1],
            city: [this.city],
            state: [this.state],
            country: [this.country],
            zip: [this.zip],
            cell_phone1: [this.cell_phone1],
            user_type: [this.user_type],
            user_id: [this.user_id],
            shop_id: [this.shop_id],
            payment_token: [this.paymentToken],
            bt_customer_id: [this.btCustomerID],
        });
    }
    
    onlyDecimalNumberKey(event) {
        let charCode = (event.which) ? event.which : event.keyCode;
        if (charCode < 48 || charCode > 57)
            return false;
        return true;
    }

    disableKey(event) {
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if (event.ctrlKey==true && (event.which == '67' || event.which == '86' ||  String.fromCharCode(event.which).toLowerCase() == 's')) {
            
            event.preventDefault();
        }
    }
    
    getCardType(event)
    {
        let ele:any = <HTMLInputElement>document.getElementById('cardnumber');
        let number:any=ele.value;
        let cardtype='';
        
        // visa
        var re = new RegExp("^4");
        if (number.match(re) != null)
           
            cardtype= '<img src="../../../assets/img/payment.png"/ style="margin-left: -2px;">';

        // Mastercard 
        // Updated for Mastercard 2017 BINs expansion
        /*if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number)) 
            cardtype= "Mastercard";*/
            
        var re = new RegExp("^5");
        if (number.match(re) != null)    
//            cardtype= "Mastercard";
            cardtype= '<img src="../../../assets/img/payment.png"/ style="margin-left: -63px;">';
            
        // AMEX
        re = new RegExp("^3[47]");
        if (number.match(re) != null)
            cardtype= '<img src="../../../assets/img/payment.png"/ style="margin-left: -368px;">';
            

        // Discover
        re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
        if (number.match(re) != null)
//            cardtype= "Discover";
            cardtype= '<img src="../../../assets/img/payment.png"/ style="margin-left: -245px;">';

        // Diners
        re = new RegExp("^36");
        if (number.match(re) != null)
//            cardtype= "Diners";
            cardtype= '<img src="../../../assets/img/payment.png"/ style="margin-left: -185px;">';

        // Diners - Carte Blanche
        re = new RegExp("^30[0-5]");
        if (number.match(re) != null)
//            cardtype= "Diners - Carte Blanche";
            cardtype= '<img src="../../../assets/img/payment.png"/ style="margin-left: -185px;">';

        // JCB
        re = new RegExp("^35(2[89]|[3-8][0-9])");
        if (number.match(re) != null)
//            cardtype= "JCB";
            cardtype= '<img src="../../../assets/img/payment.png"/ style="margin-left: -306px">';

        // Visa Electron
        re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
        if (number.match(re) != null)
//            cardtype= "Visa Electron";
            cardtype= '<img src="../../../assets/img/payment.png"/ style="margin-left: -124px;">';
            
        let cardEle:any = <HTMLElement>document.getElementById('cardIcon');
        cardEle.innerHTML=cardtype;
        return "";
    }
    
    ngOnInit() {
        this.formInitWithNewCC();
    }
    /* Pay Now From CC Pop Up FIRST TIME PAYMENT */
    
    updateCCInfo(){ 
        if (this.ccForm.valid) {
            let data = new FormData();
            for (let i in this.ccForm.value) {
                data.append(i, this.ccForm.value[i]);
            }
            this.globalService.formData(globalConstants.API_UPDATE_SHOP_BT_CC,data).subscribe((data:any)=> {
                //localStorage.setItem("shopPaySuccessMsg", data.message);
                if (data.result == "1") {
                    this.paymentSuccess = true;
                    this.globalService.snackbar('success', data.message);
                    return this.dialogRef.close({data: data.masked_cc});
                } else {
                    this.paymentSuccess = false;
                    this.globalService.snackbar('error', data.message);
                }
            });
        } 
    } 
    
    close(){
         return this.dialogRef.close({ data: 3 });
    }

    ngOnDestroy(){
        
        this.subscriptionDataForAPI.unsubscribe();
    }
}
