<div class="panel panel-primary" id="mainCompotents">
    <div class="panel-heading padding-left-30">
        <h3 class="panel-title">Customer Approval</h3>
        <button mat-mini-fab class="custom-btn-back" (click)="globalService.goBack();">
            <mat-icon>arrow_back</mat-icon>
        </button>
    </div>
    <div class="panel-body bgcolor-lightgrey">
        <div class="jumbotron padding-25 car-view-str customer-details">
            <!-- Replaced div grid structure with table -->
            <h3 class="margin-top-0">{{ currentCustomer.profile_type == '2' ? 'Contact' : 'Customer' }} Information</h3>
            <hr>
            <div class="row">
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-sm-6"><label>Name</label></div>
                        <div class="col-sm-6">{{customerName}}</div>
                    </div>
                </div>
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-sm-6"><label>Email</label></div>
                        <div class="col-sm-6 break-word">
                            <a href="mailto:{{customerEmail}}" class="text-black">{{customerEmail}}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-sm-6"><label>Address</label></div>
                        <div class="col-sm-6">{{customerAddress}}</div>
                    </div>
                </div>
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-sm-6"><label>City</label></div>
                        <div class="col-sm-6 break-word">{{customerCity}}</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-sm-6"><label>State</label></div>
                        <div class="col-sm-6">{{customerState}}</div>
                    </div>
                </div>
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-sm-6"><label>Country</label></div>
                        <div class="col-sm-6 break-word">{{customerCountry}}</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-sm-6"><label>Zip Code</label></div>
                        <div class="col-sm-6">{{customerZip}}</div>
                    </div>
                </div>
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-sm-6"><label>Home Phone</label></div>
                        <div class="col-sm-6 break-word"><a href="tel:{{homePhone}}" class="text-black">{{homePhone}}</a></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-sm-6"><label>Business Phone</label></div>
                        <div class="col-sm-6"><a href="tel:{{businessPhone}}" class="text-black">{{businessPhone}}</a></div>
                    </div>
                </div>
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-sm-6"><label>Mobile Phone</label></div>
                        <div class="col-sm-6 break-word"><a href="tel:{{mobilePhone}}" class="text-black">{{mobilePhone}}</a></div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-sm-6"><label>{{ currentCustomer.profile_type == '2' ? 'Contact' : 'Customer' }} Type</label></div>
                        <div class="col-sm-6">{{customerType}}</div>
                    </div>
                </div>
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-sm-6"><label>Tax Exempted</label></div>
                        <div class="col-sm-6 break-word">{{taxExempted}}</div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-sm-6"><label>{{globalService.getBORDName()}}</label></div>
                        <div class="col-sm-6">{{businessName}}</div>
                    </div>
                </div>
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-sm-6"><label></label></div>
                        <div class="col-sm-6 break-word">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




