<div class="panel panel-primary" id="mainCompotents">
  <div class="panel-heading padding-left-15">
    <h3 class="panel-title">Request Creation</h3>
    <button mat-mini-fab class="custom-btn-back" (click)="globalService.goBack();">
      <mat-icon>arrow_back</mat-icon>
    </button>
  </div>  
  <div class="panel-body bgcolor-lightgrey" *ngIf="currentShop.is_subscribed">
    <form [formGroup]="newRequest" (ngSubmit)="sendNewRequest()" class="col-xs-12 signup-form request-creation-form" method="POST" enctype="multipart/form-data" autocomplete="off">
      <!-- <div class="newRequest-details"> -->
        <!-- <div class="shop-title-section">
          <h3>Shop :</h3>
          <h3><strong id="shopName">{{ defaultServiceFacility.name }}</strong></h3>
        </div> -->
        <div class="row details-row">
          <div class="col-md-12">
            <div class="row vehicle-select-section">
              <div class="col-md-4 textarea-label">Select Vehicle:</div>
              <div class="col-md-7 uc-ngx-select w-full">
              <ngx-select class="display-full margin-bottom-20" [items]="serviceVehicles" formControlName="request_car_id" 
                  placeholder="Select car" optionTextField="name"  (select)="selectedVehicle()">
                  <ng-template ngx-select-option-selected let-option let-text="text">
                    <span [innerHtml]="text" class="notranslate"></span>
                  </ng-template>
                  <ng-template ngx-select-option let-option let-text="text">
                      <span [innerHtml]="text" title="{{transformInnerHtmlData(text)}}"></span>
                  </ng-template>
              
                </ngx-select>
                <div *ngIf="insuranceExpired || registrationExpired" class="insurance-notes">
                    <div *ngIf="insuranceExpired">
                        Vehicle Insurance Expired - {{displayInsuranceDate}}
                    </div>
                    <div *ngIf="registrationExpired">
                        Vehicle Registration Expired - {{displayRegistrationDate}}
                    </div>
                </div>
              </div>
              <div class="col-md-1 pad-lr-0">
                <button type="button" class="relative" mat-icon-button title="Create new vehicle" (click)="gotoAddCar()" disableRipple><i class="fa fa-plus fa-lg"></i></button>
              </div>
            </div>
           
            <div class="row nr-col-width">
              <div class="col-md-4 textarea-label">Request Title:</div>
              <div class="col-md-8 details-input">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                  <input #request_title matInput placeholder="Request Title *" title="Request Title" class="form-control input-lg custom-form-input" maxlength="255" formControlName="request_title"/>
                </mat-form-field>
              </div>
            </div>

            <div class="row nr-col-width">
              <div class="col-md-4 textarea-label">Schedule Date:</div>
              <div class="col-md-8  details-input"> 
                 <app-date-and-time-picker [importTime]="today" class="date-time-section" (SelectTime)="onDateChange($event)"></app-date-and-time-picker>
              </div>
           </div>

           <div class="row nr-col-width">
            <div class="col-md-4 textarea-label">Present Mileage (Miles):</div>
            <div class="col-md-8 details-input">
              <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                <input #distance_covered matInput placeholder="Present Mileage *" title="Present Mileage" class="form-control input-lg custom-form-input" placeholder="{{mileage}}" formControlName="distance_covered" (keypress)="onlyNumericKey($event)" id="mileage" (value)="mileageValue" maxlength="10"/>
              </mat-form-field>
            </div>
          </div>

           <div class="row nr-col-width onTheLot-section">
            <div class="col-md-4 textarea-label">On The Lot</div>
            <div class="col-md-8 checkbox-col details-input">
              <mat-checkbox (change)="updateOnTheLotStatus($event)" ></mat-checkbox>
            </div>
          </div>
          </div>
          <div class="col-sm-12">
            <div class="row nr-col-width">
              <div class="col-md-4 textarea-label">Request Notes:</div>
              <div class="col-md-8 details-input">
                <mat-form-field class="car-input btn-block textarea-input" floatPlaceholder="never" appearance="fill">
                  <textarea matInput placeholder="Request Notes" title="Request notes" rows="5" maxlength="1000" formControlName="request_notes"></textarea>
                </mat-form-field>
                <p *ngIf="currentShop.profile_type == '1'" class="tax-note text-red">Customer can see anything you write here<p>
              </div>
            </div>
          </div>
          <div class="form-group col-sm-12" id="documentsAndImagesContainer">
           <div class="row margin-0">
            <h4 class="col-md-12"><strong>Documents &amp; Images</strong></h4>
            <div class="col-md-6">
              <label for="fileDocumentUpload" class="font-bold">Upload Document</label>
              <!-- <input type="file" id="fileDocumentUpload" name="request_document"> -->
              <mat-toolbar class="mat-elevation-z2 margin-bottom-15 upload-btn relative">
                <mat-icon class="margin-right-10">cloud_upload</mat-icon>
                Drag File Here
                <input type="file" multiple id="addrequest_document" (change)="changeListner($event,'request_document')" name="request_document" />
                <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
              </mat-toolbar>
              <p class="help-block">Format(s) supported : pdf, doc, docx</p>
              <p class="help-block">Maximum size : 10MB</p>
              <p class="help-block bold-text">E.g. : Pdf reports from your scan tool or other relevant documents.</p>
              <ul class="list-inline upload-image margin-top-10" >
                <li *ngFor="let doc of request_document;let i=index">
                  <a href="javascript:void(0);" class="img-set thumbnail relative">
                      <span class="fa far fa-file-pdf fa-4x" *ngIf="doc.type==3"></span>
                      <span class="fa far fa-file-word fa-4x" *ngIf="doc.type==4"></span>
                    <i class="fa fa-trash remove-img" (click)="deleteImg('request_document',i)"></i>
                    <div class="image-loader" *ngIf="request_document_loader">
                      <mat-progress-spinner color="accent" mode="indeterminate" strokeWidth="2" diameter="30"></mat-progress-spinner>
                    </div>
                  </a>
                </li>
              </ul>

            </div>
            <div class="col-md-6">
              <label for="fileImageUpload" class="font-bold">Upload Image</label>
              <mat-toolbar class="mat-elevation-z2 margin-bottom-15 upload-btn relative">
                <mat-icon class="margin-right-10">cloud_upload</mat-icon>
                Drag File Here
                <input type="file" multiple id="addrequest_image" (change)="changeListner($event,'request_image')" name="request_image"  />
                <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
              </mat-toolbar>
            
              <p class="help-block">Format(s) supported : {{imgTypes}}</p>
              <p class="help-block">Maximum size : {{imgSize}}</p>
              <p class="help-block bold-text">E.g. : Picture of instrument cluster, 4 corners of car, vehicle damage, etc. </p>
              <ul class="list-inline upload-image margin-top-10" >
                <li *ngFor="let img of request_image;let i=index">
                  <a href="javascript:void(0);" class="img-set thumbnail relative">
                    <img class="show-image" src="{{img.src}}" />
                    <i class="fa fa-trash remove-img" (click)="deleteImg('request_image',i)"></i>
                    <div class="image-loader" *ngIf="request_image_loader">
                      <mat-progress-spinner color="accent" mode="indeterminate" strokeWidth="2" diameter="30"></mat-progress-spinner>
                    </div>
                  </a>
                </li>
              </ul>

            </div>
           </div>
          </div>
        </div>
      <!-- </div> -->

<mat-expansion-panel hideToggle="false" class="rejected-job" [class.job-available]="rejectedJobList">
  <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px">Rejected Jobs in Last Ticket:</mat-expansion-panel-header>
  <div *ngIf="rejectedJobMessage">{{rejectedJobMessage}}</div>
  <div *ngIf="!rejectedJobMessage && !rejectedJobList">No Previous Rejected Jobs</div>
  <ul class="service-list-items" *ngIf="rejectedJobList">
    <li class="topPad" *ngFor="let rejectList of rejectedJobList;let i = index">
      <span>
          {{rejectList.level1JobName}}
          <span  class="service-request-margin" *ngIf="rejectList.level2JobName"><br>{{rejectList.level2JobName}}</span>
          <br>
          <span class="service-request-margin2" [class.service-request-margin]="!rejectList.level2JobName"   >
              {{ rejectList.name }}
          </span>
      </span>
      <div class="col-sm-12" *ngIf="rejectList.Parts">
        <div class="form-group">
          <label class="col-sm-3 control-label">Part(s) :</label>
          <div class="col-sm-9 text-justify">
            <div class="addedPartsItems">
              <div class="partRow row padding-top-5" *ngFor="let rejectListParts of rejectList.Parts">
                <div class="col-sm-8 pname">
                  {{ rejectListParts.part_number }} - {{ rejectListParts.name }} -  - $ {{ rejectListParts.price }}
                </div>
                <div class="col-sm-2 pprice">
                  $ {{ rejectListParts.total_part_price }}
                </div>
                <div class="col-sm-2 pquantity">
                  {{ rejectListParts.quantity }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12" *ngIf="rejectList.GenericJobs">
        <div class="form-group">
          <label class="col-sm-3 control-label">Generic Jobs(s) :</label>
          <div class="col-sm-9 text-justify">
            <div class="addedGenericJobsItems">
              <div class="partRow row padding-top-5" *ngFor="let rejectListGenericJobs of rejectList.GenericJobs">
                <div class="col-sm-8 pname">{{ rejectListGenericJobs.name }} - {{ rejectListGenericJobs.description }} </div>
                <div class="col-sm-2 pprice">$ {{ rejectListGenericJobs.price }}</div>
                <div class="col-sm-2 pquantity">{{ rejectListGenericJobs.quantity }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

       <div class="col-md-12 "  *ngIf="currentUser.subscription_data.nexpart == 1" >
        <label class="col-sm-3 control-label">Nexpart Labor(Hours):</label>
        <div class="col-sm-9 text-justify">{{ rejectList.nexpart_labor_time }} hour(s)</div>
      </div>

      <div class="col-md-12 ">
        <label class="col-sm-3 control-label">Labor Time :</label>
        <div class="col-sm-9 text-justify">{{ rejectList.estimated_time }} Hrs</div>
      </div>
      <div class="col-sm-12 col-xs-4" *ngIf="rejectList.recommendation!=''">
          <label class="col-sm-3 control-label">Description of Work:</label>
          <div class="col-sm-9 text-justify">{{ rejectList.recommendation }} </div>
      </div>
      <div class="col-md-12  text-right top-pad">
        <button (click)="addRejectedJobs(i)" id="rejected-{{rejectList.level1Job}}-{{rejectList.level2Job}}" type="button" mat-raised-button class="btn-block text-capitalize font-bold fontcolor-white" color="accent" title="Add Job to Service Ticket" style="max-width: 200px;">Add Job to Service Ticket</button>
      </div>
    </li>
  </ul>
</mat-expansion-panel>
<div class="row padding-top-15 padding-bottom-15 newRequest-searchJob">
  <div class="col-sm-12">
      <label >Search in jobs:</label>
  </div>
  <div class="col-sm-12 relative">
      <input style="border-radius:0px" ngui-auto-complete  (valueChanged) = "getSelectedOptionForx($event)" (click)="carAttribute()"
      [source]="observableSourceForx" [list-formatter]="autocompleListFormatter"
      value-property-name="value"
      placeholder="Search Attribute"
      id="searchCarAattribute"
      class="form-control"/>
  </div>
</div>
<mat-accordion class="new-request-expension margin-bottom-10 margin-top-10 display-full" displayMode="flat" hideToggle="false">

  <mat-expansion-panel hideToggle="false" class="new-request-expension" [class.hidden]="servicerequest.is_searchable==1" *ngFor="let servicerequest of serviceRequestList;let i = index" id="expansion-{{i+1}}" (opened)="expansionOpen(i+1);">

    <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px" [class.hidden]="servicerequest.is_searchable==1" id="expansionHead-{{i+1}}" (click)="expansionOpen(i+1);" data-state="false">
      <span class="margin-right-5">{{ i+1 }}.</span>{{ servicerequest.name }}
    </mat-expansion-panel-header>

    <div *ngFor="let servicerequestChild of servicerequest.children;let j = index">
      <mat-checkbox class="btn-block" labelPosition="before" (change)="showOptions($event, i+1);makeJSON($event,servicerequest,servicerequestChild,null,i+1)" id="request-check-{{servicerequest.id}}-{{servicerequestChild.id}}" name="request-check-{{servicerequest.id}}-{{servicerequestChild.id}}">
        {{ j+1 }})<b> {{servicerequestChild.name}}</b>
      </mat-checkbox>

      <div class="padding-left-25 expansion-subchild" *ngIf="servicerequestChild.children" id="{{ 'second-level-child-' + servicerequestChild.id }}">
        <div *ngFor="let subchild of servicerequestChild.children;let k = index">
          <label *ngIf="subchild.input == 'radio'" class="material-radio padding-right-10">
            <input type="radio"  value="1" id="requestRadio-{{servicerequest.id}}-{{servicerequestChild.id}}-{{subchild.id}}-input" name="requestRadio-{{servicerequest.id}}-{{servicerequestChild.id}}" (change)="makeJSON($event,servicerequest,servicerequestChild,subchild,i+1)" />
            <span>{{ subchild.name }}</span>
          </label>
          <!-- <mat-radio-button class="display-full padding-right-10" labelPosition="before"   (change)="makeJSON($event,servicerequest,servicerequestChild,subchild,i+1)">{{ subchild.name }}</mat-radio-button> -->
          <mat-radio-button class="display-full padding-right-10" labelPosition="before" *ngIf="subchild.input == 'radioo'" value="1" name="request-{{i}}-{{j}}" (change)="makeJSON($event,servicerequest,servicerequestChild,subchild,i+1)">{{ subchild.name }}</mat-radio-button>
          <mat-checkbox class="btn-block" id="requestid-{{servicerequest.id}}-{{servicerequestChild.id}}-{{subchild.id}}" labelPosition="before" *ngIf="subchild.input == 'checkbox'" (change)="makeJSON($event,servicerequest,servicerequestChild,subchild,i+1)">
            {{subchild.name}}
          </mat-checkbox>
          <mat-form-field class="car-input btn-block" floatPlaceholder="never" *ngIf="subchild.input == 'textarea'" appearance="fill">
            <textarea matInput id="{{servicerequest.id}}-{{servicerequestChild.id}}" placeholder="{{ subchild.name }}" title="{{ subchild.name }}" rows="4" maxlength="500" (keyup)="makeJSON($event,servicerequest,servicerequestChild,subchild,i+1)"></textarea>
          </mat-form-field>
          <!-- <input type="file" id="{{subchild.id}}" name="{{ subchild.name }}" *ngIf="subchild.input == 'imageupload'" (change)="makeJSON($event,servicerequest,servicerequestChild,subchild,i+1)"> -->
          <div *ngIf="subchild.input == 'imageupload'">
          <mat-toolbar class="mat-elevation-z2 margin-bottom-15 upload-btn relative">
            <mat-icon class="margin-right-10">cloud_upload</mat-icon>
            Drag File Here
            <input type="file" id="{{subchild.id}}jobs" name="{{ subchild.name }}" (change)="makeJSON($event,servicerequest,servicerequestChild,subchild,i+1)" />
            <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
          </mat-toolbar>
          <p class="help-block">Format(s) supported : {{imgTypes}}</p>
          <p class="help-block">Maximum size : {{imgSize}}</p>
          <ul class="list-inline margin-left-0 upload-image" *ngIf="subchild.input == 'imageupload'">
            <li class="img-set thumbnail relative" *ngIf="jobImages" >
              <img class="show-image img-responsive" src="{{jobImages}}" />
              <i class="fa fa-trash remove-img" (click)="deleteJobImg(servicerequest,servicerequestChild,subchild)"></i>
            </li>
          </ul>

        </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<div class="row">
  <div class="col-xs-12 col-md-4 col-lg-4 text-right-align">
    <button type="button" *ngIf="isAllowed('addNew','fill_recommendation')" (click)="sendNewRequest(2)" [disabled]="waitingEnabled" class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white mat-raised-button mat-accent text-right-align" color="accent" mat-raised-button title="Submit & Open" style="max-width: 300px;">
      Submit & Open Request
    </button>
  </div>
  <div class="col-xs-12 col-md-3 col-lg-3 text-right-align">
    <button type="submit" [disabled]="waitingEnabled" class="hidden-xs hidden-sm car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white mat-raised-button mat-accent text-right-align" color="accent" mat-raised-button title="Submit" style="max-width: 300px;">
      SUBMIT
    </button>
    <button type="submit" [disabled]="waitingEnabled" class="visible-xs visible-sm car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white mat-raised-button mat-accent text-right-align" color="accent" mat-raised-button title="Submit" style="max-width: 300px; margin-top:10px;">
      SUBMIT
    </button>
  </div>
</div>

</form>
</div>
<div *ngIf="!currentShop.is_subscribed">You are not subscribed</div>
</div>
