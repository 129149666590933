import { Component, OnInit,HostListener,ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap, NavigationEnd, Params } from '@angular/router';
import { Http, Headers,RequestOptions, Response } from '@angular/http';
import { globalConstants } from "../../../../environments/globalConstants"
import { globalFunctions } from "../../../../environments/globalFunctions"
import { GlobalService } from "../../../../environments/global.service"
import { TextMaskModule } from 'angular2-text-mask';
import { Observable } from 'rxjs/Observable';
import { Title } from '@angular/platform-browser';

import 'rxjs/add/operator/startWith';
import 'rxjs/add/operator/map';
import { IDatePickerConfig } from 'ng2-date-picker';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { ImagetooldialogComponent } from '../../../imagetooldialog/imagetooldialog.component';
import { MatDialog } from '@angular/material/dialog';
import {Subscription} from 'rxjs';
declare var jQuery:any;
declare var $ :any;
import * as moment from 'moment';
@Component({
  selector: 'app-edit-servicerequest',
  templateUrl: './edit-servicerequest.component.html',
  styleUrls: ['./edit-servicerequest.component.scss'],
  animations: [
    trigger('popOverState', [
      state('show', style({
        opacity: 1,
        transform: 'translateX(0px)',
        'z-index': '0'
      })),
      state('hide', style({
        opacity: 0,
        transform: 'translateX(-69px)',
        'z-index': '-1'
      })),
      transition('show => hide', animate('100ms ease-out')),
      transition('hide => show', animate('0.2s 100ms ease-out'))
    ])
  ],
  providers: [DatePipe]
})
export class EditServicerequestComponent implements OnInit, OnDestroy {
    public globalConstants=globalConstants;
    public serviceRequestList=[];
    public secondLevelChild = [];
    public currentCustomer;
    public secondLevelData = "";
    public showHideVal = false;
    public serviceVehicles = [];
    public newRequest: FormGroup;
    public rejectedJob;
    public rejectedJobList;
    public rejectedJobMessage;
    public checklist = {};
    public distanceTravelled = "";
    public defaultServiceFacility = { name: "", id: "" };
    public showDropdown = false;
    public defaultServiceFacilities = [];
    public options = "";
    public filteredOptions = "";
    public customer_id = "";
    public request_car_id = "";
    public service_facility_id = "";
    public request_title = "";
    public request_notes = "";
    public request_date = "";
    public shop_name = "";
    public shop_email = "";
    public shop_address = "";
    public shop_city = "";
    public shop_state = "";
    public shop_zip = "";
    public shop_phone = "";
    public shop_url = "";
    public serviceRequestId = "";
    public customerId = "";
    public serviceRequestDetails = null;
    public isDIY = false;
    public sizes: any[] = [];
    myControl: FormControl = new FormControl();
    autoOptions = []
    filterOtionsSet: Observable<string[]>;
    public value: Date;
    public carId;
    public populates = [];
    formEdited = false;
    currentShop=[];
    public datePickerConfig: IDatePickerConfig = {
      drops: "down",
      format: "MM/DD/YYYY hh:mm A",
      min: this.datePipe.transform(new Date(), 'MM/dd/yyyy hh:mm a'),
      disableKeypress:true,
      unSelectOnClick:false
    };
    public requestImages=[];
    public requestDoc=[];
    public requestImgPath=globalConstants.S3_BUCKET_URL+globalConstants.SERVICE_REQUEST_PICTURES+globalConstants.IMG_THUMB;
    public docPath=globalConstants.S3_BUCKET_URL;
    public jobImg=globalConstants.S3_BUCKET_URL+globalConstants.SERVICE_PICTURE+globalConstants.IMG_THUMB;
    public scheduleDate='';
    public jobImages='';
    public insuranceDefaultNotes = true;
    public insuranceExpirationDate;
    public registrationExpirationDate;
    public insuranceExpired;
    public registrationExpired;
    public displayInsuranceDate;
    public displayRegistrationDate;
    public insExpDate;
    public regExpDate;
    public imgTypes=globalConstants.FORMAT_SUPPORTED_FOR_IMAGE;
    public imgSize=globalConstants.MAX_ALLOWED_IMAGE_DISPLAYED_TEXT;
    public random=Math.random();
    public jobImageDelete=false;
    public mileage :any ="";
    public today = new Date();
    public mask = {
        guide: true,
        showMask : false,
        mask: [/\d/, /\d/, '/', /\d/, /\d/, '/',/\d/, /\d/,/\d/, /\d/]
    };
    date = '';
    public calDate;
    time = '';
    dateValid = true;
    submited = false;
    public sTimeSlots: Array<any> = [];
    public subscriptionDataForAPI: Subscription = new Subscription();
    public selectedDateAndTime : any;

  filter(val: string): string[] {
    return this.autoOptions.filter(option =>
      option.name.toLowerCase() === 0);
    }
    constructor(private ref:ChangeDetectorRef, public titleService: Title, private activatedRoute: ActivatedRoute, private http: Http, private formBuilder: FormBuilder, public globalService: GlobalService, public router: Router, private datePipe: DatePipe, public dialog:MatDialog) {
      this.currentShop = JSON.parse(localStorage.getItem("loggedInUser"));
      this.setParams();
      this.globalService.setMetaData("SHOPS","EDIT_SERVICE_REQUEST")
    }
    ngOnInit() {
      this.sTimeSlots  = globalConstants.TIME_SLOTS;
      this.getDefaultServiceFacility();
      this.reinitializeFormBuilder(null);
      this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_SERVICE_REQUESTS_LIST_URL, "customer_id=" + this.customerId)
      .subscribe((res:any) => {
        this.getServiceDetail();
        this.serviceRequestList =res.data ;
        this.makeSelections(res.data);
      });
      this.selectedVehicle()
    }
    setParams() {
      this.activatedRoute.params.subscribe((params: Params) => {
        this.serviceRequestId = params["id"];
        this.customerId = params['cid'];
      });
    }
    reinitializeFormBuilder(object) {
      this.serviceRequestDetails = this.serviceRequestDetails = object

      if (object != null) {
        this.newRequest.controls.request_title.setValue(object.title);
        this.newRequest.controls.distance_covered.setValue(object.present_mileage || 0);
        this.newRequest.controls.customer_id.setValue(this.customerId);
        this.newRequest.controls.rid.setValue(this.serviceRequestId);
        this.newRequest.controls.request_car_id.setValue(object.car_id);
        this.newRequest.controls.request_notes.setValue(object.notes);
        this.newRequest.controls.shop_id.setValue(object.shop_id);
        this.newRequest.controls.company_name.setValue(object.company_name);
        // this.newRequest.controls.populates.setValue(this.populates);
        this.request_car_id=object.car_id;    
        this.scheduleDate=object.requested_delivery_date;
        if(this.scheduleDate.length>0){
          let fdate=this.getFormatedDate();
          // this.newRequest.controls.request_date.setValue(this.globalService.getFullFormateDate(this.scheduleDate,"G","C"));
          this.date = this.datePipe.transform(this.globalService.getFullFormateDate(this.scheduleDate,"G","C"), 'MM/dd/yyyy').split(' ')[0];
          this.time = this.setTime(this.datePipe.transform(this.globalService.getFullFormateDate(this.scheduleDate,"G","C"), 'HH:mm'));
        }

      } else {
        this.newRequest = this.formBuilder.group({
          customer_id: [this.customerId],
          request_car_id: [''],
          distance_covered: [''],
          request_id: [this.serviceRequestId],
          request_title: [''],
          request_notes: [''],
          request_date: [''],
          rid:[],
          rtype:1,
          from_v2:1,
          requestCreationType:1,
          shop_id:[],
          user_id:[this.currentShop['id']],
          company_name:[],
          // request_document:[''],
          // request_image:[''],
          user_type:[this.currentShop['user_type']]
        });
      }
    }
    getDefaultServiceFacility() {
      this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_SERVICE_FACILITY_LIST_FOR_SERVICE_REQUEST_URL, "customer_id=" + this.customerId).subscribe((parsedResponse:any) => {
        for (var i = 0; i < parsedResponse.length; i++) {
          if (parsedResponse[i]["shop"] == this.newRequest.controls.shop_id.value) {
            this.defaultServiceFacility.name = parsedResponse[i]["name"]
            this.defaultServiceFacility.id = parsedResponse[i]["id"]
          }
        }

      });

    }
    selectedVehicle() {
        /* Insurance/registration alert */
        for (var i = 0; i < this.serviceVehicles.length; i++) {
            if (this.serviceVehicles[i]["id"] == this.newRequest.value.request_car_id) {
                this.insuranceDefaultNotes = false;
                /* Registration/Insurance expiration notice */
                this.insuranceExpirationDate = this.serviceVehicles[i]["insurance_expiration_date"];
                this.registrationExpirationDate = this.serviceVehicles[i]["registration_expiration_date"];
                var currentDate = new Date();
                currentDate.setHours(0, 0, 0);
                this.newRequest.controls.distance_covered.setValue(this.serviceVehicles[i].distance_covered);
                if(this.insuranceExpirationDate!="" && this.insuranceExpirationDate!=null && this.insuranceExpirationDate!="0000-00-00 00:00:00" && this.insuranceExpirationDate!="Invalid Date") {
                     if(this.globalService.isOldDate(this.insuranceExpirationDate)) {
                        this.insuranceExpired = true;
                        //this.displayInsuranceDate = (new Date(this.insuranceExpirationDate).getMonth()+1) + "/" + new Date(this.insuranceExpirationDate).getDate() + "/" + new Date(this.insuranceExpirationDate).getFullYear();
                        this.displayInsuranceDate = this.globalService.getFullFormateDate(this.insuranceExpirationDate,"GD","B")
                    } else {
                        this.insuranceExpired = false;
                    }
                } else {
                    this.insuranceExpired = false;
                }

                if(this.registrationExpirationDate!="" && this.registrationExpirationDate!=null && this.registrationExpirationDate!="0000-00-00 00:00:00" && this.registrationExpirationDate!="Invalid Date") {
                     if(this.globalService.isOldDate(this.registrationExpirationDate)) {
                        this.registrationExpired = true;
                        this.displayRegistrationDate = this.globalService.getFullFormateDate(this.registrationExpirationDate,"GD","B")
                    } else {
                        this.registrationExpired = false;
                    }
                } else {
                    this.registrationExpired = false;
                }
                if(this.request_car_id==this.newRequest.value.request_car_id){
                  this.mileage = this.newRequest.value.distance_covered;
                }else{
                  this.mileage = this.newRequest.value.distance_covered;
                  this.newRequest.controls.distance_covered.setValue("")
                }

                /* END */
                break;
            }
        }
        /* ** */

        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_REJECTED_SERVICE_JOBS_URL, "customer_id=" + this.customerId + "&car_id=" + this.newRequest.value.request_car_id).subscribe((data) => {
            this.rejectedJob = data;
            if (this.rejectedJob.code == 500) {
                this.rejectedJobMessage = false;
                this.rejectedJobList = this.rejectedJob.data;
            }
            if (this.rejectedJob.code == 150) {
                this.rejectedJobList = false;
                this.rejectedJobMessage = this.rejectedJob.message;
            }
        });
    }

    showOptions(opt, event, parentEleId) {
      // event.stopPropagation();
      let parentEle = document.getElementById('expansion-' + parentEleId);
      let ele=null;
      if (event.checked == true) {
        parentEle.classList.contains("checked") ? '' : parentEle.classList.add("checked");
        if (event.source._elementRef.nativeElement.nextElementSibling != null) {
          ele=event.source._elementRef.nativeElement.nextElementSibling.id;
          document.getElementById(ele).classList.add("active");
          this.populates[opt.parent_id][opt.id+'level']=true;
        }
      } else if (event.checked == false) {
        if (event.source._elementRef.nativeElement.nextElementSibling != null) {
          ele=event.source._elementRef.nativeElement.nextElementSibling.id;
          document.getElementById(ele).classList.remove("active");
          if(typeof opt.children!='undefined' && opt.children.length>0){
            for(let i in opt.children){
              // this.makeJSON({}, {id:opt.parent_id},opt, opt.children[i],parentEleId);
              this.populates[opt.parent_id][opt.id][opt.children[i].id]=false;
            }
            this.populates[opt.parent_id][opt.id+'level']=false;
          }else{
            // this.makeJSON({}, {id:opt.parent_id},opt, null,parentEleId);
            this.populates[opt.parent_id][opt.id]=false;
          }
        }
        var totalChildren = event.source._elementRef.nativeElement.parentElement.parentElement.children.length;
        var totalUnchecked = 0;
        for (var i = 0; i < totalChildren; i++) {
          if (event.source._elementRef.nativeElement.parentElement.parentElement.children[i].childNodes[1].childNodes["0"].children["0"].childNodes["0"].checked == false) {
            totalUnchecked++;
          }
        }
        if (totalChildren == totalUnchecked) {
          // parentEle.classList.remove("checked")
        }
      }
      this.checkChild(parentEleId,ele);
    }
    expansionOpen(ele) {
      var target= '#expansionHead-'+ ele;
      var eleState = $(target).data('state');
      $(".new-request-expension").find(".mat-expansion-panel-header").data("state",false);
      if(!eleState){
        setTimeout(() => {
           let element = document.getElementById('expansionHead-' + ele);
           var target= '#expansionHead-'+ ele;
           setTimeout(() => {
             var body = $("html, body");
             body.animate({
                    scrollTop: jQuery(target).offset().top - 90
              }, 500);
           }, 500);
           $(target).data('state',true);
        }, 200);
      }
    }
    hasTextAreaChild(m2,object){
      var hasTextareaInChild = m2.children.find(function(x) { return x.input == "textarea"})
      if (hasTextareaInChild!=undefined){
        return true;
      } else {
        return false;
      }
    }
    hasTextRadioChild(m2,object){
      var hasRadioInChild = m2.children.find(function(x) { return x.input == "radio"})
      if (hasRadioInChild!=undefined){
        return true;
      } else {
        return false;
      }
    }
    hasCheckBoxChild(m2,object) {
      var hasCheckBoxChild = m2.children.find(function(x) { return x.input == "checkbox"})
      if (hasCheckBoxChild!=undefined){
        return true;
      } else {
        return false;
      }
    }
    makeJSON(object, m1, m2, m3, pid) {
      var obj = {};
      if (object.checked || object.value == 1 || object.type == "keyup" || object.type == "change") {
        if(m2.children && this.hasTextAreaChild(m2,object) && object.target == undefined){
          this.checklist["chk[" + m1.id + "][" + m2.id + "]"] = true
          this.newRequest.addControl("chk[" + m1.id + "][" + m2.id + "]", new FormControl("chk[" + m1.id + "][" + m2.id + "]"));
          this.newRequest.controls["chk[" + m1.id + "][" + m2.id + "]"].setValue("");
          let f= <HTMLButtonElement> document.getElementById(m1.id+"-"+m2.id);
          f.value=""
        }
        if(m2.children && this.hasTextRadioChild(m2,object) && m3 == undefined){
          // console.log("here");
          this.checklist["chk[" + m1.id + "][" + m2.id + "]"] = true
          this.newRequest.addControl("chk[" + m1.id + "][" + m2.id + "]", new FormControl("chk[" + m1.id + "][" + m2.id + "]"));
          this.newRequest.controls["chk[" + m1.id + "][" + m2.id + "]"].setValue(true)
        }
        if(m2.children && this.hasCheckBoxChild(m2,object) && m3 == undefined){
          // console.log("here");
          this.checklist["chk[" + m1.id + "][" + m2.id + "]"] = true
          this.newRequest.addControl("chk[" + m1.id + "][" + m2.id + "]", new FormControl("chk[" + m1.id + "][" + m2.id + "]"));
          this.newRequest.controls["chk[" + m1.id + "][" + m2.id + "]"].setValue(true)
        }
        else if (m2.children) {
          if (m3 != undefined && m3.input == "checkbox") {
          this.checklist["chk[" + m1.id + "][" + m2.id + "][" + m3.id + "]"] = true
          this.newRequest.addControl("chk[" + m1.id + "][" + m2.id + "][" + m3.id + "]", new FormControl("chk[" + m1.id + "][" + m2.id + "][" + m3.id + "]"))
          this.newRequest.controls["chk[" + m1.id + "][" + m2.id + "][" + m3.id + "]"].setValue(true)
          } else if (m3 != undefined && m3.input == "radio") {
            this.checklist["chk[" + m1.id + "][" + m2.id + "]"] = m3.id;
            this.newRequest.addControl("chk[" + m1.id + "][" + m2.id + "]", new FormControl());
            this.newRequest.controls["chk[" + m1.id + "][" + m2.id + "]"].setValue(m3.id)
          } else if (m3 != undefined && m3.input == "textarea") {
              this.checklist["chk[" + m1.id + "][" + m2.id + "][" + m3.id + "]"] = object.target.value
              this.newRequest.addControl("chk[" + m1.id + "][" + m2.id + "][" + m3.id + "]", new FormControl("chk[" + m1.id + "][" + m2.id + "][" + m3.id + "]"));
              this.newRequest.controls["chk[" + m1.id + "][" + m2.id + "][" + m3.id + "]"].setValue(object.target.value)
              delete this.checklist["chk[" + m1.id + "][" + m2.id + "]"]
              this.newRequest.removeControl("chk[" + m1.id + "][" + m2.id + "]");

          } else if (m3 != undefined && m3.input == "imageupload") {
            // this.checklist["chk[" + m1.id + "][" + m2.id + "]"] = object.target.files[0]
            // this.newRequest.addControl("chk[" + m1.id + "][" + m2.id + "]", new FormControl("chk[" + m1.id + "][" + m2.id + "]"));
            // this.newRequest.controls["chk[" + m1.id + "][" + m2.id + "]"].setValue(object.target.files[0])
            this.checklist['chk_' + m1.id + "_" + m2.id + "_" + m3.id] = object.target.files[0]
            this.newRequest.addControl('chk_' + m1.id + "_" + m2.id + "_" + m3.id, new FormControl('chk_' + m1.id + "_" + m2.id + "_" + m3.id));
            this.newRequest.controls['chk_' + m1.id + "_" + m2.id + "_" + m3.id].setValue(object.target.files[0]);
            this.uploadImage(object,m3);
          }
      } else {
        if (m2.input == "checkbox") { // always true condition , second child will always be checkbox
          obj = {["chk[" + m1.id + "][" + m2.id + "]"]: true}
          this.checklist["chk[" + m1.id + "][" + m2.id + "]"] = true;
          this.newRequest.addControl("chk[" + m1.id + "][" + m2.id + "]", new FormControl("chk[" + m1.id + "][" + m2.id + "]"));
          this.newRequest.controls["chk[" + m1.id + "][" + m2.id + "]"].setValue(true)
        }
        }
        } else {
          if (m2.input == "checkbox") { // always true condition , second child will always be checkbox\
            // console.log("y11")
            delete this.checklist["chk[" + m1.id + "][" + m2.id + "]"]
            this.newRequest.removeControl("chk[" + m1.id + "][" + m2.id + "]")
          }
          let rejectedEle = <HTMLButtonElement> document.getElementById('rejected-' + m1.id + '-' + m2.id);
          if (rejectedEle) {
            rejectedEle.disabled = false;
            this.deleteRejectedJob(m1, m2, m3);
          }
          if (m2.children) {
            // control will be here if m3 is present
            if (m3 != undefined && m3.input == "checkbox") {
            delete this.checklist["chk[" + m1.id + "][" + m2.id + "][" + m3.id + "]"]
            this.newRequest.removeControl("chk[" + m1.id + "][" + m2.id + "][" + m3.id + "]")
            // delete this.checklist["chk[" + m1.id + "][" + m2.id + "]"]
            // this.newRequest.removeControl("chk[" + m1.id + "][" + m2.id + "]")
          } else if (m3 != undefined && m3.input == "radio") {
            delete this.checklist["chk[" + m1.id + "][" + m2.id + "]"]
            this.newRequest.removeControl("chk[" + m1.id + "][" + m2.id + "]")
          } else {
            for (let i in m2.children) {
              let m3 = m2.children[i];
              if (m3 != undefined && m3.input == "checkbox") {
                delete this.checklist["chk[" + m1.id + "][" + m2.id + "][" + m3.id + "]"]
                this.newRequest.removeControl("chk[" + m1.id + "][" + m2.id + "][" + m3.id + "]")
              } else if (m3 != undefined && m3.input == "radio") {
                delete this.checklist["chk[" + m1.id + "][" + m2.id + "]"]
                this.newRequest.removeControl("chk[" + m1.id + "][" + m2.id + "]")
              } else if (m3 != undefined && m3.input == "imageupload") {
                delete this.checklist["chk[" + m1.id + "][" + m2.id + "][" + m3.id + "]"]
                this.newRequest.removeControl("chk[" + m1.id + "][" + m2.id + "][" + m3.id + "]");
              } else if (m3 != undefined && m3.input == "textarea") {
                delete this.checklist["chk[" + m1.id + "][" + m2.id + "][" + m3.id + "]"]
                this.newRequest.removeControl("chk[" + m1.id + "][" + m2.id + "][" + m3.id + "]");
              }
            }
      }
    }
  }
  if (m3) {
    let l2 = null;
    let l3CId = null;
    if (typeof object.srcElement == 'undefined') {
      l2 = object.source._elementRef.nativeElement.parentElement.parentElement.previousElementSibling.id;
      l3CId = object.source._elementRef.nativeElement.parentElement.parentElement.id;
    } else {
      if (object.srcElement.nodeName != 'TEXTAREA') {
        if (m3.input != "imageupload") {
          l2 = object.srcElement.parentElement.parentElement.parentElement.previousElementSibling.id;
          l3CId = object.srcElement.parentElement.parentElement.parentElement.id;
        }
      }
    }

    let container = document.getElementById(l3CId);
    let unChkParent = false;
    let box = (container ? container.querySelectorAll('input[type="checkbox"]') : []);
    if (box.length != 0) {
      box = container.querySelectorAll('input[type="checkbox"]:checked');
      unChkParent = (box.length == 0 ? true : unChkParent);
    }
    if (unChkParent) {
      let l2c = document.getElementById(l2);
      let l2Cbox = l2c.querySelectorAll('input[type="checkbox"]:checked');
      if (l2Cbox.length) {
        document.getElementById(l2Cbox[0].id).click();
      }
    }

  }
  this.checkChild(pid, null);
  // console.log("new_list=", this.checklist)
}
getTextValue(l1, l2, l3,type) {
  let data = [], txtValue = '';
  if (this.serviceRequestDetails) {
    data = this.serviceRequestDetails.service_request_list;
    for (var i in data) {
      if (data[i].id == l1.id && data[i].children && data[i].children.length) {

        let snd = data[i].children;
        for (var j in snd) {
          if (snd[j].id == l2.id && snd[j].children && snd[j].children.length) {
            let schild = snd[j].children;
            for (var k in schild) {
              if(schild[k].id==l3.id){
                txtValue=schild[k].value;
                break;
              }
            }
          }
          if(txtValue!=''){
            break;
          }
        }
      }
      if(txtValue!=''){
        break;
      }
    }
  }
  if(txtValue!=''){
    if (!this.formEdited || this.populates[l1.id][l2.id][l3.id]) {
      this.checklist["chk[" + l1.id + "][" + l2.id + "][" + l3.id + "]"] =txtValue;
      this.newRequest.addControl("chk[" + l1.id + "][" + l2.id + "][" + l3.id + "]", new FormControl("chk[" + l1.id + "][" + l2.id + "][" + l3.id + "]"));
      this.newRequest.controls["chk[" + l1.id + "][" + l2.id + "][" + l3.id + "]"].setValue(txtValue)
      this.populates[l1.id][l2.id][l3.id]=false;
      this.formEdited = true;
    }
  }
  if(type=='image' && this.jobImageDelete){
    return '';
  }
  return txtValue;
}
isvChecked() {

  let data = [], checked = false,ele,ele2;
  if (this.serviceRequestDetails && this.populates.length) {
    data = this.serviceRequestDetails.service_request_list;
    for (var i in data) {
      // this.populates[data[i].id] = [];
      if (data[i].children.length) {
      let snd = data[i].children;
      for (var j in snd) {

        ele2=document.getElementById('request-check-'+data[i].id+'-'+snd[j].id+'-input');
        if(ele2){
         ele2.click();
        }
        if (typeof snd[j].children!='undefined'&& snd[j].children.length) {

          let schild = snd[j].children;
          for (var k in schild) {
            this.populates[data[i].id][snd[j].id][schild[k].id] = true;
            ele=document.getElementById('requestRadio-'+data[i].id+'-'+snd[j].id+'-'+schild[k].id+'-input');
            if(ele){
              ele=<HTMLInputElement>ele;
              try{
                //ele.checked=true;
                ele.click();
              }catch(e){console.log(e)}

            }else{
              ele=document.getElementById('requestid-'+data[i].id+'-'+snd[j].id+'-'+schild[k].id+'-input');
              ele && ele.click();
            }
          }
        }else{
          this.populates[data[i].id][snd[j].id]=true;
        }
      }
    }
  }
}
return true;
}
makeSelections(sData) {
  let data = [];

  if (sData!=null && sData.length>0) {
    this.populates=[];
    data = sData;
    for (var i in data) {
      this.populates[data[i].id] = [];
      if (data[i].children.length) {
        for (var j in data[i].children) {

          this.populates[data[i].id][data[i].children[j].id] = false;

          if (typeof data[i].children[j].children!='undefined' && data[i].children[j].children.length) {
            this.populates[data[i].id][data[i].children[j].id] = [];
            this.populates[data[i].id][data[i].children[j].id+'level'] = false;
            let schild = data[i].children[j].children;
            for (var k in schild) {
              this.populates[data[i].id][data[i].children[j].id][schild[k].id] = false;
            }
          }
        }
      }
    }
  }

  if(sData==null && this.populates.length){
    this.isvChecked();
  }
  return true;
}
// isPopulates(pId, l1, l2, l3,p) {}
saveServiceRequest() {
var stringifiedFormData = globalFunctions.stringifyFormData(this.newRequest.value, 'User');
if(!this.dateValid || (this.date != '' && this.time == '')){
  this.globalService.snackbar("error", 'Service Schedule Date is invalid');
  return;
}
if(this.newRequest.value.request_date == 'undefined' || (typeof this.newRequest.value.request_date == 'undefined')){
  this.newRequest.value.request_date="";
}
let data = new FormData();

for(let i in this.newRequest.value){
  if(i=='request_date'){
    data.append(i, moment(this.selectedDateAndTime).format('MM/DD/YYYY hh:mm A Z'));
  }else{
    data.append(i, this.newRequest.value[i]);
  } 
}
this.globalService.formData(globalConstants.API_EDIT_SERVICE_REQUEST_URL, data).subscribe((parsedResponse:any) => {
  if (parsedResponse.result == "1") {
    this.globalService.snackbar("success", parsedResponse.message);
    this.router.navigate(['shops/list-service-requests']);
  } else {
    this.globalService.snackbar("error", parsedResponse.message);
  }
});

return false;
}

deleteRejectedJob(m1, m2, m3) {
  if (m3) {
    this.newRequest.removeControl("rej[" + m1.id + "][" + m2.id + "][" + m3.id + "][labor_price]")
    this.newRequest.removeControl("rej[" + m1.id + "][" + m2.id + "][" + m3.id + "][labor_time]")
    this.newRequest.removeControl("rej[" + m1.id + "][" + m2.id + "][" + m3.id + "][parts]")
    this.newRequest.removeControl("rej[" + m1.id + "][" + m2.id + "][" + m3.id + "][generic_jobs]")
  } else {
    this.newRequest.removeControl("rej[" + m1.id + "][" + m2.id + "][labor_price]")
    this.newRequest.removeControl("rej[" + m1.id + "][" + m2.id + "][labor_time]")
    this.newRequest.removeControl("rej[" + m1.id + "][" + m2.id + "][parts]")
    this.newRequest.removeControl("rej[" + m1.id + "][" + m2.id + "][generic_jobs]")
  }
}

checkChild(pId, ele) {

  let parentEle = document.getElementById('expansion-' + pId);
  if (ele) {
    let container = document.getElementById(ele)
    let box = container.querySelectorAll('input[type="checkbox"]:checked');
    let unChkChild = false;
    let bType='checkbox';
    if (box.length) {
      unChkChild = true;
    } else {
      box = container.querySelectorAll('input[type="radio"]:checked');
      bType='radio';
      unChkChild=(box.length?true:unChkChild);
    }
    if (unChkChild) {
      for (var i in box) {
        if (typeof box[i] == 'object') {
          if(bType=='checkbox'){
            document.getElementById(box[i].id).click();
          }else{
            let radioBox=<HTMLInputElement>box[i];
            radioBox.checked=false;
          }
        }
      }
    }
  }
  if (parentEle != null && parentEle != null) {
    var checkBoxesSelected = parentEle.querySelectorAll('input[type="checkbox"]:checked');
    if (checkBoxesSelected && checkBoxesSelected.length) {
      parentEle.classList.add("checked");
    } else {

      checkBoxesSelected = parentEle.querySelectorAll('input[type="radio"]:checked');
      if (checkBoxesSelected && checkBoxesSelected.length) {
        parentEle.classList.add("checked");
      }else{
        parentEle.classList.remove("checked");
      }
    }
  }
}
deleteJobsimage() {
  if (confirm('Are you sure you want to delete this image.')) {

  }
}
getFormatedDate(){
  let d=this.scheduleDate.split(' ');
  let finalDate=d[0].split('-');
  let fTime=d[1].split(':');
  let fdate=this.dateSartWithZero(finalDate[1])+'/'+this.dateSartWithZero(finalDate[2])+'/'+finalDate[0];
  if(parseInt(fTime[0])>11){
    fdate=fdate+' '+this.dateSartWithZero((parseInt(fTime[0])-12))+':'+this.dateSartWithZero(fTime[1]);
    fdate=fdate+':PM';
  }else{
    fdate=fdate+' '+this.dateSartWithZero(fTime[0])+':'+this.dateSartWithZero(fTime[1]);
    fdate=fdate+':AM';
  }
  // console.log("fdate", fdate);
  return fdate;
}
dateSartWithZero(value){
  let padding='00'+value;
  return padding.slice(-2);
}

setTime(hoursMinutes){
const time    = hoursMinutes.split(":");
let  hour     = time[0];
let  minutes  = "00";
if(time[1] > 0 && time[1] <= 15){
  minutes = "15";
}else if(time[1] > 15 && time[1] <= 30){
  minutes = "30";
}else if(time[1] > 30 && time[1] <= 45){
  minutes = "45";
}else if(time[1] > 45){
  hour = Number(hour)+1;
}   

hour    = hour.toString().padStart(2, "0");
minutes = minutes.toString().padStart(2, "0");
return hour+":"+minutes;
}

dateChange(event){
  let date = new Date(event.target.value);
  this.date = this.formatDateToString(date);
  this.dateValid = true;
  this.updateDateTime();
}

updateDate(event){
  let date = new Date(event.target.value);
  var currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1);
  if(this.globalService.isValidDate(date) && (this.isDIY || currentDate <= date)){
      this.date = this.formatDateToString(date);
      this.calDate = date;
      this.dateValid = true;
      this.updateDateTime();
  }else{
      this.submited = true;
      this.dateValid = false;
  }
}

startTimeHandler(event) {
  this.time = event;
  // let currentTime = this.setTime(new Date().getHours()+':'+new Date().getMinutes());
  // if(new Date().toDateString() == new Date(this.date).toDateString() && this.time <= currentTime){
  //     this.time = currentTime;
  // }
  this.updateDateTime();
}

updateDateTime(){
  let time = '';
  if(this.date == '' || this.time == ''){
      return;
  }
  if(parseInt(this.time.split(':')[0]) >= 12){
      time = this.time.replace(this.time.split(':')[0],(parseInt(this.time.split(':')[0])-12).toString()) + ' PM';
  }else{
      if(this.time.split(':')[0] == '00'){
          time = this.time.replace('00','12') + ' AM';
      }else{
          time = this.time + ' AM';
      }    
  }
  this.newRequest.patchValue({
      request_date: this.date + ' ' + time
  });
  console.log(this.date + ' ' + time);
}

getServiceDetail(){

  let content = "customer_id=" + this.customerId + "&service_request_id=" + this.serviceRequestId+'&user_type=User&user_id='+this.currentShop['id'];
  this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_SERVICE_REQUEST_VIEW_URL, content)
  .subscribe((data:any) => {
    // console.log("res= ",data);
    if (data.result == 1) {
        let detail=data.data;
        this.reinitializeFormBuilder(detail);
        this.requestImages=detail.request_images;
        this.requestDoc=detail.request_documents;
        /* Find insurance registration date */
        this.insExpDate = detail.insurance_expiration_date;
        this.regExpDate = detail.registration_expiration_date;
       /* */
        this.getVechile(detail.car_id);
        this.makeSelections(null);
        this.showSelectedx();
    }
  });
}
    uploadImage(event,m3){
        if (event.target.files && event.target.files[0]) {
        let valid=this.globalService.validateFiles('image',event.target.files);
         if(!valid){
            this.deleteJobImg(m3);
            return false;
          }
            let file = event.target.files[0];
            var reader = new FileReader();
            reader.onload = (e) => {
                this.jobImages = <string>e.target['result'];
                this.ref.detectChanges();
            }
            reader.readAsDataURL(file);
        }
    }
    deleteJobImg(m3){
        let f= <HTMLButtonElement> document.getElementById(m3.id+'jobs');
        f.value="";
        this.jobImages='';
    }

    chkDate(date){
      return this.globalService.isOldDate(date) ? true : false
    }

    /* DATE FORMAT FUNCTION */
    formatDateToString(date){
       // 01, 02, 03, ... 29, 30, 31
       var dd = (date.getDate() < 10 ? '0' : '') + date.getDate();
       // 01, 02, 03, ... 10, 11, 12
       var MM = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1);
       // 1970, 1971, ... 2015, 2016, ...
       var yyyy = date.getFullYear();

       // create the format you want
       return (MM + "/" + dd + "/" + yyyy);
    }
    getVechile(carId){

      this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_CUSTOMER_VEHICLES_URL, "customer_id=" + this.customerId+'&vehicle_id='+carId+ "&from_v2=1&shop_edit=1")
      .subscribe((res:any) => {
        let cars = res.data;
        if(res.result == 1){
          if (cars.length) {
            for (let i in cars) {
            cars[i].name="";
            if (cars[i].year) {
                cars[i].name = cars[i].year + ' ';
            }
            if(cars[i].make){
              cars[i].name += cars[i].make + ' ';
            }
            if(cars[i].model){
              cars[i].name +=  cars[i].model + ' ';
            }
            if (cars[i].license_plate != '') {
              cars[i].name += ' ' + cars[i].license_plate;
            }
            if(!cars[i].name){
              cars[i].name="N/A";
            }
              this.serviceVehicles.push(cars[i]);
            }
          }
          // this.serviceVehicles = cars;
        }
        this.selectedVehicle();
      });
    }
    getSubitemInputId(id){
      let data = [], txtValue = '';
      if (this.serviceRequestDetails) {
        data = this.serviceRequestDetails.service_request_list;
        for (var i in data) {
          if (data[i].children.length) {
    
            let snd = data[i].children;
            for (var j in snd) {
              if (snd[j].children.length) {
                let schild = snd[j].children;
                for (var k in schild) {
                  if (schild[k].id ==id ) {
                    txtValue = schild[k].subitem_input_id;
                    break;
                  }
                }
              }
              if (txtValue != '') {
                break;
              }
            }
          }
          if (txtValue != '') {
            break;
          }
        }
      }
      return txtValue;
    }
    deleteImgDoc(event,id,type){
      let msg='Are you sure you want to delete this document?';
      let fdata='request_id='+this.serviceRequestDetails.id;
      let url=globalConstants.API_DELETE_REQUEST_DOCUMENT_URL;

      if(type=='image'){
        msg='Are you sure you want to delete this image?';
        url=globalConstants.API_DELETE_REQUEST_IMAGE_URL;
        fdata+='&request_image_id='+id;
      }else if(type=='document'){
        fdata+='&request_document_id='+id;
      }else if(type=='job_image'){
        id=this.getSubitemInputId(id);
        msg='Are you sure you want to delete this image?';
        fdata='id='+ btoa(id)+'&rid='+this.serviceRequestDetails.id;
        url=globalConstants.API_DELETE_SERVICE_REQUEST_IMAGE_URL;
      }
      this.globalService.confirmDialog("deletes", msg).subscribe((data) => {
        if (data != undefined && data.toString() == "deletes") {
          this.subscriptionDataForAPI =  this.globalService.callAPI(url, fdata)
          .subscribe((resData:any) => {
            if (resData.result == 1) {
              if(type=='document'){
                this.requestDoc.splice(event,1);
              }else if(type=='image'){
                this.requestImages.splice(event,1);
              }else if(type=='job_image'){
                this.jobImageDelete=true;
                $('#other_image').parent('li').remove();
              }else{
                event.target.parentElement.style.display='none';
              }
              this.globalService.snackbar("success", resData.message);
            } else {
              this.globalService.snackbar("error", resData.message);
            }
          })
        }
      })
    }
    changeListner(event, rendering_tag_id) {
      this.globalService.imageLoader();
      var html = "";
      let ele = <HTMLInputElement> document.getElementById('add' + rendering_tag_id);


 if (event.target.files && event.target.files[0]) { 
      // console.log(event.target.files); 
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
      var reader = new FileReader(); 
      let file = event.target.files[i];
 // console.log(event.target.files[i]); 
        let valid=true;
        if (rendering_tag_id == 'request_document') {
          valid=this.globalService.validateFiles('docpdf',event.target.files);
        }else if (rendering_tag_id == 'request_image'){
          valid=this.globalService.validateFiles('image',event.target.files);
        }
        if(!valid){
          if (ele) {
            ele.value = '';
          }
          // event.target.value = "";
          return false;
        }
        if (valid) {
          if (file.size < 1e+7) {

            let data = new FormData();
            let url = globalConstants.API_NEW_SERVICE_REQUEST_FILE;
            if (rendering_tag_id == 'request_document') {
              data.append('new_service_file', 'doc');
            } else if (rendering_tag_id == 'request_image') {
              if (file.type == 'application/pdf') {
                this.globalService.snackbar("error", 'Please upload a valid file.');
                return false;
              }
            }
            data.append(rendering_tag_id, file);
            data.append('customer_id', this.customerId);
            data.append('request_id', this.serviceRequestId);
            data.append('from_v2', '1');


            this.globalService.formData(url, data).subscribe((resData:any) => {
              // let resData = JSON.parse(response["_body"]);
              if (resData.result == 1) {
                if (rendering_tag_id == 'request_document') {
                  this.uploadDocImg('doc', resData.data.name);
                } else if (rendering_tag_id == 'request_image') {
                  this.uploadDocImg('request_image', resData.data.name);
                }
                // event.target.value = "";
              } else {
                if (ele) {
                  ele.value = '';
                }
                this.globalService.snackbar("error", resData.message);
              }
            });
          }
          else {
            if (ele) {
              ele.value = '';
            }
            this.globalService.snackbar("error", 'Please upload a valid file.');
          }
        } else {
          if (ele) {
            ele.value = '';
          }
          this.globalService.snackbar("error", 'Please upload a valid file.');
        }
        // event.target.value = "";
      } }
    }
    uploadDocImg(type, val) {

      let url = '';
      let data = 'from_v2=1&customer_id=' + this.customerId + '&request_id=' + this.serviceRequestId;
      if (type == 'doc') {
        data = data + '&request_document=' + val;
        url = globalConstants.API_UPLOAD_SERVICE_REQUEST_DOCUMENT_URL;
      } else {
        data = data + '&request_image=' + val;
        url = globalConstants.API_UPLOAD_SERVICE_REQUEST_IMAGE_URL;
      }
      this.subscriptionDataForAPI = this.globalService.callAPI(url, data).subscribe((resData:any) => {
        if (resData.result == 1) {
          if (type == 'doc') {
            this.requestDoc.push({id: resData.data.id, document_name: resData.data.document_name});
          } else {
            this.requestImages.push({id: resData.data.id, image_name: resData.data.image_name});
          }
          this.globalService.snackbar("success", resData.message);
        } else {
          this.globalService.snackbar("error", resData.message);
        }
      });
    }
    getFileIcons(str){
      let ext=str.substr(str.lastIndexOf(".")+1);
      let type=0;
      if(ext!=''){
        type= this.globalService.getFileIcon({type:ext.toLowerCase()});
      }
      // console.log(str,ext,type);
      return type;
     }
    
     /* image rotation dialog  */
  imageToolDialog(current,i,type,imgaar): void {
    let dialogRef = this.dialog.open(ImagetooldialogComponent, {
        panelClass: ['imageToolDialog'],
        width: "100%",
        data: {event: current,index:i,imgType:type,imgArray:imgaar,customerId:this.globalService.getCurrentUser()["id"]}
    });

    dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
    });
  }

  invokeDownload(url,type,fileName=''){
    let localFileName = 'DownloadedFile',localNameExt,localName;
    if(type != ''){
      localName = fileName.split('.');
      localNameExt = localName[localName.length-1];
      localFileName = type+'.'+localNameExt;
    }
    //console.log(localFileName);
    this.globalService.downloadAsset(url,localFileName);
  }
  showSelectedx() {
    let detail = this.serviceRequestDetails.service_request_list;
    for (var p in detail) {
      for (var i in this.serviceRequestList) {
        if (detail[p].id==this.serviceRequestList[i].id) {
          this.serviceRequestList[i].is_searchable = 2;
          break;
        }
      }
    }
  }
  carAttribute(){
    return false;
}
observableSourceForx = (keyword: any): Observable<any[]> => {
    let url: string = globalConstants.API_search_service_requests_list+'?keyword='+keyword;
    if (typeof keyword !='undefined' && keyword.length >= 3) {
    let f=[];
      //by ankur
     return this.globalService.getAPI(url).map((res:any)=>{
          let json = res;
          this.globalService.authText(json);
          if(json && json != null && typeof json.data != 'undefined'){
            json.data.forEach(function(x){
              f.push(x);
            });
          }
          return f;
        });
    } else {
      return Observable.of([]);
    }
  }
  /*
  * @trackID <:CAR-418> Radio Box checked/unchecked 
  * @updated 2020-01-17
  */
  getSelectedOptionForx(opt){

            let l1=opt.level1Id;
            let l2=opt.level2Id;
            let l3=opt.level3Id;
            for(var i in this.serviceRequestList){
                if(this.serviceRequestList[i].id==l1){
                    var target= '#expansionHead-'+ (parseInt(i)+1);
                    if(typeof this.serviceRequestList[i].children != 'undefined'){
                        let l1child=this.serviceRequestList[i].children;
                        for(var j in l1child){
                            if(l1child[j].id==l2){
                                if(typeof l1child[j].children != 'undefined'){
                                    let l2child=l1child[j].children;
                                    for(var k in l2child){
                                        if(l2child[k].id==l3){
                                            this.serviceRequestList[i].is_searchable=2;
                                            this.serviceRequestList[i].children[j].is_searchable=2;
                                            this.serviceRequestList[i].children[j].children[k].is_searchable=2;
                                            let ele=<HTMLInputElement>document.getElementById('request-check-'+l1+'-'+l2+'-input');
                                            let container = document.getElementById('second-level-child-'+l2);
                                            let eleL3=<HTMLInputElement>document.getElementById('requestid-'+l1+'-'+l2+'-'+l3+'-input');
                                            let eleR3=<HTMLInputElement>document.getElementById('requestRadio-'+l1+'-'+l2+'-'+l3+'-input');
                                            let box = container.querySelectorAll('input[type="checkbox"]:checked');
                                            if(eleR3){
                                                box = container.querySelectorAll('input[type="radio"]:checked');
                                            }
                                            if(ele && box.length==0){
                                              if(!jQuery(ele).is(':checked')){
                                                ele.click();
                                              }
                                            }
                                            eleL3 = (eleL3 || eleR3);
                                            this.searchableJobSelect(l1,l2,l3,ele,eleL3,target);
                                            break ;
                                        }
                                    }
                                } else{
                                    
                                    this.serviceRequestList[i].is_searchable=2;
                                    this.serviceRequestList[i].children[j].is_searchable=2;
                                    let ele2=<HTMLInputElement>document.getElementById('request-check-'+l1+'-'+l2+'-input');
                                    this.searchableJobSelect(l1,l2,l3,ele2,'',target);
                                    this.checkChild(l1, null);
                                    break ;
                                }
                            }
                        }
                    }
                }
            }
          }
          autocompleListFormatter(opt){
            return opt.value;
          }
          searchableJobSelect(l1, l2, l3, ele2, ele3, target) {
              let t = false;
              if (l3 == "") {
                  t = jQuery('#request-check-' + l1 + '-' + l2 + '-input').is(':checked');
                  if (ele2 && t == false) {
                      ele2.click();
                  }
              } else {
                  t = jQuery('#requestid-' + l1 + '-' + l2 + '-' + l3 + '-input').is(':checked');
                  if (ele3 && t == false) {
                      ele3.click();
                  }
              }
              setTimeout(() => {
                  var body = $("html, body");
                  body.animate({
                      scrollTop: jQuery(target).offset().top - 90
                  }, 500);
              }, 300);
              let txt = <HTMLInputElement>document.getElementById('searchCarAattribute')
              txt.value = '';
              if (!jQuery(target).hasClass('mat-expanded')) {
                jQuery(target).click();
              }
          }

          onDateChange(event:any){
              this.selectedDateAndTime = event.value;
          }       

          
  ngOnDestroy(){
    this.subscriptionDataForAPI.unsubscribe();
}  

}
