<div class="panel panel-primary" id="mainCompotents">
  <div class="panel-heading padding-left-15">
    <h3 class="panel-title">Internal Note LIST</h3>
    <button mat-mini-fab class="custom-btn-back add-internal-note-button" title="Add Note"  (click)="internalNotes();" >
      <i class="fa fa-fw fa-plus margin-right-5"></i><span class="hidden-xs">Add Note</span>
    </button>
  </div>
  <div class="panel-body bgcolor-lightgrey">
    <div class="display-full relative">
      <mat-expansion-panel hideToggle="false" class="search-filter search-employee relative mat-elevation-z1">
        <mat-expansion-panel-header collapsedHeight="53px" expandedHeight="53px">
          <strong><i class="fa fa-search fa-fw"></i>&nbsp;Search Filters</strong>
        </mat-expansion-panel-header>
        <form id="advanceSearchForm" class="collapse in" aria-expanded="true">
          <div class="panel-body">
            <div class="col-sm-12">
              <div class="col-sm-3">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                  <mat-select class="form-control input-lg custom-form-input"
                      [(ngModel)]="options.search_by"
                      [ngModelOptions]="{standalone: true}"
                      placeholder="Search By">
                      <mat-option  *ngFor="let opt of searchByArray" [value]="opt.id">{{opt.text}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-sm-3">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                  <input matInput placeholder="Search Keyword" title="Search Keyword"
                    [(ngModel)]="options.keyword" [ngModelOptions]="{standalone: true}"
                    class="form-control input-lg custom-form-input" />
                </mat-form-field>
              </div>
              <div class="col-sm-3 details-input">
                  <mat-form-field class="car-input btn-block car-datePicker" floatPlaceholder="never" appearance="fill">
                      <input matInput (dateChange)="setDate($event)" class="form-control input-lg custom-form-input" [(ngModel)]="options.datef" [ngModelOptions]="{standalone: true}"  placeholder="Added on from" [matDatepicker]="filerPicker" onkeydown="return false;">
                             <mat-datepicker-toggle matSuffix [for]="filerPicker"></mat-datepicker-toggle>
                      <mat-datepicker #filerPicker [touchUi]="setTouchViewValue()"></mat-datepicker>
                  </mat-form-field>
               </div>
              <div class="col-sm-3 details-input">
                <mat-form-field class="car-input btn-block car-datePicker" floatPlaceholder="never" appearance="fill">
                    <input matInput class="form-control input-lg custom-form-input" [min]="options.datef" [(ngModel)]="options.datet" [ngModelOptions]="{standalone: true}"  placeholder="Added on to" [matDatepicker]="filerPicker2" onkeydown="return false;">
                           <mat-datepicker-toggle matSuffix [for]="filerPicker2"></mat-datepicker-toggle>
                    <mat-datepicker #filerPicker2 [touchUi]="setTouchViewValue()"></mat-datepicker>
                </mat-form-field>
             </div>
             <div class="col-sm-3">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                  <mat-select class="form-control input-lg custom-form-input"
                      [(ngModel)]="options.status"
                      [ngModelOptions]="{standalone: true}"
                      placeholder="Status">
                      <mat-option  *ngFor="let opt of status;let i=index" [value]="i">{{opt}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <mat-action-row class="search-filter-footer">
            <button mat-raised-button color="accent" type="submit" (click)="search()">
              Search
            </button>
          </mat-action-row>
        </form>
      </mat-expansion-panel>
      <button mat-button type="button" #clearBtn [class.active-search]="isAnyOptionActive(clearBtn)" class="clear-btn" (click)="clearSearch()">
        Clear Filter
      </button>
    </div>

    <div class="car-datatable uc-datatable mat-elevation-z1">
      
      <div class="table-responsive relative">
        <mat-table #table [dataSource]="dataSource" matSort class="uc-table-responsive service-list datatable-loader-bg">
          <ng-container matColumnDef="ID">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> ID </mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="auto" data-val="ID"> {{row.id}} </mat-cell>
          </ng-container>
          <!-- Title Column -->
          <ng-container matColumnDef="Name">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Title </mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="auto" data-val="Name"> {{row.name}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Status </mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="auto" data-val="status"> {{getStatus(row.status)}} </mat-cell>
          </ng-container>

          <!-- Added on Column -->
          <ng-container matColumnDef="Added On">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Added On </mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="auto" data-val="Added On"> {{row.added_on}} </mat-cell>
          </ng-container>

          <!-- Action Column -->
          <ng-container matColumnDef="Actions">
            <mat-header-cell *matHeaderCellDef data-role="button"> Actions </mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="button">
              <button mat-button class="list-action-btn" color="accent" [matMenuTriggerFor]="menu" disableRipple>
                Action<mat-icon>arrow_drop_down</mat-icon>
              </button>
              <mat-menu #menu="matMenu" class="car-icon-menu disable-no-bg" [overlapTrigger]="false">
                <button class="mat-menu-item" *ngIf="row.added_by==user.id" disableRipple type="button" (click)="edit(row)"> <i class="fa fa-edit"></i>Edit</button>
                <button class="mat-menu-item" disableRipple type="button" (click)="viewNote(row)"> <i class="fa fa-eye"></i>View</button>
                <button class="mat-menu-item hide" disableRipple type="button" (click)="markAsResolved(row)"> <i class="fa fa-"></i>Mark as Resolved</button>
              </mat-menu>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;" class="mat-row"></mat-row>
        </mat-table>
        <div class="padding-15 text-center" [style.display]="getTotalRecords() < 1 ? '' : 'none'">
          No Records Available
        </div>
      </div>
      <div class="display-full relative" [style.display]="getTotalRecords() < 1 ? 'none' : ''">
        <mat-paginator class="car-pagination" #paginator [length]="getTotalRecords()" [pageIndex]="0"
          [pageSize]="options.limit" [pageSizeOptions]="[5, 10, 25, 100]" (page)="paginateByLimit($event)">
        </mat-paginator>

        <div class="mat-paginator table-paging">
          <div class="mat-paginator-range-label margin-left-30 margin-right-30">{{options.start+1}} -
            {{getUpperLimit(options.start + options.limit,getTotalRecords())}} of {{getTotalRecords()}}</div>
          <button mat-icon-button (click)="paginate($event,'previous');" [disabled]="firstList()">
            <mat-icon>navigate_before</mat-icon>
          </button>
          <button mat-icon-button (click)="paginate($event,'next');" [disabled]="lastList()">
            <mat-icon>navigate_next</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>