<div class="panel panel-primary">
    <div class="panel-heading padding-left-15">
        <h3 class="panel-title">Live Chat Detail</h3>
        <button mat-mini-fab class="custom-btn-back" (click)="globalService.goBack();">
            <mat-icon>arrow_back</mat-icon>
        </button>
    </div>
    <div class="panel-body bgcolor-lightgrey" *ngIf="showServiceDetail">
        <div class="jumbotron inspection-report padding-25 car-view-str margin-bottom-15">
            <h3 class="margin-top-0">Live Chat Information</h3>
            <hr>
            <div class="row">
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-sm-6">
                            <label class="control-label">Number :</label>
                        </div>
                        <div class="col-sm-6">
                            <span class="pull-left" id="serviceRequestId">{{serviceInfo.id}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-sm-6"><label class="control-label">Title :</label></div>
                        <div class="col-sm-6"><span  id="shopName">{{serviceInfo.title}}</span></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-sm-6"><label class="control-label">Customer Name :</label></div>
                        <div class="col-sm-6"><span  id="vehicleName" data-original-title="" title="">{{serviceInfo.first_name}} {{serviceInfo.last_name}}</span></div>
                    </div>
                </div>
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-sm-6"><label class="control-label">Email :</label></div>
                        <div class="col-sm-6 break-all"><a *ngIf="isViewCustomerInfo" class="text-black" href="mailto:{{serviceInfo.email}}"><span>{{serviceInfo.email}}</span></a>
                            <span *ngIf="isViewCustomerInfo==false">-</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-sm-6"><label class="control-label">Contact Number :</label></div>
                        <div class="col-sm-6"><a *ngIf="isViewCustomerInfo" class="text-black" href="tel:{{serviceInfo.mobile_phone}}">{{serviceInfo.mobile_phone}}</a>
                            <span *ngIf="isViewCustomerInfo==false">-</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-sm-6"><label class="control-label">Add Date :</label></div>
                        <div class="col-sm-6"><span>{{serviceInfo.add_date}}</span></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-sm-6"><label class="control-label">Description :</label></div>
                        <div class="col-sm-6"><span  id="mobileNumber">{{serviceInfo.description}}</span></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="panel panel-title" style="box-shadow: none;">
            <div class="panel-group" id="inquiryDetail" role="tablist" aria-multiselectable="true">
                <div class="panel-heading expansion-pretend mat-elevation-z2 padding-0 margin-bottom-10">
                    <div class="panel-title padding-10">COMMENTS SECTION</div>
                    <span *ngIf="serviceInfoComments.length==0" class="display-full padding-15">No Comment Available</span>
                </div>
                <div class="display-full" *ngIf="serviceInfoComments.length > 0">
                    <mat-accordion class="new-request-expension display-full" displayMode="flat" *ngFor="let serviceInfoComment of serviceInfoComments;">
                                   <mat-expansion-panel hideToggle="false" expanded="true" class="break-word" style="box-shadow: none;">
                            <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px">
                                <ul class="list-inline display-full margin-bottom-0">
                                    <li>Added By: {{serviceInfoComment.first_name}}</li>
                                    <li class="pull-right">Date: {{serviceInfoComment.add_date}} </li>
                                </ul>
                            </mat-expansion-panel-header>
                            <span [innerHTML]="serviceInfoComment.description"></span>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>
        </div>
        <form [formGroup]="shopAddInquiry" (ngSubmit)="addComment()" novalidate id="addPartForm" class="form-inline col-sm-12 padding-0" role="form" method="POST">
            <div class="row">
                <div class="form-group col-sm-12">
                    <label for="description">Add Comment</label>
                    <div class="relative details-input">
                        <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                            <textarea matInput  placeholder="Write Your Comment Here" title="Write Your Comment Here" minHeight="200"  formControlName="inquiry_comment"  class=" input-lg custom-form-input" (keyup)="checkIfValid()"></textarea>
                            <mat-error *ngIf="shopAddInquiry.controls.inquiry_comment.hasError('required')">
                                Please add your <strong>Comment</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="form-group col-sm-12">
                    <mat-checkbox class="btn-block" labelPosition="after" (change)="showTaxExempted($event)" formControlName="inquiry_resolver" >
                        Mark Resolved
                    </mat-checkbox>
                </div>
            </div>
            <div class="row">
                <div class="display-full margin-top-5">
                    <div class="col-sm-6 col-lg-4">
                        <button type="button" mat-raised-button class="car-btn btn-block text-uppercase  btn-lg font-light fontcolor-white" id="saveClose" color="accent" title="Add Comment" [disabled]="submitted" (click)="addComment()">ADD COMMENT</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
