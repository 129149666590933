<div class="panel panel-primary" id="mainCompotents">
  <div class="panel-heading padding-left-15">
    <h3 class="panel-title">Coupon Codes</h3>
    <button *ngIf="isAddEnabled" mat-mini-fab class="custom-btn-back add-discount-button" title="Add New"
      (click)="addDiscount();">
      <i class="fa fa-fw fa-plus margin-right-5"></i><span class="hidden-xs">Add New</span>
    </button>
  </div>
  <div class="panel-body bgcolor-lightgrey">
    <div class="display-full relative">
      <mat-expansion-panel hideToggle="false" class="search-filter search-employee mat-elevation-z1 relative"
        [expanded]="openStateSearch">
        <mat-expansion-panel-header collapsedHeight="53px" expandedHeight="53px">
          <strong><i class="fa fa-search fa-fw"></i>&nbsp;Search Filters</strong>
        </mat-expansion-panel-header>
        <form id="advanceSearchForm" class="collapse in" aria-expanded="true">
          <div class="panel-body">
            <div class="col-sm-3">
              <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                <mat-select class="form-control input-lg custom-form-input" [(ngModel)]="options.search_by"
                  [ngModelOptions]="{standalone: true}" placeholder="Search By">
                  <mat-option *ngFor="let opt of searchByArray" [value]="opt.id">{{opt.text}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                <input matInput placeholder="Search Keyword" title="Search Keyword" [(ngModel)]="options.search_keyword"
                  [ngModelOptions]="{standalone: true}" class="form-control input-lg custom-form-input" />
              </mat-form-field>
            </div>
          </div>
          <mat-action-row class="search-filter-footer">
            <button mat-raised-button color="accent" type="submit" (click)="search($event)">
              Search
            </button>
          </mat-action-row>
        </form>
      </mat-expansion-panel>
      <button mat-button class="clear-btn" #clearBtn (click)="clearSearch()"
        [class.active-search]="checkFormEmpty(clearBtn)">
        Clear Filter
      </button>
    </div>
    <div class="car-datatable uc-datatable mat-elevation-z1">
      <div class="table-responsive relative">
        <mat-table #table [dataSource]="dataSource" matSort class="datatable-loader-bg uc-table-responsive">
          <!-- Color Column -->
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="name">Name</mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="name" data-val="Name"> {{row.name}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="code">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="name">Code</mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="name" data-val="Code"> {{row.code}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="added_by">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="name">Added By</mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="name" data-val="Added By"> {{row.added_by}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="name">Type</mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="name" data-val="Type"> {{row.type}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="IP Address">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="name">IP Address</mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="name" data-val="IP Address"> {{row.new_record_value}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="value">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="name">Value</mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="name" data-val="Value"> {{row.value}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="start_date">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="name">Start Date</mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="name" data-val="Start Date"> {{row.start_date}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="end_date">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="name">End Date</mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="name" data-val="End Date"> {{row.end_date}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="name">Status</mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="name" data-val="Status"> {{row.status}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="add_date">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="name">Added On</mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="name" data-val="Added On"> {{row.add_date}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="Actions">
            <mat-header-cell [ngClass]="{'hide':(!isEditEnabled && !isDelEnabled)}" *matHeaderCellDef data-role="button"> Actions </mat-header-cell>
            <mat-cell [ngClass]="{'hide':(!isEditEnabled && !isDelEnabled)}" *matCellDef="let row" data-role="button">
              <button mat-button class="list-action-btn" color="accent" [matMenuTriggerFor]="menu" disableRipple>
                Action<mat-icon>arrow_drop_down</mat-icon>
              </button>
              <mat-menu #menu="matMenu" class="car-icon-menu disable-no-bg" [overlapTrigger]="false">
                <button *ngIf="isEditEnabled" class="mat-menu-item" disableRipple type="button"
                  (click)="editDiscount(row)"> <i class="fa fa-edit"></i>Edit</button>
                <button *ngIf="isDelEnabled" class="mat-menu-item" disableRipple type="button"
                  (click)="deleteDiscount(row)"> <i class="fa fa-trash"></i>Delete</button>
              </mat-menu>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <div class="padding-15 text-center" [style.display]="getTotalRecords() < 1 ? '' : 'none'">
          No Records Available
        </div>
      </div>
      <div class="display-full relative" [style.display]="getTotalRecords() < 1 ? 'none' : ''">
        <mat-paginator class="car-pagination" #paginator [length]="getTotalRecords()" [pageIndex]="0"
          [pageSize]="options.limit" [pageSizeOptions]="[5, 10, 25, 100]" (page)="paginateByLimit($event)">
        </mat-paginator>

        <div class="mat-paginator table-paging">
          <div class="mat-paginator-range-label margin-left-30 margin-right-30">{{options.start+1}} -
            {{getUpperLimit(options.start + options.limit,getTotalRecords())}} of {{getTotalRecords()}}</div>
          <button mat-icon-button (click)="paginate($event,'previous');" [disabled]="firstList()">
            <mat-icon>navigate_before</mat-icon>
          </button>
          <button mat-icon-button (click)="paginate($event,'next');" [disabled]="lastList()">
            <mat-icon>navigate_next</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>