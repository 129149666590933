<div class="panel panel-primary service-request-grid" id="mainCompotents">
    <div class="panel-heading padding-left-15">
        <div class="row">
            <div class="col-xs-8">
                <h3 class="panel-title srGrid-title">Job Board</h3>
            </div>
            <div class="col-xs-4 header-btns">
                <div class="" style="text-align: right;">
                    <button [matMenuTriggerFor]="menu" class="settingsIcon text-white"><i
                            class="fa fa-cog"></i></button>
                    <mat-menu #menu="matMenu" class="mat-dropdown-menu" [overlapTrigger]="false" xPosition="before">
                        <button mat-menu-item [disabled]="true">Job Board</button>
                        <button mat-menu-item (click)="openMyJobBoard()">My Job Queue</button>
                        <button mat-menu-item (click)="openOnTheLotJobBoard()">On The Lot</button>
                    </mat-menu>
                </div>
            </div>
        </div>
    </div>
    <div class="panel-body bgcolor-lightgrey">
        <div class="sr-operations">
            <div class="flex">
                <div class="search-section flex">
                    <input matInput placeholder="search keyword" title="Search Keyword"
                        [(ngModel)]="options.search_keyword" [ngModelOptions]="{standalone: true}"
                        class="form-control input-lg custom-form-input" (keydown.enter)="search()" />
                    <button class="search-btn"><i class="fa fa-search" (click)="search()"></i></button>
                </div>
                <button mat-raised-button class="block-btn" [ngClass]="moreFilter == true ? 'active' : ''" type="more" (click)="showMoreFilter()" title="More"><i class="fa fa-ellipsis-h" aria-hidden="true"></i></button>
                <button  mat-raised-button class="block-btn" type="reset" (click)="clearFilter()"
                [ngClass]="showClearFilterCss == true ? 'clear-btn-clr-filter' : 'clear-btn-before-filter'" title="Clear"><i class="fa fa-ban" aria-hidden="true"></i></button>
                <button  mat-raised-button class="block-btn" type="more" (click)="search()" title="Refresh"><i class="fa fa-refresh" aria-hidden="true"></i></button>
            </div>
            <div class="grid-list-view-icon">
                <div class="padding-0">
                    <ul class="list-inline flex">
                        <li class="list-inline-item">
                            <div class="action-icon-color" (click)="navigatetoListPage()">
                                <i class="fa fa-list" aria-hidden="true"></i>
                            </div>
                        </li>
                        <!-- <li class="list-inline-item">
                            <div class="action-icon-color">
                                <i class="fa fa-th" aria-hidden="true"></i>
                            </div>
                        </li> -->
                    </ul>
                </div>
            </div>

        </div>
        <div class="filter-items" *ngIf="moreFilter">
            <div class="status-dropdown padding-right-10">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                    <mat-select class="form-control input-lg custom-form-input" placeholder="Select status"
                        [(ngModel)]="options.status" [ngModelOptions]="{standalone: true}">
                        <mat-option *ngFor="let status of srStatusOptionsList"
                            [value]="status.id">{{status.text}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="jobStatus-dropdowm padding-right-10">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                    <mat-select class="form-control input-lg custom-form-input" placeholder="Job status"
                        [(ngModel)]="options.job_status" [ngModelOptions]="{standalone: true}">
                        <mat-option *ngFor="let job of jobStatusOptionsList" [value]="job.id">{{job.text}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="jobStatus-dropdowm padding-right-10">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                    <mat-select class="form-control input-lg custom-form-input" placeholder="Select Employee"
                        [(ngModel)]="options.employee_id" (valueChange)="handleTechician($event)">
                        <mat-option *ngFor="let data of techniciansList;index as i"
                            [value]="data.id">{{data.first_name}} {{data.last_name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="jobStatus-dropdowm padding-right-10">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                    <mat-select class="form-control input-lg custom-form-input" placeholder="Select Labels"
                        [(ngModel)]="options.label_id">
                        <mat-option *ngFor="let data of serviceRequestLabelsOptionsList;index as i"
                            [value]="data.id">{{data.label_name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="jobStatus-dropdowm padding-right-10">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                    <mat-select class="form-control input-lg custom-form-input" placeholder="Select Flag"
                        [(ngModel)]="options.flag_value">
                        <mat-option *ngFor="let data of FlagOptionList;index as i"
                            [value]="data.id">{{data.text}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="quickAction-dropdown margin-right-10">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                    <mat-select class="form-control input-lg custom-form-input" placeholder="Quick Actions"
                        [(ngModel)]="quickActionData" (valueChange)="handleQuickAction($event)">
                        <mat-option *ngFor="let quickAction of quickActionsOptionsList"
                            [value]="quickAction.id">{{quickAction.text}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="margin-right-10 details-input workflow-date-time">
                <app-date-range-picker class="date-time-section"
                    (SelectTime)="handleDateRange($event)"></app-date-range-picker>
            </div>
            <div class="btn-wrap">
                <button mat-raised-button title="Submit" class="submit-btn mat-accent" type="submit"
                (click)="search()">Submit</button>
                <button mat-raised-button title="Clear" class="clear-btn" type="reset" (click)="clearFilter()"
                [ngClass]="showClearFilterCss == true ? 'clear-btn-clr-filter' : 'clear-btn-before-filter'">Clear</button>
            </div>
            


        </div>
        <!-- <div class="srList-menu">
            <ul class="srMenu-lsit">
                <li class="list-item selected" (click)="openBottomSheet()">Assignee <span>+2</span>&nbsp;<i class="fa fa-angle-down"></i> </li>
                <li class="list-item selected">Change workflow <i class="fa fa-angle-down"></i></li>
                <li class="list-item">Select Status <i class="fa fa-angle-down"></i></li>
                <li class="list-item">Job Status <i class="fa fa-angle-down"></i></li>
                
            </ul>
        </div> -->
        <div class="jobBoard-wraper" [ngClass]="moreFilter == true ? 'width-max' : ''">
            <div class="sr-header">
                <ul class="hearer-list">
                    <li class="header-item">
                        <div class="item-child">
                            <h3>All Open ({{apptAndPendingTitleOfColumn}})</h3>
                        </div>
                        <div class="item-child toggle-section"><mat-slide-toggle
                                class="mat-slide-toggle padding-right-10" labelPosition="before" [(ngModel)]="convertListStatus" (change)="showingPendingList()"></mat-slide-toggle>
                            <div class="padding-right-10"><i class="fa fa-file-text"></i><span>
                                    {{serviceRequestCount.pendingCount}}
                                </span></div>
                        </div>
                    </li>
                    <li class="header-item">
                        <div class="item-child">
                            <h3>In Progress / Inspection</h3>
                        </div>
                        <div class="item-child">
                            <div class="padding-right-10"><i class="fa fa-file-text"></i><span>
                                    {{serviceRequestCount.inProgressCount}}
                                </span></div>
                        </div>

                    </li>
                    <li class="header-item">
                        <div class="item-child">
                            <h3>Approval Awaited</h3>
                        </div>
                        <div class="item-child">
                            <div class="padding-right-10"><i class="fa fa-file-text"></i><span>
                                    {{serviceRequestCount.approveWaitCount}}
                                </span></div>
                        </div>

                    </li>
                    <li class="header-item">
                        <div class="item-child">
                            <h3>Work In Progress</h3>
                        </div>
                        <div class="item-child">
                            <div class="padding-right-10"><i class="fa fa-file-text"></i><span>
                                    {{serviceRequestCount.workInProgressCount}}
                                </span></div>
                        </div>

                    </li>
                    <!-- <li class="header-item">
                        <div class="item-child"><h3>On The Lot</h3></div>
                        <div class="item-child"><div class="padding-right-10"><i class="fa fa-file-text"></i><span> 0</span></div></div>

                    </li> -->
                    <li class="header-item">
                        <div class="item-child">
                            <h3>Invoiced</h3>
                        </div>
                        <div class="item-child">
                            <div class="padding-right-10"><i class="fa fa-file-text"></i><span>
                                    {{serviceRequestCount.invoiceCount}}
                                </span></div>
                        </div>

                    </li>
                    <li class="header-item">
                        <div class="item-child">
                            <h3>Paid</h3>
                        </div>
                        <div class="item-child">
                            <div class="padding-right-10"><i class="fa fa-file-text"></i><span>
                                    {{serviceRequestCount.paidCount}}
                                </span></div>
                        </div>

                    </li>
                </ul>
            </div>


            <div class="row srGrid-row">
                <div class="srGrid-container col-md-2" >
                    <div cdkDropList #appointmentPendingKey="cdkDropList" 
                        [cdkDropListData]="apptAndPendingList"
                        [cdkDropListConnectedTo]="[inProgressKey]"
                        id="appointAndPendingColumn"
                        class="srGrid-list" 
                        (cdkDropListDropped)="drop($event)"
                        [ngClass]="disableApptAndPendingCol == true ? 'allowdropCard' : 'normalColor'"
                        >
                        <div class="srGrid-box" *ngFor="let item of apptAndPendingList" cdkDrag
                            [cdkDragDisabled]="disableDargAndDrop" (cdkDragExited)="checkDisableColumns('appointAndPendingColumn')">
                            <app-service-card [serviceReqData]="item" 
                            [serviceReqFormColumn]="'appointAndPendingColumn'"
                            (reDrawJobBoard)="reDrawJobBoardFormServiceCard()"
                            (handleSendSRFunctionality)="sendAndCompleteTheSRButton($event)"
                            (deleteSRCardWithoutDrawBoard) ="handleDeleteSRCardWithoutDrawBoard($event)"
                            ></app-service-card>
                        </div>
                    </div>
                </div>

                <div class="srGrid-container col-md-2">
                    <div cdkDropList #inProgressKey="cdkDropList" 
                        [cdkDropListData]="inProgressList"
                        [cdkDropListConnectedTo]="[approvalAwaitedKey, workInProgressKey, paidKey, invoicedKey]"
                        id="inProgressColumn"
                        class="srGrid-list" 
                        (cdkDropListDropped)="drop($event)"
                        [ngClass]="disableInProgressCol == true ? 'allowdropCard' : 'normalColor'"
                        >
                        <div class="srGrid-box" *ngFor="let item of inProgressList" cdkDrag
                            [cdkDragDisabled]="disableDargAndDrop" 
                            (cdkDragExited)="checkDisableColumns('inProgressColumn')"
                            >
                        <app-service-card [serviceReqData]="item" 
                            (reDrawJobBoard)="reDrawJobBoardFormServiceCard()" 
                            [serviceReqFormColumn]="'inProgressColumn'"
                            (handleSendSRFunctionality)="sendAndCompleteTheSRButton($event)" 
                            (handleCompleteSRFunctionality)="sendAndCompleteTheSRButton($event)"
                            (deleteSRCardWithoutDrawBoard) ="handleDeleteSRCardWithoutDrawBoard($event)"
                            >
                        </app-service-card>
                        </div>
                    </div>
                </div>

                <div class="srGrid-container col-md-2">
                    <div cdkDropList #approvalAwaitedKey="cdkDropList" 
                        [cdkDropListData]="approvalAwaitedList"
                        [cdkDropListConnectedTo]="[workInProgressKey, paidKey, invoicedKey]"
                        id="approvalAwaitedColumn"
                        class="srGrid-list" 
                        (cdkDropListDropped)="drop($event)"
                        [ngClass]="disableApprovalAwaitedCol == true ? 'allowdropCard' : 'normalColor'"
                        >
                        <div class="srGrid-box" 
                            *ngFor="let item of approvalAwaitedList" cdkDrag
                            [cdkDragDisabled]="disableDargAndDrop" 
                            (cdkDragExited)="checkDisableColumns('approvalAwaitedColumn')"
                            >
                        <app-service-card [serviceReqData]="item" 
                             [serviceReqFormColumn]="'approvalAwaitedColumn'"
                            (reDrawJobBoard)="reDrawJobBoardFormServiceCard()" 
                            (handleCompleteSRFunctionality)="sendAndCompleteTheSRButton($event)"
                            (deleteSRCardWithoutDrawBoard) ="handleDeleteSRCardWithoutDrawBoard($event)"
                            >
                        </app-service-card>
                        </div>
                    </div>
                </div>

                <div class="srGrid-container col-md-2">
                    <div cdkDropList #workInProgressKey="cdkDropList" 
                        [cdkDropListData]="workInProgressList"
                        [cdkDropListConnectedTo]="[ paidKey, invoicedKey]"
                        id="workInProgressColumn"
                        class="srGrid-list" 
                        (cdkDropListDropped)="drop($event)"
                        [ngClass]="disableWorkInProgressCol == true ? 'allowdropCard' : 'normalColor'"
                        >
                        <div class="srGrid-box" 
                            *ngFor="let item of workInProgressList" cdkDrag
                            [cdkDragDisabled]="disableDargAndDrop"
                            [ngClass]="disableDargAndDrop == true ? 'disableDargDropClass' : ''"
                            (cdkDragExited)="checkDisableColumns('workInProgressColumn')"
                            >
                        <app-service-card [serviceReqData]="item" 
                             [serviceReqFormColumn]="'workInProgressColumn'"
                            (reDrawJobBoard)="reDrawJobBoardFormServiceCard()" 
                            (handleCompleteSRFunctionality)="sendAndCompleteTheSRButton($event)"
                            (deleteSRCardWithoutDrawBoard) ="handleDeleteSRCardWithoutDrawBoard($event)"
                            >
                        </app-service-card>
                        </div>
                    </div>
                </div>

                <div class="srGrid-container col-md-2">
                    <div cdkDropList #invoicedKey="cdkDropList" 
                        [cdkDropListData]="invoicedList"
                        [cdkDropListConnectedTo]="[ paidKey]"
                        id="invoicedColumn"
                        class="srGrid-list" 
                        (cdkDropListDropped)="drop($event)"
                        [ngClass]="disableInvoicedCol == true ? 'allowdropCard' : 'normalColor'"
                        >
                        <div class="srGrid-box" 
                            *ngFor="let item of invoicedList" cdkDrag
                            [cdkDragDisabled]="disableDargAndDrop" 
                            (cdkDragExited)="checkDisableColumns('invoicedColumn')"
                            >
                        <app-service-card [serviceReqData]="item" 
                             [serviceReqFormColumn]="'invoicedColumn'"
                            (reDrawJobBoard)="reDrawJobBoardFormServiceCard()" 
                            (handleCompleteSRFunctionality)="sendAndCompleteTheSRButton($event)"
                            (deleteSRCardWithoutDrawBoard) ="handleDeleteSRCardWithoutDrawBoard($event)"
                            (reOpenSRWithOutreDrawBoard) = "handleReOpenSRWithoutDrawBoard($event)"
                            >
                        </app-service-card>
                        </div>
                    </div>
                </div>

                <div class="srGrid-container col-md-2">
                    <div cdkDropList #paidKey="cdkDropList" 
                        [cdkDropListData]="paidList"
                        class="srGrid-list" 
                        id="paidColumn"
                        (cdkDropListDropped)="drop($event)"
                        [ngClass]="disablePaidedCol == true ? 'allowdropCard' : 'normalColor'"
                        >
                        <div class="srGrid-box disableDargIcon" 
                            *ngFor="let item of paidList" cdkDrag
                            [cdkDragDisabled]="item.disable"
                            (cdkDragExited)="checkDisableColumns('paidColumn')"
                            >
                            <app-service-card [serviceReqData]="item"
                                 [serviceReqFormColumn]="'paidColumn'"
                                 (deleteSRCardWithoutDrawBoard) ="handleDeleteSRCardWithoutDrawBoard($event)"
                                 (reOpenSRWithOutreDrawBoard) = "handleReOpenSRWithoutDrawBoard($event)"
                                 ></app-service-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>