<div mat-dialog-title class="relative">
  Edit {{ user.profile_type == '2' ? 'Contact' : 'Customer' }}
  <button mat-mini-fab mat-dialog-close class="close-modal notranslate mat-elevation-z0">
    <mat-icon aria-label="Example icon-button with a heart icon">close</mat-icon>
  </button>
</div>
<mat-dialog-content class="edit-customer-dialog">
  
        <form class="form-horizontal form-control-normal" autocomplete="off" [formGroup]="customer">
          <div class="center-block">
            <div class="display-full margin-bottom-5">
              <!-- customer detail form -->
              <div class="col-sm-6 col-md-6 margin-bottom-20">
                <label>Email Address:
                  <span>*</span>
                </label>
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" hideRequiredMarker="false" appearance="fill">
                  <input matInput placeholder="email" title="email" formControlName="email"
                    class="form-control input-lg custom-form-input" (keyup)="checkIfValid()">
                    <mat-error *ngIf="customer.controls.email.hasError('pattern')">
                      Please enter a valid Email Address
                  </mat-error>
                  <mat-error *ngIf="customer.controls.email.hasError('required')">
                      Email Address is <strong>required</strong>
                  </mat-error>
                </mat-form-field>
                
              </div>
              <div class="col-sm-6 col-md-6 margin-bottom-20">
                <label>First Name:
                  <span>*</span>
                </label>
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" hideRequiredMarker="false" appearance="fill">
                  <input matInput placeholder="First Name" title="First Name" formControlName="first_name"
                    class="form-control input-lg custom-form-input" (keyup)="checkIfValid()">
                  <mat-error *ngIf="customer.controls.first_name.hasError('required')">
                    First Name is <strong>required</strong>
                  </mat-error>
                  <mat-error *ngIf="customer.controls.first_name.hasError('pattern')">
                    Invalid First Name
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-sm-6 col-md-6 margin-bottom-20">
                <label>Last Name:
                  <span>*</span>
                </label>
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" hideRequiredMarker="false" appearance="fill">
                  <input matInput placeholder="Last Name" title="Last Name" formControlName="last_name" class="form-control input-lg custom-form-input" (keyup)="checkIfValid()">
                  <mat-error *ngIf="customer.controls.last_name.hasError('required')">Last Name is <strong>required</strong>
                  </mat-error>
                  <mat-error *ngIf="customer.controls.last_name.hasError('pattern')">
                    Invalid Last Name
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-sm-6 col-md-6 margin-bottom-20">
                <label>Address:
                  <span>*</span>
                </label>
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                  <input matInput placeholder="Address 1" title="Address 1" formControlName="address_one" class="form-control input-lg custom-form-input" (keyup)="checkIfValid()">
                  <mat-error *ngIf="customer.controls.address_one.hasError('required')">Address is <strong>required</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-sm-6 col-md-6 margin-bottom-20">
                <label>{{zipLabel}}:
                  <span>*</span>
                </label>
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                  <input matInput placeholder="{{zipLabel}}*" title="{{zipLabel}}"  #zipcode (input)="zipcode.value = zipcode.value.toUpperCase()" formControlName="zipcode"
                    class="form-control input-lg custom-form-input" maxlength="15" (keyup)="checkIfValid()" (blur)="setCountryStateCityViaZipCode()"
                    />
                  <mat-error *ngIf="customer.controls.zipcode.hasError('required')">
                    {{zipLabel}} is <strong>required</strong>
                  </mat-error>
                  <mat-error *ngIf="customer.controls.zipcode.hasError('pattern')">
                    Please enter valid {{zipLabel.toLowerCase()}}
                  </mat-error>
                  <mat-error *ngIf="customer.controls.zipcode.hasError('maxlength')">
                    Invalid {{zipLabel.toLowerCase()}}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-sm-6 col-md-6 uc-ngx-select margin-bottom-20 country-col">
                <label>Country:
                  <span>*</span>
                </label>
                <ngx-select class="display-full margin-bottom-20" 
                  [items]="countries"
                  [(ngModel)]="selectedCountry" 
                  [ngModelOptions]="{standalone: true}"
                  (select)="handleCountryDropdownChange($event)" 
                  placeholder="Select Country">
                </ngx-select>
              </div>
              
              
              <div class="col-sm-6 col-md-6 uc-ngx-select margin-bottom-20">
                <label>State:
                  <span>*</span>
                </label>
                <ngx-select class="display-full margin-bottom-20" 
                [items]="states" 
                [(ngModel)]="selectedState"
                [ngModelOptions]="{standalone: true}" 
                (select)="handleStateDropdownChange($event)"
                [disabled]="states.length==0" placeholder="Select State">
                </ngx-select>
                <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
                  <mat-error *ngIf="customer.controls.state.hasError('required')">
                    State is <strong>required</strong>
                  </mat-error>
                </div>
              </div>

              <div class="col-sm-6 col-md-6 uc-ngx-select  margin-bottom-20">
                <label>City:
                  <span>*</span>
                </label>
                <ngx-select class="display-full margin-bottom-20" 
                [items]="cities" 
                [(ngModel)]="selectedCity"
                [ngModelOptions]="{standalone: true}" 
                (select)="handleCityDropdownChange($event)"
                [disabled]="cities.length==0" 
                placeholder="Select City">
                </ngx-select>
                <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
                  <mat-error *ngIf="customer.controls.city.hasError('required')">
                    City is <strong>required</strong>
                  </mat-error>
                </div>
              </div>
              <div class="col-sm-6 col-md-6 margin-bottom-20 margin-bottom-20">
                <label>Mobile Phone:
                  <span>*</span>
                </label>
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                  <input matInput placeholder="Mobile Phone" title="Mobile Phone" formControlName="mobile_phone"
                    class="form-control input-lg custom-form-input" maxlength="15" [textMask]="{mask: phoneMask}"
                    (keyup)="checkIfValid()" />
                  <mat-error *ngIf="customer.controls.mobile_phone.hasError('required')">
                    Mobile Phone is <strong>required</strong>
                  </mat-error>
                  <mat-error
                    *ngIf="customer.controls.mobile_phone.hasError('minLength') && !customer.controls.mobile_phone.hasError('required')">
                    Invalid Mobile Phone
                  </mat-error>
                </mat-form-field>
              </div>
              
              <div class="col-sm-6 col-md-6 padding-bottom-20 uc-ngx-select margin-bottom-20">
                <label>Timezone:
                  <span>*</span>
                </label>
                <ngx-select class="display-full" [items]="timezoneData" formControlName="timezone"
                  (select)="getSelectedOptionTimezone($event)" placeholder="Select Timezone">
                </ngx-select>
                <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
                  <mat-error *ngIf="customer.controls.timezone.hasError('required')">
                    Timezone is <strong>required</strong>
                  </mat-error>
                </div>
              </div>
            </div>
          </div>
        </form>

</mat-dialog-content>
<mat-dialog-actions class="inquiry-action relative">
    <div class="text-right flexBtns">
      <button mat-raised-button color="accent" (click)="submitCustomer()" class="font-bold">Submit</button>
      <button mat-raised-button mat-dialog-close class="font-bold border">Close</button>
    </div>
</mat-dialog-actions>
