import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatInputModule } from '@angular/material/input'
import { MatButtonModule } from '@angular/material/button'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatCardModule } from '@angular/material/card'
import { MatTabsModule } from '@angular/material/tabs'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatSelectModule } from '@angular/material/select'
import { MatRadioModule } from '@angular/material/radio'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatIconModule } from '@angular/material/icon'

import { MatMenuModule } from '@angular/material/menu'
import { MatTableModule } from '@angular/material/table'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatSortModule } from '@angular/material/sort'
import { MatDialogModule } from '@angular/material/dialog'
import { MatNativeDateModule } from '@angular/material/core'
import { MatButtonToggleModule } from '@angular/material/button-toggle'

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatChipsModule } from '@angular/material/chips'

import { MatDatepickerModule } from '@angular/material/datepicker';
import {MatListModule} from '@angular/material/list';
import { MatStepperModule } from '@angular/material/stepper';

//add datetime picker
import {DpDatePickerModule} from 'ng2-date-picker';
import { DragulaModule } from 'ng2-dragula';
import {ScrollToModule} from 'ng2-scroll-to-el';
import {AppComponent} from './app.component';
import {TooltipModule} from "ngx-bs-tooltip";
import { OwlModule } from 'ngx-owl-carousel';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NguiAutoCompleteModule, NguiAutoComplete } from '@ngui/auto-complete';
/* FOR NG2SELECT DROP DOWN */
import { NgxSelectModule,INgxSelectOptions } from 'ngx-select-ex';
/* END */
import {RouterModule, Routes, ActivatedRoute} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Http, Headers, Response,RequestOptions} from '@angular/http';
import {BrowserXhr, HttpModule} from '@angular/http';
import {HomepageComponent} from './homepage/homepage.component';
import { Angular2ImageGalleryModule } from 'angular2-image-gallery';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { MomentModule } from 'angular2-moment';
import { NouisliderModule } from 'ng2-nouislider';
import { CalendarModule,DateAdapter  } from 'angular-calendar';

import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
// Common routes
import {CustomLoader} from './customloader'
import {TitleService} from './Titleservice.service'
import {CarheadService,CanComponentDeactivate} from './carhead.service';
import { ImagedialogComponent } from './assetsComponent/imagedialog/imagedialog.component'
import {ConfirmDialogComponent} from './assetsComponent/confirm-dialog/confirm-dialog.component';
import {GlobalService} from '../environments/global.service';
import {SnackbarToasterComponent} from './assetsComponent/snackbar-toaster/snackbar-toaster.component';
import {BarRatingModule} from "ngx-bar-rating";
import { ChangeDetectorRef } from "@angular/core";
// import { AngularCropperjsModule } from 'angular-cropperjs';
import { CKEditorModule } from 'ng2-ckeditor';
// customer routes
// import {CustomerloginpageComponent} from './customer/customerloginpage/customerloginpage.component';
// import { CustomerdashboardComponent } from './customer/customerdashboard/customerdashboard.component';
// import {CustomersignupComponent} from './customer/customersignup/customersignup.component';
// import {CustomerforgotpasswordComponent} from './customer/customerforgotpassword/customerforgotpassword.component';
// import {EditcardetailsComponent} from './customer/my-vehicles/editcardetails/editcardetails.component';
// import {ViewVehiclesComponent} from './customer/my-vehicles/view-vehicles/view-vehicles.component';
// import {ViewDiyServiceRequestsComponent} from './customer/service-request/view-diy-service-requests/view-diy-service-requests.component';
// import {NewRequestComponent, addCarDialogCustomer} from './customer/service-request/new-request/new-request.component';
// import {ViewServiceComplaintsComponent} from './customer/service-request/view-service-complaints/view-service-complaints.component';
// import {ListServiceRequestComponent} from './customer/service-request/list-service-request/list-service-request.component';
// import {ViewServiceFacilitiesComponent} from './customer/my-service-facilities/view-service-facilities/view-service-facilities.component';
// import {NewServiceFacilityComponent} from './customer/my-service-facilities/new-service-facility/new-service-facility.component';
// import {AddInquiryComponent} from './customer/inquiries/add-inquiry/add-inquiry.component';
// import {ViewPreviousInquiriesComponent} from './customer/inquiries/view-previous-inquiries/view-previous-inquiries.component';
// import {TransferOwnershipComponent} from './customer/my-vehicles/transfer-ownership/transfer-ownership.component';
import {ViewRequestComponent, RefreshDialogComponent} from './customer/service-request/view-request/view-request.component';
// import {ChangePasswordComponent} from './customer/change-password/change-password.component';
// import {ListTransferredVehiclesComponent} from './customer/my-vehicles/list-transferred-vehicles/list-transferred-vehicles.component';
// import {ListTransferOwnershipComponent} from './customer/my-vehicles/list-transfer-ownership/list-transfer-ownership.component';
// import {EditRequestComponent} from './customer/service-request/edit-request/edit-request.component';
// import {addCustomerCCFORM, SubscriptionComponent} from './customer/subscription/subscription.component';
// import {ViewCustomerInquiryComponent} from './customer/inquiries/view-customer-inquiry/view-customer-inquiry.component';
// import {ViewCustomerComplaintComponent} from './customer/service-request/view-customer-complaint/view-customer-complaint.component';
// import {CustomerNotificationComponent} from './customer/myAccount/customer-notification/customer-notification.component';

//shop routes
import {ShoploginComponent} from './shop/shoplogin/shoplogin.component';
import {updateCCDetail,ShopdashboardComponent} from './shop/shopdashboard/shopdashboard.component';
import {ShopForgotPasswordComponent} from './shop/shopforgotpassword/shopforgotpassword.component';
import {ShopaddnewpartComponent} from './shop/shopaddnewpart/shopaddnewpart.component';
import {ShopaddgenericjobComponent} from './shop/shopaddgenericjob/shopaddgenericjob.component';
import {ShopeditgenericjobComponent} from './shop/shopeditgenericjob/shopeditgenericjob.component';
import {ShopeditpartComponent} from './shop/shopeditpart/shopeditpart.component';
import {ShopadddisclaimerComponent} from './shop/shopadddisclaimer/shopadddisclaimer.component';
import {ShopeditdisclaimerComponent} from './shop/shopeditdisclaimer/shopeditdisclaimer.component';
import {ShopaddcomputercodeComponent} from './shop/shopaddcomputercode/shopaddcomputercode.component';
import {ShopeditcomputercodeComponent} from './shop/shopeditcomputercode/shopeditcomputercode.component';
import {addCCFORM, ShopSubscriptionComponent} from './shop/shop-subscription/shop-subscription.component';
import {FillRecommendationComponent,Autoui, CloseOrRefresh} from './shop/shopservicerequest/fill-recommendation/fill-recommendation.component';
import {addNewParts, addNewJob, addNewComputerCode, uspsVehicle,SaveRecommendationConfirmation} from './shop/shopservicerequest/fill-recommendation/fill-recommendation.component';
import {VehicleInspectionReport } from './shop/shopservicerequest/fill-recommendation/vehicle-inspection-report.component';
import { SalesreportComponent } from './shop/shopreports/salesreport/salesreport.component';
import { EmployeelogsComponent } from './shop/shopreports/employeelogs/employeelogs.component';
import { OverduereportComponent } from './shop/shopreports/overduereport/overduereport.component';
/* google chart */
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
/**Ngx-Bootstrap**/
// import {BsDropdownModule, CarouselModule, CollapseModule,ModalModule} from 'ngx-bootstrap';

import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ModalModule} from 'ngx-bootstrap/modal';
import {CollapseModule} from 'ngx-bootstrap/collapse';

// import {ReCaptchaModule} from 'angular2-recaptcha';
import {MarketplaceComponent} from './marketplace/marketplace.component';

import {TextMaskModule} from 'angular2-text-mask';
// import {AdminloginComponent} from './admin/adminlogin/adminlogin.component';
// import {AdmindashboardComponent} from './admin/admindashboard/admindashboard.component';
// import {EditshopComponent} from './admin/editshop/editshop.component';
import {EditCustomerComponent} from './customer/myAccount/edit-customer/edit-customer.component';
// import {EditpartComponent} from './admin/editpart/editpart.component';
// import {ChangeshoppasswordComponent} from './admin/changeshoppassword/changeshoppassword.component';
// import {MailchimpconnectionComponent} from './admin/mailchimpconnection/mailchimpconnection.component';
// import {EditgenericjobComponent} from './admin/editgenericjob/editgenericjob.component';
// import {PartsaddedbyshopsComponent} from './admin/partsaddedbyshops/partsaddedbyshops.component';
// import {EditdisclaimerComponent} from './admin/editdisclaimer/editdisclaimer.component';
// import {EditcomputercodeComponent} from './admin/editcomputercode/editcomputercode.component';
// import {EditcomputercodebyshopComponent} from './admin/editcomputercodebyshop/editcomputercodebyshop.component';
// import {ManagelabourratesComponent} from './admin/managelabourrates/managelabourrates.component';
import {ShopsubscriptionlogsComponent} from './admin/shopsubscriptionlogs/shopsubscriptionlogs.component';
// import {ShopsComponent} from './admin/shops/shops.component';
// import {ViewallpartsComponent} from './admin/parts/viewallparts/viewallparts.component';
// import {PartsaddedbyshopComponent} from './admin/parts/partsaddedbyshop/partsaddedbyshop.component';
// import {GenericjobsComponent} from './admin/genericjobs/genericjobs.component';
// import {ViewallcomputercodesComponent} from './admin/computercodes/viewallcomputercodes/viewallcomputercodes.component';

import {SlimLoadingBarModule} from 'ng2-slim-loading-bar';
import {NgProgressModule} from 'ngx-progressbar';
// import {ViewcodesaddedbyshopsComponent} from './admin/computercodes/viewcodesaddedbyshops/viewcodesaddedbyshops.component';
// import {ListalldisclaimersComponent} from './admin/disclaimers/listalldisclaimers/listalldisclaimers.component';
import {ChangeshopspasswordComponent} from './shop/changeshopspassword/changeshopspassword.component';
import {EditshopprofileComponent} from './shop/editshopprofile/editshopprofile.component';
import {GoogleAdsComponent} from './google-ads/google-ads.component';
import {EasycheckinComponent} from './shop/easycheckin/easycheckin.component';
import {EdituserprofileComponent} from './shop/edituserprofile/edituserprofile.component';
import {AddnewcustomerComponent} from './shop/shopcustomers/addnewcustomer/addnewcustomer.component';
import {ListshopcustomersComponent} from './shop/shopcustomers/listshopcustomers/listshopcustomers.component';
import {EditshopcustomersComponent} from './shop/shopcustomers/editshopcustomers/editshopcustomers.component';
import {AddcarComponent} from './shop/shopcar/addcar/addcar.component';
import {ListcustomercarsComponent} from './shop/shopcar/listcustomercars/listcustomercars.component';
import {ListshopserviceComponent, UserAuthConfirm} from './shop/shopservicerequest/listshopservice/listshopservice.component';
import {ShopcardetailsComponent} from './shop/shopcar/shopcardetails/shopcardetails.component';
import {ShopeditcarComponent} from './shop/shopcar/shopeditcar/shopeditcar.component';
import {ShopcarservicehistoryComponent} from './shop/shopcar/shopcarservicehistory/shopcarservicehistory.component';
import {ListshoppartsComponent} from './shop/shopparts/listshopparts/listshopparts.component';
import {ListgenericjobsComponent} from './shop/shopgenericjob/listgenericjobs/listgenericjobs.component';
import {ListshopdisclaimersComponent} from './shop/shopdisclaimers/listshopdisclaimers/listshopdisclaimers.component';
import {ViewServicerequestComponent, changeDisclaimer} from './shop/shopservicerequest/view-servicerequest/view-servicerequest.component';
import {EditServicerequestComponent} from './shop/shopservicerequest/edit-servicerequest/edit-servicerequest.component';
import {ShoplistcomputercodesComponent} from './shop/shopcomputercodes/shoplistcomputercodes/shoplistcomputercodes.component';
import {AddeditlabourratesComponent} from './shop/shoplabourrates/addeditlabourrates/addeditlabourrates.component';
import {AddeditmailchimpComponent} from './shop/shopconnection/addeditmailchimp/addeditmailchimp.component';
import {ShoplistservicecomplaintsComponent} from './shop/shopservicecomplaints/shoplistservicecomplaints/shoplistservicecomplaints.component';
import {ShopviewservicecomplaintComponent} from './shop/shopservicecomplaints/shopviewservicecomplaint/shopviewservicecomplaint.component';
import {NewCustomerTypeComponent} from './shop/shoplabourrates/new-customer-type/new-customer-type.component';
import {ShopviewcoustomerinquiryComponent} from './shop/shopinquiries/shopviewcoustomerinquiry/shopviewcoustomerinquiry.component';
import {ListshopinquiriesComponent} from './shop/shopinquiries/listshopinquiries/listshopinquiries.component';
import {ImportContactsComponent} from './shop/shopcustomers/import-contacts/import-contacts.component';
// import {AddnewshopComponent} from './admin/shops/addnewshop/addnewshop.component';
// import {AdminaddnewpartComponent} from './admin/parts/adminaddnewpart/adminaddnewpart.component';
// import {AddnewgenericjobComponent} from './admin/genericjobs/addnewgenericjob/addnewgenericjob.component';
// import {AdminaddnewdisclaimerComponent} from './admin/disclaimers/adminaddnewdisclaimer/adminaddnewdisclaimer.component';
// import {AdminaddnewcomputercodeComponent} from './admin/computercodes/adminaddnewcomputercode/adminaddnewcomputercode.component';
import {ListshopssubscribedcustomersComponent} from './admin/shops/listshopssubscribedcustomers/listshopssubscribedcustomers.component';
import {PaymentDialogComponent} from './shop/shopservicerequest/payment-dialog/payment-dialog.component';
import {CustomerFeedbackComponent} from './customer/customer-feedback/customer-feedback.component';
import { ServiceRequestLogsComponent } from './shop/shopservicerequest/service-request-logs/service-request-logs.component';

// shop payment(2checkout) repsonse
import {PaymentResponseComponent} from './shop/payment-response/payment-response.component';

// customer payment(2checkout) repsonse
import { CustomerPaymentResponseComponent } from './customer/customer-payment-response/customer-payment-response.component';

import {VideoShowComponent} from './customer/my-vehicles/video-show/video-show.component';
import {NewServiceRequestComponent, addCarDialog} from './shop/new-service-request/new-service-request.component';
import {ShopCustomerFeedbackComponent} from './shop/shop-customer-feedback/shop-customer-feedback.component';
import { ShopcustomerinformationComponent } from './shop/shopcustomers/shopcustomerinformation/shopcustomerinformation.component';
import { ListServiceRequestHistoryComponent } from './customer/service-request/list-service-request-history/list-service-request-history.component';
import { ServiceHistoryViewComponent } from './customer/service-request/service-history-view/service-history-view.component';
import { EditServiceFacilityComponent } from './customer/my-service-facilities/edit-service-facility/edit-service-facility.component';
import { AddComplaintDialogComponent } from './customer/service-request/add-complaint-dialog/add-complaint-dialog.component';
import { ViewDiyServiceRequestDetailsComponent } from './customer/service-request/view-diy-service-request-details/view-diy-service-request-details.component';
import { ShopServiceHistoryViewComponent } from './shop/shopservicerequest/shop-service-history-view/shop-service-history-view.component';
import { ImagetooldialogComponent } from './imagetooldialog/imagetooldialog.component';
import { RegistrationInsuranceAlertComponent } from './customer/customerdashboard/registration-insurance-alert/registration-insurance-alert.component';
import { ImagecroppieComponent } from './imagecroppie/imagecroppie.component';
// import { AdminpartstechComponent } from './admin/mailchimpconnection/adminpartstech/adminpartstech.component';
import { AddeditpartstechComponent } from './shop/shopconnection/addeditpartstech/addeditpartstech.component';
import { PartstechComponent } from './partstech/partstech.component';
import { ShopresetpasswordComponent } from './shop/shopresetpassword/shopresetpassword.component';
import { CustomerresetpasswordComponent } from './customer/customerresetpassword/customerresetpassword.component';
import { AddvendorComponent } from './shop/shopvendors/addvendor/addvendor.component';
import { ListshopvendorsComponent } from './shop/shopvendors/listshopvendors/listshopvendors.component';
import { EditshopvendorComponent } from './shop/shopvendors/editshopvendor/editshopvendor.component';
import { CustomerCarVinScanDialogComponent } from './customer/customer-car-vin-scan-dialog/customer-car-vin-scan-dialog.component';
import { PagenotfoundComponent } from "./pagenotfound/pagenotfound.component";
//anil sir
import { AddshopresourceComponent } from './shop/shopresources/addshopresource/addshopresource.component';
import { ListshopresourcesComponent } from './shop/shopresources/listshopresources/listshopresources.component';
import { EditshopresourceComponent } from './shop/shopresources/editshopresource/editshopresource.component';
import { ShopcarvinscanComponent } from './shop/shopcar/shopcarvinscan/shopcarvinscan.component';
// apoorv
import { PagesComponent } from './pages/pages.component';
import { GlobalErrorHandler } from '../app/global-error-handler';
import { RejectTransferOwnershipPopUpComponent } from './customer/my-vehicles/reject-transfer-ownership-pop-up/reject-transfer-ownership-pop-up.component';
import { ConfirmsignupComponent } from './customer/confirmsignup/confirmsignup.component';
import { ContactComponent } from './contact/contact.component';
import { PaymentSuccessComponent } from './customer/customer-payment-response/payment-success/payment-success.component';
import { PaymentFailComponent } from './customer/customer-payment-response/payment-fail/payment-fail.component';
import { AdsenseModule } from 'ng2-adsense';
import { ShopPaymentSuccessComponent } from './shop/payment-response/shop-payment-success/shop-payment-success.component';
import { ShopPaymentFailureComponent } from './shop/payment-response/shop-payment-failure/shop-payment-failure.component';
// import { ReopenticketComponent } from './admin/shops/reopenticket/reopenticket.component';
import { AuthenticatepassworddialogComponent } from './admin/shops/authenticatepassworddialog/authenticatepassworddialog.component';
import { ErrorOccurredComponent } from './error-occurred/error-occurred.component';
import { StockOrderComponent } from './shop/shopparts/stock-order/stock-order.component';
import { UsercontactsComponent } from './usercontacts/usercontacts.component';
import { PermissionComponent } from './role/permission/permission.component';
import { ListStockOrdersComponent } from './shop/shopparts/list-stock-orders/list-stock-orders.component';
import { ViewStockOrdersComponent } from './shop/shopparts/view-stock-orders/view-stock-orders.component';
import { AddemployeeComponent } from './shop/employee/addemployee/addemployee.component';
import { EditemployeeComponent } from './shop/employee/editemployee/editemployee.component';
import { ViewcustomerdetailsComponent } from './shop/shopcustomers/viewcustomerdetails/viewcustomerdetails.component';
import { AddMileageDialogComponent } from './shop/shopservicerequest/add-mileage-dialog/add-mileage-dialog.component';
import { ListemployeeComponent,resetPassword } from './shop/employee/listemployee/listemployee.component';
import { RequireApprovalDialogComponent } from './shop/shopservicerequest/require-approval-dialog/require-approval-dialog.component';
// import { AdmincustomerslistComponent } from './admin/admincustomerslist/admincustomerslist.component';
// import { AdmineditcustomerComponent } from './admin/admineditcustomer/admineditcustomer.component';
import { EditcustomerdialogComponent } from './admin/admineditcustomer/editcustomerdialog/editcustomerdialog.component';
import { ViewempComponent } from './shop/employee/viewemp/viewemp.component';
import { EmpPermissionComponent } from './shop/employee/emp-permission/emp-permission.component';
import { AccessLimitComponent } from './shop/access-limit/access-limit.component';
import { TechassignmentComponent } from './shop/shopservicerequest/techassignment/techassignment.component';
import { TechreportComponent } from './shop/employee/techreport/techreport.component';
import { CalendarviewComponent } from './calendarview/calendarview.component';
import { EditpartPopupComponent } from './shop/shopeditpart/editpart-popup/editpart-popup.component';
import { PartlogComponent } from './shop/shopparts/partlog/partlog.component';
import { PartlogdetailComponent } from './shop/shopparts/partlogdetail/partlogdetail.component';
import { NotifyjobComponent } from './shop/shopservicerequest/notifyjob/notifyjob.component';
import { RegistrationComponent } from './shop/registration/registration.component';
import { MailverificationComponent } from './shop/mailverification/mailverification.component';
import { TermandcondComponent } from './shop/termandcond/termandcond.component';
import { MessagingService } from './messaging.service';
// import { AngularFireAuth } from 'angularfire2/auth'; //old code for web-notification(FCM)
// import { AngularFireDatabase } from 'angularfire2/database'; //old code for web-notification(FCM)
// import { AngularFireModule } from 'angularfire2'; //old code for web-notification(FCM)
import { NotificationComponent } from './notification/notification.component';
import { MilesoutComponent } from './shop/shopservicerequest/milesout/milesout.component';
import { DetailTabComponent } from './shop/shopservicerequest/detail-tab/detail-tab.component';
import { AutoUiDirective } from './auto-ui.directive';
// import { VehicledetailComponent } from './admin/vehicles/vehicledetail/vehicledetail.component';
// import { EditvehicleComponent } from './admin/vehicles/editvehicle/editvehicle.component';
// import { StatesComponent } from './admin/states/states.component';
// import { CitiesComponent } from './admin/cities/cities.component';
import { ViktableComponent } from './admin/viktable/viktable.component';
// import { EditstateComponent } from './admin/states/editstate/editstate.component';
// import { EditcityComponent } from './admin/cities/editcity/editcity.component';
// import { AddcityComponent } from './admin/cities/addcity/addcity.component';
// import { AddstateComponent } from './admin/states/addstate/addstate.component';
import { SubscribepopupComponent } from './admin/shops/subscribepopup/subscribepopup.component';
import { NotificationhandelComponent } from './notification/notificationhandel/notificationhandel.component';
import { ImportexportComponent } from './shop/shopparts/importexport/importexport.component';
import { PartuploadlistComponent } from './shop/shopparts/partuploadlist/partuploadlist.component';
import { PartuploaddetailComponent } from './shop/shopparts/partuploaddetail/partuploaddetail.component';
import { PartimportdetailComponent } from './shop/shopparts/partimportdetail/partimportdetail.component';
import { PartagsComponent } from './shop/shopparts/partags/partags.component';
import { AddLabelDialogComponent } from './shop/shopservicerequest/add-label-dialog/add-label-dialog.component';
import { StockorderpopupComponent } from './shop/shopparts/stockorderpopup/stockorderpopup.component';
import { QueorderComponent } from './shop/shopparts/queorder/queorder.component';
import { QuedetailComponent } from './shop/shopparts/quedetail/quedetail.component';
import { ReturnqueComponent } from './shop/shopparts/returnque/returnque.component';
import { ReturnquelistComponent } from './shop/shopparts/returnquelist/returnquelist.component';
import { ReturnpickupComponent } from './shop/shopparts/returnpickup/returnpickup.component';
import { ReturnviewComponent } from './shop/shopparts/returnview/returnview.component';
import { ShopcarLicensePlateComponent } from './shop/shopcar/shopcarlicenseplate/shopcarlicenseplate.component';
// import { CMSContentAddComponent } from './admin/cms-content/cms-content-add/cms-content-add.component';
// import { CMSContentListComponent } from './admin/cms-content/cms-content-list/cms-content-list.component';
import { WAKdetailsComponent } from './wak/wak-details/wak-details.component';
import { LogviewComponent } from './shop/shopservicerequest/logview/logview.component';
import { CustomercarLicensePlateComponent } from './customer/customer-car-licenseplate/customer-car-licenseplate.component';
import { PartnumberComponent } from './shop/shopparts/partnumber/partnumber.component';
// import { PartsnumberComponent } from './admin/parts/partsnumber/partsnumber.component';
import { ShopSmartBatchListComponent } from './shop/smart-batch/smart-batch-list/shop-smart-batch-list.component';
// import { AdminSmartBatchListComponent } from './admin/smart-batch/admin-smart-batch-list/admin-smart-batch-list.component';
import { PartsmatrixComponent } from './shop/shopparts/partsmatrix/partsmatrix.component';
import { MatrixrangeComponent } from './shop/shopparts/matrixrange/matrixrange.component';
// import { AssignCMSComponent } from './admin/cms-content/assign-cms-content/assign-cms-content.component';
import { ShopSmartBatchPartsComponent } from './shop/shopparts/shop-smart-batch-part-list/shop-smart-batch-part-list.component';
import { AddSmartBatchComponent } from './shop/shopparts/add-smart-batch/add-smart-batch.component';
import { SmartBatchAssignComponent } from './shop/shopparts/smart-batch-assign/smart-batch-assign.component';
// import { AdminAddPartnerComponent } from './admin/cms-content/admin-add-partner/admin-add-partner.component';
// import { AdminListPartnersComponent } from './admin/cms-content/admin-list-partners/admin-list-partners.component';
import { PartsuggestionComponent } from './shop/shopservicerequest/partsuggestion/partsuggestion.component';
// import { AdminEditSmartBatchPartsComponent } from './admin/parts/admin-edit-smart-batch-part/admin-edit-smart-batch-part.component';
import { AdminSmartBatchAssignComponent } from './admin/parts/smart-batch-assign/admin-smart-batch-assign.component';
import { AdminEditpartPopupComponent } from './admin/parts/admin-editpart-popup/admin-editpart-popup.component';
import { ShopVehicleServiceComponent } from './shop/shopservicerequest/shop-vehicle-service/shop-vehicle-service.component';
import { EditServiceRequestFieldsComponent } from './shop/shopservicerequest/edit-servicerequest-fields/edit-servicerequest-fields.component';
import { RecommpasteComponent } from './shop/shopservicerequest/recommpaste/recommpaste.component';
import { InternalnotesComponent } from './shop/internalnote/internalnotes/internalnotes.component';
import { AddinternalnoteComponent } from './shop/internalnote/addinternalnote/addinternalnote.component';
import { ViewnoteComponent } from './shop/internalnote/viewnote/viewnote.component';
// import { DeleteticketComponent } from './admin/shops/deleteticket/deleteticket.component';
import { EditnoteComponent } from './shop/internalnote/editnote/editnote.component';
import { SrnotelistComponent } from './shop/srnote/srnotelist/srnotelist.component';
import { AddSrnoteComponent } from './shop/srnote/add-srnote/add-srnote.component';
import { EditSrnoteComponent } from './shop/srnote/edit-srnote/edit-srnote.component';
import { ViewSrnoteComponent } from './shop/srnote/view-srnote/view-srnote.component';
import { QuickactionComponent } from './shop/quickaction/quickaction.component';
import { QuickquoteComponent } from './shop/quickquote/quickquote.component';
import { AddjobsComponent } from './shop/quickquote/addjobs/addjobs.component';
import { AddVehicleComponent } from './shop/quickquote/add-vehicle/add-vehicle.component';
import { AcquirevehicleComponent } from './shop/quickquote/acquirevehicle/acquirevehicle.component';
import { QqlistComponent } from './shop/quickquote/qqlist/qqlist.component';
import { QqRecomendationComponent } from './shop/quickquote/qq-recomendation/qq-recomendation.component';
import { QqsupportComponent } from './shop/quickquote/qqsupport/qqsupport.component';
import { QqpreviousComponent } from './shop/quickquote/qqprevious/qqprevious.component';
import { QqviewComponent } from './shop/quickquote/qqview/qqview.component';
import { QqtoServicerequestComponent } from './shop/quickquote/qqto-servicerequest/qqto-servicerequest.component';
import { QuotelistComponent } from './customer/quickquote/quotelist/quotelist.component';
import { QuoteviewComponent } from './customer/quickquote/quoteview/quoteview.component';
import { QqpaymentComponent } from './shop/quickquote/qqpayment/qqpayment.component';
import { QuotedetailComponent } from './customer/quickquote/quotedetail/quotedetail.component';
import { MessagelistComponent } from './shop/shopservicecomplaints/messagelist/messagelist.component';
import { MessageviewComponent } from './shop/shopservicecomplaints/messageview/messageview.component';
import { MessageaddComponent } from './shop/shopservicecomplaints/messageadd/messageadd.component';
import { ExtrafieldsComponent } from './shop/shopcar/extrafields/extrafields.component';
import { InspectionListComponent } from './shop/shopservicerequest/inspection-list/inspection-list.component';
import { ExtrafieldListComponent } from './shop/shopcar/extrafield-list/extrafield-list.component';
import { EditpopupComponent } from './shop/shopcar/editpopup/editpopup.component';
import { VkrouteComponent } from './vkroute/vkroute.component';
import { EmissionsInspectionComponent } from './shop/shopservicerequest/emissions-inspection/emissions-inspection.component';
import { ResetPasswordComponent } from './admin/customer/reset-password/reset-password.component';
import { EditcustomerpopupComponent } from './shop/shopcustomers/editcustomerpopup/editcustomerpopup.component';
import { ProfilelogComponent } from './admin/customer/profilelog/profilelog.component';
import { ProfilelogdiffComponent } from './admin/customer/profilelogdiff/profilelogdiff.component';
import { CustomdatatableComponent } from './customdatatable/customdatatable.component';
// import { ShoploginlogsComponent } from './admin/loginlogs/shoploginlogs/shoploginlogs.component';
// import { CustomerloginlogsComponent } from './admin/loginlogs/customerloginlogs/customerloginlogs.component';
import { AddDiscountComponent } from './shop/discounts/add-discount/add-discount.component';
import { ListDiscountsComponent } from './shop/discounts/list-discounts/list-discounts.component';
import { EditDiscountComponent } from './shop/discounts/edit-discount/edit-discount.component';
import { DiscountRulesComponent } from './shop/discounts/discount-rules/discount-rules.component';
import { RedeemCouponComponent } from './redeem-coupon/redeem-coupon.component';
import {​​ ServiceHistoryComponent }​​ from './shop/shopservicerequest/service-history/service-history.component';
import {​​ ListVendorsComponent }​​ from './shop/nexpart/list-vendors/list-vendors.component';
import { AddNexpartVendorComponent }​​ from './shop/nexpart/add-nexpart-vendor/add-nexpart-vendor.component';
import { EditNexpartVendorComponent }​​ from './shop/nexpart/edit-nexpart-vendor/edit-nexpart-vendor.component';
 
import {​​ CallbackComponent }​​ from './shop/nexpart/callback/callback.component';
 
import { JobApprovalDialogComponent } from './shop/shopservicerequest/job-approval-dialog/job-approval-dialog.component';
import { EmpreportdatatableviewComponent } from './shop/shopreports/empreportdatatableview/empreportdatatableview.component';
import { CustomerpulllogsComponent } from './admin/customerpulllogs/customerpulllogs.component';
import { ServiceCalendarComponent } from './shop/shopservicerequest/service-calendar/service-calendar.component'; 
import { CdTimerModule } from 'angular-cd-timer';

import { QuickapptComponent } from './shop/quickappt/quickappt.component'; 
import { AddVehicleForApptComponent } from './shop/quickappt/add-vehicle-appt/add-vehicle-appt.component';
import { AcquirevehicleApptComponent } from './shop/quickappt/acquirevehicle-appt/acquirevehicleappt.component';
import { ServicedetailComponent } from './shop/quickappt/servicedetail/servicedetail.component';
import { AssignemployeeComponent } from './shop/quickappt/assignemployee/assignemployee.component';
import { ServiceCalendarModalComponent } from './shop/shopservicerequest/service-calendar-modal/service-calendar-modal.component';
import { ServiceFieldUpdateComponent } from './shop/shopservicerequest/service-calendar-modal/service-field-update/service-field-update.component';
import { CustomerEditModalComponent } from './customer/customer-edit-modal/customer-edit-modal.component';
import { UpdatesrFieldComponent } from './shop/quickappt/updatesr-field/updatesr-field.component';
import { AddleaveComponent } from './shop/leave/addleave/addleave.component';
import { AttentionServiceRequestsComponent } from './shop/shopservicerequest/attention-service-requests/attention-service-requests.component';
import { AdjustTechScheduleComponent } from './shop/shopservicerequest/attention-service-requests/adjust-tech-schedule/adjust-tech-schedule.component';
import { UpdateworkhoursComponent } from './shop/employee/updateworkhours/updateworkhours.component';
// import { SesStatusListComponent } from './admin/shops/ses-status-list/ses-status-list.component'
import { TechAssignmentComponent } from './shop/shopservicerequest/tech-assignment/tech-assignment.component';
import { AdvancereportComponent } from './shop/shopreports/advancereport/advancereport.component';
import { BusinessmetricsComponent } from './shop/shopreports/businessmetrics/businessmetrics.component';
import { ExcludedVehiclesComponent } from './shop/advance-filter/excluded-vehicles/excluded-vehicles.component';
import { AddExcludedVehiclesComponent } from './shop/advance-filter/add-excluded-vehicles/add-excluded-vehicles.component';
import { SearchFilterComponent } from './shop/advance-filter/search-filter/search-filter.component';
import { CustomerListComponent } from './shop/advance-filter/customer-list/customer-list.component';
import { SearchAdvanceCustomerComponent } from './shop/advance-filter/search-advance-customer/search-advance-customer.component';
import { SearchAdvanceEmployeeComponent } from './shop/advance-filter/search-advance-employee/search-advance-employee.component';
import { EmployeeListComponent } from './shop/advance-filter/employee-list/employee-list.component';
import { SearchcustomerComponent } from './shop/shopcustomers/searchcustomer/searchcustomer.component';
import { SearchothercustomerComponent } from './shop/shopcustomers/searchothercustomer/searchothercustomer.component';
import { SearchcarComponent } from './shop/shopcar/searchcar/searchcar.component';
import { SubletHistoryComponent } from './shop/shopservicerequest/sublet-history/sublet-history.component';
import { CustomerapprovaldetailsComponent } from './shop/shopcustomers/customerapprovaldetails/customerapprovaldetails.component';
import { TempCustomerApprovalComponent } from './shop/tempCustomerApproval/tempCustomerApproval.component';
import { QQSearchcarComponent } from './shop/quickquote/qq-searchcar/qq-searchcar.component';
import { ListTemplatesComponent } from './shop/sms-templates/list-template/list-templates.component';
import { EditTemplateComponent } from './shop/sms-templates/edit-template/edit-template.component';

import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';

import { JwtInspectorInterceptor} from './base/httpInspector/jwt-inspector.interceptor';
import { ErrorHandlerInterceptor} from './base/errorInspector/error-handler.interceptor';
import {LoaderInterceptor} from './base/LoaderInterceptor/loader-interceptor.interceptor';
import { ShopsrcolumnviewComponent } from './shop/shopservicerequest/shopsrcolumnview/shopsrcolumnview.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
// import { ServiceCardComponent } from './shop/shopservicerequest/shopsrcolumnview/service-card/service-card.component';
import {
  NgxMatDatetimePickerModule, 
  NgxMatNativeDateModule, 
  NgxMatTimepickerModule,
  NgxMatDateFormats,
  NGX_MAT_DATE_FORMATS
} from '@angular-material-components/datetime-picker';
import { DateAndTimePickerComponent } from './base/helpers/date-and-time-picker/date-and-time-picker.component';
import { CustomerLiveChatComponent } from './shop/customer-live-chat/customer-live-chat.component';
import { CustomerContactsComponent } from './shop/customer-live-chat/customer-contacts/customer-contacts.component';
import { ChatAreaComponent } from './shop/customer-live-chat/chat-area/chat-area.component';
import { ChatInfoAreaComponent } from './shop/customer-live-chat/chat-info-area/chat-info-area.component';
import { initializeApp } from "firebase/app";
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import { BottomSheetHelperComponent } from './base/helpers/bottom-sheet-helper/bottom-sheet-helper.component';
import { CCAuthorizationComponent } from './shop/cc-auth/cc-authorization/cc-authorization.component';
import { environment } from 'src/environments/environment';

import { DateRangePickerComponent } from './base/helpers/date-range-picker/date-range-picker.component';
import { WorkflowComponent } from './shop/boards/workflow/workflow.component';
import { OnTheLotComponent } from './shop/boards/on-the-lot/on-the-lot.component';
import { MyWorkLoadComponent } from './shop/boards/my-work-load/my-work-load.component';
import { ServiceCardComponent } from './shop/boards/service-card/service-card.component';
import { VehicleInspectionReportComponent } from './shop/shopservicerequest/vehicle-inspection-report/vehicle-inspection-report.component';
import { UspsVehicleInspectionReportComponent } from './shop/shopservicerequest/usps-vehicle-inspection-report/usps-vehicle-inspection-report.component';
import { AddLabelsDialogComponent } from './shop/boards/add-labels-dialog/add-labels-dialog.component';
import { UserAuthenticationDialogComponent } from './shop/shopservicerequest/user-authentication-dialog/user-authentication-dialog.component';
import { MobileNoIgnoreListComponent } from './shop/mobile-ignore-list/mobile-no-ignore-list/mobile-no-ignore-list.component';
import { AddMobileNoComponent } from './shop/mobile-ignore-list/add-mobile-no/add-mobile-no.component';
import { EditMobileNoComponent } from './shop/mobile-ignore-list/edit-mobile-no/edit-mobile-no.component';
import {SocketIoModule} from 'ngx-socket-io';
import { NexpartLaborNotesSettingComponent } from './shop/nexpart/nexpart-labor-notes-setting/nexpart-labor-notes-setting.component';
import { JobMatchDialogComponent } from './shop/shopservicerequest/job-match-dialog/job-match-dialog.component';
import { JobPasteConfirmationComponent } from './shop/shopservicerequest/recommpaste/job-paste-confirmation/job-paste-confirmation.component';
import { FullProfileAccessComponent } from './shop/shopcustomers/full-profile-access/full-profile-access.component';
import { SelfCheckinComponent } from './shop/self-checkin/self-checkin.component';
import { TimeFormatPipe  } from './base/pipe/time-format.pipe';
import { QboConnectComponent } from './shop/qbo-connect/qbo-connect.component';
import { ChangeCustomerVehicleComponent } from './shop/shopservicerequest/change-customer-vehicle/change-customer-vehicle.component';
import { CustomerVehicleSectionComponent } from './shop/customer-vehicle-section/customer-vehicle-section.component';
import { SubscriptionPlansDialogComponent } from './shop/subscription-plans-dialog/subscription-plans-dialog.component';
import { SubscriptionPlanPreviewComponent } from './shop/subscription-plan-preview/subscription-plan-preview.component';
import { CompleteProfileSetupComponent } from './shop/complete-profile-setup/complete-profile-setup.component';
import { CommonDialogBigComponent } from './shop/common-dialog-big/common-dialog-big.component';
import { ReviewPaymentConfigComponent } from './shop/review-payment-config/review-payment-config.component';
import { DueForServiceComponent } from './shop/due-for-service/due-for-service.component';
import { ShopVehiclesListComponent } from './shop/shop-vehicles-list/shop-vehicles-list.component';
import { LastServiceVehiclesComponent } from './shop/shopcar/last-service-vehicles/last-service-vehicles.component';
import { AlertWarningComponent } from './base/helpers/alert-warning/alert-warning.component';
import { DfsEditComponent } from './shop/due-for-service/dfs-edit/dfs-edit.component';
import { RecaptchaModule } from "ng-recaptcha";
import {CarouselModule}from'ngx-owl-carousel-o';

//Ngx-Select dropdown keyboardkey setting
const CustomSelectOptions: INgxSelectOptions = {
  keyCodeToOptionsOpen: 'ArrowDown',
  keepSelectedItems: true
};
 

initializeApp(environment.firebaseConfigInfo);

const INTL_DATE_INPUT_FORMAT = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hourCycle: 'h12',
  hour: '2-digit',
  minute: '2-digit',
};

const MAT_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: INTL_DATE_INPUT_FORMAT,
  },
  display: {
    dateInput: INTL_DATE_INPUT_FORMAT,
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
};

const routes: Routes = [
 
]


@NgModule({
  declarations: [
      AppComponent,
      HomepageComponent,
      // CustomerloginpageComponent,
      // CustomerdashboardComponent,
      ShoploginComponent,
      ShopdashboardComponent,
      ShopForgotPasswordComponent,
      ShopaddnewpartComponent,
      ShopeditpartComponent,
      ShopaddgenericjobComponent,
      ShopeditgenericjobComponent,
      ShopadddisclaimerComponent,
      ShopeditdisclaimerComponent,
      ShopaddcomputercodeComponent,
      ShopeditcomputercodeComponent,
      SalesreportComponent,
      AdvancereportComponent,
      BusinessmetricsComponent,
      EmployeelogsComponent,
      OverduereportComponent,
      // CustomersignupComponent,
      MarketplaceComponent,
      // CustomerforgotpasswordComponent,
      // AdminloginComponent,
      // AdmindashboardComponent,
      // EditshopComponent,
      // EditcardetailsComponent,
      EditCustomerComponent,
      // NewRequestComponent,
      // addCarDialogCustomer,
      // EditpartComponent,
      // ChangeshoppasswordComponent,
      // MailchimpconnectionComponent,
      // EditgenericjobComponent,
      // PartsaddedbyshopsComponent,
      // EditdisclaimerComponent,
      // EditcomputercodeComponent,
      // EditcomputercodebyshopComponent,
      // ManagelabourratesComponent,
      ShopsubscriptionlogsComponent,
      // ListServiceRequestComponent,
      // ViewVehiclesComponent,
      // ViewDiyServiceRequestsComponent,
      // ViewServiceComplaintsComponent,
      // ViewServiceFacilitiesComponent,
      // NewServiceFacilityComponent,
      // AddInquiryComponent,
      // ViewPreviousInquiriesComponent,
      // TransferOwnershipComponent,
      // ListTransferredVehiclesComponent,
      ConfirmDialogComponent,
      SnackbarToasterComponent,
      // ListTransferOwnershipComponent,
      // ChangePasswordComponent,
      // ShopsComponent,
      ViewRequestComponent,
      // ViewallpartsComponent,
      // PartsaddedbyshopComponent,
      // GenericjobsComponent,
      // ViewallcomputercodesComponent,
      // ViewcodesaddedbyshopsComponent,
      // ListalldisclaimersComponent,
      ChangeshopspasswordComponent,
      EditshopprofileComponent,
      EasycheckinComponent,
      EdituserprofileComponent,
      AddnewcustomerComponent,
      // EditRequestComponent,
      // SubscriptionComponent,
      GoogleAdsComponent,
      ShopSubscriptionComponent,
      addCCFORM,
      // addCustomerCCFORM,
      updateCCDetail,
      ListshopcustomersComponent,
      // ViewCustomerInquiryComponent,
      // ViewCustomerComplaintComponent,
      EditshopcustomersComponent,
      AddcarComponent,
      ListcustomercarsComponent,
      ListshopserviceComponent,
      UserAuthConfirm,
      // CustomerNotificationComponent,
      ShopcardetailsComponent,
      ShopeditcarComponent,
      ShopcarservicehistoryComponent,
      FillRecommendationComponent,
      ListshoppartsComponent,
      ListgenericjobsComponent,
      ListshopdisclaimersComponent,
      FillRecommendationComponent,
      ViewServicerequestComponent,
      changeDisclaimer,
      EditServicerequestComponent,
      ShoplistcomputercodesComponent,
      AddeditlabourratesComponent,
      AddeditmailchimpComponent,
      ShoplistservicecomplaintsComponent,
      ShopviewservicecomplaintComponent,
      FillRecommendationComponent,
      VehicleInspectionReport,
      addNewParts,
      addNewJob,
      SaveRecommendationConfirmation,
      addNewComputerCode,
      uspsVehicle,
      NewCustomerTypeComponent,
      ListshopinquiriesComponent,
      ShopviewcoustomerinquiryComponent,
      ImportContactsComponent,
      // AddnewshopComponent,
      // AdminaddnewpartComponent,
      // AddnewgenericjobComponent,
      // AdminaddnewdisclaimerComponent,
      // AdminaddnewcomputercodeComponent,
      ListshopssubscribedcustomersComponent,
      PaymentDialogComponent,
      CustomerFeedbackComponent,
      PaymentResponseComponent,
      VideoShowComponent,
      NewServiceRequestComponent,
      addCarDialog,
      ShopCustomerFeedbackComponent,
      ShopcustomerinformationComponent,
      ListServiceRequestHistoryComponent,
      ServiceHistoryViewComponent,
      CustomerPaymentResponseComponent,
      EditServiceFacilityComponent,
      AddComplaintDialogComponent,
      ViewDiyServiceRequestDetailsComponent,
      ShopServiceHistoryViewComponent,
      ImagetooldialogComponent,
      RegistrationInsuranceAlertComponent,
      ImagecroppieComponent,
      // AdminpartstechComponent,
      AddeditpartstechComponent,
      PartstechComponent,
      ShopresetpasswordComponent,
      CustomerresetpasswordComponent,
      AddvendorComponent,
      ListshopvendorsComponent,
      EditshopvendorComponent,
      CustomerCarVinScanDialogComponent,
      PagenotfoundComponent,
      AddshopresourceComponent,
      ListshopresourcesComponent,
      EditshopresourceComponent,
      ImagedialogComponent,
      ShopcarvinscanComponent,
      PagesComponent,
      RejectTransferOwnershipPopUpComponent,
      ConfirmsignupComponent,
      ContactComponent,
      PaymentSuccessComponent,
      PaymentFailComponent,
      ShopPaymentSuccessComponent,
      ShopPaymentFailureComponent,
      // ReopenticketComponent,
      AuthenticatepassworddialogComponent,
      ErrorOccurredComponent,
      StockOrderComponent,
      UsercontactsComponent,
      PermissionComponent,
      ListStockOrdersComponent,
      ViewStockOrdersComponent,
      AddemployeeComponent,
      EditemployeeComponent,
      ViewcustomerdetailsComponent,
      CustomerapprovaldetailsComponent,
      AddMileageDialogComponent,
      ListemployeeComponent,
      RequireApprovalDialogComponent,
      // AdmincustomerslistComponent,
      // AdmineditcustomerComponent,
      EditcustomerdialogComponent,
      ViewempComponent,
      EmpPermissionComponent,
      resetPassword,
      AccessLimitComponent,
      ServiceRequestLogsComponent,
      TechassignmentComponent,
      TechAssignmentComponent,
      UpdateworkhoursComponent,
      ExcludedVehiclesComponent,
      AddExcludedVehiclesComponent,
      CustomerListComponent,
      EmployeeListComponent,
      SearchAdvanceCustomerComponent,
      SearchAdvanceEmployeeComponent,
      SearchFilterComponent,
      TechreportComponent,
      CalendarviewComponent,
      EditpartPopupComponent,
      PartlogComponent,
      PartlogdetailComponent,
      NotifyjobComponent,
      RegistrationComponent,
      MailverificationComponent,
      TempCustomerApprovalComponent,
      TermandcondComponent,
      NotificationComponent,
      MilesoutComponent,
      DetailTabComponent,
      // AutoUiDirective,
      Autoui,
      // VehicledetailComponent,
      // EditvehicleComponent,
      // StatesComponent,
      // CitiesComponent,
      ViktableComponent,
      // EditstateComponent,
      // EditcityComponent,
      // AddcityComponent,
      // AddstateComponent,
      SubscribepopupComponent,
      NotificationhandelComponent,
      ImportexportComponent,
      PartuploadlistComponent,
      PartuploaddetailComponent,
      PartimportdetailComponent,
      PartagsComponent,
      AddLabelDialogComponent,
      StockorderpopupComponent,
      QueorderComponent,
      QuedetailComponent,
      ReturnqueComponent,
      ReturnquelistComponent,
      ReturnpickupComponent,
      ReturnviewComponent,
      ShopcarLicensePlateComponent,
      // CMSContentAddComponent,
      // CMSContentListComponent,
      WAKdetailsComponent,
      LogviewComponent,
      CustomercarLicensePlateComponent,
      PartnumberComponent,
      // PartsnumberComponent,
      ShopSmartBatchListComponent,
      // AdminSmartBatchListComponent,
      PartsmatrixComponent,
      MatrixrangeComponent,
      // AssignCMSComponent,
      // AdminEditSmartBatchPartsComponent,
      ShopSmartBatchPartsComponent,
      AddSmartBatchComponent,
      SmartBatchAssignComponent,
      // AdminAddPartnerComponent,
      // AdminListPartnersComponent,
      PartsuggestionComponent,
      AdminSmartBatchAssignComponent,
      AdminEditpartPopupComponent,
      ShopVehicleServiceComponent,
      EditServiceRequestFieldsComponent,
      RecommpasteComponent,
      InternalnotesComponent,
      AddinternalnoteComponent,
      ViewnoteComponent,
      // DeleteticketComponent,
      EditnoteComponent,
      SrnotelistComponent,
      AddSrnoteComponent,
      EditSrnoteComponent,
      ViewSrnoteComponent,
      QuickactionComponent,
      QuickquoteComponent,
      AddjobsComponent,
      AddVehicleComponent,
      AcquirevehicleComponent,
      QqlistComponent,
      QqRecomendationComponent,
      QqsupportComponent,
      QqpreviousComponent,
      QqviewComponent,
      QqtoServicerequestComponent,
      QuotelistComponent,
      QuoteviewComponent,
      QqpaymentComponent,
      QuotedetailComponent,
      MessagelistComponent,
      MessageviewComponent,
      MessageaddComponent,
      ExtrafieldsComponent,
      InspectionListComponent,
      CloseOrRefresh,
      ExtrafieldListComponent,
      EditpopupComponent,
      VkrouteComponent,
      EmissionsInspectionComponent,
      ResetPasswordComponent,
      EditcustomerpopupComponent,
      ProfilelogComponent,
      ProfilelogdiffComponent,
      CustomdatatableComponent,
      // ShoploginlogsComponent,
      // CustomerloginlogsComponent,
      AddDiscountComponent,
      ListDiscountsComponent,
      EditDiscountComponent,
      DiscountRulesComponent,
      RedeemCouponComponent,
      RefreshDialogComponent,
      ServiceHistoryComponent,
      SubletHistoryComponent,
      ListVendorsComponent,
      JobApprovalDialogComponent,
      EmpreportdatatableviewComponent,
      CustomerpulllogsComponent,
      ServiceCalendarComponent,
      AddNexpartVendorComponent,
      EditNexpartVendorComponent,
      CallbackComponent,
      ServicedetailComponent,
      AssignemployeeComponent,
      ServiceCalendarModalComponent,
      ServiceFieldUpdateComponent,
      CustomerEditModalComponent,
      UpdatesrFieldComponent,       
      QuickapptComponent,
      AddVehicleForApptComponent,
      AcquirevehicleApptComponent,
      AddleaveComponent,
      AttentionServiceRequestsComponent,
      SearchcustomerComponent,
      SearchothercustomerComponent,
      SearchcarComponent,
      AdjustTechScheduleComponent,
      // SesStatusListComponent,
      QQSearchcarComponent,
      ListTemplatesComponent,
      EditTemplateComponent,
      ShopsrcolumnviewComponent,
      ServiceCardComponent,
      DateAndTimePickerComponent,
      CustomerLiveChatComponent,
      CustomerContactsComponent,
      ChatAreaComponent,
      ChatInfoAreaComponent,
      BottomSheetHelperComponent,
      CCAuthorizationComponent,
      DateRangePickerComponent,
      WorkflowComponent,
      OnTheLotComponent,
      MyWorkLoadComponent,
      VehicleInspectionReportComponent,
      UspsVehicleInspectionReportComponent,
      AddLabelsDialogComponent,
      UserAuthenticationDialogComponent,
      MobileNoIgnoreListComponent,
      AddMobileNoComponent,
      EditMobileNoComponent,
      NexpartLaborNotesSettingComponent,
      JobMatchDialogComponent,
      JobPasteConfirmationComponent,
      FullProfileAccessComponent,
      SelfCheckinComponent,
      TimeFormatPipe,
      QboConnectComponent,
      ChangeCustomerVehicleComponent,
      CustomerVehicleSectionComponent,
      SubscriptionPlansDialogComponent,
      SubscriptionPlanPreviewComponent,
      CompleteProfileSetupComponent,
      CommonDialogBigComponent,
      ReviewPaymentConfigComponent,
      DueForServiceComponent,
      ShopVehiclesListComponent,
      LastServiceVehiclesComponent,
      AlertWarningComponent,
      DfsEditComponent,
  ],
  imports: [
      MomentModule,
      NguiAutoCompleteModule,
      HttpModule,
      FormsModule,
      TextMaskModule,
      HttpModule,
      BrowserAnimationsModule,
      BrowserModule,
      FormsModule,
      ReactiveFormsModule,
      BsDropdownModule.forRoot(),
      CollapseModule.forRoot(),
      NgProgressModule,
      SlimLoadingBarModule.forRoot(),
      // ReCaptchaModule,
      MatSnackBarModule,
      MatCheckboxModule,
      MatInputModule,
      MatButtonModule,
      MatSidenavModule,
      MatCardModule,
      MatTabsModule,
      MatProgressBarModule,
      MatExpansionModule,
      MatSelectModule,
      MatDatepickerModule,
      MatNativeDateModule,
      MatButtonToggleModule,
      MatProgressSpinnerModule,
      MatSlideToggleModule,
      MatGridListModule,
      MatToolbarModule,
      MatChipsModule,
      MatIconModule,
      MatMenuModule,
      HttpModule,
      Angular2ImageGalleryModule,
      MatTableModule,
      MatRadioModule,
      MatAutocompleteModule,
      MatTooltipModule,
      MatPaginatorModule,
      MatSortModule,
      MatDialogModule,
      DpDatePickerModule,
      MatListModule,
      TooltipModule,
      BarRatingModule,
      DragulaModule.forRoot(),
      // AngularCropperjsModule,
      Ng2GoogleChartsModule,
      ScrollToModule.forRoot(),
      RouterModule.forRoot(routes),
      AdsenseModule.forRoot({
          adClient: '',
          adSlot: '',
      }),
      NgxSelectModule.forRoot(CustomSelectOptions),
      NouisliderModule,
      CalendarModule.forRoot({ provide: DateAdapter,
                               useFactory: adapterFactory}),
      // AngularFireModule.initializeApp(firebaseConfig), //old code for web-notification(FCM)
      // CKEditorModule,
      OwlModule,
      ModalModule.forRoot(),
      CdTimerModule,
      HttpClientModule,
      AppRoutingModule,
      DragDropModule,
      NgxMatDatetimePickerModule, 
      NgxMatNativeDateModule, 
      NgxMatTimepickerModule,
      MatBottomSheetModule,
      MatStepperModule,
      RecaptchaModule,
      CarouselModule,
      // NgxScrollEventModule,
      SocketIoModule.forRoot({ url: environment.SOCKET_SERVER_URL, options: {reconnection:true,autoConnect:true} })
  ],
  exports: [RouterModule, ConfirmDialogComponent, SnackbarToasterComponent, SlimLoadingBarModule],
  providers: [
      // CustomerloginpageComponent, 
      GlobalService,
      CanComponentDeactivate,
      // CustomLoader,
      // {provide: BrowserXhr, useExisting: CustomLoader},
      {provide: ErrorHandler, useClass: GlobalErrorHandler },
      // {provide: RequestOptions, useClass: CarheadService},
      {provide: HTTP_INTERCEPTORS, useClass: JwtInspectorInterceptor, multi: true},
      { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true},
      NguiAutoComplete, TitleService,MessagingService,
      // AngularFireDatabase, //old code  for web-notification(FCM)
      // AngularFireAuth, //old code  for web-notification(FCM)
      // {provide: CanComponentDeactivate,useValue: (component: ViewServicerequestComponent) => true}
      { provide: NGX_MAT_DATE_FORMATS, useValue: MAT_DATE_FORMATS },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: LoaderInterceptor,
        multi: true,
     },
  ],
  bootstrap: [AppComponent], 
  entryComponents: [ConfirmDialogComponent, SnackbarToasterComponent, VehicleInspectionReport, 
      NewCustomerTypeComponent, addNewParts, addNewJob, addNewComputerCode,
       uspsVehicle, PaymentDialogComponent, VideoShowComponent, addCarDialog,
       changeDisclaimer,
      //  addCarDialogCustomer,
       AddComplaintDialogComponent,
      ImagetooldialogComponent,ImagecroppieComponent,PartstechComponent,
      CustomerCarVinScanDialogComponent, ShopcarvinscanComponent, SaveRecommendationConfirmation,
       ImagedialogComponent, RejectTransferOwnershipPopUpComponent, AuthenticatepassworddialogComponent,
       UsercontactsComponent, AddMileageDialogComponent, RequireApprovalDialogComponent,
       EditcustomerdialogComponent,resetPassword,TechassignmentComponent,TechAssignmentComponent,UpdateworkhoursComponent,EditpartPopupComponent,
       addCCFORM,
      // addCustomerCCFORM,
      updateCCDetail,NotifyjobComponent,TermandcondComponent,ExcludedVehiclesComponent,AddExcludedVehiclesComponent,
      SearchFilterComponent,CustomerListComponent,EmployeeListComponent,SearchAdvanceCustomerComponent,SearchAdvanceEmployeeComponent,
      MilesoutComponent,DetailTabComponent,Autoui,SubscribepopupComponent,PartagsComponent,
      AddLabelDialogComponent,StockorderpopupComponent,ReturnqueComponent,ReturnpickupComponent,
      ShopcarLicensePlateComponent,CustomercarLicensePlateComponent,MatrixrangeComponent,
      SmartBatchAssignComponent,PartsuggestionComponent, AdminSmartBatchAssignComponent, 
      AdminEditpartPopupComponent,ShopVehicleServiceComponent,EditServiceRequestFieldsComponent,
      RecommpasteComponent,AddinternalnoteComponent,SrnotelistComponent,AddSrnoteComponent,
      EditSrnoteComponent,ViewSrnoteComponent,QuickquoteComponent,AddVehicleComponent,
      AcquirevehicleComponent,QqsupportComponent,QqviewComponent,QqtoServicerequestComponent,
      QqpaymentComponent,QuoteviewComponent,MessagelistComponent,MessageviewComponent,
      MessageaddComponent,ExtrafieldsComponent,CloseOrRefresh,ExtrafieldListComponent,EditpopupComponent,
      EmissionsInspectionComponent,ResetPasswordComponent,EditcustomerpopupComponent,ProfilelogComponent,
      ProfilelogdiffComponent,UserAuthConfirm,RefreshDialogComponent,ServiceHistoryComponent, SubletHistoryComponent,
      ListVendorsComponent,AddNexpartVendorComponent,EditNexpartVendorComponent,CallbackComponent,
      JobApprovalDialogComponent,EmpreportdatatableviewComponent,CustomerpulllogsComponent,
      ServiceCalendarComponent,QuickapptComponent, AddVehicleForApptComponent,AcquirevehicleApptComponent,
      ServicedetailComponent,AssignemployeeComponent,ServiceCalendarModalComponent,ServiceFieldUpdateComponent,
      CustomerEditModalComponent,
      UpdatesrFieldComponent,AdjustTechScheduleComponent,AddleaveComponent,SearchcustomerComponent,SearchothercustomerComponent,
      SearchcarComponent,QQSearchcarComponent] 
})
export class AppModule { }
