import {Component, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import {Router, ActivatedRoute, ParamMap, NavigationEnd, Params} from '@angular/router';
import {Http, Headers, Response} from '@angular/http';
import {HttpHeaders} from '@angular/common/http';
import {globalFunctions} from "../../../environments/globalFunctions";
import {globalConstants} from "../../../environments/globalConstants";
import {GlobalService} from "../../../environments/global.service";
import {lang} from "../../../environments/lang-en";
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {TextMaskModule} from 'angular2-text-mask';

@Component({
  selector: 'app-shopresetpassword',
  templateUrl: './shopresetpassword.component.html',
  styleUrls: ['./shopresetpassword.component.scss']
})
export class ShopresetpasswordComponent implements OnInit {
    public change_pass: FormGroup;
    hide: boolean = false;
    hide1: boolean = false;
    public resetEmail;
    public resetToken;
    public userDetails
    public request;
    public currentCustomer;
    constructor(private activatedRoute: ActivatedRoute, private formBuilder: FormBuilder, private route: ActivatedRoute, public titleService: Title, private http: Http, private router: Router, public globalService: GlobalService) {
      var URLData = JSON.parse(localStorage.getItem("reset-password-data"))

        if (localStorage.getItem("loggedInUser")) {
            this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
            this.router.navigate(["/shops/dashboard"]);
        } else {
            this.setParams();
            var shopContent = "email=" + URLData.email+"&reset_token=" + URLData.token;
            this.globalService.callAPI(globalConstants.API_SHOP_CHECK_RESET_PASSWORD_URL, shopContent).subscribe((data:any) => {
                var message = data.message;
                if (data.result == 1) {
                    this.userDetails = data.data;
                } else {
                    this.globalService.snackbar("error", message);
                }
            });
        }
        this.change_pass = this.formBuilder.group({
            AVAR: [URLData.email],
            BVAR: [URLData.token],
            password: ['', [Validators.required, Validators.minLength(6)]],
            confirm_password: ['', Validators.required]
        }, {validator: this.confirmPasswordValidator});

        console.log(this.activatedRoute.snapshot.queryParams);
    }

    ngOnInit() {
    }

    confirmPasswordValidator(c: AbstractControl) {
        if (c.get('password').value !== c.get('confirm_password').value) {
            c.get('confirm_password').setErrors({"matched": true})
            return {matched: true};
        } else {
            c.get('confirm_password').valid;
        }
    }

    setParams() {
        this.activatedRoute.params.subscribe((params: Params) => {
            this.resetEmail = params["id"];
            this.resetToken = params["id1"];
        });
    }

    resetPassword() {
        if (this.change_pass.valid) {
            let formdata = this.change_pass.value;
            formdata.password = formdata.password.replaceAll('+','%2B');
            formdata.confirm_password = formdata.confirm_password.replaceAll('+','%2B');
            this.globalService.callAPI(globalConstants.API_SHOP_RESET_PWD_URL, formdata).subscribe(
            data => {
                this.request = data;
                if (this.request.result == "1") {
                    this.router.navigate(["/shops"]);
                    this.globalService.snackbar("success", this.request.message);
                } else {
                    this.globalService.snackbar("error", this.request.message);
                }
            });
        }
    }

}
