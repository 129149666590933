<div class="vehicle-selection-component">
  <div class="panel panel-primary vehicle-selection-section margin-top-20">
    <div class="panel-heading">
      <h3 class="panel-title view-title">
        Vehicle Selection
      </h3>
      <div class="btn-wrap">
        <button mat-button *ngIf="(selectedVehicle && selectedVehicle.id) || showAddVehicleSection" title="View All Vehicle"
          (click)="handleUnSelectVehicle()" class="btn btn-list list-action-btn padding-left-0 view-all-btn">
          <span>
            <i class="fa fa-list-ul"></i>
          </span>
          <span class="hidden-xs">
            View All
          </span>
        </button>
        <button mat-button (click)="showAddVehiclesSection()" title="Add Vehicle" class="btn btn-list list-action-btn padding-left-0">
          <span>
            <i class="fa fa-fw fa-plus"></i>
          </span>
          <span class="hidden-xs">
            Add
          </span>
        </button>
      </div>
    </div>
    <div class="panel-body bgcolor-lightgrey" *ngIf="!showAddVehicleSection">
      <div class="row" *ngIf="importVehicleList.length > 0">
        <div class="col-sm-12">
          <p>Please select the vehicle which you have brought with you. Click on "Add Vehicle" if you don't see that vehicle in below list.</p>
        </div>
      </div>
      <div class="row">
      </div>
      <div class="row search-row">
        <form [formGroup]="vehicleSearchForm" (ngSubmit)="handleVehicleSearch()">
        <div class="col-xs-12 col-sm-4 uc-ngx-select">
          <ngx-select class="display-full margin-bottom-20" [items]="searchByItem"  formControlName="search_by"
             placeholder="Search By" [noAutoComplete]="true">
          </ngx-select>
        </div>
        
        <div class="col-xs-10 col-sm-6 col-lg-5 search-input-col">
          <mat-form-field class=" car-input btn-block" floatLabel="never" appearance="fill">
            <input matInput placeholder="Search Keyword" title="Search Keyword"  formControlName="search_keyword"
               class="form-control input-lg custom-form-input" (keydown.enter)="handleVehicleSearch()" />
               <mat-error *ngIf="vehicleSearchForm.controls.search_keyword.hasError('required')">
                Search keyword is <strong>required</strong>
              </mat-error>
            </mat-form-field>
           <button  type="submit"  class="search-btn"  title="Search">
              <mat-icon>search</mat-icon><span>Search</span>
            </button>
        </div>
        
        <div class="col-xs-2 col-sm-2 padding-sm-left-0">
          <button type="button" class="clear-btn" (click)="handleClearVehicleSearch()"  [ngClass]="this.payload.search_keyword =='' ? 'clr-filter' : ''" ><i class="fa fa-ban" aria-hidden="true"></i><span>Clear</span>
          </button>
        </div>
        </form>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="list-wrap" *ngIf="importVehicleList.length>0">
            <div class="list-group" *ngFor="let vehicle of importVehicleList; let i = index"
              [class.hide]="selectedVehicle && selectedVehicle.id!=vehicle.id">
              <div *ngIf="vehicle.is_walkin_customer_vehicle == '0' || vehicle.id != importVehicleId">
              <div class="list-group-item relative" [class.border-color-dark]="vehicle.id==importVehicleId">
                <div class="row list-row">
                  <div class="col-sm-9 col-md-10">
                    <div class="row vehicle-info-wrap">
                      <div class="col-xs-5 col-sm-2 image-section">
                        <img class="img-responsive" src="{{imgPath}}default-car.png" *ngIf="vehicle.vehicle_pic == ''">
                        <img class="img-responsive" src="{{imgPath}}{{vehicle.vehicle_pic}}" *ngIf="vehicle.vehicle_pic != ''">
                      </div>
                      <div class="col-xs-7 col-sm-9 car-info-section">
                        <h2 class="car-name">
                          {{ getVehicleName(vehicle) || 'N/A' }} &nbsp;&nbsp;
                          <mat-chip class="anotherOwner-badge" selected="true"
                            *ngIf="vehicle.customer_id != importCustomerData[0].id">Temp</mat-chip>
                            &nbsp;&nbsp;<mat-chip class="anotherOwner-badge" selected="true"
                            *ngIf="vehicle.is_walkin_customer_vehicle == '1'">Walkin</mat-chip>
                        </h2>
                        <p class="car-info hidden-xs" style="text-transform:uppercase;"><span
                          [innerHTML]="vehicle.license_plate ? vehicle.license_plate : 'N/A' "></span> <span
                          *ngIf="vehicle.unit_number">({{vehicle.unit_number}})</span></p>
                        <p class="car-info hidden-xs" [innerHTML]="vehicle.vin ? vehicle.vin : 'N/A' "></p>
                      </div>
                    </div>
                  </div>                  
                  <div class="col-xs-12 col-sm-3 col-md-10 col-sm-2 select-btn text-right">
                    <button *ngIf="selectedVehicle == null" mat-raised-button class=""
                      type="button" title="Select" color="accent" (click)="handleVehicleSection(vehicle)" [disabled]="vehicle.id ==importVehicleId">
                      <span>{{vehicle.id ==importVehicleId?"Selected":"Select"}}</span>
                    </button>
                    <div *ngIf="vehicle.id !== importVehicleId">
                    <button *ngIf="selectedVehicle && selectedVehicle.id ==vehicle.id" mat-raised-button
                      class="selected" type="button" title="Select" color="accent"
                      (click)="handleUnSelectVehicle()">
                      Unselect
                    </button>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <div *ngIf="selectedVehicle && vehicle.id ==importVehicleId" class="margin-top-15">
                <p  class="mat-error"> The service request is already created for this vehicle. Please select another vehicle if you want to change the vehicle.</p>
              </div>
              
              <div *ngIf="selectedVehicle" class="margin-top-15">
                <p class="mat-error" *ngIf="vehicle.ownerStatus !== 'undefined' && vehicle.ownerStatus">
                  This vehicle is owned by another customer registered in CAR. We will send vehicle transfer ownership request to current owner..
                </p>
              </div>
            </div>
          </div>
          <div class="noVehicles" *ngIf="importVehicleList.length == 0">
           <p class="mat-error text-center"> No vehicle Found</p>
          </div>
        </div>
      </div>
      <div class="row addVehicle-text-row" *ngIf="importVehicleList && selectedVehicle == null">
        <div class="col-sm-8 col-lg-10 font-bold">Do you want to add a new vehicle? Please click "Add Vehicle" button.</div>
        <div class="col-sm-3 col-lg-2 text-right">
          <button type="submit" mat-raised-button class="fontcolor-white" color="accent"
            title="Add Vehicle" (click)="showAddVehiclesSection()">
            Add Vehicle
          </button>
        </div>
      </div>
    </div>
    <div class="add-vehicle-row row margin-sm-0" *ngIf="showAddVehicleSection">
      <form [formGroup]="addVehicleForm"
        class="form-inline col-xs-12 padding-bottom-15 add-vehicle-form">
        <div class="row margin-0">
          <div class="col-sm-12 margin-top-10">
            <p>Please enter license plate or Make, Year and Model.</p>
          </div>
        </div>
        <div class="col-xs-12 form-block">
          <div class="row">
            <div class="form-group col-sm-6">
              <label for="">License Plate</label>
              <div class='relative'>
                <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                  <input matInput placeholder="Enter License Plate Number" title="License Plate"
                    formControlName="license_plate" (blur)="checkLicense()" (keyup.enter)="checkLicense()"
                    class="form-control input-lg custom-form-input" maxlength="20"/>
                </mat-form-field>
              </div>
            </div>
            <div class="form-group col-sm-6">
              <label for="">State</label>
              <div class='relative uc-ngx-select'>
                <ngx-select class="display-full margin-bottom-20" [defaultValue]="defaultState" formControlName="state"
                  [items]="states" (select)="handleSelectedState($event)" placeholder="Select State">
                </ngx-select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 text-center">
              <p class="font-bold margin-0">OR</p>
            </div>
          </div>
          <div class="row">
            <div class="form-group  col-sm-6 uc-ngx-select">
              <label for="">Make</label>
              <ngx-select class="display-full margin-bottom-20" formControlName="make" [items]="vehicleMakes"
                (select)="handleSelectedMakes($event)" placeholder="Select Make" *ngIf="!showMakeTxtFld">
              </ngx-select>
              <mat-form-field class="btn-block car-input" floatPlaceholder="never" *ngIf="showMakeTxtFld" appearance="fill">
                <input matInput placeholder="Make" title="Make" formControlName="make"
                  class="form-control input-lg custom-form-input" maxlength="100"/>
              </mat-form-field>
              <a href="javascript:void(0);" class="others-link make-link" (click)="useExitingMake(true)"
                *ngIf="!showMakeTxtFld">Another Make</a>
              <a href="javascript:void(0);" class="others-link make-link" (click)="useExitingMake(false)"
                *ngIf="showMakeTxtFld">Existing Make</a>
            </div>
          </div>
          <div class="row">
            <div class="form-group  col-sm-6 uc-ngx-select">
              <label for="">Year</label>
              <ngx-select class="display-full margin-bottom-20" formControlName="year" [items]="vehicleYears"
                (select)="handleSelectedYear($event)" [ngClass]="disableYear ? 'disabledDropDown':''"
                placeholder="Select Year" *ngIf="!showYearTxtFld">
              </ngx-select>

              <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill" *ngIf="showYearTxtFld">
                <input  matInput placeholder="Year" title="Year" formControlName="year"
                  class="form-control input-lg custom-form-input" maxlength="4" minlength="4" (keypress)="onlyDecimalNumberKey($event)" (paste)="validateFieldOnPaste('year')"/>
                  <mat-error *ngIf="addVehicleForm.controls.year.hasError('pattern') && addVehicleForm.get('year').touched">Please enter a valid year. </mat-error>
              </mat-form-field>
              <div *ngIf="showYearAndModel">
                <a href="javascript:void(0);" class="others-link make-link" (click)="useExitingYear(true)"
                  *ngIf="!showYearTxtFld">Another year</a>
                <a href="javascript:void(0);" class="others-link make-link" (click)="useExitingYear(false)"
                  *ngIf="showYearTxtFld">Existing year</a>
              </div>

            </div>
          </div>
          <div class="row">
            <div class="form-group  col-sm-6 uc-ngx-select">
              <label for="">Model</label>
              <ngx-select class="display-full margin-bottom-20" formControlName="model" [items]="vehicleModels"
                (select)="getSelectedOptionModels($event)" [ngClass]="disableModel ? 'disabledDropDown':''"
                placeholder="Select Model" *ngIf="!showModelTxtFld">
              </ngx-select>
              <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill"
                *ngIf="showModelTxtFld">
                <input matInput placeholder="Model" title="Model" formControlName="model"
                  class="form-control input-lg custom-form-input" maxlength="100"/>
              </mat-form-field>
              <div *ngIf="showModel">
                <a href="javascript:void(0);" class="others-link make-link" (click)="useExitingModel(true)"
                  *ngIf="!showModelTxtFld">Another Model</a>
                <a href="javascript:void(0);" class="others-link make-link" (click)="useExitingModel(false)"
                  *ngIf="showModelTxtFld">Existing Model</a>
              </div>
            </div>
          </div>
          <div class="row common-error" *ngIf="showCommonErrorMessage == 3">
            <div class="col-sm-12 margin-top-10">
              <p class="mat-error">Please enter license plate or Make, Year and Model.</p>
            </div>
          </div>
        </div>
        <div class="col-sm-12 data-fields">
          <div class="row">
            <div class="col-xs-12 submit-block">
              <div class="row">
                <div class="col-md-6 margin-sm-bottom-20">
                </div>
                <div class="col-md-6 text-right">
                  <button type="button" mat-raised-button
                    class="reset-btn btn-block fontcolor-white bg-black" title="Reset" (click)="reSetAddVehicleForm()">
                  Reset
                  </button>
                  <button type="button" mat-raised-button class="btn-block fontcolor-white" color="accent" title="Add vehicle" (click)="AddNewVehicles()">
                  Add Vehicle
                </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    </div>
</div>