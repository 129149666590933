<div mat-dialog-title class="relative" >
    License Plate
    <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
        <mat-icon class="notranslate close-icon-lg">close</mat-icon>
    </button>
</div>
<!-- BODY -->
<form class="form-horizontal form-control-normal" autocomplete="off" [formGroup]="licensePlateForm" (ngSubmit)="qq?getLicensePlate():checkLicence()">
<mat-dialog-content>
        <div class="display-full margin-bottom-5">
            <div class="col-sm-6">
                <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                    <input matInput type="text" placeholder="Plate number*" title="Plate Number" formControlName="plate_number" class="form-control input-lg custom-form-input btn-block"  maxlength="15" />
                    <mat-error *ngIf="licensePlateForm.controls.plate_number.hasError('required')">
                        Plate number cannot be empty
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <div class='relative uc-ngx-select modal-autoselect'>
                    <ngx-select 
                        [defaultValue]="defaultState"
                        formControlname="state"        
                        [items]="states" 
                        (select)="getSelectedOptionState($event)"
                        placeholder="Select State">
                    </ngx-select>                    
                </div>
                <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper" style="margin-top:25px;" *ngIf="!qq">
                    <mat-error *ngIf="licensePlateForm.value.state == ''">
                        This field cannot be empty
                    </mat-error>
                </div>
            </div>
        </div>      
      
      <div class="display-full margin-bottom-5">
          <div class="col-sm-12 text-right">

          </div>
      </div>        
    </mat-dialog-content>
    <mat-dialog-actions>
        <div class="text-right flexBtns">
            <button type="button" mat-raised-button  color="accent" title="Ok"  (click)="qq?getLicensePlate():checkLicence()"> OK</button>
            <button mat-raised-button mat-dialog-close class="font-bold" title="Close" id="close">Close</button>
        </div>
    </mat-dialog-actions>
</form>

<!-- END BODY -->
