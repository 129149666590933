import {Component, OnInit} from '@angular/core';
import {ChangeDetectorRef} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {globalFunctions} from "../../../environments/globalFunctions";
import {globalConstants} from "../../../environments/globalConstants";
import {GlobalService} from "../../../environments/global.service";
import {Title} from '@angular/platform-browser';
import {Location} from '@angular/common';
import { AppComponent } from "../../app.component";
import { Socket } from 'ngx-socket-io';

const EMAIL_REGEX = globalConstants.EMAIL_REGEX;
 @Component({
    selector: 'app-shoplogin',
    templateUrl: './shoplogin.component.html',
    styleUrls: ['./shoplogin.component.scss'],
})

export class ShoploginComponent implements OnInit {
    public shop: FormGroup;
    public submitted = false;
    public forgotPassword = false;
    public showLoginPanel = true;
    public loginError = false;
    public hide1: boolean = false;
    public enableInput =  false;
    public message:any;
    constructor(
        public cdr:ChangeDetectorRef,
        public app:AppComponent,
        private formBuilder: FormBuilder, 
        private router: Router, 
        public titleService: Title, 
        public location: Location, 
        public socket: Socket,
        private globalService: GlobalService) {
        this.globalService.setMetaData("SHOPS","LOGIN");
        if (localStorage.getItem("loggedInUser") != undefined && JSON.parse(localStorage.getItem("loggedInUser")).user_type == "User") {
            this.app.showCommonHeader=true;
            this.app.showShopView = true;
            this.router.navigate(["shops/dashboard"]);
        }
        //old code start for web-notification(FCM)
        // if (("Notification" in window)) {
        //     this.msgService.getPermission()
        //     this.msgService.receiveMessage()
        //     this.message = this.msgService.currentMessage;
        // }
        //  old code end for web-notification(FCM)
    }

    ngOnInit() {
        this.shop = this.formBuilder.group({
            email: ['', [Validators.required, Validators.pattern(EMAIL_REGEX)]],
            password: ['', [Validators.required]],
            remember_me:['']
        });
        if(this.getCookie("remember_me_info")) {
          let savedData = JSON.parse(localStorage.getItem("remember_me_info"));
          this.shop.controls.email.setValue(atob(savedData.email));
          this.shop.controls.password.setValue(atob(savedData.password));
          this.shop.controls.remember_me.setValue(true);
        }
    }
    login() {
        this.submitted = true;
        if (this.shop.valid) {
            let formdata = new FormData();
            formdata.append("email",this.shop.value.email);
            formdata.append("password",this.shop.value.password);
            formdata.append("user_type","user");
            // formdata.append("web_token",this.msgService.currentToken); //there sending the FCM token when login but now  no need for this
            this.globalService.formData(globalConstants.API_LOGIN_URL, formdata).subscribe((data:any) => {
                let response = data;
                if (response.result == 1) {
                    this.globalService.snackbar("success", response.message);
                    response.data['timeStamp'] = globalFunctions.getCurrentTimeStamp(); // set timestamp normal
                    response.data.password = "";
                    let jwt={ jwt: response.data.t ,token: response.data.jwt ,type:response.data.user_type,module:btoa(response.data.id)};
                    localStorage.setItem(globalConstants.SHOP_JWT_KEY, JSON.stringify(jwt));
                    let deviceToken=response.data['t'];
                    delete response.data['t'];
                    delete response.data['jwt'];
                    /** google translate init START*/
                    let langEnable = response.data.lang_enable;
                    let select = document.getElementById('google_translate_element');
                    if (langEnable == 1) {
                        select.style.visibility = 'visible';
                    } else {
                        select.style.visibility = 'hidden';
                    }
                    if(response.data.original_lang=='1'){
                        this.globalService.setGLangCss('#goog-gt-tt{}');
                    }else{
                        this.globalService.setGLangCss(this.globalService.GLANGSTYLE);
                    }
                    // if (currentLang != null && currentLang) {
                    //     this.globalService.setDefaultLang(currentLang);
                    // } else {
                    //     this.globalService.setDefaultLang('English');
                    // }
                    /** google translate init END*/
                    localStorage.setItem("loggedInUser", JSON.stringify(response.data));
                    this.app.getMenuForShops();
                    this.app.showCommonHeader=true;
                    this.app.showShopView = true;
                    this.app.getCurrentShopSubscription();
                    // verify latest uploaded build
                    this.globalService.verifyBuildVersion('shop');
                    this.setCookie();
                    if(response.data.role_id == 5){
                        this.router.navigate(["/shops/self-checkin"]);
                    }else{
                        try {
                            const socketId=this.socket.ioSocket.id;
                            this.app.updateSocketId(deviceToken,socketId).then(() => { }).catch(() => {  });
                        }
                        catch(e){}
                        setTimeout(() => {
                            window.location.reload(); 
                        }, 1000);
                    }
                    
                } else {
                    this.globalService.snackbar("error", response.message);
                    this.loginError = true;
                }
            });
        }
    }
    setCookie(){
      if(this.shop.value.remember_me) {
        let savingCookie = {
          email:btoa(this.shop.controls.email.value),
          password:btoa(this.shop.controls.password.value)
        }
        localStorage.setItem("remember_me_info",JSON.stringify(savingCookie));
      } else {
        localStorage.removeItem("remember_me_info");
      }
    }
    getCookie(name){
      return localStorage.getItem("remember_me_info")!=null && localStorage.getItem("remember_me_info")!=undefined ? true : false
    }
    showForgotPasswordPanel() {
        this.showLoginPanel = false;
        this.router.navigateByUrl("/pages?next=/shops/forgot_password",{skipLocationChange:true})
        // this.router.navigate(["/shops/forgot_password"]);
    }
    registration(){
        this.router.navigate(["shops/register"])
    }
}
