import {Component, OnInit, ViewChild, Pipe, Inject, ElementRef, ViewEncapsulation,OnDestroy} from '@angular/core';
import {AbstractControl, FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import {Router, ActivatedRoute, ParamMap, NavigationEnd, Route} from '@angular/router';
import {Http, Headers, Response} from '@angular/http';
import {HttpHeaders} from '@angular/common/http';
import {globalFunctions} from "../../../../environments/globalFunctions"
import {globalConstants} from "../../../../environments/globalConstants"
import {GlobalService} from "../../../../environments/global.service"
import {lang} from "../../../../environments/lang-en"
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {TextMaskModule} from 'angular2-text-mask';

import {DataSource, SelectionModel} from '@angular/cdk/collections';
import {Observable} from 'rxjs/Observable';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';


import 'rxjs/add/operator/startWith';
import 'rxjs/add/observable/merge';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime'
const SHOP_PART_LIST='shop-part-';
import { StockorderpopupComponent } from '../stockorderpopup/stockorderpopup.component';
import {ScrollToService} from 'ng2-scroll-to-el';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {MatSort, Sort, MatSortModule} from '@angular/material/sort'

@Component({
    selector: 'app-listshopparts',
    templateUrl: './listshopparts.component.html',
    styleUrls: ['./listshopparts.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ListshoppartsComponent implements OnInit,OnDestroy {
    public currentCustomer;
    public currentCustomerName;
    public currentCustomerVehicles;
    public isCollapsed: boolean = true;
    public openShopPartsListSearch: boolean = true;
    public currentShopDetails;
    public listShops;
    public listParts = [];
    public UnapprovedPartsList = [];
    public result: any;
    public allTags=[];

    displayedColumns = ['name', 'part_number', 'manufacturer','matrix_price','quantity','Picture','added_on','Actions'];
    dataSource: MatTableDataSource<UserData> = new MatTableDataSource();

    public more_rows;
    public options:any = {
        user_type: "User",
        user_id: "",
        search_by: '',
        sort_by: "",
        sort_type: "",
        search_keyword: "",
        search_date:"",
        part_tag:"",
        start: 0 ,
        limit: 10 ,
    }
    public modulesName='part';
    public partPicture=globalConstants.S3_BUCKET_URL+globalConstants.PART_PICTURE+globalConstants.IMG_THUMB;
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort!: MatSort;
    public searchByArray = [];
    public sortByArray = [];
    public sortTypeArray = [];
    public isInventory=false;
    public addTostockOrder=false;
    public showCostPrice=false;
    public searchEnabled: boolean = false;
    public subscriptionDataForAPI: Subscription = new Subscription();
    recordsTotal: any;
    recode:any = [];
    constructor(public titleService: Title, public globalService: GlobalService, private route: ActivatedRoute, private http: Http, private router: Router,public dialog: MatDialog) {
        this.globalService.setMetaData("SHOPS","LIST_PARTS")
        this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
        this.options.user_id = this.currentCustomer.id;
        this.currentCustomerName = this.currentCustomer.first_name + this.currentCustomer.last_Name;
        this.reinitializeOptions();
        this.openShopPartsListSearch = this.openSearch(SHOP_PART_LIST);
        /* SEARCH BY ARRAY */
        this.searchByArray = [
            { id: 1, text: "Part Name"},
            { id: 2, text: "Part Number"},
            { id: 6, text: "Manufecturer"},
            { id: 3, text: "OEM Part Number"},
            { id: 4, text: "Deleted"}
        ];
        /* SORT BY ARRAY */
        this.sortByArray = [
            { id: 'name', text: "Part Name"},
            { id: 'part_number', text: "Part Number"},
            { id: 'price', text: "Part Price"}
        ];
        
        /* SORT TYPE ARRAY */
        this.sortTypeArray = [
            { id: 'ASC', text: "Ascending"},
            { id: 'DESC', text: "Descending"}
        ];
    }

    ngOnInit() {
        setTimeout(()=>{
            let isEnable=this.globalService.getAcl(this.modulesName,'enable');
            if(!(isEnable)){
                this.router.navigate(['shops/action/forbidden']);
            }   
            this.addTostockOrder=this.globalService.getAcl('stock_order','addNew');
            this.showCostPrice=this.globalService.getAcl('display_part_cost','views');
          },globalConstants.RELOAD_WAIT);
        this.isInventory = this.globalService.isInventories();
        if(this.isInventory){
            this.searchByArray.push({ id: 5, text: "Low Stock"});
        }
        this.getAllTags();
        
        let s=this.globalService.localGetter('search_by','shop-part-');
        let sd=this.globalService.localGetter('search_date','shop-part-');
        if(s=="5"){
            this.options.search_by=5;
        }
        this.setDataTable();
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
      }

    formatDate(date) {
        if (!new Date(date) || date == null || date == "0000-00-00 00:00:00") {return "--"}
        return (new Date(date).getMonth() + 1) + "/" + new Date(date).getDate() + "/" + new Date(date).getFullYear()
    }

    search(event) {
        this.options.start = 0;
        this.setDataTable();
        this.searchEnabled = true;
    }

    clearSearch() {
        this.options.search_by = '';
        this.globalService.localGetterSetter(null,this.options,SHOP_PART_LIST);
        this.reinitializeOptions();
        this.setDataTable();
        this.searchEnabled = false;
    }

    editPart(part_id) {
        this.globalService.localGetterSetter(this.options,null,SHOP_PART_LIST);
        localStorage.setItem("shopPartID", part_id);
        this.router.navigate(['shops/edit-part']);
    }

    // ######## DELETE PART ########

    deletePart(part_id) {
        this.globalService.localGetterSetter(this.options,null,SHOP_PART_LIST);
        var stringifiedFormData = "user_type=" + this.currentCustomer.user_type + "&user_id=" + this.currentCustomer.id + "&part_id=" + part_id;
        this.globalService.confirmDialog(part_id, 'Are you sure you want to delete this part?').subscribe((res) => {
            this.result = res;
            if (this.result != null) {
               this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_DELETE_PART_URL, stringifiedFormData).subscribe((data:any) => {
                        var message = data.message;
                        if (data.result == "1") {
                            this.globalService.onDeleteCheck(SHOP_PART_LIST,this.options)
                            this.reinitializeOptions()
                            this.setDataTable();
                            this.globalService.snackbar('success', message);
                        } else {
                            this.globalService.snackbar("error", message);
                        }
                });
            }
        });
    }

    sideNavOpen() {

    }

    checkFormEmpty(button) {
        if (this.options.part_tag=="" && this.options.search_keyword == "" && (this.options.search_by == '' || this.options.search_by == null || this.options.search_by == "null") && this.options.sort_by == "" && this.options.sort_type == "") {
            if (button) {button.color = "primary"}
            return false
        }
        else {
            if (button) {button.color = "accent"}
            return true;
        }
    }

    onResize(event) {
        event.target.innerWidth;
    }

    getCurrentRoute() {
        return this.router.url
    }

    scrolling(){
        this.globalService.setScrolling('.car-datatable', 1000, -20);
    }

    paginateByLimit(event){
        this.globalService.setScrolling('body', 600, 0);
        this.options.limit = event.pageSize;
        this.options.start = 0;
        this.scrolling();
        this.setDataTable();
    }

    reinitializeOptions() {
        this.options = this.globalService.localGetterSetter(this.options,this.options,SHOP_PART_LIST);
        this.options.user_id= this.currentCustomer.id;
        this.options.user_type= this.currentCustomer.user_type;
        if(this.options.search_by == null || this.options.search_by == "null"){
            this.options.search_by = '';
        }else{
            if(this.options.search_by){
                this.options.search_by = Number(this.options.search_by);
            }
        }
    }

    getTotalRecords() {
        let t=parseInt(localStorage.getItem(SHOP_PART_LIST+'totalRecords'));
        return (isNaN(t) ? 0 : t );
    }

   paginate(event,type){
        this.scrolling();
        if(type=="next") {
          this.options.start = this.options.start + this.options.limit;

        } else {
          this.options.start = this.options.start - this.options.limit;

        }
        this.setDataTable();
    }



    getUpperLimit(currentLastLimit,total) {
        if(currentLastLimit > total) {
          return total
        } else {
          return currentLastLimit
        }
    }
    setDataTable(){
        this.recode = [];
        var callApi = this.globalService.callAPI(globalConstants.API_GET_PARTS_LIST_URL, this.options);
        callApi.subscribe((data:any) => {
            if (data.data != undefined) {
                let moreRows='false';
                let firstList='false';
                this.globalService.localGetterSetter("totalRecords",data.recordsTotal,SHOP_PART_LIST);
                if(data.more_rows == "true") {
                    moreRows="true";
                }
                this.globalService.localGetterSetter("more_rows",moreRows,SHOP_PART_LIST);
                if (data.start >= 0 && data.start <= 5) {
                    firstList="true";
                }
                this.globalService.localGetterSetter("first_list",firstList,SHOP_PART_LIST);
                this.recordsTotal = data.data.length;
                if(data.data.length==undefined){
                    this.dataSource.data = data.data
                    this.AddRecord(data.data)
                    this.globalService.localGetterSetter("current-visible",'1',SHOP_PART_LIST);
                    this.dataSource = new MatTableDataSource(this.recode)
                    this.dataSource.sort = this.sort;
                } else {
                    this.globalService.localGetterSetter("current-visible",this.recordsTotal,SHOP_PART_LIST);
                    // this.dataSource.data = data.data
                    for(var i=0;i<data.data.length;i++){
                        this.AddRecord(data.data[i])
                    }
                    this.dataSource = new MatTableDataSource(this.recode)
                    this.dataSource.sort = this.sort;
                   
                }
                this.globalService.localGetterSetter(null,this.options,SHOP_PART_LIST);
            } else {
                this.dataSource = new MatTableDataSource(this.recode)
                this.globalService.localGetterSetter("totalRecords",'0',SHOP_PART_LIST);
            }
        })
    }

    AddRecord(record) {
        var data = this.createRecordrecord(record);
        this.recode.push(data);
    }

    private createRecordrecord(record) {
        return {
            name: record.name,
            part_number: (record.part_number) ? (record.part_number) : '-',
            manufacturer:  (record.manufacturer) ? (record.manufacturer) : '-',
            price: parseFloat(record.price),
            cost: record.cost,
            description: record.description,
            picture: record.part_picture,
            added_on: this.globalService.getFullFormateDate(record.add_date,'GD','H'),
            part_id: record.id,
            delete_status: record.delete_status,
            quantity : record.quantity,
            low_stock_value : record.low_stock_value,
            is_low_stock : this.isLowStock(record.low_stock_value,record.quantity),
            matrix_price:record.part_matrix_price,
            matrix_status:record.part_matrix_status
        };
    }
   
    firstList() {
        return this.globalService.firstList(SHOP_PART_LIST,this.options);
    }

    lastList(){
       return this.globalService.lastList(SHOP_PART_LIST);
    }
    isAllowed(action,module?:any){
        return this.globalService.getAcl(this.modulesName,action);
    }
    enablePart(part_id){
        this.globalService.localGetterSetter(this.options,null,SHOP_PART_LIST);
        var stringifiedFormData = "user_type=" + this.currentCustomer.user_type + "&user_id=" + this.currentCustomer.id + "&part_id=" + part_id;
        this.globalService.confirmDialog(part_id, 'Are you sure you want to enable this part?').subscribe((res) => {
            this.result = res;
            if (this.result != null) {
                this.subscriptionDataForAPI =   this.globalService.callAPI(globalConstants.API_ENABLE_PART_URL, stringifiedFormData).subscribe((data:any) => {
                        var message = data.message;
                        if (data.result == "1") {
                            this.setDataTable();
                            this.globalService.snackbar('success', message);
                        } else {
                            this.globalService.snackbar("error", message);
                        }
                });
            }
        });
    }
    viewLog(id){
        this.globalService.localGetterSetter(this.options,null,SHOP_PART_LIST);
        this.router.navigate(['shops/part/logs',id]);
    }
    isLowStock(val,quantity){
     let lq=parseFloat(val);
     let q=parseFloat(quantity);

     return (q <= lq ? true : false);
    }

    showWholeNumber(val){
      return globalFunctions.hasDecimal(val,true);
    }
    getAllTags(){
        let data={user_id: this.currentCustomer.id};
        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_LIST_PART_TAGS,data).subscribe((res:any)=>{
            if (res.result == "1") {
                this.allTags=res.data;
            }
        },(error)=>{})
    }
    addStockOrder(row) {
        row.id = row.part_id;
        let dialogRef = this.dialog.open(StockorderpopupComponent, {
            panelClass: ['car-dialog-form','ticket-dialog'],
            width: '800px',
            data: { newAllowed: true, recom: null, request_id: "", requestId: '', part: row }
        });
    }

    goToSmartBatchList(){
        if(this.searchEnabled){
            this.globalService.localGetterSetter(this.options,null,SHOP_PART_LIST);
        }
        this.router.navigate(['shops/list-smart-batch']);
    }

    public checkSearchFilterValues(){
        if(this.options.search_by == "" && this.options.search_keyword == "" && this.options.sort_by == "" &&  this.options.sort_type == "" && this.options.part_tag == "") {
            return false;
          } else {
            return true;
          }
    }

    openSearch(PREFIX){
        let searchBy = localStorage.getItem(PREFIX+"search_by");
        let search_keyword = localStorage.getItem(PREFIX+"search_keyword");
        let sort_by = localStorage.getItem(PREFIX+"sort_by");
        let sort_type = localStorage.getItem(PREFIX+"sort_type");
        let part_tag = localStorage.getItem(PREFIX+"part_tag");
        if(searchBy == "null" || searchBy == null){
            searchBy = '';
        }
        if(search_keyword == "null" || search_keyword == null){
            search_keyword = '';
        }
        if(sort_by == "null" || sort_by == null){
            sort_by = '';
        }
        if(sort_type == "null" || sort_type == null){
            sort_type = '';
        }
        if(part_tag == "null" || part_tag == null || part_tag.length == 0){
            part_tag = '';
        }
        
        if(searchBy == "" && search_keyword == "" && sort_by == "" && search_keyword == "" && sort_type == "" && part_tag == "") {
          return false;
        } else {
          return true;
        }
      }
      ngOnDestroy(){
        this.subscriptionDataForAPI.unsubscribe();
    }  

}

export interface UserData {
    name: string;
    part_number: string;
    manufacturer:string;
    price: number;
    cost:number;
    description: string;
    picture: string;
    added_on: string;
    part_id: number;
    delete_status:number;
    quantity:number;
    low_stock_value:number;
    is_low_stock:number;
    matrix_price:number;
    matrix_status:number;
}

