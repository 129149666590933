<div mat-dialog-title class="relative">
  <span *ngIf="techStatus==false">Ticket Assignment</span>
  <span *ngIf="techStatus==true">Technician Working on this request</span>
  <button mat-mini-fab mat-dialog-close class="close-modal notranslate mat-elevation-z0">
    <mat-icon aria-label="Example icon-button with a heart icon">close</mat-icon>
  </button>
</div>
<mat-dialog-content class="ticket_dialog_content overflow-data" *ngIf="techStatus==false">
  <div class="center-block search-employee" style="max-width: 650px;">
    <form class="form-horizontal form-control-normal" autocomplete="off" [formGroup]="tech">
      <div class="vehicle-detail" *ngIf="(selectSR | json) != ({} | json)">
        <div class="row">
          <div class="col-sm-12">
            <h4>Service Request Information</h4>
          </div>
          <div class="col-sm-12"><label>SR ID : </label> {{selectSR.request_id}}</div>
          <div class="col-sm-12" *ngIf="selectSR.requested_delivery_date && selectSR.requested_delivery_date!='-'">
            <label>Appointment Date : {{ convertISODate(selectSR.requested_delivery_date) | date:'M/d/yy h:mm a'}} </label>
          </div>
        </div>
        <div class="break row"></div>
        <div class="row">
          <div class="col-sm-12">
            <h4>Vehicle Information</h4>
          </div>
          <div class="col-sm-12" *ngIf="selectSR.year !=undefined && selectSR.make !=undefined &&
          selectSR.model !=undefined"><label>Year/Make/Model : </label>
            {{selectSR.year+'/'+selectSR.make+'/'+selectSR.model}} &nbsp;&nbsp; <ng-container *ngIf="selectSR.state">
              <label>State : </label> {{selectSR.state}}</ng-container>
          </div>
          <div class="col-sm-12"><label>License Plate : </label> {{selectSR.license_plate}}</div>
          <div class="col-sm-12" *ngIf="selectSR.vin"><label>VIN : </label> {{selectSR.vin}}</div>
        </div>
        <div class="break row"></div>
        <div class="row">
          <div class="padd"><label>Estimated Service Time : </label> {{estimated_hours ? estimated_hours+' hour' : '-'}}
          </div>
        </div>
      </div>
      <div class="display-full margin-bottom-5" *ngIf="(onlySelf==false && fromConflict==false) || (fromConflict==true && userData && empId!=userData.id)">
        <label class="material-radio text-left">
          <div class="mat-radio-container1">
            <input type="radio" [checked]="tech.controls['assign_tomyself'].value==1" value="1" name="assign_tomyself"
              (change)="assignToMyself($event,1)" formControlName="assign_tomyself" />
            <strong class="mat-radio-label-content">Assign to yourself</strong>
          </div>
        </label>
      </div>
      <div class="display-full margin-bottom-5" *ngIf="userData.role_id!='4' && onlySelf==false">
        <label class="material-radio text-left">
          <div class="mat-radio-container1">
            <input type="radio" [checked]="tech.controls['assign_tomyself'].value==2" value="2" name="assign_tomyself"
              (change)="onAssignToTech($event,2)" formControlName="assign_tomyself" />
            <strong class="mat-radio-label-content">Assign to technician</strong>
          </div>
        </label>
      </div>
      <!-- <div class="select-totech uc-ngx-select" *ngIf="tech.value.assign_totech==2 && userData.role_id!='4'">
        <ngx-select class="display-full margin-bottom-20" [items]="shopTechnician" formControlName="emp_id"
          placeholder="Select technician">
        </ngx-select>
      </div> -->
      <div class="display-full margin-bottom-5" *ngIf="userData.role_id!='4' && onlySelf==false">
        <label class="material-radio text-left">
          <div class="mat-radio-container1">
            <input type="radio" value="3" [checked]="tech.controls['assign_tomyself'].value==3" name="assign_tomyself"
              (change)="onAssignToTech($event,3)" formControlName="assign_tomyself" />
            <strong class="mat-radio-label-content">Assign to employee</strong>
          </div>
        </label>
      </div>
      <!-- <div class="select-totech uc-ngx-select" *ngIf="tech.value.assign_totech==3 && userData.role_id!='4'">
      <ngx-select class="display-full margin-bottom-20" [items]="adviser" formControlName="adviser" placeholder="Select Employee">
      </ngx-select>
      </div> -->    
      <div class="select-totech uc-ngx-select"
        *ngIf="tech.controls['assign_tomyself'].value!=1 && userData.role_id!='4'">
        <ngx-select class="display-full margin-bottom-20" [noAutoComplete]="true"
          [items]="tech.controls['assign_tomyself'].value==2?shopTechnician:adviser" formControlName="emp_id"
          [placeholder]="tech.controls['assign_tomyself'].value==2?'Select technician':'Select Employee'" readonly="readonly">
        </ngx-select>
        <div class="errors"
            *ngIf="tech.controls['emp_id'].invalid && (tech.controls['emp_id'].dirty || tech.controls['emp_id'].touched)">
            <mat-error
              [hidden]="!((tech.controls['emp_id'].dirty) &&  tech.controls['emp_id'].errors?.required)">
              {{ tech.controls['assign_tomyself'].value==2?'Technician':'Employee' }} is <strong>required</strong>
            </mat-error>
          </div>
      </div>
      <div class="display-full margin-bottom-5">
        <div class="col-md-3 mat-radio-label-content" [style.padding-left]="0">Start Date:</div>
        <div class="col-md-5">
          <div class="vlio-datepicker relative">
            <dp-date-picker mode="day" [config]="datePickerConfig" theme="dp-material" class="btn-block"
              placeholder="Select Start date" formControlName="event_start_date"
              (ngModelChange)="startDateHandler($event)" #dayPicker></dp-date-picker>
            <button type="button" mat-icon-button (click)="dayPicker.api.open();">
              <mat-icon>date_range</mat-icon>
            </button>
          </div>
          <div class="errors"
            *ngIf="tech.controls['event_start_date'].invalid && (tech.controls['event_start_date'].dirty || tech.controls['event_start_date'].touched)">
            <mat-error
              [hidden]="!((tech.controls['event_start_date'].dirty) &&  tech.controls['event_start_date'].errors?.required)">
              Start Date is <strong>required</strong>
            </mat-error>
          </div>
        </div>
        <div class="col-md-4">
          <ngx-select class="display-full margin-bottom-20" [noAutoComplete]="true" formControlName="event_start_time" placeholder="Start Time"
           [items]="sTimeSlots" (select)="startTimeHandler($event)" >
          </ngx-select>
          <div
            *ngIf="tech.controls['event_start_time'].invalid && (tech.controls['event_start_time'].dirty || tech.controls['event_start_time'].touched)">
            <mat-error
              [hidden]="!((tech.controls['event_start_time'].dirty) &&  tech.controls['event_start_time'].errors?.required)">
              Start Time is <strong>required</strong>
            </mat-error>
          </div>
        </div>
      </div>
      <div class="display-full margin-bottom-5">
        <div class="col-md-3 mat-radio-label-content" [style.padding-left]="0">End Date:</div>
        <div class="col-md-5">
          <div class="vlio-datepicker relative">
            <dp-date-picker mode="day" [config]="datePickerConfig" theme="dp-material" class="btn-block"
              placeholder="Select End date" (ngModelChange)="endDateHandler($event)" formControlName="event_end_date"
              #dayPickerTwo>
            </dp-date-picker>
            <button type="button" mat-icon-button (click)="dayPickerTwo.api.open();">
              <mat-icon>date_range</mat-icon>
            </button>
          </div>
          <div
            *ngIf="tech.controls['event_end_date'].invalid && (tech.controls['event_end_date'].dirty || tech.controls['event_end_date'].touched)">
            <mat-error
              [hidden]="!((tech.controls['event_end_date'].dirty) &&  tech.controls['event_end_date'].errors?.required)">
              End Date is <strong>required</strong>
            </mat-error>          
          </div>
          <div class="f-error" >
              <mat-error *ngIf="error.dError">End Date can't before start date.</mat-error>
          </div>
        </div>
        <div class="col-md-4">
          <ngx-select class="display-full margin-bottom-20" [noAutoComplete]="true" formControlName="event_end_time" placeholder="End Time"
            [items]="eTimeSlots">
          </ngx-select>
          <div
            *ngIf="tech.controls['event_end_time'].invalid && (tech.controls['event_end_time'].dirty || tech.controls['event_end_time'].touched)">
            <mat-error
              [hidden]="!((tech.controls['event_end_time'].dirty) &&  tech.controls['event_end_time'].errors?.required)">
              End Time is <strong>required</strong>
            </mat-error>            
          </div>
          <div class="f-error" >
           <mat-error *ngIf="error.tError">End time can't before start time.</mat-error>
          </div>
        </div>
      </div>
      <div class="display-full overwrite-time">       
          <mat-checkbox formControlName="overwrite_time" class="">Do not overwrite the timing, while auto pushing the job queue</mat-checkbox>
          <mat-checkbox *ngIf="editShopForever" class="btn-block margin-bottom-10" id="requestid" labelPosition="after"
          (change)="skipForever($event)">Skip Forever </mat-checkbox>
      </div>     
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="inquiry-action relative" *ngIf="techStatus==false">
  <button mat-raised-button color="accent" (click)="assignTotech()" class="font-bold" title="Submit">Submit</button>
  <button mat-raised-button mat-dialog-close class="font-bold" title="Skip">Skip</button>
</mat-dialog-actions>
<mat-dialog-content *ngIf="techStatus==true">
  <div class="center-block" style="max-width: 830px;">
    <div class="table-responsive relative">
      <table class="table table-striped table-hover table-condensed sortable">
        <tbody>
          <tr>
            <th>Name</th>
            <th>Status</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Hour Allocated</th>
            <th>Hour Worked</th>
            <th></th>
          </tr>
          <tr *ngFor="let tech of techinianStatus;let i=index">
            <td>{{tech.first_name}} {{tech.last_name}}</td>
            <td>{{getStatus(tech.work_status)}}</td>
            <td><ng-container *ngIf="tech.start_date">{{ convertISODate(tech.start_date) | date:'M/d/yyyy, h:mm a' }}</ng-container><ng-container *ngIf="!tech.start_date">-</ng-container> </td>
            <td><ng-container *ngIf="tech.end_date">{{ convertISODate(tech.end_date) | date:'M/d/yyyy, h:mm a' }}</ng-container><ng-container *ngIf="!tech.end_date">-</ng-container> </td>
            <td>{{ convertMinutes(tech.allocated_time) }}</td>
            <td>{{ tech.totalHour }}</td>
            <td>
              <div class="btn-icon-block">
                <button class="btn btn-danger btn-icons" title="Report" type="button" aria-label="Close dialog" (click)="employeeReport(tech.user_id,tech.request_id)" mat-dialog-close>
                  <i aria-hidden="true" class="fa fa-bar-chart"></i>
                </button>
                <button class="btns btn-danger btn-icons" title="Delete" type="button" [disabled]="tech.work_status!=0" (click)="deleteAssignee(tech,i)">
                  <i class="fa fa-trash"></i>
                </button>
                <button class="btn btn-danger btn-icons" title="Stop Timer" type="button" *ngIf="(tech.work_status==1 || tech.work_status==2) && checkPermission('timer_off','enable')" (click)="stopTimer(tech,i)">
                  <i aria-hidden="true" class="fa far fa-clock"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="inquiry-action relative" *ngIf="techStatus==true">
  <button mat-raised-button mat-dialog-close class="font-bold">Close</button>
</mat-dialog-actions>