<div class="car-panel" id="mainCompotents">
    <div class="panel panel-primary" id="addGenericJobPanel">
        <div class="panel-heading padding-left-15">
            <h3 class="panel-title">
                EDIT VENDOR
            </h3>
            <button mat-mini-fab class="custom-btn-back" (click)="globalService.goBack();">
                <mat-icon>arrow_back</mat-icon>
            </button>
        </div>
        <div class="panel-body bgcolor-lightgrey" >
            <!-- Add Customer page start -->
            <div id="addGenericJobContent" class="row bgcolor-lightgrey form-content edit-vendor">
                <div class="container-fluid">
                    <form [formGroup]="editVendorForm" (ngSubmit)="editVendor()" class="form-inline col-xs-12 col-sm-12 col-md-12 col-lg-12" role="form" method="POST" enctype="multipart/form-data" autocomplete="off">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 form-block">
    
                            <div class="row">
                                <div class="form-group col-sm-12 col-md-6" >
                                    <label for="vendor_name">Vendor Name<span class="asterisk">*</span></label>
                                    <div class='relative'>
                                        <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                                            <input matInput placeholder="Vendor Name*" title="Vendor Name" formControlName="vendor_name"  class="form-control input-lg custom-form-input" [(ngModel)]="vendorName" maxlength="100"/>
                                            <mat-error *ngIf="editVendorForm.controls.vendor_name.hasError('required')">
                                                Vendor Name is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
    
                            <div class="row">
                                <div class="form-group col-sm-12 col-md-6"  >
                                    <label for="price">Vendor Link<span class="asterisk">*</span></label>
                                    <div class='relative'>
                                        <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                                            <input matInput placeholder="Vendor Link*" title="Vendor Link" formControlName="vendor_link" class="form-control input-lg custom-form-input" [(ngModel)]="vendorLink" maxlength="100"/>
                                            <mat-error *ngIf="editVendorForm.controls.vendor_link.hasError('required')">
                                                Vendor Link is required
                                            </mat-error>
                                           <mat-error *ngIf="editVendorForm.controls.vendor_link.hasError('pattern')">
                                               Please enter a valid link.
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
    
                            </div>
    
                            <div class="row">
                                <div class="form-group col-sm-12 col-md-12">
                                     <mat-checkbox class="btn-block checkbox-label-wrap" labelPosition="after" (change)="checkLeftMenuStatus($event)" formControlName="left_menu_status" [checked]="leftMenuStatus">
                                        Check if you want to display this vendor in left menu.
                                    </mat-checkbox>
                                    <p class="help-block"><strong>Note:</strong> You can display only 5 <strong>Vendors</strong> under Vendor tab in left menu.</p>
                                </div>
                            </div>
    
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 form-block submit-block">
                                    <button type="submit" mat-raised-button class="car-btn btn-block text-uppercase btn-lg font-light fontcolor-white" color="accent" title="Submit" style="max-width: 300px;">
                                        SUBMIT
                                    </button>
                                </div>
                            </div>
    
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>   
</div>
