<!--addgenericjob compoment start -->
<div class="car-panel">
    <div class="panel panel-primary" id="addGenericJobPanel">
        <div class="panel-heading padding-left-15">
            <h3 class="panel-title">
                Add New Job
            </h3>
        </div>
        <div class="panel-body bgcolor-lightgrey">
            <!-- Add Customer page start -->
            <div id="addGenericJobContent" class="row bgcolor-lightgrey form-content add-generic-job">
                <div class="container-fluid">
                    <form [formGroup]="shopAddJob" (ngSubmit)="addgenericjob()" class="form-inline col-xs-12 col-sm-12 col-md-12 col-lg-12" role="form" method="POST" enctype="multipart/form-data" autocomplete="off">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 form-block">
                            <div class="row">
                                <div class="form-group col-sm-12 col-md-6" >
                                    <label for="job_name">Name</label>
                                    <div class='relative'>
                                        <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                                            <input matInput placeholder="Name*" maxlength="200" title="Name" formControlName="job_name"  class="form-control input-lg custom-form-input" />
                                            <mat-error *ngIf="shopAddJob.controls.job_name.hasError('required')">
                                                This field cannot be empty
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
    
                            </div>
                            
                            <div class="row">
                                <div class="form-group col-sm-12 col-md-6"  >
                                    <label for="price">Job Cost</label>
                                    <div class='relative'>
                                        <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                                            <input matInput placeholder="Job Cost*" title="Job Cost" formControlName="cost" class="form-control input-lg custom-form-input" maxlength="15" (keydown)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event);convertIntoDecimal($event,'cost',11)" />
                                            <mat-error *ngIf="shopAddJob.controls.cost.hasError('required')">
                                                This field cannot be empty
                                            </mat-error>
                                            <mat-error *ngIf="shopAddJob.controls.cost.hasError('pattern')">
                                                Job Cost must be a number with 2 decimal places.
                                        </mat-error>
                                    </mat-form-field>
    
                                </div>
                            </div>
                            <div class="form-group col-sm-12 col-md-6"  >
                                <label for="cost">Retail Price</label>
                                <div class='relative'>
                                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                                        <input matInput placeholder="Retail Price*" title="Retail Price" formControlName="price"  class="form-control input-lg custom-form-input" maxlength="15" (keydown)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event);convertIntoDecimal($event,'price',11)" />
                                        <mat-error *ngIf="shopAddJob.controls.price.hasError('required')">
                                            This field cannot be empty
                                        </mat-error>
                                        <mat-error *ngIf="shopAddJob.controls.price.hasError('pattern')">
                                            Retail Price must be a number with 2 decimal places.
                                    </mat-error>
                                </mat-form-field>
    
                            </div>
                        </div>
                    </div>
    
                    <div class="row">
                        <div class="form-group col-sm-12"  >
                            <label for="description">Description</label>
                            <div class='relative'>
                                <mat-form-field class="btn-block car-input notes-input" floatPlaceholder="never" appearance="fill">
                                    <textarea matInput placeholder="Description" rows="5s" maxlength="1000" title="Description" formControlName="description"   class="form-control input-lg custom-form-input" ></textarea>
                                </mat-form-field>
    
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-12 col-md-12">
                             <mat-checkbox class="btn-block" labelPosition="after" (change)="showTaxExempted($event)" formControlName="non_taxable" >
                                Non Taxable
                            </mat-checkbox>
                            <p>Note: If checked then this generic job price would not be included in tax calculations.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 form-block submit-block">
                            <button type="submit" mat-raised-button class="car-btn btn-block text-uppercase btn-lg font-light fontcolor-white" color="accent" title="Submit" style="max-width: 300px;">
                                SUBMIT
                            </button>
                        </div>
                    </div>
                   
                </div>
            </form>
        </div>
            </div>
        </div>
    </div>

   
</div>
<!--addgenericjob compoment end -->
