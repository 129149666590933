<div mat-dialog-title class="relative">
  Message View
  <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
    <mat-icon class="close-icon-lg" aria-label="Example icon-button with a heart icon">close</mat-icon>
  </button>
</div>
<mat-dialog-content class="view-message-content">
    <div class="panelpanel-primary" *ngIf="serviceInfo">
        <div class="panel-bodybgcolor-lightgrey">
            <div class="jumbotron inspection-report padding-25 car-view-str margin-bottom-15">
                <h3 class="margin-top-0 view-message-title">Service Message Information</h3>
                <hr class="margin-5">
                <div class="row">
                    <div class="col-sm-6 margin-bottom-5">
                        <div class="row">
                            <div class="col-xs-6 col-sm-6">
                                <label class="control-label">ID :</label>
                            </div>
                            <div class="col-xs-6 col-sm-6 break-word">
                                <p class="pull-left">{{serviceInfo.request_id}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6  margin-bottom-5">
                        <div class="row">
                            <div class="col-xs-6 col-sm-6"><label class="control-label">Request ID :</label></div>
                            <div class="col-xs-6 col-sm-6 break-word"><p>{{serviceInfo.ticket_no}}</p></div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6  margin-bottom-5">
                        <div class="row">
                            <div class="col-xs-6 col-sm-6"><label class="control-label">Vehicle :</label></div>
                            <div class="col-xs-6 col-sm-6 break-word"><p data-original-title="" title="">{{serviceInfo.vehicleName}}</p></div>
                        </div>
                    </div>
                    <div class="col-sm-6  margin-bottom-5">
                        <div class="row">
                            <div class="col-xs-6 col-sm-6"><label class="control-label">Customer Name :</label></div>
                            <div class="col-xs-6 col-sm-6 break-word text-uppercase"><p>{{serviceInfo.first_name}} {{serviceInfo.last_name}}</p></div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6  margin-bottom-5">
                        <div class="row">
                            <div class="col-xs-6 col-sm-6"><label class="control-label">Date :</label></div>
                            <div class="col-xs-6 col-sm-6 break-word"><p class="form-control-static padding-0" id="title">{{getProperDate(serviceInfo.add_date)}}</p></div>
                        </div>
                    </div>
                    <div class="col-sm-6  margin-bottom-5">
                        <div class="row">
                            <div class="col-xs-6 col-sm-6"><label class="control-label">Mobile Number :</label></div>
                            <div class="col-xs-6 col-sm-6 break-word text-uppercase break-word"><p>
                              <a class="text-black" href="tel:{{serviceInfo.mobile_phone}}">{{serviceInfo.mobile_phone}}</a></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6  margin-bottom-5">
                        <div class="row">
                            <div class="col-xs-6 col-sm-6"><label class="control-label">Status :</label></div>
                            <div class="col-xs-6 col-sm-6 break-word">
                                <mat-chip-list class="mat-badge">
                                    <mat-chip [color]="getStatus(serviceInfo.status) == 'Closed' ? 'warn' : getStatus(serviceInfo.status) == 'Created' ? 'accent' : 'primary'" selected="true">{{getStatus(serviceInfo.status)}}</mat-chip>
                                </mat-chip-list>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6  margin-bottom-5 hidden-xs">
                        <div class="row">
    
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6  margin-bottom-5">
                        <div class="row">
                            <div class="col-xs-6 col-sm-6"><label class="control-label">Title :</label></div>
                            <div class="col-xs-6 col-sm-6"><p>{{serviceInfo.title}}</p></div>
                        </div>
                    </div>
                    <div class="col-sm-6  margin-bottom-5">
                        <div class="row">
    
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12  margin-bottom-5">
                        <div class="row">
                            <div class="col-sm-3 text-left"><label class="control-label">Description :</label></div>
                            <div class="col-sm-9 break-word"><p>{{serviceInfo.description}}</p></div>
                        </div>
                    </div>
                    <div class="col-sm-6  margin-bottom-5">
                        <div class="row">
                        </div>
                    </div>
                </div>
    
                <div class="row" *ngIf="serviceInfo.complaint_pic">
                    <div class="col-sm-6  margin-bottom-5">
                        <div class="row">
                            <div class="col-sm-6 text-left"><label class="control-label">Vehicle Picture :</label></div>
                            <div class="col-sm-6">
                            <div class="view-images">
                                <a href="javascript:void(0);" (click)="imageToolDialog($event,0,7,serviceInfo);$event.stopPropagation();" class="thumbnail">
                                    <img src="{{complaintImgThumbUrl}}{{serviceInfo.complaint_pic}}">
                                </a>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6  margin-bottom-5">
                        <div class="row">
    
                        </div>
                    </div>
                </div>
            </div>
            <div class="panel-heading expansion-pretend mat-elevation-z2 padding-0 margin-bottom-10">
                <div class="panel-title padding-10">COMMENTS SECTION</div>
                <span *ngIf="serviceInfoComments.length==0" class="display-full padding-15">No Comment Available</span>
            </div>
            <div class="display-full" *ngIf="serviceInfoComments.length > 0">
                <mat-accordion class="new-request-expension display-full" displayMode="flat" hideToggle="false" *ngFor="let serviceInfoComment of serviceInfoComments;">
                               <mat-expansion-panel hideToggle="false" expanded="true" class="break-word">
                        <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px">
                            <ul class="list-inline display-full margin-bottom-0">
                                <li>Added By: {{serviceInfoComment.first_name}}</li>
                                <li class="pull-right">Date: {{getProperDate(serviceInfoComment.add_date)}}</li>
                            </ul>
                        </mat-expansion-panel-header>
                        <span [innerHTML]="serviceInfoComment.description"></span>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
            <form [formGroup]="shopAddComment" (ngSubmit)="addComment()" novalidate id="addPartForm" class="form-inline" role="form" method="POST" *ngIf="serviceInfoStatus != 3">
                <div class="row add-comment-row">
                    <div class="form-group col-sm-12">
                        <label for="description">Add Comment</label>
                        <div class="relative details-input">
                            <mat-form-field class="car-input btn-block notes-input" floatPlaceholder="never" appearance="fill">
                                <textarea matInput  placeholder="Write Your Comment Here" title="Write Your Comment Here" minHeight="200" maxlength="1000"  formControlName="complaint_comment"  class=" input-lg custom-form-input"></textarea>
                                <mat-error *ngIf="shopAddComment.controls.complaint_comment.hasError('required')">
                                    Please add your <strong>Comment</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-12 checkbox-section">
                        <mat-checkbox class="btn-block" labelPosition="after" (change)="showTaxExempted($event)" formControlName="complaint_resolver" >
                            Mark Resolved
                        </mat-checkbox>
                    </div>
                </div>
                <div class="row">
                    <div class="display-full margin-top-5">
                        <div class="col-sm-6 col-lg-4">
                            <button type="button" mat-raised-button class="car-btn btn-block text-uppercase  btn-lg font-light fontcolor-white" id="saveClose" color="accent" title="Add Comment"  (click)="addComment()">ADD COMMENT</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    
</mat-dialog-content>