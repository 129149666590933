<div class="panel panel-primary" id="mainCompotents">
    <div class="panel-heading padding-left-15">
        <h3 class="panel-title">DISCLAIMERS LIST</h3>
        <button mat-mini-fab class="custom-btn-back" (click)="globalService.goBack();">
           <mat-icon>arrow_back</mat-icon>
        </button>
    </div>
    <div class="panel-body bgcolor-lightgrey">
        <div class="row">
            <div class="col-md-4 text-right"></div>
            <div class="col-md-4 text-right"></div>
            <div class="col-md-2 text-right"></div>
            <div class="col-md-2 text-right pull-right">
              <p><a [routerLink]="['/shops/new-disclaimer']" class="hover-link" title="Add Disclaimer"><i class="fa fa-plus fa-lg margin-right-5"></i>Add Disclaimer</a> </p>
            </div>
          </div>
        <div class="car-datatable uc-datatable mat-elevation-z1">
            <div class="table-responsive relative">
                <mat-table #table [dataSource]="dataSource" matSort class="datatable-loader-bg uc-table-responsive">

                    <!-- Color Column -->
                    <ng-container matColumnDef="title">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="title"> Title </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="title" data-val="Title"> {{row.title}} </mat-cell>
                    </ng-container>

                    <!-- Color Column -->
                    <ng-container matColumnDef="description">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Description </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto" data-val="Description" class="desc-cell fault-cell"> {{row.description}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="added_on">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="date"> Added on </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="date" data-val="Added on"> {{row.added_on}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Is Default">
                        <mat-header-cell *matHeaderCellDef data-role="button"> Is Default </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="button" data-val="Is Default">
                            <button class="change-status btn btn-xs btn-success" *ngIf="row.is_default == '1'" >Default <i class="fa fa-check-circle fa-lg"></i></button>
                            <button class="change-status btn btn-xs btn-default" *ngIf="row.is_default == '0' && isAllowed('edits')" (click)="setDefaultDisclaimer(row.disclaimer_id)">Set Default</button>
                            <span *ngIf="row.is_default == '0' && isAllowed('edits')==false" >-</span>
                        </mat-cell>
                    </ng-container>

                    <!-- Color Column -->
                    <ng-container matColumnDef="Actions">
                        <mat-header-cell *matHeaderCellDef  data-role="button"> Actions </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="button">
                            <button mat-button *ngIf="isAllowed('edits') || isAllowed('deletes')" class="list-action-btn" [matMenuTriggerFor]="menu" disableRipple >
                                Action<mat-icon>arrow_drop_down</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu" class="car-icon-menu" [overlapTrigger]="false">
                                <a mat-menu-item *ngIf="isAllowed('edits')" (click)="editDisclaimer(row.disclaimer_id)"><i class="fa fa-edit"></i>Edit</a>
                                <a mat-menu-item *ngIf="isAllowed('deletes')" (click)="deleteDisclaimer(row.disclaimer_id)"><i class="fa fa-trash"></i>Delete</a>
                            </mat-menu>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
                <div class="padding-15 text-center"
                     [style.display]="getTotalRecords() < 1 ? '' : 'none'">
                     No Records Available
            </div>
        </div>
        <div class="display-full relative" [style.display]="getTotalRecords() < 1 ? 'none' : ''">
            <mat-paginator class="car-pagination" #paginator [length]="getTotalRecords()" [pageIndex]="0" [pageSize]="options.limit" [pageSizeOptions]="[5, 10, 25, 100]" (page)="paginateByLimit($event)" >
            </mat-paginator>

            <div class="mat-paginator table-paging">
                <div class="mat-paginator-range-label margin-left-30 margin-right-30">{{options.start+1}} - {{getUpperLimit(options.start + options.limit,getTotalRecords())}} of {{getTotalRecords()}}</div>
                <button mat-icon-button (click)="paginate($event,'previous');" [disabled]="firstList()">
                        <mat-icon>navigate_before</mat-icon>
                </button>
                <button mat-icon-button (click)="paginate($event,'next');" [disabled]="lastList()">
                        <mat-icon>navigate_next</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>
</div>
