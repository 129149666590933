import {Component, OnInit,ChangeDetectorRef, ViewEncapsulation,OnDestroy } from '@angular/core';
import {AbstractControl, FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import {Router, ActivatedRoute, ParamMap, NavigationEnd} from '@angular/router';
import {Http, Headers, Response,RequestOptions} from '@angular/http';
import {MatDialog} from '@angular/material/dialog';
import {HttpHeaders} from '@angular/common/http';
import { globalFunctions } from "../../../environments/globalFunctions";
import { globalConstants } from "../../../environments/globalConstants";
import {GlobalService} from "../../../environments/global.service";
import { Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { lang } from "../../../environments/lang-en";
import { ImagedialogComponent } from '../../assetsComponent/imagedialog/imagedialog.component';
import {AppComponent} from '../../app.component';
import { PartagsComponent } from '../shopparts/partags/partags.component';
import { Observable,Subscription } from 'rxjs';


@Component({
  selector: 'shopAddPart-addnewpart',
  templateUrl: 'shopaddnewpart.component.html',
  styleUrls: ['./shopaddnewpart.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class ShopaddnewpartComponent implements OnInit, OnDestroy {
    public shopAddPart: FormGroup;
    public submitted=false;
    public currentCustomer;
    public currentCustomerName;
    public base64value = "";
    public selectedKey = "";
    public currentPartPicture='';
    public lowStockQty="Low Stock Quantity ";
    public isInventory=false;
    public allTags=[];
    public default_low_stock_value;
    public subscriptionDataForAPI: Subscription = new Subscription();
    constructor(private ref:ChangeDetectorRef,private formBuilder: FormBuilder, private route: ActivatedRoute, public titleService: Title, private http: Http, private router: Router, private globalService: GlobalService,public dialog: MatDialog) {
        if (localStorage.getItem("loggedInUser")) {
            this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
            this.currentCustomerName = this.currentCustomer.first_name + this.currentCustomer.last_Name;
            this.initialializeAddPartForm();
        } else {
            router.navigate(["/shops"])
        }
        setTimeout(()=>{
         let isEnable=this.globalService.getAcl('part','addNew');
          if(!(isEnable)){
            this.router.navigate(['shops/action/forbidden']);
          }
        },globalConstants.RELOAD_WAIT);
    }

    initialializeAddPartForm() {
        this.shopAddPart = this.formBuilder.group({
            part_name: ['', Validators.required],
            part_number: [''],
            cost: ['', [Validators.required, Validators.pattern(/^-?\d+(?:\.\d+)?$/)]],
            price: ['', [Validators.required, Validators.pattern(/^-?\d+(?:\.\d+)?$/)]],
            vendor: [''],
            manufacturer: [''],
            oem_part_number: [''],
            description: [''],
            part_pic: [''],
            non_taxable: [''],
            user_type: this.currentCustomer.user_type,
            user_id: this.currentCustomer.id,
            shop_id: this.currentCustomer.shop_id,
            part_picture:[''],
            part_qty:[0,Validators.pattern(/^-?\d+(?:\.\d+)?$/)],
            low_stock_qty:['',Validators.pattern(/^-?\d+(?:\.\d+)?$/)],
            part_tags:[],
            keep_vendor:[false],
            keep_manufacturer:[false],
            keep_part_name:[false],
            keep_part_no:[false],
            keep_oem_part_no:[false],
        });

        let partNumber=this.globalService.getSetItems('auto_part_number');
        if(partNumber){
            this.shopAddPart.controls.part_number.setValue(partNumber);
        }  
        
        this.prefillField();
        
    }

    prefillField(){
        let prefillVendor=this.globalService.getSetItems('prefill_vendor');
        if(prefillVendor){         
            this.shopAddPart.controls.keep_vendor.setValue(true);
            this.shopAddPart.controls.vendor.setValue(prefillVendor);
        }

        let prefillManufacturer=this.globalService.getSetItems('prefill_manufacturer');
        if(prefillManufacturer){
            this.shopAddPart.controls.keep_manufacturer.setValue(true);
            this.shopAddPart.controls.manufacturer.setValue(prefillManufacturer);
        }

        let prefillPartName=this.globalService.getSetItems('prefill_part_name');
        if(prefillPartName){         
            this.shopAddPart.controls.keep_part_name.setValue(true);
            this.shopAddPart.controls.part_name.setValue(prefillPartName);
        }

        let prefillPartNo=this.globalService.getSetItems('prefill_part_no');
        if(prefillPartNo){
            this.shopAddPart.controls.keep_part_no.setValue(true);
            this.shopAddPart.controls.part_number.setValue(prefillPartNo);
        }

        let prefillOEMPartNo=this.globalService.getSetItems('prefill_oem_part_no');
        if(prefillOEMPartNo){         
            this.shopAddPart.controls.keep_oem_part_no.setValue(true);
            this.shopAddPart.controls.oem_part_number.setValue(prefillOEMPartNo);
        }       
    }

    showTaxExempted(event) {
        if (event.checked) {
            this.shopAddPart.controls.non_taxable.setValue(1);
        } else {
            this.shopAddPart.controls.non_taxable.setValue(0);
        }
    }

    onlyDecimalNumberKey(event) {
        let charCode = (event.which) ? event.which : event.keyCode;
        if (charCode != 46 && charCode > 31
            && (charCode < 48 || charCode > 57))
            return false;
        return true;
    }

    disableKey(event) {
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if (event.ctrlKey==true && (event.which == '67' || event.which == '86' ||  String.fromCharCode(event.which).toLowerCase() == 's')) {
            console.log('thou. shalt. not. PASTE!');
            event.preventDefault();
        }
    }


    getFile(evt, key) {
        this.selectedKey = key;
        let validImage  = this.globalService.validateFiles('image', evt.target.files);
        if (validImage) {
            var reader = new FileReader();
            reader.onload = this._handleReaderLoaded.bind(this);
            reader.readAsDataURL(evt.target.files[0]);
        }
    }

    _handleReaderLoaded(readerEvt) {
        var binaryString = readerEvt.target.result;
        this.base64value = (binaryString);
        this.currentPartPicture=binaryString;
        this.ref.detectChanges();
        if (this.selectedKey == "part_pic") {
            this.shopAddPart.controls.part_picture.setValue(this.base64value);
        }
    }

    ngOnInit() {
        this.initialializeAddPartForm();
        this.globalService.setMetaData("SHOPS","NEW_PART");
        this.isInventory = this.globalService.isInventories();
        if (this.isInventory) {
            this.shopAddPart.get('part_qty').setValidators([Validators.required,Validators.pattern(/^-?\d+(?:\.\d+)?$/)]);
            this.shopAddPart.get('low_stock_qty').setValidators([Validators.required,Validators.pattern(/^-?\d+(?:\.\d+)?$/)]);

            let content = { shop_id: this.currentCustomer.shop_id };
            this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_SHOP_DEFAULT_LOW_STOCK_VALUE, content).subscribe((data:any) => {
                if (data.result == "1") {
                    this.default_low_stock_value = data.data.default_low_stock_value;
                    this.lowStockQty += data.data.default_low_stock_value;
                    this.shopAddPart.controls.low_stock_qty.setValue(this.default_low_stock_value);
                }
            }, (error) => { });
        }
        this.getAllTags();   
    }

    setButtonStatusAndSubmit(status) {
        this.AddPart(status)
    }

    AddPart(opr) {
        this.shopAddPart.get('part_number').setValidators([Validators.required]);
        Object.keys(this.shopAddPart.controls).map((controlName) => {
            this.shopAddPart.get(controlName).markAsTouched({onlySelf: true});
        });
        this.shopAddPart.get('part_number').updateValueAndValidity({ onlySelf: false, emitEvent: false });
       
        if(this.shopAddPart.valid) {
            this.shopAddPart.controls.user_type.setValue(this.currentCustomer.user_type);
            this.shopAddPart.controls.user_id.setValue(this.currentCustomer.id);
            this.shopAddPart.controls.shop_id.setValue(this.currentCustomer.shop_id);

            if (this.shopAddPart.value.vendor == null) {
                this.shopAddPart.controls.vendor.setValue('');
            }

            if (this.shopAddPart.value.manufacturer == null) {
                this.shopAddPart.controls.manufacturer.setValue('');
            }

            if (this.shopAddPart.value.oem_part_number == null) {
                this.shopAddPart.controls.oem_part_number.setValue('');
            }

            if (this.shopAddPart.value.description == null) {
                this.shopAddPart.controls.description.setValue('');
            }

            if (this.shopAddPart.value.part_picture == null) {
                this.shopAddPart.controls.part_picture.setValue('');
            }

            if(this.shopAddPart.value.keep_vendor==true && this.shopAddPart.value.vendor){    
                this.globalService.getSetItems('prefill_vendor',this.shopAddPart.value.vendor);   
            }else{
                sessionStorage.removeItem('prefill_vendor');
            }

            if(this.shopAddPart.value.keep_manufacturer==true && this.shopAddPart.value.manufacturer){              
                this.globalService.getSetItems('prefill_manufacturer',this.shopAddPart.value.manufacturer);   
            }else{
                sessionStorage.removeItem('prefill_manufacturer');
            }

            if(this.shopAddPart.value.keep_part_name==true && this.shopAddPart.value.part_name){    
                this.globalService.getSetItems('prefill_part_name',this.shopAddPart.value.part_name);   
            }else{
                sessionStorage.removeItem('prefill_part_name');
            }
            
            if(this.shopAddPart.value.keep_part_no==true && this.shopAddPart.value.part_number){              
                this.globalService.getSetItems('prefill_part_no',this.shopAddPart.value.part_number);   
            }else{
                sessionStorage.removeItem('prefill_part_no');
            }

            if(this.shopAddPart.value.keep_oem_part_no==true && this.shopAddPart.value.oem_part_number){    
                this.globalService.getSetItems('prefill_oem_part_no',this.shopAddPart.value.oem_part_number);   
            }else{
                sessionStorage.removeItem('prefill_oem_part_no');
            }
            
            

            var data = new FormData();
            for(let i in this.shopAddPart.value){
              data.append(i, this.shopAddPart.value[i]);
            }

            this.globalService.formData(globalConstants.API_ADD_PART_URL,data).subscribe((data:any)=> {
                var message = data.message;
                if (data.result == "1") {
                    localStorage.setItem("search_by","");
                    localStorage.setItem("sort_by","");
                    localStorage.setItem("sort_type","");
                    localStorage.setItem("search_keyword","");
                    localStorage.setItem("start","0");
                    localStorage.setItem("limit","10");
                    this.globalService.getSetItems('auto_part_number','');
                    if (opr == 1) {
                        this.router.navigate(["/shops/list-parts"]);
                        this.globalService.snackbar('success', message);
                    } else if (opr == 2) {
                        this.deletePartPic()
                        this.globalService.setScrolling("body", 500, 0);
                        this.globalService.snackbar('success', message);
                        this.shopAddPart.reset();
                        this.shopAddPart.markAllAsTouched();
                        this.shopAddPart.controls.low_stock_qty.setValue(this.default_low_stock_value);
                        this.shopAddPart.controls.part_qty.setValue(0);                        
                        this.prefillField();
                    }
                } else {
                    this.globalService.snackbar('error', message);
                }
            });
        } else {
            this.submitted = true;
            this.mandatoryMsg();
        }
    }

    mandatoryMsg() {
        this.globalService.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
    }

    deletePartPic(){
        this.currentPartPicture='';
        this.shopAddPart.controls.part_picture.setValue('');
        let ele=<HTMLInputElement>document.getElementById('partPic');
        ele.value='';
    }
    openImageDialog(catType){
      let imgfile,base64;
      imgfile="";
      base64=this.currentPartPicture;
      let dialogRef = this.dialog.open(ImagedialogComponent, {
          panelClass: ['image-dialog'],
          data: {img:imgfile, category:catType, base64Image:base64}

      });
      imgfile="";
      base64="";
    }
    manageTag(){
        let dialogRef = this.dialog.open(PartagsComponent, {
            panelClass: 'partstech-order-dialog',
            width: '800px',
            data: {}
        });
        dialogRef.afterClosed().subscribe(result => {
            this.getAllTags();
        });
    }
    getAllTags(){
        let data={user_id: this.currentCustomer.id};
        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_LIST_PART_TAGS,data).subscribe((res:any)=>{
            if (res.result == "1") {
                this.allTags=res.data;
            }
        },(error)=>{})
    }
    selectedTags(e){
        // console.log('add tags',e)
    }
    removeTags(e){
        // console.log('remove tags',e)
    }

    partNumber(opt) {
        if (typeof opt == "object") {
            this.shopAddPart.controls.part_number.setValue(opt.part_number);
        }else if(typeof opt!='undefined'){
            this.shopAddPart.controls.part_number.setValue(opt);
        }
        // this.checkIfValid();
    }

    observableSourcePN = (keyword: any): Observable<any[]> => {
        let PartSearchKeyword = keyword.replace(/#/g, "|");
        let url: string = globalConstants.API_GET_PART_NUMBERS + "?user_id=" + this.globalService.getCurrentUser()["id"]+ "&user_type=" + this.globalService.getCurrentUser()["user_type"] + "&query=" + PartSearchKeyword;
        if (keyword) {
            let f = [];
           return this.globalService.getAPI(url).map((res:any)=>{
                    let json = res
                    this.globalService.authText(json);
                    if (json != null) {
                        json.data.forEach(function (x) {
                            x.value = x.part_number;
                            f.push(x)
                        }
                        );
                    }
                    return f
                })
        } else {
            return Observable.of([]);
        }
    }

    setReturnFalse() {
        return false
    }

    myListFormatter(data: any): string {
        return data['part_number'];
    }

    convertIntoDecimal(event,frmControlName,beforeDecimalDigit){
        let fieldValue = this.shopAddPart.get(frmControlName).value
        let result= this.globalService.processDecimalValue(fieldValue,beforeDecimalDigit);
        this.shopAddPart.get(frmControlName).setValue(result);
        return globalFunctions.validateNumbersWithDecimal(event,3);
      }
    ngOnDestroy(){
        this.subscriptionDataForAPI.unsubscribe();
    } 
}
