<div mat-dialog-title class="relative" id="mainCompotents">
  Message
  <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
      <mat-icon class="notranslate close-icon-lg">close</mat-icon>
  </button>
</div>
<mat-dialog-content class="message-content">
  <div class="center-block">
      <form class="form-horizontal" autocomplete="off" [formGroup]="addMessage" enctype="multipart/form-data">

          <div class="display-full margin-bottom-5">
              <label class="col-sm-3 control-label"><strong>Title</strong></label>
              <div class="col-sm-9">
                  <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                      <input matInput type="text" placeholder="Title" formControlName="add_complaint_title" class="form-control custom-form-input" maxlength="100"/>
                      <mat-error *ngIf="addMessage.controls.add_complaint_title.hasError('required')">
                          This field cannot be empty
                      </mat-error>
                  </mat-form-field>
              </div>
          </div>

          <div class="display-full margin-bottom-5">
              <label class="col-sm-3 control-label"><strong>Description</strong></label>
              <div class="col-sm-9 details-input">
                  <mat-form-field class="car-input btn-block notes-input" floatPlaceholder="never" appearance="fill">
                      <textarea matInput placeholder="Description" formControlName="add_complaint_description" rows="2" #notes maxlength="250"></textarea>
                      <mat-hint align="end">{{notes.value?.length || 0}}/250 Characters Max</mat-hint>
                      <mat-error *ngIf="addMessage.controls.add_complaint_description.hasError('required')">
                          This field cannot be empty
                      </mat-error>
                  </mat-form-field>
              </div>
          </div>

          <div class="display-full margin-bottom-5">
              <label class="col-sm-3 control-label"><strong>Upload Image</strong></label>
              <div class="col-sm-6">
                  <input id="partPicImagedata" name="complaint_pic" value="" type="hidden" formControlName="complaint_pic">
                  <mat-toolbar class="mat-elevation-z2 margin-bottom-15 upload-btn relative">
                      <mat-icon class="margin-right-10">cloud_upload</mat-icon>Drag File Here
                      <input type="file" id="complaintPic" name="complaint_pic" accept="image/*" (change)="getFile($event,'complaint_pic')" formControlname="complaint_pic"/>
                      <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
                  </mat-toolbar>
                  <div class="col-sm-12 padding-0" >
                      <p class="help-block margin-0">Formats supported : png, jpeg, jpg</p>
                      <p class="help-block margin-0">Maximum size : 10MB</p>
                  </div>
              </div>

              <div id="businessLogoView" class="col-sm-3">
                  <ul class="list-inline upload-image margin-top-10" *ngIf="currentComplaintPicture!=''">
                      <li>
                          <a href="javascript:void(0);" class="img-set thumbnail relative">
                              <img class="show-image" src="{{currentComplaintPicture}}">
                              <i class="fa fa-trash remove-img" (click)="deleteComplaintPic()"></i>
                          </a>
                      </li>
                  </ul>
              </div>
          </div>

          <div class="display-full margin-bottom-5">
              <label class="col-sm-3 control-label"><strong>&nbsp;</strong></label>
              <div class="col-sm-9">
                  <button mat-raised-button (click)="addComplaint()" class="font-bold" color="accent" title="Submit">Submit</button>
              </div>
          </div>

      </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="inquiry-action relative">
  <button mat-raised-button mat-dialog-close class="font-bold" title="Close">Close</button>
</mat-dialog-actions>
