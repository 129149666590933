import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewEncapsulation, Inject,ChangeDetectorRef ,ChangeDetectionStrategy, ElementRef, Input, Output, ViewChild, EventEmitter, Optional, OnDestroy} from '@angular/core';
import {Router, ActivatedRoute, ParamMap, NavigationEnd, Params} from '@angular/router';
import { globalFunctions } from '../../../../environments/globalFunctions';
import { globalConstants } from '../../../../environments/globalConstants';
import { GlobalService, ModalInfo } from '../../../../environments/global.service';
import { Observable } from 'rxjs/Observable';
import { Subscriber } from 'rxjs/Subscriber';
import 'rxjs/add/operator/startWith';
import 'rxjs/add/operator/map';
// import { VehicleInspectionReport } from './vehicle-inspection-report.component'; //remove it when re-visit the code
import { VehicleInspectionReportComponent} from '../vehicle-inspection-report/vehicle-inspection-report.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatListModule} from '@angular/material/list'; 
import {NativeDateAdapter } from '@angular/material/core';
import { locales } from 'moment';
import {Http, Headers, Response,RequestOptions, ResponseContentType} from '@angular/http';
import {IDatePickerConfig} from 'ng2-date-picker';
import {DatePipe} from '@angular/common';
import {VideoShowComponent} from '../../../customer/my-vehicles/video-show/video-show.component';
import { ImagedialogComponent } from '../../../assetsComponent/imagedialog/imagedialog.component';
import 'rxjs/add/observable/of';
//import * as $ from 'jquery';
import {PartstechComponent} from '../../../partstech/partstech.component';
import  saveAs  from 'file-saver';
import {AppComponent} from "../../../app.component";
import {EditpartPopupComponent} from "../../shopeditpart/editpart-popup/editpart-popup.component";
import { ImagetooldialogComponent } from '../../../imagetooldialog/imagetooldialog.component';
import { NotifyjobComponent } from './../notifyjob/notifyjob.component';
import {trigger, state, style, animate, transition, group} from '@angular/animations';
import {changeDisclaimer} from '../view-servicerequest/view-servicerequest.component';
import { DetailTabComponent } from '../detail-tab/detail-tab.component';
import { NguiAutoCompleteModule, NguiAutoCompleteComponent, NguiAutoComplete } from '@ngui/auto-complete';
import { PartagsComponent } from '../../shopparts/partags/partags.component';
import { MilesoutComponent } from '../milesout/milesout.component';
import { StockorderpopupComponent } from '../../shopparts/stockorderpopup/stockorderpopup.component';
import { ReturnqueComponent } from '../../shopparts/returnque/returnque.component';
import { TechassignmentComponent } from '../techassignment/techassignment.component';
import { PartsuggestionComponent } from '../partsuggestion/partsuggestion.component';
import { EditServiceRequestFieldsComponent } from '../edit-servicerequest-fields/edit-servicerequest-fields.component';
import { RecommpasteComponent } from '../recommpaste/recommpaste.component';
import { SrnotelistComponent } from '../../srnote/srnotelist/srnotelist.component';
import { MessagelistComponent } from '../../shopservicecomplaints/messagelist/messagelist.component';
import { QqviewComponent } from '../../quickquote/qqview/qqview.component';
import { EmissionsInspectionComponent } from '../emissions-inspection/emissions-inspection.component';
import { RedeemCouponComponent } from '../../../redeem-coupon/redeem-coupon.component';
import {​ ServiceHistoryComponent }​ from '../../../shop/shopservicerequest/service-history/service-history.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { JobApprovalDialogComponent } from '../job-approval-dialog/job-approval-dialog.component';
import { RequireApprovalDialogComponent } from "../require-approval-dialog/require-approval-dialog.component";
import { AddLabelDialogComponent } from '../add-label-dialog/add-label-dialog.component';
import * as moment from 'moment';
import { AnyCnameRecord } from 'dns';
import { UspsVehicleInspectionReportComponent} from '../usps-vehicle-inspection-report/usps-vehicle-inspection-report.component';
import { JobMatchDialogComponent } from '../job-match-dialog/job-match-dialog.component';
import { ChangeCustomerVehicleComponent } from '../change-customer-vehicle/change-customer-vehicle.component';
import { QuickactionComponent } from '../../quickaction/quickaction.component'

export class AppDateAdapter extends NativeDateAdapter {
  parse(value: any): Date | null {
   if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
     const str = value.split('/');
     const year = Number(str[2]);
     const month = Number(str[1]) - 1;
     const date = Number(str[0]);
     return new Date(year, month, date);
   }
   const timestamp = typeof value === 'number' ? value : Date.parse(value);
   return isNaN(timestamp) ? null : new Date(timestamp);
 }

  format(date: Date, displayFormat: Object): string {
      if (displayFormat == "input") {
          let day = date.getDate();
          let month = date.getMonth() + 1;
          let year = date.getFullYear();
          return this._to2digit(month)+ '/' + this._to2digit(day)  + '/' + year;
      } else {
          return date.toDateString();
      }
  }

  private _to2digit(n: number) {
      return ('00' + n).slice(-2);
  }
}

export const APP_DATE_FORMATS =
{
  parse: {
      dateInput: {month: 'short', year: 'numeric', day: 'numeric'}
  },
  display: {
      // dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
      dateInput: 'input',
      monthYearLabel: { month: 'short', year: 'numeric', day: 'numeric' },
      dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
      monthYearA11yLabel: {year: 'numeric', month: 'long'},
  }
}
declare var jQuery:any;
declare var $ :any;
@Component({
  selector: 'app-fill-recommendation',
  templateUrl: './fill-recommendation.component.html',
  styleUrls: ['./fill-recommendation.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe],
  animations: [
  trigger('slideInOut',[
    state('in', style({ 'max-height': '1500px', overflow: 'hidden' })),
    state('out', style({ 'max-height': '0px', overflow: 'hidden' })),
    transition('in => out', [group([
      animate('700ms ease-in-out', style({ 'max-height': '0px', overflow: 'hidden'})),
    ])]),
    transition('out => in', [group([
      animate('700ms ease-in-out', style({ 'max-height': '1500px', overflow: 'hidden'})),
    ])]),
   ])
  ]

})
export class FillRecommendationComponent implements OnInit,OnDestroy {
  
  @ViewChild('fillRecommendationCoupon') fillRecommendationCoupon: RedeemCouponComponent;
  @ViewChild('onTheLotCheckbox') onTheLotCheckbox: ElementRef;
  @ViewChild('waitingForPartsCheckbox') waitingForPartsCheckbox: ElementRef;
  @ViewChild(QuickactionComponent, { static: false }) quickaction: QuickactionComponent;
  
  isFromCoupon:boolean;
  couponOpr:number = 1;
  couponRedeemConditions:Array<boolean> = [];
  
  public serviceRequestId: number;
  public reload_count:number;
  public serviceInfo: any;
  public serviceRequestList: any;
  public price_set: number;
  public uploaded_video:any;
  public uploaded_video_id:number;
  public uploaded_video_name:any;
  public allLabels = [];
  public preSelectLabel=[];
  upload_v = [];
  public showServiceDetail: boolean = false;
  serviceDetail:any;
  myControl: FormControl = new FormControl();
  ComputerCodesControl: FormControl = new FormControl();
  genericControl: FormControl = new FormControl();
  filteredOptions: Observable<string[]>;
  genericFilterOptions: Observable<string[]>;
  ComputerCodesFilterOptions: Observable<string[]>;
  ComputerCodesOptions = [];
  selectedNewComputerCodes = []
  partsOptions = [];
  genericOptions = [];
  PartsPrice = "";
  PartsQuantity = "";
  newPartLabel = "";
  selectedParts = [];
  addedNewParts = [];
  addNewGenercJob = [];
  newPart = "";
  reports = {};
  tempReports = {};
  tempLevel2Ary = {};
  viewingJSON = {};
  viewingJSONParts = [];
  serviceRequest: FormGroup;
  base64Image = "";
  name: string;
  public allGenericJobs;
  public VehicleInspectionReport = [];
  public reportDownloadLink = '';
  public PartsDropDownModel = {};
  public genericJobsDropDownModel = {};
  public computerCodesDropdown = {};
  public bucket_url = globalConstants.S3_BUCKET_URL;
  public uploadImage = {
    user_id:"",
    level_1_id:"",
    level_2_id:"",
    level_3_id:"",
    request_id:"",
    recommendation_pic:""
  };
  public subtotal=0.00;
  public calcObj = {};
  public totalTaxedValue;
  public grandTotal;
  public totalNonTaxableValue;
  public vehicle_pic:any;
  public vin_photo:any;
  public nonTaxabaleArray = [];
  public datePickerConfig: IDatePickerConfig = {
    format: "MM/DD/YYYY hh:mm A",
  };
  public globalConstants=globalConstants;
  public partechSession={};
  public nexpartSession={};
  public showPartsTechButon=false;
  public showNexpartButton=false;
  public saveRecommendationConfirm:any;

  public confirmText:string;
  public confirmPopup:boolean=false;
  public child:any;
  public level_id:number;
  public job_id:number; 
  public imgTypes=globalConstants.FORMAT_SUPPORTED_FOR_IMAGE;
  public videoTypes=globalConstants.FORMAT_SUPPORTED_FOR_VIDEO;
  public imgMaxAllowedSize=globalConstants.MAX_ALLOWED_IMAGE_DISPLAYED_TEXT;
  public videoMaxAllowedSize=globalConstants.MAX_ALLOWED_VIDEO_DISPLAYED_TEXT;
  public editingPart = {
    part_id    : "",
    part_name  : "",
    part_price : "",
    part_qty   : "",
    type       : "",
    index      : ""
  }
  public showUpdateBtn = {
    part_id:"",
    id:"",
    show:false
  };
  public initFormValue=true;
  public requestImgPath=globalConstants.S3_BUCKET_URL+globalConstants.SERVICE_REQUEST_PICTURES+globalConstants.IMG_THUMB;
  public docPath=globalConstants.S3_BUCKET_URL+globalConstants.SERVICE_REQUEST_DOC;
  public requestImages=[];
  public requestDoc=[];
  public techinianStatusDone=false;
  public vinTimeStamp = new Date().getTime();
  public vehicleTimeStamp = new Date().getTime();
  public addToreturnQue=[];
  animationState = 'out';
  public isSubscribed=true;
  public isNexpartSubscribed=true;
  public taskTrack=true;
  public userTaskTrack:any=0;
  public isEmployee=false;
  public isInventory=false;
  public addTostockOrder=false;
  public snackBarSave=false;
  public showCostPrice=false;
  public partstechLoadCount=1;
  public nexpartLoadCount=1;
  public currentUser:any=null;
  public statuses = ['Appointment', 'Pending', 'Processing', 'Approval Awaited', '', 'Service Completed', 'Completed', 'Approval Completed', 'Completed And Paid', 'Unread Rating', 'Non-CAR','','Work In Progress'];
  public partMatrixStatus=false;
  public isLaborTax=false;
  public newJobs:any=[];
  private identifixShop=[1,3,12,75,153,201,4];
  private repairlinkShop=[1,3,12,13,75,76,82,124,4,5,12,43,46,82,130,172,332,109,619];
  isRepairLinkAllowed:boolean = false;
  public isServiceNote=false
  public qqDetail:any;
  public refreshList:any=null
  public sendIO:any=false
  public levelPriceObj = {}
  public hidePrices=true;
  bsModalRef: BsModalRef;
  
  vendorsData:Array<any>;
  filteredVendorsData:Array<any>;
  selectedVendor:any;
  vendorNameVisibility:boolean;
  indx:any;

  logoBaseUrl:string = globalConstants.S3_BUCKET_URL+globalConstants.NEXPART_LOGO;
  nexpartLogoURL:string = globalConstants.NEXPART_OFFICIAL_LOGO;
  defaultLogo:string = globalConstants.NEXLINK_DEFAULT_VENDOR_LOGO;
  nexpartOrderPermission:boolean;
  customerUpdateFormData:FormData;
  private accessTimestamp:any;
  private approvalDiagParams:any;
  public supplyCharges:any;
  myFiles:string [] = [];
  public techData:any = {};
  private isSupplyUpdated = false;
  private isTaxUpdated = false;
  public balance = 0;
  public totalCCfeeValue = 0;
  public netPayable = 0;
  private isPartstechOrderView = false;
  private isNextPartOrderView = false;
  isAddInPayment: boolean = false;
  public businessMetricReport:any = {};
  public profitPermission = false;
  public labourRatesType :any = [];
  public isNextPartStatus : any = false;
  public noJobsAddedMsg : boolean = false;
  public carRequestId:any;
  public showLabelsDropdown: boolean;
    public customLabels = [
      {
      "id": "OTL",
      "shop_id": "",
      "label_name": "OTL",
      "add_date": "",
      "modify_date": ""
     },
     {
      "id": "WAITING_FOR_PARTS",
      "shop_id": "",
      "label_name": "Waiting For Parts",
      "add_date": "",
      "modify_date": ""
     },
  ]; //Revisit add this tempSRLabels in global constant file
  public PTLaborSettingTypeDOW = 0;
  public PTLaborPrefixSetting = 0;
  public PTLaborSettingTypeTooltip = 0;
  public editPartInfo :any;
  public holdJobDataFromPaste:any;
  public findJobAndCreateStatus: boolean = false; // Job doesn't exist in SR, Add the selected job on reinit.
  public jobOpenIndex :any = 0;
  public controlledReinitOnJobAdd: any = false;
  public stockOrderBtnId: any  = null;
  public serviceRequestNotes :any;
  public msgForSnackBar: any = "";
  public requestCreationType : any ;
  public skipTicketAssign=false;
  public invokeInspectionReportPopup: any ={
    openPopup: false,
    note: '',
    formData:''
  }
  public isReportGenerate=false;

  constructor(private app: AppComponent,public cdr: ChangeDetectorRef,private router: Router,private datePipe: DatePipe,public http:Http,public globalService: GlobalService, private activatedRoute: ActivatedRoute, public formBuilder: FormBuilder, public dialog: MatDialog, private modalService: BsModalService) {
    this.getLabourType();
    setTimeout(()=>{
    let isEnable=this.globalService.isAllowed(this.app,'fill_recommendation','addNew');
     if(!isEnable){
      this.router.navigate(['shops/action/forbidden']);
     }
      this.addTostockOrder=this.globalService.getAcl('stock_order','addNew'); 
      this.showCostPrice=this.globalService.getAcl('display_part_cost','views'); 
      this.nexpartOrderPermission = this.globalService.getAcl('nexpart_order','views');
      this.isRepairLinkAllowed = this.globalService.getShopDetails('repairlink') == 1;
    },globalConstants.RELOAD_WAIT);
    this.globalService.setMetaData("SHOPS","SERVICE_REQUEST_DETAIL");
    this.isEmployee=this.globalService.isSubscribe('employee');
    this.isInventory = this.globalService.isInventories();
    this.isReportGenerate=this.globalService.isUserReportOn();
    this.setParams();
    this.globalService.updateIO.next(false);
    this.refreshList=this.globalService.updateIO.subscribe((r)=>{
      if(r){
        this.closeOrfresh(r);
      }
    })
   }

  /* animation */
  toggleShowDiv() {
    this.animationState = this.animationState === 'out' ? 'in' : 'out';
  }
  isStockEmpty(val){
    // let sq=parseFloat(val.stock_quantity);
    // let pq=parseFloat(val.part_qty);
    if(val.out_of_stock=='1'){
      return true;
    }else if(val.out_of_stock=='0'){
      return false;
    }
    return false;
  }

  /* STATUS COLOR */
  getStatusColor() {      
    let status:any = this.serviceInfo.is_appointment== '1' && (this.serviceInfo.request_status=='1' || this.serviceInfo.request_status=='2') ? 0: this.serviceInfo.request_status ;
    if(status != -1){
        var statusArr = ['customer-appt', 'warning-cell', 'info-cell', 'awaited-cell', "",  'success-cell', 'success-completed', 'success-cell', 'success-paid', "", "", "", 'work-progress-cell'];
        return statusArr[status];
    }
    else{
        return 'deleted-cell';
    }
  }

  editDailog(){
    let dialogRef = this.dialog.open(EditServiceRequestFieldsComponent, {
      panelClass: 'car-dialog-form',
      width: "80vw",
      data: {serviceInfo:this.serviceInfo}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(dialogRef["response"] instanceof Object){
        this.serviceRequest.controls.title.setValue(dialogRef["response"].request_title);
        this.serviceInfo.title = dialogRef["response"].request_title;
        this.serviceRequest.controls.mileage.setValue(dialogRef["response"].distance_covered);
        this.serviceInfo.distance_covered = dialogRef["response"].distance_covered;
        this.serviceInfo.notes = dialogRef["response"].request_notes;
        this.serviceRequest.controls.requested_date.setValue(this.globalService.getFullFormateDate(dialogRef["response"].requested_delivery_date ,'G','H'))
        this.serviceInfo.requested_delivery_date = this.serviceRequest.value.requested_date;
        this.serviceInfo.est_hours =  dialogRef["response"].est_service_time;
        this.serviceInfo.present_mileage= dialogRef["response"].distance_covered;
        this.serviceRequest.controls.access_timestamp.setValue(dialogRef["response"].access_timestamp);
        this.accessTimestamp = dialogRef["response"].access_timestamp;
      }      
    });
  }

  editPart(index,lastestChild,recommendation,type){
    var existingPartObject = this.viewingJSON[recommendation.id][type][index];
    this.editPartInfo = {
      listType: type,
      index: index
    };
    var ElementOfQty   =  $("#new-part-" + lastestChild.id + "-qty");
    var ElementOfPrice =  $("#new-part-" + lastestChild.id + "-price");
    var ElementOfAc    =  $("#new-part-" + lastestChild.id + "-ac");
    if(type == "AP"){
      this.viewingJSON[recommendation.id]["UNP"].forEach(function(x){ x.is_disabled = true })
      this.viewingJSON[recommendation.id]["UAP"].forEach(function(x) { x.is_disabled = true })
      this.viewingJSON[recommendation.id]["UAP"][index].is_editable = true;
      this.viewingJSON[recommendation.id]["UAP"][index].is_disabled = false;
      // console.log(this.viewingJSON[recommendation.id]["UAP"][index]);
      ElementOfAc.attr("disabled","disabled");
      ElementOfAc.val(this.viewingJSON[recommendation.id]["UAP"][index].part_name);
      ElementOfQty.click();
      ElementOfQty.val(this.viewingJSON[recommendation.id]["UAP"][index].part_qty);
      ElementOfPrice.click();
      ElementOfPrice.val(this.viewingJSON[recommendation.id]["UAP"][index].part_price);
      $("#new-part-"+lastestChild.id+"-update-btn").attr("data-part-id",this.viewingJSON[recommendation.id]["UAP"][index].id)
      $("#new-part-"+lastestChild.id+"-update-btn").attr("data-part-type","AP");
      $("#edit-part-"+lastestChild.id+"-edit-btn").attr("data-part-id",this.viewingJSON[recommendation.id]["UAP"][index].id);
      $("#edit-part-"+lastestChild.id+"-edit-btn").attr("data-part-type","AP");
    } else if(type == "NP") {
      this.viewingJSON[recommendation.id]["UAP"].forEach(function(x){ x.is_disabled = true })
      this.viewingJSON[recommendation.id]["UNP"].forEach(function(x) { x.is_disabled = true })
      this.viewingJSON[recommendation.id]["UNP"][index].is_editable = true;
      this.viewingJSON[recommendation.id]["UNP"][index].is_disabled = false;
      // console.log(this.viewingJSON[recommendation.id]["UNP"][index]);
      ElementOfAc.attr("disabled","disabled")
      ElementOfAc.val(this.viewingJSON[recommendation.id]["UNP"][index].part_name);
      ElementOfQty.click();
      ElementOfQty.val(this.viewingJSON[recommendation.id]["UNP"][index].part_qty);
      ElementOfPrice.click();
      ElementOfPrice.val(this.viewingJSON[recommendation.id]["UNP"][index].part_price);
      $("#new-part-"+lastestChild.id+"-update-btn").attr("data-part-id",this.viewingJSON[recommendation.id]["UNP"][index].id)
      $("#new-part-"+lastestChild.id+"-update-btn").attr("data-part-type","NP");
      $("#edit-part-"+lastestChild.id+"-edit-btn").attr("data-part-id",this.viewingJSON[recommendation.id]["UNP"][index].id);
      $("#edit-part-"+lastestChild.id+"-edit-btn").attr("data-part-type","NP");
    }
    // console.log(lastestChild.id)
    $("#new-part-"+lastestChild.id+"-update-btn").removeClass("hidden");
    $("#edit-part-"+lastestChild.id+"-edit-btn").removeClass("hidden");
    $("#new-part-"+lastestChild.id+"-add-btn").addClass("hidden");
    $("#new-part-"+lastestChild.id+"-add-new-btn").addClass("hidden");
    $("#new-part-"+lastestChild.id+"-partech-new-btn").attr("disabled",true);//.addClass("hidden");
    $("#new-part-"+lastestChild.id+"-nexpart-new-btn").attr("disabled",true);//.addClass("hidden");

  }

  UpdateNewPart(event, lastestChild, recommendation, index) {
    var ElementOfQty   =  $("#new-part-" + lastestChild.id + "-qty");
    var ElementOfPrice =  $("#new-part-" + lastestChild.id + "-price");
    var ElementOfAc    =  $("#new-part-" + lastestChild.id + "-ac");

    var part_id = event.target.attributes[3].value;
    var part_type = event.target.attributes[4].value;
    let updatedQty=ElementOfQty.val();
    let updatePrice=ElementOfPrice.val();
  if((parseFloat(updatedQty)>0) && (isNaN(parseFloat(updatedQty))!= true) && (parseFloat(updatePrice)>=0) && (isNaN(parseFloat(updatePrice))!= true)){

    if(part_type == "AP") {
      var existing = this.viewingJSON[recommendation.id]["UAP"].find(function(x){ return parseInt(x.id) == parseInt(part_id) });
      var inViewingJSON = this.viewingJSON[recommendation.id]["AP"].find(function(x){{ return parseInt(x.id) == parseInt(part_id) }});
    } else if(part_type == "NP") {
      var existing = this.viewingJSON[recommendation.id]["UNP"].find(function(x){ return parseInt(x.id) == parseInt(part_id) });
      var inViewingJSON = this.viewingJSON[recommendation.id]["NP"].find(function(x){{ return parseInt(x.id) == parseInt(part_id) }});
    }
    this.viewingJSON[recommendation.id]["UAP"].forEach(function(x){ x.is_disabled = false })
    this.viewingJSON[recommendation.id]["UNP"].forEach(function(x) { x.is_disabled = false })
    ElementOfAc.attr("disabled",false);
    let qty:any=Math.round(updatedQty);
    if(inViewingJSON.partstech==1 || inViewingJSON.nexpart==1){

      existing.part_qty = (qty>0?qty:1);
      inViewingJSON.part_qty = (qty>0?qty:1);
    }else{
      if(updatedQty.indexOf('.')!=-1){
        qty=parseFloat(updatedQty).toString();
        if(qty.indexOf('.')!=-1){
          qty=qty.substr(0,qty.indexOf('.')) + qty.substr(qty.indexOf('.'), 4);
        }
      }
      existing.part_qty = qty;
      inViewingJSON.part_qty = qty;
    }
    existing.is_editable = false;
    let price=parseFloat(updatePrice).toFixed(2);
    existing.part_price = price;
    inViewingJSON.part_price = price;
    existing.is_editable = false;
    ElementOfQty.val("")
    ElementOfPrice.val("")
    ElementOfAc.val("");
    $("#new-part-"+lastestChild.id+"-update-btn").addClass("hidden");
    $("#edit-part-"+lastestChild.id+"-edit-btn").addClass("hidden");
    $("#new-part-"+lastestChild.id+"-add-btn").removeClass("hidden");
    $("#new-part-"+lastestChild.id+"-add-new-btn").removeClass("hidden");
    $("#new-part-"+lastestChild.id+"-partech-new-btn").attr("disabled",false);//removeClass("hidden");
    $("#new-part-"+lastestChild.id+"-nexpart-new-btn").attr("disabled",false);//removeClass("hidden");

    this.makeCalculations();
    this.globalService.snackbar('error', "Save Recommendation to update the changes.",{ component:this,method :'postSaveAndStay',param:0});
  }else if((parseFloat(updatePrice)<0) || (isNaN(parseFloat(updatePrice))== true)){
    this.globalService.snackbar('error', "Invalid Price");
  } else if((isNaN(parseFloat(updatedQty)) == true) && (updatedQty!='')) {
    this.globalService.snackbar('error', "Invalid quantity")
  } else if((parseFloat(updatedQty)<=0) || (isNaN(parseFloat(updatedQty))== true)){
    this.globalService.snackbar('error', "Quantity should be greater than 0");
  }
    // console.log(existing);
  }

  CancelEditPart(index,lastestChild,recommendation,type){
    var ElementOfQty   =  $("#new-part-" + lastestChild.id + "-qty");
    var ElementOfPrice =  $("#new-part-" + lastestChild.id + "-price");
    var ElementOfAc    =  $("#new-part-" + lastestChild.id + "-ac");    ElementOfAc.attr("disabled",false);
    $("#new-part-"+lastestChild.id+"-update-btn").addClass("hidden");
    $("#edit-part-"+lastestChild.id+"-edit-btn").addClass("hidden");
    $("#new-part-"+lastestChild.id+"-add-btn").removeClass("hidden");
    $("#new-part-"+lastestChild.id+"-add-new-btn").removeClass("hidden");
    $("#new-part-"+lastestChild.id+"-partech-new-btn").attr("disabled",false);//removeClass("hidden");
    $("#new-part-"+lastestChild.id+"-nexpart-new-btn").attr("disabled",false);//removeClass("hidden");


     if(type == "AP"){
      this.viewingJSON[recommendation.id]["UAP"][index].is_editable = false;
      // console.log(this.viewingJSON[recommendation.id]["UAP"][index])
    } else if( type == "NP") {
      this.viewingJSON[recommendation.id]["UNP"][index].is_editable = false;
      // console.log(this.viewingJSON[recommendation.id]["UNP"][index])
    }
    this.viewingJSON[recommendation.id]["UAP"].forEach(function(x){ x.is_disabled = false })
    this.viewingJSON[recommendation.id]["UNP"].forEach(function(x) { x.is_disabled = false })
    ElementOfQty.val("")
    ElementOfPrice.val("")
    ElementOfAc.val("");
  }
  initFormBuiler() {
    this.serviceRequest = this.formBuilder.group({
      access_timestamp:[''],
      request_id: [""],
      shop_name: [""],
      vehicle: [""],
      license_plate: [""],
      title: [""],
      vin_number: [""],
      customer_name: [""],
      requested_date: [""],
      mobile_number: [""],
      scheduled_date: [""],
      mileage: [""],
      needed_by: [""],
      scheduledServiceDate: [''],
      estimated_delivery_date:[''],
      estimatedDeliveryDate:[''],
      inspectionReportUPSPValue: [''],
      inspectionReportACDELCOValue: [''],
      inspectionReportType: [''],
      report_1_2_parts_price: [''],
      report_1_2_parts_quantity: [''],
      report_1_7_8_parts_1_price: [''],
      report_1_7_8_parts_1_quantity: [''],
      is_supply_charges_included: [''],
      is_tax_included:[''],
      is_labor_tax_excluded:[''],
      requestCreationType: [''],
      user_type: [],
      user_id: [],
      service_request_id: [''],
      service_request_list: [''],
      supplies_charges:"",
      grand_total: [''],
      tax_rate: [''],
      tax_amount: [''],
      customer_type: [''],
      labor_rate: [''],
      supply_charges: [''],
      non_taxable_amount: [''],
      requestedDeliveryDate: [''],
      report: [''],
      from_v2: ["1"],
      usps_vehicle:"",
      recommendation_saved:"",
      customer_id:"",
      car_id:"",
      id:"",
      no_report_performed:"",
      is_supplies_charges_included:"",
      notify_to_customer:"",
      first_name:"",
      last_name:"",
      insurance_expiration_date:[''],
      registration_expiration_date:[''],
      is_auto_order:[''],
      return_queue : [''],
      on_the_lot : [false],
      parts_awaited:[false],
      cc_fee_included:['0'],  
    });
    // this.getGenericJobs()
    this.globalService.hideTooltipImage();
  }
  openImageDialog(catType){
        let imgfile;
      if(catType == 1){
        imgfile= this.vehicle_pic;
      }else{
        imgfile= this.vin_photo;
      }
      let dialogRef = this.dialog.open(ImagedialogComponent, {
          panelClass: ['image-dialog'],
          data: {img:imgfile, category:catType}

      });
      imgfile="";
  }
  checkVehicleReportFlag(event) {
    if(event.checked) {
      this.serviceRequest.controls.no_report_performed.setValue(true)
    } else {
      this.serviceRequest.controls.no_report_performed.setValue(false)
    }
  }
  getNltoBr(val){
    if(val && val.length>0){
      let notes = val.toString().replace(/\r\n/g, "<br />").replace(/\n/g, "<br />");
      return notes;
    }
    return '-';
  }
  
  selectVendor(l1,l2,l3, recom, vendor:any){
    this.selectedVendor = vendor;
    this.nexpart(l1, l2, l3, recom);
  }
  
  getVendors(){
    let data={
      user_type: this.currentUser.user_type,
      user_id: this.currentUser.id,
      opr:2
    }
    this.globalService.callAPI(globalConstants.API_GET_NEXLINK_VENDORS_URL , data)
    .subscribe((response:any)=>{
      if(response.result == 1){
        this.vendorsData = response.data;
        if(this.vendorsData.length == 1){
          this.selectedVendor = this.vendorsData[0];
        }
      }
      else{
        this.vendorsData = [];
      }
      this.vendorsData.forEach((vendor)=>{
        vendor.label = vendor.name;
      });
      // this.filteredVendorsData = this.vendorsData.slice(0,100);
    });
  }
  
  getVendorNameSetting(){
    this.vendorNameVisibility = (this.globalService.getShopDetails('nexlink_vendor_name_display') == 1) ? true : false;
  }
  
  ngOnInit() {
    this.profitPermission = this.globalService.getAcl('display_part_cost','views');
    this.confirmPopup = false;  
    this.setCouponRedeemConditions(); //not in use
    // this.setParams(); //revisit
    this.getVendorNameSetting(); //one time use
    if(this.initFormValue){
      this.initFormBuiler(); //one time use
    }
    this.isServiceNote= this.globalService.isSubscribe('service_notes');
    this.currentUser = this.globalService.getCurrentUser();
    this.isSubscribed=this.globalService.isPartstechAllowed();
    this.isNexpartSubscribed=this.globalService.isNexpartAllowed();
    this.getVendors(); //one time use
    this.getSRData();
    this.globalService.receiveDirectPasteResponse.subscribe((res:any)=>{
      if(typeof(res) == "object"){
         this.addPreRecommToJobs(res.suggestion,res.jobData);
      }
    })
    this.getTicketAssignSetting();
  }

  getSRData(){
    this.getMetrixReport();
    let content = "user_id=" + this.globalService.getCurrentUser()["id"] + "&user_type=" + this.globalService.getCurrentUser()["user_type"] + "&service_request_id=" + this.serviceRequestId;
    this.globalService.callAPI(globalConstants.API_GET_SERVICE_REQUEST_VIEW_URL, content).subscribe((response:any) => {
      if (response.result == 1) {
        this.serviceInfo = response.data;
        this.carRequestId = response.data.request_id;
        this.accessTimestamp = response.data.access_timestamp;
        this.partMatrixStatus=(this.serviceInfo.part_matrix_status==1 ? true : false);
        if(this.serviceInfo.request_status==6 || this.serviceInfo.request_status==8 || this.serviceInfo.request_status==12){
          this.router.navigate(['shops/list-service-requests']);
        }
        let partsTechOrder=this.globalService.getAcl('partstech_order','addNew');
        if(this.serviceInfo.is_partstech_user=="1" && partsTechOrder && this.isSubscribed){
          this.showPartsTechButon=true;
        }
        let nexpartOrder=this.globalService.getAcl('nexpart_order','addNew');
        this.showNexpartButton=(this.serviceInfo.is_nexlink_user=="1" && nexpartOrder && this.isNexpartSubscribed);
        if(this.serviceInfo.is_report_generation==1){
          this.taskTrack=true;
        }

        if(this.serviceInfo.is_labor_tax_excluded==1){
          this.isLaborTax=true;
        }
        if(this.isAllowed('views','quick_quote') && typeof this.serviceInfo.quote_detail!='undefined' && typeof this.serviceInfo.quote_detail.quote_id!='undefined'){
          this.qqDetail=this.serviceInfo.quote_detail
        }
        if(this.serviceInfo.user_work_tracker=="1" || this.serviceInfo.user_work_tracker=="2" || this.serviceInfo.user_work_tracker=="3" || this.serviceInfo.user_work_tracker=="0"){
          this.userTaskTrack=this.serviceInfo.user_work_tracker;
        }else{
          this.userTaskTrack=-1
        }
        if(this.serviceInfo.is_recom_locked && this.serviceInfo.recom_locker_id != this.globalService.getCurrentUser()["id"]){
          this.router.navigate(['shops/view-service-request/'+this.serviceRequestId]);
          return;
        }
        this.lockServiceRequest(1);
        this.VehicleInspectionReport = this.serviceInfo.inspection_reports;
        this.serviceRequestList = response.data.service_request_list;
        if(response.data.service_request_list.length == 0){
           this.noJobsAddedMsg = true;
        }else{
          this.noJobsAddedMsg = false;
        }
        this.showServiceDetail = true;
        this.serviceRequest.controls.access_timestamp.setValue(this.serviceInfo.access_timestamp);
        this.serviceRequest.controls.customer_id.setValue(this.serviceInfo.customer_id)
        this.serviceRequest.controls.car_id.setValue(this.serviceInfo.car_id)
        this.serviceRequest.controls.request_id.setValue(this.serviceInfo.request_id)
        this.serviceRequest.controls.shop_name.setValue(this.serviceInfo.company_name);
        this.serviceRequest.controls.vehicle.setValue(this.serviceInfo.year + "/" + this.serviceInfo.make + "/" + this.serviceInfo.model);
        this.serviceRequest.controls.license_plate.setValue(this.serviceInfo.license_plate);
        this.serviceRequest.controls.title.setValue(this.serviceInfo.title);
        this.serviceRequest.controls.vin_number.setValue(this.serviceInfo.vin);
        this.serviceRequest.controls.customer_name.setValue(this.serviceInfo.customer_name);
        this.serviceRequest.controls.requested_date.setValue(this.globalService.getFullFormateDate(this.serviceInfo.requested_delivery_date,'G','H'));
        this.serviceRequest.controls.no_report_performed.setValue(this.serviceInfo.no_report_performed == "1" ? true : false);
        this.serviceRequest.controls.mobile_number.setValue(this.serviceInfo.mobile_phone);
        this.serviceRequest.controls.scheduled_date.setValue(this.serviceInfo.scheduled_date);
        this.serviceRequest.controls.mileage.setValue(this.serviceInfo.present_mileage);
        this.serviceRequest.controls.needed_by.setValue(this.serviceInfo.needed_by);
        this.serviceRequest.controls.grand_total.setValue(this.serviceInfo.grand_total);
        if(isNaN(parseFloat(this.serviceInfo.supply_charges))){
          this.serviceRequest.controls.supply_charges.setValue('0.00');
        }else{
          this.serviceRequest.controls.supply_charges.setValue(this.serviceInfo.supply_charges);
        }
        if(isNaN(parseFloat(this.serviceInfo.supplies_charges))){
          this.serviceRequest.controls.supplies_charges.setValue('0.00');
        }else{
          this.serviceRequest.controls.supplies_charges.setValue(this.serviceInfo.supplies_charges);
        }
        this.supplyCharges = this.serviceRequest.controls.supplies_charges.value;
        this.serviceRequest.controls.labor_rate.setValue(this.serviceInfo.labor_rate);
        this.serviceRequest.controls.first_name.setValue(this.serviceInfo.first_name);
        this.serviceRequest.controls.last_name.setValue(this.serviceInfo.last_name);
        this.serviceRequest.controls.notify_to_customer.setValue(this.serviceInfo.notify_to_customer);
        // this.serviceRequest.controls.scheduledServiceDate.setValue((this.serviceInfo.schedule_service_date == "0000-00-00 00:00:00" || (this.serviceInfo.schedule_service_date == null) ?  this.datePipe.transform(new Date(),'MM/dd/yyyy hh:mm a') : this.datePipe.transform(this.serviceInfo.schedule_service_date,'MM/dd/yyyy hh:mm a')))
        let t=this.globalService.getFullFormateDate(this.serviceInfo.schedule_service_date,'G','C');
        // if(t==""){
        //   t=this.datePipe.transform(new Date(),'MM/dd/yyyy hh:mm a');
        // }
        this.serviceRequest.controls.scheduledServiceDate.setValue(t);

        // this.serviceRequest.controls.estimated_delivery_date.setValue(((this.serviceInfo.estimated_delivery_date == "0000-00-00 00:00:00" || this.serviceInfo.estimated_delivery_date == "1969-12-31 19:00:00") ?  this.datePipe.transform(new Date(),'MM/dd/yyyy hh:mm a') : this.datePipe.transform(this.serviceInfo.estimated_delivery_date,'MM/dd/yyyy hh:mm a')) )
        let edd=this.globalService.getFullFormateDate(this.serviceInfo.estimated_delivery_date,'G','B');
        this.serviceRequest.controls.estimated_delivery_date.setValue(edd);

        // this.serviceRequest.controls.estimatedDeliveryDate.setValue(((this.serviceInfo.estimated_delivery_date == "0000-00-00 00:00:00" || this.serviceInfo.estimated_delivery_date == "1969-12-31 19:00:00") ?  this.datePipe.transform(new Date(),'MM/dd/yyyy hh:mm a') : this.datePipe.transform(this.serviceInfo.estimated_delivery_date,'MM/dd/yyyy hh:mm a')))
        let eddA=this.globalService.getFullFormateDate(this.serviceInfo.estimated_delivery_date,'G','B');
        this.serviceRequest.controls.estimatedDeliveryDate.setValue(eddA);
        if((typeof this.serviceInfo.request_documents !='undefined') && (this.serviceInfo.request_documents.length > 0 )){
          this.requestDoc=this.serviceInfo.request_documents;
        }
        if((typeof this.serviceInfo.request_images !='undefined') && (this.serviceInfo.request_images.length > 0 )){
          this.requestImages=this.serviceInfo.request_images;
        }
        this.serviceRequest.controls.inspectionReportUPSPValue.setValue(this.serviceInfo.inspectionReportUPSPValue)
        this.serviceRequest.controls.inspectionReportACDELCOValue.setValue(this.serviceInfo.inspectionReportACDELCOValue)
        this.serviceRequest.controls.inspectionReportType.setValue(this.serviceInfo.inspectionReportType)
        this.serviceRequest.controls.is_supply_charges_included.setValue(this.serviceInfo.is_supply_charges_included)
        this.serviceRequest.controls.requestCreationType.setValue(this.serviceInfo.requestCreationType)
        this.serviceRequest.controls.user_type.setValue("User")
        this.serviceRequest.controls.user_id.setValue(this.globalService.getCurrentUser()["id"]);
        this.serviceRequest.controls.service_request_id.setValue(this.serviceInfo.id);
        this.serviceRequest.controls.grand_total.setValue(this.serviceInfo.grand_total);
        if(isNaN(parseFloat(this.serviceInfo.tax_rate))){
          this.serviceRequest.controls.tax_rate.setValue('0.00')
        }else{
          this.serviceRequest.controls.tax_rate.setValue(this.serviceInfo.tax_rate)
        }
        this.serviceRequest.controls.tax_amount.setValue(this.serviceInfo.tax_amount)
        this.serviceRequest.controls.customer_type.setValue(this.serviceInfo.customer_type)
        this.serviceRequest.controls.service_request_list.setValue(this.serviceRequestList);
        this.serviceRequest.controls.usps_vehicle.setValue(this.serviceInfo.usps_vehicle);
        this.serviceRequest.controls.recommendation_saved.setValue(this.serviceInfo.recommendation_saved)
        this.serviceRequest.controls.id.setValue(this.serviceInfo.id);
        this.serviceRequest.controls.is_supplies_charges_included.setValue(this.serviceInfo.is_supplies_charges_included == "1" ? true : false)
        this.serviceRequest.controls.is_tax_included.setValue(this.serviceInfo.is_tax_included == "1" ? true : false);
        this.serviceRequest.controls.insurance_expiration_date.setValue(this.globalService.getFullFormateDate(this.serviceInfo.insurance_expiration_date,'GD','B'));
        this.serviceRequest.controls.registration_expiration_date.setValue(this.globalService.getFullFormateDate(this.serviceInfo.registration_expiration_date,'GD','B'));
        this.serviceRequest.controls.is_auto_order.setValue(this.serviceInfo.partstech_auto_order);
        this.serviceRequest.controls.on_the_lot.setValue(this.serviceInfo.on_the_lot == "1" ? true : false);
        this.serviceRequest.controls.parts_awaited.setValue(this.serviceInfo.parts_awaited == "1" ? true : false);
        this.serviceRequest.controls.cc_fee_included.setValue(this.serviceInfo.cc_fee_included);
        this.vehicle_pic=this.serviceInfo.vehicle_pic
        this.vin_photo=this.serviceInfo.vin_photo

        this.uploaded_video = this.serviceInfo.walk_around_video!=null && this.serviceInfo.walk_around_video!='null'?1:2;
         this.upload_v = [];
        if(this.uploaded_video==1){
          this.upload_v.push(this.serviceInfo.walk_around_video)
        } 
        this.downloadUspsReport();
        this.serviceRequest.controls.vehicle.setValue(this.globalService.getGlobalVehicleName(this.serviceInfo));      
        let byPassRoute = 'shops/view-service-request',prevRoute = this.globalService.reConstructPath(this.app.previousUrl);
        if(prevRoute != byPassRoute){
          this.startTracker();
        }
        
        this.getAllTechJobStatus();
        
        setTimeout(()=>{
          if(this.fillRecommendationCoupon && this.serviceInfo.discounts){
            this.fillRecommendationCoupon.reInitialize(this.serviceInfo.discounts);
      
            if(this.job_id){ 
               if(!$("[data-level="+this.level_id+"]").hasClass("mat-expanded")){  
                  $("[data-level="+this.level_id+"]").click();  
              }
                if(this.job_id){ 

                  setTimeout(()=>{
                    $([document.documentElement, document.body]).animate({
                      scrollTop: $("[data-level="+this.job_id+"]").offset().top-170
                    }, 2000);
                  },1000);

                }  
             
            }
          }
        }, 500);

      } else {
        this.showServiceDetail = false;
        this.globalService.snackbar("error", response.message);
      }
      this.showCostPrice=this.globalService.getAcl('display_part_cost','views'); 
      this.getAllLabels();
      this.checkPartsTechLaborSetting();
      this.makeAPIStructureForReports(this.serviceRequest);
      this.makeCalculations();
      if(this.findJobAndCreateStatus){
          this.findJobInSR(this.holdJobDataFromPaste);
      }
      if(this.stockOrderBtnId !== null){
        setTimeout(() => {
          document.getElementById(this.stockOrderBtnId).click();
        }, 10);
      }
      if(this.invokeInspectionReportPopup.openPopup == true){
        if (this.serviceInfo.is_usps_vehicle == '1') {
          this.openInsceptionReport(3, { 'note': this.invokeInspectionReportPopup.notes, 'form_data': this.invokeInspectionReportPopup.formData });
        } else {
          this.openVehicleInscpectionReport(3, { 'note': this.invokeInspectionReportPopup.notes, 'form_data': this.invokeInspectionReportPopup.formData });
        }
      }

    });
    this.filteredOptions = this.myControl.valueChanges
    .startWith('')
    .map(val => val ? this.filter(val) : this.partsOptions.slice());

    // filter genericJobs
    this.genericFilterOptions = this.genericControl.valueChanges
    .startWith(null)
    .map(val => val ? this.genericFilter(val) : this.genericOptions.slice());

    this.ComputerCodesFilterOptions = this.ComputerCodesControl.valueChanges
    .startWith(null)
    .map(val => val ? this.CCfilter(val) : this.ComputerCodesOptions.slice());

    let contents = { user_id: this.globalService.getCurrentUser()["id"] };
    this.globalService.callAPI(globalConstants.API_GET_PART_MATRIX, contents).subscribe((res:any) => {
      if (res.result == '1') {
        this.price_set = res.data.price_set ;
      }
    });
  }

  getLabourType(){
    var currentCustomer:any  = JSON.parse(localStorage.getItem("loggedInUser"));
    var stringifiedFormData = "user_type="+ currentCustomer.user_type +"&user_id=" + currentCustomer.id + "&shop_id=" + currentCustomer.shop_id;
          this.globalService.callAPI(globalConstants.API_GET_CUSTOMER_TYPES,stringifiedFormData).subscribe(data=> {
                this.labourRatesType = data.data;
            });
  }

  selectLabourType(e: any, obj, obj_child, level_3_child) {
    let event = e.target.value;
    var tempLabourRate;
    this.labourRatesType.forEach(element => {
        if(element.id == event){
             tempLabourRate = element.labor_rate;
        }
    });
    if (level_3_child != undefined) {
      if(event == 0){
        tempLabourRate = this.tempReports[obj.id][obj_child.id]['old_labour_rate'];
      }
      var pastCost = parseFloat(this.tempReports[obj.id][obj_child.id][level_3_child.id]["estimated_time"]);
      var nextPartLabour = parseFloat(this.tempReports[obj.id][obj_child.id][level_3_child.id]["nexpart_labor_time"]);
      this.serviceRequest.value.labor_rate = tempLabourRate;
      var labourRate = tempLabourRate;
      var labourTime = 1;
      
      if (pastCost > 1) {
        labourRate = tempLabourRate * (pastCost + nextPartLabour);
        labourTime = pastCost;
      } else {
        labourRate = tempLabourRate * (pastCost + nextPartLabour);
        labourTime = pastCost;
      }
      
      this.tempReports[obj.id][obj_child.id][level_3_child.id]["estimated_labor"] = labourRate;
      this.tempReports[obj.id][obj_child.id][level_3_child.id]["estimated_time"] = labourTime;
      this.tempReports[obj.id][obj_child.id][level_3_child.id]['labour_type'] = event;
      this.tempReports[obj.id][obj_child.id][level_3_child.id]['job_customer_type_id'] = event;
      this.tempReports[obj.id][obj_child.id][level_3_child.id]['job_labor_rate'] = tempLabourRate;
      this.viewingJSON[level_3_child.recommendations[0].id]["labour"] = {
        price: parseFloat(this.tempReports[obj.id][obj_child.id][level_3_child.id]["estimated_labor"]).toFixed(2),
        level: 3,
        traverse: level_3_child.id + "|" + obj_child.id + "|" + obj.id
      }
    } else {
      if(event == 0){
        tempLabourRate = this.tempReports[obj.id][obj_child.id]['old_labour_rate'];
      }
      var pastCost = parseFloat(this.tempReports[obj.id][obj_child.id]["estimated_time"]);
      var nextPartLabour = parseFloat(this.tempReports[obj.id][obj_child.id]["nexpart_labor_time"]);
      var labourRate = tempLabourRate;
      var labourTime = 1;

      if (pastCost > 1) {
        labourRate = tempLabourRate * (pastCost + nextPartLabour);
        labourTime = pastCost;
      } else {
        labourRate = tempLabourRate * (pastCost + nextPartLabour);
        labourTime = pastCost;
      }

      this.tempReports[obj.id][obj_child.id]["estimated_labor"] = labourRate;
      this.tempReports[obj.id][obj_child.id]["estimated_time"] = labourTime;
      this.tempReports[obj.id][obj_child.id]['labour_type'] = event;
      this.tempReports[obj.id][obj_child.id]['job_customer_type_id'] = event;
      this.tempReports[obj.id][obj_child.id]['job_labor_rate'] = tempLabourRate;
      this.viewingJSON[obj_child.recommendations[0].id]["labour"] = {
        price: (parseFloat(this.tempReports[obj.id][obj_child.id]["estimated_labor"])).toFixed(2),
        level: 2,
        traverse: obj_child.id + "|" + obj.id
      }
      this.serviceRequest.value.labor_rate = tempLabourRate;
    }
    this.makeCalculations();
  }

    chkDate(date){
      if (date!="0000-00-00 00:00:00" && date!=null) {
        var currentDate = new Date();
        if(new Date(date)<new Date(currentDate)) {
          return true
        } else {
          return false;
        }
      }
      return(false)
    }
  makeCalculations(){
    this.calcObj = {}
    var keys = Object.keys(this.viewingJSON);
    var addedPartSum = [];
    var addedPartGJ = [];
    var newPartSum=0,newGJSum=[];
    var l1id="";
    let source=this.viewingJSON;
    let vTotal=0;
    let taxable=0;
    let levelPrice={};
    let jobPrice={}
    for(let i in source){
      newPartSum=0
      for(let j in source[i]){
        if( j == "UAP" || j == "UNP") { continue; }
        if(Array.isArray(source[i][j]) && source[i][j].length){
          for(let k in source[i][j]){
            let price=0;
            let level=source[i][j][k].traverse.split('|');
            if(typeof source[i][j][k].non_taxable!='undefined' && source[i][j][k].non_taxable=='1'){
                price=(parseFloat(source[i][j][k].quantity)* parseFloat(source[i][j][k].price));
              if(!Number.isNaN(price)){
                vTotal=vTotal+price;
              }else{
                price=(parseFloat(source[i][j][k].part_price) * parseFloat(source[i][j][k].part_qty));
                if(!Number.isNaN(price)){
                  vTotal=vTotal+price;
                }
              }
            }
            newPartSum+=price
            if(!Number.isNaN(price) && price){
              if(typeof levelPrice[level[level.length-1]]=='undefined'){
                levelPrice[level[level.length-1]]=0;
              }
              levelPrice[level[level.length-1]]=(levelPrice[level[level.length-1]]+price);
              this.calcObj[level[level.length-1]]=(levelPrice[level[level.length-1]]).toFixed(2);
            }else if(typeof levelPrice[level[level.length-1]]=='undefined'){
              this.calcObj[level[level.length-1]]='0.00';
              levelPrice[level[level.length-1]]=0;
            }
            let pricel=0;
            if(typeof source[i][j][k].non_taxable!='undefined' && source[i][j][k].non_taxable!='1'){
              pricel=(parseFloat(source[i][j][k].quantity)* parseFloat(source[i][j][k].price));
              if(!Number.isNaN(pricel)){
                taxable=taxable+pricel;
              }else{
                pricel=(parseFloat(source[i][j][k].part_price) * parseFloat(source[i][j][k].part_qty));
                if(!Number.isNaN(pricel)){
                  taxable=taxable+pricel;
                }
              }
            }
            if(!Number.isNaN(pricel) && pricel){
              if(typeof levelPrice[level[level.length-1]]=='undefined'){
                levelPrice[level[level.length-1]]=0.00;
              }
              levelPrice[level[level.length-1]]=(levelPrice[level[level.length-1]]+pricel);
              this.calcObj[level[level.length-1]]=(levelPrice[level[level.length-1]]).toFixed(2);
            }else if(typeof levelPrice[level[level.length-1]]=='undefined'){
              this.calcObj[level[level.length-1]]='0.00';
              levelPrice[level[level.length-1]]=0.00;
            }
            newPartSum+=pricel
          }
        }else{
          if(j=='labour'){
            let pricel=parseFloat(source[i][j].price);
            if(this.isLaborTax){
              vTotal=vTotal+pricel;
            }else{
              taxable=taxable+pricel;
            }
            let level=source[i][j].traverse.split('|');
            if(!Number.isNaN(pricel) && pricel){
              if(typeof levelPrice[level[level.length-1]]=='undefined'){
                levelPrice[level[level.length-1]]=0.00;
              }
              newPartSum+=pricel
              levelPrice[level[level.length-1]]=(levelPrice[level[level.length-1]]+pricel);
              this.calcObj[level[level.length-1]]=(levelPrice[level[level.length-1]]).toFixed(2);
            }else if(typeof levelPrice[level[level.length-1]]=='undefined'){
              this.calcObj[level[level.length-1]]='0.00';
              levelPrice[level[level.length-1]]=0.00;
            }
          }
        }
        /** penny issue fix */
        taxable=parseFloat(taxable.toFixed(3))
        /** penny issue fix */
      }
      jobPrice[i]=newPartSum.toFixed(2)
    }
    this.levelPriceObj=jobPrice
    vTotal=parseFloat(vTotal.toFixed(2));
    taxable=parseFloat(taxable.toFixed(2));
    this.subtotal = (taxable+vTotal);
    this.totalNonTaxableValue =vTotal;
    var TaxableValue = taxable;
    
    // console.log(TaxableValue);
    this.totalTaxedValue = (TaxableValue * this.serviceRequest.value.tax_rate)/100;
    this.totalTaxedValue = this.totalTaxedValue.toFixed(2);
    this.grandTotal = this.subtotal.toFixed(2);
    if(this.serviceRequest.controls.is_tax_included.value == "1") {
      this.AddTaxToGrandTotal({checked:true},0);
    }
    if(this.serviceRequest.controls.is_supplies_charges_included.value == "1") {
      this.AddSCToGrandTotal({checked:true},0)
    }
    // To apply discount
    this.applyDiscount(this.serviceInfo.discounts.total_discounted_amount);
    if(this.serviceInfo.total_paid == null){
      this.serviceInfo.total_paid = 0;
    }
    // console.log(this.grandTotal)
  }
  
  addCouponControls(couponCode:string, opr:number){
    let couponKey:string = 'coupon_code';
    this.couponOpr = opr;
    if(opr == 2){
      couponKey = 'discount_id';
    }
    let couponControl = new FormControl(couponCode);
    let oprControl = new FormControl(opr);
    if(!this.serviceRequest.contains(couponKey)){
      this.serviceRequest.addControl(couponKey, couponControl);
    }
    if(!this.serviceRequest.contains('opr')){
      this.serviceRequest.addControl('opr', oprControl);
    }
    this.serviceRequest.controls[couponKey].setValue(couponCode);
    this.serviceRequest.controls['opr'].setValue(opr);
    this.serviceRequest.controls[couponKey].updateValueAndValidity();
    this.serviceRequest.controls['opr'].updateValueAndValidity();
    // console.log('coupon code value = ', couponControl.value);
    // console.log('opr value = ', oprControl.value);
    // console.log('data = ', this.serviceRequest.value);
  }
  
  removeCouponControls(){
    if(this.serviceRequest.contains('discount_id')){
      this.serviceRequest.removeControl('discount_id');
    }
    if(this.serviceRequest.contains('coupon_code')){
      this.serviceRequest.removeControl('coupon_code');
    }
    if(this.serviceRequest.contains('opr')){
      this.serviceRequest.removeControl('opr');
    }
  }
  
  applyDiscount(discount:number){
    // console.log('applying discount fill = ', discount);
    // this.grandTotal = this.parseFloats(parseFloat(this.grandTotal) - discount);
    let tmpSubTotal = this.subtotal;
    let tmpSC = ((this.serviceRequest.value.is_supplies_charges_included == '1') ? parseFloat(this.supplyCharges) : 0.0);
    let tmpTax = ((this.serviceRequest.value.is_tax_included == '1') ? parseFloat(this.totalTaxedValue) : 0.0);
    let totalBeforeDiscount = tmpSubTotal + tmpSC + tmpTax;
    if(totalBeforeDiscount > 0){
      this.grandTotal = this.parseFloats(totalBeforeDiscount - discount);
      if(parseFloat(this.grandTotal) < 0){
        this.grandTotal = this.parseFloats('0');
      }
    }
    this.balance = parseFloat(this.grandTotal) - parseFloat(this.serviceInfo.total_paid);
    this.calculateCCFee({checked: this.serviceRequest.controls.cc_fee_included.value == '1'});
  }
  
  openServiceHistoryPopup(){​
    // Material Popup Dialog
    
    // let dialogRef = this.dialog.open(ServiceHistoryComponent, {​
    //   panelClass: 'car-dialog-form',
    //   width:'95%',
    //   data: {​ isPopup: true, car_id:this.serviceDetail.car_id, service_data: this.serviceDetail }​
    // }​);
    // dialogRef.afterClosed().subscribe(result => {​
    //   console.log('history popup closed = ', result);
    // }​);
    let isModalPresent:ModalInfo = this.globalService.getBootstrapModalInfo('service-history-'+this.serviceInfo.car_id);
    let initialState:any;
    if(!isModalPresent){
      initialState = {​ isPopup: true, carId:this.serviceInfo.car_id, serviceInfo: this.serviceInfo, id: 'service-history-'+this.serviceInfo.car_id };
      this.bsModalRef = this.modalService.show(ServiceHistoryComponent, {initialState});
      this.globalService.setBootstrapModalRef(initialState.id, this.bsModalRef);
      this.globalService.setBootstrapModalInfo(initialState.id, {component: ServiceHistoryComponent, data: initialState, name: 'Service History View', id: initialState.id, module:'Service Request'});
    }
    else{
      initialState = isModalPresent.data;
      this.bsModalRef = this.modalService.show(isModalPresent.component, {initialState});
    }
    for(let x in initialState){
      this.bsModalRef.content[x] = initialState[x];
    }
  }​
  
  removeDiscount(discount:number){
    this.grandTotal = this.parseFloats(parseFloat(this.grandTotal) + discount);
    this.balance = parseFloat(this.grandTotal) - parseFloat(this.serviceInfo.total_paid);
    if(this.balance < 0){
      this.balance = 0;
    }
    this.calculateCCFee({checked: this.serviceRequest.controls.cc_fee_included.value == '1'});
  }
  
  parseFloats(value){
    let val=parseFloat(value);
    if(isNaN(val)){
      return '0.00';
    }
    return val.toFixed(2);
  }
  
  AddTaxToGrandTotal(event,type){
    if(event.checked) {
      this.grandTotal = parseFloat(this.grandTotal) + parseFloat(this.totalTaxedValue);
      this.serviceRequest.controls.is_tax_included.setValue(1);
      if(type == 1 && this.isTaxUpdated){
        this.grandTotal = 0; 
      }
    }
    else  {
      this.grandTotal = this.grandTotal = parseFloat(this.grandTotal) - parseFloat(this.totalTaxedValue)
      this.serviceRequest.controls.is_tax_included.setValue(0);
    }
    if(this.grandTotal < 0){
      this.grandTotal = 0;
      this.isTaxUpdated = true;
    }
    this.grandTotal = this.grandTotal.toFixed(2);
    this.balance = parseFloat(this.grandTotal) - parseFloat(this.serviceInfo.total_paid);
    this.calculateCCFee({checked: this.serviceRequest.controls.cc_fee_included.value == '1'});
    // To apply discount
    // this.applyDiscount(this.serviceInfo.discounts.total_discounted_amount);
  }
  AddSCToGrandTotal(event,type){
    this.supplyCharges = this.serviceRequest.value.supplies_charges;
    if(this.serviceInfo.supply_charges_disp_type == 2){ //if percentage based supply charges
      let shopSCPercent = this.serviceInfo.supply_charge_percentage;
      this.supplyCharges = (shopSCPercent/100)*this.subtotal;
      if(parseFloat(this.serviceInfo.supply_charges_limit) > 0){
        this.supplyCharges = this.supplyCharges < this.serviceInfo.supply_charges_limit ? this.supplyCharges : this.serviceInfo.supply_charges_limit;
      }
      this.serviceRequest.controls.supply_charges.setValue(this.supplyCharges);
    }
    if(event.checked) {
      this.grandTotal = parseFloat(this.grandTotal) + parseFloat(this.supplyCharges);
      this.serviceRequest.controls.is_supply_charges_included.setValue(1);
      if(type === 1 && this.isSupplyUpdated){
        this.grandTotal = 0; 
      }
    } else {
      this.grandTotal = parseFloat(this.grandTotal) - parseFloat(this.supplyCharges);
      this.serviceRequest.controls.is_supply_charges_included.setValue(0);
    }
    if(this.grandTotal < 0){
      this.grandTotal = 0;
      this.isSupplyUpdated = true;
    }
    this.grandTotal = this.grandTotal.toFixed(2);
    this.balance = parseFloat(this.grandTotal) - parseFloat(this.serviceInfo.total_paid);
    this.calculateCCFee({checked: this.serviceRequest.controls.cc_fee_included.value == '1'});
    // To apply discount
    // this.applyDiscount(this.serviceInfo.discounts.total_discounted_amount);
  }
  CCfilter(val){
    if (val.length != undefined) {
      var filteredValue = this.ComputerCodesOptions.filter(option => option.fault_location.toLowerCase().indexOf(val.toLowerCase()) === 0);
      return filteredValue
    }
  }
  addNewComputerCodeFromList( lastestChild, recommendation,index ){
    if(typeof this.selectedNewComputerCodes[lastestChild.id]!="undefined" && this.selectedNewComputerCodes[lastestChild.id]!="") {
    var recommendation = lastestChild.recommendations[0]
    var alreadypresent = this.viewingJSON[recommendation.id]["NCC"].find((x)=> {
      return x.id == this.selectedNewComputerCodes[lastestChild.id]["id"]
    });
    if(alreadypresent!=undefined) {
      this.globalService.snackbar("error","Computer code already present")
    } else {
      this.viewingJSON[recommendation.id]["NCC"].push(this.selectedNewComputerCodes[lastestChild.id]);
      this.selectedNewComputerCodes[lastestChild.id] = ""
    }
    (<HTMLInputElement> document.getElementById("new-cc-"+lastestChild.id+"-ac")).value ="";
    } else {
      this.globalService.snackbar("error","Please enter computer code")
    }
  }
  
    // Add By Tafzeel //
    getAllLabels(){
      this.allLabels = [];
      let content = "user_id="+this.globalService.getCurrentUser()["id"];
      this.globalService.callAPI(globalConstants.API_GET_SERVICE_REQUEST_LABELS_MASTER, content).subscribe((res:any)=> {
        if(res.result == 1){
          this.allLabels = res.data;
          this.getDefaultServiceRequestLabel();
        }else{
            this.addCustomLabels();
        }
      },(error)=>{});
    }

    selectedLabel(label){
      if (label == 'OTL' || label == 'WAITING_FOR_PARTS') {
        this.updateCustomLabel(true, label);
      } else {
        let label_id : number = label;
        this.preSelectLabel.push(label);
        this.globalService.callAPI(globalConstants.API_UPDATE_SERVICE_REQUEST_LABELS,"request_id="+this.serviceRequestId+"&user_id="+this.globalService.getCurrentUser()["id"]+"&label_id="+label_id+ "&user_type=User").subscribe(function(response:any){
      })
    }
    }

    removeLabel(label){
      if (label == 'OTL' || label == 'WAITING_FOR_PARTS') {
        this.updateCustomLabel(false, label);
      } else {
      let label_id : number = label;
      this.removeCustomLabelInPreSelectLabel(label_id);
      this.globalService.callAPI(globalConstants.API_DELETE_SERVICE_REQUEST_LABEL,"request_id="+this.serviceRequestId+"&user_id="+this.globalService.getCurrentUser()["id"]+"&label_id="+label_id+ "&user_type=User").subscribe(function(response){
      })
     }
    }

    updateCustomLabel(checkboxStatus:any,labelType){
      let obj = {
        checked: checkboxStatus
      };
      this.showLabelsDropdown = checkboxStatus;
      if (labelType == 'OTL') {
        this.onTheLotCheckbox['checked']= checkboxStatus;
        this.showLabelsDropdown = true;
        this.updateOnTheLotStatus(obj);
      }else if(labelType == 'WAITING_FOR_PARTS'){
        this.waitingForPartsCheckbox['checked']= checkboxStatus;
        this.showLabelsDropdown = true;
        this.updateWaitingForPartStatus(obj);
      }
    }

  removeCustomLabelInPreSelectLabel(labelType: any) {
    let i = this.preSelectLabel.findIndex(label => label == labelType);
    if (i >= 0) {
      this.preSelectLabel.splice(i, 1);
    }
  }
  
    manageLabels(){
      let dialogRef = this.dialog.open(AddLabelDialogComponent, {
          panelClass: 'partstech-order-dialog',
          width: '800px',
          data: {}
      });
      dialogRef.afterClosed().subscribe(result => {
          this.getAllLabels();
      });
    }

     // get default service request label
    public getDefaultServiceRequestLabel(){
      this.preSelectLabel = [];
      this.showLabelsDropdown = false;
      this.globalService.callAPI(globalConstants.API_GET_SERVICE_REQUEST_LABELS, "request_id="+this.serviceRequestId+"&user_id="+this.globalService.getCurrentUser()["id"]+"&user_type=User").subscribe((response:any)=>{
      if(response.result == "1"){
        let labels = response.data;
        if(labels && labels.length>0){
          labels.forEach((r,v)=>{
              this.preSelectLabel.push(r.label_id);
          })
        }
        this.addCustomLabels(); 
      }
      })
    }

    getMetrixReport(){
      this.globalService.callAPI(globalConstants.API_SR_STATISTICS, "request_id="+this.serviceRequestId+"&user_id="+this.globalService.getCurrentUser()["id"]+"&user_type=User").subscribe((response:any)=>{
        if(response.result == "1" && 'data' in response){       
          this.businessMetricReport = Object.assign({}, response.data);
        }
      });
    }

  getSelectedOptionForCC(opt, immediateChild, level1Child){
    if(typeof opt == "object") {
      if(immediateChild.level == "2") {
        this.selectedNewComputerCodes[immediateChild.id] = {
          traverse: immediateChild.id + "|" + level1Child.id,
          id:opt.id,
          fault_location:opt.fault_location,
          trouble_code:opt.trouble_code
        }
      } else {
        this.selectedNewComputerCodes[immediateChild.id] = {
          traverse: immediateChild.id + "|" + immediateChild.parent_id + "|" + level1Child.id,
          id:opt.id,
          fault_location:opt.fault_location,
          trouble_code:opt.trouble_code
        }
      }
    }
    // (<HTMLInputElement>document.getElementById("new-cc-" + immediateChild.id + "-ac")).value = "";
  }
  imgDelete(index,servicerequest_id,servicerequestChild_id,servicerequest3levelChild_id,imgId,requestid){
    let request_id=this.serviceRequestId;
    this.globalService.confirmDialog("sure","Are you sure to delete?").subscribe((response)=>{
      if(response.toString() == "sure" && response!=undefined) {
        if(servicerequest3levelChild_id != undefined && servicerequest3levelChild_id){
          this.tempReports[servicerequest_id][servicerequestChild_id][servicerequest3levelChild_id].uploaded_images.splice(index,1)
        } else {
          this.tempReports[servicerequest_id][servicerequestChild_id].uploaded_images.splice(index,1)
        }
        this.globalService.callAPI(globalConstants.API_DELETE_RECOMMENDATION_PICTURE,"request_id="+request_id+"&user_id="+this.globalService.getCurrentUser()["id"]+"&recom_image_id="+imgId+"&user_type=User").subscribe(function(response){
        })
      }
    })

  }
  videoDelete(index,servicerequest_id,servicerequestChild_id,servicerequest3levelChild_id,imgId){
    let request_id=this.serviceRequestId;
    this.globalService.confirmDialog("sure","Are you sure to delete?").subscribe((response)=>{
      if(response.toString() == "sure" && response!=undefined) {
        if(servicerequest3levelChild_id != undefined && servicerequest3levelChild_id){
          this.tempReports[servicerequest_id][servicerequestChild_id][servicerequest3levelChild_id].uploaded_videos.splice(index,1)
        } else {
          this.tempReports[servicerequest_id][servicerequestChild_id].uploaded_videos.splice(index,1)
        }
        this.globalService.callAPI(globalConstants.API_DELETE_RECOMMENDATION_VIDEO,"request_id="+request_id+"&user_id="+this.globalService.getCurrentUser()["id"]+"&recom_video_id="+imgId+"&user_type=User").subscribe(function(response){
        })
      }
    })

  }

  imageDelete(imgId){
    let request_id=this.serviceRequestId;
    this.globalService.confirmDialog("sure","Are you sure to delete?").subscribe((response)=>{
      if(response.toString() == "sure" && response!=undefined) {  
        this.globalService.callAPI(globalConstants.API_DELETE_WALK_AROUND_VIDEO,"request_id="+request_id+"&user_id="+this.globalService.getCurrentUser()["id"]+"&user_type=User").subscribe((response)=>{  
              this.upload_v = [];
        }) 
            
      }
    })

  }
   uploadImages(event) {

     if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
      let valid=this.globalService.validateFiles('video',event.target.files);
      if(valid==false){
        this.removeFileValues();
        return false;
      }
      if (file.type == 'video/mp4' || file.type == "video/quicktime") {
        if (file.size) {



          let request_id=this.serviceRequestId;
          this.globalService.callAPI(globalConstants.API_DELETE_WALK_AROUND_VIDEO,"request_id="+request_id+"&user_id="+this.globalService.getCurrentUser()["id"]+"&user_type=User").subscribe((response)=>{

                this.upload_v = [];
                var data = new FormData(); 
                data.append('user_id',this.globalService.getCurrentUser()["id"] );
                data.append('user_type','User');
                data.append('request_id',this.serviceRequest.controls.id.value); 
                var th=this;
                data.append('walk_around_video',event.target.files[0]);
                this.globalService.formData(globalConstants.API_SET_WALK_AROUND_VIDEO, data).subscribe((res:any) => {
                  if(res.result==1){
                    let resData=res.data;
                    this.uploaded_video = 1 ;
                    this.uploaded_video_name = resData.walk_around_video ;
                    this.uploaded_video_id = resData.id ; 
                    this.upload_v.push(this.uploaded_video_name) 
                              this.cdr.detectChanges();
                  }
                  th.removeFileValues();
                });
            }) 


       
        }else{
          this.globalService.snackbar("error","Please choose corret file");
          this.removeFileValues();
        }
      }else{
        this.globalService.snackbar("error","Please choose correct file");
        this.removeFileValues();
      }
    }
  }

  uploadPicture(event, l1, l2, l3, rec) {
    let valid=this.globalService.validateFiles('image',event.target.files);
    if(valid){
      if (event.target.files && event.target.files[0]) {
        var filesAmount = event.target.files.length;
        for (let i = 0; i < filesAmount; i++) {
                var reader = new FileReader(); 
                var th=this;
                reader.onload = (event:any) => {
                   this.myFiles.push(event.target.result);   
                    var base64Image = event.target.result; 
                    this.uploadImage.level_1_id = l1.id;
                    this.uploadImage.level_2_id = l2.id;
                    if(l3!=null && l3!=undefined) {
                      this.uploadImage.level_3_id = l3.id;
                    }else{
                      this.uploadImage.level_3_id ='';
                    }
                    this.uploadImage.user_id = this.globalService.getCurrentUser()["id"];
                    this.uploadImage.request_id = rec.request_id;
                    this.uploadImage.recommendation_pic = base64Image
                    let fData=new FormData();
                    for(let i in this.uploadImage){
                      fData.append(i,this.uploadImage[i]);
                    } 
                    var tempobj = this.tempReports
                    this.globalService.formData(globalConstants.API_SET_RECOMMENDATION_PICTURE, fData).subscribe(function(response:any){
                      let result=response;
                      if(result.result == "1") {
                          if(l3!=undefined){
                            tempobj[l1.id][l2.id][l3.id]["uploaded_images"].push({filename:result.data.recommendation_pic,id:result.data.id})
                          } else {
                            tempobj[l1.id][l2.id]["uploaded_images"].push({filename:result.data.recommendation_pic,id:result.data.id})
                          }
                      }
                        th.removeFileValues();
                    }) 
                } 
                reader.readAsDataURL(event.target.files[i]);
        }
    }
    }
    // if (event.target.files && event.target.files[0]) {
    // if (event.target.files[0].type == 'image/png' || event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/jpg") {
    // this.globalService.imageLoader();
    // var files = event.target.files;
    // var file = files[0];
    // var getBase64Image;
    // var reader = new FileReader();
    // var th=this;
    // reader.onload = (e) => {
    //   var base64Image = e.target["result"]; 
    //   this.uploadImage.level_1_id = l1.id;
    //   this.uploadImage.level_2_id = l2.id;
    //   if(l3!=null && l3!=undefined) {
    //     this.uploadImage.level_3_id = l3.id;
    //   }else{
    //     this.uploadImage.level_3_id ='';
    //   }
    //   this.uploadImage.user_id = this.globalService.getCurrentUser()["id"];
    //   this.uploadImage.request_id = rec.request_id;
    //   this.uploadImage.recommendation_pic = base64Image
    //   let fData=new FormData();
    //   for(let i in this.uploadImage){
    //     fData.append(i,this.uploadImage[i]);
    //   }

    //   var tempobj = this.tempReports
    //   this.globalService.formData(globalConstants.API_SET_RECOMMENDATION_PICTURE, fData).subscribe(function(response){
    //     let result=response;
    //     if(result.result == "1") {
    //         if(l3!=undefined){
    //           tempobj[l1.id][l2.id][l3.id]["uploaded_images"].push({filename:result.data.recommendation_pic,id:result.data.id})
    //         } else {
    //           tempobj[l1.id][l2.id]["uploaded_images"].push({filename:result.data.recommendation_pic,id:result.data.id})
    //         }
    //     }
    //       th.removeFileValues();
    //   })
    // }
    // reader.readAsDataURL(file);
    // } else{
    //     this.globalService.snackbar("error","Please choose correct file");
    //   }
    // }
  }
  uploadVideo(event, l1, l2, l3, rec){
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
      let valid=this.globalService.validateFiles('video',event.target.files);
      if(valid==false){
        this.removeFileValues();
        return false;
      }
      if (file.type == 'video/mp4' || file.type == "video/quicktime") {
        if (file.size) {
          var data = new FormData();
          data.append('customer_id',this.serviceRequest.controls.customer_id.value);
          data.append('user_id',this.globalService.getCurrentUser()["id"] );
          data.append('vehicle_id',this.serviceRequest.controls.car_id.value);
          data.append('request_id',this.serviceRequest.controls.id.value);
          data.append('level_1_id',l1.id);
          data.append('level_2_id',l2.id);
          if(l3!=null){
            data.append('level_3_id',l3.id);
          }
          var th=this;
          data.append('recommendation_video',event.target.files[0]);
          this.globalService.formData(globalConstants.API_SET_RECOMMENDATION_VIDEO, data).subscribe((res:any) => {
            if(res.result==1){
              let resData=res.data;
             if(l3!=undefined){
              this.tempReports[l1.id][l2.id][l3.id]["uploaded_videos"].push({filename:resData.recommendation_video,id:resData.id})
             } else {
              this.tempReports[l1.id][l2.id]["uploaded_videos"].push({filename:resData.recommendation_video,id:resData.id})
             }
            }
            th.removeFileValues();
          });
        }else{
          this.globalService.snackbar("error","Please choose corret file");
          this.removeFileValues();
        }
      }else{
        this.globalService.snackbar("error","Please choose correct file");
        this.removeFileValues();
      }
    }
  }
  genericFilter(val: any): any[] {
    if (val.length != undefined) {
      var filteredValue = this.genericOptions.filter(option => option.name.toLowerCase().indexOf(val.toLowerCase()) === 0);
      return filteredValue
    }
  }
  setParams() {
    this.activatedRoute.params.subscribe((params: Params) => {
       if(this.serviceRequestId != params["id"] && typeof(this.serviceRequestId) !== 'undefined'){
        window.location.reload();
      }else{
        this.msgForSnackBar = localStorage.getItem('msgForSnackBar');
        if(typeof(this.msgForSnackBar) !=='undefined' && this.msgForSnackBar !== '' && this.msgForSnackBar !== null){
            this.globalService.snackbar("success",this.msgForSnackBar);
            localStorage.removeItem('msgForSnackBar');
        }
      }
      this.serviceRequestId = params["id"];
      this.globalService.srnoteListId(this.serviceRequestId);
    });
  }
  makeAPIStructureForReports(object) {
    var serviceRequestObject = object.value.service_request_list;
    for (var i = 0; i < serviceRequestObject.length; i++) {
        this.tempLevel2Ary = {};
        let subJobCategoriesInfo = [];
        for (var j = 0; j < serviceRequestObject[i].children.length; j++) {
          // level 2 children
        if (typeof(serviceRequestObject[i].children[j].recommendations[0]) != 'undefined') {
          subJobCategoriesInfo.push(serviceRequestObject[i].children[j].name);
          this.makeStructureForReport(i, j, null, serviceRequestObject[i].children[j].recommendations[0], serviceRequestObject[i], null)
        } else {
          if (typeof (serviceRequestObject[i].children[j].children) != 'undefined') {
            for (var p = 0; p < serviceRequestObject[i].children[j].children.length; p++) {
              subJobCategoriesInfo.push(serviceRequestObject[i].children[j].name +" -> "+ serviceRequestObject[i].children[j].children[p].name);
              this.makeStructureForReport(i, j, p, serviceRequestObject[i].children[j].children[p].recommendations[0], serviceRequestObject[i], serviceRequestObject[i].children[j].children[p])
            }
          }
        }
      }
      this.serviceRequestList[i]['subJobCategories']= subJobCategoriesInfo;
    }
  }
  makeStructureForReport(i, j, k, recommendation, child, intermediate_id) {
    var viewingJSONParts = []
    var viewingJSONGJ = []
    if (recommendation != undefined && recommendation.length!== 0) {
      var totalParts = recommendation.parts;
      var totalGj = recommendation.generic_jobs;
      for(var m=0;m<recommendation.computer_codes.length;m++){
        if (intermediate_id != undefined) {
          recommendation.computer_codes[m]["traverse"] =  recommendation.service_request_list_id + "|" + intermediate_id.parent_id + "|" + child.id;
        }
        else {
          recommendation.computer_codes[m]["traverse"] =  recommendation.service_request_list_id + "|" + child.id;
        }
      }
      var computerCodes = recommendation.computer_codes;
    }
    if ((totalParts != undefined && totalParts.length > 0) ) {
      var str = "";
      var x = '{';
      /** already added parts "AP"*/
      for (var t = 0; t < totalParts.length; t++) {
        let ptech=(typeof totalParts[t]["partstech_cart_item"]=="undefined"?'0':totalParts[t]["partstech_cart_item"]);
        let npart=(typeof totalParts[t]["nexlink_cart_item"]=="undefined"?'0':totalParts[t]["nexlink_cart_item"]);
        if (intermediate_id != undefined) {
          // level 3
          viewingJSONParts.push({
            "traverse": recommendation.service_request_list_id + "|" + intermediate_id.parent_id + "|" + child.id,
            "id": totalParts[t]["part_id"],
            "part_number": totalParts[t]["part_number"],
            "inventory_price":totalParts[t]["inventory_price"],
            "part_matrix_price":totalParts[t]["part_matrix_price"],
            "part_name": totalParts[t]["part_number"] + (this.isAllowed('views','display_part_cost') ?" ($"+totalParts[t]['cost']+') ':' ') + totalParts[t]["name"] + " " + totalParts[t]["description"],
            "part_price":parseFloat(totalParts[t]["part_price"]).toFixed(2),
            "part_qty": totalParts[t]["quantity"],
            "non_taxable": totalParts[t]["non_taxable"],
            "description":  totalParts[t]["description"],
            "partstech": ptech,
            "partstech_order": (typeof totalParts[t]["partstech_order_item"]=="undefined"?'0':totalParts[t]["partstech_order_item"]),
            "nexpart": npart,
            "nexpart_order": (typeof totalParts[t]["nexlink_order_item"]=="undefined"?'0':totalParts[t]["nexlink_order_item"]),
            "cost" :totalParts[t]['cost'],
            "level": 3,
            "stock_quantity": totalParts[t]['stock_quantity'],
            "low_stock_value": totalParts[t]['low_stock_value'],
            "out_of_stock":totalParts[t]['out_of_stock'],
            "quantity_overused" : totalParts[t]['quantity_overused'],
            "partstech_price":totalParts[t]['partstech_price'],
            "suggestion_parts_count":totalParts[t]['suggestion_parts_count'],
            "force_override":0,
            "partstech_labor_time":totalParts[t]['partstech_labor_time'],
            "partstech_labor_notes":totalParts[t]['partstech_labor_notes']
          })
        }
        else { // level 2
          viewingJSONParts.push({
            "traverse": recommendation.service_request_list_id + "|" + child.id,
            "id": totalParts[t]["part_id"],
            "part_number": totalParts[t]["part_number"],
            "inventory_price":totalParts[t]["inventory_price"],
            "part_matrix_price":totalParts[t]["part_matrix_price"],
            "part_name": totalParts[t]["part_number"] +(this.isAllowed('views','display_part_cost')? " ($"+totalParts[t]['cost']+') ':' ') + totalParts[t]["name"] + " " + totalParts[t]["description"],
            "part_price": parseFloat(totalParts[t]["part_price"]).toFixed(2),
            "part_qty": totalParts[t]["quantity"],
            "non_taxable": totalParts[t]["non_taxable"],
            "description": totalParts[t]["description"],
            "partstech":ptech ,
            "partstech_order": (typeof totalParts[t]["partstech_order_item"]=="undefined"?'0':totalParts[t]["partstech_order_item"]),
            "nexpart": npart,
            "nexpart_order": (typeof totalParts[t]["nexlink_order_item"]=="undefined"?'0':totalParts[t]["nexlink_order_item"]),
            "level": 2,
            "stock_quantity": totalParts[t]['stock_quantity'],
            "low_stock_value": totalParts[t]['low_stock_value'],
            "out_of_stock":totalParts[t]['out_of_stock'],
            "quantity_overused":totalParts[t]['quantity_overused'],
            "partstech_price":totalParts[t]['partstech_price'],
            "suggestion_parts_count":totalParts[t]['suggestion_parts_count'],
            "force_override":0,
            "partstech_labor_time":totalParts[t]['partstech_labor_time'],
            "partstech_labor_notes":totalParts[t]['partstech_labor_notes']
          })
        }
      str = str + ('"' + totalParts[t]["part_id"] + '":"' + parseFloat(totalParts[t]["part_price"]).toFixed(2) + "|" + totalParts[t]["quantity"] + "|" + totalParts[t]["non_taxable"] + "|" + totalParts[t]["name"] + '"' + ',');
      }
      var z = "}"
      str = x + str + z;
      var l = str.length;
      var y = str.substr(0, l - 2) + '}';
      var p = { "Parts": y };
      viewingJSONParts = JSON.parse(JSON.stringify(viewingJSONParts));
    }

     if((totalGj != undefined && totalGj.length > 0)) {

      var gj_arr = {}

      for (var q = 0; q < totalGj.length; q++) {

        var gj_str;
        gj_str = [];
        gj_str.push(parseFloat(totalGj[q]["price"]).toFixed(2) + "|" + totalGj[q]["id"] + "|" + totalGj[q]["quantity"] + "|" + totalGj[q]["non_taxable"]);
        gj_arr[totalGj[q]["generic_job_id"]] = gj_str;
        if (intermediate_id != undefined) {
          // level 3
          viewingJSONGJ.push({
            "traverse": recommendation.service_request_list_id + "|" + intermediate_id.parent_id + "|" + child.id,
            "id": totalGj[q]["id"],
            "name": totalGj[q]["name"],
            "price": parseFloat(totalGj[q]["price"]).toFixed(2),
            "quantity": totalGj[q]["quantity"],
            "non_taxable": totalGj[q]["non_taxable"],
            "generic_job_id": totalGj[q]["generic_job_id"],
            "level": 3
          })
        }
        else { // level 2
          viewingJSONGJ.push({
            "traverse": recommendation.service_request_list_id + "|" + child.id,
            "id": totalGj[q]["id"],
            "name": totalGj[q]["name"],
            "price": parseFloat(totalGj[q]["price"]).toFixed(2),
            "quantity": totalGj[q]["quantity"],
            "non_taxable": totalGj[q]["non_taxable"],
            "generic_job_id": totalGj[q]["generic_job_id"],
            "level": 2
          })
        }
      }
    }

    // console.log(viewingJSONGJ)
    if (intermediate_id != null && recommendation != undefined) {
      if(typeof this.tempLevel2Ary[intermediate_id.parent_id] != 'object'){
        this.tempLevel2Ary[intermediate_id.parent_id] = {}
      }
      this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id] = {}
      this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]["Parts"] = {};
      this.viewingJSON[recommendation.id] = { AP: JSON.parse(JSON.stringify(viewingJSONParts)), NP: [], AGJ: viewingJSONGJ, NGJ: [], ACC: computerCodes, NCC: [],labour:{
        price:parseFloat(recommendation.estimated_labor).toFixed(2),
        level:3,
        traverse:recommendation.service_request_list_id + "|" + intermediate_id.parent_id + "|" + child.id
        }
      }
      if(isNaN(parseFloat(recommendation.estimated_time))==true){
       recommendation.estimated_time=0;
     }
     if(isNaN(parseFloat(recommendation.estimated_labor))==true){
       recommendation.estimated_labor=0;
     }


     viewingJSONParts.forEach(function(x){
       x.is_editable = false;
       x.is_disabled = false;
     })

     this.viewingJSON[recommendation.id]["UAP"] = JSON.parse(JSON.stringify(viewingJSONParts));
     this.viewingJSON[recommendation.id]["UNP"] = []

      // leval 3 work for set the value in labour and rate & next part 
      
      if (recommendation.nexpart_labor_time > 0) {
        this.isNextPartStatus = true;
      }

      if (Number(recommendation.estimated_labor) == 0 && Number(recommendation.nexpart_labor_time) == 0) {
        if (Number(recommendation.job_labor_rate) == 0 && Number(recommendation.job_customer_type_id) == 0) {
          this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]["labour_type"] = this.serviceInfo.customer_type;
          this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]["job_customer_type_id"] = this.serviceInfo.customer_type;
          for (let i = 0; i < this.labourRatesType.length; i++) {
            if (this.labourRatesType[i].id == this.serviceInfo.customer_type) {
              this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]["job_labor_rate"] = this.labourRatesType[i].labor_rate;
              break;
            }
          }
        } else {
          var labourArr = this.labourRatesType;
          for (let i = 0; i < labourArr.length; i++) {
            if (Number(labourArr[i].labor_rate) == Number(recommendation.job_labor_rate)) {
              this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]["select_costom_type"] = false;
              this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]["labour_type"] = labourArr[i].id;
              this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]["job_customer_type_id"] = labourArr[i].id;
              this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]["job_labor_rate"] = labourArr[i].labor_rate;
              break;
            } else {
              this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]['old_labour_rate'] = recommendation.job_labor_rate;
              this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]["select_costom_type"] = true;
              this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]["labour_type"] = "0";
              this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]["job_customer_type_id"] = recommendation.job_customer_type_id;
              this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]["job_labor_rate"] = recommendation.job_labor_rate;

            }
          }
        }
      }

      else {
        for (let i = 0; i < this.labourRatesType.length; i++) {
          if (this.labourRatesType[i].labor_rate == recommendation.job_labor_rate) {
            this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]["select_costom_type"] = false;
            this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]["labour_type"] = recommendation.job_customer_type_id;
            this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]["job_customer_type_id"] = this.labourRatesType[i].id;
            this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]["job_labor_rate"] = this.labourRatesType[i].labor_rate;
            break;
          } else {
            this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]["labour_type"] = "0";
            this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]["select_costom_type"] = true;
            this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]['old_labour_rate'] = recommendation.job_labor_rate;
            this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]["job_customer_type_id"] = '0';
            this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]["job_labor_rate"] = recommendation.job_labor_rate;
          }
        }
      }
      this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]["totalNextPartLaborHours"] = 0;
      this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]["totalPartsTechLaborHours"] = 0;
      this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]['combinedPartsTechLaborNotes'] = [];
      let partData = recommendation.parts;
      if (partData.length > 0) {
        //process PartsTech labor data
        for (let i = 0; i < partData.length; i++) {
          if (partData[i].partstech_cart_item == 1 || partData[i].partstech_order_item == 1) {
            this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]["totalPartsTechLaborHours"] = parseFloat(this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]["totalPartsTechLaborHours"]) + parseFloat(partData[i].partstech_labor_time);
            this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]['combinedPartsTechLaborNotes'].push(partData[i].partstech_labor_notes);
          }
        }
      }
      let nextPartLabors = recommendation.nexpart_labor;
        if (nextPartLabors.length > 0) {
          //process nexpart labor data
         for (let j = 0; j < nextPartLabors.length; j++) {
            this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]["totalNextPartLaborHours"] = parseFloat(this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]["totalNextPartLaborHours"]) + parseFloat(nextPartLabors[j].hours);
        }
      }
      
     this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]["nexpart_labor_time"] = recommendation.nexpart_labor_time;

     this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]["nexpart_labor"] = recommendation.nexpart_labor; 

      this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]["estimated_labor"] = recommendation.estimated_labor;
      this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]["estimated_time"] = recommendation.estimated_time;
      this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]["generic_jobs"] = {}
      this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]["computer_codes"] = {};
      this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]["uploaded_images"] = recommendation.uploaded_images;
      this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]["uploaded_videos"] = recommendation.uploaded_videos;
      this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]["rec"] = recommendation.recommendation;
      this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]["notes"] = recommendation.notes;
      this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]["recommendation"] = recommendation.recommendation;
      this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]["notes_check"] = recommendation.notes_check == "1" ? "on" : "off";
      this.tempLevel2Ary[intermediate_id.parent_id][recommendation.service_request_list_id]["rec_check"] = recommendation.recommendation_check == "1" ? "on" : "off";
      this.tempReports[child.id] = this.tempLevel2Ary;
    } else {
      this.tempLevel2Ary[recommendation.service_request_list_id] = {}
      this.tempLevel2Ary[recommendation.service_request_list_id]["Parts"] = {};
      this.viewingJSON[recommendation.id] = { AP:JSON.parse(JSON.stringify(viewingJSONParts)), NP: [], AGJ:viewingJSONGJ, NGJ: [], ACC: computerCodes, NCC: [],labour:{
        price:parseFloat(recommendation.estimated_labor).toFixed(2),
        level:2,
        traverse:recommendation.service_request_list_id + "|" + child.id
        }
      }

      if(isNaN(parseFloat(recommendation.estimated_time))==true){
       recommendation.estimated_time=0;
       }
       if(isNaN(parseFloat(recommendation.estimated_labor))==true){
         recommendation.estimated_labor=0;
       }
       viewingJSONParts.forEach(function(x){
         x.is_editable = false;
         x.is_disabled = false;
       })
       this.viewingJSON[recommendation.id]["UAP"] = JSON.parse(JSON.stringify(viewingJSONParts));
       this.viewingJSON[recommendation.id]["UNP"] = []

      // leval 2 work for set the value in labour and rate & next part 

      if (recommendation.nexpart_labor_time > 0) {
        this.isNextPartStatus = true;
      }
      if (Number(recommendation.estimated_labor) == 0 && Number(recommendation.nexpart_labor_time) == 0) {
        if (Number(recommendation.job_labor_rate) == 0 && Number(recommendation.job_customer_type_id) == 0) {
          this.tempLevel2Ary[recommendation.service_request_list_id]["labour_type"] = this.serviceInfo.customer_type;
          this.tempLevel2Ary[recommendation.service_request_list_id]["job_customer_type_id"] = this.serviceInfo.customer_type;
          for (let i = 0; i < this.labourRatesType.length; i++) {
            if (this.labourRatesType[i].id == this.serviceInfo.customer_type) {
              this.tempLevel2Ary[recommendation.service_request_list_id]["job_labor_rate"] = this.labourRatesType[i].labor_rate;
              break;
            }
          }

        } else {
          var labourArr = this.labourRatesType;
          for (let i = 0; i < labourArr.length; i++) {
            if (Number(labourArr[i].labor_rate) == Number(recommendation.job_labor_rate)) {
              this.tempLevel2Ary[recommendation.service_request_list_id]["select_costom_type"] = false;
              this.tempLevel2Ary[recommendation.service_request_list_id]["labour_type"] = labourArr[i].id;
              this.tempLevel2Ary[recommendation.service_request_list_id]["job_customer_type_id"] = labourArr[i].id;
              this.tempLevel2Ary[recommendation.service_request_list_id]["job_labor_rate"] = labourArr[i].labor_rate;
              break;
            } else {
              this.tempLevel2Ary[recommendation.service_request_list_id]['old_labour_rate'] = recommendation.job_labor_rate;
              this.tempLevel2Ary[recommendation.service_request_list_id]["select_costom_type"] = true;
              this.tempLevel2Ary[recommendation.service_request_list_id]["labour_type"] = "0";
              this.tempLevel2Ary[recommendation.service_request_list_id]["job_customer_type_id"] = recommendation.job_customer_type_id;
              this.tempLevel2Ary[recommendation.service_request_list_id]["job_labor_rate"] = recommendation.job_labor_rate;
            }
          }
        }
      }
      else {
       
        for (let i = 0; i < this.labourRatesType.length; i++) {
          if (this.labourRatesType[i].labor_rate == recommendation.job_labor_rate) {
            this.tempLevel2Ary[recommendation.service_request_list_id]["select_costom_type"] = false;
            this.tempLevel2Ary[recommendation.service_request_list_id]["labour_type"] = recommendation.job_customer_type_id;
            this.tempLevel2Ary[recommendation.service_request_list_id]["job_customer_type_id"] = this.labourRatesType[i].id;
            this.tempLevel2Ary[recommendation.service_request_list_id]["job_labor_rate"] = this.labourRatesType[i].labor_rate;
            break;
          } else {
            this.tempLevel2Ary[recommendation.service_request_list_id]["labour_type"] = "0";
            this.tempLevel2Ary[recommendation.service_request_list_id]["select_costom_type"] = true;
            this.tempLevel2Ary[recommendation.service_request_list_id]['old_labour_rate'] = recommendation.job_labor_rate;
            this.tempLevel2Ary[recommendation.service_request_list_id]["job_customer_type_id"] = '0';
            this.tempLevel2Ary[recommendation.service_request_list_id]["job_labor_rate"] = recommendation.job_labor_rate;
          }
        }
      }
      this.tempLevel2Ary[recommendation.service_request_list_id]["totalNextPartLaborHours"] = 0;
      this.tempLevel2Ary[recommendation.service_request_list_id]["totalPartsTechLaborHours"] = 0;
      this.tempLevel2Ary[recommendation.service_request_list_id]['combinedPartsTechLaborNotes'] = [];
      let partData = recommendation.parts;
      if (partData.length > 0) {
        //process PartsTech labor data
        for (let i = 0; i < partData.length; i++) {
          if (partData[i].partstech_cart_item == 1 || partData[i].partstech_order_item == 1) {
            this.tempLevel2Ary[recommendation.service_request_list_id]["totalPartsTechLaborHours"] = parseFloat(this.tempLevel2Ary[recommendation.service_request_list_id]["totalPartsTechLaborHours"]) + parseFloat(partData[i].partstech_labor_time);
            this.tempLevel2Ary[recommendation.service_request_list_id]['combinedPartsTechLaborNotes'].push(partData[i].partstech_labor_notes);
          }
        }
      }
      let nextPartLabors = recommendation.nexpart_labor;
      if (nextPartLabors.length > 0) {
        //process nexpart labor data
        for (let j = 0; j < nextPartLabors.length; j++) {
          this.tempLevel2Ary[recommendation.service_request_list_id]["totalNextPartLaborHours"] = parseFloat(this.tempLevel2Ary[recommendation.service_request_list_id]["totalNextPartLaborHours"]) + parseFloat(nextPartLabors[j].hours);
        }
      }

     //end
       this.tempLevel2Ary[recommendation.service_request_list_id]["nexpart_labor_time"] = recommendation.nexpart_labor_time;

       this.tempLevel2Ary[recommendation.service_request_list_id]["nexpart_labor"] = recommendation.nexpart_labor;
      this.tempLevel2Ary[recommendation.service_request_list_id]["estimated_labor"] = recommendation.estimated_labor;
      this.tempLevel2Ary[recommendation.service_request_list_id]["estimated_time"] = recommendation.estimated_time;
      this.tempLevel2Ary[recommendation.service_request_list_id]["generic_jobs"] = {}
      this.tempLevel2Ary[recommendation.service_request_list_id]["computer_codes"] = {};
      this.tempLevel2Ary[recommendation.service_request_list_id]["rec"] = recommendation.recommendation;
      this.tempLevel2Ary[recommendation.service_request_list_id]["uploaded_images"] = recommendation.uploaded_images;
      this.tempLevel2Ary[recommendation.service_request_list_id]["uploaded_videos"] = recommendation.uploaded_videos;
      this.tempLevel2Ary[recommendation.service_request_list_id]["notes"] = recommendation.notes;
      this.tempLevel2Ary[recommendation.service_request_list_id]["recommendation"] = recommendation.recommendation;
      this.tempLevel2Ary[recommendation.service_request_list_id]["notes_check"] = recommendation.notes_check == "1" ? "on" : "off";
      this.tempLevel2Ary[recommendation.service_request_list_id]["rec_check"] = recommendation.recommendation_check == "1" ? "on" : "off";
      this.tempReports[child.id] = this.tempLevel2Ary;
    }
  }
  getGenericJobs() {
    let url:string = globalConstants.API_GET_GENERIC_JOBS_URL+ "?user_id=" + this.globalService.getCurrentUser()["id"];
    this.globalService.getAPI(url).map((data:any) => {
      if (data.result == "1") {
        this.allGenericJobs = data.data;
      }
    })
  }
  getDepth = function (object) {
    var level = 1;
    var key;
    for (key in object) {
      if (!object.hasOwnProperty(key)) continue;

      if (typeof object[key] == 'object') {
        var depth = this.getDepth(object[key]) + 1;
        level = Math.max(depth, level);
      }
    }
    return level;
  }
  show(obj) {
    // console.log(obj)
  }
  addRec(event:any, obj, obj_child, level_3_child) {
    if (level_3_child == undefined) {
      if (this.tempReports[obj.id][obj_child.id]["rec"] != undefined) {
        this.tempReports[obj.id][obj_child.id]["rec"] = event.target.value;
        this.tempReports[obj.id][obj_child.id]["recommendation"] = event.target.value;
      } else {
        this.tempReports[obj.id][obj_child.id] = {}
        this.tempReports[obj.id][obj_child.id]["rec"] = event.target.value;
        this.tempReports[obj.id][obj_child.id]["recommendation"] = event.target.value;
      }
    } else {
      if (this.tempReports[obj.id][obj_child.id][level_3_child.id] != undefined) {
        this.tempReports[obj.id][obj_child.id][level_3_child.id]["rec"] = event.target.value;
        this.tempReports[obj.id][obj_child.id][level_3_child.id]["recommendation"] = event.target.value;
      } else {
        this.tempReports[obj.id][obj_child.id][level_3_child.id] = {}
        this.tempReports[obj.id][obj_child.id][level_3_child.id]["rec"] = event.target.value;
        this.tempReports[obj.id][obj_child.id][level_3_child.id]["recommendation"] = event.target.value;
      }
    }
  }
  addNotes(event, obj, obj_child, level_3_child) {
    if (level_3_child == undefined) {
      if (this.tempReports[obj.id][obj_child.id]["notes"] != undefined) {
        this.tempReports[obj.id][obj_child.id]["notes"] = event.target.value;
      } else {
        this.tempReports[obj.id][obj_child.id] = {}
        this.tempReports[obj.id][obj_child.id]["notes"] = event.target.value;
      }
    } else {
      if (this.tempReports[obj.id][obj_child.id][level_3_child.id]["notes"] != undefined) {
        this.tempReports[obj.id][obj_child.id][level_3_child.id]["notes"] = event.target.value;
      } else {
        // this.tempReports[obj.id][obj_child.id][level_3_child.id] = {}
        this.tempReports[obj.id][obj_child.id][level_3_child.id]["notes"] = event.target.value;
      }
    }
  }

  addNexpartLabour(event, obj, obj_child, level_3_child) {
    let nexpartLabortimeVal = !isNaN(event) && event ? event : 0
    if (level_3_child == undefined) {
      if (this.tempReports[obj.id][obj_child.id]['labour_type'] == 0) {
        this.serviceRequest.value.labor_rate = this.tempReports[obj.id][obj_child.id]['old_labour_rate'];
      } else {
        this.labourRatesType.forEach(element => {
          if (element.id == this.tempReports[obj.id][obj_child.id]['labour_type']) {
            this.serviceRequest.value.labor_rate = element.labor_rate;
          }
        });
      }
      if (this.tempReports[obj.id][obj_child.id]["nexpart_labor_time"] != undefined) {

         this.tempReports[obj.id][obj_child.id]["nexpart_labor_time"] = nexpartLabortimeVal;
         var sum =  parseFloat(this.tempReports[obj.id][obj_child.id]["estimated_time"]) +  parseFloat(this.tempReports[obj.id][obj_child.id]["nexpart_labor_time"]) ;

         this.tempReports[obj.id][obj_child.id]["estimated_labor"] = (parseFloat(this.serviceRequest.value.labor_rate) * sum  ).toFixed(2);

         this.viewingJSON[obj_child.recommendations[0].id]["labour"] = {
              price:(parseFloat(this.serviceRequest.value.labor_rate) * sum ).toFixed(2),
           level:2,
           traverse: obj_child.id+"|"+ obj.id
         }
      } else {
        this.tempReports[obj.id][obj_child.id] = {}
        this.tempReports[obj.id][obj_child.id]["nexpart_labor_time"] = nexpartLabortimeVal;
        var sum =  parseFloat(this.tempReports[obj.id][obj_child.id]["estimated_time"]) +  parseFloat(this.tempReports[obj.id][obj_child.id]["nexpart_labor_time"]) ;

         this.tempReports[obj.id][obj_child.id]["estimated_labor"] = (parseFloat(this.serviceRequest.value.labor_rate) * sum  ).toFixed(2);

         this.viewingJSON[obj_child.recommendations[0].id]["labour"] = {
              price:(parseFloat(this.serviceRequest.value.labor_rate) * sum ).toFixed(2),
           level:2,
           traverse: obj_child.id+"|"+ obj.id
         }
      } 
    } else { 
      if(this.tempReports[obj.id][obj_child.id][level_3_child.id]['labour_type'] == 0){
        this.serviceRequest.value.labor_rate = this.tempReports[obj.id][obj_child.id][level_3_child.id]['old_labour_rate']
      }else{
        this.labourRatesType.forEach(element => {
          if(element.id == this.tempReports[obj.id][obj_child.id][level_3_child.id]['labour_type']){
            this.serviceRequest.value.labor_rate = element.labor_rate;
          }
      });
         
      }
      if (this.tempReports[obj.id][obj_child.id][level_3_child.id]["nexpart_labor_time"] != undefined) { 
        this.tempReports[obj.id][obj_child.id][level_3_child.id]["nexpart_labor_time"] = nexpartLabortimeVal;
          sum =  parseFloat(this.tempReports[obj.id][obj_child.id][level_3_child.id]["estimated_time"]) +  parseFloat(this.tempReports[obj.id][obj_child.id][level_3_child.id]["nexpart_labor_time"] ); 
         this.tempReports[obj.id][obj_child.id][level_3_child.id]["estimated_labor"] = (parseFloat(this.serviceRequest.value.labor_rate) * sum  ).toFixed(2);
 
         this.viewingJSON[level_3_child.recommendations[0].id]["labour"] = {
           price: (parseFloat(this.serviceRequest.value.labor_rate) * sum ).toFixed(2),
           level: 3,
           traverse:level_3_child.id+"|"+ obj_child.id+"|"+ obj.id
         }

      } else { 
        // this.tempReports[obj.id][obj_child.id][level_3_child.id] = {}
        this.tempReports[obj.id][obj_child.id]["nexpart_labor_time"] = nexpartLabortimeVal;


          sum =  parseFloat(this.tempReports[obj.id][obj_child.id]["estimated_time"]) + parseFloat( this.tempReports[obj.id][obj_child.id]["nexpart_labor_time"] );
         
         this.tempReports[obj.id][obj_child.id]["estimated_labor"] = (parseFloat(this.serviceRequest.value.labor_rate) * sum).toFixed(2);
         this.viewingJSON[obj_child.recommendations[0].id]["labour"] = {
           price:(parseFloat(this.serviceRequest.value.labor_rate) * sum ).toFixed(2),
           level:2,
           traverse: obj_child.id+"|"+ obj.id
         }
      } 
    }
    this.makeCalculations();
  }
  addTime(event, obj, obj_child, level_3_child) {
    if(parseInt(this.serviceRequest.value.labor_rate) > 0) {
      if((isNaN(parseFloat(event.target.value))==true) || (event.target.value=='')){
        event.target.value=0;
      }
       if (level_3_child != undefined) {
        if(this.tempReports[obj.id][obj_child.id][level_3_child.id]['labour_type'] == 0){
          this.serviceRequest.value.labor_rate = this.tempReports[obj.id][obj_child.id][level_3_child.id]['old_labour_rate'];
        }else{
          this.labourRatesType.forEach(element => {
            if(element.id == this.tempReports[obj.id][obj_child.id][level_3_child.id]['labour_type']){
              this.serviceRequest.value.labor_rate = element.labor_rate;
            }
        });
           
        }
         this.tempReports[obj.id][obj_child.id][level_3_child.id]["estimated_time"] = parseFloat(event.target.value);
        
         var sum =  parseFloat(this.tempReports[obj.id][obj_child.id][level_3_child.id]["estimated_time"]) + parseFloat(this.tempReports[obj.id][obj_child.id][level_3_child.id]["nexpart_labor_time"]) ;

         this.tempReports[obj.id][obj_child.id][level_3_child.id]["estimated_labor"] = (parseFloat(this.serviceRequest.value.labor_rate) *sum).toFixed(2);

         this.viewingJSON[level_3_child.recommendations[0].id]["labour"] = {
            price: (parseFloat(this.serviceRequest.value.labor_rate) *   sum ).toFixed(2),
           level: 3,
           traverse:level_3_child.id+"|"+ obj_child.id+"|"+ obj.id
         }
       } else {
        if(this.tempReports[obj.id][obj_child.id]['labour_type'] == 0){
          this.serviceRequest.value.labor_rate = this.tempReports[obj.id][obj_child.id]['old_labour_rate']
        }else{
          this.labourRatesType.forEach(element => {
            if(element.id == this.tempReports[obj.id][obj_child.id]['labour_type']){
              this.serviceRequest.value.labor_rate = element.labor_rate;
            }
        });
           
        }
         this.tempReports[obj.id][obj_child.id]["estimated_time"] = parseFloat(event.target.value);
        
           var sum =  parseFloat(this.tempReports[obj.id][obj_child.id]["estimated_time"]) +  parseFloat(this.tempReports[obj.id][obj_child.id]["nexpart_labor_time"]) ;

         this.tempReports[obj.id][obj_child.id]["estimated_labor"] = (parseFloat(this.serviceRequest.value.labor_rate) * sum).toFixed(2);

         this.viewingJSON[obj_child.recommendations[0].id]["labour"] = {
              price:(parseFloat(this.serviceRequest.value.labor_rate) * sum ).toFixed(2),
           level:2,
           traverse: obj_child.id+"|"+ obj.id
         }
       }
    }else{
      if((isNaN(parseFloat(event.target.value))==true) || (event.target.value=='')){
        event.target.value=0;
      }
      if (level_3_child != undefined) {
        this.tempReports[obj.id][obj_child.id][level_3_child.id]["estimated_time"] = parseFloat(event.target.value);
      }else {
        this.tempReports[obj.id][obj_child.id]["estimated_time"] = parseFloat(event.target.value);
      }
    }
    this.makeCalculations();
  }
  addLabor(event, obj, obj_child, level_3_child) {
    if(parseInt(this.serviceRequest.value.labor_rate) > 0) {
      if((isNaN(parseFloat(event.target.value))==true) || (event.target.value=='')){
        event.target.value=0;
      }
     if (level_3_child != undefined) {
      if(this.tempReports[obj.id][obj_child.id][level_3_child.id]['labour_type'] == 0){
        this.serviceRequest.value.labor_rate = this.tempReports[obj.id][obj_child.id][level_3_child.id]['old_labour_rate'];
      }else{
        this.labourRatesType.forEach(element => {
          if(element.id == this.tempReports[obj.id][obj_child.id][level_3_child.id]['labour_type']){
            this.serviceRequest.value.labor_rate = element.labor_rate;
          }
      });
         
      }
       this.tempReports[obj.id][obj_child.id][level_3_child.id]["estimated_labor"] = event.target.value;
      
       var total_minus =   ( parseFloat(this.tempReports[obj.id][obj_child.id][level_3_child.id]["estimated_labor"]) / parseFloat(this.serviceRequest.value.labor_rate)).toFixed(2)   ;
 

       this.tempReports[obj.id][obj_child.id][level_3_child.id]["estimated_time"] = (  parseFloat(total_minus) - parseFloat(this.tempReports[obj.id][obj_child.id][level_3_child.id]["nexpart_labor_time"])).toFixed(2);

      if(this.tempReports[obj.id][obj_child.id][level_3_child.id]["estimated_time"] < 0){
         
         var get_minus = (0-parseFloat(this.tempReports[obj.id][obj_child.id][level_3_child.id]["estimated_time"])).toFixed(2);

         this.tempReports[obj.id][obj_child.id][level_3_child.id]["nexpart_labor_time"] = (parseFloat(this.tempReports[obj.id][obj_child.id][level_3_child.id]["nexpart_labor_time"]) - parseFloat(get_minus)).toFixed(2);


          if(this.tempReports[obj.id][obj_child.id][level_3_child.id]["nexpart_labor_time"] < 0){
              this.tempReports[obj.id][obj_child.id][level_3_child.id]["nexpart_labor_time"] = 0;
          }

         this.tempReports[obj.id][obj_child.id][level_3_child.id]["estimated_time"] = 0;
      }  

       this.viewingJSON[level_3_child.recommendations[0].id]["labour"] = {
         price: parseFloat(this.tempReports[obj.id][obj_child.id][level_3_child.id]["estimated_labor"]).toFixed(2),
         level: 3,
         traverse:level_3_child.id+"|"+ obj_child.id+"|"+ obj.id
       }
     } else {
      if(this.tempReports[obj.id][obj_child.id]['labour_type'] == 0){
        this.serviceRequest.value.labor_rate = this.tempReports[obj.id][obj_child.id]['old_labour_rate'];
      }else{
        this.labourRatesType.forEach(element => {
          if(element.id == this.tempReports[obj.id][obj_child.id]['labour_type']){
            this.serviceRequest.value.labor_rate = element.labor_rate;
          }
      });  
      }
       this.tempReports[obj.id][obj_child.id]["estimated_labor"] = event.target.value;
       

        var total_minus =   ( parseFloat(this.tempReports[obj.id][obj_child.id]["estimated_labor"]) / parseFloat(this.serviceRequest.value.labor_rate)).toFixed(2)   ; 

       this.tempReports[obj.id][obj_child.id]["estimated_time"] =   (parseFloat(total_minus) - parseFloat(this.tempReports[obj.id][obj_child.id]["nexpart_labor_time"])).toFixed(2);


          if(this.tempReports[obj.id][obj_child.id]["estimated_time"] < 0){
         
         var get_minus = (0-parseFloat(this.tempReports[obj.id][obj_child.id]["estimated_time"])).toFixed(2);

         this.tempReports[obj.id][obj_child.id]["nexpart_labor_time"] = (parseFloat(this.tempReports[obj.id][obj_child.id]["nexpart_labor_time"]) - parseFloat(get_minus)).toFixed(2);


          if(this.tempReports[obj.id][obj_child.id]["nexpart_labor_time"] < 0){
              this.tempReports[obj.id][obj_child.id]["nexpart_labor_time"] = 0;
          }

         this.tempReports[obj.id][obj_child.id]["estimated_time"] = 0;
      }  


       this.viewingJSON[obj_child.recommendations[0].id]["labour"] = {
         price:(parseFloat(this.tempReports[obj.id][obj_child.id]["estimated_labor"])).toFixed(2),
         level:2,
         traverse: obj_child.id+"|"+ obj.id
       }
     }
    }else{
      if((isNaN(parseFloat(event.target.value))==true) || (event.target.value=='')){
        event.target.value=0;
      }
      if (level_3_child != undefined) {
        this.tempReports[obj.id][obj_child.id][level_3_child.id]["estimated_labor"] = event.target.value;
        this.viewingJSON[level_3_child.recommendations[0].id]["labour"] = {
          price: parseFloat(this.tempReports[obj.id][obj_child.id][level_3_child.id]["estimated_labor"]).toFixed(2),
          level: 3,
          traverse:level_3_child.id+"|"+ obj_child.id+"|"+ obj.id
        }
      }else{
        this.tempReports[obj.id][obj_child.id]["estimated_labor"] = event.target.value;
        this.viewingJSON[obj_child.recommendations[0].id]["labour"] = {
          price:(parseFloat(this.tempReports[obj.id][obj_child.id]["estimated_labor"])).toFixed(2),
          level:2,
          traverse: obj_child.id+"|"+ obj.id
        }
      }
    }
    this.makeCalculations();
  }
  add_rec_check(event, obj, obj_child, level_3_child) {
    if (level_3_child != undefined) {
      if (event.checked) {
        if (this.tempReports[obj.id][obj_child.id][level_3_child.id] != undefined) {
          this.tempReports[obj.id][obj_child.id][level_3_child.id]["rec_check"] = "on";
        } else {
          this.tempReports[obj.id][obj_child.id][level_3_child.id] = {}
          this.tempReports[obj.id][obj_child.id][level_3_child.id]["rec_check"] = "on";
        }
      } else {
        if (this.tempReports[obj.id][obj_child.id][level_3_child.id] != undefined) {
          this.tempReports[obj.id][obj_child.id][level_3_child.id]["rec_check"] = "off";
        } else {
          this.tempReports[obj.id][obj_child.id][level_3_child.id] = {}
          this.tempReports[obj.id][obj_child.id][level_3_child.id]["rec_check"] = "off";
        }
      }
    } else {
        if (event.checked) {
          this.tempReports[obj.id][obj_child.id]["rec_check"] = "on";
        } else {
          this.tempReports[obj.id][obj_child.id]["rec_check"] = "off";
        }
      }
  }
  add_notes_check(event, obj, obj_child, level_3_child) {
    if (level_3_child != undefined) {
      if (event.checked) {
        if (this.tempReports[obj.id][obj_child.id][level_3_child.id] != undefined) {
          this.tempReports[obj.id][obj_child.id][level_3_child.id]["notes_check"] = "on";
        } else {
          this.tempReports[obj.id][obj_child.id][level_3_child.id] = {}
          this.tempReports[obj.id][obj_child.id][level_3_child.id]["notes_check"] = "on";
        }

      } else {
        if (this.tempReports[obj.id][obj_child.id][level_3_child.id] != undefined) {
          this.tempReports[obj.id][obj_child.id][level_3_child.id]["notes_check"] = "off";
        } else {
          this.tempReports[obj.id][obj_child.id][level_3_child.id] = {}
          this.tempReports[obj.id][obj_child.id][level_3_child.id]["notes_check"] = "off";
        }

      }
    } else {
      if (event.checked) {
        this.tempReports[obj.id][obj_child.id]["notes_check"] = "on";
      } else {
        this.tempReports[obj.id][obj_child.id]["notes_check"] = "off";
      }
    }
  }
  sumArray(array){
    var sum = 0;
      if(array.length!= 0 && array!=undefined) {
        for(var i=0;i<array.length;i++){
          if(array[i]!=undefined && !Number.isNaN(array[i])) {
            sum = sum + array[i]
          }
        }
      }
      return sum
  }
  set_checkbox_as_checked(obj) {
    if (obj == "1" || obj == "on") {
      return true
    } else {
      return false
    }
  }
  filter(val: any): any[] {
    if (val.length != undefined) {
      // state.value.indexOf(lowercaseQuery) === 0
      var filteredValue = this.partsOptions.filter(option => option.label.toLowerCase().indexOf(val.toLowerCase()) === 0);
      // var filteredValue = this.partsOptions.filter(option => option.label.indexOf(val) === 0)
      return filteredValue
    }
  }
  expansionOpen(ele) { 

     this.jobOpenIndex = ele-1;
    this.globalService.accordionOpenAnimation(ele);
  }
  optionSelected(ele) {
    // console.log("ele", ele);
  }
  onlyNumericKey(event) {
    return globalFunctions.onlyDecimalNumberKey(event);
  }
  changeEstimatedDeliveryDate($event){
    // var value;
    // if((typeof $event !='undefined') && typeof $event._d=='undefined') {
    //   value = $event || $event._d;
    //   var c = new Date(value);
    //   c.setTime(c.getTime() + 30*60000);
    //   var f = new Date(c)
    //   var estimated_delivery_date = this.datePipe.transform(f, 'MM/dd/yyyy hh:mm a');
    //   this.serviceRequest.controls.estimated_delivery_date.setValue(estimated_delivery_date);
    //   this.serviceRequest.controls.estimatedDeliveryDate.setValue(estimated_delivery_date);
    // }
  }
  getSelectedOptionForGj(opt, immediateChild, level1Child) {
    if(typeof opt=="object") {
      (<HTMLInputElement>document.getElementById("new-gj-" + immediateChild.id + "-qty")).value = opt.quantity;
      if (immediateChild.level == "2") {
        this.genericJobsDropDownModel[immediateChild.id] = {
          traverse: immediateChild.id + "|" + level1Child.id,
          id: opt.id,
          name: opt.name,
          price: opt.price,
          quantity: opt.quantity,
          non_taxable: opt.non_taxable,
          level: 2
        }
      } else if (immediateChild.level == "3") {
        this.genericJobsDropDownModel[immediateChild.id] = {
          traverse: immediateChild.id + "|" + immediateChild.parent_id + "|" + level1Child.id,
          id: opt.id,
          name: opt.name,
          price: opt.price,
          quantity: opt.quantity,
          non_taxable: opt.non_taxable,
          level: 3
        }
      }
    }
  }
  setReturnFalse(){
    return false
  }
  displayFnforGj(optVal) {
    return optVal != null ? optVal.name : '';
  }
  displayFnforCC(val){
    return val != null ? val.fault_location : '';
  }
addNewGJ(event, lastestChild, recommendation, index) {
  var updatedQty = (<HTMLInputElement>document.getElementById("new-gj-" + lastestChild.id + "-qty")).value;
  if(updatedQty!="" && updatedQty!=undefined && parseFloat(updatedQty) > 0 && isNaN(Number(updatedQty))!= true && typeof this.genericJobsDropDownModel[lastestChild.id]!="undefined" && this.genericJobsDropDownModel[lastestChild.id]!="") {
    if(updatedQty.indexOf('.')!=-1){
      updatedQty=parseFloat(updatedQty).toString();
      if(updatedQty.indexOf('.')!=-1){
       updatedQty=updatedQty.substr(0,updatedQty.indexOf('.')) + updatedQty.substr(updatedQty.indexOf('.'), 4);
      }
    }
    this.genericJobsDropDownModel[lastestChild.id]["quantity"] = updatedQty;

    var isAlreadyPresentInNGJ = this.viewingJSON[recommendation.id]['NGJ'].
    find((x) => {
      return x.id == this.genericJobsDropDownModel[lastestChild.id]["id"]
    });

    var isAlreadyPresentInAGJ = this.viewingJSON[recommendation.id]['AGJ'].
    find((x) => {
      return x.id == this.genericJobsDropDownModel[lastestChild.id]["id"]
    });
    if (isAlreadyPresentInAGJ != undefined || isAlreadyPresentInNGJ != undefined) {
      // this.globalService.snackbar('error', " Generic Job already Exists");
  //     if (isAlreadyPresentInNGJ != undefined && isAlreadyPresentInNGJ.length == 0 && isAlreadyPresentInAGJ != undefined && isAlreadyPresentInAGJ.length == 0) {
  //       this.viewingJSON[recommendation.id]['NGJ'].push(this.genericJobsDropDownModel[lastestChild.id]);
  //     } else {
  //       //auto update object
  //       if (isAlreadyPresentInAGJ != undefined) {
  //       this.globalService.snackbar('error', "Parts already Exitists");
  //     } else {
  //       this.globalService.snackbar('success', "Parts Quantity Updated");
  //     }
  //   }
  // } else {
  this.globalService.snackbar('error', " Generic Job already Exists");
  } else {
    this.viewingJSON[recommendation.id]['NGJ'].push(this.genericJobsDropDownModel[lastestChild.id]);
    this.genericJobsDropDownModel[lastestChild.id] = "";
    // this.globalService.snackbar('success', "Generic Job added")
  }
  (<HTMLInputElement>document.getElementById("new-gj-" + lastestChild.id + "-ac")).value="";
  (<HTMLInputElement>document.getElementById("new-gj-" + lastestChild.id + "-qty")).value="";
  // console.log(this.viewingJSON[recommendation.id])
  this.makeCalculations()
  } else {
    if(this.genericJobsDropDownModel[lastestChild.id]== undefined || this.genericJobsDropDownModel[lastestChild.id]== ""){
      this.globalService.snackbar('error', "Please Enter Generic Job");
    }
    else if(parseFloat(updatedQty)<=0 || updatedQty == ""){
      this.globalService.snackbar('error', "Quantity should be greater than 0");
      }
      if(isNaN(Number(updatedQty)) == true) {
        this.globalService.snackbar('error', "Invalid Quantity");
      }
    }
  }

// part addition deletion & filter

getSelectedOption(opt, immediateChild, level1Child) {
  if(typeof opt == "object") {


    const price_array = [opt.part_matrix_status ? opt.part_matrix_price:0, opt.price ? opt.price:0]; 
    var value = Math.max(...price_array).toString() ;
    if(opt.part_matrix_status){
      if(this.price_set ==  1  ){
           value = price_array[0].toString(); 
      } 
       if(this.price_set ==  2  &&  opt.part_matrix_price<opt.price ){
           value = price_array[1].toString(); 
      } 
    }
    let p=(value);
    (<HTMLInputElement>document.getElementById("new-part-" + immediateChild.id + "-price")).value = p  ;
    (<HTMLInputElement>document.getElementById("new-part-" + immediateChild.id + "-qty")).value = opt.quantity;
  if (immediateChild.level == "2") {
    let partData:any= this.partStruct(opt);
    partData.traverse= immediateChild.id + "|" + level1Child.id;
    partData.part_price=parseFloat(p).toFixed(2);
    partData.partstech_labor_time = opt.partstech_labor_time;
    partData.partstech_labor_notes = opt.partstech_labor_notes;
    this.PartsDropDownModel[immediateChild.id] =partData;
  } else if (immediateChild.level == "3") {
    let partData:any= this.partStruct(opt);
    partData.traverse= immediateChild.id + "|" + immediateChild.parent_id + "|" + level1Child.id;
    partData.part_price=parseFloat(p).toFixed(2);
    partData.partstech_labor_time = opt.partstech_labor_time;
    partData.partstech_labor_notes = opt.partstech_labor_notes;
    partData.level=3;
    this.PartsDropDownModel[immediateChild.id] = partData;
  }
}

}
displayFnforParts(optVal) {
  return optVal != null ? optVal.label : '';
}
addNewPart(event, lastestChild, recommendation, index) { 
  var updatedQty = (<HTMLInputElement>document.getElementById("new-part-" + lastestChild.id + "-qty")).value;
  var updatedPrice = (<HTMLInputElement>document.getElementById("new-part-" + lastestChild.id + "-price")).value;
  updatedPrice=parseFloat(updatedPrice).toFixed(2);


  if(typeof this.PartsDropDownModel[lastestChild.id] == "object" && this.PartsDropDownModel[lastestChild.id]!=undefined && updatedQty!=undefined && updatedPrice!=undefined && updatedQty!=null && updatedPrice!=null && updatedQty!="" && updatedPrice!="" && parseFloat(updatedQty) > 0
  && isNaN(Number(updatedPrice))!= true && isNaN(parseFloat(updatedQty))!= true) {
    if(updatedQty.indexOf('.')!=-1){
      updatedQty=parseFloat(updatedQty).toString();
      if(updatedQty.indexOf('.')!=-1){
       updatedQty=updatedQty.substr(0,updatedQty.indexOf('.')) + updatedQty.substr(updatedQty.indexOf('.'), 4);
      }
    }
    this.PartsDropDownModel[lastestChild.id]["part_qty"] = updatedQty;
    this.PartsDropDownModel[lastestChild.id]["part_price"] = updatedPrice;
    this.PartsDropDownModel[lastestChild.id]["partstech"] = this.checkPartTech(this.PartsDropDownModel[lastestChild.id],recommendation.id);
    this.PartsDropDownModel[lastestChild.id]["nexpart"] = this.checkNexpart(this.PartsDropDownModel[lastestChild.id],recommendation.id);
    //first time    
    var isAlreadyPresentInNP = this.viewingJSON[recommendation.id]['NP'].
    find((x) => {
      return x.id == this.PartsDropDownModel[lastestChild.id]["id"]
    });

    var isAlreadyPresentInAP = this.viewingJSON[recommendation.id]['AP'].
    find((x) => {
      return x.id == this.PartsDropDownModel[lastestChild.id]["id"]
    });
    if (isAlreadyPresentInAP != undefined || isAlreadyPresentInNP != undefined) { // present in AP or NP
      this.globalService.snackbar('error', "Parts already Exists");
  } else {
    this.getReplacementCounts(this.PartsDropDownModel[lastestChild.id],recommendation);
    var currentSum:any = parseFloat(this.calcObj[recommendation.level1ParentId]).toFixed(2);
    currentSum = parseFloat(currentSum) + (parseFloat(this.PartsDropDownModel[lastestChild.id]["part_price"]) * parseFloat(this.PartsDropDownModel[lastestChild.id]["part_qty"]));
    this.calcObj[recommendation.level1ParentId] = parseFloat(currentSum).toFixed(2);
    // this.globalService.snackbar('success', "Parts added")
  }
  (<HTMLInputElement>document.getElementById("new-part-" + lastestChild.id + "-ac")).value = "";
  (<HTMLInputElement>document.getElementById("new-part-" + lastestChild.id + "-qty")).value = "";
  (<HTMLInputElement>document.getElementById("new-part-" + lastestChild.id + "-price")).value = "";
  this.makeCalculations();
  this.PartsDropDownModel[lastestChild.id] = "";
  // console.log(this.PartsDropDownModel)
    } else{
      if(this.PartsDropDownModel[lastestChild.id]==undefined){
        this.globalService.snackbar('error', "Please Enter Part")
      }
      else if(this.PartsDropDownModel[lastestChild.id]["id"]==undefined){
        this.globalService.snackbar('error', "Please Enter Part")
      }else if(isNaN(Number(updatedPrice)) == true) {
        this.globalService.snackbar('error', "Invalid Price")
      }
      else if((isNaN(Number(updatedQty)) == true)) {
        this.globalService.snackbar('error', "Invalid quantity")
      }
      else if((parseFloat(updatedQty) <= 0) || (updatedQty=='')) {
        this.globalService.snackbar('error', "Quantity should be greater than 0")
      } else {
        this.globalService.snackbar('error', "Please enter part")
      }
    }
    
  }
    /** remove parts */
  removePart(index, rec, partType,recommendation) {
    var rec_id = rec.id;
    if (partType == "AP") {
      var part_id = this.viewingJSON[rec_id]['AP'][index]["id"];
      if (rec.job_level == "3") {
        delete (this.tempReports[rec.level1ParentId][rec.level2ParentId][rec.service_request_list_id]["Parts"][part_id]);
        this.viewingJSON[rec_id]["AP"].splice(index, 1);
        this.viewingJSON[rec_id]["UAP"].splice(index,1);
        this.updatePartsTechTooltipInformation(rec_id,recommendation,parseInt(rec.job_level));
      } else {
        delete (this.tempReports[rec.level1ParentId][rec.service_request_list_id]["Parts"][part_id]);
        this.viewingJSON[rec_id]["AP"].splice(index, 1);
        this.viewingJSON[rec_id]["UAP"].splice(index,1);
        this.updatePartsTechTooltipInformation(rec_id,recommendation,parseInt(rec.job_level));       
      }
    } else if (partType == "NP") {
      this.viewingJSON[rec_id]['NP'].splice(index, 1);   
      this.viewingJSON[rec_id]["UNP"].splice(index,1);
      if (rec.job_level == "3") {
        this.updatePartsTechTooltipInformation(rec_id,recommendation,parseInt(rec.job_level));
      }else{
        this.updatePartsTechTooltipInformation(rec_id,recommendation,parseInt(rec.job_level));
      }
    }
    this.makeCalculations();
  }
  removeCC(index,rec,type){
    var rec_id = rec.id
    if (type == "ACC") {
      this.viewingJSON[rec_id]['ACC'].splice(index, 1)
      if (rec.job_level == "3") {

        delete (this.tempReports[rec.level1ParentId][rec.level2ParentId][rec.service_request_list_id]["generic_jobs"][this.viewingJSON[rec_id]['ACC']["id"]])
      } else {

        delete (this.tempReports[rec.level1ParentId][rec.service_request_list_id]["generic_jobs"][this.viewingJSON[rec_id]['ACC']["id"]])
      }
    } else if (type == "NCC") {
      this.viewingJSON[rec_id]['NCC'].splice(index, 1)
    }
  }
  removeGJ(index, rec, type) {
    var rec_id = rec.id
    if (type == "AGJ") {
      delete this.viewingJSON[rec_id]['AGJ'][index];
      // this.viewingJSON[rec_id]['AGJ'].splice(this.viewingJSON[rec_id]['AGJ'].indexOf(this.viewingJSON[rec_id]['AGJ'][index]), 1)
      this.viewingJSON[rec_id]['AGJ'].splice(index, 1)

    } else if (type == "NGJ") {
    delete(this.viewingJSON[rec_id]['NGJ'][index])
    this.viewingJSON[rec_id]['NGJ'].splice(index, 1)
  }
  this.makeCalculations()
  }

observableSource = (keyword: any): Observable<any[]> => {
  let url: string = globalConstants.API_GET_PARTS_DATA_URL+"?user_id="+this.globalService.getCurrentUser()["id"]+"&query="+keyword;
  if(this.showCostPrice){
    url+='&get_cost=1';
  }
  if (keyword && keyword.length>=3) {
  let f= [];
    return this.globalService.getAPI(url)
      .map((res:any) => {
        let json = res;
        this.globalService.authText(json);
        if(json!=null){
          json.forEach(function(x){
            x.value = x.label;
            x.quantity = 1
            f.push(x)
          });
        }
        return f
      })
  } else {
    return Observable.of([]);
  }
}

observableSourceforGJ = (keyword: any): Observable<any[]> => {
  let url: string = globalConstants.API_GET_GENERIC_JOBS_URL+"?from_v2=1&user_id="+this.globalService.getCurrentUser()["id"]+"&query="+keyword;
  if (keyword||1) {
    let f= [];
      return this.globalService.getAPI(url)
        .map((res:any) => {
          let json = res;
          let show=this.showCostPrice;
          this.globalService.authText(json);
          if(json!=null){  
            json.forEach(function(x){
              x.value = x.label;
              x.name = x.label;
              x.label+=(show?' '+x.price:'');
              x.quantity = 1;
              // x.non_taxable = 1;
              f.push(x)
            }
          );
          }
          return f
        })
    } else {
      return Observable.of([]);
    }
}
observableSourceforCC = (keyword: any): Observable<any[]> => {
  let url: string = globalConstants.API_GET_COMPUTER_CODES_URL+"?user_id="+this.globalService.getCurrentUser()["id"]+"&query="+keyword;
  if (keyword) {
  let f= [];
    return this.globalService.getAPI(url)
      .map(res => {
        let json = res;
        this.globalService.authText(json);
        if(json!=null){
          json.forEach(function(x){
            x.value = x.label;
            x.name = x.label;
            f.push(x)
          }
        );
        }
        return f
      })
  } else {
    return Observable.of([]);
  }
}
// codes for sending structure


prepareRecommendationFormData(type, coupon?:boolean, showPopup:boolean=false) {
  this.isFromCoupon = coupon;
  if(type == 1 || type == 0){
    let continueSaveRecommendation = (result?:any)=>{
      this.saveRecommendationConfirm= result.data;
      this.serviceRequest.controls.report.setValue(JSON.stringify(this.tempReports));
      this.serviceRequest.controls.is_labor_tax_excluded.setValue(this.isLaborTax?1:0);
      var getAllKeys = Object.keys(this.viewingJSON);
      var temparr = []
      for (var i = 0; i < getAllKeys.length; i++) {
        var tempPartObjToSend = []
        var total = this.viewingJSON[getAllKeys[i]]["AP"] + this.viewingJSON[getAllKeys[i]]["NP"];
        this.viewingJSON[getAllKeys[i]]["AP"].forEach(function (ap) {
          let forceOverride=0;
          if(typeof ap.force_override!='undefined'){
            forceOverride=ap.force_override
          }
          var sendingPartObj = ap.id + "|" + ap.part_price + "|" + ap.part_qty + "|" + ap.non_taxable+"|"+forceOverride;
          var traverse = ap.traverse;
          var l2Id;
          var l1Id;
          var l3Id;
          var d;
          if (ap.level == 3) {
            l1Id = ap.traverse.split("|")[2];
            l2Id = ap.traverse.split("|")[1];
            l3Id = ap.traverse.split("|")[0];
            d = {
              [l1Id]: {
                [l2Id]: {
                  [l3Id]:
                  {
                    "Parts": sendingPartObj,
                  }
                }
              }
            }
          } else if (ap.level == 2) {
            l1Id = ap.traverse.split("|")[1];
            l2Id = ap.traverse.split("|")[0];
            d = {
              [l1Id]: {
                [l2Id]: {
                  "Parts": sendingPartObj
                }
              }
            }
          }
          temparr.push(d);
          // this.serviceRequest.controls.report(temparr)
        });

        this.viewingJSON[getAllKeys[i]]["NP"].forEach(function (ap) {
          let forceOverride=0;
          if(typeof ap.force_override!='undefined'){
            forceOverride=ap.force_override
          }
          var sendingPartObj = ap.id + "|" + ap.part_price + "|" + ap.part_qty + "|" + ap.non_taxable+"|"+forceOverride;
          var traverse = ap.traverse;
          var l2Id;
          var l1Id;
          var l3Id;
          var d;
          if (ap.level == 3) {
            l1Id = ap.traverse.split("|")[2];
            l2Id = ap.traverse.split("|")[1];
            l3Id = ap.traverse.split("|")[0];
            d = {
              [l1Id]: {
                [l2Id]: {
                  [l3Id]:
                  {
                    "Parts": sendingPartObj,
                  }
                }
              }
            }
          } else if (ap.level == 2) {
            l1Id = ap.traverse.split("|")[1];
            l2Id = ap.traverse.split("|")[0];
            d = {
              [l1Id]: {
                [l2Id]: {
                  "Parts": sendingPartObj
                }
              }
            }
          }
          temparr.push(d);
        });

        temparr.forEach((part) => {
          var l1 = Object.keys(part)[0];
          Object.keys(part[Object.keys(part)[0]])
          var l2 = Object.keys(Object.keys(part))[0];
          var l3 = null;
          if (Object.keys(Object.keys(Object.keys(part)))) {
            //has level 3
            l3 = Object.keys(Object.keys(Object.keys(part)))[0]
          }
        })
      }
      // this.makeChangesInSendingObjectToAddNewParts(temparr,type);
      this.makeChangesInSendingObjectToAddNewParts(temparr,1);
    };
    if(!coupon){
      if(showPopup){
        let dialogRef = this.dialog.open(SaveRecommendationConfirmation, {
          panelClass: 'payment-dialog',
          data: {isCoupon: coupon, opr:this.couponOpr}
        });
        dialogRef.afterClosed().subscribe(result => {
          this.removeCouponControls();
          if(result && result.data != undefined){
            /** these line exec after snackbar save now button */
            continueSaveRecommendation(result);
          }
        });
      }
      else{
        this.removeCouponControls();
        continueSaveRecommendation({data: type});
      }
    }
    else{
      continueSaveRecommendation({ data: 0 });
    }
    
  }else{
    this.serviceRequest.controls.is_labor_tax_excluded.setValue(this.isLaborTax?1:0);
    this.serviceRequest.controls.report.setValue(JSON.stringify(this.tempReports));
    var getAllKeys = Object.keys(this.viewingJSON);
    var temparr = []
    for (var i = 0; i < getAllKeys.length; i++) {
      var tempPartObjToSend = []
      var total = this.viewingJSON[getAllKeys[i]]["AP"] + this.viewingJSON[getAllKeys[i]]["NP"];
      this.viewingJSON[getAllKeys[i]]["AP"].forEach(function (ap) {
        let forceOverride=0;
        if(typeof ap.force_override!='undefined'){
          forceOverride=ap.force_override
        }
        var sendingPartObj = ap.id + "|" + ap.part_price + "|" + ap.part_qty + "|" + ap.non_taxable+"|"+forceOverride;
        var traverse = ap.traverse;
        var l2Id;
        var l1Id;
        var l3Id;
        var d;
        if (ap.level == 3) {
          l1Id = ap.traverse.split("|")[2];
          l2Id = ap.traverse.split("|")[1];
          l3Id = ap.traverse.split("|")[0];
          d = {
            [l1Id]: {
              [l2Id]: {
                [l3Id]:
                {
                  "Parts": sendingPartObj,
                }
              }
            }
          }
        } else if (ap.level == 2) {
          l1Id = ap.traverse.split("|")[1];
          l2Id = ap.traverse.split("|")[0];
          d = {
            [l1Id]: {
              [l2Id]: {
                "Parts": sendingPartObj
              }
            }
          }
        }
        temparr.push(d);
        // this.serviceRequest.controls.report(temparr)
      });

      this.viewingJSON[getAllKeys[i]]["NP"].forEach(function (ap) {
        let forceOverride=0;
        if(typeof ap.force_override!='undefined'){
          forceOverride=ap.force_override
        }
        var sendingPartObj = ap.id + "|" + ap.part_price + "|" + ap.part_qty + "|" + ap.non_taxable+"|"+forceOverride;
        var traverse = ap.traverse;
        var l2Id;
        var l1Id;
        var l3Id;
        var d;
        if (ap.level == 3) {
          l1Id = ap.traverse.split("|")[2];
          l2Id = ap.traverse.split("|")[1];
          l3Id = ap.traverse.split("|")[0];
          d = {
            [l1Id]: {
              [l2Id]: {
                [l3Id]:
                {
                  "Parts": sendingPartObj,
                }
              }
            }
          }
        } else if (ap.level == 2) {
          l1Id = ap.traverse.split("|")[1];
          l2Id = ap.traverse.split("|")[0];
          d = {
            [l1Id]: {
              [l2Id]: {
                "Parts": sendingPartObj
              }
            }
          }
        }
        temparr.push(d);
      });

      temparr.forEach((part) => {
        var l1 = Object.keys(part)[0];
        Object.keys(part[Object.keys(part)[0]])
        var l2 = Object.keys(Object.keys(part))[0];
        var l3 = null;
        if (Object.keys(Object.keys(Object.keys(part)))) {
          //has level 3
          l3 = Object.keys(Object.keys(Object.keys(part)))[0]
        }
      })
    }
    this.makeChangesInSendingObjectToAddNewParts(temparr,type);
  }
}
makeChangesInSendingObjectToAddNewParts(newObject,type) {
  var existingObj;
  newObject.forEach(object => {
    existingObj = JSON.parse(this.serviceRequest.controls.report.value);
    if (this.getDepth(object) == 3) {
      var stringifiedData = JSON.stringify(object);
      var ids = stringifiedData.match(/\d+/g).map(Number).slice(0, 2);
      var partObj = JSON.stringify(object).split("Parts")[1].replace('}}}', '').replace(/"/g, "").replace(":", "");
      var l1 = ids[0];
      var l2 = ids[1];
      if (partObj != null) {
        existingObj[l1][l2]["Parts"][partObj.split('|')[0]] = partObj.split("|")[1] + "|" + partObj.split("|")[2] + "|" + partObj.split("|")[3] + "|" + partObj.split("|")[4];
      }
      this.serviceRequest.controls.report.setValue(JSON.stringify(existingObj));
    } else if (this.getDepth(object) == 4) {
      var stringifiedData = JSON.stringify(object);
      var ids = stringifiedData.match(/\d+/g).map(Number).slice(0, 3);
      var partObj = JSON.stringify(object).split("Parts")[1].replace('}}}}', '').replace(/"/g, "").replace(":", "");
      var l1 = ids[0];
      var l2 = ids[1];
      var l3 = ids[2];
      if (partObj != null) {
        existingObj[l1][l2][l3]["Parts"][partObj.split('|')[0]] = partObj.split("|")[1] + "|" + partObj.split("|")[2] + "|" + partObj.split("|")[3] + "|" + partObj.split("|")[4];
      }
      this.serviceRequest.controls.report.setValue(JSON.stringify(existingObj));
    }
  });
  this.makeChangesForGJ(type);
  // this.serviceRequest.controls.report.setValue(JSON.stringify(existingObj));
  }
makeChangesForGJ(type) {
  var tempArr = [];
  var keys = Object.keys(this.viewingJSON);
  for (var i = 0; i < keys.length; i++) {
    if (this.viewingJSON[keys[i]]["AGJ"].length > 0) {
      for (var j = 0; j < this.viewingJSON[keys[i]]["AGJ"].length; j++) {
        this.viewingJSON[keys[i]]["AGJ"][j]["from_agj"] = true;
        tempArr.push(this.viewingJSON[keys[i]]["AGJ"][j])
      }
    }
    if (this.viewingJSON[keys[i]]["NGJ"].length > 0) {
      for (var j = 0; j < this.viewingJSON[keys[i]]["NGJ"].length; j++) {
        tempArr.push(this.viewingJSON[keys[i]]["NGJ"][j]);
      }
    }
  }
  var existingObj = JSON.parse(this.serviceRequest.controls.report.value);
  for (var i = 0; i < tempArr.length; i++) {

    var jsonstructure = {
      "price": tempArr[i]["price"],
      "quantity": tempArr[i]["quantity"],
      "non_taxable": tempArr[i]["non_taxable"]
    }
    var pipeStructure = tempArr[i]["price"] + "|" + tempArr[i]["id"] + "|" + tempArr[i]["quantity"] + "|" +tempArr[i]["non_taxable"];
    if (tempArr[i].traverse.split("|").length == 3) {
      // l3
      var traverse = tempArr[i].traverse.split("|");
      var l1, l2, l3;
      l1 = traverse[2];
      l2 = traverse[1];
      l3 = traverse[0];

      if (tempArr[i]["from_agj"] != undefined) {
        // piping structure for existing jobs

        existingObj[l1][l2][l3]["generic_jobs"][tempArr[i]["generic_job_id"]] = new Array();
        existingObj[l1][l2][l3]["generic_jobs"][tempArr[i]["generic_job_id"]][0] = pipeStructure;
      } else {
        existingObj[l1][l2][l3]["generic_jobs"][tempArr[i]["id"]] =  jsonstructure;
        // existingObj[l1][l2][l3]["generic_jobs"][tempArr[i]["id"]][0] = jsonstructure;
      }

    } else if (tempArr[i].traverse.split("|").length == 2) {
      // l2
      var traverse = tempArr[i].traverse.split("|");
      var l1, l2;
      l1 = traverse[1];
      l2 = traverse[0];
      // existingObj[l1][l2]["generic_jobs"][tempArr[i]["id"]] = jsonstructure;

      if (tempArr[i]["from_agj"] != undefined) {
        // piping structure for existing jobs
        existingObj[l1][l2]["generic_jobs"][tempArr[i]["generic_job_id"]] = new Array();
        existingObj[l1][l2]["generic_jobs"][tempArr[i]["generic_job_id"]][0] = pipeStructure;
      } else {
        existingObj[l1][l2]["generic_jobs"][tempArr[i]["id"]] =  jsonstructure;
        // existingObj[l1][l2]["generic_jobs"][tempArr[i]["id"]][0] = jsonstructure;
      }
    }
  }
  this.serviceRequest.controls.report.setValue(JSON.stringify(existingObj));

  var l3ForCC = JSON.parse(this.serviceRequest.controls.report.value);
  var ccKeys = Object.keys(this.viewingJSON)
  for(var k=0;k<ccKeys.length;k++){
    if(this.viewingJSON[ccKeys[k]]["NCC"].length > 0) {
      for(var m=0;m<this.viewingJSON[ccKeys[k]]["NCC"].length;m++){
        var traverse = this.viewingJSON[ccKeys[k]]["NCC"][m]["traverse"];
        if(traverse.split("|").length == 2){
          var l2 = traverse.split("|")[0];
          var l1 = traverse.split("|")[1];
          l3ForCC[l1][l2]["computer_codes"][this.viewingJSON[ccKeys[k]]["NCC"][m]["id"]] = this.viewingJSON[ccKeys[k]]["NCC"][m]["id"]
        } else {
          var l3 = traverse.split("|")[0];
          var l2 = traverse.split("|")[1];
          var l1 = traverse.split("|")[2];
          l3ForCC[l1][l2][l3]["computer_codes"][this.viewingJSON[ccKeys[k]]["NCC"][m]["id"]] =  this.viewingJSON[ccKeys[k]]["NCC"][m]["id"]
        }
      }
    }
  }

  for(var k=0;k<ccKeys.length;k++){
    if(this.viewingJSON[ccKeys[k]]["ACC"].length > 0) {
      for(var m=0;m<this.viewingJSON[ccKeys[k]]["ACC"].length;m++){
        var traverse = this.viewingJSON[ccKeys[k]]["ACC"][m]["traverse"];
        if(traverse.split("|").length == 2){
          var l2 = traverse.split("|")[0];
          var l1 = traverse.split("|")[1];
          l3ForCC[l1][l2]["computer_codes"][this.viewingJSON[ccKeys[k]]["ACC"][m]["id"]] = this.viewingJSON[ccKeys[k]]["ACC"][m]["computer_code_id"]
        } else {
          var l3 = traverse.split("|")[0];
          var l2 = traverse.split("|")[1];
          var l1 = traverse.split("|")[2];
          l3ForCC[l1][l2][l3]["computer_codes"][this.viewingJSON[ccKeys[k]]["ACC"][m]["id"]] = this.viewingJSON[ccKeys[k]]["ACC"][m]["computer_code_id"]
        }
      }
    }
  }

  this.serviceRequest.controls.report.setValue(JSON.stringify(l3ForCC));
  this.serviceRequest.controls.tax_amount.setValue(this.totalTaxedValue)
  this.serviceRequest.controls.grand_total.setValue(this.grandTotal)
  this.serviceRequest.controls.non_taxable_amount.setValue(this.totalNonTaxableValue);
  this.serviceRequest.controls.supply_charges.setValue(this.supplyCharges);
}

/**
 * 0 = save 
 * 1 = close
 * 2 = send
 * 3 = complete
 * 4 = auto save
 * @param button action
 */
// handleConfirmationDailogs(type) { //revisit and remove that on code cleanup
//     if (type !== 4) { 
//       if(this.confirmPopup == false){ // by pass pop-up in nexPart dialog
//       let actionFlag = 0;
//       let confirmationScreenType = "yes"; // display type - yes/no
//       let note = "Are you sure you want to continue ?";
//       if(this.serviceInfo.is_walkin_customer == '1'){
//          if(this.serviceInfo.is_recommendation_saved == 0 && (type == 1 || type== 0)){
//             note = "SR is created for <b>Walkin Customer</b>. Do you want to change the customer profile or vehicle now?";
//             actionFlag = 1;
//             confirmationScreenType = 'select_walkin_customer';  // display type - skip/yes
//          } else if(type == 2 || type == 3 ){
//             note = "SR is created for <b>Walkin Customer</b>. Do you want to change the customer profile or vehicle now?";
//             actionFlag = 1;
//             confirmationScreenType = 'select_walkin_customer'; // display type - skip/yes
//          }
//       }
//       this.globalService.confirmDialog(confirmationScreenType, note).subscribe((res: any) => {
//         if (typeof (res) !== 'undefined') {
//           switch (actionFlag) {
//             case 1:
//               this.handleUpdateCustomerOrVehicleInfo(1);
//               break;
//             default:
//               this.sendFinalObject(type);
//           }
//         } else {
//           if (actionFlag == 1) {
//             this.sendFinalObject(type);
//           }
//         }
//       })
//     }else{
//       this.sendFinalObject(type);
//     }
//     } else {
//       this.sendFinalObject(type);
//     }
// }
// sendFinalObject(type) { //revisit and remove that on code cleanup
//   this.infoOther()
//   if(type == 1) {
//     this.serviceRequest.controls.requestCreationType.setValue(1);
//     let data=this.undefinedToBlank();
//     console.log('new data = ', data);
//     let fData=new FormData();
//     for(let i in data){
//       fData.append(i,data[i]);
//     }  
//       this.openPopup(fData);
//   } else if(type == 2 || type == 3) {
//     this.serviceRequest.controls.requestCreationType.setValue(type);
//     let data=this.undefinedToBlank();
//     let fData=new FormData();
//     for(let i in data){
//       fData.append(i,data[i]);
//     }

//     let note='Are you sure to continue?';
//     if(!this.techinianStatusDone){
//       fData.append('force_override','1');
//       note='<center><b>All timers will stop</b></center> <center>Are you sure the technician is done with this service and you want to send updated information to customer?</center> ';
//     }else{
//       fData.append('force_override','0');
//     };
//     if(type==3){
//       this.approvalDiagParams = {requestId: this.serviceRequestId,car_id : this.serviceInfo.car_id,customer_id: this.serviceInfo.customer_id};
//       let conf = 'Are you sure to continue?';
//       note=
//       '<center><b>Are You Sure: </b></center> <center>The technician(s) work is completed on this service request? <br><span class="milesout-popup"><i>This action stops all timers immediately!</i></span><br/> <b>Completing This Action:</b></center><center>Mark this service request as complete and generates the invoice.</center>';
//       if(this.VehicleInspectionReport.length == 0 && fData.get('no_report_performed') != 'true'){
//         let ask = "Inspection report is not performed on this vehicle. Do you want to submit the report now ?";
//         this.globalService.confirmDialog("ask_vehicle_ins", ask).subscribe((insDiagRes) => {
//           let insDiagResult: any = insDiagRes;
//          this.confirmText = insDiagResult ; 
//           switch(insDiagResult){
//             case 'no':
//             case 'close':
//               break;
//             case 'skip':
//               this.autoSaveRecom().subscribe(res => {
//                 if(res){
//                   fData.set('no_report_performed','true');
//                   this.getJobsApproval(note,fData);
//                 }
//               })
//               break;
//             case 'yes':
//               this.autoSaveRecom().subscribe(res => {
//                 if(res){
//                   if(this.serviceInfo.is_usps_vehicle == '1'){
//                     this.openInsceptionReport(3,{'note':note,'form_data':fData});
//                   }else{
//                     this.openVehicleInscpectionReport(3,{'note':note,'form_data':fData});
//                   }
//                 }
//               })
//               break;
//           }
//         });
//       }else{
//         conf = "Would you like to Save & Complete the Service Request ?";
//         this.globalService.confirmDialog("forceCompleteTicket", conf).subscribe((r:any)=>{
//           let res: string | undefined = r; 
//           if(res == 'forceCompleteTicket'){
//             //save recommendation first
//             this.autoSaveRecom().subscribe(result => {
//               if(result) this.getJobsApproval(note,fData);
//             })
//           }
//         });
//       }
//     }else{
//           this.globalService.formData(globalConstants.API_SAVE_RECOMMENDATION_URL, fData).subscribe((response:any) => {
//             if (response.result == "1") {
//               this.globalService.snackbar("success", response.message);
//               // For Code 518 - to handle invalid coupon codes
//               // if(response.code == 518  && response.data.discounts_list){
//               //   console.log('inavlid coupons detected');
//               //   this.fillRecommendationCoupon.reInitialize(response.data);
//               //   this.fillRecommendationCoupon.reset();
//               // }
//               this.router.navigate(["/shops/view-service-request/" + this.serviceRequest.value.id])
//             } else {
//               if(response.code == 352 ) {
//               let msg = response.message;
//               this.globalService.snackbar('error', msg ,{ component:this,method :'reinit',param:1},true);
//               }else{
//                 if(response.access_timestamp){
//                   this.serviceRequest.controls.access_timestamp.setValue(response.access_timestamp);
//                   this.accessTimestamp = response.access_timestamp;
//                 }
//                 // this.globalService.snackbar("error", response.message);
//                 if(response.code == 166 || response.code == 167){           
//                   let ask = "Inspection report is not performed on this vehicle. Do you want to submit the report now ?";
//                   this.globalService.confirmDialog("ask_vehicle_ins", ask).subscribe((insDiagRes) => {
//                     let insDiagResult: any = insDiagRes;
//                     this.customerUpdateFormData = fData;
//                     switch(insDiagResult){
//                       case 'no':
//                       case 'close':
//                         break;
//                       case 'skip':
//                         this.customerUpdateFormData.set('no_report_performed','true');
//                         this.submitCustomerUpdateForm();
//                         break;
//                       case 'yes':
//                         if(this.serviceInfo.is_usps_vehicle == '1'){
//                           this.openInsceptionReport(2);
//                         }else{
//                           this.openVehicleInscpectionReport(2);
//                         }
//                         break;
//                     }
//                   });
//                 }else{
//                   this.globalService.snackbar('error', response.message);
//                 }
//               }
//             }
//           })
//    }
//   }else if(type==4){
//     this.autoSaveRecom().subscribe(res=>{});
//   }
// }

autoSaveRecom():Observable<boolean>{
  return new Observable<boolean>((subscriber:Subscriber<boolean>) => {
    this.prepareRecommendationFormData(4);
    this.infoOther();
    this.serviceRequest.controls.requestCreationType.setValue(1);
    let saveData=this.undefinedToBlank();
    let saveForm = new FormData();
    for(let i in saveData){
      saveForm.append(i,saveData[i]);
    }
      this.saveRecommendationAPI(saveForm).subscribe((response: any) => {  
      let oprStatus = false;
      if(response.result == "1") {
        this.serviceRequest.controls.access_timestamp.setValue(response.data.access_timestamp);
        this.accessTimestamp = response.access_timestamp;
        this.globalService.snackbar("success", response.message);
        if(this.isPartstechOrderView){
          this.getDataForOrderNow(2);
        }
        if(this.isNextPartOrderView){
          this.getDataForOrderNowNexpart(2);
        }
        this.reinit();
        // is from coupon
        if(this.isFromCoupon && response.data.discounts_list){
          this.fillRecommendationCoupon.reInitialize(response.data);
          this.fillRecommendationCoupon.reset();
          this.removeCouponControls();
        }
        // For Code 518 - to handle invalid coupon codes
        if(response.code == 518  && response.data.discounts_list){
          console.log('inavlid coupons detected');
          this.fillRecommendationCoupon.reInitialize(response.data);
          this.fillRecommendationCoupon.reset();
        }
        this.removeCouponControls();
        this.serviceRequest.controls.recommendation_saved.setValue(1);
        oprStatus = true
      } else {
        if(response.access_timestamp){
          this.serviceRequest.controls.access_timestamp.setValue(response.access_timestamp);
          this.accessTimestamp = response.access_timestamp;
        }
        if(response.code == 590  && response.data.discounts_list){
          console.log('for inapplicable coupons detected');
          this.fillRecommendationCoupon.reInitialize(response.data);
        }
        this.fillRecommendationCoupon.reset();
        this.removeCouponControls();
        if(response.result == "0" || response.result == "352" ) {
            let msg = response.message;
            this.globalService.snackbar('error', msg ,{ component:this,method :'reinit',param:1},true);
        }else{
            this.globalService.snackbar("error", response.message); 
        }
      }
      subscriber.next(oprStatus)
    });
  })
}

submitCustomerUpdateForm(){
  // console.log("this is cusomerUpdateForm",this.customerUpdateFormData);
  this.saveRecommendationAPI(this.customerUpdateFormData).subscribe((response: any) => {
    if (response.result == "1") {
      this.globalService.snackbar("success", response.message);
      this.router.navigate(["/shops/view-service-request/" + this.serviceRequest.value.id])
    } else {
      if(response.access_timestamp){
        this.serviceRequest.controls.access_timestamp.setValue(response.access_timestamp);
        this.accessTimestamp = response.access_timestamp;
        this.reinit();
      }
      this.globalService.snackbar("error", response.message);
    }
  });
}





videoShow(val): void {
  let dialogRef = this.dialog.open(VideoShowComponent, {
    panelClass: 'car-dialog-form',
    width: "800px",
    data: {name: val, url : globalConstants.S3_BUCKET_URL + globalConstants.RECOMMEDATION_VIDEO}
  });

  dialogRef.afterClosed().subscribe(result => {

  });
}


videoShowMain(val): void {
  console.log(val);
  let dialogRef = this.dialog.open(VideoShowComponent, {
    panelClass: 'car-dialog-form',
    width: "800px",
    data: {name: val, url : globalConstants.S3_BUCKET_URL + globalConstants.WALKAROUND_VIDEO}
  });

  dialogRef.afterClosed().subscribe(result => {

  });
}
cleanArray(actual) {
  var newArray = new Array();
  for (var i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }
  return newArray;
}
printInvoice(){
  /* var content ="user_type=User&user_id="+this.globalService.getCurrentUser()["id"]+"&customer_id="+this.serviceRequest.controls.customer_id.value+"&car_id="+this.serviceRequest.controls.car_id.value+"&service_request_id="+this.serviceRequest.controls.id.value;
  this.globalService.callAPI(globalConstants.API_CREATE_ESTIMATE_URL,content).subscribe(function(response){
    window.open(globalConstants.S3_BUCKET_URL + globalConstants.SERVICE_INVOICE +response.data.url)
  }) */

  let url = globalConstants.API_CREATE_ESTIMATE_URL;
  let data={
    "service_request_id":this.serviceRequest.controls.id.value,
    "user_type":this.globalService.getCurrentUser()["user_type"],
    "customer_id":this.serviceRequest.controls.customer_id.value,
    "user_id":this.globalService.getCurrentUser()["id"],
    "car_id":this.serviceRequest.controls.car_id.value,
    "invoke_download":1
  };
  let skipFirst:boolean = true;
  for(let d in data){
    //console.log(d,data[d]);
    if(skipFirst == true){
      url += "?"+d+"="+data[d];
      skipFirst = false;
    }else{
      url += "&"+d+"="+data[d];
    }
  }
  
  let serviceRequestId = this.serviceInfo.request_id;
  this.globalService.downloadFileWithGet(url,serviceRequestId);
   // these code for set the value for complete mark when response going in applicaltion/json  and change the button on top but now no need this code
    // this.serviceInfo.controls.request_status.setValue('6');
    // this.cdr.markForCheck();
}

printTechSheet(){
  /* var content ="user_type=User&user_id="+this.globalService.getCurrentUser()["id"]+"&customer_id="+this.serviceRequest.controls.customer_id.value+"&car_id="+this.serviceRequest.controls.car_id.value+"&service_request_id="+this.serviceRequest.controls.id.value;
  this.globalService.callAPI(globalConstants.API_CREATE_ESTIMATE_URL,content).subscribe(function(response){
    window.open(globalConstants.S3_BUCKET_URL + globalConstants.SERVICE_INVOICE +response.data.url)
  }) */

  let url = globalConstants.API_TECHNICIAN_SHEET_URL;
  let data={
    "service_request_id":this.serviceRequest.controls.id.value,
    "user_type":this.globalService.getCurrentUser()["user_type"],
    "customer_id":this.serviceRequest.controls.customer_id.value,
    "user_id":this.globalService.getCurrentUser()["id"],
    "car_id":this.serviceRequest.controls.car_id.value,
    "invoke_download":1
  };
  let skipFirst:boolean = true;
  for(let d in data){
    //console.log(d,data[d]);
    if(skipFirst == true){
      url += "?"+d+"="+data[d];
      skipFirst = false;
    }else{
      url += "&"+d+"="+data[d];
    }
  }
  let serviceRequestId = this.serviceInfo.request_id;
  this.globalService.downloadFileWithGet(url,serviceRequestId);
   // these code for set the value for complete mark when response going in applicaltion/json  and change the button on top but now no need this code
  // this.serviceInfo.controls.request_status.setValue('6');
  // this.cdr.markForCheck();
}

invokeDownload(url,type,fileName=''){
  let localFileName = 'DownloadedFile',localNameExt,localName;
  if(type == 'ServiceRequestDocument'){
    localName = fileName.split('.');
    localNameExt = localName[localName.length-1];
    localFileName = 'ServiceRequestDocument.'+localNameExt;
  }else if(type == 'CARInspectionReport'){
    localFileName = 'CARInspectionReport.pdf';
  }
  //console.log(localFileName);
  this.globalService.downloadAsset(url,localFileName);
}

imageToolDialog(current,i,type,imgaar): void {
  let dialogRef = this.dialog.open(ImagetooldialogComponent, {
      panelClass: ['imageToolDialog'],
      width: "100%",
      data: {event: current,index:i,imgType:type,imgArray:imgaar,customerId:this.globalService.getCurrentUser()["id"]}
  });
  dialogRef.afterClosed().subscribe(result => {
    //console.log('The dialog was closed');
    if(type == 1){
      this.vehicleTimeStamp = new Date().getTime();
    }else if(type == 5){
      this.vinTimeStamp = new Date().getTime();
    }
});
}

  addCustomLabels() {
    this.showLabelsDropdown = false;
    for (let index = 0; index < this.customLabels.length; index++) {
      this.allLabels.push(this.customLabels[index]);
    }
    this.allLabels = this.globalService.sortLabelsListInAlphabeticallyOrder(this.allLabels);
    if (this.serviceRequest.value.on_the_lot == true) {
      this.preSelectLabel.push('OTL');
    }
    if (this.serviceRequest.value.parts_awaited == true) {
      this.preSelectLabel.push('WAITING_FOR_PARTS');
    }
    this.showLabelsDropdown = true;
  }
/**
   * updateOnTheLotStatus
   */
public updateOnTheLotStatus(value) {
  this.showLabelsDropdown = false;
  let checked: any = value.checked == true ? 1:0;
  this.globalService.callAPI(globalConstants.API_UPDATE_ON_THE_LOT_STATUS, "request_id=" + this.serviceRequestId + "&user_id=" + this.globalService.getCurrentUser()["id"] + "&on_the_lot=" + checked + "&user_type=User").subscribe((response: any) => {
    if (response.result == 1) {
      if (checked == 1) {
        this.preSelectLabel.push('OTL');
        this.showLabelsDropdown = true;
      } else {
        this.removeCustomLabelInPreSelectLabel('OTL');
        this.showLabelsDropdown = true;
      }
      this.globalService.snackbar("success", response.message);
    }
  })
}

/*******Trigger Dialog************/

openInsceptionReport(opr = 1,extraParams = null): void {
  this.invokeInspectionReportPopup.openPopup = false;
  let reportData;
  if (this.VehicleInspectionReport.length) {
    reportData = this.VehicleInspectionReport[0].usps_report_data;
  }
  let dialogRef = this.dialog.open(UspsVehicleInspectionReportComponent, {
    panelClass: ['car-dialog-form', 'inscpection-dialog'],
    data: { service_request_id: this.serviceRequestId, report_data: reportData,disableFormInputControls:0, fullRequestId: this.carRequestId}
  });

  dialogRef.afterClosed().subscribe(result => {
    if (result !== '' && typeof (result) !== 'undefined' && result !== null) {
      this.serviceRequest.controls.access_timestamp.setValue(result.access_timestamp);
      this.accessTimestamp = result.access_timestamp;
      let data = this.undefinedToBlank();
      let payload = new FormData();
      for (let i in data) {
        payload.append(i, data[i]);
      }
      this.customerUpdateFormData = payload;
    }
    if(opr == 1){
      this.updateReportData();
    }else if(opr == 2){
      if(result == 'success'){
        this.submitCustomerUpdateForm();
      }
    }else{
      if(result == 'success'){
        this.requestCreationType = null;
        this.getJobsApproval(extraParams.note,extraParams.form_data,true);
      }
    }
    if (typeof (result.status) !== 'undefined') {
      if (this.requestCreationType == 2) { //send SR
        this.requestCreationType = null;
        this.submitCustomerUpdateForm();
      } else if (this.requestCreationType == 3) { //compelete SR
        this.autoSaveRecom().subscribe((res: any) => {
          this.requestCreationType = null;
          this.getJobsApproval(extraParams.note, extraParams.form_data, true);
        });
      }
    } else {  //close inspection report 
      this.requestCreationType = null;
      this.autoSaveRecom().subscribe((res:any)=>{});
    }
  });
}
openVehicleInscpectionReport(opr = 1,extraParams = null): void {
  this.invokeInspectionReportPopup.openPopup = false;
  let reportData;
  if (this.VehicleInspectionReport.length) {
    reportData = this.VehicleInspectionReport[0].acdelco_report_data;
  }
  let dialogRef = this.dialog.open(VehicleInspectionReportComponent, {
    panelClass: ['car-dialog-form', 'inscpection-dialog'],
    data: { service_request_id: this.serviceRequestId, report_data: reportData,disableFormInputControls:0, fullRequestId: this.carRequestId }
  });
  dialogRef.afterClosed().subscribe(result => {
    if(result !== '' && typeof(result) !== 'undefined' && result !== null){
    this.serviceRequest.controls.access_timestamp.setValue(result.access_timestamp);
    this.accessTimestamp = result.access_timestamp;
    let data = this.undefinedToBlank();
    let payload = new FormData();
    for (let i in data) {
      payload.append(i, data[i]);
    }
    this.customerUpdateFormData = payload;
    }
    if(opr == 1){
      this.updateReportData();
    }else if(opr == 2){
      if(result == 'success'){
        this.submitCustomerUpdateForm();
      }
    }else{
      if(result == 'success'){
        this.requestCreationType = null;
        this.getJobsApproval(extraParams.note,extraParams.form_data,true);
      }
    }
    if (typeof (result.status) !== 'undefined') {
      if (this.requestCreationType == 2) { //send SR 
        this.requestCreationType = null;
        this.submitCustomerUpdateForm();
      } else if (this.requestCreationType == 3) { //complete SR 
        this.autoSaveRecom().subscribe((res: any) => {
          this.requestCreationType = null;
          this.getJobsApproval(extraParams.note, extraParams.form_data, true);
        });
      }
    } else { //close inspection report 
      this.requestCreationType = null;
      this.autoSaveRecom().subscribe((res:any)=>{});
    }
  });
}
downloadUspsReport() {
  let downloadUSPSReport_URL = '';
  let reportData;
  let qryStringUrl = "";
  if (this.VehicleInspectionReport.length) {
    reportData = this.VehicleInspectionReport[0].acdelco_report_data;
    if (reportData) {
      downloadUSPSReport_URL = globalConstants.API_DOWNLOAD_INSPECTION_REPORT_ACDELCO_URL;
      qryStringUrl += "?report_type=2";
      qryStringUrl += "&acdelco_report_id=" + reportData.id;
    } else {
      downloadUSPSReport_URL = globalConstants.API_DOWNLOAD_INSPECTION_REPORT_USPS_URL;
      reportData = this.VehicleInspectionReport[0].usps_report_data;
      qryStringUrl += "?report_type=1";
      qryStringUrl += "&usps_report_id=" + reportData.id;
    }
    this.reportDownloadLink = downloadUSPSReport_URL + qryStringUrl;
  } else {
    this.reportDownloadLink = 'javascript:void(0);';
  }
}
updateReportData() {
  let content = "user_id=" + this.globalService.getCurrentUser()["id"] + "&user_type=" + this.globalService.getCurrentUser()["user_type"] + "&service_request_id=" + this.serviceRequestId;
  this.globalService.callAPI(globalConstants.API_GET_SERVICE_REQUEST_VIEW_URL, content).subscribe((response:any) => {
    if (response.result == 1) {
      this.serviceInfo = response.data;
      this.accessTimestamp = response.data.access_timestamp;
      this.VehicleInspectionReport = this.serviceInfo.inspection_reports;
      this.downloadUspsReport();
    }
  });
}

updateCustomerVehicleData() {
  let content = "user_id=" + this.globalService.getCurrentUser()["id"] + "&user_type=" + this.globalService.getCurrentUser()["user_type"] + "&service_request_id=" + this.serviceRequestId;
  this.globalService.callAPI(globalConstants.API_GET_SERVICE_REQUEST_VIEW_URL, content).subscribe((response:any) => {
    if (response.result == 1) {
      this.serviceInfo = response.data;
      this.serviceRequest.controls.vehicle.setValue(this.globalService.getGlobalVehicleName(this.serviceInfo));
      this.serviceRequest.controls.license_plate.setValue(this.serviceInfo.license_plate);
      this.serviceRequest.controls.vin_number.setValue(this.serviceInfo.vin);
      this.serviceRequest.controls.customer_name.setValue(this.serviceInfo.customer_name);
      this.serviceRequest.controls.requested_date.setValue(this.globalService.getFullFormateDate(this.serviceInfo.requested_delivery_date,'G','H'));
      this.serviceRequest.controls.mobile_number.setValue(this.serviceInfo.mobile_phone);
      this.serviceRequest.controls.mileage.setValue(this.serviceInfo.present_mileage);
    }
  });
}

addNewPartOpen(rec,level,l1Id,l2Id,l3Id): void {
  var data;
  let dialogRef;
  dialogRef = this.dialog.open(addNewParts, {
    panelClass: 'car-dialog-form',
    data: { recommendation:rec,ref:this,level:level,l1Id:l1Id,l2Id:l2Id,l3Id:l3Id,newParts:[] },
  });

  dialogRef.afterClosed().subscribe(result => {
    for(var i=0;i<dialogRef.newParts.length;i++) {
      var newPartData = dialogRef.newParts[i];

      const price_array = [this.partMatrixStatus ? newPartData.part_matrix_price:0, newPartData.price ? newPartData.price:0]; 
 
      var value = Math.max(...price_array).toString() ; 

      if(this.partMatrixStatus){
         if(this.price_set ==  1  ){
           value = price_array[0].toString(); 
          } 

         if(this.price_set ==  2  &&  newPartData.part_matrix_price<newPartData.price  ){
             value = price_array[1].toString(); 
        } 
      }


      let p=(this.partMatrixStatus ? value : newPartData.price);
      if(newPartData.add_to_job) {
        if(level == 2){
          this.viewingJSON[rec.id]["NP"].push({
            traverse: l2Id + "|" + l1Id,
            id: newPartData.id,
            part_name: newPartData.part_number +(this.showCostPrice?" ("+newPartData.cost+") ":'')+newPartData.name+' '+newPartData.description,
            part_price: p,
            part_qty: newPartData.quantity,
            non_taxable: newPartData.non_taxable,
            description:newPartData.description,
            partstech:0,
            partstech_order:0,
            nexpart:0,
            nexpart_order:0,
            level: 2,
            stock_quantity: newPartData.stock_quantity,
            low_stock_value: newPartData.low_stock_value,
            quantity_overused:newPartData.quantity_overused,
            part_matrix_price:newPartData.part_matrix_price,
            inventory_price:newPartData.price,
            suggestion_parts_count:newPartData.suggestion_parts_count,
            force_override:0,
            partstech_labor_time:0,
            partstech_labor_notes:[]
          });
          this.viewingJSON[rec.id]['UNP'].push({
            traverse: l2Id + "|" + l1Id,
            id: newPartData.id,
            part_name: newPartData.part_number+(this.showCostPrice?" ("+newPartData.cost+") ":' ')+newPartData.name+' '+newPartData.description,
            part_price: p,
            part_qty: newPartData.quantity,
            non_taxable: newPartData.non_taxable,
            level: 2,
            partstech_order:0,
            is_editable:false,
            is_disabled:false,
            partstech:0,
            nexpart:0,
            nexpart_order:0,
            stock_quantity: newPartData.stock_quantity,
            low_stock_value: newPartData.low_stock_value,
            quantity_overused:newPartData.quantity_overused,
            part_matrix_price:newPartData.part_matrix_price,
            inventory_price:newPartData.price,
            suggestion_parts_count:newPartData.suggestion_parts_count,
            force_override:0,
            partstech_labor_time:0,
            partstech_labor_notes:[]
          })

        } else if(level == 3) {
          this.viewingJSON[rec.id]["NP"].push({
            traverse: l3Id+"|"+l2Id + "|" + l1Id,
            id: newPartData.id,
            part_name:newPartData.part_number+(this.showCostPrice?"("+newPartData.cost+")":' ')+newPartData.name+' '+newPartData.description,
            part_price: p,
            part_qty: newPartData.quantity,
            non_taxable: newPartData.non_taxable,
            description:newPartData.description,
            partstech:0,
            partstech_order:0,
            nexpart:0,
            nexpart_order:0,
            level: 3,
            stock_quantity: newPartData.stock_quantity,
            low_stock_value: newPartData.low_stock_value,
            quantity_overused: newPartData.quantity_overused,
            part_matrix_price:newPartData.part_matrix_price,
            inventory_price:newPartData.price,
            suggestion_parts_count:newPartData.suggestion_parts_count,
            force_override:0,
            partstech_labor_time:0,
            partstech_labor_notes:[]
          });
          this.viewingJSON[rec.id]['UNP'].push({
          traverse: l3Id+"|"+l2Id + "|" + l1Id,
            id: newPartData.id,
            part_name:newPartData.part_number+(this.showCostPrice?"("+newPartData.cost+")":' ')+newPartData.name+' '+newPartData.description,
            part_price: p,
            part_qty: newPartData.quantity,
            non_taxable: newPartData.non_taxable,
            level: 3,
            partstech_order:0,
            is_editable:false,
            is_disabled:false,
            partstech:0,
            nexpart:0,
            nexpart_order:0,
            stock_quantity: newPartData.stock_quantity,
            low_stock_value: newPartData.low_stock_value,
            quantity_overused : newPartData.quantity_overused,
            part_matrix_price:newPartData.part_matrix_price,
            inventory_price:newPartData.price,
            suggestion_parts_count:newPartData.suggestion_parts_count,
            force_override:0,
            partstech_labor_time:0,
            partstech_labor_notes:[]
          })
        }
      }
    }
    this.makeCalculations();
    this.cdr.markForCheck();
  });
}

addNewJob(rec,level,l1Id,l2Id,l3Id): void {
  let dialogRef;
  dialogRef = this.dialog.open(addNewJob, {
    panelClass: 'car-dialog-form',
    data: { recommendation: rec,newGJ:[]}
  });

  dialogRef.afterClosed().subscribe(result => {

    for(var i=0;i<dialogRef.newGJ.length;i++) {
      var newGJData = dialogRef.newGJ[i];
      if(newGJData.add_to_job) {
        if(level == 2){
          this.viewingJSON[rec.id]["NGJ"].push({
            traverse: l2Id + "|" + l1Id,
            id:newGJData.id,
            name: newGJData.name,
            price: newGJData.price,
            quantity: newGJData.quantity,
            non_taxable: newGJData.non_taxable,
            level: 2
          });
        } else if(level == 3) {
          this.viewingJSON[rec.id]["NGJ"].push({
            traverse: l3Id+"|"+l2Id + "|" + l1Id,
            id:newGJData.id,
            name: newGJData.name,
            price: newGJData.price,
            quantity: newGJData.quantity,
            non_taxable: newGJData.non_taxable,
            level: 3
          })
        }
      }
    }
    this.makeCalculations();
    this.cdr.markForCheck();
  });
}
addNewComputerCode(rec,level,l1Id,l2Id,l3Id): void {
  let dialogRef ;
  dialogRef = this.dialog.open(addNewComputerCode, {
    panelClass: 'car-dialog-form',
    data: { recommendation: rec,newCC:[]}
  });

  dialogRef.afterClosed().subscribe(result => {

    for(var i=0;i<dialogRef.newCC.length;i++) {
      var newCCData = dialogRef.newCC[i];
      if(newCCData.add_to_job) {
        if(level == 2){
          this.viewingJSON[rec.id]["NCC"].push({
            traverse: l2Id + "|" + l1Id,
            id:newCCData.id,
            fault_location:newCCData.fault_location,
            trouble_code:newCCData.trouble_code
          });
        } else if(level == 3) {
          this.viewingJSON[rec.id]["NCC"].push({
            traverse: l3Id+"|"+l2Id + "|" + l1Id,
            id:newCCData.id,
            fault_location:newCCData.fault_location,
            trouble_code:newCCData.trouble_code
          })
        }
      }
    }
    this.cdr.markForCheck();
  });
}
emissionInspection(): void {
  let dialogRef = this.dialog.open(EmissionsInspectionComponent, {
    panelClass: 'emissionInspection-dialog',
    width: "1100px",
    data: { service_request_id: this.serviceRequestId,status:this.serviceInfo.request_status }
  });

  dialogRef.afterClosed().subscribe(result => {

  });
}
partech(l1,l2,l3, recom,levelOneObj, level2ndObj, level3rdObj) {
    let indx=0;
    if(l3==null){
      indx=l2;
    }else{
      indx=l3;
    }
    let keyWords=(<HTMLInputElement>document.getElementById("new-part-" +indx+ "-ac")).value;
    let dialogRef;
    this.partechSession[indx]={};
    this.partechSession[indx].vin=this.serviceInfo.vin;
    this.partechSession[indx].request_id= this.serviceRequestId;
    this.partechSession[indx].level1_id = l1;
    this.partechSession[indx].level2_id = l2;
    this.partechSession[indx].level3_id = (l3==null?'':l3);
    this.partechSession[indx].keyword=keyWords;
    this.partechSession[indx].recom_id=recom.id;
    this.partechSession[indx].user_id = this.globalService.getCurrentUser()["id"];
    this.partechSession[indx].user_type = this.globalService.getCurrentUser()["user_type"];
    this.globalService.callAPI(globalConstants.API_GET_PARTSTECH_URL, this.partechSession[indx]).subscribe((response:any) => {
      if (response.result == 1) {
        this.partechSession[indx].redirect_url =response.data.redirect_url;
        this.partechSession[indx].session_id =response.data.session_id;
        dialogRef = this.dialog.open(VideoShowComponent, {
          panelClass: 'partechDialog',
          width: "100%",
          data: {'iframe':this.partechSession[indx].redirect_url,'ref':this.partechSession[indx],isPartsTechConfigured:this.serviceInfo.is_partstech_configured}
        });
        dialogRef.afterClosed().subscribe(result => {
          dialogRef['ref'].get_cost=1;
          let th=this;
          setTimeout(()=>{
            th.getPartstechData(dialogRef,levelOneObj, level2ndObj, level3rdObj);
            th.cdr.detectChanges();
          },1000);
        });
      }
    });
  
  }
  
  nexpart(l1,l2,l3, recom) {

    let indx=0;
    if(l3==null){
      indx=l2;
    }else{
      indx=l3;
    }
    this.indx = indx;
    let keyWords=(<HTMLInputElement>document.getElementById("new-part-" +indx+ "-ac")).value;
    let dialogRef;
    this.nexpartSession[indx]={};
    // this.nexpartSession[indx].keyword=keyWords;
    this.nexpartSession[indx].vin=this.serviceInfo.vin;
    this.nexpartSession[indx].level1_id = l1;
    this.nexpartSession[indx].level2_id = l2;
    this.nexpartSession[indx].level3_id = (l3==null?'':l3);
    this.nexpartSession[indx].recom_id=recom.id;
    this.nexpartSession[indx].request_id= this.serviceRequestId;
    this.nexpartSession[indx].job_id = (l3 != null ? l3 : l2);
    this.nexpartSession[indx].search=keyWords;
    this.nexpartSession[indx].vendor_id=this.selectedVendor.id;
    this.nexpartSession[indx].user_id = this.globalService.getCurrentUser()["id"];
    this.nexpartSession[indx].user_type = this.globalService.getCurrentUser()["user_type"];
    this.globalService.callAPI(globalConstants.API_GET_NEXLINK_URL, this.nexpartSession[indx]).subscribe((response:any) => {
      if (response.result == 1) {
        this.nexpartSession[indx].redirect_url =response.data.session_url;
        this.nexpartSession[indx].session_id =response.data.access_token;
        this.nexpartSession[indx].cart_id = response.data.cart_id;
        // dialogRef = this.dialog.open(VideoShowComponent, {
        //   panelClass: 'partechDialog',
        //   width: "100%",
        //   data: {'iframe':this.nexpartSession[indx].redirect_url,'ref':this.nexpartSession[indx]}
        // });
        // dialogRef.afterClosed().subscribe(result => {
        //   dialogRef['ref'].get_cost=1;
        //   let th=this;
        //   setTimeout(()=>{
        //     th.getNexpartData(dialogRef);
        //     th.cdr.detectChanges();
        //   },1000);
        // });


        var nexpartSession = this.nexpartSession[indx] ; 
        var url = this.nexpartSession[indx].redirect_url;  
        this.confirmPopup = true;
        var savedata =  this.openNexpartWindow(0) ; //save recommendation first
        this.confirmPopup = false;  
        // var interval = setInterval(function(ctx) { 
         // if(ctx.confirmText == 'yes'){ 
                // clearInterval(interval);  
            // this.child = window.open(url , 'Nexpart', "toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width="+screen.availWidth+",height="+screen.availHeight); 

         
          }
          else{
            // this.globalService.snackbar('error', response.message);
          }
        // }
      // this.getNexpartData1(nexpartSession);
    });
  
  }
 getNexpartData1(dialogRef) {
    // console.log('123');
    let data = {
              request_id: this.serviceRequestId,
              user_id: dialogRef.user_id,
              job_id: dialogRef.job_id,
              cart_id: dialogRef.cart_id
    }
    this.globalService.callAPI(globalConstants.API_GET_NEXLINK_CART_URL, data).subscribe((response:any) => {
    // console.log('12345');
      if (response.is_cart_modified == "1" || response.is_cart_ordered == 1) {
        this.partMatrixStatus=(response.part_matrix_status==1 ? true : false);
        this.addUpdateNexpart(dialogRef, response.data, response.is_cart_ordered);
        this.cdr.detectChanges();
      } else if (response.result == '0' ){
        this.globalService.snackbar("error", response.message);
      }
      // else if (response.result == '0' && response.code == "449") {
      //   this.globalService.snackbar("error", response.message);
      //   let th = this;
      //   if (this.nexpartLoadCount <= 3) {
      //     setTimeout(() => {
      //       th.nexpartLoadCount += 1;
      //       th.getNexpartData(dialogRef);
      //     }, 1000)
      //   }else{
      //     this.nexpartLoadCount=1;
      //   }
      // }
    },(error)=>{});
  }

  addUpdatePartech(ref,data,levelOneObj,level2ndObj,level3rdObj){
    let level=0;
    if(ref.level3_id==''){
      level=2;
    }else{
      level=3;
    }
    let th=this;
    let rec = { job_level: level, id: ref.recom_id, level1ParentId: ref.level1_id, level2ParentId: ref.level2_id, service_request_list_id: ref.level3_id };
    if(level==2){
      rec.service_request_list_id=ref.level2_id;
    }
    let founded=[];
    if(data.length){
    let preOrder=[];
    for(var i=0;i<data.length;i++) {
      var newPartData = data[i];
      if(newPartData) {
        var inNP = this.viewingJSON[ref.recom_id]["NP"].filter(function(x,v) {
          if (x.id == newPartData["id"]) {
            th.updateApNp(v,rec,'NP',newPartData);
            founded.push(x.id);
            return true;
          }
          return false;
      } );
        var inAP = this.viewingJSON[ref.recom_id]["AP"].filter(function(x,v) {
          if (x.id == newPartData["id"] && x.partstech_order==0) {
            th.updateApNp(v,rec,'AP',newPartData);
            founded.push(x.id);
            return true;
          }
          if(x.id == newPartData["id"] && x.partstech_order==1){
            preOrder.push(x);
            return true;
          }
          return false;
        } );
        /* */
        if((inAP.length == 0 && inNP.length == 0) || (inAP == undefined && inNP == undefined)) {
          founded.push(newPartData.id);

          const price_array = [this.partMatrixStatus ? newPartData.part_matrix_price:0, newPartData.price ? newPartData.price:0]; 
          var value = Math.max(...price_array).toString() ; 
          if(this.partMatrixStatus){
            if(this.price_set ==  1  ){
              value = price_array[0].toString(); 
            } 
            if(this.price_set ==  2   &&  newPartData.part_matrix_price<newPartData.price ){
              value = price_array[1].toString(); 
            } 
          }
          let p=(this.partMatrixStatus ? value : newPartData.price);
          let partData:any= this.partStruct(newPartData);
          partData.part_price=parseFloat(p).toFixed(2);
          partData.part_qty=newPartData.part_quantity;
          partData.partstech=1;
          partData.partstech_price=newPartData.partstech_price;
          partData.partstech_labor_time = newPartData.partstech_labor_time;
          partData.partstech_labor_notes =  newPartData.partstech_labor_notes;
          if(level == 2){
            if(this.serviceInfo.partstech_labor == 1){
               this.updatePartsTechLaborInJob(ref,rec,newPartData,levelOneObj,level2ndObj,level3rdObj);
            }
            partData.level=2
            partData.traverse= ref.level2_id + "|" + ref.level1_id
            this.viewingJSON[ref.recom_id]["NP"].push(partData);
            this.viewingJSON[ref.recom_id]['UNP'].push(partData);
          } else if(level == 3) {
            if(this.serviceInfo.partstech_labor == 1){
                this.updatePartsTechLaborInJob(ref,rec,newPartData,levelOneObj,level2ndObj,level3rdObj);
            }
            partData.level=3
            partData.traverse= ref.level3_id+"|"+ref.level2_id + "|" + ref.level1_id
            this.viewingJSON[ref.recom_id]["NP"].push(partData);         
            this.viewingJSON[ref.recom_id]['UNP'].push(partData)
          }

        } else {

        }
        if(this.serviceInfo.partstech_labor == 1){
         if(level == 3){
           this.updatePartsTechTooltipInformation(ref.recom_id,rec,3)
         }else{
           this.updatePartsTechTooltipInformation(ref.recom_id,rec,2);
        }
      }
      }
    }
    if(preOrder.length){
      this.globalService.snackbar("error",'One or more parts added on PartsTech are already added in previous order');
    }
    this.removePartstechData(rec,founded);
  }else{
    this.removePartstechData(rec,[]);
  }
    this.makeCalculations();
  }

checkPrefixSetting(){
     if(this.PTLaborPrefixSetting == 1){
         return '[PT]: ';
     }else{
         return '';
     }
}
  updatePartsTechLaborInJob(ref, rec, partsData, levelOneObj, level2ndObj, level3rdObj) {
    let partsTechPreFix = "\n"+this.checkPrefixSetting();
    if (level3rdObj == null) { //level 2 work
      if(this.PTLaborSettingTypeDOW !== 0){
      if (partsData.partstech_labor_notes.length > 0) {
        let descOfWork  = this.getPTLaborNotesAsPerSettings(partsData,'DOW');
        let combinePartsTechLaborNotes = descOfWork.join("\n");
        if (this.tempReports[rec.level1ParentId][rec.level2ParentId]['rec'] == '' && this.tempReports[rec.level1ParentId][rec.level2ParentId]['recommendation'] == '') {
              partsTechPreFix = this.checkPrefixSetting();
        }
          this.tempReports[rec.level1ParentId][rec.level2ParentId]['rec'] = this.tempReports[rec.level1ParentId][rec.level2ParentId]['rec'] + partsTechPreFix + combinePartsTechLaborNotes;
          this.tempReports[rec.level1ParentId][rec.level2ParentId]['recommendation'] = this.tempReports[rec.level1ParentId][rec.level2ParentId]['recommendation'] + partsTechPreFix + combinePartsTechLaborNotes;
      }
    }
      this.tempReports[rec.level1ParentId][rec.level2ParentId]['partstech_labor_time'] = partsData.partstech_labor_time; //revisit
      let revisedNXTPTLaborTimeTotal = parseFloat(this.tempReports[rec.level1ParentId][rec.level2ParentId]['nexpart_labor_time']) + parseFloat(partsData.partstech_labor_time);
      this.addNexpartLabour(revisedNXTPTLaborTimeTotal, levelOneObj, level2ndObj, level3rdObj);
    } else { //level 3 work
      if(this.PTLaborSettingTypeDOW !== 0){
      if (partsData.partstech_labor_notes.length > 0) {
        let descOfWork  = this.getPTLaborNotesAsPerSettings(partsData,'DOW');
        let combinePartsTechLaborNotes = descOfWork.join('\n');
        if (this.tempReports[rec.level1ParentId][rec.level2ParentId][rec.service_request_list_id]['rec'] == '' && this.tempReports[rec.level1ParentId][rec.level2ParentId][rec.service_request_list_id]['recommendation'] == '') {
          partsTechPreFix = this.checkPrefixSetting();
        }
          this.tempReports[rec.level1ParentId][rec.level2ParentId][rec.service_request_list_id]['rec'] = this.tempReports[rec.level1ParentId][rec.level2ParentId][rec.service_request_list_id]['rec'] + partsTechPreFix + combinePartsTechLaborNotes;
          this.tempReports[rec.level1ParentId][rec.level2ParentId][rec.service_request_list_id]['recommendation'] = this.tempReports[rec.level1ParentId][rec.level2ParentId][rec.service_request_list_id]['recommendation'] + partsTechPreFix+ combinePartsTechLaborNotes;
      }
    }
      this.tempReports[rec.level1ParentId][rec.level2ParentId][rec.service_request_list_id]['partstech_labor_time'] = partsData.partstech_labor_time; //revisit
      let revisedNXTPTLaborTimeTotal = parseFloat(this.tempReports[rec.level1ParentId][rec.level2ParentId][rec.service_request_list_id]['nexpart_labor_time']) + parseFloat(partsData.partstech_labor_time);
      this.addNexpartLabour(revisedNXTPTLaborTimeTotal, levelOneObj, level2ndObj, level3rdObj);
    }
  }


  getPTLaborNotesAsPerSettings(partsData,type) {
    let settingType = type == 'DOW'? this.PTLaborSettingTypeDOW : this.PTLaborSettingTypeTooltip;
    let laborNotes = [];
    for (let i = 0; i < partsData.partstech_labor_notes.length; i++) {
      if (settingType == 3) {
        laborNotes.push(partsData.partstech_labor_notes[i].title_and_notes);
      } else if (settingType == 2) {
        laborNotes.push(partsData.partstech_labor_notes[i].notes);
      } else if (settingType == 1) {
        laborNotes.push(partsData.partstech_labor_notes[i].title);
      }
    }
    return laborNotes;
  }

 
  updatePartsTechTooltipInformation(partLevelid, rec, jobLevel) {
    let APPartList = this.viewingJSON[partLevelid]["AP"];
    let NPPartList = this.viewingJSON[partLevelid]["NP"];
    if (jobLevel == 3) {  //level 3 for partstech labor
      this.tempReports[rec.level1ParentId][rec.level2ParentId][rec.service_request_list_id]['totalPartsTechLaborHours'] = 0;
      this.tempReports[rec.level1ParentId][rec.level2ParentId][rec.service_request_list_id]['combinedPartsTechLaborNotes'] = [];
      for (let i = 0; i < APPartList.length; i++) {
        if (APPartList[i].partstech == 1) {
          this.tempReports[rec.level1ParentId][rec.level2ParentId][rec.service_request_list_id]['combinedPartsTechLaborNotes'].push(APPartList[i].partstech_labor_notes);
          this.tempReports[rec.level1ParentId][rec.level2ParentId][rec.service_request_list_id]['totalPartsTechLaborHours'] = parseFloat(this.tempReports[rec.level1ParentId][rec.level2ParentId][rec.service_request_list_id]['totalPartsTechLaborHours']) + parseFloat(APPartList[i].partstech_labor_time);
        } else if (APPartList[i].nexpart == 1) {
          this.tempReports[rec.level1ParentId][rec.level2ParentId][rec.service_request_list_id]['totalNextPartLaborHours'] = parseFloat(this.tempReports[rec.level1ParentId][rec.level2ParentId][rec.service_request_list_id]['totalNextPartLaborHours']) + 0;
        }
      }

      for (let i = 0; i < NPPartList.length; i++) {
        if (NPPartList[i].partstech == 1) {
          this.tempReports[rec.level1ParentId][rec.level2ParentId][rec.service_request_list_id]['combinedPartsTechLaborNotes'].push(NPPartList[i].partstech_labor_notes);
          this.tempReports[rec.level1ParentId][rec.level2ParentId][rec.service_request_list_id]['totalPartsTechLaborHours'] = parseFloat(this.tempReports[rec.level1ParentId][rec.level2ParentId][rec.service_request_list_id]['totalPartsTechLaborHours']) + parseFloat(NPPartList[i].partstech_labor_time);
        } else if (NPPartList[i].nexpart == 1) {
          this.tempReports[rec.level1ParentId][rec.level2ParentId][rec.service_request_list_id]['totalNextPartLaborHours'] = parseFloat(this.tempReports[rec.level1ParentId][rec.level2ParentId][rec.service_request_list_id]['totalNextPartLaborHours']) + 0;
        }
      }
    } else { //level 2nd wrok for parttech labor
      this.tempReports[rec.level1ParentId][rec.level2ParentId]['totalPartsTechLaborHours'] = 0;
      this.tempReports[rec.level1ParentId][rec.level2ParentId]['combinedPartsTechLaborNotes'] = [];
      for (let i = 0; i < APPartList.length; i++) {
        if (APPartList[i].partstech == 1) {
          this.tempReports[rec.level1ParentId][rec.level2ParentId]['combinedPartsTechLaborNotes'].push(APPartList[i].partstech_labor_notes);
          this.tempReports[rec.level1ParentId][rec.level2ParentId]['totalPartsTechLaborHours'] = parseFloat(this.tempReports[rec.level1ParentId][rec.level2ParentId]['totalPartsTechLaborHours']) + parseFloat(APPartList[i].partstech_labor_time);
        } else if (APPartList[i].nexpart == 1) {
          this.tempReports[rec.level1ParentId][rec.level2ParentId]['totalNextPartLaborHours'] = parseFloat(this.tempReports[rec.level1ParentId][rec.level2ParentId]['totalNextPartLaborHours']) + 0;
        }
      }

      for (let i = 0; i < NPPartList.length; i++) {
        if (NPPartList[i].partstech == 1) {
          this.tempReports[rec.level1ParentId][rec.level2ParentId]['combinedPartsTechLaborNotes'].push(NPPartList[i].partstech_labor_notes);
          this.tempReports[rec.level1ParentId][rec.level2ParentId]['totalPartsTechLaborHours'] = parseFloat(this.tempReports[rec.level1ParentId][rec.level2ParentId]['totalPartsTechLaborHours']) + parseFloat(NPPartList[i].partstech_labor_time);
        } else if (NPPartList[i].nexpart == 1) {
          this.tempReports[rec.level1ParentId][rec.level2ParentId]['totalNextPartLaborHours'] = parseFloat(this.tempReports[rec.level1ParentId][rec.level2ParentId]['totalNextPartLaborHours']) + 0;
        }
      }
    }

  }


  getPartToolTipTitle(partData: any) {
    if (this.serviceInfo.partstech_labor == 1) {
      let tooltipLabor = '';
      if (partData.partstech == 1 || partData.partstech_order == 1) {
        tooltipLabor = "Labor: " + parseFloat(partData.partstech_labor_time).toFixed(2) + " Hrs";
      } else if (parseFloat(partData.partstech_labor_time) > 0) {
        tooltipLabor = "Labor: " + partData.partstech_labor_time + " Hrs";
      } else if (partData.partstech_labor_time == '' || parseFloat(partData.partstech_labor_time) == 0) {
        tooltipLabor = "Labor: N/A";
      }
      if (this.PTLaborSettingTypeTooltip !== 0) {
        let laborData = "\nNotes: " + (partData.partstech_labor_notes.length > 0 ? this.getPTLaborNotesAsPerSettings(partData, 'Tooltip').join("\n") : 'N/A');
        return tooltipLabor + laborData;
      } else {
        return tooltipLabor;
      }
    }
  } 

  addUpdateNexpart(ref,data, isOrdered = 0){
    let level=0;
    if(ref.level3_id==''){
      level=2;
    }else{
      level=3;
    }
    let th=this;
    let rec = { job_level: level, id: ref.recom_id, level1ParentId: ref.level1_id, level2ParentId: ref.level2_id, service_request_list_id: ref.level3_id };
    if(level==2){
      rec.service_request_list_id=ref.level2_id;
    }
    let founded=[];
    if(data.length){
    let preOrder=[];
    for(var i=0;i<data.length;i++) {
      var newPartData = data[i];
      newPartData.nexpart_order = isOrdered;
      if(newPartData) {
        var inNP = this.viewingJSON[ref.recom_id]["NP"].filter(function(x,v) {
          if (x.id == newPartData["id"]) {
            th.updateApNpNexpart(v,rec,'NP',newPartData);
            founded.push(x.id);
            return true;
          }
          return false;
        });
        var inAP = this.viewingJSON[ref.recom_id]["AP"].filter(function(x,v) {
          if (x.id == newPartData["id"] && x.nexpart_order==0) {
            th.updateApNpNexpart(v,rec,'AP',newPartData);
            founded.push(x.id);
            return true;
          }
          if(x.id == newPartData["id"] && x.nexpart_order==1){
            preOrder.push(x);
            return true;
          }
          return false;
        } );
        /* */
        if((inAP.length == 0 && inNP.length == 0) || (inAP == undefined && inNP == undefined)) {
          founded.push(newPartData.id);

      const price_array = [this.partMatrixStatus ? newPartData.part_matrix_price:0, newPartData.price ? newPartData.price:0];  
      var value = Math.max(...price_array).toString() ;  
      if(this.partMatrixStatus){
        if(this.price_set ==  1  ){
          value = price_array[0].toString(); 
        } 
        if(this.price_set ==  2  &&  newPartData.part_matrix_price<newPartData.price  ){
            value = price_array[1].toString(); 
        } 
      }
      let p=(this.partMatrixStatus ? value : newPartData.price);

          // let p=(this.partMatrixStatus ? newPartData.part_matrix_price : newPartData.price)
          let partData:any= this.partStructNexpart(newPartData);
          partData.part_price=parseFloat(p).toFixed(2)
          partData.part_qty=newPartData.part_quantity
          partData.nexpart=1;
          partData.nexpart_order=newPartData.nexpart_order;
          partData.partstech_price=newPartData.partstech_price;
          if(level == 2){
            partData.level=2
            partData.traverse= ref.level2_id + "|" + ref.level1_id
            this.viewingJSON[ref.recom_id]["NP"].push(partData);
            this.viewingJSON[ref.recom_id]['UNP'].push(partData)
          } else if(level == 3) {
            partData.level=3
            partData.traverse= ref.level3_id+"|"+ref.level2_id + "|" + ref.level1_id
            this.viewingJSON[ref.recom_id]["NP"].push(partData);         
            this.viewingJSON[ref.recom_id]['UNP'].push(partData)
          }

        } else {

        }
      }
    }
    if(preOrder.length){
      this.globalService.snackbar("error",'One or more parts added on Nexpart are already added in previous order');
    }
    this.removeNexpartData(rec,founded);
  }else{
    this.removeNexpartData(rec,[]);
  }
    this.makeCalculations();
  }
  
  
AddAutoOrder(e){
 if(e.checked){
  this.serviceRequest.controls.is_auto_order.setValue(1);
 }else{
  this.serviceRequest.controls.is_auto_order.setValue(0);
 }
}

checkPartTech(part,recommId) {
  let serviceRequest = this.serviceRequestList;
  let applyChanges=false;
  for (let i in serviceRequest) {
    for (let j in serviceRequest[i].children) {
      if (part.level==3 && typeof serviceRequest[i].children[j].children!='undefined' && serviceRequest[i].children[j].children.length > 0) {
        for (let k in serviceRequest[i].children[j].children) {
          for (let l in serviceRequest[i].children[j].children[k].recommendations) {
            let parts=serviceRequest[i].children[j].children[k].recommendations[l].parts;
            if(parts.length){
              for(let p in parts){
                if((parts[p].recommendation_id==recommId) && (parts[p].part_id==part.id) && (parts[p].partstech_cart_item==1)){
                  applyChanges=true;
                  break;
                }
              }
            }
          }
        }
      } else if (part.level==2) {
        for (let k in serviceRequest[i].children[j].recommendations) {
           let parts=serviceRequest[i].children[j].recommendations[k].parts;
           if(parts.length>0){
            for(let p in parts){
              if((parts[p].recommendation_id==recommId) && (parts[p].part_id==part.id) && (parts[p].partstech_cart_item==1)){
                applyChanges=true;
                break;
              }
            }
           }
        }
      }
    }
  }
  return (applyChanges?1:0);
}

checkNexpart(part,recommId) {
  let serviceRequest = this.serviceRequestList;
  let applyChanges=false;
  for (let i in serviceRequest) {
    for (let j in serviceRequest[i].children) {
      if (part.level==3 && typeof serviceRequest[i].children[j].children!='undefined' && serviceRequest[i].children[j].children.length > 0) {
        for (let k in serviceRequest[i].children[j].children) {
          for (let l in serviceRequest[i].children[j].children[k].recommendations) {
            let parts=serviceRequest[i].children[j].children[k].recommendations[l].parts;
            if(parts.length){
              for(let p in parts){
                if((parts[p].recommendation_id==recommId) && (parts[p].part_id==part.id) && (parts[p].nexlink_cart_item==1)){
                  applyChanges=true;
                  break;
                }
              }
            }
          }
        }
      } else if (part.level==2) {
        for (let k in serviceRequest[i].children[j].recommendations) {
           let parts=serviceRequest[i].children[j].recommendations[k].parts;
           if(parts.length>0){
            for(let p in parts){
              if((parts[p].recommendation_id==recommId) && (parts[p].part_id==part.id) && (parts[p].nexlink_cart_item==1)){
                applyChanges=true;
                break;
              }
            }
           }
        }
      }
    }
  }
  return (applyChanges?1:0);
}
myListFormatter(data: any): string {
  return data['label'];
}
autoCompleListParts(data:any) {
  return data['label'];
}
updateApNp(v,rec,type,newPart){
  if(type=='AP'){
    let p=parseFloat(newPart.price).toFixed(2);
    this.viewingJSON[rec.id]["AP"][v].part_qty=newPart.part_quantity;
    this.viewingJSON[rec.id]["AP"][v].part_price=p;
    this.viewingJSON[rec.id]["AP"][v].force_override=1;
    this.viewingJSON[rec.id]["AP"][v].partstech=1
    this.viewingJSON[rec.id]["AP"][v].partstech_labor_time=newPart.partstech_labor_time;
    this.viewingJSON[rec.id]["AP"][v].partstech_labor_notes=newPart.partstech_labor_notes;
    this.viewingJSON[rec.id]["UAP"][v].part_qty=newPart.part_quantity;
    this.viewingJSON[rec.id]["UAP"][v].part_price=p;
    this.viewingJSON[rec.id]["UAP"][v].partstech_labor_time=newPart.partstech_labor_time;
    this.viewingJSON[rec.id]["UAP"][v].partstech_labor_notes=newPart.partstech_labor_notes; 

  }else{
      const price_array = [this.partMatrixStatus ? newPart.part_matrix_price:0, newPart.price ? newPart.price:0];  
      var value = Math.max(...price_array).toString() ;
      if(this.partMatrixStatus){
        if(this.price_set ==  1  ){
          value = price_array[0].toString(); 
        } 
        if(this.price_set ==  2 && newPart.part_matrix_price<newPart.price ){
            value = price_array[1].toString(); 
        } 
      }
      let price=(this.partMatrixStatus ? value : newPart.price);

    // let price=(this.partMatrixStatus ? newPart.part_matrix_price : newPart.price);
    let p=parseFloat(price).toFixed(2);
    this.viewingJSON[rec.id]["NP"][v].part_qty=newPart.part_quantity;
    this.viewingJSON[rec.id]["NP"][v].part_price=p;
    this.viewingJSON[rec.id]["NP"][v].force_override=1;
    this.viewingJSON[rec.id]["NP"][v].partstech=1
    this.viewingJSON[rec.id]["NP"][v].partstech_labor_time=newPart.partstech_labor_time;
    this.viewingJSON[rec.id]["NP"][v].partstech_labor_notes=newPart.partstech_labor_notes; 
    this.viewingJSON[rec.id]["UNP"][v].part_qty=newPart.part_quantity;
    this.viewingJSON[rec.id]["UNP"][v].part_price=p;
    this.viewingJSON[rec.id]["UNP"][v].partstech_labor_time=newPart.partstech_labor_time;
    this.viewingJSON[rec.id]["UNP"][v].partstech_labor_notes=newPart.partstech_labor_notes;
  }
}

updateApNpNexpart(v,rec,type,newPart){
  if(type=='AP'){
    let p=parseFloat(newPart.price).toFixed(2);
    this.viewingJSON[rec.id]["AP"][v].part_qty=newPart.part_quantity;
    this.viewingJSON[rec.id]["AP"][v].part_price=p;
    this.viewingJSON[rec.id]["AP"][v].force_override=1;
    if(!('nexpart' in this.viewingJSON[rec.id]["AP"][v])){
      this.viewingJSON[rec.id]["AP"][v].nexpart=0;
    }
    this.viewingJSON[rec.id]["AP"][v].nexpart=1;
    this.viewingJSON[rec.id]["UAP"][v].part_qty=newPart.part_quantity;
    this.viewingJSON[rec.id]["UAP"][v].part_price=p;
  }else{

      const price_array = [this.partMatrixStatus ? newPart.part_matrix_price:0, newPart.price ? newPart.price:0];  
      var value = Math.max(...price_array).toString() ; 
      if(this.partMatrixStatus){
       if(this.price_set ==  1  ){
         value = price_array[0].toString(); 
        } 
         if(this.price_set ==  2  &&  newPart.part_matrix_price<newPart.price  ){
             value = price_array[1].toString(); 
        } 
      }
      let price=(this.partMatrixStatus ? value : newPart.price);

    // let price=(this.partMatrixStatus ? newPart.part_matrix_price : newPart.price);
    let p=parseFloat(price).toFixed(2);
    this.viewingJSON[rec.id]["NP"][v].part_qty=newPart.part_quantity;
    this.viewingJSON[rec.id]["NP"][v].part_price=p;
    this.viewingJSON[rec.id]["NP"][v].force_override=1;
    if(!('nexpart' in this.viewingJSON[rec.id]["NP"][v])){
      this.viewingJSON[rec.id]["NP"][v].nexpart=0;
    }
    this.viewingJSON[rec.id]["NP"][v].nexpart=1;
    this.viewingJSON[rec.id]["UNP"][v].part_qty=newPart.part_quantity;
    this.viewingJSON[rec.id]["UNP"][v].part_price=p;
  }
}

removePartstechData(rec,obj){
  let th=this;
  if(this.viewingJSON[rec.id]["AP"].length){
    this.viewingJSON[rec.id]["AP"]=this.viewingJSON[rec.id]["AP"].filter((x,v) => {
      if (x.partstech == 1 && x.partstech_order==0) {
        if(obj.length && (obj.indexOf(x.id)!=-1)){
          return true;
        }else{
          return false;
        }
      }
      return true;
    });
  }
  if(this.viewingJSON[rec.id]["NP"].length){
    this.viewingJSON[rec.id]["NP"]=this.viewingJSON[rec.id]["NP"].filter((x,v) => {
      if (x.partstech == 1 && x.partstech_order==0) {
        if(obj.length && (obj.indexOf(x.id)!=-1)){
          return true;
        }else{
           return false;
        }
      }
      return true;
    });
    this.viewingJSON[rec.id]["UNP"]=this.viewingJSON[rec.id]["UNP"].filter((x,v) => {
      if (x.partstech == 1 && x.partstech_order==0) {
        if(obj.length && (obj.indexOf(x.id)!=-1)){
          return true;
        }else{
           return false;
        }
      }
      return true;
    });
 }
}

removeNexpartData(rec,obj){
  let th=this;
  if(this.viewingJSON[rec.id]["AP"].length){
    this.viewingJSON[rec.id]["AP"]=this.viewingJSON[rec.id]["AP"].filter((x,v) => {
      if (x.nexpart == 1 && x.nexpart_order==0) {
        if(obj.length && (obj.indexOf(x.id)!=-1)){
          return true;
        }else{
          return false;
        }
      }
      return true;
    });
  }
  if(this.viewingJSON[rec.id]["NP"].length){
    this.viewingJSON[rec.id]["NP"]=this.viewingJSON[rec.id]["NP"].filter((x,v) => {
      if (x.nexpart == 1 && x.nexpart_order==0) {
        if(obj.length && (obj.indexOf(x.id)!=-1)){
          return true;
        }else{
           return false;
        }
      }
      return true;
    });
    this.viewingJSON[rec.id]["UNP"]=this.viewingJSON[rec.id]["UNP"].filter((x,v) => {
      if (x.nexpart == 1 && x.nexpart_order==0) {
        if(obj.length && (obj.indexOf(x.id)!=-1)){
          return true;
        }else{
           return false;
        }
      }
      return true;
    });
 }
}


undefinedToBlank(){
  let data=this.serviceRequest.value;
   for(var i in data){
     if((typeof data[i]=='undefined') || (typeof data[i]==null) ){
       data[i]='';
     }else if((i=='scheduledServiceDate' || i=='estimatedDeliveryDate') && data[i]){
       data[i] = moment(data[i]).format('MM/DD/YYYY hh:mm A Z');
     }
   }
   if(this.addToreturnQue.length){
     data['return_queue']=JSON.stringify(this.addToreturnQue);
   }else{
     data['return_queue']='';
   }
   return data;
}
removeFileValues(){
  let file:any = document.querySelectorAll('input[type="file"]');
  if(typeof file.length!='undefined'){
    file.length && file.forEach(function(x){
      x.value='';
    })
  }
}

/**Order Now from partstech */
  getDataForOrderNow(isAutoSave = 1) {
    if(isAutoSave == 1){
      this.autoSaveRecom().subscribe((res:any)=>{});
      this.isPartstechOrderView  = true;
    }else{    
    //let note='Make sure you have saved the latest changes made to recommendation(s).';
    
    let user = this.globalService.getCurrentUser();
    let content = 'user_id=' + user['id'] + '&user_type=' + user["user_type"] + '&request_id=' + this.serviceRequestId;
      //this.globalService.confirmDialog("yes",note).subscribe((response)=>{
      //let res:any=response;
      //if(res=='yes'){
          this.globalService.callAPI(globalConstants.API_GET_PARTSTECH_ALL_CART_URL, content).subscribe((r:any) => {
          if (r.result == 1) {
            let dialogRef = this.dialog.open(PartstechComponent, {
              panelClass: 'partstech-order-dialog',
              width:'900px',
              data: { dataSources: r.data,orderNow:true, request_id: this.serviceRequestId, user_id: user['id'], userType: user['user_type'] }
            });
            dialogRef.afterClosed().subscribe(result => {
              this.isPartstechOrderView = false;
              this.reinit();
            });
          }
        });
      }
      //}else if(res=='No'){
      //  this.globalService.scrollToEle('#sendNotificationToCustomer');
      //}
    //});
  }

  /**Order Now from Nexpart */
  getDataForOrderNowNexpart(isAutoSave = 1) {
    if(isAutoSave == 1){
      this.autoSaveRecom().subscribe((res:any)=>{});
      this.isNextPartOrderView = true;
    }else{
    //let note='Make sure you have saved the latest changes made to recommendation(s).';
    let user = this.globalService.getCurrentUser();
    let content = 'user_id=' + user['id'] + '&user_type=' + user["user_type"] + '&request_id=' + this.serviceRequestId;
    //this.globalService.confirmDialog("yes",note).subscribe((response)=>{
        //let res:any=response;
        //if(res=='yes'){
          this.globalService.callAPI(globalConstants.API_GET_NEXLINK_ORDERS_URL, content).subscribe((r:any) => {
          if (r.result == 1) {
            let dialogRef = this.dialog.open(PartstechComponent, {
              panelClass: 'partstech-order-dialog',
              width:'900px',
              data: { dataSources: r.data,orderNow:false, request_id: this.serviceRequestId, user_id: user['id'], userType: user['user_type'], partShop:globalConstants.PART_SHOPS.NEXPART,showOrder:true }
            });
            dialogRef.afterClosed().subscribe(result => {
              // this.reinit();
              this.isNextPartOrderView = false;
            });
          }

        });
      }
      //}else if(res=='No'){
      //  this.globalService.scrollToEle('#sendNotificationToCustomer');
      //}
    //});
  }
/** Delete PartsTech  parts after confirmation */
  deleteParts(index, rec, partType, parts,levelOneObj, level2ndObj, level3rdObj) {
    let recommendation = {id: rec.id, level1ParentId: levelOneObj.id, level2ParentId:level2ndObj.id, service_request_list_id: level2ndObj.id}
    if(level3rdObj == null){
      recommendation.service_request_list_id = level2ndObj.id;
    } else {
      recommendation.service_request_list_id = level3rdObj.id;
    }
    let th = this;
    let sq=parseFloat(parts.stock_quantity);
    let qou=parseFloat(parts.quantity_overused);
    let q=parseFloat(parts.part_qty);
    if (partType == 'NP' || ((q-qou) <= 0)) {
      if ((parts.partstech_order == '1') || (parts.partstech == '1') || (parts.nexpart_order == '1') || (parts.nexpart == '1')) {
        let note = 'Are you sure,you want to delete this part?';
        this.globalService.confirmDialog("yes", note).subscribe((response) => {
          let res: any = response;
          if (res == 'yes') {
            th.removePart(index, rec, partType,recommendation);
            this.cdr.markForCheck();
          }
        });
      } else {
        this.removePart(index, rec, partType,recommendation);
      }
    } else if(this.isInventory) {
      let dialogRef = this.dialog.open(ReturnqueComponent, {
        panelClass: 'partstech-order-dialog',
        width: '800px',
        data: { part: parts, request_id: this.serviceRequestId }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result == 1) {
          this.manageReturnQue(rec, parts);
        }
        if (result == 1 || result == 2) {
          this.removePart(index, rec, partType,recommendation);
          this.globalService.snackbar('error', "Save Recommendation to update the changes.",{ component:this,method :'postSaveAndStay',param:0});
        }
        this.cdr.detectChanges();
      });
    } else {
      this.removePart(index, rec, recommendation,partType);
    }
  }
  reinit(){
    this.serviceRequest.reset();
    this.serviceInfo=[];
    this.serviceRequestList=[];
    this.addToreturnQue=[];
    this.cdr.detectChanges();
    this.initFormValue=false;
    this.newJobs=[];
    // this.ngOnInit(); //revisit
    this.getSRData();
    if(!this.snackBarSave){
      this.globalService.setScrolling("body", 500, 0);
      this.snackBarSave=false;
    }
    this.cdr.detectChanges();

  }
  uploadDocImg(event, rendering_tag_id) {
    var html = "";
    let ele = <HTMLInputElement> document.getElementById('add' + rendering_tag_id);

    if (event.target.files && event.target.files[0]) { 
      // console.log(event.target.files);
      

      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
              var reader = new FileReader();


      let file = event.target.files[i];
      // console.log(file);
      let valid=true;
      if (rendering_tag_id == 'request_document') {
        valid=this.globalService.validateFiles('docpdf',event.target.files);
      }else if (rendering_tag_id == 'request_image'){
        valid=this.globalService.validateFiles('image',event.target.files);
      }
      if(!valid){
        if (ele) {
          ele.value = '';
        }
        event.target.value = "";
        return false;
      }
      if(valid){
        let data = new FormData();
        if (rendering_tag_id == 'request_document') {
          data.append('new_service_file', 'doc');
        } else if (rendering_tag_id == 'request_image') {
          if (file.type == 'application/pdf') {
            this.globalService.snackbar("error", 'Please upload a valid file.');
            return false;
          }
        }
        data.append(rendering_tag_id, file);
        data.append('customer_id', this.serviceInfo.customer_id);
        data.append('request_id', this.serviceRequestId.toString());

          let url = '';
          if (rendering_tag_id == 'request_document') {
            url = globalConstants.API_UPLOAD_SERVICE_REQUEST_DOCUMENT_URL;
          } else {
            url = globalConstants.API_UPLOAD_SERVICE_REQUEST_IMAGE_URL;
          }
          this.globalService.formData(url, data).subscribe((resData:any) => {
            if (resData.result == 1) {
              if (rendering_tag_id == 'request_document') {
                this.requestDoc.push({id: resData.data.id, document_name: resData.data.document_name});
              } else {
                this.requestImages.push({id: resData.data.id, image_name: resData.data.image_name});
              }
              this.globalService.snackbar("success", resData.message);
            } else {
              this.globalService.snackbar("error", resData.message);
            }
          });
        }
    }
  }else if (ele) {
      ele.value = '';
      event.target.value = "";
    }
  }
  getFileIcons(str){
    let ext=str.substr(str.lastIndexOf(".")+1);
    let type=0;
    if(ext!=''){
      type= this.globalService.getFileIcon({type:ext.toLowerCase()});
    }
    return type;
   }
  deleteImgDoc(event,id,type){
    let msg='Are you sure you want to delete this document?';
    let fdata='request_id='+this.serviceRequestId;
    let url=globalConstants.API_DELETE_REQUEST_DOCUMENT_URL;

    if(type=='image'){
      msg='Are you sure you want to delete this image?';
      url=globalConstants.API_DELETE_REQUEST_IMAGE_URL;
      fdata+='&request_image_id='+id;
    }else if(type=='document'){
      fdata+='&request_document_id='+id;
    }
    this.globalService.confirmDialog("deletes", msg).subscribe((data) => {
      if (data != undefined && data.toString() == "deletes") {
        this.globalService.callAPI(url, fdata)
        .subscribe((resData:any) => {
          if (resData.result == 1) {
            if(type=='document'){
              this.requestDoc.splice(event,1);
            }else if(type=='image'){
              this.requestImages.splice(event,1);
            }
            this.globalService.snackbar("success", resData.message);
          } else {
            this.globalService.snackbar("error", resData.message);
          }
        })
      }
    })
  }
  isAllowed(action,module?:any){
    if(typeof module!='undefined'){
        return this.globalService.isAllowed(this.app,module,action);
    }
    return false;
  }
  getAllTechJobStatus(){
    if(this.isEmployee==false){
      this.techinianStatusDone=true;
      return false;
    }
    let data = "user_id=" + this.globalService.getCurrentUser()["id"] + "&user_type=" + this.globalService.getCurrentUser()["user_type"] + "&request_id=" + this.serviceRequestId;
      this.globalService.callAPI(globalConstants.API_SERVICE_REQUEST_TRACKERS,data).subscribe((r:any)=>{

        this.techinianStatusDone=true;
        if(r.result==1){

          for(var i in r.data){
            if(r.data[i].work_status!='3'){
              this.techinianStatusDone=false;
            }
          }
        }
      })
    }
    /** part update on the fly */
    editNewPart(event,child,recommendation,i){
      let part_id=$("#edit-part-"+child.id+"-edit-btn").attr("data-part-id");

      let dialogRef = this.dialog.open(EditpartPopupComponent, {
        panelClass: 'car-dialog-form',
        data: { part_id: part_id,request_id: this.serviceRequestId }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(typeof result != 'undefined' && typeof result.part_id != 'undefined' ){
          this.updatejobPart(part_id,result,recommendation,child);
        }
      });
    }
    /** update local part updated on the fly */
    updatejobPart(part_id,part,rec,child){
        var uAP = this.viewingJSON[rec.id]["UAP"].find(function(x){ return parseInt(x.id) == parseInt(part_id) });
        var AP = this.viewingJSON[rec.id]["AP"].find(function(x){{ return parseInt(x.id) == parseInt(part_id) }});
        var uNP = this.viewingJSON[rec.id]["UNP"].find(function(x){ return parseInt(x.id) == parseInt(part_id) });
        var NP = this.viewingJSON[rec.id]["NP"].find(function(x){{ return parseInt(x.id) == parseInt(part_id) }});
        if(this.isUpdatePartCost(uAP)){
          uAP.cost=part.cost;
          uAP.description=part.description;
          uAP.part_name=part.part_number +(this.showCostPrice?' ($'+part.cost+') ':' ')+part.part_name + ' ' + part.description;
          uAP.non_taxable=part.non_taxable;
          uAP.inventory_price=part.price;
        }
        if(this.isUpdatePartCost(AP)){
          AP.cost=part.cost;
          AP.description=part.description;
          AP.part_name=part.part_number +(this.showCostPrice?' ($'+part.cost+') ':' ')+part.part_name + ' ' + part.description;
          AP.non_taxable=part.non_taxable;
          AP.inventory_price=part.price;
          AP.part_matrix_price=part.part_matrix_price
        }
        if(this.isUpdatePartCost(uNP)){
          uNP.cost=part.cost;
          uNP.description=part.description;
          uNP.part_name=part.part_number +(this.showCostPrice?' ($'+part.cost+') ':' ')+part.part_name + ' ' + part.description;
          uNP.non_taxable=part.non_taxable;
          uNP.inventory_price=part.price;
        }
        if(this.isUpdatePartCost(NP)){
          NP.cost=part.cost;
          NP.description=part.description;
          NP.part_name=part.part_number +(this.showCostPrice?' ($'+part.cost+') ':' ')+part.part_name + ' ' + part.description;
          NP.non_taxable=part.non_taxable;
          NP.inventory_price=part.price;
          NP.part_matrix_price=part.part_matrix_price
        }
        this.editPart(this.editPartInfo.index,child,rec,this.editPartInfo.listType);
    }
   isUpdatePartCost(part){
    return typeof part!='undefined';
  }
  taskTracking(option){
    let data='user_id='+this.globalService.getCurrentUser()["id"]+'&user_type='+this.globalService.getCurrentUser()["user_type"]+'&request_id='+this.serviceRequestId+'&tracker='+btoa(option);
    let conf='Are you sure to continue?';
    this.globalService.confirmDialog('taskTrack', conf).subscribe((r) => {
      let res:any=r;
     if(res=='taskTrack'){
     this.globalService.callAPI(globalConstants.API_TRACK_SERVICE_REQUEST,data).subscribe((track:any)=>{
      if(track.result==1){
        this.userTaskTrack=option;
       this.globalService.snackbar("success", track.message);
      }else{
        if(track.code==388){
          let conf = 'Are you sure to want Pause/Complete other service request timer?';
          this.globalService.confirmDialog('taskTrackConfirm', conf).subscribe((res) => { 
            if(res){
              this.taskTrackingApi(option,res);
            }  
          }); 
        }else{
          this.globalService.snackbar("error", track.message);
        }
      }
    })
    }
   });
  }
  taskTrackingApi(option,forceStop=null){
    let data = 'user_id=' + this.globalService.getCurrentUser()["id"] + '&user_type=' + this.globalService.getCurrentUser()["user_type"] + '&request_id=' + this.serviceRequestId + '&tracker=' + btoa(option);
    if(forceStop==2 || forceStop==3){
      data +="&force_action="+forceStop;
    }
    this.globalService.callAPI(globalConstants.API_TRACK_SERVICE_REQUEST,data).subscribe((track:any)=>{
      if(track.result==1){
        this.userTaskTrack=option;
        this.globalService.snackbar("success", track.message);
      }else{
        if(track.code==388){
          let conf = 'Are you sure to want Pause/Complete other service request timer?';
          this.globalService.confirmDialog('taskTrackConfirm', conf).subscribe((res:any) => { 
            if(res){
              this.taskTrackingApi(option,res);
            }  
          }); 
        }else{
          this.globalService.snackbar("error", track.message);
        }
      }
    })
  }
  startTracker() {
    if (this.serviceInfo.timer_control_status == '0') {
      if (localStorage.getItem('bypassPopup-' + this.serviceRequestId) == '1') return false;
      if (this.isEmployee && this.taskTrack && (this.userTaskTrack == 0 || this.userTaskTrack == 2)) {
        let dialogRef = this.dialog.open(NotifyjobComponent, {
          panelClass: ['car-dialogbox'],
          width: "300px",
          data: { start: true, taskTrack: this.taskTrack, userTaskTrack: this.userTaskTrack, request_id: this.serviceRequest.value.request_id, id: this.serviceRequestId }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result == 1) {
            this.taskTrackingApi(1);
          } else {
            localStorage.setItem('bypassPopup-' + this.serviceRequestId, '1');
          }
        });
      }
    }
  }
  
  checkDeActive() {
    if (this.serviceInfo.timer_control_status == '0') {
    if(localStorage.getItem('bypassPopup-'+this.serviceRequestId) == '1') return true;
    if (this.isEmployee && (this.userTaskTrack == 1 || this.userTaskTrack == 2)) {
      let dialogRef = this.dialog.open(NotifyjobComponent, {
        panelClass: ['car-dialogbox'],
        width: "300px",
        data: { start: false, taskTrack: this.taskTrack, userTaskTrack: this.userTaskTrack, request_id: this.serviceRequest.value.request_id, id: this.serviceRequestId }
      });
      return dialogRef.afterClosed().map(result => {
        this.dialog.closeAll();
        if (result == 4) {
          return true;
        }else if(result == 1){
          this.taskTrackingApi(result);
          return true;
        }else if(result == 2){
          this.taskTrackingApi(result);
          return true;
        }else if(result == 3){
          this.taskTrackingApi(result);
          return true;
        }
        return false;
      });
    } else {
      return true;
    }
  }else{
    return true;
  }
  }
  disclaimerOpen(){
    let dialogRef;
    let th=this;
    let content = "user_id=" + this.globalService.getCurrentUser()["id"] + "&user_type=" + this.globalService.getCurrentUser()["user_type"];
    this.globalService.callAPI(globalConstants.API_GET_DISCLAIMERS_LIST_URL, content).subscribe((response:any) => {
        if (response.result == 1) {
            dialogRef = this.dialog.open(changeDisclaimer, {
                panelClass: 'car-dialog-form',
                width: '800px',
                data: {dataSources: response, requestId: this.serviceRequestId, disclaimer: '',disclaimer_id:th.serviceInfo.disclaimer_id}
            });
            dialogRef.afterClosed().subscribe(result => {
              if(typeof dialogRef["response"] != 'undefined'){
                th.serviceInfo.disclaimer=dialogRef["response"]["disclaimer"];
              }
              th.cdr.markForCheck();
            });
        } else {
            this.globalService.snackbar("error", response.message);
        }
    });
}
copyText() {
  var text = this.serviceInfo.vin;
  this.globalService.txtCopy(text);
}


  copyNexpartLaborNotes( obj, obj_child, level_3_child) { 
         
        var text = '';
     if (level_3_child == undefined) {
       if(this.tempReports[obj.id][obj_child.id]["nexpart_labor"]!=undefined){
          for(var i=0;i<this.tempReports[obj.id][obj_child.id]["nexpart_labor"].length;i++){ 

             if(this.tempReports[obj.id][obj_child.id]["nexpart_labor"][i].component!=undefined){
                text += this.tempReports[obj.id][obj_child.id]["nexpart_labor"][i].group +" - "+ this.tempReports[obj.id][obj_child.id]["nexpart_labor"][i].component;
                text += "\n";
              }
          } 
          this.globalService.txtCopyNexpart(text);
        }
    } else { 
       if(this.tempReports[obj.id][obj_child.id][level_3_child.id]["nexpart_labor"]!=undefined){
        for(var i=0;i<this.tempReports[obj.id][obj_child.id][level_3_child.id]["nexpart_labor"].length;i++){ 
             if(this.tempReports[obj.id][obj_child.id][level_3_child.id]["nexpart_labor"][i].component!=undefined){
              text += this.tempReports[obj.id][obj_child.id][level_3_child.id]["nexpart_labor"][i].group+" - "+this.tempReports[obj.id][obj_child.id][level_3_child.id]["nexpart_labor"][i].component;
              text += "\n";
            }
        } 
        this.globalService.txtCopyNexpart(text);
      }
    }
}

  copyPartsTechLaborNotes(obj, obj_child, level_3_child) {
    var text = '';
    if (level_3_child == undefined) { //level 2nd work
      if (this.tempReports[obj.id][obj_child.id]["combinedPartsTechLaborNotes"].length > 0) {
        let combinedPartsTechLaborNotesArr = this.tempReports[obj.id][obj_child.id]["combinedPartsTechLaborNotes"];
        for (let i = 0; i < combinedPartsTechLaborNotesArr.length; i++) {
          if (combinedPartsTechLaborNotesArr[i].length > 0) {
            let partsObj = {
              partstech_labor_notes: combinedPartsTechLaborNotesArr[i]
            }
            let combinLaborNotes = this.getPTLaborNotesAsPerSettings(partsObj, 'DOW').join('\n');
            text += this.checkPrefixSetting() + combinLaborNotes;
            text += "\n";
          }
        }
        this.globalService.txtCopyNexpart(text);
      }
    } else {
      if (this.tempReports[obj.id][obj_child.id][level_3_child.id]["combinedPartsTechLaborNotes"].length > 0) {
        let combinedPartsTechLaborNotesArr = this.tempReports[obj.id][obj_child.id][level_3_child.id]["combinedPartsTechLaborNotes"];
        for (let i = 0; i < combinedPartsTechLaborNotesArr.length; i++) {
          if (combinedPartsTechLaborNotesArr[i].length > 0) {
            let partsObj = {
              partstech_labor_notes: combinedPartsTechLaborNotesArr[i]
            }
            let combinLaborNotes = this.getPTLaborNotesAsPerSettings(partsObj, 'DOW').join('\n');
            text += this.checkPrefixSetting() + combinLaborNotes;
            text += "\n";
          }
        }
        this.globalService.txtCopyNexpart(text);
      }
    }
  }

detailTab(type) {
  let dialogRef = this.dialog.open(DetailTabComponent, {
    panelClass: 'car-dialog-form',
    width: '800px',
    data: { requestId: this.serviceRequestId, customer_id: this.serviceInfo.customer_id, tab: type ,car_id : this.serviceInfo.car_id }
  });
  dialogRef.afterClosed().subscribe((r: any) => {
    if(r == true){
      this.updateCustomerVehicleData();
    }
  });
 }
 isTechnicianAllowed(){
  let user = this.globalService.getCurrentUser();
  return ( user.role_id != globalConstants.ROLE_ID_TECHNICIAN ? true : false );
 }
 ngOnDestroy(){
   this.globalService.srnoteListId(false);
   this.lockServiceRequest('0');
   this.refreshList && this.refreshList.unsubscribe()
   localStorage.removeItem('bypassPopup-'+this.serviceRequestId);
   this.globalService.passPasteObj(false); // to prevent to auto paste on page revisit
 }
 lockServiceRequest(lock){
  if(this.serviceRequestId){
   let data={request_id:this.serviceRequestId,lock_status:lock};
   this.globalService.callAPI(globalConstants.API_MODIFY_RECOM_LOCK,data).subscribe((res:any)=>{
    if (res.result != 1) {
      this.globalService.snackbar("error", res.message);
    }
   },(error)=>{});
  }
 }
 stockInHand(part){
  let q=globalFunctions.hasDecimal(part.stock_quantity,true)+' remaining in stock';
  let ouq=globalFunctions.hasDecimal(part.quantity_overused,true);
  let count=globalFunctions.hasDecimal(part.suggestion_parts_count,true);
  if(parseFloat(ouq)){
    q+=', '+ouq+' short';
  }
  if(parseFloat(count) && count){
    q+=', '+count+' replacements available';
  }else{
    q+=', No Replacements Available';
  }
  return q;
}

// saveAndStay(type){
//   this.saveRecommendationConfirm= 0;
//   this.snackBarSave=true;
//   this.serviceRequest.controls.report.setValue(JSON.stringify(this.tempReports));
//   var getAllKeys = Object.keys(this.viewingJSON);
//   var temparr = []
//   for (var i = 0; i < getAllKeys.length; i++) {
//     var tempPartObjToSend = []
//     var total = this.viewingJSON[getAllKeys[i]]["AP"] + this.viewingJSON[getAllKeys[i]]["NP"];
//     this.viewingJSON[getAllKeys[i]]["AP"].forEach(function (ap) {
//       let forceOverride=0;
//       if(typeof ap.force_override!='undefined'){
//         forceOverride=ap.force_override
//       }
//       var sendingPartObj = ap.id + "|" + ap.part_price + "|" + ap.part_qty + "|" + ap.non_taxable+"|"+forceOverride;
//       var traverse = ap.traverse;
//       var l2Id;
//       var l1Id;
//       var l3Id;
//       var d;
//       if (ap.level == 3) {
//         l1Id = ap.traverse.split("|")[2];
//         l2Id = ap.traverse.split("|")[1];
//         l3Id = ap.traverse.split("|")[0];
//         d = {
//           [l1Id]: {
//             [l2Id]: {
//               [l3Id]:
//               {
//                 "Parts": sendingPartObj,
//               }
//             }
//           }
//         }
//       } else if (ap.level == 2) {
//         l1Id = ap.traverse.split("|")[1];
//         l2Id = ap.traverse.split("|")[0];
//         d = {
//           [l1Id]: {
//             [l2Id]: {
//               "Parts": sendingPartObj
//             }
//           }
//         }
//       }
//       temparr.push(d);
//       // this.serviceRequest.controls.report(temparr)
//     });

//     this.viewingJSON[getAllKeys[i]]["NP"].forEach(function (ap) {
//       let forceOverride=0;
//       if(typeof ap.force_override!='undefined'){
//         forceOverride=ap.force_override
//       }
//       var sendingPartObj = ap.id + "|" + ap.part_price + "|" + ap.part_qty + "|" + ap.non_taxable+"|"+forceOverride;
//       var traverse = ap.traverse;
//       var l2Id;
//       var l1Id;
//       var l3Id;
//       var d;
//       if (ap.level == 3) {
//         l1Id = ap.traverse.split("|")[2];
//         l2Id = ap.traverse.split("|")[1];
//         l3Id = ap.traverse.split("|")[0];
//         d = {
//           [l1Id]: {
//             [l2Id]: {
//               [l3Id]:
//               {
//                 "Parts": sendingPartObj,
//               }
//             }
//           }
//         }
//       } else if (ap.level == 2) {
//         l1Id = ap.traverse.split("|")[1];
//         l2Id = ap.traverse.split("|")[0];
//         d = {
//           [l1Id]: {
//             [l2Id]: {
//               "Parts": sendingPartObj
//             }
//           }
//         }
//       }
//       temparr.push(d);
//     });

//     temparr.forEach((part) => {
//       var l1 = Object.keys(part)[0];
//       Object.keys(part[Object.keys(part)[0]])
//       var l2 = Object.keys(Object.keys(part))[0];
//       var l3 = null;
//       if (Object.keys(Object.keys(Object.keys(part)))) {
//         //has level 3
//         l3 = Object.keys(Object.keys(Object.keys(part)))[0]
//       }
//     })
//   }
//   this.makeChangesInSendingObjectToAddNewParts(temparr,type);

// }

getJobsApproval(note,fdata,updateReport = false){
  this.approvalDiagParams.access_timestamp = this.accessTimestamp;
  this.approvalDiagParams.skip_reassign = 1;
  this.approvalDiagParams.fullRequestId = this.carRequestId;
  let dialogRef = this.dialog.open(JobApprovalDialogComponent, {
    panelClass: 'car-dialog-form',
    width: '800px',
    data: this.approvalDiagParams
  });
  dialogRef.afterClosed().subscribe((res:any)=>{
    fdata.set('access_timestamp',res.data.access_timestamp);
    this.accessTimestamp = res.data.access_timestamp;
    this.serviceRequest.controls.access_timestamp.setValue(res.data.access_timestamp);
    if(res.action == 'goToCompletion'){
      this.milesOut(note,fdata);
    }else if(res.action == 'requireApproval'){
      this.requireApprovalDialog(res.data,note,fdata,updateReport);
    }else if(updateReport){
      this.updateReportData();
    }
  });
}

  requireApprovalDialog(data, note, fdata, updateReport = false): void {
    localStorage.setItem("requestCreatedDate", this.globalService.getFullFormateDate(this.serviceInfo.add_date, "G", ""));
    let dialogRef = this.dialog.open(RequireApprovalDialogComponent, {
      panelClass: ['payment-dialog'],
      width: '800px',
      data: { service_request_id: this.serviceRequestId, access_timestamp: data.access_timestamp, user_id: this.globalService.getCurrentUser()["id"], customer_id: this.serviceInfo.customer_id, jobStatus: data.areAllJobsRejected, skip_reassign: 1,fullRequestId: this.carRequestId}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.access_timestamp) fdata.set('access_timestamp', result.access_timestamp)
        this.milesOut(note, fdata);
      } else {
        if (updateReport) {
          this.updateReportData();
        }
      }
    });
  }

milesOut(notes,data){
   let content = "user_id=" + this.globalService.getCurrentUser()["id"] + "&user_type=" + this.globalService.getCurrentUser()["user_type"] + "&service_request_id=" + this.serviceRequestId;
   this.globalService.callAPI(globalConstants.API_GET_SERVICE_REQUEST_VIEW_URL, content).subscribe((response:any) => {
    if (response.result == 1) {
         let options ={
            tax: response.data.tax_rate,
            total: response.data.tax_amount,
            isTax: response.data.is_tax_included,
            fullRequestId: this.carRequestId
         };
         let existingReminderObj ={
          standardMilage:this.serviceInfo.standard_mileage,
          standardDays:this.serviceInfo.standard_days,
          existingReminder: this.serviceInfo.existing_reminder
         };

         let pvtReviewsSettingsObj = {
          reviewNotes:this.serviceInfo.pvt_reviews_settings.review_notes,
          reviewUrl:this.serviceInfo.pvt_reviews_settings.review_url,
          reviewNotesIncluded:this.serviceInfo.pvt_reviews_settings.review_notes_included,
          reviewUrlIncluded:this.serviceInfo.pvt_reviews_settings.review_url_included,
         };

         let extPaymentSettingsObj = {
          paymentNotes:this.serviceInfo.ext_payment_settings.payment_notes,
          paymentUrl:this.serviceInfo.ext_payment_settings.payment_url,
          paymentNotesIncluded:this.serviceInfo.ext_payment_settings.payment_notes_included,
          paymentUrlIncluded:this.serviceInfo.ext_payment_settings.payment_url_included,
         };

         let permissionObj ={
           privateReview: this.serviceInfo.private_review,
           externalPayment: this.serviceInfo.external_payment,
           dfsReminders: this.serviceInfo.dfs_reminders,
         };
         let dialogRef = this.dialog.open(MilesoutComponent, {
          panelClass: ['car-dialog-form','milesout-panel'],
          data: {
             returnData:false,
             recomData:data,
             requestId: this.serviceRequestId,
             miles:this.serviceInfo.present_mileage,
             option:options,note:notes,
             car_id : this.serviceInfo.car_id,
             list:response.data.service_request_list,
             customer_id: this.serviceInfo.customer_id, 
             labor_rate:response.data.labor_rate,
             existingReminderObj: existingReminderObj,
             pvtReviewsSettingsObj:pvtReviewsSettingsObj,
             extPaymentSettingsObj:extPaymentSettingsObj,
             isWalkinCustomer:this.serviceInfo.is_walkin_customer,
             isWalkinVehicle:this.serviceInfo.is_walkin_customer_vehicle,
             permissionObj:permissionObj
          }
      });
      dialogRef.afterClosed().subscribe(result => {
       localStorage.setItem("fillRecommendationPaymentDialog-"+this.serviceRequestId,'1');
       this.router.navigate(["/shops/view-service-request/" + this.serviceRequest.value.id])
      });
    }
  })
  //end

  // remove this code when perform code cleanup task
//   let dialogRef = this.dialog.open(MilesoutComponent, {
//      panelClass: 'car-dialog-form',
//      width: '800px',
//      data: {returnData:true,recomData:data,requestId: this.serviceRequestId, miles:this.serviceInfo.present_mileage,note:notes,car_id : this.serviceInfo.car_id,customer_id: this.serviceInfo.customer_id}
//  });
//  dialogRef.afterClosed().subscribe(result => {
//   localStorage.setItem("fillRecommendationPaymentDialog-"+this.serviceRequestId,'1');
//   this.router.navigate(["/shops/view-service-request/" + this.serviceRequest.value.id])
//  });
//end
}
/** add to stock order que*/
addStockOrder(a,recommendation,type,parts){
  let dialogRef = this.dialog.open(StockorderpopupComponent, {
    panelClass: ['car-dialog-form','ticket-dialog'],
    width: '800px',
    data: {newAllowed:false,recom:recommendation,request_id:this.serviceInfo.request_id,requestId: this.serviceRequestId,part:parts}
  });
}
manageReturnQue(rec,parts){
  let part={part_id:0,quantity:0,service_list_id:0};
  let qou=parseFloat(parts.quantity_overused);
  let q=parseFloat(parts.part_qty);
  // if (((q-qou) > 0)) {

  // }
  part.part_id=parts.id;
  part.quantity=(q-qou);//parts.part_qty;
  part.service_list_id=rec.service_request_list_id;
  if(this.addToreturnQue.length==0){
    this.addToreturnQue.push(part);
  }else{
   let f= [];
   let ff=this.addToreturnQue.filter(ele => {
      if(ele.part_id==part.part_id && ele.service_list_id==part.service_list_id){
        return ele;
      }
    });
    if(ff.length==0){
      this.addToreturnQue.push(part);
    }
    // console.log(this.addToreturnQue);
  }
  return true;
}

/* parstech dealy dataload */
  getPartstechData(dialogRef,levelOneObj,level2ndObj,level3rdObj) {
    this.globalService.callAPI(globalConstants.API_GET_PARTSTECH_CART_URL, dialogRef['ref']).subscribe((response:any) => {
      if (response.is_cart_modified == "1") {
        this.partMatrixStatus=(response.part_matrix_status==1 ? true : false);
        this.addUpdatePartech(dialogRef['ref'], response.data,levelOneObj,level2ndObj,level3rdObj);
        this.cdr.detectChanges();
      } else if (response.result == '0' && response.code == "449") {
        this.globalService.snackbar("error", response.message);
        let th = this;
        if (this.partstechLoadCount <= 3) {
          setTimeout(() => {
            th.partstechLoadCount += 1;
            th.getPartstechData(dialogRef,levelOneObj,level2ndObj,level3rdObj);
          }, 1000)
        }else{
          this.partstechLoadCount=1;
        }
      }else if (response.result == '0' ){
        this.globalService.snackbar("error", response.message);
      }
    },(error)=>{});
  }

  /* nexpart dataload */
  getNexpartData(dialogRef) {
    // console.log('123');
    let data = {
      request_id: this.serviceRequestId,
      user_id: this.currentUser.id,
      job_id: dialogRef['ref'].job_id,
      cart_id: dialogRef['ref'].cart_id
    }
    this.globalService.callAPI(globalConstants.API_GET_NEXLINK_CART_URL, data).subscribe((response:any) => {
    // console.log('12345');
      if (response.is_cart_modified == "1" || response.is_cart_ordered == 1) {
        this.partMatrixStatus=(response.part_matrix_status==1 ? true : false);
        this.addUpdateNexpart(dialogRef['ref'], response.data, response.is_cart_ordered);
        this.cdr.detectChanges();
      } else if (response.result == '0' ){
        this.globalService.snackbar("error", response.message);
      }
      // else if (response.result == '0' && response.code == "449") {
      //   this.globalService.snackbar("error", response.message);
      //   let th = this;
      //   if (this.nexpartLoadCount <= 3) {
      //     setTimeout(() => {
      //       th.nexpartLoadCount += 1;
      //       th.getNexpartData(dialogRef);
      //     }, 1000)
      //   }else{
      //     this.nexpartLoadCount=1;
      //   }
      // }
    },(error)=>{});
  }

  getStatus() {
    return this.serviceInfo.is_appointment== '1' && (this.serviceInfo.request_status=='1' || this.serviceInfo.request_status=='2') ? 'Appointments': this.statuses[this.serviceInfo.request_status] ;
   }
   showAllstatus() {
    if(this.isEmployee==false){
      return false;
    }
     let dialogRef = this.dialog.open(TechassignmentComponent, {
       panelClass: ['car-dialog-form'],
       width: "900px",
       data: {request_id:this.serviceRequestId,techStatus:true}
     });
   }

  suggestion(i, r, p) {
    let c=parseFloat((typeof p.suggestion_parts_count!='undefined'?p.suggestion_parts_count:0));
    if(c<=0){
      return false;
    }
    let dialogRef = this.dialog.open(PartsuggestionComponent, {
      panelClass: ['car-dialog-form'],
      width: "700px",
      data: { request_id: this.serviceRequestId, for: p }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != 'undefined' && result == 1) {
        let part = dialogRef["suggested"];
        part.traverse=p.traverse;
        part.part_qty=p.part_qty;
        part.level=p.level;
        let partObj=this.createPartObj(part,true);
        var isAlreadyPresentInNP = this.viewingJSON[r.id]['NP'].find((x) => {
          return x.id == part.id
        });

        var isAlreadyPresentInAP = this.viewingJSON[r.id]['AP'].find((x) => {
            return x.id == part.id
        });
        if (isAlreadyPresentInAP != undefined || isAlreadyPresentInNP != undefined) { 
          this.globalService.snackbar('error', "Parts already Exists");
        }else{
          this.getReplacementCounts(partObj,r);
          // this.viewingJSON[r.id]["NP"].push(partObj);
          // this.viewingJSON[r.id]["UNP"].push(partObj);
          this.cdr.markForCheck();
        }
      }
      this.makeCalculations();
      this.cdr.markForCheck();
    });
  }
   gnTipId(part){
    return (typeof part.inventory_price!='undefined' ?' Retail:'+part.inventory_price:'') +(typeof part.part_matrix_price!='undefined' && this.partMatrixStatus ?' Matrix:'+part.part_matrix_price:'')+(typeof part.partstech_price!='undefined' && part.partstech==1?' PartsTech:'+part.partstech_price:'')
   }

   openExternalUrl(arg){
    let url = 'https://repairlinkshop.com/';
    let shopId =parseInt(this.currentUser.shop_id);
    // let open=(this.repairlinkShop.indexOf(shopId) != -1 ? true : false);
    let open=this.isRepairLinkAllowed;
    if (arg==1 && open) {
      this.app.iframeUrl ='';
      let th=this;
      setTimeout(()=>{
        th.app.eLearnPortal({title:'Repair Link',url:url,img:'repairlink.jpg'});
        th.app.cdr.detectChanges()
      },0)
    }
    return open;
  } 
  openIdentifix(arg) {
    // let url = 'https://www.identifix.com/';
    // let shopId =parseInt( this.currentUser.shop_id);
    // let open=(this.identifixShop.indexOf(shopId) != -1 ? true : false);
    // if (arg==1 && open) {
    //   this.app.iframeUrl ='';
    //   let th=this;
    //   setTimeout(()=>{
    //     th.app.eLearnPortal({title:'Identifix',url:url,img:'identifix.jpg'});
    //     th.app.cdr.detectChanges()
    //   },0)
    // }
    // return open;
  }
  getReplacementCounts(part:any,reco){
    let user = this.globalService.getCurrentUser();
    let cont={user_id:user.id,user_type:user.user_type,part_ids:part.id};
    this.globalService.callAPI(globalConstants.API_COUNT_PART_SUGGESTION, cont).subscribe((res:any) => {
      if(res.result=='1' && typeof res.data!='undefined' && res.data.length){
        for(var i in res.data){
          if(res.data[i].part_id==part.id){
            part.suggestion_parts_count=res.data[i].count;
            break;
          }
        }
        this.viewingJSON[reco.id]['NP'].push(part);
        this.viewingJSON[reco.id]['UNP'].push(part);
        this.cdr.markForCheck();
        this.makeCalculations();
      }
    },(error)=>{
        this.viewingJSON[reco.id]['NP'].push(part);
        this.viewingJSON[reco.id]['UNP'].push(part);
        this.cdr.markForCheck();
        this.makeCalculations();
    })
  }
  /** create generic job structure data and push into list for copy-paste */
  createGJObj(newGJData, rec) {
    this.viewingJSON[rec.id]["NGJ"].push({
      traverse: newGJData.traverse,
      id: newGJData.id,
      name: newGJData.name,
      price: newGJData.price,
      quantity: newGJData.quantity,
      non_taxable: newGJData.non_taxable,
      level: newGJData.level
    });
  }
  /** create part object for copy-paste */
  createPartObj(part, partId?: any) {
    let price: any = (typeof part.price != 'undefined' ? part.price : part.part_price);
    if (typeof part.part_matrix_status != 'undefined' && part.part_matrix_status == 1) {
      price = part.part_matrix_price;
    }
    let invPrice: any = (typeof part.price != 'undefined' ? part.price : part.part_price);
    if (typeof part.inventory_price != 'undefined') {
      invPrice = part.inventory_price;
    }
    let partObj = {
      traverse: part.traverse,
      id: (partId ? part.id : part.part_id),
      part_number: part.part_number,
      part_name: part.part_number + (this.showCostPrice ? " (" + part.cost + ") " : '') + part.name + ' ' + part.description,
      part_price: price,
      part_qty: (typeof part.quantity != 'undefined' ? part.quantity : part.part_qty),
      non_taxable: part.non_taxable,
      description: part.description,
      partstech: 0,
      partstech_order: 0,
      nexpart:0,
      nexpart_order:0,
      level: part.level,
      is_editable: false,
      is_disabled: false,
      part_matrix_price: part.part_matrix_price,
      stock_quantity: part.stock_quantity,
      low_stock_value: part.low_stock_value,
      quantity_overused: 0,
      inventory_price: invPrice,
      suggestion_parts_count: part.suggestion_parts_count,
      force_override:0,
      partstech_labor_time:0,
      partstech_labor_notes:[]
    }
    return partObj;
  }
  partStruct(opt) {
    return {
      traverse: "",
      id: opt.id,
      part_name: opt.label,
      part_price: 0,
      part_qty: opt.quantity,
      non_taxable: opt.non_taxable,
      part_number: opt.part_number,
      description: "",
      partstech: 0,
      partstech_order: 0,
      level: 2,
      is_editable: false,
      is_disabled: false,
      stock_quantity: opt.stock_quantity,
      low_stock_value: opt.low_stock_value,
      out_of_stock: opt.out_of_stock,
      quantity_overused: opt.quantity_overused,
      part_matrix_price: opt.part_matrix_price,
      partstech_price: 0,
      inventory_price: opt.price,
      suggestion_parts_count: opt.suggestion_parts_count,
      force_override:0,
      partstech_labor_time:0,
      partstech_labor_notes:[]
    }
  }
  
  partStructNexpart(opt) {
    return {
      traverse: "",
      id: opt.id,
      part_name: opt.label,
      part_price: 0,
      part_qty: opt.quantity,
      non_taxable: opt.non_taxable,
      part_number: opt.part_number,
      description: "",
      nexpart: 0,
      nexpart_order: 0,
      level: 2,
      is_editable: false,
      is_disabled: false,
      stock_quantity: opt.stock_quantity,
      low_stock_value: opt.low_stock_value,
      out_of_stock: opt.out_of_stock,
      quantity_overused: opt.quantity_overused,
      part_matrix_price: opt.part_matrix_price,
      partstech_price: 0,
      inventory_price: opt.price,
      suggestion_parts_count: opt.suggestion_parts_count,
      force_override:0
    }
  }
  /** check clipboard data */
  isPaste(obj) {
    let p = this.globalService.copyJob('get');
    let c=false;
    if(typeof obj.children == 'undefined'){
      c=true;
    }else if(typeof obj.children != 'undefined' && obj.children == 0){
      c=true;
    }
    let cond=(p && typeof p != 'undefined' ? p.length : false);
    return (cond && c ? true : false) ;
  }
  /** choose paste option from clipboard */
  pasteJobData(job,mainJob,currentjobId) {
    let jobName= null; //level2 Job name
    if(typeof(job.children) !== 'undefined' && job.children.length > 0){
       let chileJobName = job.children.find(job => job.id === currentjobId);
       jobName = chileJobName.name;
    }
    let jobNameObj = {
      level1JobName:mainJob.name,
      level2JobName:job.name,
      level3JobName:jobName
    };
    let dialogRef = this.dialog.open(RecommpasteComponent, {
      panelClass: ['car-dialog-form'],
      width: '1200px',
      data: { recom: job, requestId: this.serviceRequestId,jobNameObj,currentjobId:currentjobId }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 1 && typeof dialogRef["jobs"] != 'undefined') {
        if(dialogRef["jobs"].isSameJobSelected == true){
          this.addPreRecommToJobs(dialogRef["jobs"].job, job,currentjobId);
        }else{
             let jobDataForPaste = dialogRef["jobs"] ;
             let jobFoundInSR = this.findJobInSR(jobDataForPaste.job);
             if(jobFoundInSR == true){
             this.holdJobDataFromPaste = dialogRef["jobs"].job;
             this.findJobAndCreateStatus = true;
             let newJobObj :any = {};
             if(this.holdJobDataFromPaste.l3 !== ''){
              newJobObj = {
                input:"checkbox",
                level1Id: this.holdJobDataFromPaste.recomm[0].level1ParentId,
                level1Name: this.holdJobDataFromPaste.l1,
                level2Id:  this.holdJobDataFromPaste.recomm[0].level2ParentId,
                level2Name: this.holdJobDataFromPaste.l2,
                level3Id: this.holdJobDataFromPaste.recomm[0].service_request_list_id,
                level3Name: this.holdJobDataFromPaste.l3,
                value: this.holdJobDataFromPaste.l1 +"->"+ this.holdJobDataFromPaste.l2 + "->"+ this.holdJobDataFromPaste.l3 
               };
             }else {
              newJobObj = {
                input:"checkbox",
                level1Id: this.holdJobDataFromPaste.recomm[0].level1ParentId,
                level1Name: this.holdJobDataFromPaste.l1,
                level2Id:  this.holdJobDataFromPaste.recomm[0].service_request_list_id,
                level2Name: this.holdJobDataFromPaste.l2,
                level3Id: "",
                level3Name: "",
                value: this.holdJobDataFromPaste.l1 +" -> "+ this.holdJobDataFromPaste.l2
              };
             }
              this.getSelectedOptionForx(newJobObj);
              this.addNewJobs();
             }
        }
      }
      this.cdr.markForCheck();
    });
  }

  findJobInSR(jobData) {
    let level1id = jobData.recomm[0].level1ParentId;
    let createNewJob = false;
    for (let i = 0; i < this.serviceRequestList.length; i++) {
      if (this.serviceRequestList[i].id == level1id) {
        if (this.serviceRequestList[i].children.length > 0) {
          let checkLoopBrk = false;
          for (let j = 0; j < this.serviceRequestList[i].children.length; j++) {
            if (typeof (this.serviceRequestList[i].children[j].children) !== 'undefined' && this.serviceRequestList[i].children[j].children.length > 0) { //check level 3 job
              for(let k = 0; k< this.serviceRequestList[i].children[j].children.length;k++){
              if (this.serviceRequestList[i].children[j].children[k].recommendations[0].service_request_list_id == jobData.recomm[0].service_request_list_id) {
                this.addPreRecommToJobs(jobData, this.serviceRequestList[i].children[j].children[k]);
                this.expansionOpen(i + 1);
                checkLoopBrk = true;
                createNewJob = false;
                break;
              } else {
                createNewJob = true;
              }
            }
            } else {
              if (this.serviceRequestList[i].children[j].recommendations[0].service_request_list_id == jobData.recomm[0].service_request_list_id) { // level 2nd job check
                this.addPreRecommToJobs(jobData, this.serviceRequestList[i].children[j]);
                this.expansionOpen(i + 1);
                checkLoopBrk = true;
                createNewJob = false;
                break;
              } else {
                createNewJob = true;
              }
            }
          }
          if (checkLoopBrk) {
            break;
          }
        }
        else {
          createNewJob = true;
        }
      } else {
        createNewJob = true;
      }
    }
    return createNewJob;
  }
 
  /** add recommendation from clipboar to existing jobs */
  addPreRecommToJobs(rec, job,currentJobId:any=null) {
    this.findJobAndCreateStatus = false;
    let recom = rec.recomm[0];
    let part: any = '';
    // let r = (typeof job.recommendations[0] == 'undefined' ? job.children[0].recommendations[0] : job.recommendations[0]);
    let r;
    if(typeof job.recommendations[0] == 'undefined'){
      if(currentJobId !== null){
        let reco = job.children.find(job => job.id === currentJobId);
        r = reco.recommendations[0];
      }else{
        let reco = job.children.find(job => job.id === rec.id);
        r = reco.recommendations[0];
      }
    }else{
      r = job.recommendations[0];
    }
    let level = (typeof r.job_level != "undefined" ? r.job_level : r.level);
    
    let traverse = r.service_request_list_id + "|";
    if (level == 2) {
      traverse = traverse + r.level1ParentId;
    } else {
      traverse = traverse + r.level2ParentId + "|" + r.level1ParentId;
    }
    if (recom && typeof recom.parts != 'undefined' && recom.parts.length) {
      for (var i in recom.parts) {
        let p: any = recom.parts[i];
        p.level = level;
        p.traverse = traverse;
        part = this.createPartObj(recom.parts[i], false);
        var isAlreadyPresentInNP = this.viewingJSON[r.id]['NP'].find((x) => {
          return x.id == part.id
        });
        var isAlreadyPresentInAP = this.viewingJSON[r.id]['AP'].find((x) => {
          return x.id == part.id
        });
        if (isAlreadyPresentInAP != undefined || isAlreadyPresentInNP != undefined) {

        } else {
          this.viewingJSON[r.id]["NP"].push(part);
          this.viewingJSON[r.id]["UNP"].push(part);
        }
      }
    }
    if (recom && typeof recom.generic_jobs != 'undefined' && recom.generic_jobs.length) {
      for (var i in recom.generic_jobs) {
        let g = recom.generic_jobs[i];
        g.id = recom.generic_jobs[i].generic_job_id;
        g.traverse = traverse;
        g.level = level;
        var alNGJ=this.viewingJSON[r.id]["NGJ"].find((x) => {
          return x.id == g.id;
        });
        var alAGJ=this.viewingJSON[r.id]["AGJ"].find((x) => {
          return x.generic_job_id == g.id;
        });
        if (alAGJ != undefined || alNGJ != undefined) {

        }else{
          this.createGJObj(g, r);
        }
      }
    }
    if (recom) {
      if (level == 2) {  
        let esLabour=this.tempReports[r.level1ParentId][r.service_request_list_id]["estimated_labor"];
        let esTime=this.tempReports[r.level1ParentId][r.service_request_list_id]["estimated_time"];
        let recomtxt=this.tempReports[r.level1ParentId][r.service_request_list_id]['recommendation'];
        if (recomtxt.length == 0) {
          recomtxt = recomtxt + "" + recom.recommendation;
        } else {
          recomtxt = recomtxt + '\n' + recom.recommendation;
        }
        let noteTxt= this.tempReports[r.level1ParentId][r.service_request_list_id]['notes'];
        if (noteTxt.length == 0) {
          noteTxt = noteTxt + `PREVIOUS NOTES: \n` + recom.notes;
        } else {
          noteTxt = noteTxt + `\nPREVIOUS NOTES: \n` + recom.notes;
        }
        let nexpart_labor =recom.nexpart_labor_time; 
        let tempTime = 0;
        if(!isNaN(parseFloat(esTime))){
          esTime= parseFloat(esTime) + parseFloat(recom.estimated_time);
          if(this.isNextPartStatus || this.currentUser.subscription_data.nexpart == 1){
            tempTime = esTime + parseFloat(nexpart_labor);
            this.tempReports[r.level1ParentId][r.service_request_list_id]["nexpart_labor_time"]=nexpart_labor;
          }else{
            tempTime = esTime;
            this.tempReports[r.level1ParentId][r.service_request_list_id]["nexpart_labor_time"]=0;
          }
        }else{
          esTime= recom.estimated_time;
          tempTime = esTime;
        }
        esLabour = (parseFloat(this.tempReports[r.level1ParentId][r.service_request_list_id]["job_labor_rate"]) * tempTime).toFixed(2);
        this.tempReports[r.level1ParentId][r.service_request_list_id]["estimated_labor"]=esLabour;
        this.tempReports[r.level1ParentId][r.service_request_list_id]["estimated_time"]=esTime;
        this.tempReports[r.level1ParentId][r.service_request_list_id]['recommendation'] = recomtxt;
        this.tempReports[r.level1ParentId][r.service_request_list_id]["rec"] = recomtxt;
        this.tempReports[r.level1ParentId][r.service_request_list_id]['notes'] = noteTxt;
        this.viewingJSON[r.id]["labour"] = {
          price: esLabour,
          level: level,
          traverse: traverse
        }
      } else { 
        let esLabour=this.tempReports[r.level1ParentId][r.level2ParentId][r.service_request_list_id]["estimated_labor"];
        let esTime=this.tempReports[r.level1ParentId][r.level2ParentId][r.service_request_list_id]["estimated_time"];
        let recomtxt=this.tempReports[r.level1ParentId][r.level2ParentId][r.service_request_list_id]['recommendation'];
        if (recomtxt.length == 0) {
          recomtxt = recomtxt + "" + recom.recommendation;
        } else {
          recomtxt = recomtxt + '\n' + recom.recommendation;
        }
        let noteTxt= this.tempReports[r.level1ParentId][r.level2ParentId][r.service_request_list_id]['notes'];
        if (noteTxt.length == 0) {
          noteTxt = noteTxt + `PREVIOUS NOTES: \n` +  recom.notes;
        } else {
          noteTxt = noteTxt + `\nPREVIOUS NOTES: \n` + recom.notes;
        }
        let nexpart_labor =recom.nexpart_labor_time; 
        let tempTime = 0;
        if(!isNaN(parseFloat(esTime))){
          esTime= parseFloat(esTime) + parseFloat(recom.estimated_time);
          if(this.isNextPartStatus || this.currentUser.subscription_data.nexpart == 1){
               tempTime = esTime + parseFloat(nexpart_labor);
               this.tempReports[r.level1ParentId][r.level2ParentId][r.service_request_list_id]["nexpart_labor_time"]=nexpart_labor;
          }else{
            tempTime = esTime;
            this.tempReports[r.level1ParentId][r.level2ParentId][r.service_request_list_id]["nexpart_labor_time"]=0;
          }
        }else{
          esTime= recom.estimated_time;
          tempTime = esTime;
        }
        esLabour = (parseFloat(this.tempReports[r.level1ParentId][r.level2ParentId][r.service_request_list_id]["job_labor_rate"]) * tempTime).toFixed(2);
        this.tempReports[r.level1ParentId][r.level2ParentId][r.service_request_list_id]["estimated_labor"] = esLabour;
        this.tempReports[r.level1ParentId][r.level2ParentId][r.service_request_list_id]["estimated_time"] = esTime;
        this.tempReports[r.level1ParentId][r.level2ParentId][r.service_request_list_id]['recommendation'] = recomtxt;
        this.tempReports[r.level1ParentId][r.level2ParentId][r.service_request_list_id]['notes'] = noteTxt;
        this.tempReports[r.level1ParentId][r.level2ParentId][r.service_request_list_id]["rec"] = recomtxt;
        this.viewingJSON[r.id]["labour"] = {
          price: esLabour,
          level: level,
          traverse: traverse
        }
      }
      if (typeof recom.computer_codes != 'undefined' && recom.computer_codes.length) {

        for (var i in recom.computer_codes) {
          let opt = recom.computer_codes[i];
          let cc = {
            traverse: traverse,
            id: opt.computer_code_id,
            fault_location: opt.fault_location,
            trouble_code: opt.trouble_code
          }
          var alreadypresent = this.viewingJSON[r.id]["NCC"].find((x) => {
            return x.id == cc.id;
          });
          var alreadypresentACC = this.viewingJSON[r.id]["ACC"].find((x) => {
            return x.computer_code_id == cc.id;
          });
          if (alreadypresent != undefined || alreadypresentACC != undefined) {

          }else {
            this.viewingJSON[r.id]["NCC"].push(cc);
          }
        }
      }
    }   
    this.cdr.markForCheck();
    this.makeCalculations();
  }
  carAttribute() {
    return false;
  }
  autocompleListFormatter(opt) {
    return opt.value;
  }
  observableSourceForx = (keyword: any): Observable<any[]> => {
    let url: string = globalConstants.API_search_service_requests_list + '?keyword=' + keyword;
    if (typeof keyword != 'undefined' && keyword.length >= 3) {
      let f = [];
      return this.globalService.getAPI(url).map(res => {
        let json = res;
        this.globalService.authText(json);
        if (json && json != null && typeof json.data != 'undefined') {
          json.data.forEach(function (x) {
            f.push(x);
          });
        }
        return f;
      });
    } else {
      return Observable.of([]);
    }
  }
  /** delete selected job */
  deleteNewJob(i, j, k?: any) {
    this.globalService.confirmDialog("yes", "Are you sure?").subscribe((response) => {
      if (response != undefined && response.toString() == "yes") {
        if (typeof k == 'undefined') {
          this.newJobs[i].child.splice(j, 1);
        } else {
          this.newJobs[i].child[j].child.splice(k, 1);
          if (this.newJobs[i].child[j].child.length == 0) {
            this.newJobs[i].child.splice(j, 1);
          }
        }
        if (this.newJobs[i].child.length == 0) {
          this.newJobs.splice(i, 1);
        }
        this.cdr.markForCheck();
      }
    });
  }
  /** submit new selected job */
  /**
   * @author Vikas Kumar First save recommendations and then save new jobs
   * @trackID <:mail>
   * @created 29-04-2020
   */
  addNewJobs() {
     this.controlledReinitOnJobAdd = true;
     this.postSaveAndStay(0);
  }

  addJobs(){
    let data = { new_jobs: JSON.stringify(this.newJobs), user_type: this.globalService.getCurrentUser()["user_type"], user_id: this.globalService.getCurrentUser()["id"], request_id: this.serviceRequestId };
    let formdata = this.globalService.formsData(data);
    this.globalService.formData(globalConstants.API_ADD_SERVICE_JOBS, formdata).subscribe((r:any) => {
      if(r.result==1){
        this.serviceRequest.controls.access_timestamp.setValue(r.access_timestamp);
        this.accessTimestamp = r.access_timestamp;
        this.globalService.snackbar("success", r.message);
        this.controlledReinitOnJobAdd = false;
        if(this.requestCreationType == 1){
          this.router.navigate(["/shops/list-service-requests"]);
        }else if(this.requestCreationType == 2){
            this.postSaveAndSend(this.requestCreationType);
        }else if(this.requestCreationType == 3){
             this.postSaveAndComplete(this.requestCreationType);
        }else{
           this.reinit();
        }
      }else{
        this.globalService.snackbar("error", r.message);
      }
    }, (error) => { })
  }
  /** find data then return index */
  findNewJobs(data, opt) {
    let th = this;
    let I = null;
    let f = data.find((x, v) => {
      let jf = false;
      if (x.id == opt.id) {
        jf = true; I = v;
      }
      return jf;
    });
    return (typeof f == 'undefined' ? false : I);
  }
  /** add selected job */
  getSelectedOptionForx(opt) {
    let s1 = { id: 0, ln: '', child: [] };
    let s2 = { id: 0, ln: '', child: [] };
    let s3 = { id: 0, ln: '', type: '', value: '' };
    if (opt && typeof opt.level3Id != 'undefined' && opt.level3Id != '') {
      s3.id = opt.level3Id;
      s3.ln = opt.level3Name;
      s3.type = opt.input;
    }
    if (opt && typeof opt.level2Id != 'undefined') {
      s2.id = opt.level2Id;
      s2.ln = opt.level2Name;
    }
    if (opt && typeof opt.level1Id != 'undefined') {
      s1.id = opt.level1Id;
      s1.ln = opt.level1Name;
    }
    let isAddedl3 = this.alreadyAdded(s3,0);
    let isGroupFound = ( isAddedl3 ? false : this.alreadyAdded(s3,s2.id) );
    let isAdded = this.alreadyAdded(s2,0);
    if(isGroupFound && s3.type == 'radio'){
      this.globalService.snackbar("error", 'Only one job can be selected from radio group ' + s1.ln + ' -> ' + s2.ln + '.');
    }else if (isAdded || isAddedl3) {
      this.globalService.snackbar("error", 'Job already exists.');
    }
    if (isAdded || isAddedl3 || isGroupFound){
      this.clearSearch();
      return false;
    }
    let isAlreadyAdded = false;
    let f = this.findNewJobs(this.newJobs, s1);
    if (f === false && s1.id != 0) {
      if (s3.id != 0) {
        s2.child.push(s3);
      }
      s1.child.push(s2);
      this.newJobs.push(s1);
    } else if (typeof this.newJobs[f] != 'undefined') {
      let f2 = this.findNewJobs(this.newJobs[f].child, s2);
      if (f2 === false && s2.id != 0) {
        if (s3.id != 0) {
          s2.child.push(s3);
        }
        this.newJobs[f].child.push(s2);
      } else if (s3.id != 0) {
        if (s3.type == 'radio' && this.newJobs[f].child[f2].child.length) {
          this.globalService.snackbar("error", 'Only one job can be selected from radio group ' + s1.ln + ' -> ' + s2.ln + '.');
        } else {
          let f3 = this.findNewJobs(this.newJobs[f].child[f2].child, s3);
          if (f3 === false) {
            this.newJobs[f].child[f2].child.push(s3);
          } else {
            isAlreadyAdded = true;
          }
        }

      } else {
        isAlreadyAdded = true;
      }

      if (isAlreadyAdded) {
        this.globalService.snackbar("error", 'This option already selected');
      }

    }
    this.clearSearch();
  }
  clearSearch(){
    let txt = <HTMLInputElement>document.getElementById('searchAddJobAattribute')
    txt.value = '';
  }
  /** find job already in service request */
  alreadyAdded(obj,rg) {
    let list = this.serviceRequestList;
    let jobFound = false;
    let f = list.forEach((v, i) => {
      if (v.children) {
        v.children.forEach((x) => {
          if (x.recommendations.length) {
            let recomId = x.recommendations[0].service_request_list_id;
            if (recomId == obj.id) {
              jobFound = true;
              return true;
            }
          } else {
            x.children.forEach((d) => {
              let recomId = d.recommendations[0].service_request_list_id;
              if (recomId == obj.id) {
                jobFound = true;
                return true;
              }else if(obj && obj.type=='radio' && rg==d.recommendations[0].level2ParentId){
                jobFound = true;
                return true;
              }
            });
          }
          if (jobFound) {
            jobFound = true;
            return true;
          }
        });
      }
      if (jobFound) {
        jobFound = true;
        return true;
      }
    });
    return jobFound;
  }
  /** add text to selected job
   *  if it is textarea */
  addNewJobComment(obj, i, j, k) {
    this.newJobs[i].child[j].child[k].value = obj.target.value;
  }
  /** show warning to user make sure all data is saved before adding new job */
  showWaring(){
    if(this.newJobs.length==0){
      this.globalService.snackbar('error', "Make sure you have saved the recommendations changes before adding new jobs.",{ component:this,method :'saveAndStay',param:0});
    }
  }
  updateWaitingForPartStatus(value){
    this.showLabelsDropdown = false;
    let checked: any = value.checked == true ? 1:0;
    let data={request_id:this.serviceRequestId,user_id:this.globalService.getCurrentUser()["id"],user_type:this.globalService.getCurrentUser()["user_type"],parts_awaited:checked}
    this.globalService.callAPI(globalConstants.API_UPDATE_PARTS_WAITING_STATUS,data).subscribe((res:any)=>{
      if(res.result==1){
        if(checked == 1){
         this.preSelectLabel.push('WAITING_FOR_PARTS');
         this.showLabelsDropdown = true;
      }else{
        this.removeCustomLabelInPreSelectLabel('WAITING_FOR_PARTS');
        this.showLabelsDropdown = true;
      } 
        this.globalService.snackbar("success", res.message);
      }else{
        this.showLabelsDropdown = true;
        this.globalService.snackbar("error", res.message);
      }
    },(error)=>{})
  }
  /** open service request notes list */
  srnoteList(){
    this.globalService.setServiceNoteOpen()
  }
  // srnoteList() {
  //   this.globalService.localGetterSetter('SRNoteListId',this.serviceRequestId, '');
  //   let dialogRef = this.dialog.open(SrnotelistComponent, {
  //       panelClass: 'dialog-view-note',
  //   });
  // }
  messages() {
    let dialogRef = this.dialog.open(MessagelistComponent, {
      panelClass: ['car-dialog-form','message-dialog-list'],
      width: '800px',
      data: { request_id: this.serviceInfo.request_id, requestId: this.serviceRequestId }
    });
  }
  priceHide() {
    let isShow=this.globalService.getAcl('display_part_cost','views');
    if(isShow){
      if(this.hidePrices){
        this.hidePrices=false
      }else{
        this.hidePrices=true
      }
    }
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }
  quickQuoteView() {
    let qq=this.qqDetail
    let dialogRef = this.dialog.open(QqviewComponent, {
      panelClass: ['quickqouotedialog', 'quickqouotview'],
      width: "100%",
      data: { id: qq.quote_id }
    });
  }
  /** send message using io socket */
  infoOther() {
    this.sendIO = true
    this.globalService.sendMessage({'id': this.serviceRequestId });
  }
  stopBubbling(ev:any){
    ev.stopPropagation();
    ev.preventDefault();
  }
  public closeRefresh=false
  closeOrfresh(obj) {
    let msg = JSON.parse(obj)
    if (msg && typeof msg.id != 'undefined') {

      if ((!this.sendIO) && this.serviceRequestId == msg.id) {
        this.sendIO = false
        if (!this.closeRefresh) {
          this.closeRefresh = true
          let dialogRef = this.dialog.open(CloseOrRefresh, {
            disableClose: true,
            panelClass: 'payment-dialog',
          });
          dialogRef.afterClosed().subscribe(result => {
            this.closeRefresh = false
            if (typeof result != 'undefined' && result == 2) {
              this.reinit();
            }
          })
        }
      } else if (this.serviceRequestId == msg.id) {
        this.sendIO = false
      }
    }
  }
  /**
   * @author Vikas Kumar First save recommendations and then save new jobs
   * @trackID <:mail>
   * @created 29-04-2020
   */
  saveRecAfterJobAdd(){
        let type=1
        /** these line exec after snackbar save now button */
        this.saveRecommendationConfirm=0;
        this.serviceRequest.controls.report.setValue(JSON.stringify(this.tempReports));
        this.serviceRequest.controls.is_labor_tax_excluded.setValue(this.isLaborTax?1:0);
        var getAllKeys = Object.keys(this.viewingJSON);
        var temparr = []
        for (var i = 0; i < getAllKeys.length; i++) {
          var tempPartObjToSend = []
          var total = this.viewingJSON[getAllKeys[i]]["AP"] + this.viewingJSON[getAllKeys[i]]["NP"];
          this.viewingJSON[getAllKeys[i]]["AP"].forEach(function (ap) {
            let forceOverride=0;
            if(typeof ap.force_override!='undefined'){
              forceOverride=ap.force_override
            }
            var sendingPartObj = ap.id + "|" + ap.part_price + "|" + ap.part_qty + "|" + ap.non_taxable+"|"+forceOverride;
            var traverse = ap.traverse;
            var l2Id;
            var l1Id;
            var l3Id;
            var d;
            if (ap.level == 3) {
              l1Id = ap.traverse.split("|")[2];
              l2Id = ap.traverse.split("|")[1];
              l3Id = ap.traverse.split("|")[0];
              d = {
                [l1Id]: {
                  [l2Id]: {
                    [l3Id]:
                    {
                      "Parts": sendingPartObj,
                    }
                  }
                }
              }
            } else if (ap.level == 2) {
              l1Id = ap.traverse.split("|")[1];
              l2Id = ap.traverse.split("|")[0];
              d = {
                [l1Id]: {
                  [l2Id]: {
                    "Parts": sendingPartObj
                  }
                }
              }
            }
            temparr.push(d);
            // this.serviceRequest.controls.report(temparr)
          });

          this.viewingJSON[getAllKeys[i]]["NP"].forEach(function (ap) {
            let forceOverride=0;
            if(typeof ap.force_override!='undefined'){
              forceOverride=ap.force_override
            }
            var sendingPartObj = ap.id + "|" + ap.part_price + "|" + ap.part_qty + "|" + ap.non_taxable+"|"+forceOverride;
            var traverse = ap.traverse;
            var l2Id;
            var l1Id;
            var l3Id;
            var d;
            if (ap.level == 3) {
              l1Id = ap.traverse.split("|")[2];
              l2Id = ap.traverse.split("|")[1];
              l3Id = ap.traverse.split("|")[0];
              d = {
                [l1Id]: {
                  [l2Id]: {
                    [l3Id]:
                    {
                      "Parts": sendingPartObj,
                    }
                  }
                }
              }
            } else if (ap.level == 2) {
              l1Id = ap.traverse.split("|")[1];
              l2Id = ap.traverse.split("|")[0];
              d = {
                [l1Id]: {
                  [l2Id]: {
                    "Parts": sendingPartObj
                  }
                }
              }
            }
            temparr.push(d);
          });

          temparr.forEach((part) => {
            var l1 = Object.keys(part)[0];
            Object.keys(part[Object.keys(part)[0]])
            var l2 = Object.keys(Object.keys(part))[0];
            var l3 = null;
            if (Object.keys(Object.keys(Object.keys(part)))) {
              //has level 3
              l3 = Object.keys(Object.keys(Object.keys(part)))[0]
            }
          })
        }
        this.makeChangesInSendingObjectToAddNewParts(temparr,type);
  }

  /**
   * Function to handle onCouponAdded event of quick-actions component
   * @param coupon - Code of coupon to be added to current service request.
   * @createdBy Shashank Thakur
   * @trackID <:CAR-1571>
   * @created 10-08-2020
   */
  onCouponAdded(coupon:string){
    // call save recommendation method by adding add coupon controls.
    this.addCouponControls(coupon, 1);
    this.postSaveAndStay(0,true);
  }

  OnPaymentAdded(status: string){
    if(status === 'true'){
      this.reinit();
    }
  }

  calculateCCFee(event){    
    let tPaid  = this.serviceInfo.total_paid?parseFloat(this.serviceInfo.total_paid):0;
    if(this.serviceInfo.cc_fee_type == '2'){
      this.totalCCfeeValue  = ((parseFloat(this.grandTotal)-tPaid)  * this.serviceInfo.cc_fee)/100;
    }else{
      this.totalCCfeeValue  = this.serviceInfo.cc_fee;
    }
    if(event.checked) {
      this.serviceRequest.controls.cc_fee_included.setValue('1');
    }
    else{
      this.serviceRequest.controls.cc_fee_included.setValue('0');
    }
    this.calculateNetPayable();
  }

  calculateNetPayable(){
    let tPaid  = this.serviceInfo.total_paid?parseFloat(this.serviceInfo.total_paid):0;    
    this.netPayable  = (parseFloat(this.grandTotal)-tPaid);
    if(this.serviceRequest.value.cc_fee_included == '1'){    
      this.netPayable  = parseFloat(this.grandTotal)-tPaid+parseFloat(this.totalCCfeeValue.toString());
      this.isAddInPayment = true;
    }else{
      this.netPayable  = parseFloat(this.grandTotal)-tPaid;
      this.isAddInPayment = false;
    }
  }

  totalNetPayed(amount, ccFee){
    return parseFloat(amount) + parseFloat(ccFee);
  }

  onTicketAssignment($event){ 
    this.techData= $event;
  }
  
  techAssignmentOut($event:any){
    if ($event.assignedToSelf) {
        this.userTaskTrack  = 0;
        this.startTracker();
    }
    this.techData = {};
  }
  /**
   * Function to set all the conditions for initializing redeem coupon component.
   */
  setCouponRedeemConditions(){
    this.couponRedeemConditions.push(this.globalService.isSubscribe(globalConstants.SUBSCRIPTION_DISCOUNTS_FEATURE));
  }
  
  /**
   * Function for open the suggestion dialog box 
   */
  showJobMatchDialog(SRData:any,jobData:any,currentJobId) {
    let jobId = jobData.id; //level2 JobId
    let jobName= null; //level2 Job name
    if(typeof(jobData.children) !== 'undefined' && jobData.children.length > 0){
        let childJobName = jobData.children.find(job => job.id === currentJobId);
         jobId = childJobName.id; //level2 JobId
        jobName = childJobName.name; //level3 Job name
    }
    let SRInfo={
         jobIds:jobId,
         vehicleID:this.serviceInfo.car_id,
         jobName:{
               level1JobName:SRData.name,
               level2JobName:jobData.name,
               level3JobName:jobName
         },
         vehicleInfo:{
             year: this.serviceInfo.year,
             make: this.serviceInfo.make,
             model: this.serviceInfo.model,
             sub_model : this.serviceInfo.sub_model
         },
         vehicleName:this.serviceRequest.value.vehicle,
         jobData:jobData,
         serviceReqId: this.serviceInfo.id
    };
    let info: any = {
      id: 'Job-match-dailog',
      name: 'Job Match Suggestion',
      module: 'job Suggestion'
    };
    let data: any = {SRData:SRInfo};
    this.globalService.openMinimizablePopup(JobMatchDialogComponent, info, data);
  }

  checkPartsTechLaborSetting() {
    let PTLaborSetting: any = this.serviceInfo.partstech_settings;
    if (PTLaborSetting.chk_pt_labor_job_desc == "1" && this.serviceInfo.partstech_labor == 1) {
      if (PTLaborSetting.chk_pt_labor_job_desc_notes == '1' && PTLaborSetting.chk_pt_labor_job_desc_title == '1') {
        this.PTLaborSettingTypeDOW = 3;
      } else if (PTLaborSetting.chk_pt_labor_job_desc_notes == '1') {
        this.PTLaborSettingTypeDOW = 2;
      } else if (PTLaborSetting.chk_pt_labor_job_desc_title == '1') {
        this.PTLaborSettingTypeDOW = 1;
      }
      if( PTLaborSetting.chk_pt_labor_job_desc_notes_prefix == '1'){
        this.PTLaborPrefixSetting = 1;
      }
    }
    if (PTLaborSetting.chk_pt_labor_job_tooltip == "1" && this.serviceInfo.partstech_labor == 1) {
      if (PTLaborSetting.chk_pt_labor_job_tooltip_notes == '1' && PTLaborSetting.chk_pt_labor_job_tooltip_title == '1') {
        this.PTLaborSettingTypeTooltip = 3;
      } else if (PTLaborSetting.chk_pt_labor_job_tooltip_notes == '1') {
        this.PTLaborSettingTypeTooltip = 2;
      } else if (PTLaborSetting.chk_pt_labor_job_tooltip_title == '1') {
        this.PTLaborSettingTypeTooltip = 1;
      }
    }
  }

  AddPartsInStockOrder(jobRecom: any) {
    let newPartList = this.viewingJSON[jobRecom[0].id]["NP"];
    if (newPartList.length > 0) {
      this.stockOrderBtnId = 'stockOrder'+jobRecom[0].service_request_list_id;
      this.autoSaveRecom().subscribe((res:any)=>{});
    } else {
      if (jobRecom[0].parts.length > 0) {
         this.openStockOrderWindow(jobRecom);
      }else{
        this.globalService.snackbar('error', "Please add parts in job in order to create a stock order.");
      }
    }
  }


   openStockOrderWindow(jobRecom: any){
    let dialogRef;
    let alredyPartsList = jobRecom[0].parts;
    let formData = new FormData();
    formData.append('user_id', this.currentUser.id);
    formData.append('user_type', this.currentUser.user_type);
    alredyPartsList.forEach(part => {
      if (part.partstech_cart_item == 0 && part.partstech_order_item == 0 && part.nexlink_cart_item == 0 && part.nexlink_order_item == 0) {
        formData.append('parts[' + part.part_id + ']', part.quantity);
      }
    });
    this.globalService.formData(globalConstants.API_GET_STOCK_ORDER_URL, formData)
      .subscribe((response) => {
        if (response.result == 1) {
          let stockOrderId = response.data.stock_order_id;
          let redirectUrl = response.data.redirect_url;
          let sessionId = response.data.session_id;
          dialogRef = this.dialog.open(VideoShowComponent, {
            panelClass: 'partechDialog',
            width: "100%",
            data: { 'iframe': redirectUrl, 'ref': sessionId,isPartsTechConfigured:this.serviceInfo.is_partstech_configured }
          });
          dialogRef.afterClosed().subscribe(result => {
            this.stockOrderBtnId = null;
            let formData = new FormData();
            formData.append('user_id', this.currentUser.id);
            formData.append('user_type', this.currentUser.user_type);
            formData.append('stock_order_id', stockOrderId);
            this.globalService.formData(globalConstants.API_GET_STOCK_ORDER_STATUS_URL, formData)
              .subscribe((response) => {
                if (response.result == '1') {
                  if (response.order_completed == '1') {
                    this.globalService.snackbar('success', response.message);
                  }
                } else {
                  this.globalService.snackbar('error', response.message);
                }
              });
          })
        } else {
          this.globalService.snackbar('error', response.message);
        }
      })
   }

   handleUpdateCustomerOrVehicleInfo(optionType){
    let passingObj =  {
      serviceRequestId:this.serviceInfo.id,
      openForm:"formVehicle",
      customerId: this.serviceInfo.customer_id,
      mobilePhone : this.serviceInfo.mobile_phone,
      emailId: this.serviceInfo.mobilePhone,
      firstName: this.serviceInfo.first_name,
      lastName: this.serviceInfo.last_name,
      make: this.serviceInfo.make,
      year: this.serviceInfo.year,
      model: this.serviceInfo.model,
      sub_model: this.serviceInfo.sub_model,
      vehicleVinNumber: this.serviceInfo.vin_number,
      vehicleLincenceNumber : this.serviceInfo.license_plate,
      vehicleId: this.serviceInfo.car_id,
      carRequestId: this.serviceInfo.request_id,
      isWalkinCustomer: this.serviceInfo.is_walkin_customer,
      isWalkinCustomerVehicles: this.serviceInfo.is_walkin_customer_vehicle
    };
    if(optionType == 1){
      passingObj['openForm']="formCustomer"
      }
    let dialogRef = this.dialog.open(ChangeCustomerVehicleComponent, {
      panelClass: 'change-customer-vehicle',
      width: "1600px",
      data: passingObj
    });
    dialogRef.afterClosed().subscribe(result => {
          if(result.status == 'success'){
            if(this.newJobs.length>0){
              this.controlledReinitOnJobAdd = true;
              this.handlePostUpdateCustomerOrVehicleInfo();
            }else{
               this.handlePostUpdateCustomerOrVehicleInfo();
            }
          }
    })
   }

   /**
    *  requestCreationType = 0 call saveAndStay function
    *  requestCreationType = 1 call saveAndClose function
    *  requestCreationType = 2 call saveAndSend function
    *  requestCreationType = 3 call saveAndComplete function
    */
   handlePostUpdateCustomerOrVehicleInfo(){
      switch (this.requestCreationType) {
                case 0:
                  this.saveAndStay(this.requestCreationType,true);
                break;
                case 1:
                  this.saveAndClose(this.requestCreationType,true);
                break;
                case 2:
                    if(this.newJobs.length>0){
                      this.handleUnSavedJobs(this.requestCreationType);
                    }else{
                      this.saveAndSend(this.requestCreationType, true);
                    }
                break;
                case 3:
                    if(this.newJobs.length>0){
                      this.handleUnSavedJobs(this.requestCreationType);
                    }else{
                      this.saveAndComplete(this.requestCreationType, true);
                    }
                break;
                default:
                  this.saveAndStay(this.requestCreationType,true);
                break;
              }
   }

   updateSRNotes(){
        let payload={
          request_id: this.serviceInfo.id,
          request_notes: this.serviceInfo.notes,
          user_type: this.currentUser.user_type,
          user_id: this.currentUser.id,
        }
        this.globalService.callAPI(globalConstants.API_EDIT_SERVICE_REQUEST_FIELDS_NOTES,payload).subscribe((res) => {
          if (res.result == 1) { 
            this.serviceInfo.notes = res.data.request_notes;
            this.serviceRequest.controls.access_timestamp.setValue(res.data.access_timestamp);
            this.accessTimestamp = res.data.access_timestamp;
          }
        })
   }

  handleOpenViewSRPage() {
    this.autoSaveRecom().subscribe((res: any) => {
      if (res) {
        this.router.navigateByUrl("/shops/view-service-request/" + this.serviceRequestId);
      }
    })
  }

  handleOpenEditSRPage() {
    let customerId = this.serviceInfo.customer_id;
    this.autoSaveRecom().subscribe((res: any) => {
      if (res) {
          this.router.navigateByUrl('shops/edit-service-request/' + this.serviceRequestId + "/" + customerId);
      }
    })
  }

   /**\
 * save and stay in SR when click on save button
 * @param type = 0
 */
  saveAndStay(type, skipAllPopUp) {  //revisit change type to requestCreationType
    this.requestCreationType = type;  // type 0 means save 
    let actionFlag = 0 // defualt popup display 
    let popUpDisplayType = "yes"; // defualt display type - yes/no
    let note = "Are you sure you want to continue ?";
    if (this.serviceInfo.is_walkin_customer == '1' || this.serviceInfo.is_walkin_customer_vehicle == '1') {
      actionFlag = 1;
    }
    if(this.serviceInfo.request_status==2 || this.serviceInfo.request_status==3 || this.serviceInfo.request_status==7 || this.serviceInfo.request_status==12 ){
      actionFlag = 0;
      popUpDisplayType = "yes"; // defualt display type - yes/no
      note = "Are you sure you want to continue ?";
    }
    if (!skipAllPopUp) {
      switch (actionFlag) {
        case 1:
          note = "SR is created for <b>Walkin Customer</b>. Do you want to change the customer or vehicle profile now?";
          popUpDisplayType = 'select_walkin_customer';
          this.globalService.confirmDialog(popUpDisplayType, note).subscribe((res: any) => {
            if (typeof (res) !== 'undefined') {
              this.handleUpdateCustomerOrVehicleInfo(1);
            } else {
              if (this.newJobs.length > 0) {
                this.controlledReinitOnJobAdd = true;
                this.postSaveAndStay(type);
              } else {
                this.postSaveAndStay(type);
              }
            }
          })
          break;
        case 0:
          this.globalService.confirmDialog(popUpDisplayType, note).subscribe((res: any) => {
            if (typeof (res) !== 'undefined') {
              if (this.newJobs.length > 0) {
                this.controlledReinitOnJobAdd = true;
                this.postSaveAndStay(type);
              } else {
                this.postSaveAndStay(type);
              }
            } else { // there select no 
               this.requestCreationType = null;
            }
          })
      }
    } else {
      this.postSaveAndStay(type);
    }
  }

  postSaveAndStay(type,addCopoun:boolean = false) {
    this.prepareRecommendationFormData(type,addCopoun);
    this.infoOther();
    this.serviceRequest.controls.requestCreationType.setValue(1);
    let data = this.undefinedToBlank();
    let payload = new FormData();
    for (let i in data) {
      payload.append(i, data[i]);
    }
 
    this.saveRecommendationAPI(payload).subscribe((response: any) => {
      if (response.result == "1") {
        this.serviceRequest.controls.access_timestamp.setValue(response.access_timestamp);
        this.accessTimestamp = response.access_timestamp;
        this.globalService.snackbar("success", response.message);
        if (this.controlledReinitOnJobAdd) {
          this.addJobs();
        } else {
          this.reinit();
        }
        // is from coupon
        if (this.isFromCoupon && response.data.discounts_list) {
          this.fillRecommendationCoupon.reInitialize(response.data);
          this.fillRecommendationCoupon.reset();
          this.removeCouponControls();
        }
        // For Code 518 - to handle invalid coupon codes
        if (response.code == 518 && response.data.discounts_list) {
          this.fillRecommendationCoupon.reInitialize(response.data);
          this.fillRecommendationCoupon.reset();
        }
        this.removeCouponControls();

        this.serviceRequest.controls.recommendation_saved.setValue(1);
      } else {
        if (response.access_timestamp) {
          this.serviceRequest.controls.access_timestamp.setValue(response.access_timestamp);
          this.accessTimestamp = response.access_timestamp;
        }
        if (response.code == 590 && response.data.discounts_list) {
          this.fillRecommendationCoupon.reInitialize(response.data);
        }
        this.fillRecommendationCoupon.reset();
        this.removeCouponControls();
        if (response.result == "0" || response.result == "352") {
          let msg = response.message;
          this.globalService.snackbar('error', msg, { component: this, method: 'reinit', param: 1 }, true);
        } else {
          this.globalService.snackbar("error", response.message);
        }
      }
    });
  }

/**
 * save and clos the SR when click on close button
 * @param type=1 
 */
  saveAndClose(type, skipAllPopUp) {
    this.requestCreationType = type;
    let actionFlag = 0 // defualt popup display 
    let popUpDisplayType = "yes"; // defualt display type - yes/no
    let note = "Are you sure you want to continue ?";
    if (this.serviceInfo.is_walkin_customer == '1' || this.serviceInfo.is_walkin_customer_vehicle == '1') {
      actionFlag = 1;
    }
    if(this.serviceInfo.request_status==2 || this.serviceInfo.request_status==3 || this.serviceInfo.request_status==7 || this.serviceInfo.request_status==12 ){
      actionFlag = 0;
      popUpDisplayType = "yes"; // defualt display type - yes/no
      note = "Are you sure you want to continue ?";
    }
    if (!skipAllPopUp) {
      switch (actionFlag) {
        case 1:
          note = "SR is created for <b>Walkin Customer</b>. Do you want to change the customer or vehicle profile now?";
          popUpDisplayType = 'select_walkin_customer';
          this.globalService.confirmDialog(popUpDisplayType, note).subscribe((res: any) => {
            if (typeof (res) !== 'undefined') { //click on yes
              this.handleUpdateCustomerOrVehicleInfo(1);
            } else { // click on skip
              if (this.newJobs.length > 0) {
                this.controlledReinitOnJobAdd = true;
                this.postSaveAndClose(type);
              } else {
                this.postSaveAndClose(type);
              }
            }
          })
          break;
        case 0:
          this.globalService.confirmDialog(popUpDisplayType, note).subscribe((res: any) => {
            if (typeof (res) !== 'undefined') { //click on yes
              if (this.newJobs.length > 0) {
                this.controlledReinitOnJobAdd = true;
                this.postSaveAndClose(type);
              } else {
                this.postSaveAndClose(type);
              }
            } else { // click on no option
              this.requestCreationType = null;
            }
          })
      }
    } else {
      this.postSaveAndClose(type);
    }
  }


  postSaveAndClose(type) {
    this.prepareRecommendationFormData(type);
    this.infoOther();
    this.serviceRequest.controls.requestCreationType.setValue(1);
    let data = this.undefinedToBlank();
    let payload = new FormData();
    for (let i in data) {
      payload.append(i, data[i]);
    }
    this.saveRecommendationAPI(payload).subscribe((response: any) => {
      if (response.result == "1") {
        this.serviceRequest.controls.access_timestamp.setValue(response.access_timestamp);
        this.accessTimestamp = response.access_timestamp;
        this.globalService.snackbar("success", response.message);
        if (this.controlledReinitOnJobAdd) {
          this.addJobs();
        } else {
          this.router.navigate(["/shops/list-service-requests"]);
        }
        this.saveRecommendationConfirm = "";
      } else {
        if (response.access_timestamp) {
          this.serviceRequest.controls.access_timestamp.setValue(response.access_timestamp);
          this.accessTimestamp = response.access_timestamp;
        }
        if (response.code == 590 && response.data.discounts_list) {
          this.fillRecommendationCoupon.reInitialize(response.data);
        }
        this.fillRecommendationCoupon.reset();
        this.removeCouponControls();
        if (response.result == "0" || response.result == "352") {
          let msg = response.message;
          this.globalService.snackbar('error', msg, { component: this, method: 'reinit', param: 1 }, true);
        } else {
          this.globalService.snackbar("error", response.message);
        }
      }
    });
  }

  /**
   * save and send the recommandation to client
   * @param type = 2
   */
  saveAndSend(type, skipAllPopUp) {
    this.requestCreationType = type;
    let actionFlag = 0 // defualt popup display 
    let popUpDisplayType = "yes"; // defualt display type - yes/no
    let note = "Are you sure you want to continue ?";
    if (this.serviceInfo.is_walkin_customer == '1' || this.serviceInfo.is_walkin_customer_vehicle == '1') {
      actionFlag = 1;
    }
    else if (this.newJobs.length > 0) {
      actionFlag = 2;
    }
    if (!skipAllPopUp) {
      switch (actionFlag) {
        case 0:
          this.globalService.confirmDialog(popUpDisplayType, note).subscribe((res: any) => {
            if (typeof (res) !== 'undefined') { //click on yes
              if (this.newJobs.length > 0) {
                // this.controlledReinitOnJobAdd = true; //revisite
                // this.postSaveAndStay(0); //revisite
                this.handleUnSavedJobs(this.requestCreationType);
              } else {
                this.postSaveAndSend(type);
              }
            } else { // click on no
              this.requestCreationType = null;
            }
          })
          break;
        case 1:
          note = "SR is created for <b>Walkin Customer</b>. Do you want to change the customer or vehicle profile now?";
          popUpDisplayType = 'select_walkin_customer';
          this.globalService.confirmDialog(popUpDisplayType, note).subscribe((res: any) => {
            if (typeof (res) !== 'undefined') { //click on yes
              this.handleUpdateCustomerOrVehicleInfo(1);
            } else { // click on skip
              if (this.newJobs.length > 0) {
                this.handleUnSavedJobs(this.requestCreationType);
              } else {
                this.postSaveAndSend(type);
              }
            }
          })
          break;
        case 2:
          this.handleUnSavedJobs(this.requestCreationType);
          break;
      }
    } else {
      this.postSaveAndSend(type);
    }
  }

  postSaveAndSend(type) {
    this.prepareRecommendationFormData(type);
    this.infoOther();
    this.serviceRequest.controls.requestCreationType.setValue(type);
    let data = this.undefinedToBlank();
    let payload = new FormData();
    for (let i in data) {
      payload.append(i, data[i]);
    }
    if (!this.techinianStatusDone) {
      payload.append('force_override', '1');
    } else {
      payload.append('force_override', '0');
    };
    this.saveRecommendationAPI(payload).subscribe((response: any) => {
      if (response.result == "1") {
        this.globalService.snackbar("success", response.message);
        this.router.navigate(["/shops/view-service-request/" + this.serviceRequest.value.id]);
      } else {
        if (response.code == 352) {
          let msg = response.message;
          this.globalService.snackbar('error', msg, { component: this, method: 'reinit', param: 1 }, true);
        } else {
          if (response.access_timestamp) {
            this.serviceRequest.controls.access_timestamp.setValue(response.access_timestamp);
            this.accessTimestamp = response.access_timestamp;
          }
          if (response.code == 166 || response.code == 167) {
            let ask = "Inspection report is not performed on this vehicle. Do you want to submit the report now ?";
            this.globalService.confirmDialog("ask_vehicle_ins", ask).subscribe((insDiagRes) => {
              let insDiagResult: any = insDiagRes;
              this.customerUpdateFormData = payload;
              switch (insDiagResult) {
                case 'no':
                case 'close':
                  this.requestCreationType = null;
                  this.autoSaveRecom().subscribe((res:any)=>{});
                  break;
                case 'skip':
                  this.requestCreationType = null;
                  this.customerUpdateFormData.set('no_report_performed', 'true');
                  this.submitCustomerUpdateForm();
                  break;
                case 'yes':
                  if (this.serviceInfo.is_usps_vehicle == '1') {
                    this.openInsceptionReport(2);
                  } else {
                    this.openVehicleInscpectionReport(2);
                  }
                  break;
              }
            });
          } else {
            this.globalService.snackbar('error', response.message);
          }
        }
      }
    })
  }


  /**
   * save and complate the SR 
   * @param type = 3
   */
  saveAndComplete(type, skipAllPopUp) {
    this.requestCreationType = type;
    let actionFlag = 0 // defualt popup display 
    let popUpDisplayType = "yes"; // defualt display type - yes/no
    let note = "Are you sure you want to continue ?";
    if (this.serviceInfo.is_walkin_customer == '1' || this.serviceInfo.is_walkin_customer_vehicle == '1') {
      actionFlag = 1;
    }
    else if (this.newJobs.length > 0) {
      actionFlag = 2;
    }
    if (!skipAllPopUp) {
      switch (actionFlag) {
        case 0:
          this.globalService.confirmDialog(popUpDisplayType, note).subscribe((res: any) => {
            if (typeof (res) !== 'undefined') { //click on yes
              if (this.newJobs.length > 0) {
                // this.controlledReinitOnJobAdd = true;
                // this.postSaveAndStay(0);
                this.handleUnSavedJobs(this.requestCreationType);
              } else {
                this.postSaveAndComplete(type);
              }
            } else { // click on no
              this.requestCreationType = null;
            }
          })
          break;
        case 1:
          note = "SR is created for <b>Walkin Customer</b>. Do you want to change the customer or vehicle profile now?";
          popUpDisplayType = 'select_walkin_customer';
          this.globalService.confirmDialog(popUpDisplayType, note).subscribe((res: any) => {
            if (typeof (res) !== 'undefined') { //click on yes
              this.handleUpdateCustomerOrVehicleInfo(1);
            } else { // click on skip
              if (this.newJobs.length > 0) {
                this.handleUnSavedJobs(this.requestCreationType);
              } else {
                this.postSaveAndComplete(type);
              }
            }
          })
          break;
        case 2:
          this.handleUnSavedJobs(this.requestCreationType);
          break;
      }
    } else {
      this.postSaveAndComplete(type);
    }
  }


  postSaveAndComplete(type) {
    this.prepareRecommendationFormData(type);
    this.infoOther();
    this.serviceRequest.controls.requestCreationType.setValue(type);
    let data = this.undefinedToBlank();
    let payload = new FormData();
    for (let i in data) {
      payload.append(i, data[i]);
    }
    let note = 'Are you sure to continue?';
    if (!this.techinianStatusDone) {
      payload.append('force_override', '1');
      note = '<center><b>All timers will stop</b></center> <center>Are you sure the technician is done with this service and you want to send updated information to customer?</center> ';
    } else {
      payload.append('force_override', '0');
    };
    this.approvalDiagParams = { requestId: this.serviceRequestId, car_id: this.serviceInfo.car_id, customer_id: this.serviceInfo.customer_id };
    let conf = 'Are you sure to continue?';
    note =
      '<center><b>Are You Sure: </b></center> <center>The technician(s) work is completed on this service request? <br><span class="milesout-popup"><i>This action stops all timers immediately!</i></span><br/> <b>Completing This Action:</b></center><center>Mark this service request as complete and generates the invoice.</center>';
    if (this.VehicleInspectionReport.length == 0 && payload.get('no_report_performed') != 'true') {
      let ask = "Inspection report is not performed on this vehicle. Do you want to submit the report now ?";
      this.globalService.confirmDialog("ask_vehicle_ins", ask).subscribe((insDiagRes) => {
        let insDiagResult: any = insDiagRes;
        this.confirmText = insDiagResult;
        switch (insDiagResult) {
          case 'no':
          case 'close':
            this.requestCreationType = null;
            this.autoSaveRecom().subscribe((res:any)=>{});
            break;
          case 'skip':
            this.requestCreationType = null;
            this.autoSaveRecom().subscribe(res => {
              if (res) {
                payload.set('no_report_performed', 'true');
                this.getJobsApproval(note, payload);
              }
            })
            break;
          case 'yes':
            this.invokeInspectionReportPopup = {
                openPopup: true,
                note: note,
                formData:payload
            };
            this.autoSaveRecom().subscribe(res => {});
            break;
        }
      });
    } else {
      this.requestCreationType = null;
          this.autoSaveRecom().subscribe(result => {
            if (result) this.getJobsApproval(note, payload);
          })
    }
  }

  /**
   * save and open nexPart window
   */
  openNexpartWindow(type) {
    this.prepareRecommendationFormData(type);
    this.infoOther();
    this.serviceRequest.controls.requestCreationType.setValue(1);
    let data = this.undefinedToBlank();
    let payload = new FormData();
    for (let i in data) {
      payload.append(i, data[i]);
    }
    this.saveRecommendationAPI(payload).subscribe((response: any) => {
      if (response.result == "0" || response.result == "352") {
        let msg = response.message;
        this.globalService.snackbar('error', msg, { component: this, method: 'reinit', param: 1 }, true);
      }
      if (response.result == "1") {
        var widthScreen = screen.width - 200;
        var heightScreen = screen.height - 170;
        var url = this.nexpartSession[this.indx].redirect_url;
        this.child = window.open(url, 'Nexpart', "toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width= " + widthScreen + " ,height=" + heightScreen + ",top=50,left=100");
        // window.open('https://3506062.nexpartuat.com/acesCat.php' , 'Nexpart', "toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width= 1166 ,height=568,top=100,left=100");
        // this.child = window.open(url , 'Nexpart', "toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width= "+width+" ,height="+height+",top="+top+",left="+left); 
        this.child.document.title = this.nexpartSession[this.indx].request_id;
        this.confirmText = 'no';
        this.level_id = this.nexpartSession[this.indx].level1_id;
        this.job_id = this.nexpartSession[this.indx].job_id;
        var leftDomain = false;
        var interval = setInterval(function (ctx) {
          try {
            if (ctx.child.closed) {
              ctx.reinit();
              clearInterval(interval);
            }
            var locate = ctx.child.location.href;
            var loc = globalConstants.DOMAIN_URL + '/shops/nexpart/callback';
            if (locate == loc) {
              ctx.child.close();
            }
          }
          catch (e) {
            leftDomain = true;
          }
        }, 500, this);

        this.serviceRequest.controls.access_timestamp.setValue(response.access_timestamp);
        this.accessTimestamp = response.access_timestamp;
        if (this.saveRecommendationConfirm == 1) {
          this.saveRecommendationConfirm = "";
        } else {
          // is from coupon
          if (this.isFromCoupon && response.data.discounts_list) {
            this.fillRecommendationCoupon.reInitialize(response.data);
            this.fillRecommendationCoupon.reset();
            this.removeCouponControls();
          }
          // For Code 518 - to handle invalid coupon codes
          if (response.code == 518 && response.data.discounts_list) {
            console.log('inavlid coupons detected');
            this.fillRecommendationCoupon.reInitialize(response.data);
            this.fillRecommendationCoupon.reset();
          }
          this.removeCouponControls();
        }
        this.serviceRequest.controls.recommendation_saved.setValue(1);
      } else {
        if (response.access_timestamp) {
          this.serviceRequest.controls.access_timestamp.setValue(response.access_timestamp);
          this.accessTimestamp = response.access_timestamp;
        }
        if (response.code == 590 && response.data.discounts_list) {
          this.fillRecommendationCoupon.reInitialize(response.data);
        }
        this.fillRecommendationCoupon.reset();
        this.removeCouponControls();
      }
    });
  }

  /**
   * comman save recomandation function
   */
  saveRecommendationAPI(saveData): Observable<object> {
    return new Observable<object>((subscriber: Subscriber<object>) => {
      this.globalService.formData(globalConstants.API_SAVE_RECOMMENDATION_URL, saveData).subscribe((response: any) => {
        subscriber.next(response);
      })
    })
  }
  handleUnSavedJobs(type) {
    let newJobInfo = ''
    for (let i = 0; i < this.newJobs.length; i++) {
      if (this.newJobs[i].child.length > 0) {
        for (let j = 0; j < this.newJobs[i].child.length; j++) {
          newJobInfo = newJobInfo + "<br><b>" + this.newJobs[i].ln + " -> " + this.newJobs[i].child[j].ln +"</b>"
          if (this.newJobs[i].child[j].child.length > 0) {
            newJobInfo = newJobInfo + "<b> -> " + this.newJobs[i].child[j].child[0].ln  +"</b>"
          }
        }
      }
    }
    let note = "Below jobs are added but are not saved:<br>" + newJobInfo + '<br><br>Press <b>"Yes"</b>, if you want to add these jobs.<br> Press <b>"Skip"</b> if you want to proceed without adding these jobs.';
    this.globalService.confirmDialog("select_walkin_customer", note,423,{},'unsave-job-popup').subscribe((res: any) => {
      if (typeof (res) !== 'undefined') { //click on yes 
        this.addJobs();
      } else {
        if (this.requestCreationType == 2) {
          this.postSaveAndSend(type);
        } else {
          this.postSaveAndComplete(type);
        }
      }
    })
  }
  //click function to open Quick Action menu 
  onQuickActionIconClick() {
    if (this.quickaction) {
      this.quickaction.showOptions = true;
    }
  }
  getTicketAssignSetting(){
    let data="user_id="+this.currentUser.id+"&user_type="+this.currentUser.user_type;
    this.globalService.callAPI(globalConstants.API_SKIP_FOREVER,data).subscribe((skipTicket:any)=>{
      if(skipTicket.result=="1"){
        if(typeof skipTicket.data.skip_forever !='undefined' && skipTicket.data.skip_forever=='1'){
            this.skipTicketAssign=false;
        }else{
            this.skipTicketAssign=true;
        }
      }else{
        this.skipTicketAssign=true;
      }
    })
  }
  isTicketAssign() {
    let row =  this.serviceInfo;
    return (this.isVisible() && this.isEmployee && row.request_status != 10)
  }
  isVisible() {
    let row = this.serviceInfo;
    if (row.request_status == 6 || row.request_status == 8) {
      return false;
    }
    return ((this.currentUser.role_id != 4 || this.currentUser.role_id != 5) ? true : false);
  }
  //click function to open Ticket Assignment sidebar
  ticketAssignment() {
    this.techData = { request_id:this.serviceRequestId,data:this.serviceInfo};
  }

  //handle the self assigment of SR
  slefTicketAssignment(){
    this.techData = { request_id:this.serviceRequestId,data:this.serviceDetail,onlySelf:true};
  }

  //handle the assign the SR to yourself via tiket assignment pop-up
  autoAssign(forcAction=null) {
    let conf = 'Are you sure want to assign and continue?';
    this.globalService.confirmDialog('autoAssign', conf).subscribe((r) => {
      let res: any = r;
      if (res == 'autoAssign') {
        this.autoAssignWithoutConfirm();
      }
    });    
  }

  autoAssignWithoutConfirm(forcAction=null,stopTimer=null,conflictAction=null){
    let data = { assign_totech: 1, emp_id: this.currentUser.id, request_id: this.serviceRequestId, user_id: this.currentUser.id, user_type: this.currentUser.user_type,force_action:forcAction?forcAction:0 };
    if(stopTimer){
      Object.assign(data,{stop_running_timer:stopTimer});
    }
    if(conflictAction > 0){
      Object.assign(data,{adding_conflict:conflictAction});
    }
    this.globalService.callAPI(globalConstants.API_ASSIGN_SERVICE_REQUEST, data).subscribe((tech:any) => {
        if (tech.result == 1 || tech.code=='400') {    
          this.userTaskTrack  = 0;
          if(this.isReportGenerate){
            this.taskTrack = this.isReportGenerate;
            this.taskTrackingApi(1);
          }else{
            this.taskTrackingApi(1); 
          }  
        }else if([730,731,732,734,740].includes(parseInt(tech.code))){
          let code:any = tech.code;
          this.globalService.confirmDialog('techAssign', tech.message).subscribe((resp:any) => {
            let fStatus = {730:1,731:2,732:3,740:4,734:5};
            if(resp=='techAssign'){          
              if([1,2].includes(fStatus[Number(code)])){
                conflictAction = fStatus[Number(code)];
              }          
              this.autoAssignWithoutConfirm(fStatus[Number(code)],stopTimer,conflictAction);
            }
          });
        }else{
          if(tech.code==388){
            let conf = 'Are you sure to want Pause/Complete other service request timer?';
            this.globalService.confirmDialog('taskTrackConfirm', conf).subscribe((res:any) => { 
              if(res){
                this.autoAssignWithoutConfirm(null,res);
              }  
            }); 
          }else{
            this.globalService.snackbar("error", tech.message);
          }  
        }  
    }, (error) => {

    })
  }
}

/************
Add New Part Dialog ****
***************/
@Component({
  templateUrl: 'add-new-parts.html',
})
export class addNewParts {
  public newPart: FormGroup;
  public activatedRoute;
  // public dialog;
  public base64image;
  public lowStockQty="Low Stock Quantity ";
  public isInventory=false;
  public allTags=[];
  public default_low_stock_value;
  public submitted : boolean = false;
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<addNewParts>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder, 
    private globalService: GlobalService, 
    private http: Http,
    private ref:ChangeDetectorRef
  ) {

    this.newPart = this.formBuilder.group({
      part_name: ['', Validators.required],
      part_number: ['', Validators.required],
      cost: ['', [Validators.required,Validators.pattern(/^-?\d+(?:\.\d+)?$/)]],
      price: ['', [Validators.required,Validators.pattern(/^-?\d+(?:\.\d+)?$/)]],
      vendor: [''],
      part_picture:[''],
      manufacturer: [''],
      oem_part_number: [''],
      description: [''],
      user_type: this.globalService.getCurrentUser()["user_type"],
      user_id: this.globalService.getCurrentUser()["id"],
      shop_id: this.globalService.getCurrentUser()["shop_id"],
      quantity:[1,[Validators.pattern("^[0-9]*$")]],
      non_taxable:[0],
      add_to_job:[true],
      part_qty:[0,Validators.pattern(/^-?\d+(?:\.\d+)?$/)],
      low_stock_qty:['',Validators.pattern(/^-?\d+(?:\.\d+)?$/)],
      part_tags:[],
      keep_vendor:[false],
      keep_manufacturer:[false],
      keep_part_name:[false],
      keep_part_no:[false],
      keep_oem_part_no:[false]
    });
    dialogRef["newParts"] = [];
    this.globalService.diaLogObj(this.dialogRef);
    this.isInventory = this.globalService.isInventories();
    if (this.isInventory) {
      let content = { shop_id: this.newPart.value.shop_id };
      this.globalService.callAPI(globalConstants.API_GET_SHOP_DEFAULT_LOW_STOCK_VALUE, content).subscribe((data:any) => {
        if (data.result == "1") {
          this.default_low_stock_value = data.data.default_low_stock_value;
          this.lowStockQty += data.data.default_low_stock_value;
          this.newPart.controls.low_stock_qty.setValue(this.default_low_stock_value);
        }
      }, (error) => { });

      this.newPart.get('part_qty').setValidators([Validators.required,Validators.pattern(/^-?\d+(?:\.\d+)?$/)]);
      this.newPart.get('low_stock_qty').setValidators([Validators.required,Validators.pattern(/^-?\d+(?:\.\d+)?$/)]);

    }


    this.prefillField();
    this.getAllTags();
  }

  prefillField(){
      let prefillVendor=this.globalService.getSetItems('prefill_vendor');
      if(prefillVendor){         
        this.newPart.controls.keep_vendor.setValue(true);
        this.newPart.controls.vendor.setValue(prefillVendor);
      }

      let prefillManufacturer=this.globalService.getSetItems('prefill_manufacturer');
      if(prefillManufacturer){
        this.newPart.controls.keep_manufacturer.setValue(true);
        this.newPart.controls.manufacturer.setValue(prefillManufacturer);
      }

      let prefillPartName=this.globalService.getSetItems('prefill_part_name');
      if(prefillPartName){         
        this.newPart.controls.keep_part_name.setValue(true);
        this.newPart.controls.part_name.setValue(prefillPartName);
      }

      let prefillPartNo=this.globalService.getSetItems('prefill_part_no');
      if(prefillPartNo){
        this.newPart.controls.keep_part_no.setValue(true);
        this.newPart.controls.part_number.setValue(prefillPartNo);
      }

      let prefillOEMPartNo=this.globalService.getSetItems('prefill_oem_part_no');
      if(prefillOEMPartNo){         
        this.newPart.controls.keep_oem_part_no.setValue(true);
        this.newPart.controls.oem_part_number.setValue(prefillOEMPartNo);
      }       
  }

  uploadImage(evt) {
    var validImage=this.globalService.validateFiles('image', evt.target.files);
    if (validImage) {
        var reader = new FileReader();
        reader.onload = this._handleReaderLoaded.bind(this);
        reader.readAsDataURL(evt.target.files[0]);
    }
}

_handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64image = btoa(binaryString);
    this.newPart.controls.part_picture.setValue(binaryString);
    this.ref.detectChanges();
}

  deleteImg(){
     this.globalService.confirmDialog("yes","Are you sure you want to delete this image?").subscribe((response)=>{
       if(response!=undefined && response.toString() == "yes") {
         this.newPart.controls.part_picture.setValue("");
         this.base64image = "";
       }
     })
  }

  toggleQuantityField(event) {
    event.checked ? this.newPart.controls.add_to_job.setValue(true) : this.newPart.controls.add_to_job.setValue(false);
  }
  toggleNonTaxable(event){
    event.checked ? this.newPart.controls.non_taxable.setValue(1) : this.newPart.controls.non_taxable.setValue(0);
  }
  addNewPartSubmit(btnType) {
    Object.keys(this.newPart.controls).map((controlName) => {
      this.newPart.get(controlName).markAsTouched({onlySelf: true});
    });
    this.newPart.controls.user_type.setValue(this.globalService.getCurrentUser()["user_type"]);
    this.newPart.controls.user_id.setValue(this.globalService.getCurrentUser()["id"]);
    this.newPart.controls.shop_id.setValue(this.globalService.getCurrentUser()["shop_id"]);
    this.submitted = true;
    if (this.newPart.valid) {
      var data=this.globalService.formsData(this.newPart.value);
      if(this.newPart.value.keep_vendor==true && this.newPart.value.vendor){    
        this.globalService.getSetItems('prefill_vendor',this.newPart.value.vendor);   
      }else{
        sessionStorage.removeItem('prefill_vendor');
      }

      if(this.newPart.value.keep_manufacturer==true && this.newPart.value.manufacturer){              
        this.globalService.getSetItems('prefill_manufacturer',this.newPart.value.manufacturer);   
      }else{
        sessionStorage.removeItem('prefill_manufacturer');
      }

      if(this.newPart.value.keep_part_name==true && this.newPart.value.part_name){    
        this.globalService.getSetItems('prefill_part_name',this.newPart.value.part_name);   
      }else{
        sessionStorage.removeItem('prefill_part_name');
      }

      if(this.newPart.value.keep_part_no==true && this.newPart.value.part_number){              
        this.globalService.getSetItems('prefill_part_no',this.newPart.value.part_number);   
      }else{
        sessionStorage.removeItem('prefill_part_no');
      }

      if(this.newPart.value.keep_oem_part_no==true && this.newPart.value.oem_part_number){    
        this.globalService.getSetItems('prefill_oem_part_no',this.newPart.value.oem_part_number);   
      }else{
        sessionStorage.removeItem('prefill_oem_part_no');
      }

      this.globalService.formData(globalConstants.API_ADD_PART_URL,data).subscribe((response:any) => {
      // console.log(JSON.parse(res["_body"]))
      // var response = JSON.parse(res["_body"]);
        if (response.result == 1) {
          this.globalService.snackbar("success", response.message);
          response.data.quantity = this.newPart.controls.quantity.value;
          response.data.add_to_job = this.newPart.controls.add_to_job.value;
          if (btnType == 1) {
            this.dialogRef["newParts"].push(response.data);
            this.dialogRef.close();
          } else {
            this.dialogRef["newParts"].push(response.data);
            Object.keys(this.newPart.controls).map((controlName) => {
              this.newPart.get(controlName).reset();
            });
            this.newPart.controls.part_picture.setValue("")
            this.newPart.controls.add_to_job.setValue(true)
            this.newPart.controls.quantity.setValue(1);
            this.newPart.controls.description.setValue("")
            this.newPart.patchValue({
              part_qty : 0,
              low_stock_qty : this.default_low_stock_value
            });
            this.prefillField();
            this.submitted = false;
          }
        } else {
          this.globalService.snackbar("error", response.message);
        }
      });
    } else {
      this.globalService.snackbar("error", globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
    }
  }
  onlyNumericKey(event) {
    return globalFunctions.onlyDecimalNumberKey(event);
  }
  close(){
    return this.dialogRef["newParts"];
    // this.dialogRef.close();
  }
  disableKey(event) {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if (event.ctrlKey==true && (event.which == '67' || event.which == '86' ||  String.fromCharCode(event.which).toLowerCase() == 's')) {
        console.log('thou. shalt. not. PASTE!');
        event.preventDefault();
    }
  }
  manageTag(){
    let dialogRef = this.dialog.open(PartagsComponent, {
        panelClass: 'partstech-order-dialog',
        width: '800px',
        data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
        this.getAllTags();
    });
}
getAllTags(){
    let data={user_id: this.globalService.getCurrentUser()["id"]};
    this.globalService.callAPI(globalConstants.API_LIST_PART_TAGS,data).subscribe((res:any)=>{
        if (res.result == "1") {
            this.allTags=res.data;
        }
    },(error)=>{})
}

partNumber(opt) {
  if (typeof opt == "object") {
      this.newPart.controls.part_number.setValue(opt.part_number);
  }else if(typeof opt!='undefined'){
      this.newPart.controls.part_number.setValue(opt);
  }
}

validateFieldOnPaste(controlName: string) {
  const control = this.newPart.get(controlName);

  control.markAsTouched();
}

observableSourcePN = (keyword: any): Observable<any[]> => {
  let PartSearchKeyword = keyword.replace(/#/g, "|");
  let url: string = globalConstants.API_GET_PART_NUMBERS + "?user_id=" + this.globalService.getCurrentUser()["id"] + "&user_type=" + this.globalService.getCurrentUser()["user_type"] + "&query=" + PartSearchKeyword;
  if (keyword) {
      let f = [];
      return this.globalService.getAPI(url)
          .map(res => {
              let json = res;
              this.globalService.authText(json);
              if (json != null) {
                  json.data.forEach(function (x) {
                      x.value = x.part_number;
                      f.push(x)
                  }
                  );
              }
              return f
          })
  } else {
      return Observable.of([]);
  }
}

setReturnFalse() {
  return false
}

myListFormatter(data: any): string {
  return data['part_number'];
}

convertIntoDecimal(event,frmControlName,beforeDecimalDigit){
  let fieldValue = this.newPart.get(frmControlName).value
  let result= this.globalService.processDecimalValue(fieldValue,beforeDecimalDigit);
  this.newPart.get(frmControlName).setValue(result);
  return globalFunctions.validateNumbersWithDecimal(event,3);
}
}
/**********
**
*Add New Job Dialog ****
***************/
@Component({
  templateUrl: 'add-new-job.html',
})
export class addNewJob {
  public newGenericJob: FormGroup;
  constructor(public dialogRef: MatDialogRef<addNewJob>, private formBuilder: FormBuilder, private globalService: GlobalService) {
    this.newGenericJob = this.formBuilder.group({
      job_name: ['', Validators.required],
      price: ['',[Validators.required,Validators.pattern(/^-?\d+(?:\.\d+)?$/)]],
      cost: ['', [Validators.required,Validators.pattern(/^-?\d+(?:\.\d+)?$/)]],
      description: [''],
      non_taxable: [false],
      user_type: this.globalService.getCurrentUser()["user_type"],
      user_id: this.globalService.getCurrentUser()["id"],
      shop_id: this.globalService.getCurrentUser()["shop_id"],
      quantity:[1],
      add_to_job:[true]
    });
    dialogRef["newGJ"] = [];
    this.globalService.diaLogObj(this.dialogRef);
  }
  toggleQuantityField(event) {
    event.checked ? this.newGenericJob.controls.add_to_job.setValue(true) : this.newGenericJob.controls.add_to_job.setValue(false);
  }
  toggleNonTaxable(event){
    event.checked ? this.newGenericJob.controls.non_taxable.setValue(1) : this.newGenericJob.controls.non_taxable.setValue(0);
  }
  addNewGenericJobSubmit(btnType) {
    Object.keys(this.newGenericJob.controls).map((controlName) => {
      this.newGenericJob.get(controlName).markAsTouched({onlySelf: true});
    });
    this.newGenericJob.controls.user_type.setValue(this.globalService.getCurrentUser()["user_type"]);
    this.newGenericJob.controls.user_id.setValue(this.globalService.getCurrentUser()["id"]);
    this.newGenericJob.controls.shop_id.setValue(this.globalService.getCurrentUser()["shop_id"]);

    if (this.newGenericJob.valid) {
      let content = globalFunctions.stringifyFormData(this.newGenericJob.value, null);
      this.globalService.callAPI(globalConstants.API_ADD_GENERIC_JOB_URL, content).subscribe((response:any) => {
        if (response.result == 1) {
          response.data["quantity"] = this.newGenericJob.controls.quantity.value;
          response.data.add_to_job = this.newGenericJob.controls.add_to_job.value;

          this.globalService.snackbar("success", response.message);
          if (btnType == 1) {
            this.dialogRef["newGJ"].push(response.data);
            this.dialogRef.close();
          } else {
            this.dialogRef["newGJ"].push(response.data);
            Object.keys(this.newGenericJob.controls).map((controlName) => {
              this.newGenericJob.get(controlName).reset();
            });
            this.newGenericJob.controls.add_to_job.setValue(true);
            this.newGenericJob.controls.quantity.setValue(1);
          }
        } else {
          this.globalService.snackbar("error", response.message);
          return false;
        }
      });
    } else {
      this.globalService.snackbar("error", globalConstants.FORM_SUBMISSION_VALIDATION_WARNING)
    }
  }
  onlyNumericKey(event) {
    return globalFunctions.onlyDecimalNumberKey(event);
  }
  
  convertIntoDecimal(event,frmControlName,beforeDecimalDigit){
    let fieldValue = this.newGenericJob.get(frmControlName).value
    let result= this.globalService.processDecimalValue(fieldValue,beforeDecimalDigit);
    this.newGenericJob.get(frmControlName).setValue(result);
    return globalFunctions.validateNumbersWithDecimal(event,3);
  }
}
/************
*Add New Computer Code Dialog ****
***************/
@Component({
  templateUrl: 'add-new-computer-code.html',
})
export class addNewComputerCode {
  public newComputerCode: FormGroup;
  constructor(public dialogRef: MatDialogRef<addNewComputerCode>, private formBuilder: FormBuilder, private globalService: GlobalService) {
    this.newComputerCode = this.formBuilder.group({
      trouble_code: ['', Validators.required],
      fault_location: ['', Validators.required],
      user_type: this.globalService.getCurrentUser()["user_type"],
      user_id: this.globalService.getCurrentUser()["id"],
      shop_id: this.globalService.getCurrentUser()["shop_id"],
      add_to_job:[true]
    });
    dialogRef["newCC"] = [];
    this.globalService.diaLogObj(this.dialogRef);
  }
  toggleQuantityField(event) {
    event.checked ? this.newComputerCode.controls.add_to_job.setValue(true) : this.newComputerCode.controls.add_to_job.setValue(false);
  }
  addComputerCode(btnType) {
    Object.keys(this.newComputerCode.controls).map((controlName) => {
      this.newComputerCode.get(controlName).markAsTouched({onlySelf: true});
    });
    this.newComputerCode.controls.user_type.setValue(this.globalService.getCurrentUser()["user_type"]);
    this.newComputerCode.controls.user_id.setValue(this.globalService.getCurrentUser()["id"]);
    this.newComputerCode.controls.shop_id.setValue(this.globalService.getCurrentUser()["shop_id"]);

    if (this.newComputerCode.valid) {
      let content = globalFunctions.stringifyFormData(this.newComputerCode.value, null);
      this.globalService.callAPI(globalConstants.API_ADD_COMPUTER_CODE_URL, content).subscribe((response:any) => {

        if (response.result == 1) {
          this.globalService.snackbar("success", response.message);
          response.data.add_to_job = this.newComputerCode.controls.add_to_job.value;
          if (btnType == 1) {
            this.dialogRef["newCC"].push(response.data);
            this.dialogRef.close();
          } else {
            this.dialogRef["newCC"].push(response.data);
            Object.keys(this.newComputerCode.controls).map((controlName) => {
              this.newComputerCode.get(controlName).reset();
            });
            this.newComputerCode.controls.add_to_job.setValue(true);
          }
        } else {
          this.globalService.snackbar("error", response.message);
          return false;
        }
      });
    } else {
      this.globalService.snackbar("error", globalConstants.FORM_SUBMISSION_VALIDATION_WARNING)
    }
  }
}
/** save recommendations confirmation component*/
@Component({
  templateUrl: 'saverecommendationsconfirmation.html',
})
export class SaveRecommendationConfirmation {
  isCoupon:boolean;
  couponOpr:number
  constructor(private http: Http, public dialogRef: MatDialogRef<SaveRecommendationConfirmation>, private formBuilder: FormBuilder, private globalService: GlobalService, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.globalService.diaLogObj(this.dialogRef);
    this.isCoupon = this.data.isCoupon;
    this.couponOpr = this.data.opr;
  }
  saveAndStay(){
    let data =0;
    this.dialogRef.close({ data: data });
  }
  saveAndClose(){
    let data =1;
    this.dialogRef.close({ data: data });
  }
}

@Component({
  templateUrl: 'usps-vehicle.html',
})
export class uspsVehicle {
  public uspsReport;
  public reportData;
  public service_request_id;
  constructor(public dialogRef: MatDialogRef<VehicleInspectionReportComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder, private globalService: GlobalService) {
    this.reportData = data.report_data;
    this.service_request_id=data.service_request_id;
    this.globalService.diaLogObj(this.dialogRef);
    this.initForm();
    this.setReportData();
  }
  setReportData() {
    if (this.reportData) {
      for (var i in this.reportData) {
        try {
          this.uspsReport.controls[i].setValue(this.reportData[i]);
        } catch (e) { }
      }
    }else{
      this.uspsReport.controls.service_request_id.setValue(this.service_request_id);
    }

  }
  saveUsps() {
    let url = globalConstants.API_FILL_INSPECTION_REPORT_USPS_URL;
    let data = globalFunctions.stringifyFormData(this.uspsReport.value, this.globalService.getCurrentUser()["user_type"]);
    data = data + "&user_id=" + this.globalService.getCurrentUser()["id"];
    this.globalService.callAPI(url, data).subscribe((result:any) => {
      if (result.result == '1') {
        this.dialogRef.close('success');
        this.globalService.snackbar('success', result.message);
      } else {
        this.globalService.snackbar("error", result.message);
      }
    });
    // this.dialogRef.close();
  }
  initForm() {
    this.uspsReport = this.formBuilder.group({
      vehicle_number: [''],
      assigned_location: [''],
      due_date: [''],
      type_pmi: [''],
      cab_area_A_1: [''],
      cab_area_B_1: [''],
      cab_area_A_2: [''],
      cab_area_B_2: [''],
      cab_area_A_3: [''],
      cab_area_B_3: [''],
      cab_area_A_4: [''],
      cab_area_B_4: [''],
      cab_area_A_5: [''],
      cab_area_B_5: [''],
      cab_area_A_6: [''],
      cab_area_B_6: [''],
      cab_area_A_7: [''],
      cab_area_B_7: [''],
      cab_area_A_8: [''],
      cab_area_B_8: [''],
      cab_area_A_9: [''],
      cab_area_B_9: [''],
      cab_area_A_10: [''],
      cab_area_B_10: [''],
      cab_area_textval_10: [''],
      cab_area_A_11: [''],
      cab_area_B_11: [''],
      cab_area_A_12: [''],
      cab_area_B_12: [''],
      cab_area_A_13: [''],
      cab_area_B_13: [''],
      cab_area_A_14: [''],
      cab_area_B_14: [''],
      cab_area_A_15: [''],
      cab_area_B_15: [''],
      cab_area_A_16: [''],
      cab_area_B_16: [''],
      cab_area_A_17: [''],
      cab_area_B_17: [''],
      cab_area_A_18: [''],
      cab_area_B_18: [''],
      cab_area_A_19: [''],
      cab_area_B_19: [''],
      cab_area_A_20: [''],
      cab_area_B_20: [''],
      cab_area_A_21: [''],
      cab_area_B_21: [''],
      cab_area_A_22: [''],
      cab_area_B_22: [''],
      cab_area_textboxval_22: [''],
      circle_inspection_A_1: [''],
      circle_inspection_B_1: [''],
      circle_inspection_A_2: [''],
      circle_inspection_B_2: [''],
      circle_inspection_A_3: [''],
      circle_inspection_B_3: [''],
      circle_inspection_textval_3_lf: [''],
      circle_inspection_textval_3_rf: [''],
      circle_inspection_textval_3_lr: [''],
      circle_inspection_textval_3_rr: [''],
      circle_inspection_A_4: [''],
      circle_inspection_B_4: [''],
      circle_inspection_A_5: [''],
      circle_inspection_B_5: [''],
      circle_inspection_A_6: [''],
      circle_inspection_B_6: [''],
      circle_inspection_A_7: [''],
      circle_inspection_B_7: [''],
      circle_inspection_A_8: [''],
      circle_inspection_B_8: [''],
      circle_inspection_A_9: [''],
      circle_inspection_B_9: [''],
      circle_inspection_A_10: [''],
      circle_inspection_B_10: [''],
      circle_inspection_A_11: [''],
      circle_inspection_B_11: [''],
      circle_inspection_A_12: [''],
      circle_inspection_B_12: [''],
      circle_inspection_textboxval_12: [''],
      circle_inspection_A_13: [''],
      circle_inspection_B_13: [''],
      circle_inspection_textboxval_13: [''],
      underneath_inspect_lube_A_1: [''],
      underneath_inspect_lube_B_1: [''],
      underneath_inspect_lube_A_2: [''],
      underneath_inspect_lube_B_2: [''],
      underneath_inspect_lube_A_3: [''],
      underneath_inspect_lube_B_3: [''],
      underneath_inspect_lube_A_4: [''],
      underneath_inspect_lube_B_4: [''],
      underneath_inspect_lube_textval_4_lf: [''],
      underneath_inspect_lube_textval_4_rf: [''],
      underneath_inspect_lube_textval_4_lr: [''],
      underneath_inspect_lube_textval_4_rr: [''],
      underneath_inspect_lube_A_5: [''],
      underneath_inspect_lube_B_5: [''],
      underneath_inspect_lube_A_6: [''],
      underneath_inspect_lube_B_6: [''],
      underneath_inspect_lube_A_7: [''],
      underneath_inspect_lube_B_7: [''],
      underneath_inspect_lube_A_8: [''],
      underneath_inspect_lube_B_8: [''],
      underneath_inspect_lube_A_9: [''],
      underneath_inspect_lube_B_9: [''],
      underneath_inspect_lube_A_10: [''],
      underneath_inspect_lube_B_10: [''],
      underneath_inspect_lube_A_11: [''],
      underneath_inspect_lube_B_11: [''],
      underneath_inspect_lube_A_12: [''],
      underneath_inspect_lube_B_12: [''],
      underneath_inspect_lube_A_13: [''],
      underneath_inspect_lube_B_13: [''],
      underneath_inspect_lube_textboxval_13: [''],
      underneath_inspect_lube_A_14: [''],
      underneath_inspect_lube_B_14: [''],
      underneath_inspect_lube_textboxval_14: [''],
      underneath_inspect_lube_A_15: [''],
      underneath_inspect_lube_B_15: [''],
      underneath_inspect_lube_textboxval_15: [''],
      engine_compartment_area_A_1: [''],
      engine_compartment_area_B_1: [''],
      engine_compartment_area_A_2: [''],
      engine_compartment_area_B_2: [''],
      engine_compartment_area_textval_2: [''],
      engine_compartment_area_A_3: [''],
      engine_compartment_area_B_3: [''],
      engine_compartment_area_A_4: [''],
      engine_compartment_area_B_4: [''],
      engine_compartment_area_A_5: [''],
      engine_compartment_area_B_5: [''],
      engine_compartment_area_A_6: [''],
      engine_compartment_area_B_6: [''],
      engine_compartment_area_A_7: [''],
      engine_compartment_area_B_7: [''],
      engine_compartment_area_A_8: [''],
      engine_compartment_area_B_8: [''],
      engine_compartment_area_A_9: [''],
      engine_compartment_area_B_9: [''],
      engine_compartment_area_A_10: [''],
      engine_compartment_area_B_10: [''],
      engine_compartment_area_A_11: [''],
      engine_compartment_area_B_11: [''],
      engine_compartment_area_A_12: [''],
      engine_compartment_area_B_12: [''],
      engine_compartment_area_A_13: [''],
      engine_compartment_area_B_13: [''],
      engine_compartment_area_textval_13_volts: [''],
      engine_compartment_area_A_14: [''],
      engine_compartment_area_B_14: [''],
      engine_compartment_area_textval_14_amps: [''],
      engine_compartment_area_A_15: [''],
      engine_compartment_area_B_15: [''],
      engine_compartment_area_textval_15_volts: [''],
      engine_compartment_area_textval_15_amps: [''],
      engine_compartment_area_A_16: [''],
      engine_compartment_area_B_16: [''],
      engine_compartment_area_textval_16_volts: [''],
      engine_compartment_area_A_17: [''],
      engine_compartment_area_B_17: [''],
      engine_compartment_area_textval_17_hc_idle: [''],
      engine_compartment_area_textval_17_co_idle: [''],
      engine_compartment_area_textval_17_hc_rpm: [''],
      engine_compartment_area_textval_17_co_rpm: [''],
      engine_compartment_area_textval_17_o2_idle: [''],
      engine_compartment_area_textval_17_co2_idle: [''],
      engine_compartment_area_textval_17_o2_rpm: [''],
      engine_compartment_area_textval_17_co2_rpm: [''],
      engine_compartment_area_A_18: [''],
      engine_compartment_area_B_18: [''],
      engine_compartment_area_A_19: [''],
      engine_compartment_area_B_19: [''],
      cng_vehicles_A_1: [''],
      cng_vehicles_B_1: [''],
      cng_vehicles_A_2: [''],
      cng_vehicles_B_2: [''],
      engine_compartment_A_1: [''],
      engine_compartment_B_1: [''],
      engine_compartment_A_2: [''],
      engine_compartment_B_2: [''],
      engine_compartment_A_3: [''],
      engine_compartment_B_3: [''],
      engine_compartment_textval_3_hc_idle: [''],
      engine_compartment_textval_3_co_idle: [''],
      engine_compartment_textval_3_hc_rpm: [''],
      engine_compartment_textval_3_co_rpm: [''],
      engine_compartment_textval_3_o2_idle: [''],
      engine_compartment_textval_3_co2_idle: [''],
      engine_compartment_textval_3_o2_rpm: [''],
      engine_compartment_textval_3_co2_rpm: [''],
      underneath_inspection_A_1: [''],
      underneath_inspection_B_1: [''],
      underneath_inspection_A_2: [''],
      underneath_inspection_B_2: [''],
      underneath_inspection_A_3: [''],
      underneath_inspection_B_3: [''],
      underneath_inspection_A_4: [''],
      underneath_inspection_B_4: [''],
      underneath_inspection_textboxval_4: [''],
      underneath_inspection_A_5: [''],
      underneath_inspection_B_5: [''],
      underneath_inspection_textboxval_5: [''],
      completed_by: [''],
      order_date: [''],
      workorder_number: [''],
      odometer: [''],
      service_request_id: [''],
      inspectionReportType: [1]
    });
  }
}

@Component({template:  `
<div #autoCompleteContainer class="ngui-auto-complete">
  <!-- keyword input -->
  <input *ngIf="showInputTag"
         #autoCompleteInput class="keyword"
         placeholder="{{placeholder}}"
         (focus)="showDropdownList($event)"
         (blur)="blurHandler($event)"
         (keydown)="inputElKeyHandler($event)"
         (input)="reloadListInDelay($event)"
         [(ngModel)]="keyword" />

  <!-- dropdown that user can select -->
  <ul *ngIf="dropdownVisible" [class.empty]="emptyList">
    <li *ngIf="isLoading && loadingTemplate" class="loading" [innerHTML]="loadingTemplate"></li>
    <li *ngIf="isLoading && !loadingTemplate" class="loading">{{loadingText}}</li>
    <li *ngIf="minCharsEntered && !isLoading && !filteredList.length"
         (mousedown)="selectOne('')"
         class="no-match-found">{{noMatchFoundText || 'No Result Found'}}</li>
    <li *ngIf="blankOptionText && filteredList.length"
        (mousedown)="selectOne('')"
        class="blank-item">{{blankOptionText}}</li>
    <li class="item"
        *ngFor="let item of filteredList; let i=index"
        (mousedown)="selectOne(item)"
        [ngClass]="{selected: i === itemIndex}"
        [innerHtml]="autoComplete.getFormattedListItem(item)">
    </li>
  </ul>

</div>`,
styles: [`
  @keyframes slideDown {
    0% {
      transform:  translateY(-10px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  .ngui-auto-complete {
    background-color: transparent;
  }
  .ngui-auto-complete > input {
    outline: none;
    border: 0;
    padding: 2px; 
    box-sizing: border-box;
    background-clip: content-box;
  }

  .ngui-auto-complete > ul {
    background-color: #fff;
    margin: 0;
    width : 100%;
    overflow-y: auto;
    list-style-type: none;
    padding: 0;
    border: 1px solid #ccc;
    box-sizing: border-box;
    animation: slideDown 0.1s;
  }
  .ngui-auto-complete > ul.empty {
    display: none;
  }

  .ngui-auto-complete > ul li {
    padding: 2px 5px;
    border-bottom: 1px solid #eee;
  }

  .ngui-auto-complete > ul li.selected {
    background-color: #ccc;
  }

  .ngui-auto-complete > ul li:last-child {
    border-bottom: none;
  }

  .ngui-auto-complete > ul li:hover {
    background-color: #ccc;
  }`
  ],
  encapsulation: ViewEncapsulation.None
})
export class Autoui extends NguiAutoCompleteComponent {
  @Input("list-formatter") listFormatter: (arg: any) => string;
  @Input("source") source: any;
  @Input("path-to-data") pathToData: string;
  @Input("min-chars") minChars: number = 0;
  @Input("placeholder") placeholder: string;
  @Input("blank-option-text") blankOptionText: string;
  @Input("no-match-found-text") noMatchFoundText: string;
  @Input("accept-user-input") acceptUserInput: boolean = true;
  @Input("loading-text") loadingText: string = "Loading";
  @Input("loading-template") loadingTemplate = null;
  @Input("max-num-list") maxNumList: number;
  @Input("show-input-tag") showInputTag: boolean = true;
  @Input("show-dropdown-on-init") showDropdownOnInit: boolean = false;
  @Input("tab-to-select") tabToSelect: boolean = true;
  @Input("match-formatted") matchFormatted: boolean = false;
  @Input("auto-select-first-item") autoSelectFirstItem: boolean = false;
  @Input("select-on-blur") selectOnBlur: boolean = false;

  @Output() valueSelected = new EventEmitter();
  @Output() customSelected = new EventEmitter();
  @Output() textEntered = new EventEmitter();
  @ViewChild('autoCompleteInput') autoCompleteInput: ElementRef;
  @ViewChild('autoCompleteContainer') autoCompleteContainer: ElementRef;

  // public el: HTMLElement;           // this component  element `<ngui-auto-complete>`

  dropdownVisible: boolean = false;
  isLoading: boolean = false;

  filteredList: any[] = [];
  minCharsEntered: boolean = false;
  itemIndex: number = null;
  keyword: string;
  constructor(elementRef: ElementRef, public autoComplete: NguiAutoComplete) {
    super(elementRef, autoComplete);
  }
  delays = (function () {
    let timer:any = 0;
    return function (callback: any, ms: number) {
      clearTimeout(timer);
      timer = setTimeout(callback, ms);
    };
  })();
  showDropdownList(event: any): void {
    this.reloadList(event.target.value);
  }
  public showData(keyword) {
    let th = this;
    this.dropdownVisible = true;
    th.filteredList = [];
    if (keyword.length < (th.minChars || 0)) {
      th.minCharsEntered = false;
      return;
    }
    else {
      th.minCharsEntered = true;
    }
    if (th.isSrcArr()) {
      th.isLoading = false;
      th.filteredList = th.autoComplete.filter(th.source, keyword, th.matchFormatted,th.minCharsEntered);
      if (th.maxNumList) {
        th.filteredList = th.filteredList.slice(0, th.maxNumList);
      }
    }
    else {
      th.isLoading = true;

      if (typeof th.source === "function") {
        // custom function that returns observable
        th.source(keyword).subscribe(function (resp) {
          if (th.pathToData) {
            var paths = th.pathToData.split(".");
            paths.forEach(function (prop) { return resp = resp[prop]; });
          }
          th.filteredList = resp;
          if (th.maxNumList) {
            th.filteredList = th.filteredList.slice(0, th.maxNumList);
          }
        }, function (error) { return null; }, function () { return th.isLoading = false; } // complete
        );
      }

    }
  }
  public reloadList(keyword) {
    this.delays(() => this.showData(keyword), 1000);
  }

  
}
@Component({
  template:  `
  <div mat-dialog-title class="relative hide">
  Click as per your choice.
<button mat-mini-fab mat-dialog-close class="close-modal notranslate mat-elevation-z0">
  <mat-icon aria-label="Example icon-button with a heart icon">close</mat-icon>
</button>
</div>
<mat-dialog-content>
<div class="row">
<div class="form-group padding-left-15 padding-right-15">
  Some one has modified the fill-recommendation. 
</div>
  <div class="col-sm-6 form-group hide">
    <button mat-raised-button (click)="closeIT()" class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white"
      title="Close" color="accent">Close</button>
  </div>
  <div class="col-sm6 form-group">
    <button mat-raised-button (click)="refreshIT()" class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white"
      title="Refresh" color="accent">Refresh</button>
  </div>
</div>
</mat-dialog-content>`,
})
export class CloseOrRefresh {
  constructor(private http: Http, public dialogRef: MatDialogRef<CloseOrRefresh>, private formBuilder: FormBuilder, private globalService: GlobalService) {
    this.globalService.diaLogObj(this.dialogRef);
  }
  closeIT(){
    // this.dialogRef.close(1);
    // window.open('','_self').close();
  }
  refreshIT(){
    this.dialogRef.close(2);
  }
}
